import React from "react";
const Button = ({ buttonText, fullButton, loading, disabled, type, onClick, listingButtonUnfilled, listingButtonfilled, ...props }) => {
    return (
        <button
            type={type ?? "button"}
            className={
                listingButtonUnfilled
                    ? "btn fs-4 waves-effect waves-light btn-outline-primary padding-10"
                    :
                    listingButtonfilled
                        ? "btn btn-primary fs-4 text-white padding-10"
                        :
                        !!fullButton
                            ? "btn btn-primary fs-5 text-white w-100 py-2-5 mb-4 rounded-2"
                            : "btn btn-primary fs-5 text-white py-2-5 px-13"
            }
            // ? "btn btn-primary text-white w-100 py-3 mb-3 rounded-2"
            //         : "btn btn-primary text-white w-50 py-3 mb-3"
            // onClick={onClick?.()}
            disabled={disabled || loading}
            onClick={onClick}
            {...props}
        >
            {loading ? (
                <div>
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span style={{ paddingLeft: 5 }}>Loading...</span>
                </div>
            ) : (
                <span dangerouslySetInnerHTML={{ __html: buttonText }} />
            )}
        </button>
    );
}

export default Button;
