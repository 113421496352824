import React, { useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import { isMobile } from "../../helpers/helper";

function ImportProductNav() {
    const isMobileSize = isMobile();
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');

    const [storeid, setStoreid] = useState("");
    const [accesstoken, setAccesstoken] = useState("");
    // const [showErrors, setShowErrors] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(false);
    const [importProductLoader, setImportProductLoader] = useState(false);

    
    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm(storeid, accesstoken);
    };

    const submitForm = (storeid, accessToken) => {

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-variant-shopify-product-sync', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "shopify_store": storeid,
                    "access_token": accessToken
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {
                            if(history.location.state.source=="addvideo"){
                            history_push_link = "/videos/add-nav-video/select-nav-product";}
                        } 
                        else {
                            // history_push_link = "/add-product";
                        }

                        history.push({
                            pathname: history_push_link,
                            state: history.location.state,
                        })
                    });
                }
            }).catch((error) => {
            }).finally((error) => {
                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };


    const isButtonDisabled = !isURL(storeid) || !accesstoken;


    const onNavBarClick = () =>{
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
      } 

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
        <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Import Product" onNavBarClick={onNavBarClick} formWidth = {true}>
                <div className="card bg-light-info shadow-none position-relative overflow-hidden">
                    <div className="card-body px-4 py-3">
                        <div className="row align-items-center">
                            <div className="col-9">
                                <h4 className="fw-semibold mb-8">{history.location.state.source === "addvideo" ? "Video":"Product"}</h4>
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            <Link className="text-muted" to="/">
                                                Dashboard
                                            </Link>
                                        </li>
                                        <li className="breadcrumb-item" aria-current="page">
                                            Import Products From Shopify
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="col-3">
                                <div className="text-center mb-n5">
                                    <img
                                        src="../../dist/images/breadcrumb/ChatBc.png"
                                        alt=""
                                        className="img-fluid mb-n4"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row"> */}
                    <section>
                        <div class="card">
                            <div class="card-body">
                                {/* {(showErrors || errorMessage) ? <div class="alert alert-danger" role="alert"><strong>Error </strong> {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."} </div> : ""} */}
                                {/* <p className="card-subtitle mb-3"> You can us the validation like what we did </p> */}
                                <form className="form">


                                    <div className="row">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-3">
                                            <Link
                                                to={{
                                                    pathname: `products/add-nav-product`,
                                                    state: history.location.state
                                                }}
                                                className="btn btn-outline-primary w-100 py-8"
                                            >
                                                Add Product
                                            </Link>
                                        </div>
                                        <div className="col-md-3">
                                            <Link

                                                to={{
                                                    pathname: `import-nav-product`,
                                                    state: history.location.state
                                                }}
                                                className="btn btn-primary w-100 py-8 mb-4 rounded-2"
                                            >
                                                Import From Shopify
                                            </Link>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="wfirstName2"> Shopify Store URL <span className="danger">*</span>
                                                </label>
                                                <input type="text" placeholder="https://STORE_ID.myshopify.com" className="form-control required" id="wfirstName2" name="storeid" value={storeid} onChange={(e) => setStoreid(e.target.value)} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <label htmlFor="wfirstName2"> Access Key:<span className="danger">*</span>
                                                </label>
                                                <input type="text" className="form-control required" id="wfirstName2" name="accesstoken" value={accesstoken} onChange={(e) => setAccesstoken(e.target.value)} />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-actions">
                                        <div class="card-body border-top">
                                            <button type="submit" onClick={handleSubmit} disabled={(isButtonDisabled || importProductLoader )} class="btn btn-primary text-white">                                                
                                                <div class="d-flex align-items-center">
                                                {importProductLoader ? (
                                                        <div>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...
                                                        </div>
                                                    ) : "Next"}
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div className="actions clearfix"><ul role="menu" aria-label="Pagination"><li aria-hidden="false" aria-disabled="false"><a onClick={handleSubmit} role="menuitem">Submit</a></li><li aria-hidden="true" style={{ display: 'none' }}><a href="#finish" role="menuitem">Submit</a></li></ul></div> */}
                                </form>
                            </div>
                        </div>
                    </section>
                {/* </div> */}
            </Page>
        </div>
    );
}
export default ImportProductNav;