// UserSubscribedDataContext.js
import React, { createContext, useContext, useState } from 'react';

const UserSubscribedDataContext = createContext();

export const UserSubscribedDataProvider = ({ children }) => {
  const [contextSubscribedUserData, setContextSubscribedUserData] = useState('');

  const updateContextSubscribedUserData = (data) => {
    setContextSubscribedUserData(data);
  };

  const contextValue = { contextSubscribedUserData, updateContextSubscribedUserData };

  // console.log('UserSubscribedDataContext - Provider Value:', contextValue);

  return (
    <UserSubscribedDataContext.Provider value={contextValue}>
      {children}
    </UserSubscribedDataContext.Provider>
  );
};

export const useUserSubscribedDataContext = () => {
  const contextValue = useContext(UserSubscribedDataContext);


  if (!contextValue) {
    throw new Error('useUserSubscribedDataContext must be used within a UserSubscribedDataProvider');
  }

  return contextValue;
};
