import React, { useState, useEffect, useRef } from "react";
// import {loggedUserAccountType,userLoggedAccessToken, isMobile} from '../../../helpers/helper';
import crossDeleteIcon from "../../assets/icons/cross-x-close-icon.svg";

const ProductImageCardBox = ({
  boxImgSrc,
  boxTitle,
  boxIndexKey,
  crossDeleteBtnFunction,
  ...props
}) => {
  const toSelectPopupCallBack = crossDeleteBtnFunction || (() => {}); // Default callback function

  return (
    <>
      <div className="product-image-box">
        <div className="close-btn">
          <img
            src={crossDeleteIcon}
            onClick={(e) => {
              toSelectPopupCallBack(boxIndexKey);
            }}
          />
        </div>
        <div className="image-area">
          <img src={boxImgSrc} />
        </div>
      </div>
    </>
  );
};

export default ProductImageCardBox;
