import React, { useState, useEffect } from "react";
import { generateRandomString } from "../../helpers/helper";
import validateField from "../../helpers/AllValidtions";
import * as helper from "../../helpers/helper";
import moment from "moment";
import Loading from "./../Loadings/Loading"
import WhiteSquareButton from "../../Form/WhiteSquareButton";
import LinkImg from "./../../../assets/icons/link.svg"
import AppWindowImg from "./../../../assets/icons/app-window.svg"
import Settings2Img from "./../../../assets/icons/settings-2.svg"
import Input from "../../Form/Input";
import FieldWarning from "../../Form/FieldWarning";
import DateTimePicker from "../../Form/DateTimePicker";


export const STREAM_TYPE_OPTIONS = ["Live stream from URL", "Connect to RTMP encoders", "Live stream from browser"];

const StreamVideoFormFields = ({ streamplatform, setStreamplatform, streamname, setStreamname, streamurl, setStreamurl, streamkey, setStreamkey, setStreamstarttime, setStreamendtime, setErrorMessage, streamstarttime, streamendtime, streamSourceType, setStreamSourceType, setAllstreamuploadError, userSubscription }) => {
  const [minDateTime, setMinDateTime] = useState("");
  const [urlErrors, setUrlErrors] = useState(false);
  const [loading, setLoading] = useState(true);

  const [streamstarttimeErrors, setstreamstarttimeErrors] = useState("");
  const [streamendtimeErrors, setstreamendtimeErrors] = useState("");
  const [maxStreamDuration, setMaxStreamDuration] = useState();

  useEffect(() => {
    if (
      helper.showNumberOrInfinity(
        userSubscription?.live_stream_from_video_squirrel
      ) === "Unlimited"
    ) {
      setMaxStreamDuration(
        helper.showNumberOrInfinity(
          userSubscription?.live_stream_from_video_squirrel
        )
      );
    } else {
      setMaxStreamDuration(
        helper.allowedAddonSteamTimeMinutes(userSubscription)
          ?.totalStreamMinutes * 60
      );
    }
  }, [userSubscription]);

  useEffect(() => {

    const streamStartNewDate = moment(streamstarttime);
    const streamEndNewDate = moment(streamendtime);

    if (streamstarttime && streamStartNewDate.isBefore(moment())) {
      setstreamstarttimeErrors(`Stream start time must be greater than the current time.`);
    }

    if (streamstarttime && streamendtime) {
      const timeDifferenceInSeconds = streamEndNewDate.diff(streamStartNewDate, 'seconds');

      if (streamSourceType === STREAM_TYPE_OPTIONS[2] && maxStreamDuration !== 'Unlimited' && timeDifferenceInSeconds > maxStreamDuration && !streamendtimeErrors) {
        const suggestNewEndDate = streamStartNewDate.add(maxStreamDuration, 'second').format('MM/DD/YYYY hh:mm a');
        if (maxStreamDuration === 0) {
          setstreamendtimeErrors(`You have ${maxStreamDuration / 60} minutes allowed for live streaming.`);
        } else {
          setstreamendtimeErrors(`You have ${maxStreamDuration / 60} minutes allowed for live streaming, please end date max  ${suggestNewEndDate} `);
        }
      }

    }

  }, [streamstarttime, streamendtime, maxStreamDuration]);

  useEffect(() => {
    async function fetchData() {
      setTimeout(() => {
        setLoading(false)
      }, 1000);
    }

    fetchData();

    if (streamstarttimeErrors || streamendtimeErrors) {
      setAllstreamuploadError(true)

    } else {
      setAllstreamuploadError(false)

    }
  }, [streamstarttimeErrors, streamendtimeErrors]);

  const presetStreamLinkAndKey = () => {
    setStreamurl("rtmps://streaming.videosquirrel.app:8090/live/")
    setStreamkey(generateRandomString(28))
  }

  const emptyStreamLinkAndKey = () => {
    setStreamurl("")
    setStreamkey("")
  }

  useEffect(() => {
    setMinDateTime(new Date().toISOString().slice(0, 16));
  }, []);


  function setPublicDateValue(value) {
    var start_time = new Date(streamstarttime);
    var end_time = new Date(value);

    setStreamendtime(value)
    setstreamendtimeErrors("")

    if (start_time !== "Invalid Date" && start_time !== undefined && end_time > start_time) {

      setStreamendtime(value)

    } else if (start_time == "Invalid Date" || start_time === undefined) {
      setstreamendtimeErrors('Enter Start Time First')
    } else {
      setstreamendtimeErrors('End Time should be greater than Start Time')
    }
  }

  function setFirstPublicDateValue(value) {
    var start_time = new Date(value);
    var end_time = new Date(streamendtime);
    var current_time = new Date();

    setstreamstarttimeErrors("")
    setStreamstarttime(value);

    if ((start_time > current_time) === false && end_time?.length > 0) {
      setstreamstarttimeErrors("Stream start time must be greater than the current time.")
    }
    else {
      if (end_time == "Invalid Date" || end_time === undefined || end_time > start_time) {
        setStreamendtime("")
        setstreamendtimeErrors("")
      } else {
        setstreamstarttimeErrors('Start Time should be less than End Time')
      }
    }
  }

  const checkStreamurl = (e) => {
    var value = e.target.value;
    var rule = "url";
    setStreamurl(value)
    validateField(value, rule, setUrlErrors);
  }

  if (loading) {
    return <Loading />
  }

  const onStreamSourceChange = (e) => {
    const value = e.target.value;
    setStreamSourceType(value)

    if (value === STREAM_TYPE_OPTIONS[0]) {
      emptyStreamLinkAndKey();
    } else {
      presetStreamLinkAndKey();
    }

    if (value === STREAM_TYPE_OPTIONS[1]) {
      setUrlErrors("")
    }
  }

  return (
    <div className="p-3 py-4">
      <h5 className="fw-bolder">How do you want to stream?</h5>
      <FieldWarning message="Ensure to input your streaming details and the crucial m3u8 URL before proceeding." />
      <div className="d-flex flex-row justify-content-center gap-8 mt-3 mb-4">
        <WhiteSquareButton
          icon={<img src={LinkImg} alt="From URL" height="28" width="28" />}
          label="From URL"
          active={streamSourceType === "Live stream from URL"}
          onClick={() => onStreamSourceChange({ target: { value: "Live stream from URL" } })}
          styles={{
            height: 90,
            flex: 1
          }}
        />
        <WhiteSquareButton
          icon={<img src={AppWindowImg} alt="From Browser" height="28" width="28" />}
          label="From Browser"
          active={streamSourceType === "Live stream from browser"}
          onClick={() => onStreamSourceChange({ target: { value: "Live stream from browser" } })}
          styles={{
            height: 90,
            flex: 1
          }}
        />
        <WhiteSquareButton
          icon={<img src={Settings2Img} alt="RTMP encoders" height="28" width="28" />}
          label="RTMP encoders"
          active={streamSourceType === "Connect to RTMP encoders"}
          onClick={() => onStreamSourceChange({ target: { value: "Connect to RTMP encoders" } })}
          styles={{
            height: 90,
            flex: 1
          }}
        />
      </div>

      <div className="row">
        {streamSourceType === STREAM_TYPE_OPTIONS[0] &&
          <>
            <div className="col-md-12">
              <div className="mb-2">
                <Input
                  id="steam-platform"
                  type="text"
                  label="Stream Platform"
                  value={streamplatform}
                  onChange={(e) => setStreamplatform(e.target.value)}
                  disabled={false}
                  placeholder=""
                />
              </div>
            </div>

            <div className="col-md-12">
              <div className="mb-2">
                <Input
                  id="steam-name"
                  type="text"
                  label="Stream Name"
                  value={streamname}
                  onChange={(e) => setStreamname(e.target.value)}
                  disabled={false}
                  placeholder=""
                />
              </div>
            </div>
          </>
        }

        {streamSourceType === STREAM_TYPE_OPTIONS[1] && (
          <div className="col-md-12 mb-2">
            <FieldWarning message="Copy the details below and plug them into your streaming platform" />
          </div>
        )}

        {streamSourceType !== STREAM_TYPE_OPTIONS[2] && (
          <div className="col-md-12">
            <div className="mb-3">
              <Input
                id="steam-url"
                type="text"
                label={streamSourceType === STREAM_TYPE_OPTIONS[0] ? "Stream URL *" : "Server URL"}
                value={streamurl}
                onChange={checkStreamurl}
                disabled={streamSourceType === STREAM_TYPE_OPTIONS[1]}
                placeholder=""
                errorMessage={urlErrors}
                copyToClipboard={streamSourceType === STREAM_TYPE_OPTIONS[1]}
              />
            </div>
          </div>
        )}

        {streamSourceType === STREAM_TYPE_OPTIONS[1] &&
          <div className="col-md-12">
            <div className="mb-3">
              <Input
                id="steam-key"
                type="text"
                label="Stream Key"
                value={streamkey}
                onChange={(e) => setStreamkey(e.target.value)}
                disabled={streamSourceType === STREAM_TYPE_OPTIONS[1]}
                placeholder=""
                copyToClipboard={streamSourceType === STREAM_TYPE_OPTIONS[1]}
              />
            </div>
          </div>
        }

        <div className="col-md-6">
          <div className="mb-2">

            {/* <DateTimePicker
                label="Start Time"
                id="start-time"
                value={streamstarttime}
                inputProps={{ placeholder: 'Start Time' }}
                onChange={(newDate) => setFirstPublicDateValue(newDate)}
                errorMessage={streamstarttimeErrors}
              /> */}

            <Input
              id="start-time"
              type="datetime-local"
              label="Start Time *"
              value={streamstarttime}
              onChange={(e) => setFirstPublicDateValue(e.target.value)}
              disabled={false}
              placeholder=""
              errorMessage={streamstarttimeErrors}
              min={minDateTime}
            />
            {/* minDateTime--{minDateTime} */}
          </div>
        </div>

        <div className="col-md-6">
              {/* <DateTimePicker
                id="end-time"
                label="End Time"
                value={streamendtime}
                inputProps={{ placeholder: 'End Time' }}
                onChange={(newDate) => setPublicDateValue(newDate)}
                errorMessage={streamendtimeErrors}
              /> */}

          <Input
            id="end-time"
            type="datetime-local"
            label="End Time *"
            value={streamendtime}
            onChange={(e) => setPublicDateValue(e.target.value)}
            disabled={false}
            placeholder=""
            errorMessage={streamendtimeErrors}
            min={minDateTime}
          />
        </div>
      </div>
    </div>
  );
};

export default StreamVideoFormFields;
