import { createContext } from "react";

export const defaultAuthState = {
  loading: true,
  user: undefined,
  isLoggedIn: false
};

const AuthContext = createContext(defaultAuthState);

export default AuthContext;
