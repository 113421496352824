import React, { useEffect, useState } from "react";
// import { Sidebar } from "../../molecules/Sidebar";
// import { Page } from "../../layouts/Page";
// import { Link } from "react-router-dom";
// import { useHistory } from "react-router-dom";
// import { isURL } from "validator";
// import AddWoocommerceKeyNav from "./AddWoocommerceKeyNav";
import ImportWoocommerceProductNav from "./ImportWoocommerceProductNav";

function WoocommerceSetup({ onCompleteCallback }) {
    // const history = useHistory();
    const [keysStored, setkeysStored] = useState(true);
    const [user, setUserData] = useState([]);

    useEffect(() => {
        fetchVideosData();
       
    }, []);

    
    const fetchVideosData = () => {
        let token =localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL+'/check-user-woocommerce-keys',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer '+token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
          })
            .then((response) => response.json())
            .then((data) => {
                setkeysStored(false)
                // setVideotdata(data.data)
                // setLoader(true)
                if(data.code==200){
                    setUserData(data.data)
                }else{

                }
         
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };
    
    
    return (
        <>
        {(keysStored)?<><div class="card-body"><div class="text-center"><div class="spinner-border" role="status"></div></div></div></>:<ImportWoocommerceProductNav propValue={user} onCompleteCallback={onCompleteCallback} />}
        {/* {(keysStored)?<ImportShopifyProductNav propValue={user}/> :<AddShopifyKeyNav/>} */}
        </>
    );
}
export default WoocommerceSetup;