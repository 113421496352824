import React, { useEffect, useState } from 'react';
import { Page } from "../../layouts/Page/";
import { useHistory } from "react-router-dom";
import { Sidebar } from "../../../component/molecules/Sidebar";
import IncompleteDashboard from "./IncompleteDashboard";
import StatsDashboard from "./StatsDashboard";
import { isMobile, isOnboardCompleted } from "../../helpers/helper";
import ScreenLoading from '../Loadings/ScreenLoading';
import VideoPurpose from '../Onboarding/Video/VideoPurpose';
import Button from '../../Form/Button';


function Dashboard() {
  const isMobileSize = isMobile();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [videoData, setVideoData] = useState(false);
  const [videoPurpose, setVideoPurpose] = useState("");
  const [packageDaysLeft, setPackageDaysLeft] = useState("");

  useEffect(() => {
    fetchVideoData();
  }, []);

  const fetchVideoData = async () => {
    try {
      var token = localStorage.getItem("authSession")
      token = JSON.parse(token);
      await fetch(process.env.REACT_APP_API_URL + '/user-videos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token.accessToken,
        },
        body: JSON.stringify({ "id": token.idToken })
      })
        .then((response) => response.json())
        .then((data) => {

          setVideoData(data.data.length > 0 ? true : false)
          // localStorage.setItem("is_onboarding_complete",data.data.length>0?true:false);
          setLoading(false)
        })
    } catch (error) {
      window.localStorage.removeItem("authSession");
      history.push("/")
    }
  };

  if (loading) {
    return <ScreenLoading />
  }

  const onNavBarClick = () => {
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }

  return (
    <div
      className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`}
      id="main-wrapper"
      data-layout="vertical"
      data-sidebartype={sidebarType}
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} videoData={videoData} />
      <Page title="Dashboard" onNavBarClick={onNavBarClick} setPackageDaysLeft={setPackageDaysLeft}>
   
        {isOnboardCompleted() === false ? <VideoPurpose videoPurpose={videoPurpose} setVideoPurpose={setVideoPurpose} /> : <StatsDashboard packageDaysLeft={packageDaysLeft}/>}
        {/* {isOnboardCompleted() === false ? <IncompleteDashboard /> : <StatsDashboard />} */}
      </Page>

      {/* sticky footer */}
      {!isOnboardCompleted() && (
      <div className="sticky-footer ">
          <div className="footer-container">
            <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
              <Button
                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                type="button"
                onClick={() => {
                  const addVideoData = {
                    videoType: videoPurpose,
                  }
              
                  history.push({
                    pathname: '/add-video',
                    state: {
                      addVideoData: addVideoData
                    },
                  });
                }}
                buttonText="Next"
                fullButton={false}
                loading={false}
                disabled={!videoPurpose}
              />
            </div>
          </div>
        </div>
      )}
      {/* sticky footer */}
    </div>
  );
}

export default Dashboard;
