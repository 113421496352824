import React, { useState, useEffect, useRef } from "react";
import { useChangeProfile } from "./hook";
import { userLoggedAccessToken } from "../../../helpers/helper";
import ColorField from "../../../Form/ColorField";
import Button from "../../../Form/Button";

const Theme = () => {
    const containerRef = useRef(null);
    const [profile, setProfile] = useState();
    const [fontColorShow, setFontColorShow] = useState(false);
    const [backgroundColorShow, setBackgroundColorShow] = useState(false);
    const [buttonColorShow, setButtonColorShow] = useState(false);
    const [buttonTextColorShow, setButtonTextColorShow] = useState(false);

    const hasElementAncestor = (element, className) => {
        let currentElement = element;

        while (currentElement) {
            if (currentElement.classList.contains(className)) {
                return true;
            }

            currentElement = currentElement.parentElement;
        }

        return false;
    }

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/get-profile', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userLoggedAccessToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response?.status) {
                    setProfile(response.data)
                }
            });

    }, []);

    const closeAllPickers = () => {
        setFontColorShow(false);
        setBackgroundColorShow(false);
        setButtonColorShow(false);
        setButtonTextColorShow(false);
    }

    useEffect(() => {
        function handleClickOutside(event) {

            // Ignore initial states
            if (event && hasElementAncestor(event.target, 'vs-color-picker')) {
                return
            }

            if (event && !hasElementAncestor(event.target, 'sketch-picker')) {
                closeAllPickers()
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const {
        fontColor,
        backgroundColor,
        buttonColor,
        buttonTextColor,
        setFontColor,
        setBackgroundColor,
        setButtonColor,
        setButtonTextColor,
        onThemeSaveChanges,
        isSaving,
        success,
        error
    } = useChangeProfile({ profile });

    // const onNavBarClick = () => {
    //     setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    // }

    // const themeTagLine = showUserTypeBaseMessage({ 'Brand': 'Choose a Theme for Your Account', 'default': 'Choose a Theme for Your Account' });


    return (
        <div className="container col-lg-6 col-md-12 col-xs-12">
            <section className="central-form">

                {success && <div className="alert alert-success" role="alert"><strong>Success: </strong> {success} </div>}
                {error && <div className="alert alert-danger" role="alert"><strong>Error: </strong> {error} </div>}
                <form className="form">
                    <div className="mt-4" ref={containerRef}>

                        <div className="row">
                            <div className="col-12 mb-4">
                                <ColorField label="Font Color:" defaultValue={fontColor} show={fontColorShow} onShowChange={(e) => {
                                    closeAllPickers();
                                    setFontColorShow(e);
                                }} onChange={e => setFontColor(e?.hex)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <ColorField label="Background Color:" defaultValue={backgroundColor} show={backgroundColorShow} onShowChange={(e) => {
                                    closeAllPickers();
                                    setBackgroundColorShow(e);
                                }} onChange={e => setBackgroundColor(e?.hex)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <ColorField label="Button Color:" defaultValue={buttonColor} show={buttonColorShow} onShowChange={(e) => {
                                    closeAllPickers();
                                    setButtonColorShow(e);
                                }} onChange={e => setButtonColor(e?.hex)} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <ColorField label="Button Text Color:" defaultValue={buttonTextColor} show={buttonTextColorShow} onShowChange={(e) => {
                                    closeAllPickers();
                                    setButtonTextColorShow(e);
                                }} onChange={e => setButtonTextColor(e?.hex)} />
                            </div>
                        </div>
                    </div>
                    <div className="form-actions submit">
                        <Button
                            type="submit"
                            buttonText="Save"
                            fullButton={false}
                            loading={isSaving}
                            onClick={onThemeSaveChanges}
                        />
                    </div>
                </form>

            </section>
        </div>

    )
}

export default Theme;
