import React from "react";
import { useHistory } from "react-router-dom";
import {showUserTypeBaseMessage} from "../../helpers/helper";


const boxTitle = showUserTypeBaseMessage(
  {
    'Brand': 'Generate Embed Code', 
    'default':'Create Integration Code',
    'NonProfit':'Embed Code Generator',
    'Influencer':'Embed Code Generator',
    'Individual':'Embed Code Generator',
  }
);

const buttonText = showUserTypeBaseMessage(
  {
    'Brand': 'Embed Code Generator', 
    'default':'Create Integration Code',
    'NonProfit':'Embed Code Generator',
    'Influencer':'Embed Code Generator',
    'Individual':'Embed Code Generator',
  }
);

const createPara = showUserTypeBaseMessage(
  {
    'Brand': 'Generate a responsive embed code to add videos to your website.', 
    'default':'Create a widget to integrate a video gallery on your website.',
    'NonProfit':'Generate a responsive embed code to add videos to your website.',
    'Influencer':'Generate a responsive embed code to add videos to your website.',
    'Individual':'Generate a responsive embed code to add videos to your website.',
  }
);


// const boxTitle =showUserTypeBaseMessage({Brand: 'Generate Embed Code', default:'Create Integration Code'});
// const buttonText =showUserTypeBaseMessage({Brand: 'Embed Code Generator', default:'Create Integration Code'});
// const createPara =showUserTypeBaseMessage({Brand: 'Generate a responsive embed code to add videos to your website.', default:'Create a widget to integrate a video gallery on your website.'});










function AddingDataPrompt({heading,title,link,btntxt}) {
  const history = useHistory();
  return (

<div className="row">
  <div class="col-12">
    <div class="card w-100 ">
      <div class="card-body" >
        <div class="mb-4">
          <h5 class="mb-0">{heading}</h5>
        </div>
        <p class="mb-3 card-subtitle">{title}</p>
        <div class="row justify-content-center">
          <div class="col-md-12 d-grid gap-2">
            <button type="button" className="btn btn-primary text-white" onClick={() => history.push(link)}>
              {btntxt}
            </button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  );
}

export default AddingDataPrompt;
