const RadioButtonRow = ({
    label,
    name,
    id,
    value,
    disabled,
    checked,
    onChange
}) => {
    return (
        <div className="form-check form-check-inline">
            <input
                className="form-check-input"
                type="radio"
                name={name}
                id={id}
                value={value}
                disabled={disabled}
                checked={checked}
                onChange={onChange}
            />
            <label className="form-check-label text-black fw-semibold fw-3" htmlFor={id}>{label}</label>
        </div>
    )
}

export default RadioButtonRow;