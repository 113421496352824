import React, { useState } from "react";

const DropDown = ({ id, label, options, value, onChange, disabled, errorMessage, copyToClipboard, ...props }) => {
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    return (
        <div className="form-group position-relative">
            <label htmlFor={id} className="form-label">{label}</label>
            <div className="position-relative">
                <select
                    className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                    id={id}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    {...props}
                    
                >
                     <option value="">Select an option</option>
                    {options.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
            {errorMessage && <div><span className="text-danger fs-2 lh-1">{errorMessage}</span></div>}
        </div>
    );
}

export default DropDown;
