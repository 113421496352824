import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ActivityLog from "../../helpers/ActivityLog";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import RoundtickIcon from "./../../../assets/icons/roundtick.svg"

import Input from "./../../Form/Input";
import Button from "./../../Form/Button";

import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";

const Statuses = {
    VERIFY: "verify",
    VERIFIED: "verified",
    SYNCING: "product-syncing",
    DONE: "done"
}

function ImportWoocommerceProductNav(props) {
    const isMobileSize = isMobile();
    const userdata = props.propValue;
    const [storeurl, setStoreurl] = useState(userdata ? userdata?.store_url : "")
    const [consumerkey, setConsumerkey] = useState(userdata ? userdata?.consumer_key : "");
    const [consumersecret, setConsumersecret] = useState(userdata ? userdata?.consumer_secret : "");
    const [storeurlError, setStoreurlError] = useState('');
    const [consumerkeyError, setConsumerkeyError] = useState('');
    const [consumersecretError, setConsumersecretError] = useState('');
    const [urlErrors, setUrlErrors] = useState(false);
    const history = useHistory();
    // const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [importProductLoader, setImportProductLoader] = useState(false);
    const [producterrors, setProductErrors] = useState('');
    const [productsuccess, setProductSuccess] = useState('');
    const [status, setStatus] = useState("verify");

    const [loader, setLoader] = useState(false);


    const handleUrlChange = (e) => {
        var value = e.target.value;
        var rule = "url";
        setStoreurl(value)
        validateField(value, rule, setStoreurlError);
    }

    const handleConsumerkeyChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setConsumerkey(value);
        validateField(value, rule, setConsumerkeyError);

    };

    const handleConsumersecretChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setConsumersecret(value);
        validateField(value, rule, setConsumersecretError);

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm(storeurl, consumerkey, consumersecret);
    };

    const resetState = () => {
        setShowErrors(false);
        setErrorMessage('');
    };

    const submitForm = (storeurl, consumerkey, consumersecret) => {

        setLoader(true)

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-woocommerce-keys', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "store_url": storeurl,
                    "consumer_key": consumerkey,
                    "consumer_secret": consumersecret,

                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 14;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            setLoader(false)

                            setStatus("verified")


                            // history.push({
                            //     pathname: '/woocommerce-product-sync',
                            //     state: { statsData: json.data }
                            // });

                        }
                        else {

                            
                            setLoader(false)

                            setShowErrors(true);
                            setErrorMessage(json.message);
                            setTimeout(resetState, 2000);


                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {

                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };


    const isButtonDisabled = urlErrors || !consumerkey || !consumersecret || storeurlError || consumerkeyError || consumersecretError;


    // const tagLine = showUserTypeBaseMessage({
    //     'Brand': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    //     'default': '',
    //     'NonProfit': 'Empower Your Donors by Providing an Ultimate Shoppable Video Experience',
    //     'Influencer': 'Empower Your Viewers by Providing an Ultimate Shoppable Video Experience',
    //     'Individual': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    // });


    const SyncProduct = (e) => {
        e.preventDefault();
        submitProductForm(storeurl, consumerkey, consumersecret);
    };

    const submitProductForm = (storeurl, consumerkey, consumersecret) => {

        setStatus("product-syncing")
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-variant-woocommerce-product-sync', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "store_url": storeurl,
                    "consumer_key": consumerkey,
                    "consumer_secret": consumersecret,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 16;
                            var account_id = token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })
                            setStatus("done")
                            setProductSuccess(json.message)

                            props.onCompleteCallback?.()
                        }
                        else {
                            // setShowErrors(true);
                            // setErrorMessage(json.message);
                            setProductErrors(json.message)
                            // setTimeout(resetState, 2000);
                            // history_push_link = "/add-product";
                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {
                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };

    const SeeProducts = (e) => {

        e.preventDefault();
        history.push("/products");


    };



    return (
        <div className="container col-lg-6 col-md-12 col-xs-12 import-shopify">
            <section className="import-shopify-integration">
                {/* {(showErrors || errorMessage) ? <div className="alert alert-danger" role="alert"><strong>Error </strong> {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."} </div> : ""} */}

                {errorMessage && (
                                <FormToast
                                    type={FORM_TOAST_TYPES.DANGER}
                                    message={errorMessage}
                                />
                                )}


                {status === Statuses.VERIFY && (

                <div className="mt-4">
                    <form className="form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    {/* <label htmlFor="wfirstName2">Store URL <span className="danger">*</span>
                                                </label>
                                                <input type="text" placeholder="https://example.com" className="form-control required" id="wfirstName2" name="storeid" value={storeurl} onChange={handleUrlChange} />
                                                {(storeurlError?<small className="text-danger">{storeurlError}</small>:"")} */}
                                    <Input
                                        id="store-url"
                                        type="text"
                                        label="Store URL *"
                                        value={storeurl}
                                        onChange={(e) => handleUrlChange(e)}
                                        disabled={false}
                                        placeholder="https://example.com"
                                        errorMessage={storeurlError}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    {/* <label htmlFor="wfirstName2">Consumer Key<span className="danger">*</span>
                                                </label>
                                                <input type="text" placeholder="ck_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" className="form-control required" id="wfirstName2" name="consumerkey" value={consumerkey} onChange={handleConsumerkeyChange} />
                                                {(consumerkeyError?<small className="text-danger">{consumerkeyError}</small>:"")} */}
                                    <Input
                                        id="consumer-key"
                                        type="text"
                                        label="Consumer Key *"
                                        value={consumerkey}
                                        onChange={(e) => handleConsumerkeyChange(e)}
                                        disabled={false}
                                        placeholder="ck_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                        errorMessage={consumerkeyError}
                                    />
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    {/* <label htmlFor="wfirstName2">Consumer Secret:<span className="danger">*</span>
                                                </label>
                                                <input type="text" placeholder="cs_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" className="form-control required" id="wfirstName2" name="consumersecret" value={consumersecret} onChange={handleConsumersecretChange} />
                                                {(consumersecretError?<small className="text-danger">{consumersecretError}</small>:"")} */}
                                    <Input
                                        id="consumer-secret"
                                        type="text"
                                        label="Consumer Secret *"
                                        value={consumersecret}
                                        onChange={(e) => handleConsumersecretChange(e)}
                                        disabled={false}
                                        placeholder="cs_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                        errorMessage={consumersecretError}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="vs-form-footer d-flex justify-content-center align-items-center">
                            <Button
                                type="submit"
                                buttonText="Next"
                                fullButton={false}
                                loading={loader}
                                onClick={handleSubmit}
                                disabled={isButtonDisabled}
                            />
                        </div>
                    </form>
                    </div>)}

                {/* adding all the components  */}

                {status === Statuses.VERIFIED && (
                    <div className="row">
                        <section>
                            <div class="card-body pt-5 pb-5">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <img src={RoundtickIcon} width="75" height="75"></img>
                                    <br />
                                    <h2 className="text-center mb-4 text-verfied">Credentials Verified</h2>

                                    {/* <button type="submit" onClick={SyncProduct} class="btn mb-1 btn-primary btn-lg px-4 w-10 text-white">Sync Products</button> */}

                                    <Button
                                        type="submit"
                                        buttonText="Sync Products"
                                        fullButton={false}
                                        loading={false}
                                        onClick={SyncProduct}
                                        disabled={false}
                                    />

                                </div>
                            </div>
                        </section>
                    </div>
                )}

                {status === Statuses.SYNCING && (

                    <div className="row">
                        <section>
                            <div class="card-body pt-5 pb-5">
                                <div class="card-body">
                                    <p className="card-subtitle-verified-cred mb-3 text-black fw-bolder">Product Syncing</p>
                                    <div class="card-body">
                                        <div class="text-center">
                                            <div class="spinner-border" role="status">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                )}


                {status === Statuses.DONE && (

                    <div className="row">
                        <section>
                            <div class="card-body pt-5 pb-5">
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    <img src={RoundtickIcon} width="75" height="75"></img>
                                    <br />
                                    <h2 className="text-center mb-4 text-verfied">{productsuccess}</h2>
                                    {/* <button type="submit" onClick={SyncProduct} class="btn mb-1 btn-primary btn-lg px-4 w-10 text-white">See Products</button> */}
                                    <Button
                                        type="submit"
                                        buttonText="See Products"
                                        fullButton={false}
                                        loading={false}
                                        onClick={SeeProducts}
                                        disabled={false}
                                    />


                                </div>
                            </div>
                        </section>
                    </div>


                )}


            </section>
        </div>
    );
}
export default ImportWoocommerceProductNav;