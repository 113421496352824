import React from "react";
import { Link } from "react-router-dom";
import styles from "../Login/LoginForm.module.scss";
import Button from "./../../Form/Button";
import { useHistory } from "react-router-dom";
import FormToast, { TYPES } from "./../../Form/FormToast";


function ForgotPasswordForm({
  email,
  error,
  success,
  disabled,
  onEmailChange,
  onSubmit,
  loader
}) {
  const history = useHistory();
  return (
    <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
      <div class="col-sm-7 col-md-7 col-xl-7">
        <h2 className="mb-3 fs-7 fw-bold">Forgot your password?</h2>

        <p class="mb-3">
          We all forget our passwords. Just enter in your account’s email address and we’ll have you back up and running in no time.
        </p>
        <form className={styles.register} onSubmit={onSubmit}>
          {error && (

            <FormToast
              type={TYPES.DANGER}
              message={error.message}
            />
            

            // <Callout
            //   intent={Intent.WARNING}
            //   title={error.title}
            //   style={{ marginBottom: "1em" }}
            //   icon={null}
            // >
            //   {error.message}
            // </Callout>
          )}

          {success && (
            <FormToast
              type={TYPES.SUCCESS}
              message="We've sent you an email with a link to reset your password."
            />
            // <Callout intent={Intent.SUCCESS} title="Check your email!">
            //   We've sent you an email with a link to reset your password.
            // </Callout>
          )}



          {/* {success && (
            <>
            {window.location.reload()}
            </>
          )} */}


          {(!success)?<>
            <div class="mb-3">
              <label for="forgot-email-input" class="form-label">Email address *</label>
              <input
                className="form-control"
                aria-describedby="emailHelp"
                id="forgot-email-input"
                type="email"
                value={email}
                onChange={onEmailChange} />
            </div>
            <div className="">
            <Button
              type="submit"
              buttonText="Send email"
              fullButton={true}
              loading={loader}
              disabled={disabled}
            />
          </div>

          </>:""}



             


         
        </form>
        <div className="">
            <a
              className={"btn w-100 mb-1 py-3 waves-effect waves-light btn-outline-primary"}
              buttonText="Go Back"
              onClick={(e) => {
                history.push(history.push("/login"));
              }}
            >Go Back</a>
          </div>
      </div>
    </div>

  );
}
<a class="btn btn-light-primary text-primary w-100 py-8 btn-hover-bg" href="/login">Go Back</a>
export default ForgotPasswordForm;
