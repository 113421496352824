import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { userLoggedAccessToken, loggedUserEmail, isTypeNullOrNotFilled, isMobile } from "../../helpers/helper";
import RadioButtonRow from "../../Form/RadioButtonRow";
import Button from "../../Form/Button";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import PhoneInputField from "../../Form/PhoneInputField";

function SelectAccountType() {
    const isMobileSize = isMobile();
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [userType, setUserType] = useState(false);

    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState();
    
    const [phoneNumber, setPhoneNumber] = useState();
    const [countryCode, setCountryCode] = useState();
    const [countryDiallingCode, setCountryDiallingCode] = useState();
    const [countryId, setCountryId] = useState();

    useEffect(() => {
        if (isTypeNullOrNotFilled() == false) {
            history.push("/dashboard");
        }
    }, []);

    const handleSubmit = async () => {

        
        setLoading(true);

        await fetch(process.env.REACT_APP_API_URL + '/user-update-type', {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + userLoggedAccessToken(),
            },
            body: JSON.stringify({ userType: userType,country_code:countryCode,country_dialling_code:countryDiallingCode,phoneNumber:phoneNumber })
        }).then(e => e.json()).then(() => {

            localStorage.setItem('accounTypeAdded', loggedUserEmail());
            localStorage.setItem('accounTypeAddedValue', userType);

            setTimeout(function () {
                history.push("/dashboard");
            }, 3000);

        }).catch(() => setErrorMessage('Something went wrong')).finally(() => {
            setLoading(false);
        });
    };

    const isButtonDisabled = !userType;
    
    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
            {errorMessage && (
                <FormToast
                    type={FORM_TOAST_TYPES.DANGER}
                    message={errorMessage}
                />
            )}

            <Sidebar onNavBarClick={onNavBarClick} />
            <Page
                title="Add Video"
                onNavBarClick={onNavBarClick}
                formWidth={true}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">
                                <h5 className="fw-bolder">Select Account Type</h5>
                            </div>
                            <div className="card-body">
                                <div className="w-20 d-flex flex-column gap-9 auto-margin mt-6 mb-6">
                                    <RadioButtonRow
                                        label="Brand"
                                        name="user_type"
                                        id="user_type_brand"
                                        value="Brand"
                                        disabled={false}
                                        checked={userType === "Brand" ? true : false}
                                        onChange={(e) => setUserType(e.target.value)}
                                    />
                                    <RadioButtonRow
                                        label="Influencer"
                                        name="user_type"
                                        id="user_type_influencer"
                                        value="Influencer"
                                        disabled={false}
                                        checked={userType === "Influencer" ? true : false}
                                        onChange={(e) => setUserType(e.target.value)}
                                    />
                                    <RadioButtonRow
                                        label="Individual"
                                        name="user_type"
                                        id="user_type_individual"
                                        value="Individual"
                                        disabled={false}
                                        checked={userType === "Individual" ? true : false}
                                        onChange={(e) => setUserType(e.target.value)}
                                    />
                                    <RadioButtonRow
                                        label="Non Profit"
                                        name="user_type"
                                        id="user_type_non"
                                        value="Non Profit"
                                        disabled={false}
                                        checked={userType === "Non Profit" ? true : false}
                                        onChange={(e) => setUserType(e.target.value)}
                                    />
                                </div>

                               

                                <div className="w-20 d-flex flex-column auto-margin mt-6 mb-6">
                        
                                <label className="form-check-label text-black fw-semibold fw-3">Enter your phone number</label>
                                <PhoneInputField
                                    id="phone-number"
                                    label="Phone Number"
                                    errorMessage={isPhoneNumberValid === false ? "Invalid phone number" : ""}
                                    valid={isPhoneNumberValid}
                                    setValid={setIsPhoneNumberValid}
                                    onChange={(e) => {
                                    setCountryDiallingCode(e?.dialCode)
                                    setCountryCode(e?.country)
                                    setPhoneNumber(e?.phone)
                                    }}
                                />
                                </div>


                                <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                    <Button
                                        type="button"
                                        onClick={handleSubmit}
                                        buttonText="Confirm"
                                        fullButton={false}
                                        loading={loading}
                                        disabled={isButtonDisabled || loading|| !isPhoneNumberValid}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        </div>
    )
}
export default SelectAccountType;