import React, { useEffect, useState } from 'react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from 'validator';
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from '../../helpers/helper';
import { urlSegment } from "../../helpers/helper";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import FieldWarning from '../../Form/FieldWarning';
import Input from "./../../Form/Input";
import ToggleSwitchRow from "./../../Form/ToggleSwitchRow";
import DropDown from "./../../Form/DropDown";
import Button from "./../../Form/Button";
import TrashPinkIcon from "./../../../assets/icons/trash-pink.svg"
import PageHeading from "./../../Form/PageHeading";



const AddLeadForm = () => {
    const history = useHistory();
    const [name, setName] = useState('');
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [urlErrors, setUrlErrors] = useState([]);
    const [urlproblemError, setProblemErrorErrors] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [optionErrors, setOptionErrors] = useState([]);
    const [leadName, setLeadName] = useState();
    const [leadNameError, setLeadNameError] = useState();
    const [loaderSubmit, setloaderSubmit] = useState(false);


    useEffect(() => {
        fetchLeadData();

    }, []);

    const fetchLeadData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        var formid = urlSegment(1);


        fetch(process.env.REACT_APP_API_URL + '/find-lead-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken, "form_id": formid }),
        })
            .then((response) => response.json())
            .then((data) => {


                setFields(data.data)
                setLeadName(data.title)

            })
            .catch((error) => {
                // console.error('Error fetching data:', error);
            });
    };

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    const [fields, setFields] = useState([]);


    function validateFields(fields) {
        let hasError = false;
        for (let field of fields) {
            // if (field.isChecked) {
            // Check if the 'name' field or any required field is empty
            if (!field.name || field.name.trim() === "") {
                setProblemErrorErrors(true);
                break;
            } else {
                setProblemErrorErrors(false)
            }
            // }
        }
    }


    const addFieldOption = (index) => {



        const updatedFields = [...fields];
        var newValue = { "name": "", "error": "" };
        updatedFields[index]["child"].push(newValue);

        setFields(updatedFields);

        setProblemErrorErrors(true)

    };

    const addField = () => {

        if (fields.length <= 4) {
            setFields([...fields, { name: '', type: 'Text', isChecked: false, fieldvalue: null, child: [] }]);
            setErrors('')
            setProblemErrorErrors(true)

        }else{

            setErrors('Only 5 fields are allowed')


        }

      



    };

    const removeField = (index) => {

        const updatedFields = [...fields];

        validateFields(updatedFields);

        if (updatedFields.length == 1) {

            setProblemErrorErrors(true)
            setErrors("Atlest one Field is required")

        } else {
            setErrors('')
            // setProblemErrorErrors(false)
            updatedFields.splice(index, 1);

        }

        setFields(updatedFields);
    };




    const handleFieldChange = (index, event) => {

        const updatedFields = [...fields];



        if (event.target.name === 'isChecked') {
            updatedFields[index].isChecked = event.target.checked;
        } else if (event.target.id == "input-type") {
            updatedFields[index]["type"] = event.target.value;
            if (event.target.value == "Dropdown") {
                addFieldOption(index);
            }
        }
        else if (event.target.id == "input-name") {

            if (event.target.value === null || event.target.value === undefined || event.target.value === "") {
                urlErrors[index] = 'Please enter value';
                setProblemErrorErrors(true)

            } else {
                urlErrors[index] = null;
                setProblemErrorErrors(false)
            }
            updatedFields[index]["name"] = event.target.value;


        }

        validateFields(updatedFields);

        setFields(updatedFields);

    };

    const handleFieldOptionChange = (index1, index, event) => {

        const updatedFields = [...fields];

        if (event.target.value === null || event.target.value === undefined || event.target.value === "") {

            // optionErrors[index] = 'Please enter value';

            updatedFields[index]["child"][index1]["error"] = 'Please enter value';

            setProblemErrorErrors(true)

        } else {

            updatedFields[index]["child"][index1]["error"] = null;

            setProblemErrorErrors(false)

        }

        updatedFields[index]["child"][index1]["name"] = event.target.value;
        setFields(updatedFields);



    };

    const removeOptionField = (index1, index) => {

        const updatedFields = [...fields];
        updatedFields[index]["child"].splice(index1, 1);
        setFields(updatedFields);

    };

    const handleNameChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setLeadName(value);
        validateField(value, rule, setLeadNameError);

    };



    const isButtonDisabled = urlproblemError || !leadName || !(fields.length !== 0);;

    const handleSubmit = (e) => {
        setShowPopup(true);
        // e.preventDefault()
        // submitForm();
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };


    const handleDelete = () => {
        submitForm();
        setShowPopup(false);
    };

    const submitForm = () => {

        setloaderSubmit(true)

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        var formid = urlSegment(1);

        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/edit-lead-form', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "fields": fields,
                    "form_id": formid,
                    "title": leadName,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json?.code?.toString() === "200") {

                            var token = localStorage.getItem("authSession")
                            token = JSON.parse(token);

                            const userLog = new ActivityLog();
                            var user_id = token.idToken;
                            var is_admin = 0;
                            var event_id = 28;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            setSuccess("lead form updated")
                            setloaderSubmit(false)

                        } else {

                            setErrors("Something went wrong")
                            setloaderSubmit(false)

                        }
                    });
                }
            }).
                catch((error) => {

                    console.log('error>>>', error)
                });
        });
    };

    const Popup = ({ onClose, onDelete }) => {
        return (
            <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block", " padding-left": "0px" }} aria-modal="true" role="dialog">

                <div class="modal-dialog modal-sm" >
                    <div class="modal-content modal-filled bg-light-danger">
                        <div class="modal-body p-4">
                            <div class="text-center text-danger">
                                <i class="ti ti-x fs-7" onClick={onClose}></i>
                                <h4 class="mt-2">Are You sure?</h4>
                                <p class="mt-3">
                                    You want to update form this may cause permanent lead data loss?
                                </p>
                                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        );
    };


    const backToMainVideoStep = () => {
        return history.push({
            pathname: "/lead-form-report",
            state: history.location.state,
        });
    };





    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} data-sidebartype={sidebarType} id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title="Edit Lead Form" formWidth={true}>
                <PageHeading text="Edit Lead Form" />
                <div className='mt-4'></div>
                <div className="row add-form">
                    <section>
                        <div className="card">
                            <div className="card-body wizard-content">

                                {showPopup ?
                                    <Popup
                                        onClose={handleClosePopup}
                                        onDelete={handleDelete}
                                    /> : ""}

                                {/* {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
                                {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""} */}

                                {errors && (
                                    <FormToast
                                        type={FORM_TOAST_TYPES.DANGER}
                                        message={errors}
                                    />
                                )}


                                {success && (
                                    <FormToast
                                        type={FORM_TOAST_TYPES.SUCCESS}
                                        message={success}
                                    />
                                )}
                                <div>

                                    <form>

                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <Input
                                                        id="store-url"
                                                        type="text"
                                                        label="Lead Form Title *"
                                                        value={leadName}
                                                        onChange={(e) => handleNameChange(e)}
                                                        disabled={false}
                                                        placeholder="Enter Form Title"
                                                        errorMessage={leadNameError}
                                                    />
                                                </div>
                                            </div>
                                            <div className="border-bottom black mb-3 pb-3"></div>
                                        </div>



                                        {fields.map((field, index) => (

                                            <div key={index}>
                                                <div class="row">
                                                    <div className="mt-4"></div>
                                                    <div class="col-md-4">
                                                        <div class="mb-3">
                                                            <Input
                                                                type="text"
                                                                id="input-name"
                                                                label="Field Name "
                                                                value={field.name}
                                                                onChange={(e) => handleFieldChange(index, e)}
                                                                disabled={false}
                                                                placeholder="Enter Form Title"
                                                                errorMessage={false}
                                                            />
                                                            {(urlErrors[index]?.length >= 1) && <small className="text-danger">This field is required</small>}
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="mb-3">
                                                            <DropDown
                                                                id="input-type"
                                                                label="Field Type"
                                                                options={[
                                                                    { value: 'Text', label: 'Text' },
                                                                    { value: 'Number', label: 'Number' },
                                                                    { value: 'Email', label: 'Email' },
                                                                    { value: 'Phone', label: 'Phone' },
                                                                    { value: 'Textarea', label: 'Textarea' },
                                                                    { value: 'Dropdown', label: 'Dropdown' },
                                                                ]}
                                                                value={field.type}
                                                                onChange={(e) => handleFieldChange(index, e)}
                                                                errorMessage={false}
                                                            />
                                                        </div>
                                                    </div>


                                                    <div class="col-md-2 leadform">
                                                        <ToggleSwitchRow
                                                            id={`demo${index}`}
                                                            name="isChecked"
                                                            label="Required"
                                                            // value={field.isChecked}
                                                            checked={field.isChecked}
                                                            onChange={(e) => handleFieldChange(index, e)}
                                                            disabled={false}
                                                            width="40px"
                                                            height="20px"
                                                        />
                                                    </div>

                                                    {(index == '0') ? "" : <div className="col-md-2 mb-3 mt-3 leadform" style={{ height: 70 }}>
                                                        <div className="d-flex justify-content-space-between">
                                                            <button type="button" onClick={() => removeField(index)} className="btn btn-lead-form">
                                                                <img src={TrashPinkIcon} width="20" height="20"></img>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    }


                                                    {(field.type == 'Dropdown') ?
                                                        <>
                                                            {field?.child?.map((fieldOption, index1) => (

                                                                <div key={index1}>

                                                                    <div class="row">
                                                                        <div class="col-md-4"></div>
                                                                        <div class="col-md-4">
                                                                            <div class="mb-3">
                                                                                <Input
                                                                                    id="store-url"
                                                                                    type="Field Option"
                                                                                    label="Field Option *"
                                                                                    value={fieldOption.name}
                                                                                    onChange={(e) => handleFieldOptionChange(index1, index, e)}
                                                                                    disabled={false}
                                                                                    placeholder=""
                                                                                    errorMessage={fieldOption.error}
                                                                                />
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-md-1 leadform">
                                                                            <img style={{ margin: '9px 0 0 -15px' }} type="button" onClick={() => removeOptionField(index1, index)} src={TrashPinkIcon} width="20" height="20"></img>

                                                                        </div>
                                                                        {
                                                                            (index1 == field?.child?.length - 1) &&
                                                                            <div className="row">
                                                                                <div className='col-md-4'></div>
                                                                                <div className='col-md-4'>
                                                                                    <button type="button" onClick={() => addFieldOption(index)} className="btn mb-1 waves-effect waves-light btn-rounded btn-outline-primary" role="menuitem">
                                                                                        Add Option
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        }

                                                                    </div>



                                                                </div>



                                                            ))}




                                                        </>
                                                        : ""

                                                    }

                                                </div>




                                            </div>
                                        ))}




                                    </form>

                                    {/* <div className="mt-4"></div>
                                    <div className="d-flex justify-content-start mb-3">
                                        <button type="button" onClick={addField} className="btn mb-1 waves-effect waves-light btn-rounded btn-outline-primary" role="menuitem">
                                            Add Field
                                        </button>
                                    </div> */}

                                    <div className="vs-lead-form-secondary-row d-flex align-items-center mb-4">
                                        <span className="text-pink" role="button" onClick={addField}>
                                            <span className="fs-5 mr-5">+</span>
                                            <span className="fs-3">Add Field</span>
                                        </span>
                                    </div>
                                    <FieldWarning message="You can add 1 more field" />

                                    {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                        <Button
                                            type="submit"
                                            buttonText="Submit"
                                            fullButton={false}
                                            loading={loaderSubmit}
                                            onClick={handleSubmit}
                                            disabled={isButtonDisabled}
                                        />
                                    </div> */}

                                    <div className="sticky-footer ">
                                        <div className="footer-container">
                                            <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                                                <Button
                                                    className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                                                    type="button"
                                                    buttonText={"Back"}
                                                    onClick={() => { backToMainVideoStep() }}
                                                />
                                                <Button
                                                    className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                                                    type="button"
                                                    onClick={handleSubmit}
                                                    buttonText="Submit"
                                                    loading={loaderSubmit}
                                                    disabled={isButtonDisabled}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </section>
                </div>

            </Page>
        </div>
    );
}
export default AddLeadForm;