import React, { useState, useEffect } from "react";
import { useHistory,Route, Link } from "react-router-dom";
import DataTableExtensions from "react-data-table-component-extensions";
import FormHeading from "../../../../atoms/FormHeading/FormHeading";
import DataTable from "react-data-table-component";
import { userLoggedAccessToken, formatNumber } from '../../../helpers/helper';
import ExpandOrderCommissionView from "./ExpandOrderCommissionView";
import Loading from "./../../../../component/Pages/Loadings/Loading"
const moment = require("moment");


export default function BillingInvoices() {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [expandViewType, setExpandViewType] = useState();


  useEffect(() => {
    fetchInvoicesData();
  }, []);

  const closeFromChild = () => setCurrentRow(null);

  const toggleExpandView = (row, expandType) => {
    if (row?.id === currentRow?.id && expandType != expandViewType) {
      setCurrentRow(row);
    } else {
      setCurrentRow(currentRow === row ? null : row);
    }
    setExpandViewType(expandType);
  };

  const fetchInvoicesData = () => {
    setLoading(true);

    fetch(process.env.REACT_APP_API_URL + '/invoices', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + userLoggedAccessToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => setData(data.data))
      .catch((error) => console.error('Error fetching data:', error))
      .finally(() => setLoading(false));
  };

  const columns = [
    {
      name: "Amount",
      selector: (row, i) => row.amount,
      sortable: false,
      cell: (row) => (
        <span>{formatNumber(row.amount)}</span>
      ),
    },
    {
      name: "Type",
      selector: (row, i) => row.created_at,
      sortable: false,
      cell: (row) => {
        let packageTypeReturn = 'CORE (Package)';
        if (row.subscribed_addon_id) {
          packageTypeReturn = 'ADD-ON (Package)';
        } else if (row.commission_deduction_id) {
          packageTypeReturn = 'Commission Deduction';
        } else if (row.order_id) {
          packageTypeReturn = 'Order';
        }

        return (<span>{packageTypeReturn}</span>);
      },
    },
    {
      name: "Dated",
      selector: (row, i) => row.created_at,
      sortable: false,
      cell: (row) => (
        <span>{moment(row.created_at).format("MMMM Do YYYY")}</span>
      ),
    },
    {
      name: "Payment Status",
      selector: (row, i) => row.invoice_url,
      sortable: false,
      cell: (row) => {
        let statusColorClass = '';
        let statusText = row.status;
        switch (row.status) {
          case 'success':
          case 'succeeded':
            statusColorClass = 'text-success fw-semibold';
            statusText = 'PAID';
            break;
          default:
            statusColorClass = ''; // Default class for other statuses
        }
        return <span className={statusColorClass}>{statusText}</span>;
      },
    },
    {
      name: "Action",
      selector: (row, i) => row.invoice_url,
      sortable: false,
      width: '190px',
      cell: (row) => {

        if (row.commission_deduction_id) {
          return (
            <div className="action-btn tbl-action-buttons">
              {row.status == 'succeeded' && (
                <a href="javascript:void(0)" onClick={(e) => toggleExpandView(row, 1)} className={"text-dark ms-2"}>
                  <i className="ti ti-eye-check fs-7" />
                </a>
              )}

              {row.status == 'Failed' && (
                // <button type="button" onClick={(e)=> toggleExpandView(row,1)} className="btn rounded-pill waves-effect waves-light btn-primary">Pay Now</button>
                <Link to={`/settings/order-commission/${row.id}`} className="btn rounded-pill waves-effect waves-light btn-primary">Pay Now</Link>
              )}
            </div>
          )
          // packageTypeReturn = 'Commission Deduction Report';
        } else {
          return (
            <div className="action-btn tbl-action-buttons">
              <a target="_blank" className={"text-dark ms-2"} href={row.invoice_url}><i class="ti ti-download fs-5"></i></a>
            </div>
          )
        }
      },
      maxWidth: "100px"
    },
  ]

  const tableData = {
    columns,
    data: data
  };

  if (loading) {
    return <Loading />
  }

  if (data.length === 0) return null;

  return (
    <div>
      <FormHeading weight="600" size={16}>
        Invoice History
      </FormHeading>

      <DataTableExtensions {...tableData} export={false} print={false}>
        <DataTable
          columns={columns}
          data={data}
          className="hide-expandable-icons-row"
          noHeader
          defaultSortField="id"
          defaultSortAsc={true}
          pagination
          highlightOnHover
          dense
          expandableRows
          expandableRowsComponent={(props) => (
            expandViewType === 1 ? <ExpandOrderCommissionView {...props} closeFromChild={closeFromChild} /> : <></>
          )}
          expandableRowExpanded={(row) => (row === currentRow)}
        />
      </DataTableExtensions>
    </div>
  );
}
