import React, { useEffect, useState } from 'react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from 'validator';
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from '../../helpers/helper';

import Input from "./../../Form/Input";
import Select from "./../../Form/Select";
import CheckBoxRow from "./../../Form/CheckBoxRow";
import PhoneInputField from "./../../Form/PhoneInputField";
import Warning from "./../../Form/Warning";
import { Intents } from "../../../Enums/Intents";
import Button from "./../../Form/Button";
import DropDown from "./../../Form/DropDown";
import Textarea from "./../../Form/Textarea";
import EyeIcon from "./../../../assets/icons/eye.svg"
import ArrowLeft from "./../../../assets/icons/arrow-left.svg"
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";



function NewAddLead() {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [videodata, setVideotdata] = useState([]);
    const [loader, setLoader] = useState(false);
    const [product, setProduct] = useState('');
    const [video, setvideo] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [datetime, setDatetime] = useState('');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loaderSubmit, setloaderSubmit] = useState(false);
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [productError, setProductError] = useState('');
    const [videoError, setvideoError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [submitError, setSubmitError] = useState(false);
    const [leadform, setleadform] = useState([]);
    const [fields, setFields] = useState([]);
    const [fielderror, setFieldError] = useState(true);

    const [fieldloader, setFieldLoader] = useState(false);
    const [formId, setFormId] = useState('');
    const [theFormId, setTheFormId] = useState('');

    const FIELD_TYPES = {
        TEXT: "Text",
        NUMBER: "Number",
        EMAIL: "Email",
        PHONE: "Phone",
        TEXTAREA: "Textarea",
        DROPDOWN: "Dropdown"
    }

    const handleNameChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setName(value);
        validateField(value, rule, setNameError);

    };

    const handleEmailChange = (e) => {
        var value = e.target.value;
        var rule = "email";
        setEmail(value);
        validateField(value, rule, setEmailError);

    };

    const handlePhoneChange = (e) => {
        var value = e.target.value;
        var rule = "phone";

        setPhone(value);
        validateField(value, rule, setPhoneError);

    };

    const validateForm = () => {

        const updatedFields = fields.map((item) => {

            if (item.is_required) {
                if ([FIELD_TYPES.TEXT, FIELD_TYPES.TEXTAREA].indexOf(item.type) !== -1) {
                    if (!item.value) {
                        return { ...item, error: true, error_message: `Please write ${item.name}` };
                    }
                }
                if (item.type === FIELD_TYPES.NUMBER) {
                    const numericRegex = /^[0-9]+$/;
                    if (!numericRegex.test(item.value)) {
                        return { ...item, error: true, error_message: `Please write valid ${item.name}` };
                    }
                }
                if (item.type === FIELD_TYPES.EMAIL) {
                    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
                    if (!emailRegex.test(item.value)) {
                        return { ...item, error: true, error_message: `Please write valid ${item.name}` };
                    }
                }
                if (item.type === FIELD_TYPES.PHONE) {
                    if (!item.value || item.value.length > 12 || item.value.length < 8) {
                        return { ...item, error: true, error_message: `Please write valid ${item.name}` };
                    }
                }
            }


            return { ...item, error: false, error_message: "" }
        })
        setFields(updatedFields);
        return updatedFields;

    };

    const handleSubmit = (e) => {
        e.preventDefault()
        submitForm(fields);
    };


    useEffect(() => {

        fetchVideosData();
        // fetchLeadData()

    }, []);

    useEffect(() => {

        fetchLeadData()

    }, [formId]);

    useEffect(() => {

        let path = window.location.pathname;
        var confirmcode = path.split("/");
        var formid = confirmcode[2];
    
        setTheFormId(formid);

    }, []);



    const fetchLeadData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        if(formId){

            setFieldLoader(true)

        fetch(process.env.REACT_APP_API_URL + '/find-all-lead-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken,"form_id":formId}),
        })
            .then((response) => response.json())
            .then((data) => {

                // setFields(data.data)
                // setleadform(data.data)
                var lead_form = data.data;
                var new_lead_form = lead_form.map(e => ({
                    ...e,
                    value: "",
                    error: false,
                    error_message: ""
                }))
                setFields(new_lead_form)
                //   console.log(new_lead_form,"najum")

                // setFormId(data.form_id)

                setFieldLoader(false)

            })
            .catch((error) => {
                // console.error('Error fetching data:', error);
            });

        }
    };

    const fetchProductData = (id) => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/video-product-id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "videoid": id }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                const dataArray = data.data;
                const dropdownOptions = dataArray.map(item => ({
                    value: item.product_id.toString(), // assuming 'id' is unique and can be used as a value
                    label: item.product_name // assuming 'name' can be used as a label
                }));

                setData(dropdownOptions)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    const fetchFormData = (id) => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/video-lead-form-id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "videoid": id }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {

                var formdata=data.data
                setFormId(formdata[0].parent_lead_form_id)
                
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchVideosData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/lead-videos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {

                const dataArray = data.data;
                const dropdownOptions = dataArray.map(item => ({
                    value: item.id.toString(), // assuming 'id' is unique and can be used as a value
                    label: item.name // assuming 'name' can be used as a label
                }));

                setVideotdata(dropdownOptions)


                // setVideotdata(data.data)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const resetState = () => {
        setErrors('');
        setSuccess('');
    };

    const handleProductChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        // setProduct(value);
        // validateField(value, rule, setProductError);

        if (value == '') {
            setProduct(value);
            validateField(value, rule, setProductError);
        } else {
            setProduct(value);
            validateField(value, rule, setProductError);

        }

    };

    const handleVdeoChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        e.preventDefault();
        if (value == '') {

            setvideo(e.target.value)
            validateField(value, rule, setvideoError);

        } else {

            setvideo(e.target.value)
            fetchFormData(e.target.value);
            fetchProductData(e.target.value);
            validateField(value, rule, setvideoError);

        }
    };


    const submitForm = (updatedFields) => {


        const userLog = new ActivityLog();

        setloaderSubmit(true)
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-dashboard-lead', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "user_id": token.idToken,
                    // "product": product,
                    "video": video,
                    "data": updatedFields,
                    "source": "dashboard",
                    "form_id":formId
                    
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json?.code?.toString() === "200") {

                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 5;
                            var account_id = token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            setSuccess("Lead Added succesfully")

                            setTimeout(() => {
                                resetState()
                                history.push(`/leads/${formId}`)
                            }, 2000);

                        
                            setloaderSubmit(false)
                        } else {
                            // history.push("/leads")
                            setloaderSubmit(false)
                        }
                    });
                }
            }).
                catch((error) => {
                    setloaderSubmit(false)
                    console.log('error>>>', error)
                });
        });
        return fetch_prods;
    };


    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }




    // const isButtonDisabled = !product.trim() || !video.trim() || productError || videoError || fielderror;

    // const isButtonDisabled = !product.trim() || !video.trim() || productError || videoError || fielderror;

    const isButtonDisabled = !video.trim() || videoError || fielderror;

    const onFieldChange = (id, value, type, is_required) => {

        if (type === FIELD_TYPES.EMAIL) {
            const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;


            if (!emailRegex.test(value)) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;

            } else {

                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }


        }

        if (type === FIELD_TYPES.NUMBER) {
            const numericRegex = /^[0-9]+$/;
            if (!numericRegex.test(value)) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;
            } else {

                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }
        }

        if (type === FIELD_TYPES.PHONE) {
            if (!value || value.length > 12 || value.length < 8) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;
            } else {

                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }
        }

        if ([FIELD_TYPES.TEXT, FIELD_TYPES.TEXTAREA].indexOf(type) !== -1) {

            if (is_required === true) {

                if (!value) {
                    const objectToUpdate = fields.find(itemv => itemv.id === id);
                    objectToUpdate.error = true;
                    objectToUpdate.error_message = `Please write valid ${type}`;
                } else {

                    const objectToUpdate = fields.find(itemv => itemv.id === id);
                    objectToUpdate.error = false;
                    objectToUpdate.error_message = "";

                }

            } else {


                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";


            }

        }

        if (type === FIELD_TYPES.DROPDOWN) {
          
            if (!value) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;
            } else {

                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }
        }


        const updatedFields = fields.map((item) =>
            item.id === id ? { ...item, value } : item
        )

        setFields(updatedFields);

        const totalErrors = updatedFields.filter(item => item.error).length;
        const totalRequiredFields = updatedFields.filter(item => item.is_required).length;
        const totalFilledFields = updatedFields.filter(item => item.value).length;
        const canSubmit = totalErrors === 0 && totalFilledFields >= totalRequiredFields;

        setFieldError(!canSubmit);

     

    }


    const addLeadTagLine = showUserTypeBaseMessage({
        'Brand': `Entice Viewers and Drive Leads, Simplify the Shopping Journey`, 
        'default':``,
        'NonProfit':`Inspire Donors, Streamline Your Fundraising Journey`,
        'Influencer':`Entice Viewers and Drive Leads, Simplify the Shopping Journey`,
        'Individual':`Entice Viewers and Drive Leads, Simplify the Shopping Journey`,
    });


    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} data-sidebartype={sidebarType} id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title="Add Video" formWidth={true}>
        
               <div className="row adding-lead">
                    <section>

                    <div className="card header">
                            <div className="card-body">
                                <a  className="icon-image"><img src={ArrowLeft} width="20" height="20" onClick={() => history.push(`/leads/${theFormId}`)}></img></a>
                                <h4 className="text-black fs-6 fw-semibold">Add Lead</h4>
                            </div>
                        </div>



                        <div className="card form" >
                            <div class="card-body pt-4 pb-5">
                                <div className="card-body add-lead">

                                {/* {(errors) ? <div className="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""} */}
                                {/* {(success) ? <div className="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""} */}


                                {errors && (
                                <FormToast
                                    type={FORM_TOAST_TYPES.DANGER}
                                    message={errors}
                                />
                                )}


                                {success && (
                                <FormToast
                                    type={FORM_TOAST_TYPES.SUCCESS}
                                    message={success}
                                />
                                )}


                                <form className="validation-wizard wizard-circle mt-5 lead clearfix">
                                    <div className="content clearfix">
                                          <div className="mb-3 row">
                                            <div class="col-12">
                                                <div class="mb-3">
                                                    <DropDown
                                                            id="dropdownExample"
                                                            label="Select Video:*"
                                                            options={videodata}
                                                            value={video}
                                                            onChange={(e) => handleVdeoChange(e)}
                                                            errorMessage={videoError}
                                                        />
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="mb-3 row">
                                            <div class="col-12">
                                                <div class="mb-3">
                                              <DropDown
                                                            id="dropdownExample"
                                                            label="Select Product:*"
                                                            options={data}
                                                            value={product}
                                                            onChange={(e) => handleProductChange(e)}
                                                            errorMessage={productError}
                                                        />
                                                </div>
                                            </div>
                                        </div> */}

      
    
                                {fieldloader?<div class="d-flex justify-content-center">
                                    <div class="spinner-border" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                    </div>
                                </div>:<>



                                        {fields.map(f => {
                                            if (f.type === FIELD_TYPES.TEXTAREA) {
                                                return (

                                                    
                                                    // <div className="mb-3 row" key={`field-${f.id}`}>
                                                    //     <div className="col-12">
                                                    //         <div className="mb-3">
                                                    //             <label htmlFor={`field-${f.id}`}>{f.name}: {f.is_required && <span className="danger">*</span>}</label>
                                                    //                <textarea
                                                    //                     placeholder={`Enter ${f.name}`}
                                                    //                     className="form-control"
                                                    //                     type={"text"}
                                                    //                     id={`field-${f.id}`}
                                                    //                     name={f.name} onChange={(e) => onFieldChange(f.id, e.target.value, f.type, f.is_required)}
                                                    //                 ></textarea>
                                                    //             <p>{f.error_message}</p>
                                                    //             {(f.error ? <small className="text-danger">{f.error_message}</small> : "")}
                                                    //         </div>
                                                    //     </div>
                                                    // </div>

                                                    <div className="mb-3 row" key={`field-${f.id}`}>
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <Textarea
                                                                id={`field-${f.id}`}
                                                                type="text"
                                                                label={<>{f.name} {f.is_required && <span className="danger">*</span>}</>}
                                                                onChange={(e) => onFieldChange(f.id, e.target.value, f.type, f.is_required)}
                                                                disabled={false}
                                                                placeholder={f.name}
                                                                errorMessage={f.error_message}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                );
                                            } else if (f.type === FIELD_TYPES.DROPDOWN && f.fieldvalue?.split(',')) {

                                                const options = f.fieldvalue.split(',').map(item => ({
                                                    value: item.trim(),
                                                    label: item.trim()
                                                }));

                                                return (

                                                   

                                                    <div className="mb-3 row" key={`field-${f.id}`}>
                                                            <div className="col-12">
                                                                <div className="mb-3">
                                                                    <DropDown
                                                                        id={`field-${f.id}`}
                                                                        label={<>{f.name}:{f.is_required && <span className="danger">*</span>}</>}
                                                                        options={options}
                                                                        // value={video}
                                                                        onChange={(e) => onFieldChange(f.id, e.target.value, f.type, f.is_required)}
                                                                        errorMessage={f.error_message}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                )


                                            } return (
                                                

                                                <div className="mb-3 row" key={`field-${f.id}`}>
                                                <div className="col-12">
                                                    <div className="mb-3">
                                                        <Input
                                                            id={`field-${f.id}`}
                                                            type={f.type === FIELD_TYPES.PHONE ? "number" : f.type}
                                                            label={<>{f.name} {f.is_required && <span className="danger">*</span>}</>}
                                                            onChange={(e) => onFieldChange(f.id, e.target.value, f.type, f.is_required)}
                                                            disabled={false}
                                                            placeholder={f.name}
                                                            errorMessage={f.error_message}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            )

                                        })}

                                  



                        
                              

                                        <div className="actions clearfix">
                                            <ul role="menu" aria-label="Pagination">
                                                <li aria-hidden="false" aria-disabled="false">
                                                 

                                                    <div className="form-actions lead-button">
                                                    <Button
                                                        type="submit"
                                                        buttonText="Submit"
                                                        fullButton={false}
                                                        loading={loaderSubmit}
                                                        onClick={handleSubmit}
                                                        disabled={isButtonDisabled}
                                                    />
                                                    </div>

                                                </li>
                                            </ul>
                                        </div>


                                        </> }
                                        </div>
                                </form>
                                </div>
                            </div>
                        </div>

                    </section>
                </div>
               
            </Page>
        </div>
    );
}
export default NewAddLead;