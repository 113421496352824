import React, { useState, useEffect } from "react";
import {
  userLoggedAccessToken,
  showUserTypeBaseMessage,
} from "../../helpers/helper";
import DataTable from "react-data-table-component";
// import { truncateText } from "../../helpers/helper";
import "./ExpandableVideosDetails.css";
// import CopyToClipboard from "../../Form/CopyToClipboard";
import placeholder from "../../../assets/thumbnail.jpg";
import Loading from "../../Pages/Loadings/Loading";
import Input from "../../Form/Input";
import ExpandableVideosDetailsLeadsFormDetails from "./ExpandableVideosDetailsLeadsFormDetails";
const moment = require("moment");




const ExpandableVideosDetails = (data) => {

  data = data?.data;

  const [productsData, setProductsData] = useState([]);
  const [videoStreamData, setVideoStreamData] = useState(null);
  const [dataLoader, setDataLoader] = useState(false);
  const [videoTypeText, setVideoTypeText] = useState(false);

  const titleHeadingText = showUserTypeBaseMessage({
    NonProfit: "Donation Campaign",
    default: "Product Name",
  });

  useEffect(() => {
    fetchProductData(data?.id);

    if (data && data.stream_detail !== null) {

      switch (data.stream_detail) {
        case "live-stream-from-browser":
          setVideoTypeText("Live Stream From Browser");
          break;
        case "live-stream-from-external-source":
          setVideoTypeText("External Live Stream");
          break;
        case "record-video":
          setVideoTypeText("Record Video");
          break;
        case "upload-video":
          setVideoTypeText("VOD");
          break;
        case "facebook-integration":
          setVideoTypeText("Facebook Integration");
          break;
        default:
          setVideoTypeText(data.stream_detail);
      }


    }
    else{

        setVideoTypeText("VOD");

      }



  }, [data]);

  const fetchProductData = (videoId) => {
    setDataLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/find-product-by-video-id", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ video_id: videoId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setProductsData(data?.data);
        setVideoStreamData(data?.video_stream);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setDataLoader(false);
      });
  };

  const columns = [
    {
      name: titleHeadingText,
      cell: (row) => {
        const imageSrc = row.images?.[0]?.url ? row.images?.[0]?.url : placeholder;

        return (
          <div className="d-flex flex-row align-items-center gap-2">
            <img
              src={imageSrc}
              className="rounded-2 border border-2 border-white"
              width={51}
              height={51}
              alt={row.name}
            />
            <div className="d-flex flex-column gap-1">
              <span className="fw-semibold text-black fs-3 text-wrap">{row.name}</span>
            </div>
          </div>
        )
      },
      sortable: false,
    },
    // {
    //   name: "Price",
    //   selector: (row, i) => row.price,
    //   sortable: false,
    //   center: true,
    //   width: "110px",
    // },
    // {
    //   name: "Images",
    //   cell: (row) => (
    //     <ul className="hstack mb-0">
    //       {row.images.slice(0, 3).map((imagesData, index) => (
    //         <li className="ms-n8" key={index}>
    //           <img
    //             src={imagesData.url}
    //             className="rounded-circle border border-2 border-white"
    //             width={44}
    //             height={44}
    //             alt=""
    //           />
    //         </li>
    //       ))}
    //     </ul>
    //   ),
    //   // width: "100px", // Adjust the width accordingly
    //   sortable: false,
    // },
    {
      name: "Categories",
      selector: (row, i) => {
        const categoryNames = Object.values(row.categories)
          .map((category) => category.name)
          .filter((name) => name); // Filter out falsy values
        return categoryNames.length > 0 ? categoryNames.join(", ") : "none";
      },
      sortable: false,
      center: true,
      // width: "200px",
    },
    {
      name: "Checkout Type",
      selector: (row) => {
        if (row.use_collect_info === "yes") {
          return "Collect Information";
        } else if (row.use_in_video_checkout === "yes") {
          return "In-Video Checkout";
        } else if (row.use_shopify_checkout === "yes") {
          return "Shopify Checkout";
        }else if (row.use_woo_commerce_checkout === "yes") {
          return "Woo Commerce Checkout";
        }else {
          return ""; // Default value if none of the conditions are met
        }
      },
      sortable: false,
      center: true,
    },
    // {
    //   name: "Date",
    //   selector: (row, i) => row.created_at,
    //   sortable: false,
    //   center: true,
    //   width: "200px",
    // },
  ];

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#ffffff !important",
      },
    },
    rows: {
      style: {
        minHeight: "70px !important",
        backgroundColor: "#F9F9F9",
        borderBottom: "none !important",
        borderTop: "none !important",
        marginBottom: "10px !important",
        borderRadius: 16,
      },
    },
  };

  if (dataLoader) {
    return (
      <div className="py-10">
        <Loading />
      </div>
    );
  }



  const GetVideoThumbnail = (data) => {
    let iconClass = '';

    if (['live-stream-from-external-source', 'live-stream-from-browser'].indexOf(data.stream_detail) !== -1) {
      iconClass = 'ti ti-cast';
    } else if (data.stream_detail === 'facebook-integration') {
      iconClass = 'ti ti-brand-facebook';
    }

    if (iconClass) {
      return <i className={iconClass}></i>
    }

    return (
      <img className="rounded-2" src={data?.video_thumbnail ? data?.video_thumbnail : placeholder} alt={data?.videoname} />
    )
  }

  return (
    <>

    
      <div className="mt-2 mb-3 d-flex align-items-center gap-4">
        <div className="rounded-2 border border-2 border-white d-flex justify-content-center align-items-center video-image-modal">
          {GetVideoThumbnail(data)}
        </div>
        <div className="d-flex flex-column align-items-start">
          <h4 className="text-black">{data?.videoname}</h4>
          <span
            className={`mb-1 badge font-medium text-success d-flex align-items-center ${data.status === "Published" ? "bg-status-light-success" : (data.status === "Draft" || data.status === "Disabled") ? "bg-status-light-warning" : ""}`}
            title={data.status}
          >
            {data.status}
          </span>
        </div>
      </div>

      <div className="col-12 mb-3 d-flex justify-content-center">
        <div className="video-list-detail-box-area col-lg-12 col-sm-12 col-xs-12">
          <div className="bg-baby-blue1 video-list-detail-box d-flex flex-column align-items-start py-2">
            <span className="fw-bolder text-black">{videoTypeText}</span>
            <span>Video Type</span>
          </div>
          <div className="checkout-type bg-misty-rose video-list-detail-box d-flex flex-column align-items-start py-2">
            <span className="fw-bolder text-black">
              {moment(
                data?.schedule_start_time != null
                  ? data?.schedule_start_time
                  : data?.video_created_at,
                "YYYY-MM-DD HH:mm:ssZ"
              ).format("MMMM D, YYYY, h:mm A")}
            </span>
            <span>Publish Time</span>
          </div>
          <div className="checkout-type bg-platinum video-list-detail-box d-flex flex-column align-items-start py-2">
            <span className="fw-bolder text-black">{data?.video_type}</span>
            <span>Purpose</span>
          </div>





          {data?.end_time != null && data?.start_time != null && (
            <>
              <div
                className="stream-start-time bg-melon video-list-detail-box d-flex flex-column align-items-start py-2"
                title={data?.start_time}
              >
                <span className="fw-bolder text-black">
                  {moment(data?.start_time, "YYYY-MM-DD HH:mm:ssZ").format(
                    "hh:mm A"
                  )}
                </span>
                <span>Stream Start Time</span>
              </div>
              <div
                className="stream-end-time bg-baby-blue video-list-detail-box d-flex flex-column align-items-start py-2"
                title={data?.end_time}
              >
                <span className="fw-bolder text-black">
                  {moment(data?.end_time, "YYYY-MM-DD HH:mm:ssZ").format(
                    "hh:mm A"
                  )}
                </span>
                <span>Stream End Time</span>
              </div>
            </>
          )}
        </div>
      </div>

   



      {(data?.stream_detail === "live-stream-from-external-source") && (videoStreamData?.key !== "" && videoStreamData?.key !== null) && (
        <div className="row">
          <div className="col-md-6">
            <Input
              id="server-url"
              type="text"
              label="Server URL:"
              value={`rtmp://streaming.videosquirrel.app:8090/live/${videoStreamData?.key}`}
              onChange={() => { }}
              disabled={true}
              placeholder=""
              copyToClipboard={true}
            />
          </div>
          <div className="col-md-6">
            <Input
              id="stream-key"
              type="text"
              label="Stream Key:"
              value={videoStreamData?.key}
              onChange={() => { }}
              disabled={true}
              placeholder=""
              copyToClipboard={true}
            />
          </div>
        </div>
      )}

      {data?.video_type == 'To generate lead' && !dataLoader &&  (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <ExpandableVideosDetailsLeadsFormDetails data={data} />
          </div>

      )}




    {data?.video_type != 'To generate lead' &&  (


      <div className="">
        {!dataLoader && (
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <DataTable
              columns={columns}
              data={productsData}
              selectableRows={false}
              customStyles={customStyles}
              
            />
          </div>
        )}
      </div>
    )}

    </>
  );
};
export default ExpandableVideosDetails;
