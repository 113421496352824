import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import bigTickIcon from "../../../../src/assets/icons/big-tick.svg";
import {
  showNumberOrInfinity,
  videoResolutions,
  userLoggedAccessToken,
  getQueryParam,
} from "../../helpers/helper";
const moment = require("moment");

const RightCheckHTML = () => {
  return (<img className="right-check" src={bigTickIcon} alt="" />);
};

const isAssignedCurrentPackage = (packageData, assignedUserPackage) => {
  if (packageData && assignedUserPackage) {
    if (
      packageData.hasOwnProperty("id") &&
      assignedUserPackage.hasOwnProperty("id")
    ) {

      const isPackageExpired = moment(
        assignedUserPackage.current_period_end
      ).isBefore(moment());

      if (
        packageData.plan_type === "paid" &&
        assignedUserPackage.plan_type === "paid" &&
        isPackageExpired === true
      ) {
        return false; // expired package can be re-subscribed!
      }

      if (
        packageData.plan_type === "free" &&
        assignedUserPackage.plan_type === "paid"
      ) {
        return true; // paid user cannot subscribe free package.
      }

      if (
        assignedUserPackage.status === "Cancelled" &&
        assignedUserPackage.plan_type !== "free"
      ) {
        return false; // show subscribe button on paid package if already cancelled!
      }

      if (packageData.id === assignedUserPackage.subscription_plan) {
        return true;
      }
    }
  } else {
    // user cannot subscribe free package if already used free or paid package!
    if (packageData?.plan_type === "free") {
      return true;
    }
    //expired package
  }
  return false;
}

const PlanTitleRow = ({ plan, userSubscribedData }) => {
  const { nickname, plan_type, amount, plan_key } = plan;

  return (
    <div className="package-title-name-tagline-subscribe-btn">
      <div className="package-title-name-tagline">
        <h5 className="card-title packag-title-name">{nickname}</h5>
        <small className="packag-subline">
          {plan_type === "free" ? "(No Credit Card Required)" : "($" + amount + "/Month)"}
        </small>
      </div>

      <div className="subscribe-btn">
        {!isAssignedCurrentPackage(plan, userSubscribedData) && plan_type !== "free" && (
          <div className="text-center">
            <Link
              to={
                "/settings/subscription/upgrade-plan/" +
                plan_key + (getQueryParam("redirect") ? `?redirect=${getQueryParam("redirect")}` : "")
              }
              className="btn btn-primary mt-4 px-4 py-2"
            >
              Subscribe
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

const PlanItemRow = ({ text }) => {
  return (
    <div className="py-1">
      <div className="ms-3">
        <h6 className="mb-0 fw-semibold"> <RightCheckHTML /> {text}</h6>
      </div>
    </div>
  )
}

export default function PackagesListing({ userSubscribedData, headingText }) {
  const [utmSource, setUtmSource] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchPackagesPlans();
    setUtmSource(localStorage.getItem("utm_source"));
  }, []);

  const fetchPackagesPlans = () => {
    setLoading(true);

    fetch(process.env.REACT_APP_API_URL + "/subscriptions-plans", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({})
    })
      .then((response) => response.json())
      .then((data) => setData(data?.data?.filter((plan) =>
        utmSource === "marketingweb"
          ? plan.plan_type !== "free"
            ? true
            : false
          : true
      )))
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoading(false));
  };

  return (
    <>
      {headingText && <h4 className="fw-bolder mb-3">{headingText}</h4>}
      <div className="row justify-content-center plan-packages">
        {loading && (
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <div
                className="spinner-border spinner-border-sm spinner-border-sm"
                role="status"
              ></div>
            </div>
          </div>
        )}

        {(!loading && data.length > 0) && (
          data.map((plan, index) => (
            <div className="col-md-6 col-sm-12" key={index}>
              <div className="card w-100">
                <div className="card-body py-3 position-relative row">
                  {/* {userSubscribedData?.subscription_plan ==
                    plan?.id && (
                      <div className="selected-plan-tag position-absolute top-40 end-0">
                        <div className="bg-primary text-white p-2">
                          {moment(
                            userSubscribedData?.current_period_end
                          ).isBefore(moment()) == true && userSubscribedData?.plan_type != "free"
                            ? "EXPIRED"
                            : "SELECTED"}
                        </div>
                      </div>
                    )} */}

                  <PlanTitleRow plan={plan} userSubscribedData={userSubscribedData} />

                  {plan?.plan_type === "free" ? (
                    <>
                      <div className="d-flex align-items-center package-parameter">
                        <div className="ms-3">
                          <h6 className="mb-0 fw-semibold">No Prepayment - No Credit Card required</h6>
                        </div>
                      </div>

                      <h5 className="mt-4 mb-2 ms-3"><b>Dashboard Features</b></h5>
                      <PlanItemRow text={`Upload ${showNumberOrInfinity(plan.product_limit)} Products (Active Products)`} />

                      {plan.import_products_from_shopify && (
                        <PlanItemRow text={`Import products from Shopify`} />
                      )}

                      {plan.import_products_from_woocommerce && (
                        <PlanItemRow text={`Import products from WooCommerce`} />
                      )}

                      <PlanItemRow text={`Upload ${showNumberOrInfinity(plan.video_limit)} Videos (Active Videos)`} />
                      <PlanItemRow text={`Facebook Video Embed (Unlimited time)`} />
                      <PlanItemRow text={`Record Video from Browser`} />
                      <PlanItemRow text={`Maximum Video length/time ${showNumberOrInfinity(plan.video_record_max_length)} minute ${plan.video_record_max_length > 1 ? "s" : ""}`} />
                      <PlanItemRow text={`Simple Lead Management`} />
                      <PlanItemRow text={`Simple Order Management`} />
                      <PlanItemRow text={`Video Embed on websites`} />
                      <PlanItemRow text={`Shopify Plugin for Video Integration`} />
                      <PlanItemRow text={`Video Analytics (video views)`} />

                      <h5 className="mt-4 mb-2 ms-3"><b>Video Player</b></h5>
                      <PlanItemRow text={`Video Quality ${videoResolutions(plan.video_quality)?.short_name === "HD" ? "Standard/HD" : videoResolutions(plan.video_quality)?.short_name}`} />
                      <PlanItemRow text={`Total Watch Time: ${showNumberOrInfinity(plan.watch_time)} Minutes/Month`} />

                      {plan.product_limit_under_single_video && (
                        <PlanItemRow text={`Multiple Products Under one Video`} />
                      )}

                      <PlanItemRow text={`External Product Checkout`} />
                      <PlanItemRow text={`External Donation Checkout`} />
                      <PlanItemRow text={`External Lead through Videos`} />
                      <PlanItemRow text={`Product Order Form`} />
                      <PlanItemRow text={`Shopify Add to Cart + WooCommerce Add to Cart`} />
                    </>
                  ) : (
                    <>
                      <h5 className="mt-4 mb-2 ms-3"><b>Dashboard Features</b></h5>
                      <PlanItemRow text={`Upload ${showNumberOrInfinity(plan.product_limit)} Products (Active Products)`} />

                      {plan.import_products_from_shopify && (
                        <PlanItemRow text={`Import products from Shopify`} />
                      )}

                      {plan.import_products_from_woocommerce && (
                        <PlanItemRow text={`Import products from WooCommerce`} />
                      )}

                      <PlanItemRow text={`Upload ${showNumberOrInfinity(plan.video_limit)} Videos (Active Videos)`} />
                      <PlanItemRow text={`Facebook Video Embed (Unlimited time)`} />
                      <PlanItemRow text={`Record Video from Browser`} />
                      <PlanItemRow text={`Maximum Video length/time (${showNumberOrInfinity(plan.video_record_max_length)} minute${plan.video_record_max_length > 1 ? "s" : ""})`} />
                      <PlanItemRow text={`Simple Lead Management`} />
                      <PlanItemRow text={`Simple Order Management`} />
                      <PlanItemRow text={`Video Embed on websites`} />
                      <PlanItemRow text={`Shopify Plugin for Video Integration`} />
                      <PlanItemRow text={`Video Analytics (video views)`} />

                      <h5 className="mt-4 mb-2 ms-3"><b>Video Player</b></h5>
                      <PlanItemRow text={`Video Quality ${plan.video_quality} (${videoResolutions(plan.video_quality)?.short_name === "FHD" ? "Standard/HD/FHD" : videoResolutions(plan.video_quality)?.short_name})`} />
                      <PlanItemRow text={`Total Watch Time: ${showNumberOrInfinity(plan.watch_time)} Minutes/Month`} />

                      {plan.product_limit_under_single_video && (
                        <PlanItemRow text={`Multiple Products Under one Video`} />
                      )}

                      <PlanItemRow text={`External Product Checkout`} />
                      <PlanItemRow text={`External Donation Checkout`} />
                      <PlanItemRow text={`External Lead through Videos`} />
                      <PlanItemRow text={`Product Order Form`} />
                      <PlanItemRow text={`Shopify Add to Cart + WooCommerce Add to Cart`} />

                      <h5 className="mt-4 mb-2 ms-3"><b>Premium Features</b></h5>
                      <PlanItemRow text={`In Video Checkout for Products`} />
                      <PlanItemRow text={`In Video Donation Collection`} />
                      <PlanItemRow text={`Live Stream from External Source with VOD (${showNumberOrInfinity(plan.live_stream_from_external_minutes)} Min/Month)`} />

                      {plan.live_stream_from_video_squirrel && (
                        <PlanItemRow text={`Live Stream from Video Squirrel with VOD (${showNumberOrInfinity(plan.live_stream_from_external_minutes)} Min/Month)`} />
                      )}

                      <PlanItemRow text={`Stripe, Authorize.net and PayPal integrations for In Video Checkout`} />
                    </>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}
