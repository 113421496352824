import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import ShopifyProductSyncNav from "../../Pages/Products/ShopifyProductSyncNav";
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/product.png";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import RoundtickIcon from "./../../../assets/icons/roundtick.svg"

import Input from "./../../Form/Input";
import Select from "./../../Form/Select";
import CheckBoxRow from "./../../Form/CheckBoxRow";
import PhoneInputField from "./../../Form/PhoneInputField";
import Warning from "./../../Form/Warning";
import { Intents } from "../../../Enums/Intents";
import Button from "./../../Form/Button";
import { VideoSteps } from "./Video/AddVideo";

const Statuses = {
    VERIFY: "verify",
    VERIFIED: "verified",
    SYNCING: "product-syncing",
    DONE: "done"
}

function ImportWoocommerceOnboardProduct(props) {
    const isMobileSize = isMobile();
    const [keysStored, setkeysStored] = useState(true);
    const [storeurl, setStoreurl] = useState("")
    const [consumerkey, setConsumerkey] = useState("");
    const [consumersecret, setConsumersecret] = useState("");
    const [storeurlError, setStoreurlError] = useState('');
    const [consumerkeyError, setConsumerkeyError] = useState('');
    const [consumersecretError, setConsumersecretError] = useState('');
    const [urlErrors, setUrlErrors] = useState(false);
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [importProductLoader, setImportProductLoader] = useState(false);
    const [producterrors, setProductErrors] = useState('');
    const [productsuccess, setProductSuccess] = useState('');
    const [status, setStatus] = useState("verify");


    const handleUrlChange = (e) => {
        var value = e.target.value;
        var rule = "url";
        setStoreurl(value)
        validateField(value, rule, setStoreurlError);
    }

    const handleConsumerkeyChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setConsumerkey(value);
        validateField(value, rule, setConsumerkeyError);

    };

    const handleConsumersecretChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setConsumersecret(value);
        validateField(value, rule, setConsumersecretError);

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm(storeurl, consumerkey, consumersecret);
    };

    const resetState = () => {
        setShowErrors(false);
        setErrorMessage('');
    };

    const submitForm = (storeurl, consumerkey, consumersecret) => {
        
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-woocommerce-keys', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "store_url": storeurl,
                    "consumer_key": consumerkey,
                    "consumer_secret": consumersecret,

                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {

                            setStatus("product-syncing")


                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 14;
                            var account_id = token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            submitProductForm(storeurl, consumerkey, consumersecret);
                            // setStatus("verified")


                            // history.push({
                            //     pathname: '/woocommerce-product-sync',
                            //     state: { statsData: json.data }
                            // });

                        }
                        else {

                            setStatus("verify")

                            setShowErrors(true);
                            setErrorMessage(json.message);
                            setTimeout(resetState, 2000);


                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {

                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };


    const isButtonDisabled = urlErrors || !consumerkey || !consumersecret || storeurlError || consumerkeyError || consumersecretError;


    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }



    const tagLine = showUserTypeBaseMessage({
        'Brand': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
        'default': '',
        'NonProfit': 'Empower Your Donors by Providing an Ultimate Shoppable Video Experience',
        'Influencer': 'Empower Your Viewers by Providing an Ultimate Shoppable Video Experience',
        'Individual': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    });


    const SyncProduct = (e) => {
        e.preventDefault();
        submitProductForm(storeurl, consumerkey, consumersecret);
    };

    const submitProductForm = (storeurl, consumerkey, consumersecret) => {

        setStatus("product-syncing")
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-variant-woocommerce-product-sync', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "store_url": storeurl,
                    "consumer_key": consumerkey,
                    "consumer_secret": consumersecret,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 16;
                            var account_id = token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })
                            setStatus("done")
                            setProductSuccess(json.message)

                            GoToSelectProduct()
                        }
                        else {
                            // setShowErrors(true);
                            // setErrorMessage(json.message);
                            setProductErrors(json.message)
                            // setTimeout(resetState, 2000);
                            // history_push_link = "/add-product";
                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {
                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };

    const SeeProducts = (e) => {

        e.preventDefault();
        history.push("/products");


    };
    useEffect(() => {
        fetchVideosData();

    }, []);
    const fetchVideosData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/check-user-woocommerce-keys', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                setkeysStored(false)
                // setVideotdata(data.data)
                // setLoader(true)
                if (data.code == 200) {
                    setConsumerkey(data.data.consumer_key)
                    setConsumersecret(data.data.consumer_secret)
                    setStoreurl(data.data.store_url)
                } else {

                }

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const backToAddProducts = () => {

        return history.push({
            pathname: "/add-product",
            state: history.location.state,
        });
    };

    const GoToSelectProduct = () => {
        history.location.state.OnBoardingShowImport = true;
        history.push({
            pathname: "/select-product",
            state: history.location.state,
        });
    }

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} data-sidebartype={sidebarType} id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Add Product" onNavBarClick={onNavBarClick} formWidth={true}>
                <div className="row pb-50">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">                               
                                <h5 className="fw-bolder">Create Your First Shoppable Video</h5>
                                <VideoSteps onBoarding step="3" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body">
                                <div className={status !== Statuses.SYNCING  && "w-500"}>
                                    {(keysStored) ? <><div class="card-body"><div class="text-center"><div class="spinner-border" role="status"></div></div></div></> :
                                        <div className="import-shopify">
                                            <section className="import-shopify-integration">
                                                {(showErrors || errorMessage) ? <div className="alert alert-danger" role="alert"><strong>Error </strong> {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."} </div> : ""}

                                                {status === Statuses.VERIFY && (

                                                    <div className="mt-4">
                                                        <form className="form">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        {/* <label htmlFor="wfirstName2">Store URL <span className="danger">*</span>
                                                </label>
                                                <input type="text" placeholder="https://example.com" className="form-control required" id="wfirstName2" name="storeid" value={storeurl} onChange={handleUrlChange} />
                                                {(storeurlError?<small className="text-danger">{storeurlError}</small>:"")} */}
                                                                        <Input
                                                                            id="store-url"
                                                                            type="text"
                                                                            label="Store URL *"
                                                                            value={storeurl}
                                                                            onChange={(e) => handleUrlChange(e)}
                                                                            disabled={false}
                                                                            placeholder="https://example.com"
                                                                            errorMessage={storeurlError}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        {/* <label htmlFor="wfirstName2">Consumer Key<span className="danger">*</span>
                                                </label>
                                                <input type="text" placeholder="ck_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" className="form-control required" id="wfirstName2" name="consumerkey" value={consumerkey} onChange={handleConsumerkeyChange} />
                                                {(consumerkeyError?<small className="text-danger">{consumerkeyError}</small>:"")} */}
                                                                        <Input
                                                                            id="consumer-key"
                                                                            type="text"
                                                                            label="Consumer Key *"
                                                                            value={consumerkey}
                                                                            onChange={(e) => handleConsumerkeyChange(e)}
                                                                            disabled={false}
                                                                            placeholder="ck_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                                                            errorMessage={consumerkeyError}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                        {/* <label htmlFor="wfirstName2">Consumer Secret:<span className="danger">*</span>
                                                </label>
                                                <input type="text" placeholder="cs_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx" className="form-control required" id="wfirstName2" name="consumersecret" value={consumersecret} onChange={handleConsumersecretChange} />
                                                {(consumersecretError?<small className="text-danger">{consumersecretError}</small>:"")} */}
                                                                        <Input
                                                                            id="consumer-secret"
                                                                            type="text"
                                                                            label="Consumer Secret *"
                                                                            value={consumersecret}
                                                                            onChange={(e) => handleConsumersecretChange(e)}
                                                                            disabled={false}
                                                                            placeholder="cs_xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                                                                            errorMessage={consumersecretError}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                                            <Button
                                                                type="submit"
                                                                buttonText="Next"
                                                                fullButton={false}
                                                                loading={false}
                                                                onClick={handleSubmit}
                                                                disabled={isButtonDisabled}
                                                            />
                                                        </div> */}
                                                        </form>
                                                    </div>)}

                                                {/* adding all the components  */}

                                                {status === Statuses.VERIFIED && (
                                                    <div className="row">
                                                        <section>
                                                            <div class="card-body pt-5 pb-5">
                                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                                    <img src={RoundtickIcon} width="75" height="75"></img>
                                                                    <br />
                                                                    <h2 className="text-center mb-4 text-verfied">Credentials Verified</h2>

                                                                    {/* <button type="submit" onClick={SyncProduct} class="btn mb-1 btn-primary btn-lg px-4 w-10 text-white">Sync Products</button> */}

                                                                    <Button
                                                                        type="submit"
                                                                        buttonText="Sync Products"
                                                                        fullButton={false}
                                                                        loading={false}
                                                                        onClick={SyncProduct}
                                                                        disabled={false}
                                                                    />

                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                )}

                                                {status === Statuses.SYNCING && (

                                                    <div className="row">
                                                        <section>
                                                            <div class="card-body pt-5 pb-5">
                                                                <div class="card-body">
                                                                    <p className="card-subtitle-verified-cred mb-3 text-black fw-bolder">Product Syncing</p>
                                                                    <div class="card-body">
                                                                        <div class="text-center">
                                                                            <div class="spinner-border" role="status">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>
                                                )}


                                                {status === Statuses.DONE && (

                                                    <div className="row">
                                                        <section>
                                                            <div class="card-body pt-5 pb-5">
                                                                <div className="d-flex flex-column align-items-center justify-content-center">
                                                                    <img src={RoundtickIcon} width="75" height="75"></img>
                                                                    <br />
                                                                    <h2 className="text-center mb-4 text-verfied">{productsuccess}</h2>
                                                                    {/* <button type="submit" onClick={SyncProduct} class="btn mb-1 btn-primary btn-lg px-4 w-10 text-white">See Products</button> */}
                                                                    <Button
                                                                        type="submit"
                                                                        buttonText="See Products"
                                                                        fullButton={false}
                                                                        loading={false}
                                                                        onClick={SeeProducts}
                                                                        disabled={false}
                                                                    />


                                                                </div>
                                                            </div>
                                                        </section>
                                                    </div>


                                                )}


                                            </section>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
                        {/* sticky footer */}
                        {status === Statuses.VERIFY && (
                <div className="sticky-footer ">
                    <div className="footer-container">
                        <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                            <Button
                                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                                type="button"
                                buttonText="Back"
                                onClick={backToAddProducts}
                            />
                            <Button
                                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                                type="button"
                                onClick={handleSubmit}
                                buttonText="Next"
                                fullButton={false}
                                loading={importProductLoader}
                                disabled={isButtonDisabled}
                            />
                        </div>
                    </div>
                </div>
            )}
            {/* sticky footer */}
        </div>
    );
}
export default ImportWoocommerceOnboardProduct;