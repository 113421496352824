import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import moment from "moment";
import { removeEmptyProperties, getActualObjectLength, isMobile } from '../../helpers/helper';
import ActivityLog from "../../helpers/ActivityLog";
// import BreadCrump from "../../Breadcrump/BreadCrump";
// import dashboardbreadcrumimage from "../../../dist/images/dashboard/video.png";
// import { showUserTypeBaseMessage } from "../../helpers/helper";
import AuthCustom from "../../helpers/AuthCustom";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import { VideoSteps } from "../Onboarding/Video/AddVideo";
import Input from "../../Form/Input";
// import CheckBoxRow from "../../Form/CheckBoxRow";
import Button from "../../Form/Button";
import DateTimePicker from "../../Form/DateTimePicker";
import FieldWarning from "../../Form/FieldWarning";
import RadioButtonRow from "../../Form/RadioButtonRow";
import { VideoPurpose } from "../../../Enums/VideoPurpose";
import ToggleSwitchRow from "../../Form/ToggleSwitchRow";
import SuccessCheckout from "../Onboarding/Checkout/SuccessCheckout";

const EditExternalCheckoutNav = () => {
    const authCustom = new AuthCustom();
    const history = useHistory();
    const [publishDate, setPublishDate] = useState("");
    const [externalLink, setExternalLink] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [schedulePublish, setSchedulePublish] = useState(false);
    const [urlErrors, setUrlErrors] = useState([]);
    const [externalCheckoutLoader, setExternalCheckoutLoader] = useState(false);
    const [minDateTime, setMinDateTime] = useState("");
    const [editVideoId, setEditVideoId] = useState("");
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [videoShareWidgeApptId, setVideoShareWidgeApptId] = useState([]);
    const [checkoutTypes, setCheckoutTypes] = useState([]);
    const [user, setUser] = useState();
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    // const pagetitle = history.location.state.addVideoData.videoType;
    const title = "External Checkout";
    // const fieldtitle = "External Checkout";
    const [videotype, setVideotype] = useState(title);
    // const [fieldtext, setFieldtext] = useState(fieldtitle);
    const [isChecked, setIsChecked] = useState(false);
    const [predate, setPredate] = useState();
    const [publishDateError, setPublishDateError] = useState("");
    const [urlproblemError, setProblemErrorErrors] = useState(false);
    const [allcheckout, setAllcheckout] = useState(false);
    const [payment, setPayment] = useState(false);
    const [inapp, setInapp] = useState(false);
    const [showSuccessModal, setSuccessModal] = useState(false);
    const isVideoTypeLead = history.location.state.addVideoData.videoType === VideoPurpose.TO_GENERATE_LEAD;
    const isDonationVideo = history.location.state.addVideoData?.videoType === VideoPurpose.TO_COLLECT_THE_DONATION;
    const streamType = history.location.state.addVideoData.streamType;

    const previousProducts = history.location.state.originalVideoData.selectedProducts

    const [donationCheckoutType, setDonationCheckoutType] = useState("external_checkout"); // external_checkout, in_video
    const [donationAmounts, setDonationAmounts] = useState([
        { value: 5, error: "" },
        { value: 10, error: "" },
        { value: 15, error: "" },
        { value: 20, error: "" },
    ]);

    const onChangeDonationAmount = (value, index) => {
        const updatedItems = [...donationAmounts];
        const updatedItem = { ...updatedItems[index] };
        updatedItem.value = value;
        updatedItems[index] = updatedItem;
        setDonationAmounts(updatedItems);
    }




    useEffect(() => {

        // if video was prev. donation
        if (history.location.state.originalVideoData.video_type === VideoPurpose.TO_COLLECT_THE_DONATION) {
            if (history.location.state.originalVideoData.is_external_checkout) {
                setExternalLink(history.location.state.originalVideoData.external_checkout_link)
                setDonationCheckoutType("external_checkout");
            } else {
                const old_donation_amounts = JSON.parse(history.location.state.originalVideoData.donation_amounts)
                const updatedArray = donationAmounts.map((d, dIndex) => {
                    return { ...d, value: old_donation_amounts[dIndex] };
                });

                setDonationAmounts(updatedArray);

                setDonationCheckoutType("in_video");
            }
        }
    }, [history.location.state])

    useEffect(() => {

        const currentTime = new Date();
        const publishDate = new Date(currentTime);
        const formattedDate = publishDate.toISOString();
        const firstDate = new Date(history.location.state.addVideoData.publish);
        const secondDate = new Date(formattedDate);

        if (firstDate > secondDate) {
            setSchedulePublish(true)
            setIsChecked(true)
            var new_date = formatDateTime(history.location.state.addVideoData.publish);
            setPredate(new_date);
            setPublishDate(new_date)
            setPublishDateError("")
        }

        const video_type = history.location.state.addVideoData.videoType
        const original_video_type = history.location.state.originalVideoData.video_type

        if (history.location.state.originalVideoData.is_in_app_checkout == true && ((video_type !== 'To generate lead') && (original_video_type !== 'To generate lead'))) {

            setInapp(true)
        }



        async function fetchUser() {
            const authSession = JSON.parse(window.localStorage.getItem("authSession"))
            const userResponse = await authCustom.getUserInfo(authSession.accessToken, authSession.idToken);
            setUser(userResponse)

            return () => { }
        }

        fetchUser();
    }, [])

    useEffect(() => {
        if (!history.location.state?.originalVideoData || !history.location.state?.addVideoData?.editVideoId) {
            history.push({ pathname: '/' });
        } else {
            setMinDateTime(new Date().toISOString().slice(0, 16));
            setEditVideoId(history.location.state?.addVideoData?.editVideoId);
            setSelectedProducts(history.location.state.selectedProducts);
            setVideoShareWidgeApptId(history.location.state?.originalVideoData?.assignedWidgets[0]?.app_id);
            initProductLinks();
        }
    }, []);

    function validateAllUrls() {
        if (isVideoTypeLead) return

        if ((selectedProducts) && selectedProducts.length >= 1) {
            selectedProducts.map((value, index) => setExternalLinkMultiLink(value.externalLink, index, value.value));
        }
    }

    useEffect(() => {

        fetchUserPaymentGateway();
    }, [])

    const fetchUserPaymentGateway = () => {

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        fetch(process.env.REACT_APP_API_URL + '/check-payment-gateway', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,

            },
            body: JSON.stringify({ "user_id": token.idToken }),
        })
            .then((response) => response.json())
            .then((data) => {



                const video_type = history.location.state.addVideoData.videoType
                const original_video_type = history.location.state.originalVideoData.video_type


                if (data.code == '200' && ((video_type !== 'To generate lead'))) {

                    setPayment(true)

                }

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    function handlePubishRightNow(e) {

        if (e.target.checked == true) {
            setSchedulePublish(true)
            setIsChecked(true)

            setPublishDate(null);


            setPublishDateError("")

        } else {
            setPredate("");
            setIsChecked(false)
            setSchedulePublish(false)
            setPublishDate(moment());
        }
    }

    function handlePubishDate(value) {
        console.log('value--->handlePubishDate --- EDIT EXTERNAL CHECKOUT',value);
        let dateTimeOnly =  moment(value, "YYYY-MM-DD HH:mm:ss");
        setPublishDateError("");
        setPublishDate(value);
        if (dateTimeOnly?.isBefore(moment().add(0, "minutes"))) {
            setPublishDateError(
                "Publish date must be greater than " +
                moment().add(0, "minutes").format("D/M/Y h:mm a")
            );
        }
    }


    // function setPublicDateValue(value) {


    //     setPublishDate(value);
    //     setPredate(value);
    //     setPublishDateError("")

    //     if ((!moment(value).isAfter(moment().add(0, 'minutes')))) {

    //         setPublishDateError("Publish date must be greater than " + moment().add(0, 'minutes').format('D/M/Y h:mm a'))
    //     }



    //     // if ((!moment(value).isAfter(moment().add(0, 'minutes')))) {

    //     //     setPublishDateError("Publish date must be greater than " + moment().add(0, 'minutes').format('D/M/Y h:mm a'))
    //     // }
    //     // setPublishDateError("");
    // }


    const initProductLinks = () => {
        if ((history.location.state.selectedProducts) && history.location.state.selectedProducts.length >= 1) {
            history.location.state.selectedProducts.map((value, index) => {
                updateProductsLinks(index, 'externalLink', fillCheckoutUrl(value, index))

                // Set default checkout type shopify
                let type = fillCheckoutType(value);
                updateProductsLinks(index, 'use_shopify_checkout', type === 'shopify' ? true : false)

                return null;
            });
        }
    }

    const updateProductsLinks = (index, key, value) => {
        setSelectedProducts((prevData) => {
            const newData = [...prevData]; // Create a copy of the array 
            newData[index] = {
                ...newData[index], // Create a copy of the object at the specified index
                [key]: value, // Update the value of the specified key
            };

            return newData; // Return the updated array
        });
    };

    function setExternalLinkMultiLink(link_value, index, productId) {
        if (checkoutTypes[index] === "custom") {
            if (link_value == null || !isURL(link_value)) {
                urlErrors[index] = 'Please enter valid url';
                setUrlErrors(urlErrors);
                setProblemErrorErrors(true)
            } else {
                setProblemErrorErrors(false)
                urlErrors[index] = null;
            }

            setUrlErrors(removeEmptyProperties(urlErrors));
            setExternalLink(link_value);

            if (typeof selectedProducts[index] !== 'undefined') {
                updateProductsLinks(index, 'externalLink', link_value);
                updateProductsLinks(index, 'use_shopify_checkout', false);
                updateProductsLinks(index, 'use_in_video_checkout', false);
                updateProductsLinks(index, 'use_collect_info', false);
            }
        } else {
            urlErrors[index] = null;

            if (checkoutTypes[index] === "shopify") {
                updateProductsLinks(index, 'use_shopify_checkout', true);
                updateProductsLinks(index, 'use_woo_commerce_checkout', false);
                updateProductsLinks(index, 'use_in_video_checkout', false);
                updateProductsLinks(index, 'use_collect_info', false);

            }

            if (checkoutTypes[index] === "woo_commerce") {
                updateProductsLinks(index, 'use_shopify_checkout', false);
                updateProductsLinks(index, 'use_woo_commerce_checkout', true);
                updateProductsLinks(index, 'use_in_video_checkout', false);
                updateProductsLinks(index, 'use_collect_info', false);

            }

            if (checkoutTypes[index] === "in_video") {
                updateProductsLinks(index, 'use_shopify_checkout', false);
                updateProductsLinks(index, 'use_woo_commerce_checkout', false);
                updateProductsLinks(index, 'use_in_video_checkout', true);
                updateProductsLinks(index, 'use_collect_info', false);

            }


            if (checkoutTypes[index] === "use_collect_info") {

                updateProductsLinks(index, 'use_shopify_checkout', false);
                updateProductsLinks(index, 'use_woo_commerce_checkout', false);
                updateProductsLinks(index, 'use_in_video_checkout', false);
                updateProductsLinks(index, 'use_collect_info', true);


            }


        }
    }

    const SaveVideo = async (params) => {
        setErrorMessage(false);
        setExternalCheckoutLoader(true);

        const accessToken = JSON.parse(window.localStorage.getItem("authSession")).accessToken;
        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        await fetch(process.env.REACT_APP_API_URL + '/edit-video-with-product', {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + accessToken,

            },
            body: JSON.stringify(params)
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    if (json) {

                        const userLog = new ActivityLog();
                        const admin_login_id = localStorage.getItem('admin_login_id');
                        var user_id = admin_login_id ? admin_login_id : token.idToken;
                        var is_admin = admin_login_id ? 1 : 0;
                        var event_id = 3;
                        var account_id = token.idToken;
                        userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id });

                        // history.push({
                        //     pathname: '/success-update-checkout-nav',
                        //     state: {
                        //         successVideoId: editVideoId,
                        //         successVideoShortUrl: json.videoData.video_short_url,
                        //         successWidgetAppId: videoShareWidgeApptId
                        //     },
                        // });

                        return setSuccessModal({
                            successVideoId: editVideoId,
                            successVideoShortUrl: json.videoData.video_short_url,
                            successWidgetAppId: videoShareWidgeApptId
                        })

                    }
                });
            } else {
                response.json().then(json => {
                    if (json) {
                        setErrorMessage(json?.message);

                    } else {
                        setErrorMessage('Something went wrong');

                    }
                });
            }
        }).catch((error) => {
            setErrorMessage("Something went wrong!");

        }).finally((error) => {
            setExternalCheckoutLoader(false);
        });
    }

    async function moveToSuccessCheckout() {

        validateAllUrls();

        if (getActualObjectLength(urlErrors) >= 1 && !inapp) {
            setErrorMessage("Please validate fields!");
        } else {
            const params = {
                addVideoData: history.location.state,
                selectedProducts: selectedProducts,
                publishDate: publishDate,
                externalLink: externalLink,
                isExternalCheckout: true,
                inapp: inapp,
                utc_offset: new Date().getTimezoneOffset()
            }

            await SaveVideo(params);
        }
    }

    const onPressFinishDonation = async () => {

        setErrorMessage('');
        setDonationAmounts((d) => d.map(item => ({ ...item, error: "" })));

        if (donationCheckoutType === "external_checkout") {
            if (externalLink === "" || !isURL(externalLink)) {
                return setErrorMessage("Please write valid URL");
            }
        }

        if (schedulePublish) {
            if (!publishDate) {
                return setPublishDateError("Please select publish date")
            }

            let dateTimeOnly =  moment(publishDate, "YYYY-MM-DD HH:mm:ss");
            if (dateTimeOnly?.isBefore(moment().add(0, "minutes"))) {
                return setPublishDateError(
                    "Publish date must be greater than " +
                    moment().add(0, "minutes").format("D/M/Y h:mm a")
                );
            }
        }

        if (donationCheckoutType === "in_video") {
            const updatedItems = donationAmounts.map(item => ({
                ...item,
                error: item.value % 5 !== 0 ? "amount should be divisible by 5" : ""
            }));
            setDonationAmounts(updatedItems);

            const donationAmountErrors = updatedItems.reduce((accumulator, e) => {
                if (e.error !== "") {
                    accumulator++;
                }

                return accumulator;
            }, 0);

            if (donationAmountErrors > 0) {
                return;
            }
        }

        const params = {
            addVideoData: history.location.state,
            selectedProducts: null,
            publishDate: publishDate,
            externalLink: donationCheckoutType === "external_checkout" ? externalLink : null,
            isExternalCheckout: donationCheckoutType === "external_checkout" ? true : false,
            inapp: donationCheckoutType === "in_video",
            utc_offset: new Date().getTimezoneOffset(),
            donation_amounts: donationAmounts.map(a => a.value)
        };

        await SaveVideo(params);
    }

    const PublishVideoContent = () => {
        return (

            <div className="card">
                <div className="card-body">
                    {streamType !== "live-stream-from-external-source" && (<div className="d-flex justify-content-between mt-2 mb-2">
                        <h5>Schedule Video</h5>
                        <ToggleSwitchRow
                            id="schedule_video"
                            name="schedule_video"
                            label=""
                            checked={schedulePublish}
                            onChange={handlePubishRightNow}
                            disabled={false}
                            height="20px"
                            width="32px"
                        />
                    </div>)}
                    <div className="mb-2">
                        <FieldWarning message="Your video will be publish rightaway select date & time to schedule it." />
                    </div>
                    {schedulePublish === true && (
                        <div className="mt-4 mb-2">
                            {/* <DateTimePicker
                                value={publishDate}
                                inputProps={{ placeholder: 'Select Date and Time' }}
                                onChange={(newDate) => handlePubishDate(newDate)}
                                isValidDate={!publishDateError} // Disable dates before today
                                errorMessage={publishDateError}
                            /> */}
                            <Input
                                id="schedule_date"
                                type="datetime-local"
                                label="Publish Date:"
                                min={minDateTime}
                                value={publishDate}
                                onChange={(e) => handlePubishDate(e.target.value)}
                                disabled={false}
                                errorMessage={publishDateError}
                            />
                        </div>
                    )}
                </div>
            </div>

        )
    }

    const fillCheckoutUrl = (checkoutUrlData, index) => {
        const prevSelectedProducts = history.location.state?.originalVideoData?.selectedProducts;

        const item = prevSelectedProducts.find(item => item.product_id === checkoutUrlData.value);
        if (typeof item !== 'undefined') {

            let itemUrlOrNull = item?.external_checkout_link || null;

            if (typeof selectedProducts[index] !== 'undefined') {
                // selectedProducts[index].externalLink = itemUrlOrNull;
            }

            return itemUrlOrNull;
        }
    }

    const fillCheckoutType = (value) => {

        var type = 'custom'

        const isShopifyProduct = !!value?.shopify_product_id && !!user?.shopify?.store && !!user?.shopify?.storefront_access_token
        const isWooCommerceProduct = !!value?.woocommerce_product_id;
        const isInVideoCheckout = value?.use_in_video_checkout === true;

        const prevSelectedProducts = history.location.state.originalVideoData?.selectedProducts
        const prevSelectedProduct = prevSelectedProducts.find(e => e.product_id === value?.value)

        var itemfind
        var isCollectinfoPreSeleted = false

        if (previousProducts) {
            itemfind = prevSelectedProducts.find(e => e.product_id === value?.value)
            isCollectinfoPreSeleted = itemfind && itemfind.use_collect_info === 'yes'
        }


        if (isShopifyProduct) {
            type = 'shopify'
            if (prevSelectedProduct?.use_shopify_checkout === "no") {
                type = 'custom'
            }
        }

        if (isWooCommerceProduct) {
            type = 'woo_commerce'
            if (prevSelectedProduct?.use_woo_commerce_checkout === "no") {
                type = 'custom'
            }
        }

        if (isInVideoCheckout) {
            type = 'in_video';
        }

        if (isCollectinfoPreSeleted) {
            type = 'use_collect_info';
        }

        return type;
    }

    const setInVideoCheckout = (index) => {

        updateProductsLinks(index, 'use_shopify_checkout', false);
        updateProductsLinks(index, 'use_woo_commerce_checkout', false);

        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] = updatedCheckboxes[index] === "in_video" ? "custom" : "in_video";
        setCheckoutTypes(updatedCheckboxes);
        updateProductsLinks(index, 'use_in_video_checkout', updatedCheckboxes[index] === "in_video");
        setAllcheckout(true)
        updatedCheckboxes[index] === "custom" ? setProblemErrorErrors(true) : setProblemErrorErrors(false)

        if (updatedCheckboxes[index] === "in_video") {
            urlErrors[index] = null;
            setUrlErrors(urlErrors);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true)
        }
    }

    const setShopifyCheckoutType = (index) => {
        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] = updatedCheckboxes[index] === "shopify" ? "custom" : "shopify";
        setCheckoutTypes(updatedCheckboxes);
        updateProductsLinks(index, 'use_shopify_checkout', updatedCheckboxes[index] === "shopify");
        updateProductsLinks(index, 'use_in_video_checkout', false);
        setAllcheckout(true)
        updatedCheckboxes[index] === "custom" ? setProblemErrorErrors(true) : setProblemErrorErrors(false)

        if (updatedCheckboxes[index] === "shopify") {
            urlErrors[index] = null;
            setUrlErrors(urlErrors);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true)
        }
    }

    const setWooCommerceCheckoutType = (index) => {
        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] = updatedCheckboxes[index] === "woo_commerce" ? "custom" : "woo_commerce";
        setCheckoutTypes(updatedCheckboxes);
        updateProductsLinks(index, 'use_woo_commerce_checkout', updatedCheckboxes[index] === "woo_commerce");
        updateProductsLinks(index, 'use_in_video_checkout', false);

        setAllcheckout(true)
        updatedCheckboxes[index] === "custom" ? setProblemErrorErrors(true) : setProblemErrorErrors(false)

        if (updatedCheckboxes[index] === "woo_commerce") {
            urlErrors[index] = null;
            setUrlErrors(urlErrors);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true)
        }
    }


    const setCustomCheckoutLink = (index) => {
        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] = "custom";
        setCheckoutTypes(updatedCheckboxes);

        setAllcheckout(true)
        updatedCheckboxes[index] == "custom" ? setProblemErrorErrors(true) : setProblemErrorErrors(false)

        if (updatedCheckboxes[index] === "custom") {
            urlErrors[index] = null;
            setUrlErrors(urlErrors);
        }

        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true)
        }
    }





    const setICollectInfoCheckout = (index) => {
        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] = updatedCheckboxes[index] === "use_collect_info" ? "custom" : "use_collect_info";
        setCheckoutTypes(updatedCheckboxes);
        updateProductsLinks(index, 'use_collect_info', updatedCheckboxes[index] === "use_collect_info");
        updateProductsLinks(index, 'use_in_video_checkout', false);

        setAllcheckout(true)
        updatedCheckboxes[index] == "custom" ? setProblemErrorErrors(true) : setProblemErrorErrors(false)

        if (updatedCheckboxes[index] === "use_collect_info") {
            urlErrors[index] = null;
            setUrlErrors(urlErrors);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true)
        }
    };




    // const selectText = showUserTypeBaseMessage({ NonProfit: "Select Donation Campaign", default: "Select Product" });


    function formatDateTime(inputDateTime) {
        const dateObj = new Date(inputDateTime);

        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(dateObj.getDate()).padStart(2, '0');
        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');

        const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
        return formattedDateTime;
    }


    var isButtonDisabled;

    if (inapp == true) {

        isButtonDisabled = (schedulePublish === true && (publishDateError || !moment(publishDate).isValid()))

    } else {

        isButtonDisabled = (schedulePublish === true && (publishDateError || !moment(publishDate).isValid())) || (urlproblemError);


    }



    var importedProductsCount = 0;
    if (user && history.location.state?.selectedProducts) {
        history.location.state?.selectedProducts.map(e => {

            // If product is imported from shopify
            const isShopifyProduct = !!e?.shopify_product_id && !!user?.shopify?.store && !!user?.shopify?.storefront_access_token
            if (isShopifyProduct) return importedProductsCount++;

            // If product is imported from Woo Commerce
            const isWooCommerceProduct = !!e?.woocommerce_product_id && !!user?.woo_commerce?.store && !!user?.woo_commerce?.consumer_key
            if (isWooCommerceProduct) return importedProductsCount++;

            return null;
        })
    }

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    const backToMainProductSelection = () => {

        let redirectBackTo = isVideoTypeLead ? "/videos/edit-video-nav/edit-video-lead-nav" : "/edit-videos/select-nav-product";

        return history.push({
            pathname: redirectBackTo,
            state: history.location.state,
        });
    }

    const backToPurposeScreen = () => {
        return history.push({
            pathname: "/videos/edit-video-purpose-nav",
            state: history.location.state
        });
    }

    if (isDonationVideo) {
        return (
            <div
                className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
                data-sidebartype={sidebarType}
                id="main-wrapper"
                data-theme="blue_theme"
                data-layout="vertical"
                data-sidebar-position="fixed"
                data-header-position="fixed"
            >
                {errorMessage && (
                    <FormToast
                        type={FORM_TOAST_TYPES.DANGER}
                        message={errorMessage ? errorMessage : "Please publish date field."}
                    />
                )}

                {showSuccessModal && (<SuccessCheckout
                    {...showSuccessModal}
                    heading="You publish your video!"
                    subHeading="Your video is now ready"
                />)}

                <Sidebar onNavBarClick={onNavBarClick} />
                <Page onNavBarClick={onNavBarClick} title={videotype} formWidth={true}>
                    <div className="row pb-50">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header vs-card-header">
                                    <h5 className="fw-bolder">Edit your shoppable video</h5>
                                    <VideoSteps editMode step="4" purpose={history.location.state.addVideoData.videoType} />
                                </div>
                                <div className="card-body">
                                    <div className="w-500">
                                        <div className="mb-4">

                                            <div className="mb-4">
                                                {payment ? (
                                                    <div className="vs-checkout-card mt-2 flex-column">
                                                        <h5 className="mt-2 text-black mb-3">Donation Checkout Type</h5>
                                                        <div className="mb-2">
                                                            <RadioButtonRow
                                                                label="In Video Checkout"
                                                                name="checkout_type"
                                                                id="in_video"
                                                                value="in_video"
                                                                disabled={false}
                                                                checked={donationCheckoutType === "in_video"}
                                                                onChange={() => setDonationCheckoutType("in_video")}
                                                            />
                                                        </div>
                                                        {
                                                            donationCheckoutType === "in_video" && (
                                                                <div className="mb-4">
                                                                    <h5 className="mt-2 text-black">Edit donation collection amount</h5>
                                                                    {
                                                                        donationAmounts.map((d, i) => (
                                                                            <div key={`donation_amount_${i}`} className="mt-2">
                                                                                <Input
                                                                                    type="number"
                                                                                    id={`donation_amount_${i}`}
                                                                                    value={d.value}
                                                                                    onChange={(e) => onChangeDonationAmount(e.target.value, i)}
                                                                                    disabled={donationCheckoutType !== "in_video"}
                                                                                    placeholder=""
                                                                                    errorMessage={d.error}
                                                                                />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        <div className="mb-2">
                                                            <RadioButtonRow
                                                                label="Custom Checkout link"
                                                                name={`checkout_type`}
                                                                id={`custom_type`}
                                                                value="custom"
                                                                disabled={false}
                                                                checked={donationCheckoutType === "external_checkout"}
                                                                onChange={() => setDonationCheckoutType("external_checkout")}
                                                            />
                                                        </div>
                                                        <div className="mb-7">
                                                            <Input
                                                                type="text"
                                                                id="custom_link"
                                                                label=""
                                                                value={externalLink}
                                                                onChange={(e) => setExternalLink(e.target.value)}
                                                                disabled={donationCheckoutType !== "external_checkout"}
                                                                placeholder="https://www.link.com/"
                                                                errorMessage={errorMessage}
                                                            />
                                                        </div>

                                                        
                                                    </div>
                                                ) : (
                                                    <>
                                                        <h5 className="mt-2 text-black">Custom Donation Collection Link *</h5>
                                                        <div className="mb-2">
                                                            <Input
                                                                type="text"
                                                                id="custom_link"
                                                                label=""
                                                                value={externalLink}
                                                                onChange={(e) => setExternalLink(e.target.value)}
                                                                disabled={donationCheckoutType !== "external_checkout"}
                                                                placeholder="https://www.link.com/"
                                                                errorMessage={errorMessage}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <PublishVideoContent />

                                        </div>
                                    </div>
                                    {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                        <Button
                                            type="button"
                                            onClick={onPressFinishDonation}
                                            buttonText="Finish"
                                            fullButton={false}
                                            loading={externalCheckoutLoader}
                                            disabled={!user}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>

                {/* sticky footer */}
                <div className="sticky-footer ">
                    <div className="footer-container">
                        <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                            <Button
                                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                                type="button"
                                buttonText="Back"
                                onClick={backToPurposeScreen}
                            />
                            <Button
                                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                                type="button"
                                onClick={onPressFinishDonation}
                                buttonText="Finish"
                                fullButton={false}
                                loading={externalCheckoutLoader}
                                disabled={!user}
                            />
                        </div>
                    </div>
                </div>
                {/* sticky footer */}
            </div>
        )
    }

    return (
        <div
            className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
            data-sidebartype={sidebarType}
            id="main-wrapper"
            data-theme="blue_theme"
            data-layout="vertical"
            data-sidebar-position="fixed"
            data-header-position="fixed"
        >
            {errorMessage && (
                <FormToast
                    type={FORM_TOAST_TYPES.DANGER}
                    message={errorMessage ? errorMessage : "Please publish date field."}
                />
            )}

            {showSuccessModal && (<SuccessCheckout
                {...showSuccessModal}
                heading="You publish your video!"
                subHeading="Your video is now ready"
            />)}

            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title={videotype} formWidth={true}>
                <div className="row pb-50">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                <h5 className="fw-bolder">Edit your shoppable video</h5>
                                <VideoSteps editMode step="4" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body" style={{ paddingBottom: 250 }}>
                                {/* <VideoSteps step="3" /> */}
                                <div className="w-500">
                                    {!isVideoTypeLead && <h5 className="mt-2 mb-2 text-black">Select checkout option for your products</h5>}

                                    <div className="mb-4 mt-2">
                                        {user && selectedProducts.map((value, index) => {
                                            const isShopifyProduct = !!value?.shopify_product_id && !!user?.shopify?.store && !!user?.shopify?.storefront_access_token
                                            const isWooCommerceProduct = !!value?.woocommerce_product_id && !!user?.woo_commerce?.store && !!user?.woo_commerce?.consumer_key
                                            const isShippingProduct = value.has_shipping
                                            var itemfind
                                            var isCollectinfoPreSeleted = false

                                            if (previousProducts) {
                                                itemfind = previousProducts.find((e) => e.product_id === value.id)
                                                isCollectinfoPreSeleted = itemfind && itemfind.use_collect_info === 'yes'
                                            }


                                            // const isCollectInfo = value.has_shipping

                                            // Set default checkout type shopify
                                            if (!checkoutTypes[index]) {
                                                checkoutTypes[index] = fillCheckoutType(value)
                                                setCheckoutTypes(checkoutTypes)
                                                checkoutTypes[index] === 'custom' ? setProblemErrorErrors(false) : setProblemErrorErrors(false)

                                                if (isCollectinfoPreSeleted) {
                                                    checkoutTypes[index] = "use_collect_info"
                                                }
                                            }


                                            if (inapp) {
                                                return null;
                                            }

                                            return (
                                                <div className="vs-checkout-card mb-4">
                                                    <div className="vs-checkout-card-img-conatiner">
                                                        <div className="vs-checkout-card-img mb-3">
                                                            <img src={value.image} alt={value.name} />
                                                        </div>
                                                        <div className="vs-checkout-card-cotent">
                                                            <h5 className="text-black">{value.name}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="vs-checkout-card-content">
                                                        <h3>{value.name}</h3>
                                                        {isShippingProduct !== "paid-shipping" && payment && (
                                                            <div className="mb-2">
                                                                <RadioButtonRow
                                                                    id={`in_video_type_${index}`}
                                                                    name={`checkout_type_${index}`}
                                                                    value="in_video"
                                                                    label="In Video Checkout"
                                                                    checked={checkoutTypes[index] === "in_video"}
                                                                    onChange={(e) => setInVideoCheckout(index)}
                                                                    disabled={false}
                                                                />
                                                            </div>
                                                        )}
                                                        {isShopifyProduct && (
                                                            <div className="mb-2">
                                                                <RadioButtonRow
                                                                    label="Shopify Checkout"
                                                                    name={`checkout_type_${index}`}
                                                                    id={`shopify_type_${index}`}
                                                                    value="shopify"
                                                                    disabled={false}
                                                                    checked={checkoutTypes[index] === "shopify"}
                                                                    onChange={(e) => setShopifyCheckoutType(index)}
                                                                />
                                                            </div>
                                                        )}
                                                        {isWooCommerceProduct && (
                                                            <div className="mb-2">
                                                                <RadioButtonRow
                                                                    label="Woocommerce Checkout"
                                                                    name={`checkout_type_${index}`}
                                                                    id={`shopify_type_${index}`}
                                                                    value="woo_commerce"
                                                                    disabled={false}
                                                                    checked={checkoutTypes[index] === "woo_commerce"}
                                                                    onChange={(e) => setWooCommerceCheckoutType(index)}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="mb-2">
                                                            <RadioButtonRow
                                                                label="Collect Customer Info Only"
                                                                name={`checkout_type_${index}`}
                                                                id={`use_collect_info_${index}`}
                                                                value="use_collect_info"
                                                                disabled={false}
                                                                checked={checkoutTypes[index] === "use_collect_info"}
                                                                onChange={(e) => setICollectInfoCheckout(index)}
                                                            />
                                                        </div>
                                                        <div className="mb-0">
                                                            <RadioButtonRow
                                                                label="Custom Checkout link"
                                                                name={`checkout_type_${index}`}
                                                                id={`custom_type_${index}`}
                                                                value="custom"
                                                                disabled={false}
                                                                checked={checkoutTypes[index] === "custom"}
                                                                onChange={(e) => setCustomCheckoutLink(index)}
                                                            />
                                                        </div>
                                                        
                                                        <div className="pl-6">
                                                            <Input
                                                                id={`custom_link_${index}`}
                                                                type="text"
                                                                label=""
                                                                value={value?.externalLink}
                                                                onChange={(e) => setExternalLinkMultiLink(e.target.value, index)}
                                                                disabled={checkoutTypes[index] !== "custom"}
                                                                placeholder={"https://www.link" + index + ".com/"}
                                                                errorMessage={typeof urlErrors[index] !== "undefined" &&
                                                                    urlErrors[index] !== null &&
                                                                    urlErrors[index]?.length >= 1 && "Please enter correct URL"}
                                                                key={index}
                                                            />
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            )

                                            return (
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="d-flex align-items-center gap-9">
                                                                <div className="checkout-product-img">
                                                                    <img alt={value?.name} src={value?.image} />
                                                                </div>
                                                                <h5 className="text-black fs-5">{value?.name}</h5>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex flex-column mt-4">
                                                            {(importedProductsCount !== 0 || payment) && (
                                                                <>
                                                                    {isShippingProduct === "paid-shipping" && payment && (
                                                                        <div className="form-check mt-1">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`checkout_type_${index}`}
                                                                                id={`in_video_type_${index}`}
                                                                                value="in_video"
                                                                                checked={checkoutTypes[index] === "in_video"}
                                                                                onChange={(e) => setInVideoCheckout(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label user-select-none"
                                                                                for={`in_video_type_${index}`}
                                                                                role="button"
                                                                            >
                                                                                IN-Video
                                                                            </label>
                                                                        </div>
                                                                    )}

                                                                    {isShopifyProduct && (
                                                                        <div className="form-check mt-1">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`checkout_type_${index}`}
                                                                                id={`shopify_type_${index}`}
                                                                                value="shopify"
                                                                                checked={checkoutTypes[index] === "shopify"}
                                                                                onChange={(e) => setShopifyCheckoutType(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label user-select-none"
                                                                                for={`shopify_type_${index}`}
                                                                                role="button"
                                                                            >
                                                                                Shopify Checkout
                                                                            </label>
                                                                        </div>
                                                                    )}
                                                                    {isWooCommerceProduct && (
                                                                        <div className="form-check mt-1">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="radio"
                                                                                name={`checkout_type_${index}`}
                                                                                id={`shopify_type_${index}`}
                                                                                value="shopify"
                                                                                checked={checkoutTypes[index] === "woo_commerce"}
                                                                                onChange={(e) => setWooCommerceCheckoutType(index)}
                                                                            />
                                                                            <label
                                                                                className="form-check-label user-select-none"
                                                                                for={`shopify_type_${index}`}
                                                                                role="button"
                                                                            >
                                                                                WooCommerce Checkout
                                                                            </label>
                                                                        </div>
                                                                    )}



                                                                    <div className="form-check mt-1">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={`checkout_type_${index}`}
                                                                            id={`use_collect_info_${index}`}
                                                                            value="use_collect_info"
                                                                            checked={checkoutTypes[index] === "use_collect_info"}
                                                                            onChange={(e) => setICollectInfoCheckout(index)}
                                                                        />
                                                                        <label
                                                                            className="form-check-label user-select-none"
                                                                            for={`use_collect_info_${index}`}
                                                                            role="button"
                                                                        >
                                                                            Collect Info
                                                                        </label>
                                                                    </div>

                                                                    <div className="form-check mt-1">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={`checkout_type_${index}`}
                                                                            id={`custom_type_${index}`}
                                                                            value="custom"
                                                                            checked={checkoutTypes[index] === "custom"}
                                                                            onChange={(e) => setCustomCheckoutLink(index)}
                                                                        />
                                                                        <label
                                                                            className="form-check-label user-select-none"
                                                                            for={`custom_type_${index}`}
                                                                            role="button"
                                                                        >
                                                                            Custom Checkout Link
                                                                        </label>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </div>
                                                        <div className="mb-2">
                                                            <Input
                                                                id={`custom_link_${index}`}
                                                                type="text"
                                                                label=""
                                                                value={value?.externalLink}
                                                                onChange={(e) => setExternalLinkMultiLink(e.target.value, index)}
                                                                disabled={checkoutTypes[index] !== "custom"}
                                                                placeholder={"https://www.link" + index + ".com/"}
                                                                defaultValue={fillCheckoutUrl(value, index)}
                                                                errorMessage={typeof urlErrors[index] !== "undefined" &&
                                                                    urlErrors[index] !== null &&
                                                                    urlErrors[index]?.length >= 1 && "Please enter correct URL"}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                        <div className="card">
                                            <div className="card-body">
                                                {streamType !== "live-stream-from-external-source" && (<div className="d-flex justify-content-between mt-2 mb-2">
                                                    <h5>Schedule Video</h5>
                                                    <ToggleSwitchRow
                                                        id="schedule_video"
                                                        name="schedule_video"
                                                        label=""
                                                        checked={schedulePublish}
                                                        onChange={handlePubishRightNow}
                                                        disabled={false}
                                                        height="20px"
                                                        width="32px"
                                                    />
                                                </div>)}
                                                <div className="mb-2">
                                                    <FieldWarning message="Your video will be publish rightaway select date & time to schedule it." />
                                                </div>
                                                {schedulePublish === true && (
                                                    <div className="mt-4 mb-2">
                                                        
                                                        {/* <DateTimePicker
                                                            value={publishDate}
                                                            inputProps={{ placeholder: 'Select Date and Time' }}
                                                            onChange={(newDate) => handlePubishDate(newDate)}
                                                            // isValidDate={(current) => current.isAfter(moment(minDateTime).subtract(1, 'day'), 'day')} // Disable dates before today
                                                            errorMessage={publishDateError}
                                                        /> */}
                                                        <Input
                                                            id="schedule_date"
                                                            type="datetime-local"
                                                            label="Publish Dates:"
                                                            min={minDateTime}
                                                            value={publishDate}
                                                            onChange={(e) => handlePubishDate(e.target.value)}
                                                            disabled={false}
                                                            errorMessage={publishDateError}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                    <Button
                                        type="button"
                                        onClick={moveToSuccessCheckout}
                                        buttonText="Finish"
                                        fullButton={false}
                                        loading={externalCheckoutLoader}
                                        disabled={isButtonDisabled || externalCheckoutLoader || !user}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>

            {/* sticky footer */}
            <div className="sticky-footer ">
                <div className="footer-container">
                    <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                        <Button
                            className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                            type="button"
                            buttonText="Back"
                            onClick={backToMainProductSelection}
                        />
                        <Button
                            className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                            type="button"
                            onClick={moveToSuccessCheckout}
                            buttonText="Finish"
                            fullButton={false}
                            loading={externalCheckoutLoader}
                            disabled={isButtonDisabled || externalCheckoutLoader || !user}
                        />
                    </div>
                </div>
            </div>
            {/* sticky footer */}

        </div>
    );
}

export default EditExternalCheckoutNav;