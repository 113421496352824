import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import { ShopifySyncing } from "../../molecules/Woocommerce";
import { SuccessfullShopifySyncing } from "../../molecules/Woocommerce";
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import { isMobile } from "../../helpers/helper";

function WoocommerceProductSyncNav({}) {
    const isMobileSize = isMobile();
    const history = useHistory();

    const [storeurl, setStoreurl] = useState(history.location.state.statsData.store_url)
    const [consumerkey, setConsumerkey] = useState(history.location.state.statsData.consumer_key);
    const [consumersecret, setonsumersecret] = useState(history.location.state.statsData.consumer_secret);


    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [importProductLoader, setImportProductLoader] = useState(false);
    const [loader, setLoader] = useState("false");

    const [producterrors, setProductErrors] = useState('');
    const [productsuccess, setProductSuccess] = useState('');



    
    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm(storeurl, consumerkey,consumersecret);


    };

    const resetState = () => {
        setShowErrors(false);
        setErrorMessage('');
      };

    const submitForm = (storeurl, consumerkey,consumersecret) => {

        setLoader("loading")

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-variant-woocommerce-product-sync', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "store_url": storeurl,
                    "consumer_key": consumerkey,
                    "consumer_secret": consumersecret,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin =admin_login_id ? 1 : 0;
                            var event_id =16;
                            var account_id =token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            setLoader("true")
                            setProductSuccess(json.message)
                        } 
                        else {
                            // setShowErrors(true);
                            // setErrorMessage(json.message);
                            setProductErrors(json.message)
                            // setTimeout(resetState, 2000);
                            // history_push_link = "/add-product";
                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {
                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };




    const isButtonDisabled = !isURL(storeurl) || !consumerkey || !consumersecret;;


    const onNavBarClick = () =>{
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
      } 

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
        <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Import Product" onNavBarClick={onNavBarClick}>
                <BreadCrump
                    title="Product Syncing"
                    image={dashboardbreadcrumimage}
                    breadCrumpDetail={[
                        { name: "Dashboard", link: "/" },
                        { name: "Import Products From Woocommerce", link: "/settings/import-woocommerce-product" },
                        { name: "Product Syncing", link: "" },
                    ]}
                />
      

                      {(loader=="loading")?<ShopifySyncing producterrors={producterrors}/>:(loader=="true")?<SuccessfullShopifySyncing productsuccess={productsuccess}/>:
                        <div className="row">
                            <section>
                                <div class="card">
                                    <div class="card-body pt-5 pb-5">
                                        {(showErrors || errorMessage) ? <div class="alert alert-danger" role="alert"><strong>Error </strong> {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."} </div> : ""}
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <h2 className="text-center">Credentials Verified</h2>
                                            <i class="ti ti-circle-check" style={{fontSize: 80}}></i>
                                            <p className="mb-4 text-center">Verified</p>
                                            <button type="submit" onClick={handleSubmit} class="btn btn-primary w-50 text-white">Sync Products</button>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
            </Page>
        </div>
    );
}
export default WoocommerceProductSyncNav;