import { WebAuth } from "auth0-js";
import jwtDecode from "jwt-decode";

const webAuth = new WebAuth({
  domain: "asdasd",
  clientID: "asdasdad"
});

class Auth0 {
  createAccount({ email, name, username, password }) {
    return new Promise((resolve, reject) => {
      webAuth.signup(
        {
          connection: "Username-Password-Authentication",
          email,
          password,
          username,
          user_metadata: {
            name,
            picture:
              "https://s3-us-west-1.amazonaws.com/avatars-zipline-co/default-avatar.png",
            userEnvironment: process.env.REACT_APP_ENV
          }
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      webAuth.client.login(
        {
          realm: "Username-Password-Authentication",
          username: email,
          password,
          scope: "openid profile email user_metadata"
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        }
      );
    });
  }

  sendPasswordReset(email) {
    return new Promise((resolve, reject) => {
      webAuth.changePassword(
        {
          connection: "Username-Password-Authentication",
          email
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });
  }

  getUserInfo(accessToken) {
    return new Promise((resolve, reject) => {
      webAuth.client.userInfo(accessToken, (err, user) => {
        if (err) reject(err);
        else resolve(user);
      });
    });
  }

  getSavedSession() {
    const savedAuthSession = window.localStorage.getItem("authSession");

    if (!savedAuthSession) {
      throw new Error("not_logged_in");
    }

    const { idToken, accessToken } = JSON.parse(savedAuthSession);
    const { exp } = jwtDecode(idToken);

    if (Date.now() > exp * 1000) {
      throw new Error("token_expired");
    }

    return { idToken, accessToken };
  }
}

export default Auth0;
