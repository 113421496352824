import React from "react";
import styles from "./FormHeading.module.scss";
import { Icon } from "../Icon";
import classnames from "classnames";

export default function FormHeading({
  children,
  rightElement,
  size = 14,
  weight = "normal",
  className,
  icon
}) {
  return (
    <div className={classnames(styles.formHeading, className)}>
      {icon && <Icon name={icon} className={styles.formIcon} />}
      <label style={{ fontSize: `${size}px`, fontWeight: weight }}>
        {children}
      </label>
      {rightElement}
    </div>
  );
}
