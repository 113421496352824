import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import repotIcon from "../../../assets/dist/images/svgs/icon-connect.svg";
import Dailywatchtime from "../../molecules/Graph/Watchtime/Watchtime";
import Dailycta from "../../molecules/Graph/Cta/Cta";
import {
  loggedUserAccountType,
  showUserTypeBaseMessage,
  userLoggedAccessToken,
  daysRemaining,
  loggedUserName,
} from "../../helpers/helper";
import { color } from "d3-color";
import dashboardbreadcrumimage from "../../../../src/dist/images/dashboard/head-slider-image.png";
import moment from "moment";
import styles from "./StatsDashboard.module.css";
import EyeIcon from "./../../../assets/icons/eye.svg";
import InfoIcon from "./../../../assets/icons/info-black.svg";
import ClockIcon from "./../../../assets/icons/clock.svg";
import ArrowheadIcon from "./../../../assets/icons/arrowhead.svg";
import PointerIcon from "./../../../assets/icons/pointer.svg";
import WavingHandIcon from "./../../../assets/icons/waving-hand.svg";
import SubscriptionWarning from "../Warnings/SubscriptionWarning";
import ProfileWarning from "../Warnings/ProfileWarning";


function StatsDashboard({ packageDaysLeft }) {
  const history = useHistory();
  const [hasClassCollapseOne, setHasClassCollapseOne] = useState(false);
  const [hasClassCollapseTwo, setHasClassCollapseTwo] = useState(true);
  const [hasClassCollapseThree, setHasClassCollapseThree] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [watchtime, setWatchtime] = useState(0);
  const [avgwatchtime, setAvgwatchtime] = useState(0);
  const [avgwatchthrough, setAvgwatchthrough] = useState(0);
  const [videoview, setVideoview] = useState(0);
  const [totalviewers, setTotalviewers] = useState(0);
  const [videocompletions, setVideocompletions] = useState(0);
  const [activeviews, setActiveviews] = useState(0);
  const [cta, setCta] = useState(0);
  const [ctathrough, setCtathrough] = useState(0);
  const [errorMessage, setErrorMessage] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);
  const [warningMessage, setWarningMessage] = useState(false);
  const [isNecessaryProfileInfoFilled, setIsNecessaryProfileInfoFilled] = useState(true);

  

  useEffect(() => {
    checkUserProfile();
  }, []);

  const checkUserProfile = async () => {
    try {
      var token = localStorage.getItem("authSession");
      var parsedToken = JSON.parse(token);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/check-user-profile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userLoggedAccessToken(),
          },
          body: JSON.stringify({ user_id: parsedToken.idToken }),
        }
      );

      const data = await response.json();
      setIsNecessaryProfileInfoFilled(data.code === "200");
      return data.code === "200";
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();
    if (packageDaysLeft) {
      if (packageDaysLeft < 0) {
        setWarningMessage("Your subscription has been ended");
      } else if (packageDaysLeft <= 7) {
        setInfoMessage(
          "Your subscription will expire on " +
          moment(data?.data?.current_period_end).format("Do MMM YYYY")
        );
      }
    }
  }, []);


  const fetchData = () => {
    let token = localStorage.getItem("authSession");
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/user-videos-stats", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({ id: token.idToken }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        setData(data.data);
        setWatchtime(data.data.watch_time);
        setAvgwatchtime(data.data.avg_watch_time);
        setAvgwatchthrough(data.data.avg_watch_through);
        setVideoview(data.data.video_view);
        setTotalviewers(data.data.total_viewers);
        setVideocompletions(data.data?.video_completions ?? 0);
        setActiveviews(data.data.active_views);
        setCta(data.data.cta);
        setCtathrough(data.data.cta_clicks_through);

     



        setLoader(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const openCollapseOne = () => {
    setHasClassCollapseTwo(false);
    setHasClassCollapseThree(false);
  };

  const openCollapseTwo = () => {
    setHasClassCollapseTwo(true);
    setHasClassCollapseThree(false);
  };

  const openCollapseThree = () => {
    setHasClassCollapseTwo(false);
    setHasClassCollapseThree(true);
  };

  const successText = showUserTypeBaseMessage({
    NonProfit: "to start collect donations",
    Brand: "",
    default: "to earning commission",
  });

  const welcomeMessage = showUserTypeBaseMessage({
    Brand: `Hello ${loggedUserName()}!`,
    default: `Hello ${loggedUserName()}!`,
  });

  const welcomeFirstPara = showUserTypeBaseMessage({
    Brand: `You are all set to convert your brand viewers into customers with our interactive shoppable video experiences`,
    default: "Your video is ready and account setup has been completed",
    NonProfit:
      "You are all set to convert your viewers into donors with our interactive shoppable video experiences.",
    Influencer:
      "You are all set to convert your viewers into customers with our interactive shoppable video experiences.",
    Individual:
      "You are all set to convert your viewers into customers with our interactive shoppable video experiences.",
  });

  const welcomeSecondPara = showUserTypeBaseMessage({
    // Brand: `&nbsp;`,
    default: ``,
    // default: `Successfully ${successText}`,
    // default: `Successfully start selling`,
  });




  return (
    <div className="container">
      {/* {errorMessage ? (
        <div class="alert alert-danger" role="alert">
          <strong>Error </strong> {errorMessage}{" "}
        </div>
      ) : (
        ""
      )}
      {infoMessage ? (
        <div class="alert alert-info" role="alert">
          <strong></strong> {infoMessage}{" "}
        </div>
      ) : (
        ""
      )}
      {warningMessage ? (
        <div class="alert alert-warning" role="alert">
          <strong></strong> {warningMessage}{" "}
        </div>
      ) : (
        ""
      )} */}

      <h1 className="text-black fw-semibold">{welcomeMessage}👋</h1>
      <p className="text-black fs-5 fw-medium">{welcomeFirstPara}</p>
      {welcomeSecondPara && (
        <p className="text-black fs-5 fw-medium">{welcomeSecondPara} asdafsd</p>
      )}

      {infoMessage && (
        <div className="mt-4">
          <SubscriptionWarning title={infoMessage} />
        </div>
      )}



      {(isNecessaryProfileInfoFilled == false) ? <div className="mt-4">
        <ProfileWarning title={"Your profile is incomplete"} />
      </div> : ""}







      <div className="row mt-3">

        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body p-0 position-relative">
              <div className="d-flex min-height-100">
                <div className="d-flex w-25 justify-content-center align-items-center">
                  <img src={EyeIcon} alt="" width="28" height="28" />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h4 className="text-black card-text fs-6 fw-bolder">
                    {videoview}
                  </h4>
                  <p className="card-text fs-5 fw-semibold">Video Views</p>
                </div>
              </div>
              <div className="position-absolute top-0 end-0">
                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Total Number of Video Views">
                  <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body p-0 position-relative">
              <div className="d-flex min-height-100">
                <div className="d-flex w-25 justify-content-center align-items-center">
                  <img src={ClockIcon} alt="" width="28" height="28" />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h4 className="text-black card-text fs-6 fw-bolder">
                    {watchtime}
                  </h4>
                  <p className="card-text fs-5 fw-semibold">Watch Time</p>
                </div>
              </div>
              <div className="position-absolute top-0 end-0">
                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Total Duration of Videos Watched by Your Viewers">
                  <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body p-0 position-relative">
              <div className="d-flex min-height-100">
                <div className="d-flex w-25 justify-content-center align-items-center">
                  <img src={ArrowheadIcon} alt="" width="28" height="28" />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h4 className="text-black card-text fs-6 fw-bolder">{cta}</h4>
                  <p className="card-text fs-5 fw-semibold">CTA Clicks</p>
                </div>
              </div>
              <div className="position-absolute top-0 end-0">
                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Total Clicks on Checkout (CTA)">
                  <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="col-lg-3 col-md-6">
          <div className="card">
            <div className="card-body p-0 position-relative">
              <div className="d-flex min-height-100">
                <div className="d-flex w-25 justify-content-center align-items-center">
                  <img src={PointerIcon} alt="" width="28" height="28" />
                </div>
                <div className="d-flex flex-column justify-content-center align-items-start">
                  <h4 className="text-black card-text fs-6 fw-bolder">
                    {ctathrough}
                  </h4>
                  <p className="card-text fs-5 fw-semibold">Click Through</p>
                </div>
              </div>
              <div className="position-absolute top-0 end-0">
                <div data-bs-toggle="tooltip" data-bs-placement="top" title="Total Viewers Clicked on Checkout (CTA)">
                  <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>








      <div className="row">
        <div className="col-lg-6 col-sm-12">
          <Dailywatchtime />
        </div>
        <div className="col-lg-6">
          <Dailycta />
        </div>
      </div>
    </div>
  );
}
export default StatsDashboard;
