import React from "react";
import Helmet from "react-helmet";

import loginSecurity from "../../../assets/LoginImg.png";
import whiteVideoSquirrelLogo from "../../../assets/icons/white-logo.svg";
import PinkTransParentLogo from "../../../assets/PinkTransParentLogo.svg";




import { Link } from "react-router-dom";
function OnboardingPage({ title, children }) {
  return (
    <div className="white_screen">
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
        <div className="position-relative overflow-hidden reg-top-area">
          <div className="position-relative z-index-5">
            <div className="row lg-pink-for-lg">
              <div className="col-xl-6 col-xxl-6 header-logo-div lg-logo ">
                <Link to="/" className="text-nowrap logo-img d-block px-6-1 py-9 w-100">
                  <img src={whiteVideoSquirrelLogo} width="180" alt="" />
                </Link>
                <div className="row px-6-1 py-9">
                  <div className="col-md-12">
                    <h2 className="fw-bold text-capitalize text-white">Go Beyond Watching!</h2>
                    <p className="fs-4 text-black text-white">Aware, Engage and Convert with Squirrel Videos.</p>
                  </div>
                </div>
                <div className="d-none d-xl-flex align-items-center justify-content-center py-9 px-5">
                  <img src={loginSecurity} alt="" className="img-fluid" />
                </div>
              </div>

              <div className="mobile-device-logo">
                <div>
                  <Link to="/" className="transparent-logo">
                    <img src={PinkTransParentLogo} width="180" alt="" />
                  </Link>
                </div>
                <div className="reg-heading-text">
                  <h2 className="fw-bolder text-capitalize">Go Beyond Watching!</h2>
                  <p className="fs-4 text-black">Aware, Engage and Convert with Squirrel Videos.</p>
                </div>
              </div>




              <div className="col-xl-6 col-xxl-6">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingPage;
