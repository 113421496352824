import React, { useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import Tabs  from "./../../Form/Tabs";
import ShopifySetup from "./ShopifySetup";
import WoocommerceSetup from "./WoocommerceSetup";


function Integration() {
    const isMobileSize = isMobile();
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    // const [showErrors, setShowErrors] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(false);
    // const [activeTab, setActiveTab] = useState('home');


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     history.push("/settings/import-shopify-product");
    // };

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    // const tagLine = showUserTypeBaseMessage({
    //     'Brand': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    //     'default': '',
    //     'NonProfit': 'Empower Your Donors by Providing an Ultimate Shoppable Video Experience',
    //     'Influencer': 'Empower Your Viewers by Providing an Ultimate Shoppable Video Experience',
    //     'Individual': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    // });

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Import Product" onNavBarClick={onNavBarClick}>
                <h3 className="mb-3 fw-bolder">Integration Settings</h3>

                {/* <BreadCrump
                    title="Shopify Integration"
                    image={dashboardbreadcrumimage}
                    breadCrumpDetail={[
                        { name: "Dashboard", link: "/" },
                        { name: "Add Shopify Keys", link: "" },
                        
                    ]}
                    tagLine={tagLine}

                /> */}

                <div className="row">
                    <section>
                        <div class="card">
                            <div class="card-body pt-5 pb-5">
                                <div className="d-flex flex-column align-items-left">
                    <Tabs
                        tabs={[
                          { key: "shopify", label: "Shopify Integration", component:<ShopifySetup/>},
                          { key: "woocommerce", label: "Woocommerce Integration", component:<WoocommerceSetup/> },
                        ]}
                      />

                                </div>

                            </div>
                        </div>
                    </section>
                </div>

            </Page>
        </div>
    );
}
export default Integration;