import React, { useState } from "react";

const CopyToClipboard = ({ url }) => {
    const copyUrlDefaultIcon = 'ti ti-copy';

    const [copyUrlIcon, setCopyUrlIcon] = useState(copyUrlDefaultIcon);

    const copy = async (e) => {
        try {
            await navigator.clipboard.writeText(e);
            setCopyUrlIcon('ti ti-check');

        } catch (error) {
            setCopyUrlIcon('ti ti-copy');
        }

        setTimeout(() => {
            setCopyUrlIcon(copyUrlDefaultIcon);
        }, 2500);
    };

    return (
        <div title="Copy to clip board" className="fs-2 p-a copy-url-field-icon" onClick={() => copy(url)}>
            <i className={'fs-5 ' + copyUrlIcon}></i>
        </div>
    );
}

export default CopyToClipboard;