import { useState } from "react";
import ToggleSwitchRow from "./ToggleSwitchRow";
import PlusImg from "./../../assets/icons/plus.svg";
import MinusImg from "./../../assets/icons/minus.svg";

const QuantityInput = ({
    id,
    label,
    type,
    min,
    value,
    onChange,
    disabled,
    placeholder,
    errorMessage,
    ...props
}) => {
    const [unlimited, setUnlimited] = useState(false);

    const decrease = () => {
        if (unlimited) return;
        if (value < 2) return;

        onChange({ target: { value: Number(value) - 1 } });
    };

    const increase = () => {
        if (unlimited) return;
        onChange({ target: { value: Number(value) + 1 } });
    };

    const onChangeQty = (e) => {
        if (unlimited) return;
        if (isNaN(e.target.value)) return;
        
        onChange({ target: { value: Number(e.target.value) } });
    };

    return (
        <div className="form-group position-relative qty-input">
            <div className="d-flex flex-row justify-content-between align-items-start">
                <label htmlFor={id} className="form-label">
                    {label}
                </label>
                <ToggleSwitchRow
                    id="unlimited"
                    name="unlimited"
                    label="Unlimited"
                    checked={unlimited || value === -1}
                    onChange={() => {
                        setUnlimited((e) => {
                            const updatedValue = !e;

                            if (updatedValue === true) {
                                onChange({ target: { value: -1 } });
                            } else {
                                onChange({ target: { value: 1 } });
                            }

                            return updatedValue;
                        });
                    }}
                    disabled={disabled}
                    height="16px"
                    width="32px"
                />
            </div>
            <div
                className={`qty-input-counter ${(unlimited === true || disabled) && "qty-input-counter-disabled"}`}
            >
                <div className="decrease" onClick={decrease}>
                    <img src={MinusImg} alt="decrease" width="24" height="24" />
                </div>
                <div className="value">
                    {
                        value === -1 ? (
                            <span className="text-black fs-3">Unlimited</span>
                        ) : (
                            <input className="write-qty" value={value} onChange={e => onChangeQty(e)} />
                        )
                    }

                </div>
                <div className="incrase" onClick={increase}>
                    <img src={PlusImg} alt="incrase" width="24" height="24" />
                </div>
            </div>
        </div>
    );
};

export default QuantityInput;
