import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { Spinner, Alert, Callout, Intent, Button } from "@blueprintjs/core";
// import { useQuery } from "@apollo/react-hooks";
import SettingsForm from "../SettingsForm";
// import { PaymentSidebar } from "../../../molecules/PaymentSidebar";
// import { useBillingInfoDetails } from "./hook";

import Modal from "react-modal";
import validator from "validator";
import stripeHelper from "../../../helpers/StripeHelper";
import bigTickIcon from "../../../../../src/assets/icons/big-tick.svg";
import creditCardIcon  from "../../../../../src/assets/icons/credit-card-icon.svg";
import ClockIcon from "../../../../../src/assets/icons/clock.svg";
import InfoIcon from "../../../../../src/assets/icons/info-black.svg";
import inviteFriendCircle from "../../../../../src/assets/icons/color-full-start.svg";

// import Packages from "../../Packages/Packages";
import PackagesListing from "../../Packages/PackagesListing";
// import newStyles  from "./BillingInformationSettingsNew.module.css";
// import { CreateUpdateCreditCard } from "./mutations";
// import { useMutation } from "@apollo/react-hooks";
import {
  isMobile,
  showNumberOrInfinity,
  userLoggedAccessToken,
  formatCardExpiry,
  showUserTypeBaseMessage,
} from "../../../helpers/helper";
import { Page } from "../../../layouts/Page";

import { Sidebar } from "../../../molecules/Sidebar";
import CreditCardExpireyField from "./CreditCardExpireyField";

import validateField from "../../../helpers/AllValidtions";

import BillingInvoices from "./BillingInvoices";
import AddonsListing from "./AddonsListing";
import Button from "../../../../component/Form/Button";
import AddonsBoxesList from "./AddonsBoxesList";
import ActivityLog from "../../../helpers/ActivityLog";
// import Packages from "";


const moment = require("moment");

export default function BillingInformationSettingsNew() {
  // const {
  //   balance,
  //   paymentMethod,
  //   subscription,
  //   currentSubscriptionPlan,
  //   changePlan,
  //   subscriptionList,
  //   setChangePlan,
  //   isAlertOpen,
  //   setIsAlertOpen,
  //   customerInvoices,
  //   fixedFee,
  //   variableFee,
  //   loading,
  //   error,
  //   isValid,
  //   onConfirmSubscription,
  //   pageIsLoading,
  //   onChangeSubscription,
  //   alertMessage
  // } = useBillingInfoDetails();
  const isMobileSize = isMobile();
  const history = useHistory();

  const [isPaymentSidebarOpen, setIsPaymentSidebarOpen] = useState(false);
  const [generalPaymentErrorMsg, setGeneralPaymentErrorMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [cardNumberPlaceHolder, setCardNumberPlaceHolder] = useState();
  const [cardTitle, setCardTitle] = useState();
  const [cardExpiry, setCardExpiry] = useState();
  const [isValidExpiry, setIsValidExpiry] = useState(false);
  const [cardCVV, setCardCVV] = useState();
  const [cardId, setCardId] = useState();
  const [openCreditCardModal, setOpenCreditCardModal] = useState(false);
  const [cancelSubModal, setCancelSubModal] = useState(false);
  const [addonListingModal, setAddonListingModal] = useState(false);
  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );
  const [showCancelBtn, setShowCancelBtn] = useState(false);
  const [subscriptionCancelLoader, setSubscriptionCancelLoader] =
    useState(false);
  const [subscribedPlanId, setSubscribedPlanId] = useState(null);
  const [subscriptionSuccessMsg, setSubscriptionSuccessMsg] = useState(null);
  const [subscriptionErrorMsg, setSubscriptionErrorMsg] = useState(null);
  const [userCreditCardsData, setUserCreditCardsData] = useState([]);
  const [userSubscribedData, setUserSubscribedData] = useState([]);

  const [cardNumberErrorMsg, setCardNumberErrorMsg] = useState("");
  // const [cardTitleErrorMsg, setCardTitleErrorMsg] = useState("");
  const [cardExpiryErrorMsg, setCardExpiryErrorMsg] = useState("");
  const [cardCVVErrorMsg, setCardCVVErrorMsg] = useState("");
  const [subscribedPlanLoader, setSubscribedPlanLoader] = useState(false);

  // const handlecardNumberChange = (e) => {
  //   var value = e.target.value;
  //   var rule = "card-number";
  //   setCardNumber(value);
  //   validateField(value, rule, setCardNumberErrorMsg);
  // };

  // const handlecardTitleChange = (e) => {
  //   var value = e.target.value;
  //   var rule = "required";
  //   setCardTitle(value);
  //   validateField(value, rule, setCardTitleErrorMsg);
  // };

  // const handlecardExpiryChange = (e) => {
  //   var value = e.target.value;
  //   var rule = "card-expiry";
  //   setCardExpiry(value);
  //   validateField(value, rule, setCardExpiryErrorMsg);
  // };

  // const handlecardCVVChange = (e) => {
  //   var value = e.target.value;
  //   var rule = "card-cvv";
  //   setCardCVV(value);
  //   validateField(value, rule, setCardCVVErrorMsg);
  // };

  // const onDismiss = () => {
  //   setIsPaymentSidebarOpen(false);
  // };

  const modalStyles = {
    content: {
      top: "100px",
      inset: "auto",
      width: isMobileSize ? "90%" : "800px",
      margin: "100px auto",
      position: "relative",
      border: "none",
      backgroundColor: null,
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "#00000066",
    },
  }
  
  
  const addOnListingModalStyles = {
    content: {
      top: "100px",
      inset: "auto",
      // width:'450px',
      width: isMobileSize ? "90%" : "500px",
      minWidth: isMobileSize ? "90%" : "500px",
      margin: "100px auto",
      position: "relative",
      // width: isMobileSize ? "90%" : "500px",
      border: "none",
      backgroundColor: null,
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "#00000066",
    },
  };;

  const cancelSubModalStyles = {
    content: {
      top: "100px",
      inset: "auto",
      // width:'450px',
      width: isMobileSize ? "90%" : "632px",
      margin: "100px auto",
      position: "relative",
      border: "none",
      backgroundColor: null,
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "#00000066",
    },
  };

  const handleCardCVVChange = (event) => {
    const inputValue = event.target.value;
    if (!inputValue) {
      setCardCVVErrorMsg("This field is required");
    }
    const numericValue = inputValue.replace(/\D/g, "");
    setCardCVV(numericValue);
  };

  // const { data: VSUserNewSubscribedPlan } = useQuery(
  //   UserNewSubscribedPlanData,{
  //     fetchPolicy: 'cache-and-network',
  //   }
  // );

  // const { data: UserCreditCardsData,refetch:reFetchUserCreditCards } = useQuery(
  //   UserCreditCards,{
  //     fetchPolicy: 'cache-and-network',
  //   }
  // );

  // const [createUpdateCreditCard] = useMutation(CreateUpdateCreditCard, { onError:reFetchUserCreditCards, onCompleted:reFetchUserCreditCards});

  const fetchSubscribedPackage = () => {
    setSubscribedPlanLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/user-new-subscribed-plan-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ forceToFetch: true }), // forceToFetch will fetch expired
    })
      .then((response) => response.json())
      .then((data) => {
        setUserSubscribedData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setSubscribedPlanLoader(false);
      });
  };

  const fetchUserCreditCards = () => {
    fetch(process.env.REACT_APP_API_URL + "/user-credit-cards", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({}), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        // setData(data.data)

        setUserCreditCardsData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const saveUpdateCreditCardReq = (creditCardData) => {
    fetch(process.env.REACT_APP_API_URL + "/create-update-user-credit-card", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify(creditCardData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.code === 400 || data?.code === 500) {
          setGeneralPaymentErrorMsg(
            data.message ? data.message : "Something went wrong "
          );
        } else {
          handleCloseModal();
          fetchUserCreditCards();
        }
      })
      .catch((error) => {
        setGeneralPaymentErrorMsg(
          error.message ? error.message : "Something went wrong "
        );
        // console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoader(false);
        // console.error('Error fetching data:', error);
      });
  };

  function setUpdateCreditFieldNumber(creditCardValue) {
    setCardNumberErrorMsg("");
    const creditCardValueLength = creditCardValue?.replace(/\s+/g, "")?.length;
    if (creditCardValueLength <= 16) {
      setCardNumber(formatCreditCard(creditCardValue));
    }
    if (validator.isEmpty(creditCardValue)) {
      setCardNumberErrorMsg("This field is required");
    }
  }

  function formatCreditCard(creditCardNumber) {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = creditCardNumber.replace(/[^\d]/g, "");

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(" ")
    );
  }

  function handleOpenModal(userCreditCard) {
    // setCardId();
    if (userCreditCard) {
      setCardId(userCreditCard?.id);
      setCardTitle(userCreditCard?.title);
      setCardExpiry(moment(userCreditCard?.expiry).format("YYYY-MM"));
      setCardNumberPlaceHolder(
        "**** **** **** " + userCreditCard?.card_last_digits
      );
    }
    setOpenCreditCardModal(true);
  }

  const handleOpenModalCancelSub = () => {
    setCancelSubModal(true);
  };

  const handleCloseModalCancelSub = () => {
    setCancelSubModal(false);
  };

  const confirmCancelSubscription = (subscribedPlanIdParam) => {
    setSubscriptionCancelLoader(true);
    setSubscriptionSuccessMsg(null);
    setSubscriptionErrorMsg(null);

    fetch(process.env.REACT_APP_API_URL + "/cancel-user-subscriptions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ subscriptionId: subscribedPlanIdParam }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData?.status) {
          if (responseData.status === false) {
            setSubscriptionErrorMsg(
              responseData?.message
                ? responseData?.message
                : "Something went wrong"
            );
          }

          if (responseData.status === true) {

            var token = localStorage.getItem("authSession")
            token = JSON.parse(token);

            const userLog = new ActivityLog();
            var user_id = token.idToken;
            var is_admin = 0;
            var event_id = 24;
            var account_id = token.idToken;
            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

            setSubscriptionSuccessMsg(
              responseData?.message ? responseData?.message : ""
            );

            // setTimeout(function () {
            // window.location.href = "/settings/subscription?unsubscribed=true";

            // history.push("/settings/subscription?unsubscribed=true")
            refreshRecallPageData();
            // window.location.reload();
            // }, 1000);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        setSubscriptionErrorMsg(
          error?.message ? error?.message : "Something went wrong"
        );
      })
      .finally((error) => {
        setSubscriptionCancelLoader(false);
      });
  };

  function handleCloseModal() {
    setCardId(null);
    setCardNumberPlaceHolder(null);
    setCardTitle(null);
    setCardExpiry(null);
    setCardCVV(null);
    setCardNumber(null);
    setCardNumberPlaceHolder(null);
    setGeneralPaymentErrorMsg(false);
    setOpenCreditCardModal(false);
  }

  const refreshRecallPageData = () => {
    handleCloseModalCancelSub(false);
    fetchSubscribedPackage();
    fetchUserCreditCards();
  };

  useEffect(() => {
    refreshRecallPageData();
  }, []);

  useEffect(() => {
    setSubscribedPlanId(userSubscribedData?.id);
    setShowCancelBtn(false);
    if (
      userSubscribedData?.status === "Active" &&
      userSubscribedData?.plan_type === "paid" &&
      !moment(userSubscribedData?.current_period_end).isBefore(moment())
    ) {
      setShowCancelBtn(true);
    }
  }, [userSubscribedData]);

  async function saveCreditCard() {
    const cardholderName = cardTitle;
    const cvv = cardCVV;

    // const cardExpiryMMYY = moment(cardExpiry).format('MM/YY');
    // const cardExpiryMMYYYY = moment(cardExpiry).format('01/MM/YYYY');
    // const cardExpiryYear = moment(cardExpiry).format('YYYY');
    // const expiration = cardExpiryMMYY;

    let isValidCreditCardInfo = false;
    // if(cardNumber==null || (  validator.isEmpty(cardNumber) || !validator.isCreditCard(cardNumber) )){
    //   setGeneralPaymentErrorMsg('Credit card number is invalid');
    // // } else if(cardExpiry==null || (  validator.isEmpty(cardExpiry) || !moment(cardExpiry).isValid() ) || (  !validator.isInt(cardExpiryYear,{gt:2022,lt:3000}) ) ){
    // } else if(!isValidExpiry){
    //   setGeneralPaymentErrorMsg('Invalid Expiry');
    // } else if(cvv==null || (  !validator.isInt(cvv,{lt:9999,gt:99}) )){
    //   setGeneralPaymentErrorMsg('Invalid CVV');
    // } else {
    //   setGeneralPaymentErrorMsg(null);
    //   isValidCreditCardInfo = true;
    // }

    isValidCreditCardInfo = true;

    if (isValidCreditCardInfo === false) {
      return false;
    }

    setLoader(true);

    const data = {
      cardholderName: cardholderName,
      cardNumber: cardNumber,
      expiration: cardExpiry,
      cvv: cvv,
    };

    try {
      setGeneralPaymentErrorMsg(null);
      const response = await stripeHelper.createTokenFromCard(data);
      const body = await response.json();

      const tokenId = body.id;

      const saveUpdateCardInput = {
        creditCardId: tokenId,
        id: cardId,
        cardTitle: cardholderName,
        // expiry:moment(cardExpiry, 'MM/YY', true).format('01/MM/YYYY'),
        expiry: formatCardExpiry(cardExpiry),
        cardLastDigits: body.card.last4,
      };

      saveUpdateCreditCardReq(saveUpdateCardInput);
    } catch (handleError) {
      setLoader(false);
      setGeneralPaymentErrorMsg(handleError?.message);
    }

    // try {
    //   setLoader(true);
    //   //update card mutation
    //   // const updatePaymentInfoRes = await createUpdateCreditCard({
    //   //   variables:saveUpdateCardInput
    //   // });

    //   // handleCloseModal();
    //   // setLoader(false);
    // } catch(err) {
    //   // setLoader(false);
    //   // setGeneralPaymentErrorMsg(err.message?err.message:"Something went wrong ");

    // }

    //charge amoutn of plan

    // } catch (err) {
    //   console.log(err);
    //   setLoader(false);
    //   setGeneralPaymentErrorMsg(err.message?err.message:"Something went wrong ");
    // }
  }

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };
  // if (pageIsLoading) {
  //   return (
  //     <SettingsTab centered>
  //       <Spinner />
  //     </SettingsTab>
  //   );
  // }
  const isButtonDisabled =
    !cardNumber ||
    !cardExpiry ||
    !cardCVV ||
    cardNumberErrorMsg ||
    cardExpiryErrorMsg ||
    cardCVVErrorMsg;

  // const subscriptionBreadCrumbTagLine = showUserTypeBaseMessage({
  //   Brand: `Subscribe to our Shoppable Video Plans and Join the World of Interactive Shopping`,
  //   default: ``,
  //   NonProfit: `Subscribe to our Shoppable Video Plans and Join the World of Interactive Fundraising`,
  //   Influencer: `Subscribe to our Shoppable Video Plans and Join the World of Interactive Shopping`,
  //   Individual: `Subscribe to our Shoppable Video Plans and Join the World of Interactive Shopping`,
  // });

  return (
    <div
      className={`page-wrapper ${
        sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
      }`}
      id="main-wrapper"
      data-layout="vertical"
      data-sidebartype={sidebarType}
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title="Subscription Management" onNavBarClick={onNavBarClick}>
        <h3 className="mb-3 fw-bolder">Subscription Management</h3>
        {/* <BreadCrump
          title="Subscription Management"
          image={subscriptionThumbImg}
          breadCrumpDetail={[
            { name: "Dashboard", link: "/" },
            { name: "Subscription Management", link: "" },
          ]}
          tagLine={subscriptionBreadCrumbTagLine}
          additionalClasses={`subscription-breadcrumb`}
        /> */}

        {/* <div style={{height:130}} className="card bg-light-info shadow-none position-relative overflow-hidden">
                  <div className="card-body px-4 py-3">
                      <div className="row align-items-center">
                          <div className="col-9">
                              <h4 className="fw-semibold mb-8">Subscription Management</h4>
                              <nav aria-label="breadcrumb">
                                  <ol className="breadcrumb">
                                      <li className="breadcrumb-item">
                                          <Link className="text-muted" to="/">
                                              Dashboard
                                          </Link>
                                      </li>
                                      <li className="breadcrumb-item">
                                          <Link className="text-muted" to="/settings">
                                              Settings
                                          </Link>
                                      </li>
                                      <li className="breadcrumb-item" aria-current="page">Subscription</li>
                                  </ol>
                              </nav>
                          </div>
                          <div className="col-3">
                              <div className="text-center mb-n5">
                                  <img
                                      src={subscriptionThumbImg}
                                      alt=""
                                      className="img-fluid mb-n4"
                                  />
                              </div>
                          </div>
                      </div>
                  </div>
              </div> */}

        {/* <Toolbar title="Settings" /> */}
        <SettingsForm />

        {/* <FormHeading weight="600" size={16}>
          Package Information
        </FormHeading> */}

        {subscribedPlanLoader === true && (
          <div className="d-flex justify-content-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}

        {subscribedPlanLoader !== true && (
          <>
            <div className="row subscription-package-card-info">
              <div className="card">
                <div className="d-flex justify-content-between">
                  <p className="current-package">Current Package</p>

                  {userSubscribedData?.status==='Active' || userSubscribedData?.plan_type==='free' &&  (
                    <p className="green-package package-status-indicator">
                      Active
                    </p>
                  )}

                  {userSubscribedData?.status==='Cancelled' &&  (
                    <p className="red-package package-status-indicator">
                      Cancelled
                    </p>
                  )}

                  {/* {alert(userSubscribedData?.plan_type)} */}

                  

                  {userSubscribedData?.status !== 'Active' && userSubscribedData?.status !== 'Cancelled' && userSubscribedData?.plan_type!=='free' && (
                    <p className="default-package package-status-indicator">
                      {userSubscribedData?.status??"Invalid"}
                    </p>
                  )}
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  {typeof userSubscribedData?.current_period_end !==
                    "undefined" && (
                    <h3 className="package-name-title">
                      {userSubscribedData?.plan_name?.length >= 1
                        ? userSubscribedData?.plan_name?.split(" ")[0]
                        : userSubscribedData?.nickname?.split(" ")[0]}
                    </h3>
                  )}

                  {!userSubscribedData?.current_period_end && (
                    <h3 className="package-name-title">Expired</h3>
                  )}
                </div>
                <div className="plan-info-pay-cycle">
                  <p className="package-date-expiry">
                    {typeof userSubscribedData?.current_period_end !==
                      "undefined" && userSubscribedData?.plan_type != "free" && (
                      <>
                        { userSubscribedData?.status === "Cancelled"
                          ? moment(
                              userSubscribedData?.current_period_end
                            ).isBefore(moment())
                            ? "Expired on "
                            : "Expire on "
                          : moment(
                              userSubscribedData?.current_period_end
                            ).isBefore(moment())
                          ? "Expired on "
                          : "Renew at "}{" "}
                        {moment(userSubscribedData?.current_period_end).format(
                          "LL"
                        )}
                      </>
                    )}
                  </p>
                  <p className="package-amount">
                    {userSubscribedData?.status === "Active" && userSubscribedData?.plan_type != "free" && (
                      <>
                        {userSubscribedData?.plan_type === "free"
                          ? "$0/month"
                          : "$ " + userSubscribedData?.amount}
                        {userSubscribedData?.plan_type === "free"
                          ? ""
                          : "/month"}
                      </>
                    )}
                  </p>
                </div>

                <div className={`d-flex addon-subscribe-btn-area ${userSubscribedData?.plan_type === "free"?"position-absolute":""}`}>
                  <Button
                    buttonText="Buy Add-Ons"
                    // className="addons-btn add-sub-btn"
                    className="btn btn-primary mr-10 subscribe-addons"
                    fullButton={false}
                    onClick={() =>
                      setAddonListingModal(true)
                    }
                  />
                  {showCancelBtn === true && (
                    <Button
                    fullButton={false}

                      buttonText="Cancel Subscription"
                      // className="cancel-sub-btn add-sub-btn"
                      className="btn btn-outline-primary"
                      onClick={(e) => handleOpenModalCancelSub(true)}
                    />
                  )}
                  {/* {showCancelBtn != true && (
                    <Button
                      buttonText="Upgrade Plan"
                      className="cancel-sub-btn add-sub-btn"
                      onClick={() =>
                        history.push("/settings/subscription/upgrade-plan")
                      }
                    />
                  )} */}
                </div>
              </div>

              {userCreditCardsData?.length >= 1
                ? userCreditCardsData.map((userCreditCard, index) => (
                    <>
                      <div className="card" key={`card-${index}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="current-package">Billing Method</p>
                        </div>

                        <div className="d-flex justify-content-between align-items-center credit-card-title">
                          <h3 className="package-name-title">
                            Credit/Debit Card
                          </h3>
                        </div>

                        <div className="d-flex justify-content-between align-items-center">
                          <p>
                            Ending in **** **** ****{" "}
                            {userCreditCard.card_last_digits}
                          </p>
                        </div>

                        {/* <div className="d-flex justify-content-between">
                  <p>&nbsp;</p>
                </div> */}

                        <div className="d-flex addon-subscribe-btn-area">
                          <Button
                            buttonText="Edit"
                            onClick={(e) => handleOpenModal(userCreditCard)}
                            // className="addons-btn edit-credit-card add-sub-btn"
                            className="btn btn-primary"
                          />
                        </div>
                      </div>
                    </>
                  ))
                : 
                <>
                  <div className="card no-billing-method-available">
                        {/* <div className="d-flex justify-content-between align-items-center flex-lg-column"> */}
                          <img src={creditCardIcon} />
                          <p>No Billing Method</p>
                          <Button
                            buttonText="Add Card"
                            onClick={(e) => handleOpenModal()}
                            className="add-card-btn"
                          />
                        </div>
                      {/* </div> */}

                </>
                }
            </div>

            {userSubscribedData ? (
              <div className="hide">
                {/* <div className="d-flex align-items-center justify-content-between mb-4">
                  <div className="d-flex align-items-center">
                    <div>
                      {typeof userSubscribedData?.current_period_end !==
                        "undefined" && (
                        <h6 className="fw-semibold text-dark fs-4 mb-0">
                          Current Package:
                          {userSubscribedData?.plan_name?.length >= 1
                            ? userSubscribedData?.plan_name?.split(" ")[0]
                            : userSubscribedData?.nickname?.split(" ")[0]}
                        </h6>
                      )}

                      {!userSubscribedData?.current_period_end && (
                        <h6 className="fw-semibold text-dark fs-4 mb-0">
                          Current Package: Expired
                        </h6>
                      )}
                    </div>
                  </div>

                  <div className="d-flex align-items-center">
                    <div className="pkg-sb-buttons-area">
                      <button
                        type="button"
                        onClick={() =>
                          history.push("/settings/subscription/addon-listings")
                        }
                        className="bp4-button narrow bold pgk-add-ons-btn"
                      >
                        Add-Ons
                      </button>

                      {showCancelBtn != true && (
                        <button
                          type="button"
                          onClick={() =>
                            history.push("/settings/subscription/upgrade-plan")
                          }
                          className="bp4-button narrow bold"
                        >
                          Upgrade Plan
                        </button>
                      )}

                      {showCancelBtn === true && (
                        <button
                          onClick={(e) => handleOpenModalCancelSub(true)}
                          className="bp4-button narrow bold secondary-btn"
                          type="button"
                        >
                          Cancel Subscription
                        </button>
                      )}
                    </div>
                  </div>
                </div> */}

                {userSubscribedData?.id && (
                  <div class="d-flex mb-5 subscribed-package-box-parent">
                    <div className="subscribed-package-box p-5 bg-baby-blue1">
                      <p>
                        {showUserTypeBaseMessage({
                          Brand: `${showNumberOrInfinity(
                            userSubscribedData?.product_limit
                          )} Product Uploads`,

                          NonProfit: `${showNumberOrInfinity(
                            userSubscribedData?.product_limit
                          )} Campaign Uploads`,

                          Influencer: `${showNumberOrInfinity(
                            userSubscribedData?.product_limit
                          )} Product Uploads`,
                          Individual: `${showNumberOrInfinity(
                            userSubscribedData?.product_limit
                          )} Product Uploads`,
                          default: `Upload ${showNumberOrInfinity(
                            userSubscribedData?.product_limit
                          )} Products`,
                        })}
                      </p>
                    </div>

                    <div className="subscribed-package-box p-5 bg-misty-rose">
                      <p>
                        {showUserTypeBaseMessage({
                          Brand: `${showNumberOrInfinity(
                            userSubscribedData?.video_limit
                          )} Video Uploads`,

                          default: `${showNumberOrInfinity(
                            userSubscribedData?.video_limit
                          )} Video Uploads`,
                        })}
                      </p>
                    </div>

                    <div className="subscribed-package-box p-5 bg-platinum">
                      <p>
                        {showUserTypeBaseMessage({
                          Brand: `Maximum Video Length/ ${showNumberOrInfinity(
                            userSubscribedData?.video_length_limit
                          )}Minutes `,
                          default: `Maximum Video Length/ ${showNumberOrInfinity(
                            userSubscribedData?.video_length_limit
                          )}Minutes `,
                        })}
                      </p>
                    </div>

                    <div className="subscribed-package-box p-5 bg-floral-white">
                      <p>
                        Video Quality{" "}
                        {showNumberOrInfinity(
                          userSubscribedData?.video_quality
                        )}{" "}
                      </p>
                    </div>

                    <div className="subscribed-package-box p-5 bg-melon">
                      <p>
                        {showUserTypeBaseMessage({
                          Brand: `${showNumberOrInfinity(
                            userSubscribedData?.watch_time
                          )} Watch Time/Month `,
                          default: `${showNumberOrInfinity(
                            userSubscribedData?.watch_time
                          )} Watch Time/Month `,
                        })}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            <AddonsListing />
            <div className="row mt-3">
            <div className="col-lg-3 col-md-6">
            <div className="card">
                <div className="card-body p-0 position-relative">
                    <div className="d-flex min-height-100">
                        <div className="d-flex w-25 justify-content-center align-items-center">
                            <img src={inviteFriendCircle} alt="" width="28" height="28" />
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <h4 className="text-black card-text fs-6 fw-bolder">
                                {userSubscribedData.bonus_watch_time} Min
                            </h4>
                            <p className="card-text fs-5 fw-semibold">Bonus Watch Time</p>
                        </div>
                    </div>
                    <div className="position-absolute top-0 end-0">
                        <div data-bs-toggle="tooltip" data-bs-placement="top" title="Bonus watch time is the free minutes given for new signups.">
                            <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="col-lg-3 col-md-6">
            <div className="card">
                <div className="card-body p-0 position-relative">
                    <div className="d-flex min-height-100">
                        <div className="d-flex w-25 justify-content-center align-items-center">
                            <img src={ClockIcon} alt="" width="28" height="28" />
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <h4 className="text-black card-text fs-6 fw-bolder">
                                {userSubscribedData.watch_time} Min
                            </h4>
                            <p className="card-text fs-5 fw-semibold">Watch Time</p>
                        </div>
                    </div>
                    <div className="position-absolute top-0 end-0">
                        <div data-bs-toggle="tooltip" data-bs-placement="top" title="Total duration of videos a user can watch.">
                            <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6">
            <div className="card">
                <div className="card-body p-0 position-relative">
                    <div className="d-flex min-height-100">
                        <div className="d-flex w-25 justify-content-center align-items-center">
                            <img src={ClockIcon} alt="" width="28" height="28" />
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <h4 className="text-black card-text fs-6 fw-bolder">
                                {userSubscribedData.live_stream_from_external_minutes} Min
                            </h4>
                            <p className="card-text fs-5 fw-semibold">Live Stream from External Source</p>
                        </div>
                    </div>
                    <div className="position-absolute top-0 end-0">
                        <div data-bs-toggle="tooltip" data-bs-placement="top" title="Limit of allotted minutes for live streaming via RTMP">
                            <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="col-lg-3 col-md-6">
            <div className="card">
                <div className="card-body p-0 position-relative">
                    <div className="d-flex min-height-100">
                        <div className="d-flex w-25 justify-content-center align-items-center">
                            <img src={ClockIcon} alt="" width="28" height="28" />
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-start">
                            <h4 className="text-black card-text fs-6 fw-bolder">
                                {userSubscribedData.live_stream_from_video_squirrel} Min
                            </h4>
                            <p className="card-text fs-5 fw-semibold">Live Stream from Video Squirrel</p>
                        </div>
                    </div>
                    <div className="position-absolute top-0 end-0">
                        <div data-bs-toggle="tooltip" data-bs-placement="top" title="Limit of recording live streams on Video Squirrel">
                            <img src={InfoIcon} alt="Information Icon" width="16" height="16" style={{ marginTop: '5px', marginRight: '5px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

          
</div>
            <div className="row">
            

              <Modal
                isOpen={addonListingModal===true}
                style={addOnListingModalStyles}
                closable={false}
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header d-flex align-items-center">
                    </div>
                    <div class="modal-body">
                      
                    <div className="d-flex justify-content-center">
                        <div className="card">
                          <div className="px-4 py-3 d-flex justify-content-between align-items-center">
                            <h5 className="card-title fw-semibold mb-0">
                              Buy Add-Ons
                            </h5>
                            
                            <button
                              type="button"
                              onClick={(e)=> { setAddonListingModal(false)}}
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>

                          {/* <CreditCardForm /> */}

                          <div className="card-body p-4">

                            <AddonsBoxesList />
                            
                          </div>
                        </div>
                      </div>

                    </div>
                    <div class="modal-footer"></div>
                  </div>
                </div>
              </Modal>

              <Modal
                isOpen={cancelSubModal}
                style={cancelSubModalStyles}
                closable={false}
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header d-flex align-items-center">
                    </div>
                    <div class="modal-body">
                      <div className="d-flex justify-content-center">
                        <div className="card text-center">
                          <div className="card-body py-4 px-7">
                            {subscriptionErrorMsg ? (
                              <div class="alert alert-danger" role="alert">
                                <strong></strong> {subscriptionErrorMsg}{" "}
                              </div>
                            ) : (
                              ""
                            )}
                            {subscriptionSuccessMsg ? (
                              <>
                              <img src={bigTickIcon} />
                              <h3 className="m-3 subscription-cancelled-success-msg">Subscription Cancelled</h3>
                              {/* <div class="alert alert-success" role="alert">
                                <strong></strong> {subscriptionSuccessMsg}{" "}
                              </div> */}
                              </>

                            ) : (
                              ""
                            )}
                            {/* <button onClick={(e) => saveCreditCard()} className="btn btn-primary">Subscribe Plan</button> */}

                            {(subscriptionSuccessMsg?.length <= 0 ||
                              typeof subscriptionSuccessMsg?.length ===
                                "undefined") && (
                              <p className="fw-bolder fs-5">
                                Do you really want to cancel your current subscription?
                              </p>
                            )}

                            <p>
                              After the cancellation, you'll use your package
                              till{" "}
                              <strong>
                                {moment(
                                  userSubscribedData?.current_period_end
                                ).format("LL")}
                              </strong>{" "}
                            </p>

                            {(subscriptionSuccessMsg?.length <= 0 ||
                              typeof subscriptionSuccessMsg?.length ===
                                "undefined") && (
                              <div>
                                <div className="d-flex justify-content-center">

                                <Button
                                  buttonText="Yes"
                                  loading={subscriptionCancelLoader}
                                  className="btn btn-primary mr-10"
                                  fullButton={false}
                                  onClick={(e) =>
                                    confirmCancelSubscription(
                                      subscribedPlanId
                                    )
                                  }
                                />

                                  {/* <button
                                    type="button"
                                    onClick={(e) =>
                                      confirmCancelSubscription(
                                        subscribedPlanId
                                      )
                                    }
                                    class="btn btn-primary white-bg-btn px-4"
                                  >
                                    <div>
                                      {subscriptionCancelLoader ? (
                                        <div class="d-flex align-items-center px-4">
                                          <span
                                            class="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                          ></span>{" "}
                                        </div>
                                      ) : (
                                        <div>Yes</div>
                                      )}
                                    </div>
                                  </button> */}
                                  <Button
                                    buttonText="No"
                                    // loading={subscriptionCancelLoader}
                                    disabled={subscriptionCancelLoader}
                                    className="btn btn-outline-primary"
                                    fullButton={false}
                                    onClick={(e) =>
                                      handleCloseModalCancelSub()
                                    }
                                  />
                                  {/* <button
                                    type="button"
                                    disabled={subscriptionCancelLoader}
                                    onClick={handleCloseModalCancelSub}
                                    className="btn btn-primary text-white ml-5 px-4"
                                    data-bs-dismiss="modal"
                                  >
                                    No
                                  </button> */}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer"></div>
                  </div>
                </div>
              </Modal>

              <Modal
                isOpen={openCreditCardModal}
                style={modalStyles}
                closable={false}
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header d-flex align-items-center">
                      <h4 class="modal-title" id="myLargeModalLabel"></h4>
                      {/* <button
                        type="button"
                        onClick={handleCloseModal}
                        class="btn-close mb-4"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button> */}
                    </div>
                    <div class="modal-body">
                      <div className="d-flex justify-content-center">
                        <div className="card">
                          <div className="px-4 py-3 d-flex justify-content-between align-items-center">
                            <h5 className="card-title fw-semibold mb-0">
                              {userCreditCardsData?.length?"Edit Card Details":"Add Card Details"}
                            </h5>
                            <button
                              type="button"
                              onClick={handleCloseModal}
                              class="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>

                          {/* <CreditCardForm /> */}

                          <div className="card-body p-4">
                            {generalPaymentErrorMsg ? (
                              <div
                                class="alert alert-danger col-12"
                                style={{ width: 370 }}
                                role="alert"
                              >
                                {generalPaymentErrorMsg}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="mb-4">
                              <label
                                htmlFor="cc-number"
                                aria-required
                                className="form-label fw-semibold"
                              >
                                Card Number *
                              </label>
                              <input
                                type="text"
                                id="cc-number"
                                maxLength={23}
                                value={cardNumber}
                                onChange={(e) =>
                                  setUpdateCreditFieldNumber(e.target.value)
                                }
                                placeholder={
                                  cardNumberPlaceHolder
                                    ? cardNumberPlaceHolder
                                    : "Credit Card Number"
                                }
                                class="form-control"
                              />
                              {cardNumberErrorMsg ? (
                                <small className="text-danger">
                                  {cardNumberErrorMsg}
                                </small>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="mb-4">
                              <label
                                htmlFor="cc-title"
                                aria-required
                                className="form-label fw-semibold"
                              >
                                Name on card
                                <small className="text-muted">
                                  {" "}
                                  (Optional)
                                </small>
                              </label>
                              <input
                                type="text"
                                id="cc-title"
                                value={cardTitle}
                                onChange={(e) => setCardTitle(e.target.value)}
                                placeholder="Credit Title"
                                class="form-control"
                              />
                            </div>

                            <div className="row mb-4">
                              <div className="col-lg-7 credit-card-div">
                                <label
                                  htmlFor="cc-expiry"
                                  aria-required
                                  className="form-label fw-semibold"
                                >
                                  Expiry *
                                </label>

                                <CreditCardExpireyField
                                  defaultValue={cardExpiry}
                                  setIsValidExpiry={setIsValidExpiry}
                                  setCardExpiry={setCardExpiry}
                                  setCardExpiryErrorMsg={setCardExpiryErrorMsg}
                                />
                                {cardExpiryErrorMsg ? (
                                  <small className="text-danger">
                                    {cardExpiryErrorMsg}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-lg-5">
                                <label
                                  htmlFor="cc-cvv"
                                  aria-required
                                  className="form-label fw-semibold"
                                >
                                  CVV *
                                </label>
                                <input
                                  type="tel"
                                  id="cc-cvv"
                                  maxLength={4}
                                  value={cardCVV}
                                  onChange={(e) => handleCardCVVChange(e)}
                                  class="form-control"
                                />
                                {cardCVVErrorMsg ? (
                                  <small className="text-danger">
                                    {cardCVVErrorMsg}
                                  </small>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            

                            <Button
                                onClick={saveCreditCard}
                                loading={loader}
                                disabled={isButtonDisabled || loader}
                                className="btn btn-primary"
                                fullButton={false}
                                buttonText={userCreditCardsData?.length?"Update":"Save"}
                              />

                           
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer"></div>
                  </div>
                </div>
              </Modal>
            </div>

            <PackagesListing  headingText={`Packages`} userSubscribedData={userSubscribedData} />

            <BillingInvoices />
          </>
        )}
      </Page>
    </div>
  );
}
