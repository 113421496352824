import React, { useState } from "react";
import CopyToClipboard from "./CopyToClipboard";

const Input = ({ id, type, label, placeholder, value, onChange, onClick, disabled, errorMessage,rightIconCallBack, rightIconClass, leftIconClass, copyToClipboard, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);
    const isPasswordField = type === "password"

    let fieldType = isPasswordField ? "password" : type

    if (showPassword) {
        fieldType = "text"
    }

    const defaultOnClick = () => {
    }

    const arrowOnClick = onClick || defaultOnClick;
    

    return (
        <div className={`form-group position-relative`}>
            {label?<label htmlFor={id} className="form-label">{label}</label>:""}


            <div className={`${ (rightIconClass || leftIconClass) ?"icon-form-group-input-field":"position-relative" }`}>

                {leftIconClass && (
                    <i onClick={arrowOnClick} className={`field-left-icon ${leftIconClass}`}></i>
                )}
            

                <input
                    className={`form-control ${errorMessage ? 'is-invalid' : ''} ${copyToClipboard ? 'form-control-copy-clipboard' : ''}`}
                    placeholder={placeholder}
                    type={fieldType}
                    disabled={disabled}
                    id={id}
                    onClick={arrowOnClick}
                    autoCapitalize="none"
                    value={value}
                    onChange={onChange}
                    {...props}
                />
                {copyToClipboard && <CopyToClipboard url={value} title="Copy" />}


                {rightIconClass && (
                        <i onClick={arrowOnClick} className={`field-right-icon ${rightIconClass}`}></i>
                )}


            </div>
            {isPasswordField && (
                <div
                    title={showPassword ? "Hide password" : "Show password"}
                    className='register-eye-icon'
                    onTouchStart={() => setShowPassword(true)}
                    onTouchEnd={() => setShowPassword(false)}
                    onTouchCancel={() => setShowPassword(false)}
                    onMouseDown={() => setShowPassword(true)}
                    onMouseUp={() => setShowPassword(false)}
                >
                    <i className={showPassword ? "ti ti ti-eye" : "ti ti-eye-off"}></i>
                </div>
            )}



            {errorMessage && <div><span className="text-danger fs-2 lh-1">{errorMessage}</span></div>}
        </div>
    );
}

export default Input;