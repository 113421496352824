import React from "react";
import crossDeleteIcon from "../../assets/icons/cross-x-close-icon.svg";

const VideoProductCardBox = ({
  boxImgSrc,
  boxTitle,
  boxIndexKey,
  crossDeleteBtnFunction,
  ...props
}) => {
  const toSelectPopupCallBack = crossDeleteBtnFunction || (() => {}); // Default callback function

  return (
    <>
      <div className="product-video-box">
        <div className="close-btn">
          <img
            src={crossDeleteIcon}
            onClick={(e) => {
              toSelectPopupCallBack(boxIndexKey);
            }}
          />
        </div>
        <div className="image-area">
          <img src={boxImgSrc} />
        </div>
        <div className="title">
          <p>{boxTitle}</p>
        </div>
      </div>
    </>
  );
};

export default VideoProductCardBox;
