import React, { useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
// import { useHistory } from "react-router-dom";
// import { isURL } from "validator";
// import BreadCrump from "../../Breadcrump/BreadCrump";
// import dashboardbreadcrumimage from "../../../dist/images/dashboard/product.png";
import { isMobile } from "../../helpers/helper";
import Tabs from "./../../Form/Tabs";
// import ShopifySetup from "../Products/ShopifySetup";
// import WoocommerceSetup from "../Products/WoocommerceSetup";
import ProfileSettingsNew from "./ProfileSettings/ProfileSettingsNew";
import BrandInformation from "./ProfileSettings/BrandInformation";
import Theme from "./ProfileSettings/Theme";


function AccountSetting() {
    const isMobileSize = isMobile();
    // const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    // const [showErrors, setShowErrors] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(false);
    // const [activeTab, setActiveTab] = useState('home');


    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     history.push("/settings/import-shopify-product");
    // };

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Account Settings" onNavBarClick={onNavBarClick}>
                <h3 className="mb-3 fw-bolder">Account Settings</h3>
                <div className="row">
                    <section>
                        <div class="card">
                            <div class="card-body pb-5">
                                <div className="d-flex flex-column align-items-left">
                                    <Tabs
                                        tabs={[
                                            { key: "profile", label: "Profile Settings", component: <ProfileSettingsNew /> },
                                            // { key: "brand_information", label: "Brand Information", component: <BrandInformation /> },
                                            { key: "theme", label: "Video Player Theme", component: <Theme /> },
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Page>
        </div>
    );
}
export default AccountSetting;