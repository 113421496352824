import React, { useEffect, useRef, useState } from 'react';

const WebRTCComponent = ({streamEndPoint}) => {
  const localVideoRef = useRef();
  // const remoteVideoRef = useRef();
  let localStream;
  let peerConnection;
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    const initWebRTC = async () => {
      try {
        const videoConstraints = {
          audio: true,
          video: {
            width: 1280,
            height: 720,
          },
        };

        localStream = await navigator.mediaDevices.getUserMedia(videoConstraints);
        localVideoRef.current.srcObject = localStream;

        const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] };
        peerConnection = new RTCPeerConnection(configuration);

        // Add local stream to peer connection
        localStream.getTracks().forEach((track) => {
          peerConnection.addTrack(track, localStream);
        });

        // ...

        if (isPublished) {
          // Only send the offer if the stream is set to be published
          const offer = await peerConnection.createOffer();
          await peerConnection.setLocalDescription(offer);

          const opusCodecId = offer.sdp.match(/a=rtpmap:(\d+) opus\/48000\/2/);

          if (opusCodecId !== null) {
            offer.sdp = offer.sdp.replace("opus/48000/2\r\n", "opus/48000/2\r\na=rtcp-fb:" + opusCodecId[1] + " nack\r\n");
          }

          try {
            const response = await fetch(streamEndPoint, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/sdp',
              },
              body: offer.sdp,
            });

            const answerSdp = await response.text();
            const answer = new RTCSessionDescription({ type: 'answer', sdp: answerSdp });
            await peerConnection.setRemoteDescription(answer);

            peerConnection.onconnectionstatechange = () => {
              console.log('Connection state:', peerConnection.connectionState);
            };
          } catch (error) {
            console.error('Error sending signal:', error);
          }
        }

        // ...
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    initWebRTC();

    return () => {
      if (peerConnection) {
        peerConnection.close();
        console.log('Connection closed.');
      }
      if (localStream) {
        localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [isPublished]); // Dependency on isPublished to re-run the effect when the state changes

  const togglePublish = () => {
    setIsPublished((prevIsPublished) => !prevIsPublished);
  };

  return (
    <div>
      <video ref={localVideoRef} autoPlay playsInline muted />
      <button onClick={togglePublish}>
        {isPublished ? 'Unpublish' : 'Publish'}
      </button>
    </div>
  );
};

export default WebRTCComponent;
