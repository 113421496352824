import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import * as helper from "../../helpers/helper";
import UserImg from "../../../../src/assets/icons/user-round.svg";
import VideoImg from "../../../../src/assets/icons/video.svg";
import PackageSearchImg from "../../../../src/assets/icons/package-search.svg";
import TabletSmartPhoneImg from "../../../../src/assets/icons/tablet-smartphone.svg";
import CheckRoundImg from "../../../../src/assets/icons/check-round.svg";
import LockImg from "../../../../src/assets/icons/lock.svg";
import SubscriptionWarning from "../Warnings/SubscriptionWarning";
import useAuthState from "../../hooks/useAuthState";

const Statuses = {
    COMPLETE: "complete",
    ACTIVE: "acitve",
    DISABLE: "disable"
}

const OnBoardingItem = ({
    icon,
    title,
    buttonText,
    status,
    buttonCallback
}) => {

    let className = '';

    if (status === Statuses.COMPLETE) {
        className = 'onboarding-active'
    }

    if (status === Statuses.DISABLE) {
        className = 'onboarding-disable'
    }

    return (
        <li className={`d-flex list-group-item min-height-60 onboarding-row ${className}`}>
            <div className="d-flex flex-1 justify-content-between align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                    <img className="mr-10" src={icon} alt={title} height="20" width="20" />
                    <span className="text-black fs-4 fw-semibold">{title}</span>
                </div>
                {status === Statuses.COMPLETE && (
                    <img src={CheckRoundImg} alt="Completed" height="20" width="20" />
                )}
                {status === Statuses.ACTIVE && (
                    <button className="btn btn-primary py-1 px-3" onClick={buttonCallback}>
                        <span className="fs-2">{buttonText}</span>
                    </button>
                )}
                {status === Statuses.DISABLE && (
                    <img src={LockImg} alt="Locked" height="20" width="20" />
                )}
            </div>
        </li>
    )
}

const IncompleteDashboard = () => {
    const { user } = useAuthState();
    const history = useHistory();
    const [isVideoStepCompleted, setIsVideoStepCompleted] = useState(false);
    const [isProductStepCompleted, setIsProductStepCompleted] = useState(false);
    const [stepData, setStepData] = useState();
    const [stepUrl, setStepUrl] = useState();
    const [percentCompletion, setPercentCompletion] = useState(25);

    const userInputData = {
        draftType: 'onboarding',
    };

    const redirectToStep = () => {
        if (stepUrl) {
            history.push({
                pathname: stepUrl,
                state: stepData,
            });
        }

    }
    useEffect(() => {
        new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/user-onboarding-data', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + helper.userLoggedAccessToken()
                },
                body: JSON.stringify(userInputData)
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        setIsVideoStepCompleted(false);
                        if (json.data?.length > 0) {
                            const user_data = json.data[0].user_data;
                            const step = json.data[0].step;

                            if (step >= 1) {
                                setIsVideoStepCompleted(true);
                            }
                            if (step >= 2) {
                                setIsProductStepCompleted(true);
                            }

                            let history_push_link;
                            setStepData(JSON.parse(user_data));

                            if (step == 1) {
                                setPercentCompletion(50);
                                history_push_link = "/add-product";
                            } else if (step == 2) {
                                setPercentCompletion(75);
                                history_push_link = "/lead-checkout";
                            }

                            setStepUrl(history_push_link);
                        }
                    });
                }
            })
        });

    }, []);

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="fw-bolder text-capitalize">Welcome {user?.name} 👋</h1>
                    <p className="fs-4 text-black">Follow these steps to finalize setup and start engage and convert</p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <ul className="list-group mt-3">
                        <li className="d-flex list-group-item min-height-60">
                            <div className="d-flex flex-1 justify-content-between align-items-center">
                                <span className="text-black fs-4 fw-semibold">Let's get you started!</span>
                                <span className="text-black fw-bolder text-primary">{percentCompletion}%</span>
                            </div>
                        </li>
                        <div className="onboarding-progress-bar">
                            <div className="onboarding-progress-bar-complete" style={{ width: `${percentCompletion}%` }}></div>
                        </div>
                        <OnBoardingItem
                            icon={UserImg}
                            title="Complete your profile information"
                            buttonText=""
                            status={Statuses.COMPLETE}
                            buttonCallback={() => { }}
                        />
                        <OnBoardingItem
                            icon={VideoImg}
                            title="Add your first shoppable video"
                            buttonText="Add Video"
                            status={isVideoStepCompleted === true ? Statuses.COMPLETE : Statuses.ACTIVE}
                            buttonCallback={() => history.push("/video-purpose")}
                        />
                        <OnBoardingItem
                            icon={PackageSearchImg}
                            title="Add your first product"
                            buttonText="Add Product"
                            status={isVideoStepCompleted === true ? (isProductStepCompleted === true ? Statuses.COMPLETE : Statuses.ACTIVE) : Statuses.DISABLE}
                            buttonCallback={redirectToStep}
                        />
                        <OnBoardingItem
                            icon={TabletSmartPhoneImg}
                            title="Publish Video"
                            buttonText="Publish Video"
                            status={isVideoStepCompleted === true ? Statuses.ACTIVE : Statuses.DISABLE}
                            buttonCallback={redirectToStep}
                        />
                    </ul>
                </div>
            </div>
            <div className="mt-4">
                <SubscriptionWarning title="Upgrade your plan to unlock advanced features" />
            </div>
        </div>
    );
}
export default IncompleteDashboard;