import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import styles from "./LoginForm.module.scss";
import facebookIcon from "../../../assets/dist/images/svgs/facebook-icon.svg";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import googleIcon from "../../../assets/dist/images/svgs/google-icon.svg";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import useAuthState from "../../hooks/useAuthState";
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { createAvatar } from '@dicebear/core';
import { bigEarsNeutral } from '@dicebear/collection';
import { getQueryString, showUserTypeBaseMessage } from '../../helpers/helper';
import Input from "../../../component/Form/Input"
import Button from "./../../Form/Button";
import FormToast, { TYPES } from "./../../Form/FormToast";


export const RenderGoogleButton = ({ title, responseGoogle, socialMediaBtnWidth }) => {
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      let response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${tokenResponse?.access_token}`,
        },
      });

      response = await response.json();

      responseGoogle(response)
    },
  });

  return (
    <a href="#!" style={{ width: socialMediaBtnWidth }} className="btn btn-white text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8 facebook-sign" onClick={() => login()}>
      <img src={googleIcon} alt="Video Squirrel" className="img-fluid me-2" width="16" height="16" />
      <b>{title}</b>
    </a>
  );
}

function LoginForm({
  isEmailPrefilled,
  email,
  password,
  source,
  error,
  disabled,
  loading,
  onSourceChange,
  onEmailChange,
  onPasswordChange,
  checkboxs,
  onCheckboxChange,
  onSubmit
}) {
  const [socialMediaBtnWidth, setSocialMediaBtnWidth] = useState(250)
  const { login } = useAuthState();
  const history = useHistory();

  useEffect(() => {
    const el = document.getElementById("google-btn-row");
    const computedStyle = window.getComputedStyle(el);
    const totalPadding = parseInt(computedStyle.paddingRight) + parseInt(computedStyle.paddingLeft)
    const totalWidth = document.getElementById("google-btn-row").offsetWidth - totalPadding;
    setSocialMediaBtnWidth(totalWidth > 330 ? 330 : totalWidth)
  }, [history]);

  useEffect(() => {
    const utm_signup_type = getQueryString('utm_signup_type');

    if (utm_signup_type) {
      localStorage.setItem('utm_signup_type', utm_signup_type);
    }

  }, []);



  const utmSignupType = localStorage.getItem('utm_signup_type');

  const loginHeading = showUserTypeBaseMessage({
    'Brand': `Go Beyond Watching!`,
    'default': `Welcome Back`,
    'NonProfit': `Unlock Donor Engagement with Video Squirrel!`,
    'Influencer': `Create, Share and Earn with Video Squirrel!`,
    'Individual': `Go Beyond Watching!`,
  }, utmSignupType);

  const loginHeadingPara = showUserTypeBaseMessage({
    'Brand': `Aware, Engage and Convert with Squirrel Videos`,
    'default': `Aware, Engage and Convert with Squirrel Videos`,
    'NonProfit': `Fundraising is now quick and easy. Video Squirrel empowers nonprofit fundraisers to effortlessly connect and engage with potential donors`,
    'Influencer': `Video Squirrel empowers influencers like you to turn creative passion into a thriving income stream`,
    'Individual': `Aware, Engage and Convert with Squirrel Videos`,
  }, utmSignupType);



  // 



  const bottomLinkComponent = (
    <div className="d-flex align-items-center justify-content-center">
      <p className="fs-4 mb-0 fw-medium">
        No account yet?
        <Link to="/register" className="text-primary fw-medium ms-2">
          Sign up
        </Link>
      </p>
    </div>
  );

  const responseFacebook = async (FBresponse) => {
    try {

      if (FBresponse?.email) {

        const avatar = createAvatar(bigEarsNeutral, {
          "seed": FBresponse?.name,
          "scale": 120,
          "backgroundType": [
            "gradientLinear",
            "solid"
          ]
        });
        const avatarSVG = avatar.toString();
        // const decodeGoogleData = jwtDecode(response.credential);
        const verifyOTP = await fetch(process.env.REACT_APP_API_URL + '/create-new-user-otp', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "name": FBresponse?.name,
            "email": FBresponse?.email,
            "source": "facebook"

          })

        }).then(async response => {
          if (response.ok) {
            response.json().then(async json => {
              if (json) {
                await login(FBresponse.email, "12345", "facebook");
                history.replace("/");
              }
            });
          }
        }).catch((error) => {
          console.log('error>>>', error)
        }).finally((error) => {
          //
        });

      } else {
        //failed to login
      }




    } catch (err) {

      throw err;
    }
  }

  const responseGoogle = async (response) => {
    if (!response) return

    const name = `${response?.given_name} ${response?.family_name}`
    const email = response?.email

    const avatar = createAvatar(bigEarsNeutral, {
      "seed": name,
      "scale": 120,
      "backgroundType": [
        "gradientLinear",
        "solid"
      ]
    });

    const avatarSVG = avatar.toString();
    fetch(process.env.REACT_APP_API_URL + '/create-new-user-otp', {
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "name": name,
        "email": email,
        "source": "gmail",
        "avatar": avatarSVG,
        "invitation_code": localStorage.getItem('invitation_code'),
      })
    }).then(async response => {
      if (response.ok) {
        response = await response.json()
        localStorage.removeItem('invitation_code');
        await login(email, "12345", "gmail");
        history.replace("/");
      }
    }).catch((error) => {
      console.log('error>>>', error)
    });
  }

  return (
    <div className="authentication-login  bg-body row justify-content-center align-items-center p-4">
      <div class="col-sm-7 col-md-7 col-xl-7">
        <h2 className="mb-3 fs-7 fw-bold text-center">{loginHeading}</h2>
        <div className="row">
          <div className="col-xs-12 col-12 mb-2 mb-1 d-flex justify-content-center " id="google-btn-row">
          
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <RenderGoogleButton title="Sign in with Google" responseGoogle={responseGoogle} socialMediaBtnWidth={socialMediaBtnWidth} />
            </GoogleOAuthProvider>
          </div>
          {/* <div className="col-xs-12 col-12 d-flex justify-content-center none-display">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={responseFacebook}
              fields="name,email,picture"
              render={renderProps => (
                <a href="#!" style={{ width: socialMediaBtnWidth }} className="btn btn-white text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8 facebook-sign" onClick={renderProps.onClick}>
                  <img src={facebookIcon} alt="" className="img-fluid me-2" width="18" height="18" />
                  <b>Sign in with Facebook</b>
                </a>
              )}
            />
          </div> */}
          
        </div>


        <div className="position-relative text-center my-4">
          <p className="mb-0 fs-4 px-3 d-inline-block bg-white text-dark z-index-5 position-relative">OR</p>
          <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
        </div>
        <form className={styles.register} onSubmit={onSubmit}>

          <div className={styles.info}>

          </div>

          {error && (           
           <FormToast type={TYPES.WARNING}>
            {error.message}
           </FormToast>
            // <Callout
            //   intent={Intent.WARNING}
            //   title={error.title}
            //   style={{ marginBottom: "1em" }}
            //   icon={null}
            // >
            //   {error.message}
            // </Callout>
          )}

          <div className="mb-3">
            <Input
              id="email-input"
              type="email"
              label="Email Address *"
              value={email}
              onChange={onEmailChange}
              disabled={isEmailPrefilled}
            // placeholder=""
            // errorMessage="Email is required" 
            />
          </div>

          <div className="mb-4">
            <Input
              id="password-input"
              type="password"
              label="Password *"
              value={password}
              onChange={onPasswordChange}
              disabled={false}
            // errorMessage="Password is required" 
            />
          </div>
          <input type="hidden" id="source" name="source" value="generic" />

        <div className="d-flex align-items-center justify-content-between mb-4 float-end">
          <Link className="text-primary fw-medium" to="/forgotPassword">Forgot Password?</Link>
        </div>
          <div className="">
            <Button
              type="submit"
              buttonText="Sign In"
              fullButton={true}
              loading={loading}
              disabled={disabled}
            />
          </div>

        </form>

       


        <div className="d-flex align-items-center justify-content-center">
          <p className="fs-4 mb-0 fw-medium">
            No account yet?
            <Link to="/register" className="text-primary fw-medium ms-2">
              Sign up
            </Link>
          </p>
        </div>





      </div>
    </div>
  );
}

export default LoginForm;
