import React from "react";
import { Toolbar, ToolbarGroup } from "../Toolbar";
import { useHistory } from "react-router-dom";
import {showUserTypeBaseMessage} from "../../helpers/helper";
import Button from "../../Form/Button";

function VideoPageHeader({
  // onSearchTextChange,
  onUploadPress,
  // filters,
  // setFilters,
  // filterOptions,
  // uploadBtnText,
  // searchText,
  // disableAddVideo
}) {
  const history = useHistory();

  const buttonText = showUserTypeBaseMessage(
    {
      'Brand': 'Embed Video to Web', 
      'default':'Add Integration to Web',
      'NonProfit':'Embed Video to Web',
      'Influencer':'Embed Video to Web',
      'Individual':'Embed Video to Web',
    }
  );


  const addVideoText = showUserTypeBaseMessage(
    {
      'Brand': 'Organize Video', 
      'default':'Organize Video',
      // 'NonProfit':'Embed Code Generator',
      'Influencer':'Organize Video',
      // 'Individual':'Embed Code Generator',
    }
  );

  return (
    <Toolbar>
      {/* <ToolbarGroup>
        <InputGroup
          type="search"
          placeholder="Search..."
          value={searchText}
          leftIcon={<Icon name="search" color="#8a9ba8" />}
          onChange={onSearchTextChange}
        />
      </ToolbarGroup> */}

      {/* <FilterToolbar
        value={filters}
        onChange={setFilters}
        filterOptions={filterOptions}
      /> */}

      <ToolbarGroup>
        <div className="custom_button_table">
          {/* <ButtonGroup> */}
          <Button
            // icon="plus"
            className="narrow bold secondary-btn"
            text={buttonText}
            // text={showUserTypeBaseMessage({Brand: 'Embed Video to Web', default:'Add Integration to Web'})} 
            onClick={() => history.push("/videos/integration-on-web/web-integration")}
          // disabled={disableAddVideo}
          >
          </Button>
          &nbsp;
          <Button
            icon="plus"
            className="narrow bold"
            text={addVideoText}
            // text={uploadBtnText || showUserTypeBaseMessage({Brand: 'Organize Video', default:'Add Video'}) }
            // text={showUserTypeBaseMessage({Brand: 'Organize Video', default:'Add Video'}) }
            onClick={onUploadPress}
          // disabled={disableAddVideo}
          >
          </Button>
          {/* </ButtonGroup> */}
        </div>
      </ToolbarGroup>
    </Toolbar>
  );
}

export default VideoPageHeader;
