import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
// import _ from "lodash";
import CreateIntegrationOnWebPrompt from "./CreateIntegrationOnWebPrompt";
import moment from "moment";
// import { Link } from "react-router-dom";
// import ReactDOM from "react-dom";
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import styles from "./WidgetList.module.scss";
import "./StylingWidgetList.module.scss";
import Button from "./../../Form/Button";
import {showUserTypeBaseMessage} from "../../helpers/helper";
import Table from "../../Table/Table";


function WidgetList({
  // columnWidths,
  // getColumnWidth,
  // onColumnResize,
  // onVideoClick,
  searchText,
  // filters,
  // products,
  // selectedTab,
  // onAddVideo,
}) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [widgetData, setWidgetData] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedWidgetId, setSelectedWidgetId] = useState(null);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [dropDownOpenIndex, setDropDownOpenIndex] = useState(null);
  const [addprompt, setAddprompt] = useState(false);
  // const dropdownRef = useRef(null); // Define the dropdown reference

  useEffect(() => {
    fetchWidgetData();
  }, [currentPage, rowsperPage, searchValue]);

  useEffect(() => {
    fetchcountWidgetData();


    const handleDocumentClick = (event) => {
      const target = event.target;
      if (!target.closest('.dropdown')) {
        setDropDownOpenIndex(null);
      }
    };
  
    // Add event listener when component mounts
    document.addEventListener('click', handleDocumentClick);
  
    // Cleanup event listener when component unmounts
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };

    
  }, []);

  useEffect(() => {
  }, [dropDownOpenIndex]);

  // useEffect(() => {
  //   document.addEventListener("click", handleDocumentClick);

  //   return () => {
  //     document.removeEventListener("click", handleDocumentClick);
  //   };
  // }, []);

  const fetchWidgetData = () => {
    setLoader(true);
    let token = localStorage.getItem("authSession");
     token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/all-widgets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      // body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
      body: JSON.stringify({
        id: token.idToken,
        currentPage: currentPage,
        rowsPage: rowsperPage,
        search: searchValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setWidgetData(data.data);
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setLoader(false);
      });
  };

  const fetchcountWidgetData = () => {
    setLoader(true);
    let token = localStorage.getItem("authSession");
     token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/all-widgets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({
        id: token.idToken,
        currentPage: currentPage,
        rowsPage: rowsperPage,
        search: searchValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        var add = data.total <= 0 ? true : false;

        setAddprompt(add);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setLoader(false);
      });
  };

  const deleteWidget = (paramWidgetId) => {
    // setLoader(true);
    let token = localStorage.getItem("authSession");
     token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/delete-widget", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({ widgetId: paramWidgetId }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        fetchWidgetData();
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
      })
      .finally((error) => {
        setLoader(false);
      });
  };

  // const confirmDeleteWidget = (row) => {
  //   //
  // };

  if (!loader && widgetData && !widgetData.length && !searchText) {
    // if (!widgetData || !widgetData.length) {
    //   return (
    //     <div className={styles.promptWrapper}>
    //       <CreateIntegrationOnWebPrompt/>
    //     </div>
    //   );
    // }

    if (addprompt === true) {
      return (
        <div className={styles.promptWrapper}>
          <CreateIntegrationOnWebPrompt />
        </div>
      );
    }
  }

  // useEffect(() => {
  //   // Define the event listener function
    
  // }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount
  
  

  const columns = [
    {
      name: "Title",
      selector: (row, i) => row.title,
      sortable: true,
    },
    {
      name: "Website URL",
      selector: (row, i) => row.website_url,
      sortable: true,
    },
    {
      name: "Date",
      // selector: "created_at",
      sortable: true,
      cell: (row) => (
        <span>{moment(row.craeted_at).format("MMMM Do YYYY")}</span>
      ),
    },
    {
      name: "Status",
      selector: (row, i) => row.status,
      sortable: true,
      cell: (row) => (row.is_active === false ? "Inactive" : "Active"),
    }
  ];

  const Popup = ({ onClose, onDelete }) => {
    return (
      <div
        class="modal fade show"
        id="al-danger-alert"
        tabindex="-1"
        aria-labelledby="vertical-center-modal"
        style={{ display: "block", " padding-left": "0px" }}
        aria-modal="true"
        role="dialog"
      >
        <div class="modal-dialog modal-sm">
          <div class="modal-content modal-filled bg-light-danger">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7" onClick={onClose}></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">You Want to delete Permanently?</p>
                <button
                  type="button"
                  class="btn btn-light my-2 btn-cnl"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-light my-2 btn-dlt"
                  data-bs-dismiss="modal"
                  onClick={onDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleDeleteClick = (id) => {
    setSelectedWidgetId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    deleteWidget(selectedWidgetId);
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleClick = (id) => {
    history.push("/videos/integration-on-web/edit/" + id);
  };

  const data = widgetData;

  // const tableData = {
  //   columns,
  //   data,
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setrRowsperPage(newPerPage);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false);
  };

  // const customStyles = {
  //   headRow: {
  //     style: {
  //       backgroundColor: "#fafafa !important",
  //     },
  //   },
  //   rows: {
  //     style: {
  //       minHeight: "70px !important",
  //     },
  //   },
  // };

  const buttonText = showUserTypeBaseMessage(
    {
      'Brand': 'Embed Video to Web', 
      'default':'Add Integration to Web',
      'NonProfit':'Embed Video to Web',
      'Influencer':'Embed Video to Web',
      'Individual':'Embed Video to Web',
    }
  );

  return (
    <>
      <div className="App video-table vs-table">
        {showPopup && (<Popup onClose={handleClosePopup} onDelete={handleDelete} />)}

        {errors && (
          <div class="alert alert-danger" role="alert">
            <strong>Error: </strong> {errors}{" "}
          </div>
        )}

        {success && (
          <div class="alert alert-success" role="alert">
            <strong>Success: </strong> {success}{" "}
          </div>
        )}

        <div className="widget-btn-area">
          <form class="position-relative">
            <input
              type="text"
              class="form-control search-chat py-2 ps-5 d-lg-inline"
              value={searchValue}
              placeholder="Search "
              onChange={handleSearchChange}
            />
            <i class="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
          </form>
          <div className="button-group mt-3">
            <Button
              listingButtonUnfilled={true}
              type="button"
              className="btn btn-outline-primary"
              buttonText={buttonText}
              onClick={() =>
                history.push("/videos/integration-on-web/web-integration")
              }
            />
          </div>
        </div>


        <div>
          <Table
            loading={loader}
            columns={columns}
            data={data}
            totalRows={totalRows}
            currentPage={currentPage}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            actions={[
              (row) => ({
                disabled: false,
                icon: (<i className="fs-4 ti ti-edit"></i>),
                label: "Edit",
                title: "Edit",
                onClick: (row) => handleClick(row?.id)
              }), (row) => ({
                disabled: false,
                icon: (<i className="fs-4 ti ti-trash"></i>),
                label: "Delete",
                title: "Delete",
                onClick: (row) => handleDeleteClick(row?.id)
              })
            ]}
          />
        </div>
      </div>
    </>
  );
}

export default WidgetList;
