import Select from "react-select";

const MultiSelect = ({ id, label, required, options, value, onChange, errorMessage, limit }) => {
    const isDisabled = limit && value && value?.length >= limit;
    const limitationMessage = limit ? `You can select up to ${limit} ${label?.toLowerCase()} items.` : '';
    const displayLabel = required ? `${label} *` : label;
    
    return (
        <div className="form-group">
            <label htmlFor={id} className="form-label">
                {displayLabel}
            </label>
            <Select
                isOptionDisabled={() =>
                    isDisabled
                }
                isMulti
                menuPortalTarget={document.body}
                menuPosition={"fixed"}
                options={options}
                value={value}
                onChange={onChange}
                className="basic-multi-select"
                classNamePrefix="select screen"
            />
            {errorMessage && (
                <div>
                    <span className="text-danger fs-2 lh-1">{errorMessage}</span>
                </div>
            )}

            {((!errorMessage && limit)?<small>{limitationMessage}</small>:"")}

        </div>
    );
};

export default MultiSelect;
