import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import AddShopifyKeyNav from "./AddShopifyKeyNav";
import ImportShopifyProductNav from "./ImportShopifyProductNav";

function ShopifySetup({
    onCompleteCallback,handleNextBUtton
}) {
    const history = useHistory();
    const [keysStored, setkeysStored] = useState(true);
    const [user, setUserData] = useState([]);

    useEffect(() => {
        fetchVideosData();
       
    }, []);

    
    const fetchVideosData = () => {
        let token =localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL+'/check-user-keys',{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer '+token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
          })
            .then((response) => response.json())
            .then((data) => {
                setkeysStored(false)
                // setVideotdata(data.data)
                // setLoader(true)
                if(data.code==200){
                    
                    setUserData(data.data)


                }else{

                }
         
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

   
    
    return (
        <>

        {/* {(keysStored)?<ImportShopifyProductNav propValue={user}/>:<ImportShopifyProductNav propValue={user}/>} */}

        {(keysStored)?<><div class="card-body"><div class="text-center"><div class="spinner-border" role="status"></div></div></div></>:<ImportShopifyProductNav handleNextBUtton={handleNextBUtton} propValue={user} onCompleteCallback={onCompleteCallback}/>}
        {/* <ImportShopifyProductNav propValue={user}/> */}
        
        </>
    );




}
export default ShopifySetup;