import jwtDecode from "jwt-decode";
import moment from "moment";
import { Card } from 'stripe-client';
import trans from "./trans";

export const loggedUserAccountType = () => {

  try{
    const authSessionToken  = window.localStorage.getItem("authSession");
    const { accessToken } = JSON.parse(authSessionToken);
    const { type } = jwtDecode(accessToken);
    if(!type){
      return localStorage.getItem('accounTypeAddedValue');
    }
    return type;
  } catch(error){
    return null;
  }
}

export const isTypeNullOrNotFilled = () => {
  // return true;
  const accounTypeAdded = localStorage.getItem('accounTypeAdded');
  return loggedUserAccountType()===null && accounTypeAdded!==loggedUserEmail();

}

export const loggedUserEmail = () => {
  try{
    const authSessionToken  = window.localStorage.getItem("authSession");
    const { accessToken } = JSON.parse(authSessionToken);
    const { email } = jwtDecode(accessToken);
    return email;

  } catch(error){
    return null;
  }
}

export const loggedUserName = () => {
  try{
    const authSessionToken  = window.localStorage.getItem("authSession");
    const { accessToken } = JSON.parse(authSessionToken);
    const { name } = jwtDecode(accessToken);
    return name;

  } catch(error){
    return null;
  }
}


export const isOnboardCompleted = () => {

  try{
    const authSessionToken  = window.localStorage.getItem("authSession");
    const { accessToken } = JSON.parse(authSessionToken);
    const { is_onboarding_complete } = jwtDecode(accessToken);

    const localOnboardingVal = localStorage.getItem('is_onboarding_complete'); 
    return localOnboardingVal===true || localOnboardingVal==='true' || is_onboarding_complete===true;
  } catch(error){
    return false;
  }
}

export const urlSegment = (segmentNumber) => {
    try {
      const segmentArray = (window.location.pathname).split('/').filter(item => item);
      if (!isNaN(segmentNumber) && segmentArray[segmentNumber]) {
        return segmentArray[segmentNumber];
      }
    } catch (error) {
      // Handle any errors that may occur
      console.error('An error occurred:', error);
    }
    return null;
  };

  export const translate = (key) =>{
    let userType = loggedUserAccountType();
    return trans['en'][userType][key];
  }

  export const showUserTypeBaseMessage = (messagesObject,userType=null) => {
    try{
      if(!userType){
        userType = loggedUserAccountType();
      }
      userType = userType?.replace(/ /g, '');

      // if(typeof messagesObject[userType]!=='undefined'){
      //   return messagesObject[userType];
      // } else {
        return messagesObject?.default;
      // }

    } catch (error){
      console.log('error',error);
      return null;
    }
 }


  export const removeEmptyProperties = (obj) => {
    for (let key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        delete obj[key];
      }
    }
    return obj;
  }

  export const getActualObjectLength = (obj) => {
    let count = 0;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value !== null && value !== undefined && value !== '') {
          count++;
        }
      }
    }

    return count;
  }

  export const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  export const userLoggedAccessToken = () => {
    try{
      const accessToken = JSON.parse(window.localStorage.getItem("authSession")).accessToken;
      return accessToken;
    } catch(error){
      //@TODO: logout user and redirect to login page
      
      window.location.href = "/";
      // history.push({
      //   pathname: '/',
      // });
    }
  }

  export const getPathFromUrl=(url) => {
    try{
      return url.split("?")[0];
    } catch(error){
      return null;
      //@TODO: logout user and redirect to login page
    }
  }
  
  export const getQueryString=(stringKey=null,customURL=null) => {
    try{
      const queryParams = new URLSearchParams(customURL!=null?customURL:window.location.search);
      return queryParams.get(stringKey);
    } catch(error){
      return null;
      //@TODO: logout user and redirect to login page
    }
  }

  // const params = queryString.parse(window.location.search);
  // const value = params.key1; // Get the value of 'key1'

  export const displayBreadCrumbs = () => {
    //
  }

  export const showNumberOrInfinity = (value) => {
    return value===100000000?'Unlimited':value;
  }

  export const showWidgetSharingCode = (widgetData) => {
    let endpoint="widget.0.5.js"
    if(process.env.REACT_APP_ENV === "production"){
      endpoint="widget.1.5.js"
    }
    
    return `<div id='vs-widget-container-${widgetData.app_id}' style="height: 600px"></div><script>(function (w,d,s,o,f,js,fjs) {w['JS-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);}(window, document, 'script', 'vsw', '${process.env.REACT_APP_WIDGET_URL}/${endpoint}'));vsw('app_key', '${widgetData.app_id}');let el = document.getElementById("webpack-dev-server-client-overlay");el?.remove();</script>`;
  }

  export const showIframeSharingCode = (videoUrl) => {
    return `<iframe src="${videoUrl.videoUrl}" frameborder="0" allowfullscreen style="left:0;width:100%;height:600px;"></iframe>`;
  }

  export const videoWatchUrl= (videoId) => {
    return process.env.REACT_APP_WEB_URL+'/watch/'+videoId;
  }

  export const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  export const videoVerticalResolutions = (resolutionKey = null) => {
    const resolutions = {
      1920 : { "width": 1080, "height": 1920 },
      1280 : { "width": 720, "height": 1280 },
      854 : { "width": 480, "height": 854 },
      720 : { "width": 420, "height": 720 },
      640 : { "width": 360, "height": 640 },
      480 : { "width": 480, "height": 720 },
      426 : { "width": 240, "height": 426 }
    }

    if (resolutionKey === null) {
      return resolutions;
    } else {
      // Check if the exact key exists, if not, find the nearest lower resolution key
      const availableKeys = Object.keys(resolutions).map(Number).sort((a, b) => b - a);
      const nearestLowerKey = availableKeys.find((key) => key <= resolutionKey) || availableKeys[0];
      return resolutions[nearestLowerKey];
    }
  }

  export const videoResolutions = (resolutionKey = null) => {
    const resolutions = {
      240: { "width": 426, "height": 240, "name": "240p", "short_name": "SD" },
      360: { "width": 640, "height": 360, "name": "360p", "short_name": "SD" },
      480: { "width": 854, "height": 480, "name": "480p (SD)", "short_name": "SD" },
      720: { "width": 1280, "height": 720, "name": "720p (HD)", "short_name": "HD" },
      1080: { "width": 1920, "height": 1080, "name": "1080p (Full HD)", "short_name": "FHD" },
      1440: { "width": 2560, "height": 1440, "name": "1440p (2K)", "short_name": "2K" },
      2160: { "width": 3840, "height": 2160, "name": "2160p (4K)", "short_name": "4K" },
      4320: { "width": 7680, "height": 4320, "name": "4320p (8K)", "short_name": "8K" }
    };
    
    if (resolutionKey === null) {
      return resolutions;
    } else {
      const resolutionObject = resolutions[resolutionKey];
      return resolutionObject;
    }
  };

  export const daysRemaining = (datetime) => {
    var eventdate = moment(datetime);
    var todaysdate = moment();
    return eventdate.diff(todaysdate, 'days');
  };

  export const browserName =() => {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf('Chrome') !== -1) {
      return 'Google Chrome';
    } else if (userAgent.indexOf('Firefox') !== -1) {
      return 'Mozilla Firefox';
    } else if (userAgent.indexOf('Edge') !== -1) {
      return 'Microsoft Edge';
    } else if (userAgent.indexOf('OPR') !== -1 || userAgent.indexOf('Opera') !== -1) {
      return 'Opera';
    } else if (userAgent.indexOf('Safari') !== -1) {
      return 'Safari';
    } else if (userAgent.indexOf('Trident') !== -1) {
      return 'Internet Explorer';
    } else if (userAgent.indexOf('SamsungBrowser') !== -1) {
      return 'Samsung Internet';
    } else if (userAgent.indexOf('Yandex') !== -1) {
      return 'Yandex Browser';
    } else if (userAgent.indexOf('Brave') !== -1) {
      return 'Brave Browser';
    } else if (userAgent.indexOf('Vivaldi') !== -1) {
      return 'Vivaldi';
    } else {
      return 'Unknown';
    }
  }

  export const extensionToFormat = (inputFormats) => {
    const formattedFormats = inputFormats
    ?.split(",")
    ?.map((extension) => `.${extension.trim()}`)
    ?.join(", ");
    return formattedFormats;
  }

  export const addLeadingZero = (number) => {
    if (number !== 1) {
      return number < 10 ? `0${number}` : number.toString();
    } else {
      return number.toString();
    }
  }
  export const convertToMMYYYY = (inputDate) => {
    // Parse the input date in "MM/YY" format
    const parsedDate = moment(inputDate, 'MM/YY', true);
  
    if (parsedDate.isValid()) {
      // Format the parsed date to "MM/YYYY" format
      const formattedDate = parsedDate.format('MM/YYYY');
      return formattedDate;
    } else {
      return inputDate;
    }
  }

  export const truncateText = (text, maxLength = 35) => {
    if (!text || typeof text === "undefined") {
      return "";
    }
    if (text?.length > maxLength) {
      return text?.slice(0, maxLength) + " ...";
    }
    return text;
  }

  export const htmlToPlainText = (html) => {
    if (!html || typeof html === "undefined") {
        return "";
    }
    
    try{
        return html.replace(/<[^>]+>/g, '');
    } catch (errorMessage) {
        return "";
    }
  }

  export const validateURL = (urlString) => {
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    return urlRegex?.test(urlString);

  }

  export const generateRandomString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  }

export const getDateDifferenceInMinutes = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);

  // Calculate the difference in milliseconds
  const diffInMilliseconds = date2 - date1;

  // Convert milliseconds to minutes
  const diffInMinutes = diffInMilliseconds / (1000 * 60);

  return diffInMinutes;
} 

export const isMobile = () => {
  return window?.matchMedia('(max-width: 768px)')?.matches;
  // const windowWidth = window.innerWidth;
  // const mobileBreakpoint = 768;
  // return windowWidth < mobileBreakpoint;
}

export const  isTabletDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isIOS = /ipad|android|android 3.0|xoom|sch-i800|playbook|tablet|kindle/i.test(userAgent);
  const isWindows = /windows/i.test(userAgent) && /touch/i.test(userAgent);
  const isAndroid = /android/i.test(userAgent) && !isWindows;

  return isIOS || isAndroid || isWindows;
}


export const allowedVideorecordTimeSeconds = (userSubscribedData) => {




  const videoTimeInSeconds = (userSubscribedData?.video_record_max_length?userSubscribedData?.video_record_max_length:0 );

  // const videoTimeLimitAddon = userSubscribedData?.subscribedAddons?.find(
  //   (addon) => addon?.addon_id === 2
  // );
  
  // let addonItemValue = videoTimeLimitAddon?.addonItems?.find(
  //   (item) => item?.addon_item_id === 3
  // )?.addon_item_value;

  // return (addonItemValue?addonItemValue:videoTimeInSeconds)*60;
  return (videoTimeInSeconds)*60;
}

export const allowedVideoTimeSeconds = (userSubscribedData) => {
  const videoTimeInSeconds = (userSubscribedData?.video_length_limit?userSubscribedData?.video_length_limit:0 );

  const videoTimeLimitAddon = userSubscribedData?.subscribedAddons?.find(
    (addon) => addon?.addon_id === 2
  );
  
  let addonItemValue = videoTimeLimitAddon?.addonItems?.find(
    (item) => item?.addon_item_id === 3
  )?.addon_item_value;

  return (addonItemValue?addonItemValue:videoTimeInSeconds)*60;
}

export const allowedAddonWatchTimeMinutes = (userSubscribedData) => {
  const sumAddonItemValue = userSubscribedData?.subscribedAddons?.reduce((sum, subscription) => {
    const addonItem = subscription.addonItems.find(item => item.addon_item_id === 2);
    if (addonItem) {
      sum += parseInt(addonItem.addon_item_value, 10);
    }
    return sum;
  }, 0);

  return sumAddonItemValue;
}

export const allowedAddonSteamTimeMinutes = (userSubscribedData) => {
  let sumAddonItemValue = userSubscribedData?.subscribedAddons?.reduce((sum, subscription) => {
    const addonItem = subscription.addonItems.find(item => item.addon_item_id === 1);
    if (addonItem) {
      sum += parseInt(addonItem.addon_item_value, 10);
    }
    return sum;
  }, 0);

  let totalAddon = 0;
  // let addonStreamMinutes;
  // addonStreamMinutes = 0;
  // totalAddon = 0;
  sumAddonItemValue = !isNaN(sumAddonItemValue)?sumAddonItemValue:0;

  let planStreamMinutes = userSubscribedData?.live_stream_from_video_squirrel;

  planStreamMinutes = !isNaN(planStreamMinutes)?planStreamMinutes:0;

  if(userSubscribedData?.live_stream_from_video_squirrel>=1){
    // addonStreamMinutes = sumAddonItemValue; 
    totalAddon = sumAddonItemValue+planStreamMinutes;
  }
  totalAddon = sumAddonItemValue;
  
  const returnData = {totalStreamMinutes:(sumAddonItemValue+planStreamMinutes),addonStreamMinutes:sumAddonItemValue,planStreamMinutes:planStreamMinutes};

  // console.log('returnData',returnData);
  // const returnData = {totalStreamMinutes:10,addonStreamMinutes:5,planStreamMinutes:5};

  return returnData;

}

export const extractNumberFromUserId = (userRawId) => {
  return userRawId?.split('|')[1];
}

export const formatCardExpiry = (cardExpiry)  => {
  // Check the format of the card expiry and parse accordingly
  const parsedDate =
    cardExpiry.length === 7
      ? moment(cardExpiry, 'MM/YYYY', true)
      : moment(cardExpiry, 'MM/YY', true);

  // Check if the parsed date is valid
  if (parsedDate.isValid()) {
    // Format the date as "01/MM/YYYY"
    return parsedDate.format('01/MM/YYYY');
  } else {
    // Handle invalid date
    return 'Invalid Date';
  }
}

export const calculateRemainingTime = (dateTimeParam,format) => {
  // const targetDate = moment(dateTimeParam,format);
  const targetDate = dateTimeParam;
  let result = '';
  if(targetDate){

    const now = moment();
    const duration = moment.duration(targetDate.diff(now));
    const days = duration.days();
    const months = duration.months();
    const hours = Math.floor(duration.asHours());
    const minutes = moment.utc(duration.asMilliseconds()).format('mm');
    const seconds = Math.floor(duration.asSeconds()) % 60;

  
    const secondsText = ` ${seconds} seconds`;
  
    if (days > 0) {
      result += `${days} day${days !== 1 ? 's' : ''}`;
      if (months > 0) {
        result += `, ${months} month${months !== 1 ? 's' : ''}`;
      }
    } else if (hours > 0) {
      result += `${hours} hour${hours !== 1 ? 's' : ''}`;
      if (minutes > 0) {
        result += `, ${minutes} minute${minutes !== 1 ? 's' : ''}${secondsText}`;
      }
    } else if(minutes > 0) {
      result += `${minutes} minute${minutes !== 1 ? 's' : ''}${secondsText}`;
    } else {
      result += `${secondsText}`;
    }
  }

  return result;
}

export const cameraErrorMessage = (err) => {
  switch (err?.name) {
    case "OverconstrainedError":
      return (
        "CameraError: The requested media constraints are not supported by your device."
      );
      // break;

    case "NotAllowedError":
    case "PermissionDeniedError":
      return ("CameraError: Permission Denied or not allowed");
      // break;

    case "NotFoundError":
      return ("CameraError: No camera or microphone");
      // break;

    case "NotReadableError":
      return ("CameraError: Not accessible or already in use.");
      // break;

    case "TypeError":
      return ("CameraError: Not accessible or already in use.");
      // break;

    default:
      console.log("err", err);
      console.log("err.name", err.name);
      return ("CameraError: Something went wrong.");
  }
}



export const toTitleCase = (str) => {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
  });
}

export const capitalizeFirstLetter = (str) => {
  if(typeof str==='string'){
    str = str?.toLowerCase();
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
  return str;
}


export const formatCardNumber = (number) => {
  // Check if Stripe.Card utility is available
  if (Card && Card.formatCardNumber) {
    return Card.formatCardNumber(number);
  } else {
    // Use a custom formatting function if Stripe.Card is not available
    return number.replace(/(\d{4})/g, '$1 ').trim();
  }
};

export const removeTrailingZeros = (number) => {

  const numberString = parseFloat(number)?.toString();
  const trimmedNumberString = numberString?.replace(/(\.\d*?[1-9])0*$/g, '$1');
  return number>=0?parseFloat(trimmedNumberString):number;
}


export const roundNumber = (num, precision=2) => {
  if (Number.isInteger(num) || precision === 0) {
    return num.toString();
  } else {
      const factor = 10 ** precision;
      const rounded = Math.round(num * factor) / factor;
      return rounded % 1 === 0 ? rounded.toString() : rounded.toFixed(precision);
  }
}


export async function getExternalRedirectedURL(fbWatchUrl) {
  const params = {
    url: fbWatchUrl,
  };

  const queryParams = new URLSearchParams(params);

  try {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "/get-redirected-url?" + queryParams,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    // if (response.ok) {
    //   // throw new Error("Error fetching URL");
    // }

    const data = await response.json();
    return data?.url;
  } catch (error) {
    console.error("error--------------------***", error);
    // throw error; // Re-throw the error to be caught by the caller
  }
};

export function stringToBase64(str) {
  return btoa(str);
}

export function base64ToString(base64Str) {
  return atob(base64Str);
}

export function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}

export function convertStorageUnit(value, from, to) {
  from = from?.toUpperCase();
  to = to?.toUpperCase();

  const units = {
      'B': 1,
      'KB': 1024,
      'MB': 1024 * 1024,
      'GB': 1024 * 1024 * 1024,
      'TB': 1024 * 1024 * 1024 * 1024,
      'PB': 1024 * 1024 * 1024 * 1024 * 1024,
      'EB': 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
      'ZB': 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
      'YB': 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024 * 1024
  };

  const bytes = value * units[from];
  return bytes / units[to];
}


export function getReelIdFromUrl (url) {
  // try {
  //   new URL(url);
  // } catch (e) {
  //   return null;
  // }
  // const regex = /\/reel\/(\d+)/;
  // const match = url.match(regex);

  var match = url.match(/reel%2F(\d+)/);
 
  if (match) {
    return match[1];
  } else {
    return null;
  }


}

export function newgetReelIdFromUrl (url) {

  var match = url.match(/reel\/(\d+)/);
  

 
  if (match) {
    return match[1];
  } else {
    return null;
  }


}