import React, { useState, useEffect } from "react";
// import Auth0 from "../../helpers/Auth0";
// import { isEmail } from "validator";
// import useAuthState from "../../hooks/useAuthState";
import SpinnerImage from "../../../assets/spinner.png";
// import { useHistory } from "react-router-dom";
function ShortUrl() {

  // const [error, setError] = useState();
  // const [success, setSuccess] = useState(false);
  // const { login } = useAuthState();
  // const [loader, setLoader] = useState(false);
  // const history = useHistory();

  useEffect(() => {
    fetchShortUrl();
  }, []);


  const fetchShortUrl = () => {
    let path = window.location.pathname;
    var confirmcode = path.split("/");
    var id = confirmcode[2];

    var token = "sajdain";
    fetch(process.env.REACT_APP_API_URL + '/find-video-url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": id }),
    })
      .then((response) => response.json())
      .then((data) => {
        let shorturl = process.env.REACT_APP_WEB_URL + '/' + data.data.url;
        window.location.replace(shorturl);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (<div class="preloader"><img src={SpinnerImage} alt="loader" class="lds-ripple img-fluid" /></div>);
}

export default ShortUrl;
