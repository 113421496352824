import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";
import "./StylingProductList.module.scss";
import ActivityLog from "../../helpers/ActivityLog";
// import * as helper from "../../helpers/helper";
// import ExpandableProductDetails from "./ExpandableProductDetails";
import Button from "./../../Form/Button";
import Table from "../../Table/Table";
import AddDataPrompt from "../../molecules/DataPrompt/AddingDataPrompt";

function LeadFormList({
  // products,
  // isLoading,
  // onProductRowClick,
  // // columns,
  // onAddProductClick,
  // isProductListScrollLoading,
  // searchText,
  // onUploadCSVClick,
  // onImportShopify
}) {

  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  // const [isExpand, setIsExpand] = useState(false);
  // const [currentRow, setCurrentRow] = useState(null);
  // const [expandViewType, setExpandViewType] = useState();

  // const [startdate, setStartdate] = useState('');
  // const [enddate, setEnddate] = useState('');
  // const [orderId, setOrderId] = useState('');

  // const [openDropdowns, setOpenDropdowns] = useState({});
 
 



  useEffect(() => {
    fetchVideosData();

  }, [currentPage, rowsperPage, searchValue]);


  const fetchVideosData = (isClear) => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);

    fetch(process.env.REACT_APP_API_URL + '/all-lead-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": token.idToken, "currentPage": currentPage, "rowsPage": rowsperPage, "search": searchValue }),
    })
      .then((response) => response.json())
      .then((data) => {

        setVideotdata(data.data)
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(true)

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchDeleteData = (id) => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/delete-lead-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "form_id": id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.code?.toString() === "200") {
          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem('admin_login_id');
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 10;
          var account_id = token.idToken;
          userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })
          setSuccess("Product deleted succesfully")
          setSelectedOrderId(null);
          fetchVideosData()
          setLoader(true)
          setTimeout(resetState, 1000);
        } else {
          setErrors("Something went wrong")
          setTimeout(resetState, 1000);
        }


      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setErrors("Something went wrong")
        setTimeout(resetState, 1000);
      });
  };

  const resetState = () => {
    setErrors('');
    setSuccess('');
  };


  // const titleHeadingText = helper.showUserTypeBaseMessage({ NonProfit: "Donation Campaign", default: "Product Name" });


  const Popup = ({ onClose, onDelete }) => {
    return (

      <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block", " padding-left": "0px" }} aria-modal="true" role="dialog">

        <div class="modal-dialog modal-sm" >
          <div class="modal-content modal-filled bg-light-danger">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7" onClick={onClose}></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">
                  You Want to delete Permanently?
                </p>
                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  };

//   const handleSubmit = (e) => {
//     e.preventDefault()
//     fetchVideosData();
//     setLoader(false)

// };


// const handleSubmitClear = (e) => {


//     setLoader(false)

//     setCurrentPage(1);
//     setrRowsperPage(10);
//     setSearchValue('');

//     setStartdate('');
//     setEnddate('');
//     setOrderId('');

//     fetchVideosData(true);  

// };

// const handleSubmitExport = (e) => {

//     const url = process.env.REACT_APP_API_URL;
//     let token = localStorage.getItem("authSession")
//     token = JSON.parse(token);
//     const user_id =token.idToken;
//     const newPath =`${url}/download?id=${user_id}&enddate=${enddate}&startdate=${startdate}&orderId=${orderId}&time_offset=${new Date().getTimezoneOffset()}`
//     window.location.href =newPath

// };




  // function setstartDateValue(value) {
  //   setStartdate(value);
  // }

  // function setendDateValue(value) {
  //   setEnddate(value);
  // }




  const handleDeleteClick = (id) => {
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId)
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // const handleClick = (id) => {

  //   history.push("/settings/edit-lead-form/" + id)

  // };

  const redirectview = (id) => {

    history.push(`/leads/${id}`)

  };

  const columns = [

    {
      name: "Title",
      selector: (row, i) => row.title,
      sortable: true,
      width: "430px"
    },
    {
      name: "Date",
      selector: (row, i) => row.created_at,
      sortable: true,
      center: true,
      width: "430px"
    },
    // {
    //   name: "View",
    //   selector: (row, i) => row.id,
    //   sortable: true,
    //   center: true,
    //   width: "180px",
    //   cell: (row) => {
    //     return <i className="ti ti-eye fs-8 cursor-pointer" onClick={(e) => redirectview(row?.id)}></i>;
    //   }
    // }

  ];


  const data = videodata;
  // const tableData = {
  //   columns,
  //   data
  // };

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    setCurrentPage(page);
    setrRowsperPage(newPerPage)

  };

  const handleSearchChange = (event) => {

    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false)

  };

  // const toggleShareView = (row, expandType) => {
  //   if (row?.id === currentRow?.id && expandType != expandViewType) {
  //     setCurrentRow(row);
  //   } else {
  //     setCurrentRow(currentRow == row ? null : row);
  //   }
  //   setExpandViewType(expandType);
  // };

  // const closeFromChild = () => {
  //   setCurrentRow(null);
  // }


  // const isButtonDisabled  = !startdate && !enddate && !orderId;


  
  if (loader && videodata && !videodata.length && !searchValue) {

    // if (addprompt === true) {
      return (
        <div className="data-promptWrapper">
          <AddDataPrompt   
              heading="Create Lead Forms" 
              title="Create a lead form to capture potential customer information easily." 
              link="add-lead-form" 
              btntxt="Create Lead Form"/>
        </div>
      );
    // }

  }


  return (
    <>
      <div className="App video-table">

        {showPopup ?
          <Popup
            onClose={handleClosePopup}
            onDelete={handleDelete}
          /> : ""}

        {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
        {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""}

        {/* <div id="complex_header_filter" class="dataTables_filter search_section data-table-extensions-filter">

          <label for="filterDataTable" class="icon "></label>

          <input
            type="text"
            value={searchValue}
            onChange={handleSearchChange}
            placeholder="Search"
          />

        </div> */}

        {/* <div className="d-flex justify-content-between align-items-center mb-9"> */}
        <div className="leads-listing-action-btns">
          <form class="position-relative">
            <input type="text" class="form-control search-chat py-2 ps-5 d-lg-inline" value={searchValue} placeholder="Search " onChange={handleSearchChange} />
            <i class="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
          </form>
          <div className="button-group mt-3">
          <Button
              listingButtonfilled={true}
              type="submit"
              buttonText="Add Lead Form"
              onClick={() => history.push("/add-lead-form")} 
            />
          </div>
        </div>



            <Table
              loading={!loader}
              columns={columns}
              data={data}           
              totalRows={totalRows}
              currentPage={currentPage}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              onRowClicked={(row) => redirectview(row.id)}
              actions={[

                (row) => ({
                  disabled: false,
                  icon: (<i className="fs-4 ti ti-edit"></i>),
                  label: "Edit",
                  title: "Edit",
                  onClick: () => history.push(`/edit-lead-form/${row.id}`),
                
                }),(row) => ({
                  disabled: false,
                  icon: (<i className="fs-4 ti ti-trash"></i>),
                  label: "Delete",
                  title: "Delete",
                  onClick: (row) => handleDeleteClick(row?.id)
                }),
                // (row) => ({
                //    disabled: false,
                //    icon: (<i className="fs-4 ti ti-eye"></i>),
                //    label: "View",
                //    title: "View",
                //    onClick: () => history.push(`/leads/${row.id}`),
                // })

              ]}

            />
    
      </div>

    </>

  );
}

export default LeadFormList;