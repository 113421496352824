import { useRef, useState } from "react";
import UploadThumbnailAddVideo from "./UploadThumbnailAddVideo";
import UploadHelper from "../../helpers/upload";
import VideoRecorder from "./VideoRecorder";

const RecordVideoFormFields = ({
    videoThumbnailURL,
    setVideoThumbnailURL,
    fileUploadLoading,
    setFileUploadLoading,
    setParentErrorMessage,
    maxVideoTimeAllowedInSeconds,
    setWowzaId,
    // wowzaId,
    // userSubscription
}) => {
    const videoRecorderRef = useRef(null);
    const [videoRecLoading, setRecVideoLoading] = useState(false);
    const [videoError, setVideoError] = useState("");
    const [videoPreview, setVideoPreview] = useState(null);
    const [isUploadVideoSelected, setIsUploadVideoSelected] = useState();
    const [browseVideoId, setBrowseVideoId] = useState();
    const [recordVideoId, setRecordVideoId] = useState();
    const [errorMessage, setErrorMessage] = useState(false);
    const [videoLoading, setVideoLoading] = useState(false);
    const [progress, setProgress] = useState(0.0);
    const [isVideoRecorded, setIsVideoRecorded] = useState(false);

    function handleProgressEvent({ loaded, total }) {
        setProgress(loaded / total);
    }



    const validateVideoDuration = async (file) => {
        setVideoError("");

        if (file) {
            return new Promise((resolve) => {
                const video = document.createElement("video");
                video.src = URL.createObjectURL(file);

                video.onloadedmetadata = () => {
                    const durationInSeconds = Math.floor(video.duration);
                    if (durationInSeconds > maxVideoTimeAllowedInSeconds) {
                        setVideoError(
                            "The maximum video duration should not exceed " +
                            maxVideoTimeAllowedInSeconds / 60 +
                            " minutes"
                        );
                        setVideoPreview(null);
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                };
            });
        }

        return false;
    };

    const uploadVideoToServer = async (videoFile, isRecordedVideo = false) => {
        const isVideoTimeValid = await validateVideoDuration(videoFile);
        if (!isVideoTimeValid && isRecordedVideo === false) {
            return false;
        }

        const blobUrl = URL.createObjectURL(
            new Blob([videoFile], { type: videoFile.type })
        );

        setWowzaId(null);
        setErrorMessage(null);
        setVideoError("");

        if (isRecordedVideo === false && videoFile.type !== "video/mp4") {
            setVideoError("Please upload video in MP4 format");
            setVideoPreview(null);
        } else if (videoFile) {
            // console.log(blobUrl,"ansss")
            setVideoPreview(blobUrl);

            if (isRecordedVideo !== true) {
                setVideoLoading(true);
            } else {
                setRecVideoLoading(true);
            }

            const uploadedVideoRes = await UploadHelper.getSignedUrl(
                UploadHelper.VIDEO
            );

            if (uploadedVideoRes) {
                await Promise.all([
                    UploadHelper.uploadToS3(
                        uploadedVideoRes.signedUrl,
                        videoFile,
                        handleProgressEvent
                    ),
                    UploadHelper.uploadToFDCServer(
                        videoFile,
                        uploadedVideoRes.key,
                        (e) => { }
                    )
                ]);

                setVideoLoading(false);

                if (isRecordedVideo === true) {
                    setIsVideoRecorded(true);
                    setRecVideoLoading(false);
                    setRecordVideoId(uploadedVideoRes.key);
                    videoRecorderRef.current.closeCameraPopup();
                } else {
                    setBrowseVideoId(uploadedVideoRes.key);
                }
                setWowzaId(uploadedVideoRes.key);
            }
        }
    };

    // useEffect(() => {
    //     console.log('isUploadVideoSelected',isUploadVideoSelected)
    //     setWowzaId(isUploadVideoSelected ? browseVideoId : recordVideoId);
    // }, [isUploadVideoSelected]);

    return (
        <div className="p-3 py-4">
            <h5 className="fw-bolder mb-1">Upload Video Thumbnail</h5>
            <p className="fs-4">File size should not be more than 2MB. JPG, JPEG, PNG & WEBP supported</p>
            <div className="d-flex flex-row justify-content-center gap-8 mt-2 mb-2">
                <UploadThumbnailAddVideo
                    videoThumbnailURL={videoThumbnailURL}
                    setVideoThumbnailURL={setVideoThumbnailURL}
                    fileUploadLoading={fileUploadLoading}
                    setFileUploadLoading={setFileUploadLoading}
                />
            </div>
            <h5 className="fw-bolder mb-1">Record Video *</h5>
            <p className="fs-4">Max record length should be {maxVideoTimeAllowedInSeconds/60} mins</p>

            <VideoRecorder
                setParentErrorMessage={setParentErrorMessage}
                videoLoading={videoRecLoading}
                ref={videoRecorderRef}
                uploadVideoToServer={uploadVideoToServer}
                isVideoRecorded={isVideoRecorded}
                setIsVideoRecorded={setIsVideoRecorded}
                setWowzaId={setWowzaId}
            />
        </div>
    );
};

export default RecordVideoFormFields;
