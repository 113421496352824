import React, { useState, useEffect } from "react";
import { Page } from "../../layouts/Page/";
// import { useQuery } from "@apollo/react-hooks";
// import { NewSubscriptionPlanLists} from "./queries";
// import { subscriptionPackagesNewPlans,UserCreditCards} from "./queries";
import { Sidebar } from "../../../component/molecules/Sidebar";
// import { NewSubscriptionPlanLists,UserNewSubscribedPlanData } from "../Settings/BillingInformationSettings/Queries";
// import { SubscribePlan } from "./mutations";
// import { useMutation } from "@apollo/react-hooks";
import stripeHelper from "../../helpers/StripeHelper";
// import { useNavigate } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";
import validator from "validator";
import {
  isMobile,
  urlSegment,
  formatCardExpiry,
  getQueryParam,
  base64ToString,
} from "../../helpers/helper";
import creditCarThumbImg from "../../../dist/images/profile/credit-card-xs.png";
// import BreadCrump from "../../Breadcrump/BreadCrump";
import { userLoggedAccessToken } from "../../helpers/helper";
import CreditCardExpireyField from "../Settings/BillingInformationSettings/CreditCardExpireyField";
import validateField from "../../helpers/AllValidtions";
// import { videoResolutions } from "../../helpers/helper";
// import bigTickIcon from "../../../../src/assets/icons/big-tick.svg";
import PackagesListing from "./PackagesListing";
import ActivityLog from "../../helpers/ActivityLog";
const moment = require("moment");

function Packages({showPackagesBoxesOnly=false}) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [cardNumber, setCardNumber] = useState();
  const [cardTitle, setCardTitle] = useState();
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVV, setCardCVV] = useState();
  const [planId, setPlanId] = useState();
  const [isValidExpiry, setIsValidExpiry] = useState(false);
  const [pageTitle, setPageTitle] = useState("Packages");
  const [headerTitle, setHeaderTitle] = useState("Subscription Management");
  const [cardNumberErrorMsg, setCardNumberErrorMsg] = useState("");
  const [cardTitleErrorMsg, setCardTitleErrorMsg] = useState("");
  const [cardExpiryErrorMsg, setCardExpiryErrorMsg] = useState("");
  const [cardCVVErrorMsg, setCardCVVErrorMsg] = useState("");
  const [generalPaymentErrorMsg, setGeneralPaymentErrorMsg] = useState("");
  const [utmSource, setUtmSource] = useState("");
  // const [subscribePlan] = useMutation(SubscribePlan);
  const [userCreditCardsData, setUserCreditCardsData] = useState([]);
  
  const [userSubscribedData, setUserSubscribedData] = useState([]);
  const [userCardLoader, setUserCardLoader] = useState();

  const [planBtnText, setPlanBtnText] = useState("Subscribe Plan");
  const [breadcrumText, setBreadcrumText] = useState("Upgrade Plan");
  const [subscribeEndPointURL, setSubscribeEndPointURL] =
    useState("/subscribe-plan");

  const isMobileSize = isMobile();
  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );

  const handlecardNumberChange = (e) => {
    var value = e.target.value;
    var rule = "card-number";
    setCardNumber(value);
    validateField(value, rule, setCardNumberErrorMsg);
  };

  const handlecardTitleChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setCardTitle(value);
    validateField(value, rule, setCardTitleErrorMsg);
  };

  const handlecardExpiryChange = (e) => {
    var value = e.target.value;
    var rule = "card-expiry";
    setCardExpiry(value);
    validateField(value, rule, setCardExpiryErrorMsg);
  };

  const handlecardCVVChange = (e) => {
    var value = e.target.value;
    var rule = "card-cvv";
    setCardCVV(value);
    validateField(value, rule, setCardCVVErrorMsg);
  };

  useEffect(() => {
    setPlanId("");

    // console.log(urlSegment(2));

    if (urlSegment(3) != null && urlSegment(3)?.length >= 1) {
      setPlanId(urlSegment(3));
      setPageTitle("");
      setHeaderTitle("Card Details");
    }

    if (urlSegment(2) == "purchase-addon") {
      setPlanBtnText("Subscribe");
      setPageTitle("");
      setBreadcrumText("Subscribe Add-on");
      setSubscribeEndPointURL("/subscribe-addon");
    }

    if (urlSegment(1) == "order-commission") {
      setPlanId(urlSegment(2));
      setPageTitle("Order Commission");
      setPlanBtnText("Pay");
      setBreadcrumText("Order Commission");
      setSubscribeEndPointURL("/pay-failed-order-commission");
    }

    console.log("urlSegment(1),urlSegment(2)", urlSegment(1), urlSegment(2));

    // if(urlSegment(2)=='purchase-addon'){
    //   setPlanBtnText('Subscribe');
    //   setBreadcrumText('Subscribe Add-on');
    //   setSubscribeEndPointURL('/subscribe-addon');
    // }

    setUtmSource(localStorage.getItem("utm_source"));

    fetchUserCreditCards();
    // fetchPackagesPlans();
    fetchSubscribedPackage();
  }, []);

  useEffect(() => {
    // setPlanId("");
    // // console.log(urlSegment(2));
    // if (urlSegment(3) != null && urlSegment(3)?.length >= 1) {
    //   setPlanId(urlSegment(3));
    //   setPageTitle("");
    //   setHeaderTitle("Card Details");
    // }
    // if(urlSegment(2)=='purchase-addon'){
    //   setPlanBtnText('Subscribe Addon');
    //   setBreadcrumText('Subscribe Addon');
    //   setSubscribeEndPointURL('/subscribe-addon');
    // }
    // setUtmSource(localStorage.getItem("utm_source"));
    // fetchUserCreditCards();
    // fetchPackagesPlans();
    // fetchSubscribedPackage();
    // setPlanId(packageObject.id);
    //
    // if(!isValidExpiry){
    //   setCardExpiryErrorMsg("Invalid Expiry")
    // }
  }, [isValidExpiry]);

  const fetchUserCreditCards = () => {
    setUserCardLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/user-credit-cards", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({}), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        // setData(data.data)

        setUserCreditCardsData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setUserCardLoader(false);
        // console.error("Error fetching data:", error);
      });
  };



  const fetchSubscribedPackage = () => {
    fetch(process.env.REACT_APP_API_URL + "/user-new-subscribed-plan-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ forceToFetch: true }), // forceToFetch will fetch expired
    })
      .then((response) => response.json())
      .then((data) => {
        setUserSubscribedData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const subscribeThePlan = (subscriptionsInputParam) => {
    setLoader(true);
    fetch(process.env.REACT_APP_API_URL + subscribeEndPointURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify(subscriptionsInputParam), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {

        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        const userLog = new ActivityLog();
        var user_id = token.idToken;
        var is_admin = 0;
        var event_id = 23;
        var account_id = token.idToken;
        userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

        if (data.code == 200) {
          const redirectBackTo = getQueryParam("redirect")
          if (redirectBackTo) {
            history.replace(base64ToString(redirectBackTo))
          }else {
            history.push("/settings/subscription");
          }
        } else {
          setGeneralPaymentErrorMsg(
            data.message ? data.message : "Something went wrong "
          );
        }
        // setUserSubscribedData(data?.data);
      })
      .catch((err) => {
        setGeneralPaymentErrorMsg(
          err.message ? err.message : "Something went wrong "
        );
        console.error("Error fetching data:", err);
      })
      .finally((err) => {
        setLoader(false);
      });
  };

  function isAssignedCurrentPackage(packageData, assignedUserPackage) {
    if (packageData && assignedUserPackage) {
      if (
        packageData.hasOwnProperty("id") &&
        assignedUserPackage.hasOwnProperty("id")
      ) {
        // if(assignedUserPackage?.length>=1){

        const isPackageExpired = moment(
          assignedUserPackage.current_period_end
        ).isBefore(moment());

        if (
          packageData.plan_type == "paid" &&
          assignedUserPackage.plan_type == "paid" &&
          isPackageExpired == true
        ) {
          return false; //expired package can be re-subscribed!
        }

        if (
          packageData.plan_type == "free" &&
          assignedUserPackage.plan_type == "paid"
        ) {
          return true; //paid user cannot subscribe free package.
        }

        if (
          assignedUserPackage.status == "Cancelled" &&
          assignedUserPackage.plan_type != "free"
        ) {
          return false; //show subscribe button on paid package if already cancelled!
        }

        if (packageData.id === assignedUserPackage.subscription_plan) {
          return true;
        }
        // }
      }
    } else {
      //user cannot subscribe free package if already used free or paid package!
      if (packageData?.plan_type == "free") {
        return true; //
      }
      //expired package
    }
    return false;
  }

  const handleCardCVVChange = (event) => {
    const inputValue = event.target.value;
    if (!inputValue) {
      setCardCVVErrorMsg("This field is required");
    }
    const numericValue = inputValue.replace(/\D/g, "");
    setCardCVV(numericValue);
  };

  function setUpdateCreditFieldNumber(creditCardValue) {
    setCardNumberErrorMsg("");
    const creditCardValueLength = creditCardValue?.replace(/\s+/g, "")?.length;

    if (creditCardValueLength <= 16) {
      setCardNumber(formatCreditCard(creditCardValue));
    }

    if (validator.isEmpty(creditCardValue)) {
      setCardNumberErrorMsg("This field is required");
    }
  }

  function formatCreditCard(creditCardNumber) {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = creditCardNumber.replace(/[^\d]/g, "");

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(" ")
    );
  }

  const handleCardExpiryChange = (e) => {
    let inputValue = e.target.value;
    if (inputValue?.length >= 8) {
      let yearMonth = inputValue.split("-");

      let yearValue = yearMonth[0];
      let monthValue = yearMonth[1];
      inputValue = yearValue.slice(0, 4) + "-" + monthValue;
    }

    setCardExpiry(inputValue);
  };

  async function handlePackageSubscribe(creditCardData = null) {
    if (creditCardData) {
      // try {
      const subscriptionInput = {
        planId: planId,
        creditCardId: creditCardData.id,
        cardTitle: creditCardData.title,
        isCardSelected: true,
        expiry: moment(creditCardData.expiry).format("YYYY/MM"),
        cardLastDigits: creditCardData.card_last_digits,
      };

      subscribeThePlan(subscriptionInput);
    } else {
      const cardholderName = cardTitle;
      const cvv = cardCVV;
      const cardExpiryMMYY = moment(cardExpiry).format("MM/YY");
      // const cardExpiryMMYYYY = moment(cardExpiry).format("01/MM/YYYY");
      // const cardExpiryYear = moment(cardExpiry).format("YYYY");
      // const expiration = cardExpiryMMYY;

      let isValidCreditCardInfo = false;

      // if(cardNumber==null || (  validator.isEmpty(cardNumber) || !validator.isCreditCard(cardNumber) )){
      //   setGeneralPaymentErrorMsg('Credit card number is invalid');
      // // } else if(cardExpiry==null || (  validator.isEmpty(cardExpiry) || !moment(cardExpiry).isValid() ) || (  !validator.isInt(cardExpiryYear,{gt:2022,lt:3000}) ) ){
      // } else if(!isValidExpiry){
      //   setGeneralPaymentErrorMsg('Invalid Expiry');
      // } else if(cvv==null || (  !validator.isInt(cvv,{lt:9999,gt:99}) )){
      //   setGeneralPaymentErrorMsg('Invalid CVV');
      // } else {
      //   setGeneralPaymentErrorMsg(null);
      //   isValidCreditCardInfo = true;
      // }

      isValidCreditCardInfo = true;

      if (isValidCreditCardInfo === false) {
        return false;
      }

      setLoader(true);

      const data = {
        cardholderName: cardholderName,
        cardNumber: cardNumber,
        expiration: cardExpiry,
        cvv: cvv,
      };

      try {
        const response = await stripeHelper.createTokenFromCard(data);
        const body = await response.json();

        const tokenId = body.id;

        const subscriptionInput = {
          planId: planId,
          creditCardId: tokenId,
          isCardSelected: false,
          cardTitle: cardholderName,
          // expiry: moment(cardExpiry, "MM/YYYY", true).format("01/MM/YYYY"),
          expiry: formatCardExpiry(cardExpiry),
          cardLastDigits: body.card.last4,
        };

        subscribeThePlan(subscriptionInput);
      } catch (err) {
        setLoader(false);
        setGeneralPaymentErrorMsg(
          err.message ? err.message : "Something went wrong "
        );
      }
    }
  }

  const isButtonDisabled =
    !cardNumber ||
    !cardExpiry ||
    !cardCVV ||
    cardNumberErrorMsg ||
    cardExpiryErrorMsg ||
    cardCVVErrorMsg;

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  return (
    <div
      className={`page-wrapper  ${
        sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
      }`}
      data-sidebartype={sidebarType}
      id="main-wrapper"
      data-theme="blue_theme"
      data-layout="vertical"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} />

      <Page onNavBarClick={onNavBarClick} title={pageTitle}>
        {/* <BreadCrump
          title={headerTitle}
          image={subscriptionThumbImg}
          breadCrumpDetail={[
            { name: "Dashboard", link: "/" },
            { name: "Subscription", link: "/settings/subscription" },
            { name: breadcrumText, link: "" },
          ]}
          tagLine={showUserTypeBaseMessage({
            Brand:
              "Subscribe to our Shoppable Video Plans and Join the World of Interactive Shopping",
            default: "",
          })}
        /> */}
        
        {/* <h3 className="mb-3 fw-bolder">Subscription Management</h3> */}


        <h4 className="mb-4">{pageTitle}</h4>
        <div className="container-fluid form-width">
          {(planId != null && planId?.length >= 1) ||
          urlSegment(1) === "order-commission" ? (
            <div className="d-flex justify-content-center">
              {userCardLoader && (  
                <div>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              )}

              {typeof userCreditCardsData !== "undefined" &&
              userCreditCardsData?.length >= 1 ? (
                <div className="col-md-8">
                  {generalPaymentErrorMsg ? (
                    <div className="alert alert-danger" role="alert">
                      {generalPaymentErrorMsg}
                    </div>
                  ) : (
                    ""
                  )}

                  <h4 className="mb-3">Choose Card</h4>
                  {/* <div className="d-flex justify-content-center"> */}
                  {userCreditCardsData.map((userCreditCard, index) => (
                    <div className="col-md-12">
                      <div className="card card-body">
                        {/* <div className="row align-items-center justify-content-between"> */}
                        <div className="row">
                          {/* <div className="col-sm-2 col-lg-3 col-xl-1 card-icon">
                  <i className="ti ti-credit-card text-muted fs-7"></i>
                </div> */}
                          <div className="col-sm-2 nlp nrp">
                            <img src={creditCarThumbImg} />
                          </div>

                          <div className="col-sm-8 col-lg-6 col-xl-6 card-details nrp">
                            <h5
                              className="note-title text-truncate w-75 mb-0"
                              data-noteheading="Book a Ticket for Movie"
                            >
                              Credit Card Ending In
                              {/* {userCreditCard.title} */}
                            </h5>
                            {/* <p className="note-date">EXPIRY {moment(userCreditCard.expiry).format('MM/YYYY')}</p> */}
                            <div className="note-content">
                              <h4 className="note-inner-content">
                                **** **** **** {userCreditCard.card_last_digits}
                              </h4>
                            </div>
                          </div>
                          <div className="col-sm-2 col-lg-4 col-xl-4 justify-content-center align-items-center d-flex">
                            <button
                              type="submit"
                              onClick={(e) =>
                                handlePackageSubscribe(userCreditCard)
                              }
                              disabled={loader}
                              className="btn btn-primary text-white"
                            >
                              <div className="d-flex align-items-center">
                                {loader ? (
                                  <div>
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </div>
                                ) : (
                                  <div>{planBtnText}</div>
                                )}
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* </div> */}
                </div>
              ) : (
                <div
                  className="col-lg-6"
                  style={{ display: userCardLoader ? "none" : "" }}
                >
                  <div className="pac">
                    <div className="px-4 py-3 border-bottom">
                      <h5 className="card-title packag-title-name mb-0">
                        Add Card Details
                      </h5>
                    </div>

                    <div className="card-body p-4">
                      {generalPaymentErrorMsg ? (
                        <div className="alert alert-danger" role="alert">
                          {generalPaymentErrorMsg}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="mb-4">
                        <label
                          htmlFor="cc-number"
                          aria-required
                          className="form-label fw-semibold"
                        >
                          Card Number: *
                        </label>
                        <input
                          type="text"
                          id="cc-number"
                          maxLength={19}
                          value={cardNumber}
                          onChange={(e) =>
                            setUpdateCreditFieldNumber(e.target.value)
                          }
                          placeholder="Credit Card Number"
                          className="form-control"
                        />
                        {cardNumberErrorMsg ? (
                          <small className="text-danger">
                            {cardNumberErrorMsg}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="mb-4">
                        <label
                          htmlFor="cc-title"
                          aria-required
                          className="form-label fw-semibold"
                        >
                          Name on card:
                          <small className="text-muted"> (Optional)</small>
                        </label>
                        <input
                          type="text"
                          id="cc-title"
                          value={cardTitle}
                          onChange={(e) => setCardTitle(e.target.value)}
                          placeholder="Name on card"
                          className="form-control"
                        />
                      </div>

                      <div className="row mb-4">
                        <div className="col-lg-7 col-md-7 credit-card-div">
                          <label
                            htmlFor="cc-expiry"
                            aria-required
                            className="form-label fw-semibold"
                          >
                            Expiry: *
                          </label>
                          <CreditCardExpireyField
                            defaultValue={cardExpiry}
                            setIsValidExpiry={setIsValidExpiry}
                            setCardExpiry={setCardExpiry}
                            setCardExpiryErrorMsg={setCardExpiryErrorMsg}
                          />
                          {/* <input type="month" id="cc-expiry" maxLength={cardNumber?.length>=1?10:4} value={cardExpiry} onChange={handleCardExpiryChange} className="form-control" data-date-format="MM-YY" /> */}
                          {cardExpiryErrorMsg ? (
                            <small className="text-danger">
                              {cardExpiryErrorMsg}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-lg-5 col-md-5">
                          <label
                            htmlFor="cc-cvv"
                            aria-required
                            className="form-label fw-semibold"
                          >
                            CVV: *
                          </label>
                          <input
                            type="tel"
                            id="cc-cvv"
                            maxLength={4}
                            value={cardCVV}
                            onChange={(e) => handleCardCVVChange(e)}
                            className="form-control"
                          />
                          {cardCVVErrorMsg ? (
                            <small className="text-danger">
                              {cardCVVErrorMsg}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* <button onClick={(e) => handlePackageSubscribe()} className="btn btn-primary">Subscribe Plan</button> */}
                      <button
                        type="submit"
                        onClick={(e) => handlePackageSubscribe(null)}
                        disabled={isButtonDisabled}
                        className="btn btn-primary text-white"
                      >
                        <div className="d-flex align-items-center">
                          {loader ? (
                            <div>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </div>
                          ) : (
                            <div>{planBtnText}</div>
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (



            <PackagesListing userSubscribedData={userSubscribedData} />


          )}
        </div>
      </Page>
    </div>
  );
}

export default Packages;
