import React, { useEffect, useState } from "react";
import {
  WidgetPageHeader,
  WidgetList,
  // VideoTabs
} from "../../molecules/IntegrationOnWeb";
import { Page } from "../../layouts/Page";

import { getColumnWidth, onColumnResizeHof } from "./helpers";
import useInputState from "../../hooks/useInputState";
// import { useFilters, useVideoList, useProductList } from "./hooks";
import {
  BROADCAST_TABLE_COLUMNS,
  VIDEO_TABLE_COLUMNS,
  ARCHIVED_TABLE_COLUMNS,
} from "./constants";
// import {EditVideo} from "./mutations";
import { Sidebar } from "../../molecules/Sidebar";
import { useHistory } from "react-router-dom";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/integeration.png";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import PageHeading from "./../../Form/PageHeading";
import { TableContainer } from "./../Videos/Videos";

function IntegrationsOnWeb() {
  const isMobileSize = isMobile();
  const history = useHistory();

  const [isVideoDetailsOpen, setIsVideoDetailsOpen] = useState(false);
  const [columnWidths, setColumnWidths] = useState([]);
  const [selectedTab, setSelectedTab] = useState("videos");
  const [searchText, setSearchText] = useInputState("");
  const [isLoading, setIsLoading] = useInputState(false);
  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [addprompt, setAddprompt] = useState(false);

  useEffect(() => {
    fetchcountWidgetData();
  }, []);

  const fetchcountWidgetData = () => {
    let token = localStorage.getItem("authSession");
     token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/all-widgets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({
        id: token.idToken,
        currentPage: currentPage,
        rowsPage: rowsperPage,
        search: searchValue,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        var add = data.total <= 0 ? true : false;

        setAddprompt(add);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        // setLoader(false);
      });
  };

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  // const onColumnResize = onColumnResizeHof({
  //   columnWidths,
  //   setColumnWidths,
  //   columns:
  //     selectedTab === "broadcasts"
  //       ? BROADCAST_TABLE_COLUMNS
  //       : (selectedTab === "videos")
  //       ? VIDEO_TABLE_COLUMNS
  //       : ARCHIVED_TABLE_COLUMNS
  // });

  // const {
  //   filters,
  //   setFilters,
  //   filterOptions,
  //   loading: isFiltersLoading
  // } = useFilters(selectedTab);

  // const pageTitle =showUserTypeBaseMessage({Brand: 'Embed Videos to Web', default:'Integration to Web'});
  // const breadCrumb2ndLink =showUserTypeBaseMessage({Brand: 'Embed Videos to Web', default:'Integration to Web'});

  const pageTitle = showUserTypeBaseMessage({
    Brand: "Embed Videos to Web",
    default: "Generate Embed Code",
    NonProfit: "Generate Embed Code",
    Influencer: "Generate Embed Code",
    Individual: "Generate Embed Code",
  });

  const windowTitle = showUserTypeBaseMessage({
    Brand: "Embed Videos to Web",
    default: "Embed Videos to Web",
    NonProfit: "Embed Videos to Web",
    Influencer: "Embed Videos to Web",
    Individual: "Embed Videos to Web",
  });


  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page onNavBarClick={onNavBarClick} title={windowTitle} flex>
        <PageHeading text={pageTitle} />
        <div>
          {addprompt ? (
            ""
          ) : (
            <WidgetPageHeader
              isLoading={isLoading}
              onSearchTextChange={setSearchText}
              // filterOptions={filterOptions}
              uploadBtnText={
                selectedTab === "broadcasts" ? "Add Broadcast" : "Add Video"
              }
            // filters={filters}
            // setFilters={setFilters}
            // searchText={searchText}
            />
          )}

          <TableContainer>
          <WidgetList
            // filters={filters}
            searchText={searchText}
            isLoading={isLoading}
            columnWidths={columnWidths}
            getColumnWidth={getColumnWidth(selectedTab)}
            // onColumnResize={onColumnResize}
            selectedTab={selectedTab}
          />
          </TableContainer>
        </div>
      </Page>
    </div>
  );
}

export default IntegrationsOnWeb;
