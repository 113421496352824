import React from 'react';
import './index.css';
import App from './component/App/App';
import reportWebVitals from './reportWebVitals';

import { createRoot } from 'react-dom/client';
import {
    loadGAScript
  } from './component/helpers/GAEvents';
  loadGAScript();
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
reportWebVitals();