import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styles from "../Login/LoginForm.module.scss";
import OtpInput from 'react-otp-input';
import * as helper from '../../helpers/helper';
import Button from "./../../Form/Button";
import { useHistory } from "react-router-dom";
import FormToast, { TYPES } from "./../../Form/FormToast";



function OTPVerificationForm({
  otp,
  setOtp,
  error,
  success,
  disabled,
  onSubmit,
  loader,
  setLoader
}) {
  const history = useHistory();
  const [expire, setExpire] = useState(false);
  const [timer, setTimer] = useState(120);
  const [isInputDisabled, setIsInputDisabled] = useState(true);
  const [isTriggered, setIsTriggered] = useState(false);
  const [isVerifyOtp, setIsVerifyOtp] = useState(null);
  const [sendOtpLoader, setSendOtpLoader] = useState(false);

  const [OTPError, setOTPError] = useState(null);


  useEffect(() => {
    const t = timer > 0 && setInterval(() => setTimer(timer - 1), 1000);
    const verifyOTPCode = helper.urlSegment(1);

    if (verifyOTPCode != null) {
      setOtp(verifyOTPCode);
      setTimeout(function () {
        if (isTriggered == false) {
          onSubmit();
          setIsTriggered(true);
        }
      }, 2000);
    }
    if (timer == 0) {
      setExpire(true)
      setIsInputDisabled(false)
    }

    return () => clearInterval(t);
  }, [timer]);


  useEffect(() => {
    const send_otp = helper.getQueryString('send_otp');
    if (send_otp == 'true') {
      resendOtp(null);
    }
  }, []);

  const resendOtp = (e) => {
    if (e) {
      e.preventDefault()
    }


    setOTPError(null);

    setSendOtpLoader(true);
    const fetch_prods = new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + '/resend-otp', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email": localStorage.getItem("otp-email")
        })
      }).then(response => {
        setSendOtpLoader(false);

        if (response.ok) {
          response.json().then(json => {

            setTimer(60);
            if (json.code == 200) {
              setTimer(60)
            } else {
              if (json?.message) {
                setOTPError(json?.message);
                // error = {title:'OTP Verification',message:json?.message};
              }
            }

          });
        } else {
          setTimer(60);
          response.json().then(json => {
            if (json?.message) {
              setOTPError(json?.message);
            }
          });

        }
      }).
        catch((error) => {
          setSendOtpLoader(false);
          setOTPError('OTP Resend: something went wrong!');

        });
    });
    return fetch_prods;




  }

  return (
    <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
      <div class="col-sm-7 col-md-7 col-xl-7">

        <h2 className="fw-bold fs-7 mb-3">Verify Your Video Squirrel Account</h2>
      <div>
        <p className="mb-3">Enter the 6 digits verification code sent to your registered email address & click "Verify OTP"</p>
        </div>
        
      <form className={styles.register} onSubmit={onSubmit}>

        {(OTPError)  && (
          // <Callout
          //   intent={Intent.WARNING}
          //   title='OTP Verification'
          //   style={{ marginBottom: "1em" }}
          //   icon={null}
          // >
          //   {OTPError}
          // </Callout>

          <FormToast type={TYPES.WARNING}>{OTPError}</FormToast>

        )}

        {error && (

          <FormToast type={TYPES.WARNING}>{error?.message}</FormToast>

          // <Callout
          //   intent={Intent.WARNING}
          //   title={error.title}
          //   style={{ marginBottom: "1em" }}
          //   icon={null}
          // >
          //   {error.message}
          // </Callout>
        )}

        {success && (

          <FormToast type={TYPES.SUCCESS} message="OTP has been Verified Successfully"></FormToast>


          // <Callout intent={Intent.SUCCESS} title="OTP Verification!">
          //   OTP has been Verified Successfully
          // </Callout>
        )}

        {!success && (
          
          <OtpInput
            id={"OTP_" + otp}
            value={otp}
            onChange={setOtp}
            isInputNum={isInputDisabled}
            numInputs={6}
            isDisabled={true}
            // renderSeparator={<span> </span>}
            renderInput={(props) => <input {...props} />}
            containerStyle="d-flex align-items-center justify-content-center Otp-div"
            inputStyle="form-control Otp-inp"
            required={true}
          />
        
        )}
        <div className="mb-5 otp-expire-sec">
        <p className="mb-0 ">Resend OTP in {timer} Secs</p>
      </div>

        <div className="">
          <Button
            type="submit"
            buttonText="Verify OTP"
            fullButton={true}
            loading={loader}
            disabled={disabled}
          />
        </div>
      </form>
      <div className="">
            <a
              className={"btn w-100 mb-1 py-3 waves-effect waves-light btn-outline-primary"}
              buttonText="Go Back"
              onClick={(e) => {
                history.push(history.push("/login"));
              }}
            >Go Back</a>
          </div>

      {(!isInputDisabled) && timer <= 0 ? <p class="fs-4 mb-0 mt-5 fw-medium">Not received?<a class="text-primary fw-medium ms-2" disabled={sendOtpLoader || timer >= 1} href="#" onClick={resendOtp}>Resend OTP</a></p> : ""}



      </div>

    </div>
    

  );
}

export default OTPVerificationForm;
