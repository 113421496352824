import React, { useEffect, useState } from "react";
// import classnames from "classnames";
// import ReactDOM from "react-dom";
// import DataTable from "react-data-table-component";
// import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index";
// import "./StylingOrderList.module.scss";
// import styles from "./OrderList.module.scss";
import { useHistory} from "react-router-dom";
// import { SocialShare } from "../../molecules/SocialShare";
import ActivityLog from "../../helpers/ActivityLog";
import Button from "./../../Form/Button";
import Table from "../../Table/Table";
import AddDataPrompt from "../../molecules/DataPrompt/AddingDataPrompt";

// import {
//   useTable,
//   useSortBy,
//   useFlexLayout,
//   useResizeColumns,
//   useExpanded
// } from "react-table";

const OrderList = (
  // {
  // isLoading,
  // loadingMore,
  // orders,
  // // columns,
  // onSortPress,
  // onStatusChange,
  // emptyMessage = "No Orders Found",
  // sort
// }
) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);
  const [allcolumn, setAllcolumn] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [theFormId, setTheFormId] = useState('');



  const [leadForm, setLeadForm] = useState(5);
  // const [leadLoader, setLeadLoader] = useState(false);
  // const [leaddata, setLeaddata] = useState([]);

  // const [openDropdowns, setOpenDropdowns] = useState({});
  const [coloumnWidth, setColumnWidth] = useState();

  // const handleToggleDropdown = (rowId) => {
  //   setOpenDropdowns(prevState => ({
  //     ...prevState,
  //     [rowId]: !prevState[rowId]
  //   }));
  // };

  useEffect(() => {
    fetchVideosData();
  }, [currentPage, rowsperPage, searchValue,leadForm]);

  // useEffect(() => {
  //   fetchLeadData()
  // }, []);

  useEffect(() => {
    fetchLeadDataForm()
  }, []);


  const fetchLeadDataForm = () => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/user-lead-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "userId": token.idToken }),
    })
      .then((response) => response.json())
      .then((data) => {
        setLeadForm(data.data.id)
        fetchVideosData();



      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  };


  // const fetchLeadData = () => {
  //   let token = localStorage.getItem("authSession")
  //   token = JSON.parse(token);
  //   fetch(process.env.REACT_APP_API_URL + '/all-lead-form-list', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + token.accessToken,
  //     },
  //     body: JSON.stringify({ "userId": token.idToken }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {

  //       setLeaddata(data.data)
  //       setLeadLoader(true)

  //     })
  //     .catch((error) => {
  //       // console.error('Error fetching data:', error);
  //     });
  // };

  const fetchVideosData = () => {
    var token = localStorage.getItem("authSession")

    let path = window.location.pathname;
    var confirmcode = path.split("/");
    var formid = confirmcode[2];

    setTheFormId(formid);
    // theFormId, setTheFormId

    



    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/all-leads-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": token.idToken, "currentPage": 1, "rowsPage": rowsperPage, "search": searchValue, "form_id": formid }),
    })
      .then((response) => response.json())
      .then((data) => {

       

        let totalColoumn = data.data.table_header.length
        setColumnWidth(100 / totalColoumn)

        // console.log(totalColoumn, "asdasd")

        setVideotdata(data.data.table_column);
        setAllcolumn(data.data.table_header)
        // setTotalRows(100);
        // setCurrentPage(1);
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(true)

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


  const fetchDeleteData = (id) => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/delete-lead', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        // setVideotdata(data.data)
        if (data.code?.toString() === "200") {

          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem('admin_login_id');
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 7;
          var account_id = token.idToken;
          userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })


          setSuccess("Lead deleted succesfully")
          setSelectedOrderId(null);
          fetchVideosData()
          setLoader(true)
          setTimeout(resetState, 1000);
        } else {
          setErrors("Something went wrong")
          setTimeout(resetState, 1000);
        }

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setErrors("Something went wrong")
        setTimeout(resetState, 1000);

      });


  };

  // const handleClick = (id) => {
  //   console.log(`You clicked me! ${id}`);
  //   history.push("/edit-lead/" + id)
  // };

  const handleDeleteClick = (id) => {
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId)
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const resetState = () => {
    setErrors('');
    setSuccess('');
  };

  const Popup = ({ onClose, onDelete }) => {
    return (

      <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block", " padding-left": "0px" }} aria-modal="true" role="dialog">

        <div class="modal-dialog modal-sm" >
          <div class="modal-content modal-filled bg-light-danger">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7" onClick={onClose}></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">
                  You Want to delete Permanently?
                </p>
                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  };


  const transformArrayToColumns = (inputArray = []) => {
    const columns = inputArray.map((columnName) => {

      if (columnName === "Edit") {
        return {
        };
      } else if (columnName === "Status") {
        return {
          name: "Status",
          cell: (row) => (
            <span className={`mb-1 badge font-medium text-success d-flex align-items-center  ${row.Status === "Completed" ? "bg-status-light-success" : row.Status === "Pending" ? "bg-status-light-warning" : ""}`} title={row.Status}>{row.Status}</span>
          )
          ,
          width: coloumnWidth + '%',
          sortable: false


        }
      } else {
        return {
          name: columnName.replace(/_/g, ' '), // Replace underscores with spaces
          selector: (row, i) => row[columnName], // Assuming your data keys are lowercase
          sortable: true,
          width: coloumnWidth + '%',
        };
      }


    });

    return columns;
  };



  const inputArray = allcolumn;
  const columns = transformArrayToColumns(inputArray);

  const data = videodata;
  // const tableData = {
  //   columns,
  //   data
  // };

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    setCurrentPage(page);
    setrRowsperPage(newPerPage)

  };

  const handleSearchChange = (event) => {

    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false)

  };

  // const handleLeadChange = (e) => {
  //   var value = e.target.value;
  //   setLeadForm(value);

  // };

  if (loader && videodata && !videodata.length && !searchValue) {

    // if (addprompt === true) {
      return (
        <div className="data-promptWrapper">
          <AddDataPrompt   
              heading="Add Lead" 
              title="Add a lead against a video with our easy-to-use form." 
              link={`/add-lead/${theFormId}` } 
              btntxt="Add Lead"/>
        </div>
      );
    // }

  }

  return (
    <>

      <div className="App video-table">

        {showPopup ?
          <Popup
            onClose={handleClosePopup}
            onDelete={handleDelete}
          /> : ""}
        {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
        {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""}

        <div className="leads-listing-action-btns">
          <form class="position-relative">
            <input type="text" class="form-control search-chat py-2 ps-5 d-lg-inline" value={searchValue} placeholder="Search " onChange={handleSearchChange} />
            <i class="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
          </form>
         

          <div className="form-group filter-input d-flex align-items-end">
            <div className="ml-5">
            
            <Button
              className="btn btn-primary fs-4 text-white"
                listingButtonfilled={true}
                type="submit"
                buttonText="Add Lead"
                onClick={() => history.push(`/add-lead/${theFormId}`)}
              />
              </div>


          </div>


        </div>

      

      

         
            <Table
              loading={!loader}
              columns={columns}
              data={data}
              totalRows={totalRows}
              currentPage={currentPage}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              actions={[
                (row) => ({
                  disabled: false,
                  icon: (<i className="fs-4 ti ti-edit"></i>),
                  label: "Edit",
                  title: "Edit",


                  onClick: () => {
                    history.push({
                      pathname: `/edit-lead/${row.Edit}`,
                      state:{listingLeadId:theFormId},
                    })
                  },


                  // onClick: () => history.push(`/edit-lead/${row.Edit}`),
                }), (row) => ({
                  disabled: false,
                  icon: (<i className="fs-4 ti ti-trash"></i>),
                  label: "Delete",
                  title: "Delete",
                  onClick: (row) => handleDeleteClick(row.Edit)
                })
              ]}
         
            />
        
      </div>

    </>
  );
};

export default OrderList;