import React from "react";
import styles from "./ToolbarGroup.module.scss";

function ToolbarGroup({ children }) {
  return (
    <div className={styles.toolbarGroup}>
      {children}
    </div>
  );
}

export default ToolbarGroup;
