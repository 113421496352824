import React from "react";
// import styles from "./ShopifySyncing.module.scss";
import { useHistory } from "react-router-dom";

function ShopifySyncing({producterrors}) {

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    history.push("/settings/woocommerce-setup");
};

  return (
    <div>

    {(producterrors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(producterrors)} </div> : ""}


    {(producterrors) ? <div class="card">
      <div class="card-body">
        {/* <p className="card-subtitle-verified-cred mb-3">Product Syncing Failed</p> */}
        <div class="card-body">
        <div class="text-center">
        <p className="card-subtitle-verified mb-3">Product Syncing Failed</p>
      </div>
        </div>
        <div class="form-actions">
        <div class="card-body verified-btn">
            <button type="submit" onClick={handleSubmit} class="btn btn-primary w-40 text-white">Try Again</button>
        </div>
    </div>
      </div>
    </div> : <div class="card">
      <div class="card-body">
        <p className="card-subtitle-verified-cred mb-3">Product Syncing</p>
        <div class="card-body">
        <div class="text-center">
        <div class="spinner-border" role="status">
        </div>
      </div>
        </div>
        <p className="card-subtitle-verified mb-3">Copying...</p>
      </div>
    </div>}

    </div>
  

  );
}

export default ShopifySyncing;
