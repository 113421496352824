import React, { useEffect, useState } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import {
  isMobile,
  userLoggedAccessToken,
  urlSegment,
  calculateRemainingTime,
} from "../../../helpers/helper";
import StreamPlayer from "./StreamPlayer";
import styles from "./StartSteamLive.module.css";
const moment = require("moment");


function StartSteamLive() {
  const [videoStreamData, setVideoStreamData] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userSubscriptionData, setUserSubscriptionData] = useState(false);
  const [momentCurrentTime, setMomentCurrentTime] = useState();
  const [parsedEndTime, setParsedEndTime] = useState();
  const [parsedStartTime, setParsedStartTime] = useState();
  const [streamingEndPoint, setStreamingEndPoint] = useState();
  const [streamingError, setStreamingError] = useState(null);
  const [parentCameraPermission, setParentCameraPermission] = useState(false);
  const [messageIndication, setMessageIndication] = useState();

  const isMobileSize = isMobile();
  const [sidebarType, setSidebarType] = useState(
    // isMobileSize ? "mini-sidebar" : "full"
    "mini-sidebar"
  );


  const pageCustomStyles = {
    containerFluidFormWidth: { paddingTop: isMobileSize ? "0px" : "10px" },
  };

  const dbDateFormat = "YYYY-MM-DD HH:mm:ssZ";
  const videoTabStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    overflow:"hidden"
    // zIndex: 2000,
    // marginTop:'200px',
  };

  // const videoTabDialogueStyles = {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   flexDirection: "column",
  //   position: "relative",
  //   // zIndex: 2000,
  //   // marginTop: "200px",
  //   height: "90vh",
  // };

  useEffect(() => {
    const videoStreamId = urlSegment(2);
    console.log("videoStreamId", videoStreamId);
    fetchVideoStream(videoStreamId);
  }, []);

  useEffect(() => {
    fetchVideoStreamEndPoint();
    //   console.log('paramsObject>><<',paramsObject);
  }, [videoStreamData]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMomentCurrentTime(moment());
    }, 1000); // Update every 1000 milliseconds (1 second)

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const fetchVideoStream = (videoStreamId) => {
    setLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/video-for-start-streaming", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ videoId: videoStreamId }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        setVideoStreamData(data.data);
        setParsedStartTime(moment(data?.data?.start_time, dbDateFormat));
        setParsedEndTime(moment(data?.data?.end_time, dbDateFormat));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setLoader(false);
        // console.error("Error fetching data:", error);
      });
  };

  const fetchVideoStreamEndPoint = () => {
    if (
      videoStreamData?.start_time &&
      videoStreamData?.end_time &&
      videoStreamData?.owner &&
      videoStreamData?.key
    ) {
      const paramsObject = {
        start_time: videoStreamData?.start_time,
        end_time: videoStreamData?.end_time,
        user_id: videoStreamData?.owner,
        uniqueKey: videoStreamData?.key,
      };

      console.log(
        "paramsObject",
        paramsObject,
        "paramsObject?.length",
        paramsObject?.length
      );
      // console.log('videoStreamData?.length',videoStreamData?.length,'paramsObject?.length',paramsObject?.length);
      fetch(process.env.REACT_APP_API_URL + "/live-stream-from-browser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userLoggedAccessToken(),
        },
        body: JSON.stringify(paramsObject), // Replace with your POST data
      })
        .then((response) => response.json())
        .then((data) => {
          // setStreamingEndPoint(data?.url);
          setStreamingEndPoint(data?.data?.url);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally((error) => {
          // console.error("Error fetching data:", error);
        });
    }
  };

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  return (
    <div
      className={`page-wrapper ${
        sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
      }`}
      data-sidebartype={sidebarType}
      id="main-wrapper"
      data-theme="blue_theme"
      data-layout="vertical"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page
        pageCustomStyles={pageCustomStyles}
        onNavBarClick={onNavBarClick}
        title="Start Live Stream"
        formWidth={true}
        getSubscriptionData={setUserSubscriptionData}
      >
        {/* <BreadCrump
          title="Live Stream"
          image={dashboardbreadcrumimage}
          breadCrumpDetail={[
            { name: "Dashboard", link: "/" },
            { name: "Videos", link: "/videos" },
            { name: "Live Streaming", link: null },
          ]}
        /> */}
        <div className="content clearfix">
          {errorMessage ? (
            <div class="alert alert-danger" role="alert">
              <strong>Error </strong> {errorMessage}
            </div>
          ) : (
            ""
          )}

          {loader === true && (
            <div className={styles.videoTabDialogueStyles}>
              <div
                style={{ flexDirection: "column", alignItems: "center" }}
                className="d-flex justify-content-center mt-5"
              >
                <span
                  class="spinner-border fs-5"
                  role="status"
                  aria-hidden="true"
                ></span>
                <h5 className="mt-3">Putting everything together</h5>
              </div>
            </div>
          )}
        </div>

        {videoStreamData?.stream_detail === "live-stream-from-browser" ? (
          moment(videoStreamData?.end_time, dbDateFormat).isBefore(
            momentCurrentTime
          ) ? (
            <div className={styles.videoTabDialogueStyles}>

              <h3 className="fw-semibold">
                Stream concluded at {moment(videoStreamData?.end_time, dbDateFormat).format('LT')}
              </h3>
            </div>
          ) : parsedStartTime.isBefore(momentCurrentTime) &&
            parsedEndTime.isAfter(momentCurrentTime) ? (
            <div className={styles.videoTabStyles}>
              <StreamPlayer
                videoStreamData={videoStreamData}
                streamingEndPoint={streamingEndPoint}
                setParentErrorMessage={setErrorMessage}
                subscriptionData={userSubscriptionData}
                setParentStreamingError={setStreamingError}
                setParentCameraPermission={setParentCameraPermission}
                messageIndication={messageIndication}
                endTimeMessage={
                  calculateRemainingTime(parsedEndTime, dbDateFormat) +
                  " left to end streaming"
                }
              />

              {/* {parentCameraPermission && (
                <span>
                  {calculateRemainingTime(parsedEndTime, dbDateFormat)} left to
                  end streaming
                </span>
              )} */}
            </div>
          ) : parsedStartTime.isAfter(momentCurrentTime) ? (
            <div className={styles.videoTabDialogueStyles}>
              <h3 className="fw-semibold">
                {calculateRemainingTime(parsedStartTime, dbDateFormat)} left to
                start streaming
              </h3>
            </div>
          ) : null
        ) : null}

        {streamingError && <p className="text-danger text-center">Error</p>}
      </Page>
    </div>
  );
}
export default StartSteamLive;
