import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import DataTable from "react-data-table-component";
import SocialHelper from "../../../helpers/socialHelper";

import {
  isMobile,
  userLoggedAccessToken,
} from "../../../helpers/helper";
// import styles from "./InviteFriends.module.css";
import inviteFriendCircle from "../../../../../src/assets/icons/color-full-start.svg";
import yellowEnvelope from "../../../../../src/assets/icons/yellow-envelope.svg";
import inviteFriendNoneListIcons from "../../../../../src/assets/icons/invite-friend-users-round.svg";
// import { Button } from "../../../../../src/component/Form/Button";
const InviteFriends = () => {
  // const history = useHistory();
  // const location = useLocation();
  const isMobileSize = isMobile();
  const copyInviteUrlRef = useRef(null);

  // const [success, setSuccess] = useState();
  // const [error, setError] = useState();
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [dropDownOpenIndex, setDropDownOpenIndex] = useState(null);

  //   const [loader, setLoader] = useState(false);
  const [userSubscription, setUserSubscription] = useState();
  const [isSubscriptionLoading, setSubscriptionLoading] = useState();
  const [scorePointsLoader, setScorePointsLoader] = useState(false);
  const [scorePointsListingLoader, setScorePointsListingLoader] =
    useState(false);
  const [currentScorePoints, setCurrentScorePoints] = useState(0);
  // const [referrelInvitationUrl, setReferrelInvitationUrl] = useState(null);
  const [inviteesListingData, setInviteesListingData] = useState([]);
  const [invitationCode, setInvitationCode] = useState(null);

  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );

  const dropdownRef = useRef(null); // Define the dropdown reference

  useEffect(() => {
    getScoreNCode();
    getInviteesListing();
  }, []);
  //

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (event) => {
    if (
      dropDownOpenIndex >= 0 &&
      !dropdownRef.current?.contains(event?.target)
    ) {
      setDropDownOpenIndex(null); // Close the dropdown
    }
  };

  const handleCopy = (value, index) => {
    const plainValue = value?.replace(/\s+/g, ""); // Remove spaces before copying
    navigator.clipboard.writeText(plainValue);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 3500); // Reset after 2 seconds
  };

  const getScoreNCode = (videoId) => {
    // setDataLoader(true);
    setScorePointsLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/invite-code-score-count", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ video_id: videoId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setCurrentScorePoints(data?.data?.earnedPoints);
        setInvitationCode(data?.data?.invitationCode);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setScorePointsLoader(false);
      });
  };

  const getInviteesListing = (videoId) => {
    // setDataLoader(true);
    setScorePointsListingLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/invitation-user-score", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ video_id: videoId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setInviteesListingData(data?.data);
        // setCurrentScorePoints(data?.data?.earnedPoints);
        // setInvitationCode(data?.data?.invitationCode);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setScorePointsListingLoader(false);
      });
  };

  //invite-code-score-count

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  const getSubscriptionData = (e) => {
    setUserSubscription(e);
  };

  const invitedFriendsColumns = [
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Email",
      selector: "email",
      sortable: false,
    },
    {
      name: "Earned Point",
      selector: "earned_point",
      sortable: false,
      cell: (row) => Math.round(row?.earned_point),
    },
  ];

  const invitationURL = `${process.env.REACT_APP_PORTAL_URL}/register?invitation_code=`;

  const invitationFullURL = `${invitationURL}${invitationCode}`;

  return (
    <>
      <div
        className={`page-wrapper ${
          sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
        }`}
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype={sidebarType}
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        {<Sidebar onNavBarClick={onNavBarClick} />}
        <Page
          title="Invite Friends"
          onNavBarClick={onNavBarClick}
          isSubscriptionLoading={isSubscriptionLoading}
          getSubscriptionData={getSubscriptionData}
          setSubscriptionLoading={setSubscriptionLoading}
        >
          <div className="card invite-friend-page">
            <div className="card-body wizard-content">
              {/* {success && (
                <div className="alert alert-success" role="alert">
                  <strong>Success: </strong> {success}
                </div>
              )}
              {error && (
                <div className="alert alert-danger" role="alert">
                  <strong>Error: </strong> {error}
                </div>
              )} */}

              {scorePointsLoader === true && (
                <div className="d-flex justify-content-center mt-5">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </div>
              )}

              {scorePointsLoader !== true && (
                <>
                  <div className="boxes-area">
                    <div className="points-card-box">
                      <img src={inviteFriendCircle} />
                      <h3 className="point-count">
                        {Math.round(currentScorePoints)}
                      </h3>
                      <h4>Points</h4>

                      {/* <div className="points-card-box-stats">
                                            </div> */}
                    </div>
                    <div className="invitation-link-box">
                      <div className="row">
                        <div className="col-lg-7 col-xs-3 col-sm-3">
                          <h3 className="invite-get-points-text">
                            Invite and get 5 points
                          </h3>
                        </div>
                        <div className="col-lg-5 col-xs-3 col-sm-3 envelop-area">
                          <img src={yellowEnvelope} />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="share-link-button-area">
                          <p className="share-link-text">Share your link</p>
                          <div className="invite-friend-link-button-area">
                            <div
                              className="invite-friend-link cursor-pointer"
                              onClick={() =>
                                handleCopy(
                                  `${invitationURL}${invitationCode}`,
                                  1
                                )
                              }
                            >
                              <p
                                type="text"
                                ref={copyInviteUrlRef}
                                defaultValue={`${invitationURL}${invitationCode}`}
                              >
                                {invitationURL}
                                {invitationCode}
                              </p>
                              <button
                                type="button"
                                title="Copy to clip board"
                                className="cursor-pointer btn btn-primary text-white copy-link-btn"
                              >
                                {copiedIndex === 1 ? "copied" : "copy"}
                                {/* {copyUrlText} */}
                              </button>
                            </div>
                            <div
                              className="share-link-button"
                              ref={dropDownOpenIndex === 1 ? dropdownRef : null}
                            >
                              <button
                                type="button"
                                title="Copy to clip board"
                                // onClick={() =>
                                //   handleCopy(
                                //     `${invitationURL}${invitationCode}`,
                                //     1
                                //   )
                                // }
                                onClick={(e) => {
                                  if (dropDownOpenIndex === 1) {
                                    setDropDownOpenIndex(null);
                                  } else {
                                    setDropDownOpenIndex(1);
                                  }
                                }}
                                className="share-button btn btn-primary text-white"
                              >
                                Share
                              </button>

                              <div style={{ color: "#666" }}>
                                <div className="dropdown">
                                  <ul
                                    style={{ position: "absolute", right: 10 }}
                                    className={`dropdown-menu ${
                                      dropDownOpenIndex == 1 ? "show" : ""
                                    }`}
                                  >
                                    <li>
                                      <h6 className="p-2 text-center">
                                        Share on
                                      </h6>
                                    </li>

                                    <li>
                                      <a
                                        className="dropdown-item"
                                        target="_blank"
                                        href={SocialHelper.shareOnFacebook(
                                          invitationFullURL,
                                          true,
                                          true
                                        )}
                                      >
                                        <i className="ti ti-brand-facebook"></i>{" "}
                                        Facebook
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        target="_blank"
                                        href={SocialHelper.shareOnTwitter(
                                          invitationFullURL,
                                          true
                                        )}
                                      >
                                        <i className="ti ti-brand-twitter"></i>{" "}
                                        Twitter
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        target="_blank"
                                        href={SocialHelper.shareOnLinkedIn(
                                          invitationFullURL,
                                          true
                                        )}
                                      >
                                        <i className="ti ti-brand-linkedin"></i>{" "}
                                        LinkedIn
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        className="dropdown-item"
                                        target="_blank"
                                        href={SocialHelper.shareOnWhatsApp(
                                          invitationFullURL,
                                          true
                                        )}
                                      >
                                        <i className="ti ti-brand-whatsapp"></i>{" "}
                                        WhatsApp
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="mt-5">
                {scorePointsLoader === true && scorePointsLoader !== true && (
                  <div className="d-flex justify-content-center">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                )}


                { (scorePointsListingLoader === false && scorePointsLoader === false)
                  ? <h3 className="fw-bolder">Invited Friends</h3>
                  : ""}

                {scorePointsListingLoader !== true &&
                scorePointsLoader !== true &&
                inviteesListingData?.length > 0 ? (
                  <>
                    <DataTable
                      // title={
                      //   <span style={{ fontWeight: "bold" }}>
                      //     Invited Friends
                      //   </span>
                      // }
                      // title="Invited Friends"
                      columns={invitedFriendsColumns}
                      data={inviteesListingData}
                      pagination
                      responsive
                    />
                  </>
                ) : (
                  <>
                  {scorePointsListingLoader !== true && scorePointsLoader !== true && (
                    <div className="no-referrals-listng">
                      <img src={inviteFriendNoneListIcons} />
                      <span>No referrals yet</span>
                    </div>
                  )}
                  </>

                )}
              </div>
            </div>
          </div>
        </Page>
      </div>
    </>
  );
};

export default InviteFriends;
