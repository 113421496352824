const SuccessfullShopifySyncing = ({ productsuccess }) => {
  return (
    <div className="row">
      <section>
        <div class="card">
          {(productsuccess) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(productsuccess)} </div> : ""}
          <div class="card-body pt-5 pb-5">
            <div className="d-flex flex-column align-items-center justify-content-center">
              <h2 className="text-center mb-4">Successfully Product Synced</h2>
              <i class="ti ti-circle-check" style={{ fontSize: 80 }}></i>
              <p className="mb-4 text-center mt-4">Product Synced Successfully</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SuccessfullShopifySyncing;
