import React from "react";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../Form/FormToast";


const Warning = ({ intent, title, message, children }) => {
    console.log('intent, title, message, children',intent, title, message, children);
    return (

        <FormToast type={FORM_TOAST_TYPES.WARNING} message={message}>
            {children}
        </FormToast>

    );
}

export default Warning;
