import React, { useState, useRef } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { isMobile } from '../../../helpers/helper';
import dashboardbreadcrumimage from "../../../../dist/images/dashboard/add-video.png";
import BreadCrump from "../../../Breadcrump/BreadCrump";

function Invoice(...props) {
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const printableAreaRef = useRef(null);

    const handlePrint = () => {
        
        const mode = 'iframe'; //popup
        const close = mode === 'popup';
        // const options = {
        //     mode: mode,
        //     popClose: close,
        // };
    
        const printableArea = printableAreaRef.current;
        
        if (printableArea) {
            const newWin = window.open('', '_blank');
    
            if (newWin) {
                newWin.document.open();
                newWin.document.write(printableArea.innerHTML);
                newWin.document.close();
                newWin.print();
    
                if (close) {
                    newWin.close();
                }
            } else {
                console.error('Popup blocked. Please allow popups for this site and try again.');
            }
        }
    };
    
    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }
    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">

            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Invoice" flex onNavBarClick={onNavBarClick}>
                <BreadCrump
                    title="Videos"
                    image={dashboardbreadcrumimage}
                    breadCrumpDetail={[
                        { name: "Dashboard", link: "/" },
                        { name: "Settings", link: "/settings/billing" },
                        { name: "Billing", link: "/settings/billing" },
                        { name: "Invoice", link: "/settings/billing/invoice" }
                    ]}
                />
                <div class="row"><section>
                    <div className="card-body wizard-content">
                        <div className="card">
                            <div className="w-xs-100 chat-container"  >
                                <div className="invoice-inner-part h-100" >
                                    <div className="invoiceing-box" >
                                        <div className="invoice-header d-flex align-items-center border-bottom p-3">
                                            <h4 className="font-medium text-uppercase mb-0">Invoice</h4>
                                            <div className="ms-auto">
                                                <h4 className="invoice-number">#125</h4>
                                            </div>
                                        </div>
                                        <div className="p-3" id="custom-invoice"   style={{ display: "block" }}>
                                            <div
                                                className="invoice-125"
                                                id="printableArea"
                                                style={{ display: "block" }}
                                                ref={printableAreaRef}
                                            >
                                                <div className="row pt-3">
                                                    <div className="col-md-12">
                                                        <div className="">
                                                            <address>
                                                                <h6>&nbsp;From,</h6>
                                                                <h6 className="fw-bold">&nbsp;Steve Jobs</h6>
                                                                <p className="ms-1">
                                                                    1108, Clair Street, <br />
                                                                    Massachusetts,
                                                                    <br />
                                                                    Woods Hole - 02543
                                                                </p>
                                                            </address>
                                                        </div>
                                                        <div className="text-end">
                                                            <address>
                                                                <h6>To,</h6>
                                                                <h6 className="fw-bold invoice-customer">
                                                                    Angelina Rhodes,
                                                                </h6>
                                                                <p className="ms-4">
                                                                    455, Shobe Lane, <br />
                                                                    Colorado, <br />
                                                                    Fort Collins - 80524
                                                                </p>
                                                                <p className="mt-4 mb-1">
                                                                    <span>Invoice Date :</span>
                                                                    <i className="ti ti-calendar" />
                                                                    23rd Jan 2021
                                                                </p>
                                                                <p>
                                                                    <span>Due Date :</span>
                                                                    <i className="ti ti-calendar" />
                                                                    25th Jan 2021
                                                                </p>
                                                            </address>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div
                                                            className="table-responsive mt-5"
                                                            style={{ clear: "both" }}
                                                        >
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    {/* start row */}
                                                                    <tr>
                                                                        <th className="text-center">#</th>
                                                                        <th>Description</th>
                                                                        <th className="text-end">Quantity</th>
                                                                        <th className="text-end">Unit Cost</th>
                                                                        <th className="text-end">Total</th>
                                                                    </tr>
                                                                    {/* end row */}
                                                                </thead>
                                                                <tbody>
                                                                    {/* start row */}
                                                                    <tr>
                                                                        <td className="text-center">1</td>
                                                                        <td>Milk Powder</td>
                                                                        <td className="text-end">2</td>
                                                                        <td className="text-end">$24</td>
                                                                        <td className="text-end">$48</td>
                                                                    </tr>
                                                                    {/* end row */}
                                                                    {/* start row */}
                                                                    <tr>
                                                                        <td className="text-center">2</td>
                                                                        <td>Air Conditioner</td>
                                                                        <td className="text-end">5</td>
                                                                        <td className="text-end">$500</td>
                                                                        <td className="text-end">$2500</td>
                                                                    </tr>
                                                                    {/* end row */}
                                                                    {/* start row */}
                                                                    <tr>
                                                                        <td className="text-center">3</td>
                                                                        <td>RC Cars</td>
                                                                        <td className="text-end">30</td>
                                                                        <td className="text-end">$600</td>
                                                                        <td className="text-end">$18000</td>
                                                                    </tr>
                                                                    {/* end row */}
                                                                    {/* start row */}
                                                                    <tr>
                                                                        <td className="text-center">4</td>
                                                                        <td>Down Coat</td>
                                                                        <td className="text-end">62</td>
                                                                        <td className="text-end">$5</td>
                                                                        <td className="text-end">$310</td>
                                                                    </tr>
                                                                    {/* end row */}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="pull-right mt-4 text-end">
                                                            <p>Sub - Total amount: $20,858</p>
                                                            <p>vat (10%) : $2,085</p>
                                                            <hr />
                                                            <h3>
                                                                <b>Total :</b> $22,943
                                                            </h3>
                                                        </div>
                                                        <div className="clearfix" />
                                                        <hr />
                                                        <div className="text-end">
                                                            
                                                            <button className="btn btn-default print-page" type="button" onClick={handlePrint}>
                                                                <span>
                                                                    <i className="ti ti-printer fs-5" />
                                                                    Print
                                                                </span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section></div>
            </Page>
        </div>
    );
}
export default Invoice;