import React, { useEffect, useRef, useState } from "react";
import UploadHelper from "../../helpers/upload";
import { useHistory } from "react-router-dom";
import { userLoggedAccessToken, showUserTypeBaseMessage, isMobile } from '../../helpers/helper';
import validateField from "../../helpers/AllValidtions";
import ImageUploader from "./ImageUploader";
import ProductVariants from "./../../../component/Product/ProductVariants"
import Button from "../../Form/Button";
import Input from "../../Form/Input";
import Textarea from "../../Form/Textarea";
import MultiSelect from "../../Form/MultiSelect";
import QuantityInput from "../../Form/QuantityInput";
import RadioButtonRow from "../../Form/RadioButtonRow";


function EditVideoAddProduct({setSelectedOption, selectedOptionnew, setIsVisible ,fetchData}) {

    const isMobileSize = isMobile();
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [description, setDescription] = useState("");
    const [productImage, setProductImage] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [progress, setProgress] = useState(0.0);
    const [productImageUrl, setProductImageUrl] = useState();
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [addProductLoader, setAddProductLoader] = useState(false);

    const [titleError, setTitleError] = useState("");
    const [priceError, setPriceError] = useState("");
    const [quantityError, setQuantityError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [productMultiImagesError, setProductMultiImagesError] = useState("");
    const [minLimit, setMinLimit] = useState(1);

    const [showErrors, setShowErrors] = useState(false);
    const allowedExt = "jpeg, png and webp";

    const [loader, setLoader] = useState(false);
    const [accountTypeMessage, setAccountTypeMessage] = useState('');
    const [productMultiImages, setProductMultiImages] = useState([]);

    const [hasVariants, setHasVariant] = useState(false);
    const variantsRef = useRef();

    const [shipping, setShipping] = useState(false);

    const [weight, setWeight] = useState("");
    const [weightError, setWeightError] = useState("");

    const [lenght, setLenght] = useState("");
    const [lenghtError, setLenghtError] = useState("");

    const [width, setWidth] = useState("");
    const [widthtError, setWidthtError] = useState("");

    const [height, setHeight] = useState("");
    const [heightError, setHeightError] = useState("");


    const [size, setSize] = useState("");
    const [sizeError, setSizeError] = useState("");


    const [selectedOption, setSelectedOptions] = useState([]);
    const [options, setOptions] = useState([]);

    // const allowedImageTypes = {
    //     "image/jpeg": true,
    //     "image/png": true,
    //     "image/webp": true,
    // };

    const allowedImageTypes = {
        "image/jpeg": 'jpg',
        "image/png": 'png',
        "image/webp": 'webp',
    };

    const handleLenghtChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setLenght(value);
        validateField(value, rule, setLenghtError);

    };

    const handleWidthChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWidth(value);
        validateField(value, rule, setWidthtError);

    };

    const handleHeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setHeight(value);
        validateField(value, rule, setHeightError);

    };


    // const handleShippingChange = (e) => {
    //     if (shipping == false) {
    //         setShipping(true)
    //     } else {
    //         setShipping(false)
    //         setWeight("")
    //         setHeight("")
    //         setWidth("")
    //         setLenght("")
    //     }

    // };


    // const handleSizeChange = (e) => {
    //     var value = e.target.value;
    //     var rule = "required";
    //     setSize(value);
    //     validateField(value, rule, setSizeError);

    // };

    const handleWeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWeight(value);
        validateField(value, rule, setWeightError);

    };


    const handleTitleChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setTitle(value);
        validateField(value, rule, setTitleError);
    };

    const handlePriceChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setPrice(value);
        validateField(value, rule, setPriceError);
    };

    const handleQuantityChange = (e) => {
        var value = e.target.value;
        setQuantity(value);
        validateField(value, "required", setQuantityError);
    };

    const handleCategoryChange = selectedOption => {

        var rule = "cat-required";
        var value = selectedOption;
        setSelectedOptions(selectedOption)
        validateField(value, rule, setCategoryError);

    };

    const handleMultipleImages = (multiImagesData) => {
        // var rule = "multi-image";
        setProductMultiImages(multiImagesData);
        setProductImageUrl(multiImagesData?.length ? multiImagesData[0] : null);
        // validateField(multiImagesData, rule, setProductMultiImagesError);
    }



    useEffect(() => {

        setAccountTypeMessage(showUserTypeBaseMessage({ NonProfit: "to collect donations", Influencer: "to earning commission", Brand: "start to selling", default: "start to selling" }));


        fetchCategoryData();

        // const timer = setTimeout(() => {
        //     setShowSuccess(false);
        //     setSuccessMessage('');
        //   }, 4500);
        // return () => clearTimeout(timer);

    }, []);

    const fetchCategoryData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/all-product-category', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({}), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                // setData(data.data)
                setOptions(data.data)
                setLoader(true)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var variants = [];
        if (hasVariants) {
            if (variantsRef.current && variantsRef.current.validateAndGetVariants) {
                const [error, data] = variantsRef.current.validateAndGetVariants();
                if (error) return

                variants = data
            }
        }

        submitForm(title, price, quantity, description, productImageUrl, weight, size, variants);

        // if (title != '' && price != '' && productImageUrl != '') {
        //     submitForm(title, price, description, productImageUrl, weight, size, variants);
        // } else {
        //     setShowErrors('Please check Title,Category,Image and Price fields');
        // }
    };

    function handleProgressEvent({ loaded, total }) {
        setProgress(loaded / total);
    }


    const validateImage = (photoFile) => {
        if (photoFile) {
            const fileType = photoFile.type;
            if (!allowedImageTypes.hasOwnProperty(fileType)) {
                // setErrorMessage(allowedExt + ' extensions are allowed');
                setProductMultiImagesError(allowedExt + ' extensions are allowed');

                // console.log(fileType,"faisal")

                return false;
            } else {
                // setErrorMessage(null);
                setProductMultiImagesError('');
                return true;
            }
        } else {
            return false;
        }
    }


    const submitForm = (title, price, quantity, description, productImageValue, weight, size, variants) => {
        
        setAddProductLoader(true);
        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        const categoryValues = selectedOption.map(category => category.value).join(',');

        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/user-insert-product', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + userLoggedAccessToken(),
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "title": title,
                    "price": price,
                    "quantity": quantity,
                    "product_image": productImageValue,
                    "description": description,
                    "weight": weight,
                    "category": categoryValues,
                    "productMultiImages": productMultiImages,
                    "variants": hasVariants ? variants : [],
                    "shipping": shipping,
                    "lenght": lenght,
                    "width": width,
                    "height": height,


                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {


            
                            const productId = json.data;
                            // if(history.location.state.source=="addvideo"){
                            // history_push_link = '/videos/add-nav-video/select-nav-product/lead-checkout-nav';
                            // }

                            console.log(json.data,"rashidddd hereeeee")
                            console.log(history.location.state,"annas")
                 
                            const selectedProducts = selectedOptionnew;
                            selectedProducts.push({
                                id: productId,
                                video_id:  history.location.state?.originalVideoData.id,
                                product_id: productId,
                                external_checkout_link: null,
                                owner_id: history.location.state?.originalVideoData.owner,
                                created_at: "2024-06-07T11:56:51.329Z",
                                use_shopify_checkout: "no",
                                use_woo_commerce_checkout: "no",
                                use_in_video_checkout: "no",
                                use_collect_info: "yes"
                            })


                            // history.push({
                            //     pathname: '/edit-videos/select-nav-product/edit-lead-checkout-nav',
                            //     state: {
                            //         selectedProducts: selectedProducts,
                            //         addVideoData: history.location.state.addVideoData,
                            //         originalVideoData: history.location.state?.originalVideoData,
                            //         showSuccess: 'Your product has been updated successfully next step is to publish your video ' + accountTypeMessage

                            //     },
                            // });

                            history.location.state?.originalVideoData?.selectedProducts.push({
                                id: productId,
                                video_id:  history.location.state?.originalVideoData.id,
                                product_id: productId,
                                external_checkout_link: true,
                                owner_id: history.location.state?.originalVideoData.owner,
                                created_at: "2024-06-07T11:56:51.329Z",
                                use_shopify_checkout: "no",
                                use_woo_commerce_checkout: "no",
                                use_in_video_checkout: "no",
                                use_collect_info: "no"
                            })

                            
                            fetchData()
                            setIsVisible(false)

                            // history.push("/select-product")
                        } else {
                            setErrorMessage(json.message);

                            // history_push_link = "/add-product";
                        }

                    });
                }
            }).catch((error) => {
                setErrorMessage('Something went wrong!');
            }).finally((error) => {
                setAddProductLoader(false);
            });
        });
        return fetch_prods;
    };

    var isButtonDisabled =
        !title?.trim() ||
        selectedOption.length < 1 ||
        !productImageUrl?.trim() ||
        titleError ||
        categoryError ||
        productMultiImagesError;

    if (!hasVariants) {
        if (!price?.trim() || priceError || quantityError) {
            isButtonDisabled = true;
        }
    }

    if (shipping == "paid-shipping") {
        if (
            !weight?.trim() ||
            weightError ||
            !lenght?.trim() ||
            lenghtError ||
            !width?.trim() ||
            widthtError ||
            !height?.trim() ||
            heightError
        ) {
            isButtonDisabled = true;
        }
    }

    // const addText = showUserTypeBaseMessage({ NonProfit: "Add Donation Campaign", default: "Add Product" });
    // const textPlural = showUserTypeBaseMessage({ NonProfit: "Donation Campaigns", default: "Products" });
    // const textSignular = showUserTypeBaseMessage({ NonProfit: "Donation Campaign", default: "Product" });

    // console.log('history.location.state',history.location.state);

    const handleRadioChange = (value) => {



        setShipping(value);
    };

    const categoryMaxAllowedLimit = process.env.REACT_APP_SELECT_CATEGORY_MAX_LIMIT;


    return (

    <div>
        <div className="row">
            <div className="col-md-12">
                <div className="w-500">
                    {/* <div className="card-header d-flex flex-row justify-content-between">
                        <Button
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => setIsVisible(false)}
                            buttonText="Cancel"
                        />
                        <h5 className="fw-bolder">Add New Product</h5>
                        <Button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSubmit}
                            buttonText="Done"
                            disabled={isButtonDisabled || addProductLoader}
                        />
                    </div> */}
                    <div className="row mt-2">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <Input
                                    id="title"
                                    type="text"
                                    label={`Product Title`}
                                    value={title}
                                    onChange={handleTitleChange}
                                    disabled={false}
                                    placeholder=""
                                    errorMessage={titleError}
                                />
                            </div>
                            <div className="mb-2">
                                <Textarea
                                    id="description"
                                    label={`Product Description`}
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    disabled={false}
                                    placeholder=""
                                    errorMessage=""
                                />
                            </div>
                            <div className="mt-3 mb-3">
                                <MultiSelect
                                    limit={categoryMaxAllowedLimit}
                                    id="category"
                                    label="Category"
                                    value={selectedOption}
                                    options={options}
                                    onChange={handleCategoryChange}
                                    errorMessage={categoryError}
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        id="price"
                                        type="number"
                                        label="Price"
                                        value={price}
                                        onChange={handlePriceChange}
                                        disabled={hasVariants}
                                        placeholder=""
                                        errorMessage={priceError}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <QuantityInput
                                        id="quantity"
                                        label="Quantity"
                                        type="number"
                                        min="1"
                                        value={quantity}
                                        onChange={handleQuantityChange}
                                        disabled={hasVariants}
                                        placeholder=""
                                        errorMessage={quantityError}
                                    />
                                </div>
                            </div>
                            <div className="row mt-4 mb-1">
                                <div className="col-md-4 pr-0">
                                    <RadioButtonRow
                                        label="No Shipping Required"
                                        name="shipping_type"
                                        id="inlineRadiow2"
                                        value="no-shipping"
                                        disabled={false}
                                        checked={shipping === "no-shipping"}
                                        onChange={() => handleRadioChange("no-shipping")}
                                    />
                                </div>
                                <div className="col-md-4 pr-0">
                                    <RadioButtonRow
                                        label="Free Shipping"
                                        name="shipping_type"
                                        id="inlineRadio3"
                                        value="free-shipping"
                                        disabled={false}
                                        checked={shipping === "free-shipping"}
                                        onChange={() => handleRadioChange("free-shipping")}
                                    />
                                </div>

                                <div className="col-md-4 pr-0">
                                    <RadioButtonRow
                                        label="Paid Shipping"
                                        name="shipping_type"
                                        id="inlineRadio3s"
                                        value="paid-shipping"
                                        disabled={false}
                                        checked={shipping === "paid-shipping"}
                                        onChange={() => handleRadioChange("paid-shipping")}
                                    />
                                </div>
                            </div>

                            {shipping === 'paid-shipping' && (
                                <div className="mt-2">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="text-danger">In the video checkout, currently there is no support for products with paid shipping.</p>
                                            <div className="mb-3">
                                                <Input
                                                    id="weight"
                                                    type="number"
                                                    label="Weight (In Grams)"
                                                    value={weight}
                                                    onChange={handleWeightChange}
                                                    disabled={false}
                                                    placeholder=""
                                                    errorMessage={weightError}
                                                    min="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-md-12"><h5 className="text-black fw-semibold">Dimensions (L X W X H)</h5></div>
                                        <div className="col-md-4 fs-3">
                                            <Input
                                                id="length"
                                                type="number"
                                                label="Length (In Centimeter)"
                                                value={lenght}
                                                onChange={handleLenghtChange}
                                                disabled={false}
                                                placeholder=""
                                                errorMessage={lenghtError}
                                                min="1"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                id="width"
                                                type="number"
                                                label="Width (In Centimeter)"
                                                value={width}
                                                onChange={handleWidthChange}
                                                disabled={false}
                                                placeholder=""
                                                errorMessage={widthtError}
                                                min="1"
                                            />
                                        </div>

                                        <div className="col-md-4">
                                            <Input
                                                id="height"
                                                type="number"
                                                label="Height (In Centimeter)"
                                                value={height}
                                                onChange={handleHeightChange}
                                                disabled={false}
                                                placeholder=""
                                                errorMessage={heightError}
                                                min="1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="col-md-12 mt-3">
                            {/* <div className="bg-gray-smoke py-4 px-4 br-1"> */}
                                {/* <p className="text-black fs-3 fw-semibold mb-1">Product Images</p> */}
                                <ImageUploader
                                    labelText="Product Images"
                                    setErrorMessage={setErrorMessage}
                                    fieldDesc="Add Image"
                                    allowedImageTypes={allowedImageTypes}
                                    returnImagesUrls={handleMultipleImages}
                                    minLimit={minLimit}
                                    productMultiImagesError={productMultiImagesError}
                                    setProductMultiImagesError={setProductMultiImagesError}
                                />
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                <div className={`${!hasVariants ? "w-500" : ""}`}>
                    <div className="row mt-3 mb-4">
                        <div className="col-md-12 product-variants-container">
                            <div className="px-3 br-1 border border-light">
                                <ProductVariants ref={variantsRef} setHasVariant={setHasVariant} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="sticky-footer ">
                <div className="footer-container">
                    <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                        <Button
                            className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                            type="button"
                            buttonText="Back"
                            onClick={() => setIsVisible(false)}
                        />
                        <Button
                            className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                            type="button"
                            onClick={handleSubmit}
                            buttonText="Add Product"
                            fullButton={false}
                            disabled={isButtonDisabled || addProductLoader}
                        />
                    </div>
                </div>
            </div>

</div>
    )

    // return (
    //     // <section>
    //     //     <div class="card">
    //     //         <div class="card-body">
    //     //             {(showErrors || errorMessage) ? <div class="alert alert-danger" role="alert"><strong>Error </strong> {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."} </div> : ""}
    //     //             <form className="form">
    //     //                 <h4 className="mb-3">{addText}</h4>
    //     //                 <div className="row">
    //     //                     <div className="col-md-12">
    //     //                         <div className="mb-3">
    //     //                             <label htmlFor="wfirstName2">{textSignular} Title:<span className="danger">*</span>
    //     //                             </label>
    //     //                             <input type="text" className="form-control required" id="wfirstName2" placeholder="Enter product title" name="title" value={title} onChange={handleTitleChange} />
    //     //                             {(titleError ? <small className="text-danger">{titleError}</small> : "")}
    //     //                         </div>
    //     //                     </div>

    //     //                 </div>
    //     //                 <div className="row">
    //     //                     <div class="col-12">
    //     //                         <div class="mb-4">
    //     //                             <label for="exampleInputPassword1">{textSignular} Description:</label>
    //     //                             <textarea class="form-control" id="" cols="20" rows="3" placeholder="Enter product description" name="description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
    //     //                         </div>
    //     //                     </div>
    //     //                 </div>
    //     //                 <div className="row">
    //     //                     <div class="col-12">
    //     //                         <div class="mb-3">
    //     //                             <label htmlFor="wfirstName2">{textSignular} Category:<span className="danger">*</span></label>
    //     //                             <Select
    //     //                                 isMulti
    //     //                                 menuPortalTarget={document.body} menuPosition={'fixed'}
    //     //                                 options={options}
    //     //                                 onChange={handleCategoryChange}
    //     //                                 className="basic-multi-select"
    //     //                                 classNamePrefix="select screen"
    //     //                             />
    //     //                             {(categoryError ? <small className="text-danger">{categoryError}</small> : "")}
    //     //                         </div>
    //     //                     </div>
    //     //                 </div>
    //     //                 <div className="row">
    //     //                     <div className="col-md-12">
    //     //                         <label htmlFor="wfirstName2">{textSignular} Images:<span className="danger">*</span>
    //     //                         </label>
    //     //                         <ImageUploader setErrorMessage={setErrorMessage} fieldDesc={Object.values(allowedImageTypes).join(',') + ' extensions are allowed'} allowedImageTypes={allowedImageTypes} returnImagesUrls={handleMultipleImages} minLimit={minLimit} productMultiImagesError={productMultiImagesError} setProductMultiImagesError={setProductMultiImagesError} />
    //     //                         {(productMultiImagesError ? <small className="text-danger">{productMultiImagesError}</small> : "")}
    //     //                     </div>
    //     //                 </div>
    //     //                 <div className="row">
    //     //                     <div className="col-md-12">
    //     //                         <div className="mb-3">
    //     //                             <label htmlFor="wfirstName2">{textSignular} Base Price:<span className="danger">*</span>
    //     //                             </label>
    //     //                             <input disabled={hasVariants} type="number" min="1" className="form-control required" id="wfirstName2" name="price" value={price} onChange={handlePriceChange} />
    //     //                             {(priceError ? <small className="text-danger">{priceError}</small> : "")}
    //     //                         </div>
    //     //                     </div>
    //     //                 </div>
    //     //                 <div className="row">
    //     //                     <div className="col-md-12">
    //     //                         <div className="mb-3">
    //     //                             <label htmlFor="quantity">{textSignular} Quantity:<span className="danger">*</span>
    //     //                             </label>
    //     //                             <input disabled={hasVariants} type="number" min="1" className="form-control required" id="quantity" name="quantity" value={quantity} onChange={handleQuantityChange} />
    //     //                             {(quantityError ? <small className="text-danger">{quantityError}</small> : "")}
    //     //                         </div>
    //     //                     </div>
    //     //                 </div>
    //     //                 <div className="row">
    //     //                     <div className="col-md-4">
    //     //                         <div className="form-check form-check-inline">
    //     //                             <input
    //     //                                 className="form-check-input"
    //     //                                 type="radio"
    //     //                                 name="inlineRadioOptions"
    //     //                                 id="inlineRadiow2"
    //     //                                 value="no-shipping"
    //     //                                 disabled=""
    //     //                                 checked={shipping === "no-shipping"}
    //     //                                 onChange={() => handleRadioChange("no-shipping")}
    //     //                             />
    //     //                             <label className="form-check-label" htmlFor="inlineRadio2">No Shipping Required</label>
    //     //                         </div>
    //     //                     </div>
    //     //                     <div className="col-md-4">
    //     //                         <div className="form-check form-check-inline">
    //     //                             <input
    //     //                                 className="form-check-input"
    //     //                                 type="radio"
    //     //                                 name="inlineRadioOptions"
    //     //                                 id="inlineRadio3"
    //     //                                 value="free-shipping"
    //     //                                 disabled=""
    //     //                                 checked={shipping === "free-shipping"}
    //     //                                 onChange={() => handleRadioChange("free-shipping")}
    //     //                             />
    //     //                             <label className="form-check-label" htmlFor="inlineRadio2">Free Shipping</label>
    //     //                         </div>
    //     //                     </div>
    //     //                     <div className="col-md-4">
    //     //                         <div className="form-check form-check-inline">
    //     //                             <input
    //     //                                 className="form-check-input"
    //     //                                 type="radio"
    //     //                                 name="inlineRadioOptions"
    //     //                                 id="inlineRadio3s"
    //     //                                 value="paid-shipping"
    //     //                                 disabled=""
    //     //                                 checked={shipping === "paid-shipping"}
    //     //                                 onChange={() => handleRadioChange("paid-shipping")}
    //     //                             />
    //     //                             <label className="form-check-label" htmlFor="inlineRadio2">Paid Shipping</label>
    //     //                         </div>
    //     //                     </div>
    //     //                 </div>
    //     //                 {shipping == 'paid-shipping' ?
    //     //                     <>
    //     //                         <div className="row">
    //     //                             <div className="col-md-12">
    //     //                                 <p className="text-danger">In the video checkout, currently there is no support for products with paid shipping.</p>
    //     //                                 <div className="mb-3">
    //     //                                     <div className="form-group">
    //     //                                         <label htmlFor="weight">Weight (In Grams):</label>
    //     //                                         <input
    //     //                                             className="form-control"
    //     //                                             type="number"
    //     //                                             min="1"
    //     //                                             id="weight"
    //     //                                             placeholder="Enter Weight"
    //     //                                             value={weight}
    //     //                                             onChange={handleWeightChange}
    //     //                                         />
    //     //                                         {(weightError ? <small className="text-danger">{weightError}</small> : "")}
    //     //                                     </div>
    //     //                                 </div>
    //     //                             </div>
    //     //                         </div>
    //     //                         <div className="row">
    //     //                             <h5>Dimensions (L X W X H)</h5>
    //     //                             <div className="col-md-4">
    //     //                                 <div className="mb-3">
    //     //                                     <div className="form-group">
    //     //                                         <label htmlFor="weight">Lenght (In Centimeter):</label>
    //     //                                         <input
    //     //                                             className="form-control"
    //     //                                             type="number"
    //     //                                             min="1"
    //     //                                             id="weight"
    //     //                                             placeholder="Enter Lenght"
    //     //                                             value={lenght}
    //     //                                             onChange={handleLenghtChange}
    //     //                                         />
    //     //                                         {(lenghtError ? <small className="text-danger">{lenghtError}</small> : "")}
    //     //                                     </div>
    //     //                                 </div>
    //     //                             </div>

    //     //                             <div className="col-md-4">
    //     //                                 <div className="mb-3">
    //     //                                     <div className="form-group">
    //     //                                         <label htmlFor="weight">Width (In Centimeter):</label>
    //     //                                         <input
    //     //                                             className="form-control"
    //     //                                             type="number"
    //     //                                             min="1"
    //     //                                             id="weight"
    //     //                                             placeholder="Enter Width"
    //     //                                             value={width}
    //     //                                             onChange={handleWidthChange}
    //     //                                         />
    //     //                                         {(widthtError ? <small className="text-danger">{widthtError}</small> : "")}
    //     //                                     </div>
    //     //                                 </div>
    //     //                             </div>

    //     //                             <div className="col-md-4">
    //     //                                 <div className="mb-3">
    //     //                                     <div className="form-group">
    //     //                                         <label htmlFor="weight">Height (In Centimeter):</label>
    //     //                                         <input
    //     //                                             className="form-control"
    //     //                                             type="number"
    //     //                                             min="1"
    //     //                                             id="weight"
    //     //                                             placeholder="Enter Height"
    //     //                                             value={height}
    //     //                                             onChange={handleHeightChange}
    //     //                                         />
    //     //                                         {(heightError ? <small className="text-danger">{heightError}</small> : "")}
    //     //                                     </div>
    //     //                                 </div>
    //     //                             </div>
    //     //                         </div>
    //     //                     </>

    //     //                     : ""}

    //     //                 <ProductVariants ref={variantsRef} setHasVariant={setHasVariant} />


    //     //                 <div class="form-actions">
    //     //                     <div class="mt-3 d-flex justify-content-end">
    //     //                         <button type="button" onClick={() => setIsVisible(false)} class="m-l-5 btn btn-primary text-white ml-5 mr-5">Cancel</button>

    //     //                         <button type="button" onClick={handleSubmit} disabled={(isButtonDisabled || addProductLoader)} class="btn btn-primary text-white">
    //     //                             <div class="d-flex align-items-center">
    //     //                                 {addProductLoader ? (
    //     //                                     <div>
    //     //                                         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
    //     //                                     </div>
    //     //                                 ) : (
    //     //                                     <div><i class="ti ti-device-floppy me-1 fs-4"></i> Save & continue </div>
    //     //                                 )}
    //     //                             </div>
    //     //                         </button>

    //     //                     </div>
    //     //                 </div>
    //     //             </form>
    //     //         </div>
    //     //     </div>
    //     // </section>
    // );

}
export default EditVideoAddProduct;