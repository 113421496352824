import React, { useEffect, useState, useRef } from "react";
import ImageImg from "./../../../assets/icons/image.svg";
import WhiteSquareButton from "../../Form/WhiteSquareButton";
import UploadHelper from "../../helpers/upload";
import * as helper from "../../helpers/helper";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../component/Form/FormToast";
const defaultUploadPathName = UploadHelper.PRODUCT_IMAGE

const UploadThumbnailAddVideo = ({
    videoThumbnailURL,
    setVideoThumbnailURL,
    fileUploadLoading,
    setFileUploadLoading,
    uploadPathName = defaultUploadPathName
}) => {
    const inputRef = useRef();
    const imageRef = useRef();
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (!uploadPathName) {
            uploadPathName = UploadHelper.PRODUCT_IMAGE;
        }
    }, []);

    const allowedImageTypes = {
        "image/jpeg": 'jpg',
        "image/png": 'png',
        "image/webp": 'webp',
    };

    const validateVideoThumbnailImage = (photoFile) => {
        if (photoFile) {
            const fileType = photoFile.type;
            if (!allowedImageTypes.hasOwnProperty(fileType)) {
                setErrorMessage('jpeg, png and webp extensions are allowed');
                return false;
            } else {
                setErrorMessage(null);
                return true;
            }
        } else {
            return false;
        }
    }

    async function uploadThumbnail(event) {
        setVideoThumbnailURL(null);
        const photoFile = event.target.files[0];
        const allowedKB = helper.convertStorageUnit(process.env.REACT_APP_UPLOAD_IMAGE_MAX_FILE_SIZE_IN_KB, 'kb', 'b');
        const allowedMB = helper.convertStorageUnit(process.env.REACT_APP_UPLOAD_IMAGE_MAX_FILE_SIZE_IN_KB, 'kb', 'mb');


        setErrorMessage();
        if (photoFile?.size > allowedKB) {
            setErrorMessage(`File size exceeds the maximum limit of ${allowedMB}MB`);
            photoFile.value = '';
        } else {
            if (validateVideoThumbnailImage(photoFile)) {

                setFileUploadLoading(true);
                const uploadingPath = await UploadHelper.getSignedUrl(
                    uploadPathName
                    // UploadHelper.PRODUCT_IMAGE
                );

                if (uploadingPath) {
                    imageRef.current.src = URL.createObjectURL(photoFile)

                    await UploadHelper.uploadToS3(uploadingPath.signedUrl, photoFile);

                    setTimeout(() => {
                        setVideoThumbnailURL(uploadingPath.url);
                        setFileUploadLoading(false);
                    }, 1000);
                } else {
                    setFileUploadLoading(false);
                }
            }

        }
        inputRef.current.value = '';
    }

    const onPressButton = () => {
        inputRef.current.click();
    }

    const onPressClose = () => {
        setVideoThumbnailURL(null);
    }

    return (
        <>
            <div className={`w-sq-btn-view-thumbnail ${!videoThumbnailURL ? "d-none" : ""}`}>
                <div className="close" onClick={onPressClose}>
                    <i className="ti ti-x"></i>
                </div>
                <div className="w-sq-btn gap-2 position-relative" onClick={() => { }}>
                    <img className="view" src={videoThumbnailURL} ref={imageRef} alt="Upload Thumbnail" />
                </div>
            </div>
            {!videoThumbnailURL && (<WhiteSquareButton
                icon={<img src={ImageImg} alt="Upload Thumbnail" height="28" width="28" />}
                label={fileUploadLoading ? "Please wait" : "Upload Thumbnail"}
                active={false}
                onClick={onPressButton}
                disabled={fileUploadLoading}
            />)}
            <input type="file" style={{ display: 'none' }} accept=".jpg, .jpeg, .png, .webp" ref={inputRef} onChange={uploadThumbnail} />

            {errorMessage && (
                <FormToast
                    type={FORM_TOAST_TYPES.DANGER}
                    message={errorMessage}
                />
            )}
        </>
    )
}

export default UploadThumbnailAddVideo;