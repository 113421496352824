import React from "react";

const ToggleSwitchRow = ({ id, label, checked, onChange, disabled, errorMessage, width, height }) => {
    const switchStyle = {
        width: width || 'auto',   // Set width to the provided value or 'auto' if not provided
        height: height || 'auto', // Set height to the provided value or 'auto' if not provided
    };

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="form-checkform-check form-switch custom-switch">
                    <input
                        className="form-check-input primary"
                        type="checkbox"
                        name="isChecked"
                        id={id}
                        checked={checked}
                        onChange={onChange}
                        style={switchStyle}
                        disabled={disabled}
                    />
                    {label && <label className="form-check-label text-black" htmlFor={id}>
                        {label}
                    </label>}
                </div>
            </div>
            {errorMessage && <div><span className="text-danger fs-2">{errorMessage}</span></div>}
        </>
    );
}

export default ToggleSwitchRow;
