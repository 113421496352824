import React, { useEffect, useState, } from "react";
import { useHistory, Link } from "react-router-dom";
import placeholder from "../../../assets/thumbnail.jpg";
import "react-data-table-component-extensions/dist/index.css";
import { SocialShare } from "../../molecules/SocialShare";
import { calculateRemainingTime, showUserTypeBaseMessage } from '../../helpers/helper';
import ActivityLog from "../../helpers/ActivityLog";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import ExpandableVideosDetails from "./ExpandableVideosDetails";
import Button from "./../../Form/Button";
// import Input from "./../../Form/Input";
import FilterDropDown from "../../Form/FilterDropDown";
import ModalContainer from "../../Table/ModalContainer";
import Table from "../../Table/Table";
import AddDataPrompt from "../../molecules/DataPrompt/AddingDataPrompt";

const moment = require('moment');

function VideoList({ onAddVideo }) {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [expandViewType, setExpandViewType] = useState();
  const [momentCurrentTime, setMomentCurrentTime] = useState();
  const [openDropdowns, setOpenDropdowns] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showActivatePopup, setShowActivatePopup] = useState(false);
  const [filterOptionsValues, setFilterOptionsValues] = useState({});
  const videoTitleColText = showUserTypeBaseMessage({ Brand: 'Video', default: 'Video' });

  // const [importToggleBtn, setImportToggleBtn] = useState(false);


  const handleToggleDropdown = (rowId) => {
    if (openDropdowns === rowId) {
      setOpenDropdowns(null);
    } else {
      setOpenDropdowns(rowId);
    }
  };

  const watchBtnText = showUserTypeBaseMessage(
    {
      'Brand': 'Play ',
      'default': 'Watch Video',
      'NonProfit': 'Play',
      'Influencer': 'Play ',
      'Individual': 'Play',
    }
  );
  
  const filterOptions = {
    "PurposeType": {
      "label": "Purpose Type",
      "children": [
        {
          "label": "Sell Products",
          "value": "To sell your product"
        },
        {
          "label": "Get donations",
          "value": "To collect the donation"
        },
        {
          "label": "Capture Leads",
          "value": "To generate lead"
        }
      ]
    },
    "CheckoutType": {
      "label": "Checkout Type",
      "children": [
        {
          "label": "InVideo Checkout",
          "value": "InVideo Checkout"
        },
        {
          "label": "Shopify Cart",
          "value": "Shopify Cart"
        },
        {
          "label": "WooCommerce Cart",
          "value": "WooCommerce Cart"
        },
        {
          "label": "Collect Customer Info.",
          "value": "Collect Customer Info."
        },
        {
          "label": "Custom Checkout",
          "value": "Custom Checkout"
        }
      ]
    }
  };


  useEffect(() => {
    fetchVideosData();
  }, [currentPage, rowsperPage, searchValue,filterOptionsValues]);

  const fetchVideosData = () => {
    var token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/all-videos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": token.idToken, "currentPage": currentPage, "rowsPage": rowsperPage, "search": searchValue, filterOptionsValues:filterOptionsValues }),
    })
      .then((response) => response.json())
      .then((data) => {
        setVideotdata(data.data)
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
      })
      .finally(() => setLoader(true));
  };

  const fetchDeleteData = (id) => {
    var token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/delete-video', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === "200") {

          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem('admin_login_id');
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 4;
          var account_id = token.idToken;
          userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

          setSuccess("Video deleted successfully")
          setSelectedOrderId(null);
          fetchVideosData()
          setLoader(true)
          setTimeout(resetState, 2000);
        } else {
          setErrors("Something went wrong")
          setTimeout(resetState, 2000);
        }
      })
      .catch(() => {
        setErrors("Something went wrong")
        setTimeout(resetState, 2000);
      });
  };

  const fetchActivateVideo = (id) => {
    var token = localStorage.getItem("authSession")
    token = JSON.parse(token);

    fetch(process.env.REACT_APP_API_URL + '/activate-video', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": id }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === "200") {

          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem('admin_login_id');
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 4;
          var account_id = token.idToken;
          userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

          setSuccess("Video activated successfully")
          setSelectedOrderId(null);
          fetchVideosData()
          setLoader(true)
          setTimeout(resetState, 2000);
        } else {
          setErrors("Something went wrong")
          setTimeout(resetState, 2000);
        }
      })
      .catch(() => {
        setErrors("Something went wrong")
        setTimeout(resetState, 2000);
      });
  };

  const resetState = () => {
    setErrors('');
    setSuccess('');
  };

  const ActivatePopup = ({ onClose, onActivate }) => {
    return (
      <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block", " padding-left": "0px" }} aria-modal="true" role="dialog">
        <div class="modal-dialog modal-sm" >
          <div class="modal-content modal-filled">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7" onClick={onClose}></i>
                <h4 class="mt-2"> Are You sure? </h4>
                <p class="mt-3"> You want to activate the video? </p>
                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel </button>
                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onActivate}> Activate </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Popup = ({ onClose, onDelete }) => {
    return (
      <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block", " padding-left": "0px" }} aria-modal="true" role="dialog">

        <div class="modal-dialog modal-sm" >
          <div class="modal-content modal-filled">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7" onClick={onClose}></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">
                  You Want to delete Permanently?
                </p>
                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const dbDateFormat = 'YYYY-MM-DD HH:mm:ssZ';

  const handleDeleteClick = (id) => {
    handleToggleDropdown(id)
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId)
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleActivateClick = (id) => {
    handleToggleDropdown(id)
    setSelectedOrderId(id);
    setShowActivatePopup(true);
  };

  const handleActivate = () => {
    fetchActivateVideo(selectedOrderId);
    setShowActivatePopup(false);
  };

  const handleCloseActivatePopup = () => {
    setShowActivatePopup(false);
  };

  const closeFromChild = () => {
    setCurrentRow(null);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setrRowsperPage(newPerPage)
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false)
  };

  // const columns = [
  //   {
  //     name: videoTitleColText,
  //     sortable: true,
  //     width: "380px",
  //     selector: (row, i) => {

  //       let iconClass = '';

  //       if (['live-stream-from-external-source', 'live-stream-from-browser'].indexOf(row.stream_detail) !== -1) {
  //         iconClass = 'ti ti-cast';
  //       } else if (row.stream_detail === 'facebook-integration') {
  //         iconClass = 'ti ti-brand-facebook';
  //       }


  //       const imageSrc = row.video_thumbnail && row.video_thumbnail !== 'false' ? row.video_thumbnail : placeholder;
  //       return (
  //         <div className="d-flex flex-row align-items-center gap-2">
  //           {iconClass ? (
  //             <div className="rounded-2 border border-2 border-white d-flex justify-content-center align-items-center" style={{ height: 51, width: 51 }}>
  //               <span title="Live Stream"><i className={`${iconClass} fs-10`}></i></span>
  //             </div>
  //           ) : (
  //             <img
  //               src={imageSrc}
  //               className="rounded-2 border border-2 border-white"
  //               width={51}
  //               height={51}
  //               alt={row.name}
  //             />
  //           )}
  //           <div className="d-flex flex-column gap-1">
  //             <span className="fw-semibold text-black fs-3 text-wrap">{row.videoname}</span>
  //           </div>
  //         </div>
  //       )
  //     }
      
  //   },
  //   {
  //     name: "Purpose",
  //     center: true,
  //     width: "200px",
  //     selector: (row, i) => (
  //       <div className="d-flex flex-row align-items-center gap-2">
  //         <span className="fw-semibold text-black fs-3">{row.video_type}</span>
  //       </div>
  //     ),
  //     sortable: true,
      
  //   }
  //   ,
  //   {
  //     name: "Status",
  //     width: "170px",
  //     sortable: true,
  //     cell: (row) => (
  //       <span
  //         className={`mb-1 badge font-medium text-success d-flex align-items-center ${row.status === "Published" ? "bg-status-light-success" : (row.status === "Draft" || row.status === "Disabled") ? "bg-status-light-warning" : ""}`}
  //         title={row.status}
  //       >
  //         {row.status}
  //       </span>
  //     )
     
  //   },
  //   {
  //     name: "Share",
  //     selector: (row) => row.share,
  //     sortable: true,
  //     center: true,
  //     cell: (row) => {
  //       let isShareableButton = false;
  //       if (row.status === 'Draft') {
  //         return <i class="ti ti-share-2 fs-8 disabled-event"></i>;
  //       } else {
  //         const parsedStartTime = moment(row.start_time, dbDateFormat);

  //         if (row.stream_detail === 'live-stream-from-browser') {
  //           if (parsedStartTime.isSameOrBefore(momentCurrentTime)) {
  //             isShareableButton = true;
  //           }
  //         } else {
  //           isShareableButton = true;
  //         }

  //         return isShareableButton === true ? (
  //           <i class="ti ti-share-2 fs-8 cursor-pointer" onClick={(e) => handleOpenModal(row, 1)}></i>
  //         ) : "";
  //       }
  //     },
  //     width: "150px",
  //   },
  //   {
  //     name: watchBtnText,
  //     selector: (row) => row.id,
  //     sortable: true,
  //     width: "150px",
  //     cell: (row) => {
  //       if (row.status === 'Draft') {
  //         return <i className="ti ti-player-play gray-disabled-tag fs-8"></i>
  //       } else {
  //         let videoTabStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' };
  //         const parsedStartTime = moment(row.start_time, dbDateFormat);
  //         const parsedEndTime = moment(row.end_time, dbDateFormat);
  //         if (row.stream_detail?.toLowerCase() === 'live-stream-from-browser') {
  //           if (moment(row.end_time, dbDateFormat).isBefore(momentCurrentTime)) {
  //             return <div style={videoTabStyles}><a className="btn rounded-pill waves-effect waves-light btn-primary" rel="noreferrer" target="_blank" href={process.env.REACT_APP_WEB_URL + '/watch/' + row.id}><i class="ti ti-video fs-5"></i> {watchBtnText}</a></div>
  //           } else if (parsedStartTime.isBefore(momentCurrentTime) && parsedEndTime.isAfter(momentCurrentTime)) {
  //             return <div style={videoTabStyles}><Link className="btn rounded-pill waves-effect waves-light btn-primary" to={'/videos/record-stream/' + row.id}><i class="ti ti-video fs-5"></i> Start Live Stream</Link> </div>
  //           } else if (parsedStartTime.isAfter(momentCurrentTime)) {
  //             return <div style={videoTabStyles}>{calculateRemainingTime(parsedStartTime, dbDateFormat)} left to start streaming</div>
  //           }
  //         } else {
  //           return <a className="theme-color" target="_blank" rel="noreferrer" href={process.env.REACT_APP_WEB_URL + '/watch/' + row.id}><i className="ti ti-player-play  fs-8"></i></a>
  //         }
  //       }
  //     }
   
  //   }
  // ];

   if (loader && videodata && !videodata.length && !searchValue && !filterOptions) {

    // if (addprompt === true) {
      return (
        <div className="data-promptWrapper">
          <AddDataPrompt   
                heading="Create Videos" 
                title="Create shoppable videos to boost your sales effortlessly." 
                link="videos/add-nav-video" 
                btntxt="Create Video"/>
        </div>
      );
    // }

  }


  const columns = [
    {
      name: videoTitleColText,
      selector: (row) => row.videoname,
      sortable: true,
      width: "380px",
      cell: (row) => {
        let iconClass = '';
  
        if (['live-stream-from-external-source', 'live-stream-from-browser'].indexOf(row.stream_detail) !== -1) {
          iconClass = 'ti ti-cast';
        } else if (row.stream_detail === 'facebook-integration') {
          iconClass = 'ti ti-brand-facebook';
        }
  
        const imageSrc = row.video_thumbnail && row.video_thumbnail !== 'false' ? row.video_thumbnail : placeholder;
        return (
          <div className="d-flex flex-row align-items-center gap-2">
            {iconClass ? (
              <div className="rounded-2 border border-2 border-white d-flex justify-content-center align-items-center" style={{ height: 51, width: 38 }}>
                <span title="Live Stream"><i className={`${iconClass} fs-10`}></i></span>
              </div>
            ) : (
              <img
                src={imageSrc}
                className="rounded-2 border border-2 border-white"
                width={38}
                height={51}
                alt={row.name}
              />
            )}
            <div className="d-flex flex-column gap-1">
              <span className="fw-semibold text-black fs-3 text-wrap">{row.videoname}</span>
            </div>
          </div>
        );
      },
    },
    {
      name: "Purpose",
      selector: (row) => row.video_type,
      sortable: true,
      center: true,
      width: "200px",
      cell: (row) => {

        let purtext = "";
        if (row.video_type === 'To sell your product') {
         purtext = "Sell Products";
        }else if (row.video_type === 'To collect the donation') {
          purtext = "Get donations";
         }else if (row.video_type === 'To generate lead') {
          purtext = "Capture Leads";
         } else{
         purtext = "";
        }

        return   <div className="d-flex flex-row align-items-center gap-2">
          <span className="fw-semibold text-black fs-3">{purtext}</span>
        </div>;

    
      },
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      width: "170px",
      cell: (row) => (
        <span
          className={`mb-1 badge font-medium text-success d-flex align-items-center ${row.status === "Published" ? "bg-status-light-success" : (row.status === "Draft" || row.status === "Disabled") ? "bg-status-light-warning" : ""}`}
          title={row.status}
        >
          {row.status}
        </span>
      ),
    },
    {
      name: "Share",
      selector: (row) => row.share, // Assuming 'share' is a sortable field in your data
      sortable: true,
      center: true,
      width: "150px",
      cell: (row) => {
        let isShareableButton = false;
        if (row.status === 'Draft') {
          return <i className="ti ti-share-2 fs-8 disabled-event"></i>;
        } else {
          const parsedStartTime = moment(row.start_time, dbDateFormat);
  
          if (row.stream_detail === 'live-stream-from-browser') {
            if (parsedStartTime.isSameOrBefore(momentCurrentTime)) {
              isShareableButton = true;
            }
          } else {
            isShareableButton = true;
          }
  
          return isShareableButton === true ? (
            <i className="ti ti-share-2 fs-8 cursor-pointer" onClick={(e) => handleOpenModal(row, 1)}></i>
          ) : "";
        }
      },
    },
    {
      name: watchBtnText,
      selector: (row) => row.id, // Assuming 'id' is a sortable field in your data
      sortable: true,
      center: true,
      width: "150px",
      cell: (row) => {
        if (row.status === 'Draft') {
          return <i className="ti ti-player-play gray-disabled-tag fs-8"></i>;
        } else {
          let videoTabStyles = { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' };
          const parsedStartTime = moment(row.start_time, dbDateFormat);
          const parsedEndTime = moment(row.end_time, dbDateFormat);
          if (row.stream_detail?.toLowerCase() === 'live-stream-from-browser') {
            if (moment(row.end_time, dbDateFormat).isBefore(momentCurrentTime)) {
              return <div style={videoTabStyles}><a className="btn rounded-pill waves-effect waves-light btn-primary" rel="noreferrer" target="_blank" href={process.env.REACT_APP_WEB_URL + '/watch/' + row.id}><i className="ti ti-video fs-5"></i> {watchBtnText}</a></div>
            } else if (parsedStartTime.isBefore(momentCurrentTime) && parsedEndTime.isAfter(momentCurrentTime)) {
              return <div style={videoTabStyles}><Link className="btn rounded-pill waves-effect waves-light btn-primary" to={'/videos/record-stream/' + row.id}><i className="ti ti-video fs-5"></i> Start Live Stream</Link></div>
            } else if (parsedStartTime.isAfter(momentCurrentTime)) {
              return <div style={videoTabStyles}>{calculateRemainingTime(parsedStartTime, dbDateFormat)} left to start streaming</div>
            }
          } else {
            return <a className="theme-color" target="_blank" rel="noreferrer" href={process.env.REACT_APP_WEB_URL + '/watch/' + row.id}><i className="ti ti-player-play fs-8"></i></a>;
          }
        }
      },
    },
  ];

  const data = videodata;

  const buttonText = showUserTypeBaseMessage(
    {
      'Brand': 'Embed video to web',
      'default': 'Add Integration to web',
      'NonProfit': 'Embed video to web',
      'Influencer': 'Embed video to web',
      'Individual': 'Embed video to web',
    }
  );

  const addVideoText = showUserTypeBaseMessage(
    {
      'Brand': 'Add Video',
      'default': 'Add Video',
      'Influencer': 'Add Video',
    }
  );

  const handleOpenModal = (row, expandType) => {
    // console.log('row---handleOpenModal>>',row)
    if(row?.status!=="Draft"){
      setCurrentRow(row);
      setExpandViewType(expandType);
      setOpenModal(true);
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  return (
    <div className="App video-table vs-table">
      {showPopup && <Popup onClose={handleClosePopup} onDelete={handleDelete} />}
      {showActivatePopup && <ActivatePopup onClose={handleCloseActivatePopup} onActivate={handleActivate} />}
      {errors && (<FormToast type={FORM_TOAST_TYPES.DANGER} message={errors} />)}
      {success && (<FormToast type={FORM_TOAST_TYPES.SUCCESS} message={success} />)}

      {/* <div className="row">
        <h3 className="fw-bolder">Manage Videos</h3>
      </div> */}

      <div className="videos-listing-action-btns action-row">
        <form className="">
          <div className="position-relative action-search">

          <input
            type="text"
            className="form-control search-chat py-2 ps-5 d-lg-inline"
            value={searchValue}
            placeholder="Search"
            onChange={handleSearchChange}
          />
          <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
          </div>

          <FilterDropDown callBack={setFilterOptionsValues} filterOptions={filterOptions} />

          {/* <div>

          <Input
              id="store-url"
              type="text"
              // label=""
              // value={leadName}
              // onChange={(e) => handleNameChange(e)}
              disabled={false}
              placeholder="Filter Type"
              rightIconClass="ti ti-chevron-down"
              leftIconClass="ti ti-filter"
              // errorMessage={leadNameError}
          />

          </div> */}
        </form>
        <div className="action-buttons">
          <Button
            className="btn btn-outline-primary"
            type="button"
            buttonText={buttonText}
            onClick={() => history.push("/videos/integration-on-web/web-integration")}
          />
          <Button
            className="btn btn-primary ml-10"
            type="button"
            buttonText={addVideoText}
            onClick={onAddVideo}
          />
        </div>
      </div>

      <div>
        <Table
          loading={!loader}
          columns={columns}
          data={data}
          totalRows={totalRows}
          currentPage={currentPage}
          currentRow={currentRow}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          onRowClicked={(row) => handleOpenModal(row, 2)}
          actions={[
            (row) => {

              let isEditLinkDisabled = false;
              let editLink = `/videos/edit-video-nav/${row.id}`;

              if (row.status === 'Draft') {
                editLink = `/videos/add-nav-video/${row.id}`;

              } else if ((row.stream_detail === 'live-stream-from-browser' || row.stream_detail === 'live-stream-from-external-source')) {
                isEditLinkDisabled = true;
              }

              return ({
                disabled: isEditLinkDisabled,
                icon: <i className="fs-4 ti ti-edit"></i>,
                label: "Edit",
                title: ["SHOPIFY", "WOO COMMERCE"].indexOf(row.product_added_from) !== -1 ? "Synced product cannot be edited" : "",
                onClick: () => history.push(editLink),
              })
            },
            (row) => ({
              disabled: false,
              icon:
                row.status === "Disabled" ? (
                  <i className="fs-4 ti ti-checks"></i>
                ) : (
                  <i className="fs-4 ti ti-trash"></i>
                ),
              label: row.status === "Disabled" ? "Publish" : "Delete",
              title: row.status === "Disabled" ? "Publish" : "Delete",
              onClick: (row) => row.status === "Disabled" ? handleActivateClick(row?.id) : handleDeleteClick(row?.id),
            }),
          ]}
        />
        <ModalContainer
          width={expandViewType === 1 ? "500" : "650"}
          children={expandViewType === 1 ? <SocialShare data={currentRow} /> : <ExpandableVideosDetails data={currentRow} closeFromChild={closeFromChild} />}
          isOpen={openModal}
          closable={false}
          handleCloseModal={(e) => handleCloseModal(e)}
        />
      </div>
    </div>
  );
}

export default VideoList;
