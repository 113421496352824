import { useState } from "react";
import * as React from "react";
// import {
//   Button,
//   FormGroup,
//   Intent,
//   Classes,
//   Dialog,
//   InputGroup,
//   Callout
// } from "@blueprintjs/core";
// import FormInfo from "../../../../atoms/FormInfo/FormInfo";
// import { useMutation } from "@apollo/react-hooks";
// import { UpdateAccountPassword } from "./mutation";
// import Auth0 from "../../../helpers/Auth0";
import Modal from 'react-modal';
import {userLoggedAccessToken, isMobile, showUserTypeBaseMessage} from "../../../helpers/helper";
import Button from "../../../Form/Button";

import validateField from  "../../../helpers/AllValidtions";

function PasswordInput() {
  const [passwordUpdateSuccessMsg,setPasswordUpdateSuccessMsg] = useState(null);
  const [passwordUpdateErrorMsg,setPasswordUpdateErrorMsg] = useState(null);
  const [currentPassword,setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [updatePasswordloader,setUpdatePasswordloader] = useState(false);

  const [currentPasswordError,setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const isMobileSize = isMobile();


  const handleNewPasswordChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setNewPassword(value);
    validateField(value, rule, setNewPasswordError);

};

const handleConfirmPasswordChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setConfirmPassword(value);

    console.log(newPassword,"hahkhkjhkjhkjhkhkh")

    if(value !==newPassword){
      setConfirmPasswordError("New password and confirm password did not matched")

    }else{
        //  validateField(value, rule, setConfirmPasswordError);
         setConfirmPasswordError("")
    }



 

    

    };

const handCurrentPasswordChange = (e) => {

        var value = e.target.value;
        var rule = "required";
        setCurrentPassword(value)
        validateField(value, rule, setCurrentPasswordError);

      }


  const changePasswordModalStyles = {
    content: {
      top: '100px',
      inset:'auto',
      width:isMobileSize?'90%':'450px',
      margin:'100px auto',
      position:'relative',
      border: "none",
      backgroundColor: null,
    },
    overlay: {
      zIndex: 1000,
      backgroundColor:'#00000066'
    }
  };

  const handleOpenModalUpdatePass = () => {
    setChangePasswordModal(true);
  }

  const handleCloseModalUpdatePass = () => {
    setChangePasswordModal(false);
  }


  const updatePassword = () => {

    setPasswordUpdateErrorMsg(null);
    setPasswordUpdateSuccessMsg(null);

    // if(currentPassword.trim().length<=0){
    //   setPasswordUpdateErrorMsg('Please enter current valid password');
    // } else if(newPassword.trim().length<=0){
    //   setPasswordUpdateErrorMsg('Please enter new valid password');
    // } else if(confirmPassword.trim().length<4){
    //   setPasswordUpdateErrorMsg('password is required with minimum 4 length');
    // } else if(confirmPassword !==newPassword){
    //   setPasswordUpdateErrorMsg('New password and confirm password did not matched');
    // } else {

      setUpdatePasswordloader(true);

      fetch(process.env.REACT_APP_API_URL + '/change-update-password', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userLoggedAccessToken(),
        },
        body: JSON.stringify({currentPassword:currentPassword, newPassword:newPassword, confirmPassword:confirmPassword}), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 200) {
          setPasswordUpdateSuccessMsg(data?.message);
        } else {
          setPasswordUpdateErrorMsg(data?.message);
        }
        // console.log('data',data);
        // setUserSubscribedData(data?.data);
      }).catch((error) => {
        setPasswordUpdateErrorMsg('Something went wrong');
          console.error('Error fetching data:', error);
      }).finally((error) => {
        setUpdatePasswordloader(false);
      });

    // } 




  };

  // updatePassword


  const disableButton = updatePasswordloader || !currentPassword || !newPassword || !confirmPassword || currentPasswordError || newPasswordError || confirmPasswordError;

  return (
    <>
          <Modal 
           isOpen={changePasswordModal}
           style={changePasswordModalStyles}
           closable={false}
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
              <div>
                <div className="card">

                <div className="px-4 py-3 border-bottom update-pass-header">
                  <h5 className="card-title fw-semibold mb-0">Update Password</h5>
                  <button disabled={updatePasswordloader} type="button" onClick={handleCloseModalUpdatePass} class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                
                  <div className="card-body p-4">
                      
                    {(passwordUpdateErrorMsg) ? <div class="alert alert-danger" role="alert"><strong></strong> {passwordUpdateErrorMsg} </div> : ""}
                    {(passwordUpdateSuccessMsg) ? <div class="alert alert-success" role="alert"><strong></strong> {passwordUpdateSuccessMsg} </div> : ""}
                    {/* <button onClick={(e) => saveCreditCard()} className="btn btn-primary">Subscribe Plan</button> */}

                    <div className="mb-4">
                      <label htmlFor="current-password" aria-required className="form-label fw-semibold">Current Password *</label>
                      <input type="password" id="current-password" onChange={handCurrentPasswordChange} placeholder="Enter current password" class="form-control" />
                      {(currentPasswordError?<small className="text-danger">{currentPasswordError}</small>:"")}
                    </div>

                    <div className="mb-4">
                      <label htmlFor="new-password" aria-required className="form-label fw-semibold">New Password *</label>
                      <input type="password" id="new-password" value={newPassword} onChange={handleNewPasswordChange} placeholder="Enter new password" class="form-control" />
                      {(newPasswordError?<small className="text-danger">{newPasswordError}</small>:"")}
                    </div>

                    <div className="mb-4">
                      <label htmlFor="confirm-new-password" aria-required className="form-label fw-semibold">Confirm Password *</label>
                      <input type="password" id="confirm-new-password" value={confirmPassword} onChange={handleConfirmPasswordChange} placeholder="Enter confirm password" class="form-control" />
                      {(confirmPasswordError?<small className="text-danger">{confirmPasswordError}</small>:"")}
                    </div>


                    <div className="d-flex justify-content-end">

                      {/* <button type="submit" onClick={(e)=>confirmCancelSubscription(subscribedPlanId)} class="btn btn-primary text-white">                                                 */}
                      <button type="button" disabled={disableButton} onClick={updatePassword} class="btn btn-primary text-white">                                                
                        <div class="d-flex align-items-center">
                        {updatePasswordloader ? (
                                <div>
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                </div>
                            ) : (
                                <div>Update</div>
                            )}

                        </div>
                      </button>

                      <button type="button" disabled={updatePasswordloader} onClick={handleCloseModalUpdatePass} class="btn btn-primary text-white ml-5" data-bs-dismiss="modal">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
                
              </div>
              <div class="modal-footer">
                
              </div>
            </div>
          </div>
        </Modal>

        {/* className="update-profile-button" */}


      <div className="col-12">
        <div className="p-3 update-profile-button">
          <label className="form-label">Password</label>
          <p>
          {showUserTypeBaseMessage({'Brand': 'Use a strong password. Your password should contain at least one number, letter, and symbol.', 'default':'Use a strong password. Your password should contain at least one number, letter, and symbol.'})}
          </p>

          <div className="update-password-btn-area">
            <Button 
              className="btn btn-outline-primary pull-right"
              buttonText="Update Password"
              type="button"
              onClick={() => handleOpenModalUpdatePass(true)}
            />
          </div>

          
          {/* <button
          type="button"
          className="btn btn-primary text-whit"
            text="Update Password"
            intent={Intent.PRIMARY}
            onClick={() => handleOpenModalUpdatePass(true)}
          >
            Update Password
            </button> */}
        </div>
      </div>

    </>
  );
}

export default PasswordInput;
