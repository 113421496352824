import React, { useState, useEffect } from "react";
import { useChangeProfile } from "./hook";
import { isMobile, userLoggedAccessToken, showUserTypeBaseMessage } from "../../../helpers/helper";
import CurrencyList from 'currency-list';
import validateField from "../../../helpers/AllValidtions";
import Input from "../../../Form/Input";
import Button from "../../../Form/Button";
import Textarea from "../../../Form/Textarea";
import ProfileBrandUploadArea from "./ProfileBrandUploadArea";
import UploadHelper from "../../../helpers/upload";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../Form/FormToast";
const CURRENCIES = CurrencyList.getAll('en_US');



function BrandInformation() {
    const isMobileSize = isMobile();
    const [profile, setProfile] = useState();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');

    const [brandNameError, setbrandNameError] = useState("");
    const [brandDescriptionError, setbrandDescriptionError] = useState("");
    const [brandWebsiteError, setbrandWebsiteError] = useState("");
    const [brandCurrencyError, setbrandCurrencyError] = useState("");
    const [fileUploadLoading, setFileUploadLoading] = useState(false);


    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/get-profile', {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + userLoggedAccessToken(),
            },
        })
            .then((response) => response.json())
            .then((response) => {
                if (response?.status) {
                    setProfile(response.data)
                }
            });

    }, []);

    const {
        // error,
        // name,
        // email,
        // bio,
        // supportEmail,
        // termsOfServiceUrl,
        // refundPolicyUrl,
        // privacyPolicyUrl,
        // website,
        // image,
        brandName,
        brandDescription,
        brandWebsite,
        brandCurrency,
        brandImage,
        // onImageChange,
        // setName,
        // setEmail,
        // setBio,
        // setSupportEmail,
        // setTermsOfServiceUrl,
        // setPrivacyPolicyUrl,
        // setRefundPolicyUrl,
        // setWebsite,
        setBrandName,
        setBrandDescription,
        setBrandWebsite,
        setBrandCurrency,
        onBrandImageChange,
        errorField,
        isSaving,
        // saveChanges,
        onBrandSaveChanges,
        success,
        // clearAllWarnings
    } = useChangeProfile({ profile });

    // const onNavBarClick = () => {
    //     setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    // }

    useEffect(() => {

        var val="USD"

        setBrandCurrency(val);
    
    }, 
        []);

    const handlebrandNameChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setBrandName(value);
        validateField(value, rule, setbrandNameError);

    };

    const handlebrandDescriptionChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setBrandDescription(value);
        validateField(value, rule, setbrandDescriptionError);

    };

    const handlebrandWebsiteChange = (e) => {
        var value = e.target.value;
        var rule = "url";
        setBrandWebsite(value);
        validateField(value, rule, setbrandWebsiteError);

    };

    const handlebrandCurrencyChange = (e) => {
        var value = e.target.value;
        var rule = "required";
       
        setBrandCurrency(value);

        validateField(value, rule, setbrandCurrencyError);

    };

    // const brandTitle = showUserTypeBaseMessage({
    //     'Brand': 'My Brand ',
    //     'default': 'Brand Information',
    //     'NonProfit': 'My Campaign',
    //     'Influencer': 'My Content ',
    //     'Individual': 'My Offerings',
    // });

    // const breadcrumpThirdLink = showUserTypeBaseMessage({
    //     'Brand': 'Brand Information ',
    //     'default': 'Brand Information',
    //     'NonProfit': 'Campaign Information',
    //     'Influencer': 'Content Information ',
    //     'Individual': 'Offerings Information',
    // });

    // const uploadLogoTitle = showUserTypeBaseMessage({
    //     'Brand': 'Brand Logo ',
    //     'default': 'Upload Logo',
    //     'NonProfit': 'Campaign Logo',
    //     'Influencer': 'Content Logo ',
    //     'Individual': 'Offerings Logo',
    // });

    // const logoDesc = showUserTypeBaseMessage({
    //     'Brand': 'Upload Your Logo',
    //     'default': 'Strengthen your brand identity through logo upload',
    //     'NonProfit': 'Upload Your Logo',
    //     'Influencer': 'Upload Your Logo ',
    //     'Individual': 'Upload Your Logo',
    // });

    // const brandTitle=  showUserTypeBaseMessage({'Brand': 'My Brand', 'default':'Brand Information'});

    // const logoDesc =  showUserTypeBaseMessage({'Brand': 'Upload Your Logo', 'default':'Strengthen your brand identity through logo upload'});



    const isButtonDisabled = !brandName || !brandDescription || !brandWebsite || !brandCurrency || brandNameError || brandDescriptionError || brandWebsiteError || brandCurrencyError || !brandImage;

    return (
        <div className="container col-lg-6 col-md-12 col-xs-12">
            <section className="central-form">

                {/* {error && (<FormToast type={FORM_TOAST_TYPES.DANGER} message={error} />)} */}
                {success && (<FormToast type={FORM_TOAST_TYPES.SUCCESS} message={success} />)}

                {/* {success && <div className="alert alert-success" role="alert"><strong>Success: </strong> {success} </div>} */}
                {/* {(error) && <div className="alert alert-danger" role="alert"><strong>Error: </strong> {error} </div>} */}
                <div className="mt-4">
                    <form className="form">
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-4">
                                    {/* <label for="brandName">Brand Name *</label>
                                    <input type="text" id="brandName" name="brandName" value={brandName} className={errorField === "brandName" ? "is-invalid form-control" : "form-control"} placeholder="Enter Brand Name" onChange={handlebrandNameChange} />
                                    {(brandNameError ? <small className="text-danger">{brandNameError}</small> : "")} */}
                                    <Input
                                        id="brandName"
                                        type="text"
                                        label="Brand Name *"
                                        value={brandName}
                                        onChange={(e) => handlebrandNameChange(e)}
                                        disabled={false}
                                        placeholder="Enter Brand Name"
                                        errorMessage={brandNameError}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-4">
                                    {/* <label for="brandDescription">Brand Description *</label>
                                    <textarea type="text" id="brandDescription" name="brandDescription" value={brandDescription} className={errorField === "brandDescription" ? "is-invalid form-control" : "form-control"} placeholder="Enter Brand Description" onChange={handlebrandDescriptionChange}></textarea>
                                    {(brandDescriptionError ? <small className="text-danger">{brandDescriptionError}</small> : "")} */}
                                    <Textarea
                                        id="brandDescription"
                                        label="Brand Description *"
                                        value={brandDescription}
                                        onChange={(e) => handlebrandDescriptionChange(e)}
                                        disabled={false}
                                        placeholder="Enter Brand Description"
                                        errorMessage={brandDescriptionError}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-4">                                    
                                    <Input
                                        id="brandWebsite"
                                        type="url"
                                        label="Brand Website URL *"
                                        value={brandWebsite}
                                        onChange={(e) => handlebrandWebsiteChange(e)}
                                        disabled={false}
                                        placeholder="Enter Website URL"
                                        errorMessage={brandWebsiteError}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <ProfileBrandUploadArea
                                    fileUploadLoading={fileUploadLoading}
                                    setFileUploadLoading={setFileUploadLoading}
                                    label="Brand Image *"
                                    parentClass=""
                                    fileSrc={brandImage}
                                    onChangeEvent={onBrandImageChange}
                                    uploadPathName={UploadHelper.BRAND_IMAGE}
                                    inlineError={!brandImage?"Brand Image is required":null}
                                />
                            </div>
                        </div>




                        <div className="row">
                            <div className="col-12">
                                <div className="mb-4">
                                    <label for="brandWebsite" className="form-label">Currency *</label>
                                    <select value={brandCurrency} className={errorField === "brandWebsite" ? "is-invalid form-control" : "form-control"} aria-label="Default select example" onChange={handlebrandCurrencyChange}>
                                        <option value="">Select Currency</option>
                                        {Object.entries(CURRENCIES).map(([key, c]) => {
                                            return (
                                                <option key={`currency-${key}`} value={c?.code}>
                                                    {`${c?.name} (${c?.symbol_native})`}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {(brandCurrencyError ? <small className="text-danger">{brandCurrencyError}</small> : "")}
                                </div>
                            </div>
                        </div>
                   
                        <div className="form-actions submit">
                            <Button
                                type="button"
                                buttonText="Save"
                                fullButton={false}
                                loading={isSaving}
                                onClick={onBrandSaveChanges}
                                disabled={isButtonDisabled}
                            />
                        </div>
                    </form>
                </div>
            </section>
        </div>

    );
}

export default BrandInformation;
