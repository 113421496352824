import React, { useState, useEffect } from "react";
// import styles from "./Watchtime.module.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
// import { useHistory } from "react-router-dom";
import InfoIcon from "./../../../../assets/icons/info-black.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


function Watchtime() {
  const [date, setDate] = useState([]);
  const [watchtime, setWatchtime] = useState([]);
  // const [loader, setLoader] = useState(false);

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Watch Time Dates',
        },

      },
      y: {
        title: {
          display: true,
          text: 'Watch Time',
        },
        ticks: {
          beginAtZero: true,
          stepSize: 1,
        },
      },
    },
    plugins: {
      legend: {
        position: 'top',
        display: false
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
    },
  };

  // const labels = date;
  // const dates =['05 july','06 july','07 july', '08 july', '09 july', '10 july', '11 july',];
  // const labels=(date=="")?dates:date;
  const labels = date;

  const data = {
    labels,
    datasets: [
      {
        label: 'Watch Time',
        data: watchtime,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };

  useEffect(() => {
    fetchGraphData();
  }, []);

  const fetchGraphData = () => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/daily-watch-time', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      // body: JSON.stringify({}), // Replace with your POST data
      body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        setDate(data.data.dates)
        setWatchtime(data.data.watchtime)

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  return (
    <div class="card w-100 position-relative overflow-hidden">
      <div class="card-body py-3">
          <div class="d-flex align-items-center justify-content-between mb-4">
          <div class="d-flex align-items-center">
            <h5 class="card-title mb-0 fw-semibold">Daily Watch Time</h5>
          </div>
          <div class="ml-auto">
            <div title="Graphical representation of daily video viewing duration">
              <img src={InfoIcon} alt="" width="20" height="20" />
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center">
          </div>
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
}

export default Watchtime;
