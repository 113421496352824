import React from "react";

const CheckBoxRow = ({ id, label, checked, onChange, disabled, errorMessage }) => {
    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                <div className="form-check">
                    <input
                        className="form-check-input primary"
                        type="checkbox"
                        id={id}
                        checked={checked}
                        onChange={onChange}
                    />
                    <label className="form-check-label text-black" htmlFor={id}>
                        {label}
                    </label>
                </div>
            </div>
            {errorMessage && <div><span className="text-danger fs-2">{errorMessage}</span></div>}
        </>
    );
}

export default CheckBoxRow;
