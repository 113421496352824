import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import EditExternalCheckoutNav from "./EditExternalCheckoutNav";



const EditLeadCheckoutNav = () => {

    const history = useHistory();

    useEffect(() => {
        return history.push({
            pathname: `/edit-videos/select-nav-product/edit-lead-checkout-nav`,
            state: history.location.state,
        });
    }, [])

    return <EditExternalCheckoutNav />
}

export default EditLeadCheckoutNav;
