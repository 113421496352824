import React, { useEffect, useState, useRef } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import UploadHelper from "../../../../component/helpers/upload";
import { useHistory } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { userLoggedAccessToken } from "../../../helpers/helper";
import ImageUploader from '../../Products/ImageUploader';
import dashboardbreadcrumimage from "../../../../dist/images/dashboard/add-video.png";
import BreadCrump from "../../../Breadcrump/BreadCrump";
import * as helper from "../../../helpers/helper";
import Select from 'react-select';
import CheckOnBoardingSteps from "../Component/CheckOnBoardingSteps";
import SpinnerImage from "../../../../assets/spinner.png";
import validateField from "../../../helpers/AllValidtions";
import ProductVariants from "./../.././../../component/Product/ProductVariants"
import WhiteSquareButton from "../../../Form/WhiteSquareButton";
import ShirtImg from "./../../../../assets/icons/shirt.svg"
import DownloadCloudImg from "./../../../../assets/icons/download-cloud.svg"
import { VideoSteps } from "../Video/AddVideo";
import Input from "./../../../Form/Input"
import Textarea from "../../../Form/Textarea";
import FieldWarning from "../../../Form/FieldWarning";
import MultiSelect from "../../../Form/MultiSelect";
import QuantityInput from "../../../Form/QuantityInput";
import Button from "../../../Form/Button";
import RadioButtonRow from "../../../Form/RadioButtonRow";
import { VideoPurpose } from "../../../../Enums/VideoPurpose";

export const AddProductHeader = ({
    selectedType,
    addProductCallback,
    shopifyCallback,
    wooCommerceCallback,
    hideAddProduct
}) => {
    return (
        <div className="d-flex flex-wrap justify-content-center align-items-center gap-4 mb-4">
            {!hideAddProduct && (
                <WhiteSquareButton
                    icon={<img src={ShirtImg} alt="Add Product" height="28" width="28" />}
                    label="Add Product"
                    active={selectedType === "product"}
                    onClick={() => {
                        addProductCallback()
                    }}
                    styles={{
                        height: 109,
                        width: 150,
                    }}
                />
            )}
            <WhiteSquareButton
                icon={<img src={DownloadCloudImg} alt="Import from Shopify" height="24" width="24" />}
                label="Import from Shopify"
                active={selectedType === "shopify"}
                onClick={() => {
                    shopifyCallback()
                }}
                styles={{
                    height: 109,
                    width: 150,
                    padding: 8
                }}
            />
            <WhiteSquareButton
                icon={<img src={DownloadCloudImg} alt="Import from Woocommerce" height="24" width="24" />}
                label="Import from Woocommerce"
                active={selectedType === "woocommerce"}
                onClick={() => {
                    wooCommerceCallback()
                }}
                styles={{
                    height: 109,
                    width: 150,
                    padding: 8
                }}
            />
        </div>
    )
}

function AddProduct({ callFromVideo, ...props }) {
    const isMobileSize = helper.isMobile();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [description, setDescription] = useState("");
    const [productImage, setProductImage] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [progress, setProgress] = useState(0.0);
    const [wowzaId, setWowzaId] = useState();
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [productImageUrl, setProductImageUrl] = useState();
    const [showErrors, setShowErrors] = useState(false);
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [addProductLoader, setAddProductLoader] = useState(false);
    const allowedExt = "jpeg, png and webp";
    const [accountTypeMessage, setAccountTypeMessage] = useState('To start selling');
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [isVisibleAddProduct, setIsVisibleAddProduct] = useState(false);
    const [productMultiImages, setProductMultiImages] = useState([]);

    const [category, setCategory] = useState('');
    const [selectedOption, setSelectedOption] = useState([]);
    const [options, setOptions] = useState([]);

    const [titleError, setTitleError] = useState("");
    const [priceError, setPriceError] = useState("");
    const [quantityError, setQuantityError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [productMultiImagesError, setProductMultiImagesError] = useState("");
    const [minLimit, setMinLimit] = useState(1);

    const [hasVariants, setHasVariant] = useState(false);
    const variantsRef = useRef();

    const [shipping, setShipping] = useState('no-shipping');

    const [weight, setWeight] = useState("");
    const [weightError, setWeightError] = useState("");

    const [size, setSize] = useState("");
    const [sizeError, setSizeError] = useState("");

    const [lenght, setLenght] = useState("");
    const [lenghtError, setLenghtError] = useState("");

    const [width, setWidth] = useState("");
    const [widthtError, setWidthtError] = useState("");

    const [height, setHeight] = useState("");
    const [heightError, setHeightError] = useState("");

    const [selectedProductType, setSelectedProductType] = useState("");
    const [addProductVisible, setAddProductVisible] = useState(false);

    const ImageUploaderRef = useRef(null);


    const handleShippingChange = (e) => {
        if (shipping == false) {
            setShipping(true)
        } else {
            setShipping(false)
            setWeight("")
            setHeight("")
            setWidth("")
            setLenght("")
        }

    };

    const handleLenghtChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setLenght(value);
        validateField(value, rule, setLenghtError);

    };

    const handleWidthChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWidth(value);
        validateField(value, rule, setWidthtError);

    };

    const handleHeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setHeight(value);
        validateField(value, rule, setHeightError);

    };

    const handleSizeChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setSize(value);
        validateField(value, rule, setSizeError);

    };

    const handleWeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWeight(value);
        validateField(value, rule, setWeightError);

    };

    const handleTitleChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setTitle(value);
        validateField(value, rule, setTitleError);

    };

    const handlePriceChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setPrice(value);
        validateField(value, rule, setPriceError);
    };

    const handleQuantityChange = (e) => {
        var value = e.target.value;
        setQuantity(value);
        validateField(value, "required", setQuantityError);
    };

    const handleCategoryChange = selectedOption => {

        var rule = "cat-required";
        var value = selectedOption;
        setSelectedOption(selectedOption)
        validateField(value, rule, setCategoryError);

    };

    const allowedImageTypes = {
        "image/jpeg": 'jpg',
        "image/png": 'png',
        "image/webp": 'webp',
    };

    const onStepIsPassed = (e) => {
        const user_data = JSON.parse(e?.user_data)
        if (history.location.state?.backFromExternal!==true) {
            history.push({
                pathname: '/lead-checkout',
                state: user_data
            });
        }
    }

    const { loading: stepsLoading } = CheckOnBoardingSteps({ currentStep: 2, onStepIsPassed });

    useEffect(() => {

        // 


        if (!history.location.state?.addVideoData) {
            history.push({ pathname: '/' });
        }

        if (history.location.state?.showSuccess) {
            setSuccessMessage(history.location.state.showSuccess);
        }

        if (history?.location?.state && history?.location?.state?.OnBoardingShowAddProduct) {
            setIsVisibleAddProduct(true);
        }

        if (
            history.location.state?.addVideoData?.videoType === VideoPurpose.TO_COLLECT_THE_DONATION ||
            history.location.state?.addVideoData?.videoType === VideoPurpose.TO_GENERATE_LEAD
        ) {

            if (!history.location.state?.addVideoData) {
                return navigateToCheckout()
            }

        }


        if(history.location.state?.selectedProducts){
            fetchProductData(history.location.state?.selectedProducts[0]?.value);
        }

        fetchCategoryData();
        setAccountTypeMessage(helper.showUserTypeBaseMessage({ NonProfit: "to collect donations", Influencer: "to earning commission", Brand: "start to selling", default: "start to selling" }));

    }, []);

    const fetchProductData = (productId) => {
        fetch(process.env.REACT_APP_API_URL + "/find-product", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + userLoggedAccessToken(),
            },
            body: JSON.stringify({ id: productId }),
        })
            .then((response) => response.json())
            .then((data) => {
                const productData = data.data;
                const productcat = data.category;
                setTitle(productData.name);
                setPrice(productData.price);
                setQuantity(productData.quantity);
                setDescription(productData.description);
                setProductImageUrl(productData.image);
                setCategory(productData.catid);
                fillExistingProductImages(productData.images);
                setSelectedOption(productcat);
                setHasVariant(productData.has_variants);
                // setVariants(productData?.variants);
                setShipping(productData?.has_shipping);
                setWeight(productData?.weight);
                setSize(productData?.size);

                setLenght(productData?.lenght);
                setWidth(productData?.width);
                setHeight(productData?.height);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const fillExistingProductImages = (productImages) => {
        if (productImages?.length) {
            const newObj = productImages.reduce((result, item) => {
                result[item.id] = item.url;
                return result;
            }, {});
            setProductMultiImages(newObj);

            ImageUploaderRef.current.setImageFromExternal(productImages)
        }
    };

    const handleMultipleImages = (multiImagesData) => {
        setProductMultiImages(multiImagesData);
        setProductImageUrl(multiImagesData?.length ? multiImagesData[0] : null);
    }

    const setCancelAddProduct = (e) => {
        if (history?.location?.state && history?.location?.state?.OnBoardingShowAddProduct) {
            history.location.state.OnBoardingShowAddProduct = false;
        }
        setIsVisibleAddProduct(false);

    };


    const fetchCategoryData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/all-product-category', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({}),
        })
            .then((response) => response.json())
            .then((data) => {
                setOptions(data.data)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const ImportProductRedirect = () => {
        history.location.state.OnBoardingShowImport = true;
        history.push({
            pathname: '/adding-keys',
            // pathname: '/import-product',

            
            state: history.location.state,
        });
    }

    const ImportWoocommerceProductRedirect = () => {
        history.location.state.OnBoardingShowImport = true;
        history.push({
            // pathname: '/import-woocommerce-product',
            pathname: '/woocommerce-adding-keys',
            state: history.location.state,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        var variants = [];
        if (hasVariants) {
            if (variantsRef.current && variantsRef.current.validateAndGetVariants) {
                const [error, data] = variantsRef.current.validateAndGetVariants();
                if (error || data.length === 0) return

                variants = data
            }
        }

        submitForm(title, price, quantity, description, productImageUrl, variants);

        // if (title != '' && price != '' && productImageUrl != '') {
        //     submitForm(title, price, description, productImageUrl, variants);
        // } else {
        //     setShowErrors('Please check Title, Image and Price fields');
        // }
    };

    function handleProgressEvent({ loaded, total }) {
        setProgress(loaded / total);
    }

    function validateImage(productImage) {
        if (productImage) {
            const fileType = productImage.type;
            if (!allowedImageTypes.hasOwnProperty(fileType)) {
                setErrorMessage(allowedExt + ' extensions are allowed');
                return false;
            } else {
                setErrorMessage(null);
                return true;
            }


        } else {
            return false;
        }
    }

    async function upload(event) {
        setProductImageUrl(null);
        const photoFile = event.target.files[0];
        setProductImage(photoFile);
        if (validateImage(photoFile)) {
            setFileUploadLoading(true);
            const uploadingPath = await UploadHelper.getSignedUrl(
                UploadHelper.PRODUCT_IMAGE
            );
            if (uploadingPath) {
                setProductImageUrl(uploadingPath.url);
                setFileUploadLoading(false);
                await UploadHelper.uploadToS3(uploadingPath.signedUrl, photoFile, handleProgressEvent);
            }
        }
    }

    const navigateToCheckout = () => {
        let history_push_link = "/external-checkout";

        const pushStateData = {
            addVideoData: history.location.state.addVideoData,
            addStreamData: history.location.state.addStreamData,
            addFacebookData: history.location.state.addFacebookData,
            selectedProducts: []
        };

        return history.push({
            pathname: history_push_link,
            state: pushStateData,
        })
    }

    const submitForm = (title, price, quantity, description, productImageValue, variants) => {
        const categoryValues = selectedOption.map(category => category.value).join(',');
        setAddProductLoader(true);
        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/user-insert-product', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + userLoggedAccessToken(),

                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "title": title,
                    "price": price,
                    "quantity": quantity,
                    "product_image": productImageValue,
                    "description": description,
                    "category": categoryValues,
                    "productMultiImages": productMultiImages,
                    "weight": weight,
                    "variants": hasVariants ? variants : [],
                    "shipping": shipping,
                    "lenght": lenght,
                    "width": width,
                    "height": height,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;

                        const pushStateData = {
                            addVideoData: history.location.state.addVideoData,
                            addStreamData: history.location.state.addStreamData,
                            addFacebookData: history.location.state.addFacebookData,
                            selectedProducts: [{ image: productImageValue, value: json.data, label: title, has_shipping: shipping }]
                        };
                        if (json?.code?.toString() === "200") {
                            history_push_link = "/external-checkout";

                            const draftData = {
                                userdata: pushStateData,
                                id: token.idToken,
                                step: 2,
                                draftType: 'onboarding'
                            };

                            const draftVideoData = new Promise((resolve, reject) => {
                                fetch(process.env.REACT_APP_API_URL + '/user-onboarding', {
                                    method: 'POST',
                                    mode: 'cors',
                                    headers: {
                                        "Content-Type": "application/json",
                                        "Authorization": "Bearer " + token.accessToken
                                    },
                                    body: JSON.stringify(draftData)
                                });
                            });
                            // history.push("/select-product")
                        } else {
                            setErrorMessage(json.message);
                            // history_push_link = "/add-product";
                        }

                        pushStateData.showSuccess = 'Your first ' + producText + ' has been added successfully next step is to publish your video ' + accountTypeMessage;


                        if (history_push_link) {
                            history.push({
                                pathname: history_push_link,
                                state: pushStateData,
                                // showSuccess: 'Product Added'

                            })
                        }
                    });
                }
            }).catch((error) => {
                if (error?.response) {
                    const jsonError = error.response.json(); // must await for response
                    const message = jsonError.message;
                    console.error(message)

                }

                setShowErrors('Something went wrong');
            }).finally((error) => {
                setAddProductLoader(false);
            });
        });
        return fetch_prods;
    };

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    var isButtonDisabled = !title?.trim() || selectedOption.length < 1 || !productImageUrl?.trim() || titleError || categoryError || productMultiImagesError;

    if (!hasVariants) {
        // if ((!price || typeof price !== 'string' || !price.trim()) || priceError || quantityError) {
        if (!price || priceError || quantityError) {

            isButtonDisabled = true;
        }
    }

    if (shipping == 'paid-shipping') {
        if (!weight?.trim() || weightError || !lenght?.trim() || lenghtError || !width?.trim() || widthtError || !height?.trim() || heightError) {
            isButtonDisabled = true;
        }
    }

    const importProduct = () => {
        history.push({
            pathname: `import-product`,
            state: history.location.state
        })
    }


    const categoryMaxAllowedLimit = process.env.REACT_APP_SELECT_CATEGORY_MAX_LIMIT;

    // const producText = helper.showUserTypeBaseMessage({ NonProfit: "Donation Campaign", default: "Product" });
    // const addProductText = helper.showUserTypeBaseMessage({ NonProfit: "Add Donation Campaign", default: "Add Product" });

    // const producText = helper.showUserTypeBaseMessage({ NonProfit: "Donation Campaign", default: "Product" });
    const addProductText = helper.showUserTypeBaseMessage({ NonProfit: "Add Donation Campaign", default: "Add Product" });


    const producText = helper.showUserTypeBaseMessage(
        {
            //   'Brand': ``, 
            'default': `Product`,
            'NonProfit': `Donation Campaign`,
            //   'Influencer':``,
            //   'Individual':``,
        }
    );

    const handleRadioChange = (value) => {
        setShipping(value);
    };

    const backToMainVideoStep = () => {
        return history.push({
            pathname: "/add-video",
            state: history.location.state,
        });
    };

    const handleProductTypeNext = () => {
        if (selectedProductType === "product") {
            return setAddProductVisible(true)
        }

        if (selectedProductType === "shopify") {
            return ImportProductRedirect()
        }

        if (selectedProductType === "woocommerce") {
            return ImportWoocommerceProductRedirect();
        }
    }

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Add Product" onNavBarClick={onNavBarClick} formWidth={true}>
                <div className="row pb-50">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                {/* <div className="card-header-back" onClick={() => { backToMainVideoStep()}}>
                                    <i className="ti ti-arrow-left"></i>
                                </div> */}
                                <h5 className="fw-bolder">Create Your First Shoppable Video</h5>
                                <VideoSteps onBoarding step="3" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body form-card-body">
                                <div className="w-500">
                                    {!addProductVisible && <AddProductHeader
                                        selectedType={selectedProductType}
                                        addProductCallback={() => setSelectedProductType("product")}
                                        shopifyCallback={() => setSelectedProductType("shopify")}
                                        wooCommerceCallback={() => setSelectedProductType("woocommerce")}
                                        // shopifyCallback={ImportProductRedirect}
                                        // wooCommerceCallback={ImportWoocommerceProductRedirect}
                                    />}
                                </div>
                                {
                                    addProductVisible && (
                                        <>
                                            <div className="w-500">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-3">
                                                            <Input
                                                                id="title"
                                                                type="text"
                                                                label={`${producText} Title *`}
                                                                value={title}
                                                                onChange={handleTitleChange}
                                                                disabled={false}
                                                                placeholder=""
                                                                errorMessage={titleError}
                                                            />
                                                        </div>
                                                        <div className="mb-2">
                                                            <Textarea
                                                                id="description"
                                                                label={`${producText} Description`}
                                                                value={description}
                                                                onChange={(e) => setDescription(e.target.value)}
                                                                disabled={false}
                                                                placeholder=""
                                                                errorMessage=""
                                                            />
                                                        </div>
                                                        <FieldWarning message="Enter a detailed description of the product here, including its key features and benefits. Don't forget to specify available variations such as size, color, and style. Providing comprehensive information will help customers make informed choices." />
                                                        <div className="mt-3 mb-3">
                                                            <MultiSelect
                                                                limit={categoryMaxAllowedLimit}
                                                                id="category"
                                                                required={true}
                                                                value={selectedOption}
                                                                label="Category"
                                                                options={options}
                                                                onChange={handleCategoryChange}
                                                                errorMessage={categoryError}
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <Input
                                                                    id="price"
                                                                    type="number"
                                                                    label="Price *"
                                                                    value={price}
                                                                    onChange={handlePriceChange}
                                                                    disabled={hasVariants}
                                                                    placeholder=""
                                                                    errorMessage={priceError}
                                                                />
                                                            </div>
                                                            <div className="col-md-6">
                                                                <QuantityInput
                                                                    id="quantity"
                                                                    label="Quantity"
                                                                    type="number"
                                                                    min="1"
                                                                    value={quantity}
                                                                    onChange={handleQuantityChange}
                                                                    disabled={hasVariants}
                                                                    placeholder=""
                                                                    errorMessage={quantityError}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row mt-4 mb-1">
                                                            <div className="col-md-4 pr-0">
                                                                <RadioButtonRow
                                                                    label="No Shipping Required"
                                                                    name="shipping_type"
                                                                    id="inlineRadiow2"
                                                                    value="no-shipping"
                                                                    disabled={false}
                                                                    checked={shipping === "no-shipping"}
                                                                    onChange={() => handleRadioChange("no-shipping")}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 pr-0">
                                                                <RadioButtonRow
                                                                    label="Free Shipping"
                                                                    name="shipping_type"
                                                                    id="inlineRadio3"
                                                                    value="free-shipping"
                                                                    disabled={false}
                                                                    checked={shipping === "free-shipping"}
                                                                    onChange={() => handleRadioChange("free-shipping")}
                                                                />
                                                            </div>

                                                            <div className="col-md-4 pr-0">
                                                                <RadioButtonRow
                                                                    label="Paid Shipping"
                                                                    name="shipping_type"
                                                                    id="inlineRadio3s"
                                                                    value="paid-shipping"
                                                                    disabled={false}
                                                                    checked={shipping === "paid-shipping"}
                                                                    onChange={() => handleRadioChange("paid-shipping")}
                                                                />
                                                            </div>
                                                        </div>

                                                        {shipping === 'paid-shipping' && (
                                                            <div className="mt-2">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <p className="text-danger">In the video checkout, currently there is no support for products with paid shipping.</p>
                                                                        <div className="mb-3">
                                                                            <Input
                                                                                id="weight"
                                                                                type="number"
                                                                                label="Weight (In Grams) *"
                                                                                value={weight}
                                                                                onChange={handleWeightChange}
                                                                                disabled={false}
                                                                                placeholder=""
                                                                                errorMessage={weightError}
                                                                                min="1"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mb-3">
                                                                    <div className="col-md-12"><h5 className="text-black fw-semibold">Dimensions (L X W X H)</h5></div>
                                                                    <div className="col-md-4 fs-3">
                                                                        <Input
                                                                            id="length"
                                                                            type="number"
                                                                            label="Length (In Centimeter) *"
                                                                            value={lenght}
                                                                            onChange={handleLenghtChange}
                                                                            disabled={false}
                                                                            placeholder=""
                                                                            errorMessage={lenghtError}
                                                                            min="1"
                                                                        />
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <Input
                                                                            id="width"
                                                                            type="number"
                                                                            label="Width (In Centimeter) *"
                                                                            value={width}
                                                                            onChange={handleWidthChange}
                                                                            disabled={false}
                                                                            placeholder=""
                                                                            errorMessage={widthtError}
                                                                            min="1"
                                                                        />
                                                                    </div>

                                                                    <div className="col-md-4">
                                                                        <Input
                                                                            id="height"
                                                                            type="number"
                                                                            label="Height (In Centimeter) *"
                                                                            value={height}
                                                                            onChange={handleHeightChange}
                                                                            disabled={false}
                                                                            placeholder=""
                                                                            errorMessage={heightError}
                                                                            min="1"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        {/* <div className="bg-gray-smoke py-4 px-4 br-1"> */}
                                                            {/* <p className="text-black fs-3 fw-semibold mb-1">Product Images</p> */}
                                                            <ImageUploader
                                                                labelText="Product Images *"
                                                                ref={ImageUploaderRef}
                                                                setErrorMessage={setErrorMessage}
                                                                fieldDesc="Add Image"
                                                                allowedImageTypes={allowedImageTypes}
                                                                returnImagesUrls={handleMultipleImages}
                                                                minLimit={minLimit}
                                                                productMultiImagesError={productMultiImagesError}
                                                                setProductMultiImagesError={setProductMultiImagesError}
                                                            />
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-500">
                                                <div className="row mt-3">
                                                    <div className="col-md-12 product-variants-container">
                                                        <div className="px-3 br-1 border border-light">
                                                            <ProductVariants ref={variantsRef} setHasVariant={setHasVariant} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                                {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                    <Button
                                        type="button"
                                        onClick={handleSubmit}
                                        buttonText="Next"
                                        fullButton={false}
                                        loading={addProductLoader}
                                        disabled={(isButtonDisabled || addProductLoader)}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
        {/* sticky footer */}
        <div className="sticky-footer ">
          <div className="footer-container">
            <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                {
                    addProductVisible ?
                        (
                            <>
                                <Button
                                    className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                                    type="button"
                                    buttonText={"Back"}
                                    onClick={() => setAddProductVisible(false)}
                                />
                                <Button
                                    className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                                    type="button"
                                    onClick={handleSubmit}
                                    buttonText="Next"
                                    loading={addProductLoader}
                                    disabled={(isButtonDisabled || addProductLoader)}
                                />
                            </>
                        ) : (

                            <>
                                <Button
                                    className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                                    type="button"
                                    buttonText={"Back"}
                                    onClick={() => { backToMainVideoStep() }}
                                />
                                <Button
                                    className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                                    type="button"
                                    onClick={handleProductTypeNext}
                                    buttonText="Next"
                                    loading={false}
                                    disabled={!selectedProductType}
                                />
                            </>
                        )
                }
            </div>
          </div>
        </div>
      {/* sticky footer */}
        </div>
    );
}
export default AddProduct;