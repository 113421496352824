import React from "react";
import Modal from "react-modal";
import { isMobile } from "../helpers/helper";
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

const ModalContainer = ({ children, isOpen, closable, handleCloseModal, width, margin, title }) => {
  const isMobileSize = isMobile();
  const modalStyles = {
    content: {
      top: "100px",
      inset: "auto",
      zIndex: 1000,
      width: isMobileSize ? "90%" : width ? `${width}px` : "50%",
      margin: margin ? margin : "30px auto",
      position: "relative",
      borderRadius: "10px", // Adding border-radius
      background: "#FFF", // Adding background color
      boxShadow: "0px 10px 30px 0px rgba(0, 0, 0, 0.20)", // Adding box shadow
      maxHeight: '92vh',
     
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "rgba(0, 0, 0, 0.40)",
    },
  };


  return (
    <>
      {isMobileSize
        ? <BottomSheet style={{ position: 'absolute', zIndex: 100 }} open={isOpen}>
          <button
            type="button"
            onClick={handleCloseModal}
            className="btn-close mb-4"
            data-bs-dismiss="modal"
            aria-label="Close"
            style={{
              position: "absolute",
              top: "11px",
              right: "8px",
              color: "#00000",
              zIndex: 99999,
            }}
          ></button>
          <div style={{
            padding: "0px 16px 16px 16px"
          }}>
            {children}
          </div>
        </BottomSheet> :
        <Modal isOpen={isOpen} style={modalStyles} closable={closable}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className={`modal-header ${!title ? "d-flex justify-content-end align-items-center" : ""} `}>
                <div style={title ? { display: "contents" } : { display: "flex", justifyContent: "end" }}>
                  {title ? <h5 className="card-title fw-semibold mb-0">{title}</h5> : ""}
                  <button
                    type="button"
                    onClick={handleCloseModal}
                    className="btn-close mb-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={title ? {} : {
                      position: "absolute",
                      top: "1px",
                      color: "#00000",
                      zIndex: 99999,
                      fontSize: 18
                    }}
                  ></button>
                </div>
              </div>
              <div className="modal-body">{children}</div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default ModalContainer;

