import React, { useEffect, useState } from "react";
import "react-data-table-component-extensions/dist/index";
import Table from "../../Table/Table";
import AddDataPrompt from "../../molecules/DataPrompt/AddingDataPrompt";

const OrderList = () => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsperPage, setrRowsperPage] = useState(10);
    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        fetchVideosData();
      }, [currentPage, rowsperPage, searchValue]);

    const fetchVideosData = () => {
        var token = localStorage.getItem("authSession");
        token = JSON.parse(token);
        setLoader(true);

        fetch(process.env.REACT_APP_API_URL + "/donations", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token.accessToken,
            },
            body: JSON.stringify({
                id: token.idToken,
                currentPage: currentPage,
                rowsPage: rowsperPage,
                search: searchValue,
            }),
        })
            .then((response) => response.json())
            .then((e) => {
                setData(e.data);
                setTotalRows(e.total);
                setCurrentPage(e.currentPage);
            })
            .catch((error) => console.error("Error fetching data:", error))
            .finally(() => setLoader(false));
    };

    const columns = [
        {
            name: "Created At",
            selector: (row) => row["created_at"],
            sortable: true,
            width: "130px",
        },
        {
            name: "Amount",
            selector: (row) => `$${row["amount"]}`,
            sortable: true,
            width: "130px",
        },
        {
            name: "C. Name",
            selector: (row) => row["customer_name"],
            sortable: true,
            width: "150px",
        },
        {
            name: "C. Email",
            selector: (row) => row["customer_email"],
            sortable: true,
            width: "230px",
        },
        {
            name: "C. Phone",
            selector: (row) => row["customer_phone"],
            sortable: true,
            width: "130px",
        },
        {
            name: "Payment Method",
            selector: (row) => row["payment_method"],
            sortable: true,
            width: "150px",
        },
        {
            name: "Payment Status",
            selector: (row) => row["payment_status"],
            sortable: true,
            width: "230px",
        }
    ]

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setCurrentPage(page);
        setrRowsperPage(newPerPage);
    };

    const handleSearchChange = (event) => {
        setSearchValue(event.target.value);
        setCurrentPage(1);
        setLoader(false)
    };


    // if (!loader && data && !data.length && !searchValue) {

    //     // if (addprompt === true) {
    //       return (
    //         <div className="data-promptWrapper">
    //           <AddDataPrompt 
    //           heading="Create Donation Videos" 
    //           title="Create donation videos to collect donations." 
    //           link="videos/add-nav-video" 
    //           btntxt="Create Donation Video"/>
    //         </div>
    //       );
    //     // }

    //   }

    return (
        <div className="App video-table vs-table">
            {/* <div className="row">
                <h3 className="fw-bolder">Donations</h3>
            </div> */}
            <div className="d-flex justify-content-between align-items-center mb-3 mt-3 action-row">
                <form className="position-relative action-search">
                    <input
                        type="text"
                        className="form-control search-chat py-2 ps-5 d-lg-inline"
                        value={searchValue}
                        placeholder="Search"
                        onChange={handleSearchChange}
                    />
                    <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
                </form>
            </div>
            <div>
                <Table
                    loading={loader}
                    columns={columns}
                    data={data}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    handlePerRowsChange={handlePerRowsChange}
                    handlePageChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default OrderList;