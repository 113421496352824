import React from "react";

import styles from "./Toolbar.module.scss";
import classNames from "classnames";

function Toolbar({children, className }) {
  return (
    <div className={classNames([styles.toolbar, className])}>
      <h2></h2>
      <div className={styles.toolbarGroups}>{children}</div>
    </div>
  );
}

export default Toolbar;
