import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import useAuthState from "../hooks/useAuthState";
import { useHistory } from "react-router-dom";
import AuthCustom from "../helpers/AuthCustom";

function ProtectedRoute({
  path,
  exact,
  bootIntercom,
  component: Component
}) {
  const authCustom = new AuthCustom();
  const { isLoggedIn: defaultIsLoggedIn, logout,user } = useAuthState();
  const [isLoggedIn, setIsLoggedIn] = useState(defaultIsLoggedIn);
  // const {
  //   hasCompletedOnboarding,
  //   // getHasCompletedTutorial
  // } = useTutorialsState();
  const history = useHistory();

  useEffect(() => {
    setIsLoggedIn(defaultIsLoggedIn)
  }, [defaultIsLoggedIn])

  useEffect(() => {
    const loadAuthSession = async () => {

      try {
        const savedAuthSession = window.localStorage.getItem("authSession");

        if (!savedAuthSession) return

        const { idToken, accessToken } = JSON.parse(savedAuthSession);
        // const user = await authCustom.getUserInfo(accessToken, idToken);

        if (!user?.sub) {

          logout();
          return isLoggedIn(false);
        }

        setIsLoggedIn(true)

      } catch (err) {
        setIsLoggedIn(false)
        console.log("err: ", err)
      }
    };

    loadAuthSession();
  }, [path])

  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => {

        bootIntercom();

    
        window.Intercom("update");

        // const hasCompletedProfileStep = getHasCompletedTutorial(
        //   "Brand Dashboard Personal Info"
        // );


        if (isLoggedIn) {

          return <Component {...props} />;
        } else if (!isLoggedIn) {

          return history.push("/login");
        } else {
          return history.push("/register");
        }
      }}
    />
  );
}

export default ProtectedRoute;
