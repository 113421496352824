import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import Auth from "./Auth";
import RoutesApp from "./RoutesApp";
import "../../assets/dist/css/style.css"
import "../../assets/dist/libs/owl.carousel/dist/assets/owl.carousel.min.css"
import "react-datetime/css/react-datetime.css";


import { UserSubscribedDataProvider } from '../context/UserSubscribedDataContext';


function App() {
  return (
    <Router>
      <Auth>
          <UserSubscribedDataProvider>
            <RoutesApp />            
          </UserSubscribedDataProvider>
      </Auth>
    </Router>
  );
}

export default App;
