import React, { useState, useEffect } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { useHistory } from "react-router-dom";
import * as helper from "../../../helpers/helper";
import CheckOnBoardingSteps from "../Component/CheckOnBoardingSteps";
import validateField from "../../../helpers/AllValidtions";
import StreamVideoFormFields from '../../../Pages/Videos/StreamVideoFormFields'
import Input from "./../../../Form/Input"
import Textarea from "./../../../Form/Textarea"
import Select from "./../../../Form/Select"
import Tabs from "./../../../Form/Tabs"
import DateTimePicker from "./../../../Form/DateTimePicker"
import ScreenLoading from '../../Loadings/ScreenLoading';
import StreamVideoFacebookFormFields from "../../Videos/StreamVideoFacebookFormFields";
import Button from "../../../Form/Button";
import RecordVideoFormFields from "../../Videos/RecordVideoFormFields";
import UploadVideoFields from "../../Videos/UploadVideoFields";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../Form/FormToast";
import SuccessCheckout from "../Checkout/SuccessCheckout";

export const CheckSubmitDisable = (
  streamType,
  videoName,
  streamSourceType,
  streamUrl,
  streamStartTime,
  streamEndTime,
  allStreamUploadError,
  facebookCode,
  allUploadError,
  wowzaId
) => {

  var disable = true;
  if (streamType === "live-stream-from-external-source") {
    disable = (!videoName || !videoName.trim() || !streamSourceType || !streamUrl || !streamStartTime || !streamEndTime || allStreamUploadError);
  } else if (streamType === "facebook-integration") {
    disable = !videoName || !videoName.trim() || !facebookCode || allUploadError;
  } else {
    disable = (!videoName || !videoName.trim() || !wowzaId || !wowzaId.trim() || allUploadError);
  }

  return disable
}

export const PURPOSE_OF_VIDEOS = [
  { label: "Select purpose of video", value: "" },
  { label: "To sell your product", value: "To sell your product" },
  { label: "To generate lead", value: "To generate lead" },
  { label: "To collect the donation", value: "To collect the donation" },
];

export const VideoSteps = ({ step, editMode, onBoarding, purpose }) => {

  var items = [];

  if (onBoarding) {
    if (purpose === "To sell your product") {
      items = ["Select Purpose", "Add Video", "Add Product", "Publish"]
    }

    if (purpose === "To collect the donation") {
      items = []
    }

    if (purpose === "To generate lead") {
      items = ["Select Purpose", "Add Video", "Lead Form"]
    }
  }else {
    items = ["Add Video", "Select Purpose", "Publish"]

    if (purpose === "To sell your product") {
      items = ["Add Video", "Select Purpose", "Add Product", "Publish"]
    }
    if (purpose === "To generate lead") {
      items = ["Add Video",  "Select Purpose", "Lead Form", "Publish"]
    }
  }

  if (items.length === 0) return null;

  return (
    <div className="vs-pills-container">
      {
        items.map((e, eI) => {
          const label = editMode === true ? e.replace(/Add/g, e.includes("Add") ? "Edit" : "") : e;

          return (
            <div key={`pill-${eI}`} className={`vs-pill ${step > eI ? "vs-pill-active" : ""}`}>
              <div className="vs-pill-radio">
                <div className="vs-pill-radio-dot"></div>
              </div>
              <span>{label}</span>
            </div>
          )
        })
      }
    </div>
  )
}

function AddVideo(...props) {
  const isMobileSize = helper.isMobile();
  const history = useHistory();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
  const [videoName, setVideoName] = useState('');
  const [videoDesc, setVideoDesc] = useState('');
  const [videoNameError, setVideoNameError] = useState('');
  const [alluploadError, setAlluploadError] = useState(false);
  const [allstreamuploadError, setAllstreamuploadError] = useState(false);
  const [wowzaId, setWowzaId] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [videoThumbnailURL, setVideoThumbnailURL] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [userSubscription, setUserSubscription] = useState();
  const [streamType, setStreamType] = useState("upload-video");
  const [streamSourceType, setStreamSourceType] = useState("Live stream from URL");
  const [streamplatform, setStreamplatform] = useState();
  const [streamname, setStreamname] = useState();
  const [streamurl, setStreamurl] = useState();
  const [streamkey, setStreamkey] = useState();
  const [streamstarttime, setStreamstarttime] = useState();
  const [streamendtime, setStreamendtime] = useState();
  const [maxVideoTimeAllowedInSeconds, setMaxVideoTimeAllowedInSeconds] = useState();
  const [facebookCode, setFacebookCode] = useState();
  const [facebookUrl, setFacebookUrl] = useState();
  const [leadForm, setLeadForm] = useState();
  const [leadLoader, setLeadLoader] = useState(false);
  const [leaddata, setLeaddata] = useState();
  const [customUrl, setCustomUrl] = useState('');
  const [customUrlError, setCustomUrlError] = useState('');
  const [draftVideoId, setDraftVideoId] = useState();

  const [loader, setloader] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [userplanType, setUserplanType] = useState();



  useEffect(() => {
    userProfileType();
  }, []);

  const userProfileType = async () => {
    try {
      var token = localStorage.getItem("authSession");
      token = JSON.parse(token);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/user-subscribe-type",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.accessToken,
          },
          body: JSON.stringify({ user_id: token.idToken }),
        }
      );

      const data = await response.json();

      // console.log(data.data[0].plan_type,"bilalllll")
      setUserplanType(data.data[0].plan_type)

      // return data.code === "200";
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };








  useEffect(() => {
    setMaxVideoTimeAllowedInSeconds(helper.allowedVideoTimeSeconds(userSubscription));
  }, [userSubscription]);

  useEffect(() => {
    fetchLeadData()
  }, []);


  useEffect(() => {

    const backStateData = history?.location?.state;
    const addVideoData = backStateData?.addVideoData;
    const addFacebookData = backStateData?.addFacebookData;
    const addStreamData = backStateData?.addStreamData;

    if (backStateData) {
      setVideoName(addVideoData?.videoName);
      setVideoDesc(addVideoData?.videoDesc);
      setVideoThumbnailURL(addVideoData?.videoThumbnailURL);
      // setVideoType(addVideoData?.videoType);
      setWowzaId(addVideoData?.wowzaId);
      setDraftVideoId(addVideoData?.videoId);

      setStreamType(addVideoData?.streamType);

      setFacebookCode(addFacebookData?.facebookCode);
      setFacebookUrl(addFacebookData?.facebookUrl);

      setStreamSourceType(addStreamData?.streamsourcetype);
      setStreamkey(addStreamData?.streamkey);
      setStreamname(addStreamData?.streamname);
      setStreamplatform(addStreamData?.streamplatform);

      setStreamstarttime(addStreamData?.streamstarttime);
      setStreamendtime(addStreamData?.streamendtime);

      setStreamurl(addStreamData?.streamurl);


    }

    // setMaxVideoTimeAllowedInSeconds(
    //   helper.allowedVideoTimeSeconds(userSubscription)
    // );
  }, [history?.location?.state]);

  const fetchLeadData = () => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/all-lead-form-list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "userId": token.idToken }),
    })
      .then((response) => response.json())
      .then((data) => {


        var userleadform = data.data;
        const userleadformvalue = userleadform.map(item => ({ label: item.title, value: item.id.toString() }));
        // Add the default option
        userleadformvalue.unshift({ label: "Select form", value: "" });


        setLeaddata(userleadformvalue)
        setLeadLoader(true)

      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  };

  const handleNameChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setVideoName(value);
    validateField(value, rule, setVideoNameError);

  };


  const handleUrlChange = (e) => {
    var value = e.target.value;
    var rule = "url";
    setCustomUrl(value);


    validateField(value, rule, setCustomUrlError);

  };






  const handleLeadChange = (e) => {
    var value = e.target.value;
    var rule = "required";

    if (value == '') {
      setLeadForm(value);
    } else {
      setLeadForm(value);


    }

  };

  const producText = helper.showUserTypeBaseMessage({ NonProfit: "Donation Campaign", default: "Product" });


  const validateForm = () => {
    if (streamType === "live-stream-from-external-source") {
      const selectedStreamTime = helper.getDateDifferenceInMinutes(streamstarttime, streamendtime);

      if (selectedStreamTime > userSubscription?.addon_live_stream) {
        setErrorMessage("You do not have enough minutes left to schedule this stream. Please adjust your scheduling or consider upgrading your plan.")
        return false;
      }
    }

    return true;
  }





  function videoToAddProductPage() {
    if (!validateForm()) {
      return;
    }


    setloader(true)

    setErrorMessage('');
    history.location.state.addVideoData.videoName = videoName;
    history.location.state.addVideoData.videoDesc = videoDesc;
    history.location.state.addVideoData.streamType = streamType;
    history.location.state.addVideoData.wowzaId = wowzaId;
    history.location.state.addVideoData.leadForm = leadForm;
    history.location.state.addVideoData.videoThumbnailURL = videoThumbnailURL;
    // const addVideoData = {
    //   videoName: videoName,
    //   videoDesc: videoDesc,
    //   videoType: videoType,
    //   streamType: streamType,
    //   wowzaId: wowzaId,
    //   leadForm:leadForm,
    //   videoThumbnailURL: videoThumbnailURL
    // }
    const addStreamData = {
      streamsourcetype: streamSourceType,
      streamplatform: streamplatform,
      streamname: streamname,
      streamurl: streamurl,
      streamkey: streamkey,
      streamstarttime: streamstarttime,
      streamendtime: streamendtime,
      utc_offset: new Date().getTimezoneOffset()
    }

    const addFacebookData = {
      facebookCode: facebookCode,
      facebookUrl: facebookUrl,
    }

    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    const draftData = {
      userdata: { addVideoData: history.location.state.addVideoData, addStreamData: addStreamData, addFacebookData: addFacebookData },
      id: token.idToken,
      step: 1,
      draftType: 'onboarding'
    };

    new Promise((resolve, reject) => {

      fetch(process.env.REACT_APP_API_URL + '/user-onboarding', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token.accessToken
        },
        body: JSON.stringify(draftData)
      }).then(() => {


      });
    }).then(() => {


    });



    if (history.location.state.addVideoData.videoType == 'To generate lead') {



      history.push({
        pathname: '/add-video-lead',
        state: {
          addVideoData: history.location.state.addVideoData,
          addStreamData: addStreamData,
          addFacebookData: addFacebookData,
          selectedProducts: history.location.state?.selectedProducts,
          showSuccess: 'Your first video has been added successfully. The next step is to add a ' + producText + ' to your video'
        },
      });

    } else if (history.location.state.addVideoData.videoType == 'To collect the donation') {



      const pushStateData = {
        addVideoData: history.location.state.addVideoData,
        addStreamData: addStreamData,
        addFacebookData: addFacebookData,
        selectedProducts: history.location.state?.selectedProducts,
        selectedProducts: []
      }



      moveToSuccessCheckout(pushStateData)


    } else {


      history.push({
        pathname: '/add-product',
        state: {
          addVideoData: history.location.state.addVideoData,
          addStreamData: addStreamData,
          addFacebookData: addFacebookData,
          selectedProducts: history.location.state?.selectedProducts,
          showSuccess: 'Your first video has been added successfully. The next step is to add a ' + producText + ' to your video'
        },
      });


    }


  }


  const SaveVideo = async (params) => {
    setErrorMessage(false);




    const accessToken = JSON.parse(window.localStorage.getItem("authSession")).accessToken;

    // setExternalCheckoutLoader(true);

    await fetch(process.env.REACT_APP_API_URL + '/add-video-with-product', {
      method: 'POST',
      mode: 'cors',
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify(params)
    }).then(response => {
      if (response.ok) {
        response.json().then(json => {
          if (json) {
            setSuccessModal({
              successVideoId: json.videoData.id,
              successVideoShortUrl: json.videoData.video_short_url,
              successWidgetAppId: json.widgetData.app_id,
            })

            setloader(false)

            // history.push({
            //   pathname: '/success-checkout',
            //   state: {
            //     successVideoId: json.videoData.id,
            //     successVideoShortUrl: json.videoData.video_short_url,
            //     successWidgetAppId: json.widgetData.app_id,
            //   },
            // });
            localStorage.setItem('is_onboarding_complete', true);
          }
        });
      } else {
        response.json().then(json => {
          if (json) {
            setErrorMessage(json?.message);

          } else {
            setErrorMessage('Something went wrong');

          }
        });
      }
    }).catch((error) => {
      setErrorMessage('Something went wrong');
    }).finally((error) => {
      // setExternalCheckoutLoader(false);
    });
    // });

    // }
  }


  const onStepIsPassed = (e) => {
    let pathName = '/add-product';
    const user_data = JSON.parse(e?.user_data)
    const addVideoData = user_data?.addVideoData;
    const addStreamData = user_data?.addStreamData;
    const addFacebookData = user_data?.addFacebookData;

    if (addVideoData?.videoType == "To generate lead") {
      pathName = '/add-video-lead'
    }

    if (!history?.location?.state?.addVideoData) {

      history.push({
        pathname: pathName,
        state: {
          addVideoData: addVideoData,
          addStreamData: addStreamData,
          addFacebookData: addFacebookData,
          selectedProducts: history.location.state?.selectedProducts,
        },
      });

    }
  }

  const { loading: stepsLoading } = CheckOnBoardingSteps({ currentStep: 1, onStepIsPassed });
  async function moveToSuccessCheckout(pushStateData) {
    const params = {
      addVideoData: pushStateData,
      selectedProducts: pushStateData.selectedProducts,
      publishDate: "",
      externalLink: customUrl,
      isExternalCheckout: true,
      inapp: false,
      isRequestFromOnboarding: true,
      utc_offset: new Date().getTimezoneOffset(),
      donation_amounts: [5, 10, 15, 20]
    }

    await SaveVideo(params)
  }


  const onNavBarClick = () => {
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }

  const getSubscriptionData = (e) => {
    setUserSubscription(e)
  }

  if (stepsLoading) {
    return <ScreenLoading />
  }

  var isButtonDisabled = CheckSubmitDisable(
    streamType,
    videoName,
    streamSourceType,
    streamurl,
    streamstarttime,
    streamendtime,
    allstreamuploadError,
    facebookCode,
    alluploadError,
    wowzaId
  );


  const tabs =  [
    {
      key: "upload-video",
      label: "Upload Video",
      component: (
        <UploadVideoFields
          videoThumbnailURL={videoThumbnailURL}
          setVideoThumbnailURL={setVideoThumbnailURL}
          fileUploadLoading={fileUploadLoading}
          setFileUploadLoading={setFileUploadLoading}
          maxVideoTimeAllowedInSeconds={
            maxVideoTimeAllowedInSeconds
          }
          setWowzaId={setWowzaId}
          wowzaId={wowzaId}
        />
      ),
    },
    {
      key: "record-video",
      label: "Record Video",
      component: (
        <RecordVideoFormFields
          videoThumbnailURL={videoThumbnailURL}
          setVideoThumbnailURL={setVideoThumbnailURL}
          fileUploadLoading={fileUploadLoading}
          setFileUploadLoading={setFileUploadLoading}
          setParentErrorMessage={setErrorMessage}
          maxVideoTimeAllowedInSeconds={
            maxVideoTimeAllowedInSeconds
          }
          setWowzaId={setWowzaId}
        />
      ),
    },
    {
      key: "live-stream-from-external-source",
      label: "Live Stream",
      component: (
        <StreamVideoFormFields
          streamplatform={streamplatform}
          setStreamplatform={setStreamplatform}
          streamname={streamname}
          setStreamname={setStreamname}
          streamurl={streamurl}
          setStreamurl={setStreamurl}
          streamkey={streamkey}
          setStreamkey={setStreamkey}
          setStreamstarttime={setStreamstarttime}
          setStreamendtime={setStreamendtime}
          setErrorMessage={setErrorMessage}
          streamstarttime={streamstarttime}
          streamendtime={streamendtime}
          streamSourceType={streamSourceType}
          setStreamSourceType={setStreamSourceType}
          setAllstreamuploadError={
            setAllstreamuploadError
          }
          userSubscription={userSubscription}
        />
      ),
    },
    {
      key: "facebook-integration",
      label: "Facebook Video",
      component: (
        <StreamVideoFacebookFormFields
          setFacebookUrl={setFacebookUrl}
          facebookCode={facebookCode}
          setFacebookCode={setFacebookCode}
          videoThumbnailURL={videoThumbnailURL}
          setVideoThumbnailURL={setVideoThumbnailURL}
          fileUploadLoading={fileUploadLoading}
          setFileUploadLoading={setFileUploadLoading}
        />
      ),
    },
  ];

  const filteredTabs = userplanType === "free" 
  ? tabs.filter(tab => tab.key !== "live-stream-from-external-source")
  : tabs;

  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">

      {errorMessage && (
        <FormToast
          type={FORM_TOAST_TYPES.DANGER}
          message={(errorMessage) ? errorMessage : ":  Please fill the required fields."}
        />
      )}

      {showSuccessModal && (<SuccessCheckout
        {...showSuccessModal}
        heading="You publish your video!"
        subHeading="Your video is now ready"
      />)}

      <Sidebar onNavBarClick={onNavBarClick} />
      <Page
        title="Add Video"
        onNavBarClick={onNavBarClick}
        formWidth={true}
        getSubscriptionData={getSubscriptionData}>
        <div className="row pb-50">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header vs-card-header">
                <h5 className="fw-bolder">Create Your First Shoppable Video</h5>
                <VideoSteps onBoarding step="2" purpose={history.location.state.addVideoData.videoType} />
              </div>
              <div className="card-body">
                <div className="w-500">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <Input
                          id="title"
                          type="text"
                          label="Video Title *"
                          value={videoName}
                          onChange={handleNameChange}
                          disabled={false}
                          placeholder=""
                          errorMessage={videoNameError}
                        />
                      </div>
                      <div className="mb-3">
                        {/* <Textarea
                          id="description"
                          label="Description"
                          value={videoDesc}
                          onChange={(e) => setVideoDesc(e.target.value)}
                          disabled={false}
                          placeholder=""
                          errorMessage=""
                        /> */}
                     <label className="form-label">Video Description</label>
                     <Editor
                          tinymceScriptSrc='/tinymce/tinymce.min.js'
                          apiKey='ivcylt2lphkntvad6xrzptldut427yg3fhhsl2iq8dfgtkq8'
                          init={{
                            promotion: false,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            toolbar: 'undo redo | bold italic underline strikethrough | link | numlist bullist | removeformat',
                            menubar: 'insert format table',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                              { value: 'First.Name', title: 'First Name' },
                              { value: 'Email', title: 'Email' },
                            ],
                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                            branding: false
                          }}
                          value={videoDesc}
                          onEditorChange={(newValue, editor) => setVideoDesc(newValue)}
                        />
                      </div>

                      {(history.location.state.addVideoData.videoType === 'To collect the donation') &&
                        <div className="mb-3">
                          <Input
                            id="custom_link"
                            label="Donation Link"
                            type="text"
                            value={customUrl}
                            onChange={handleUrlChange}
                            placeholder={"https://www.link.com/"}
                            disabled={false}
                            errorMessage={customUrlError}
                          />
                        </div>}
                      <div className="video-upload-type-container">
                        <Tabs
                          selectedTab={streamType}
                          tabs={filteredTabs}
                          onChange={(selectedTab) => {
                            setStreamType(selectedTab.key)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                  <Button
                    type="button"
                    onClick={videoToAddProductPage}
                    buttonText={(history.location.state.addVideoData.videoType === 'To collect the donation') ? "Publish" : "Next"}
                    fullButton={false}
                    loading={loader}
                    disabled={isButtonDisabled}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Page>
      {/* sticky footer */}
        <div className="sticky-footer ">
          <div className="footer-container">
            <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
              <Button
                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                type="button"
                buttonText="Back"
                onClick={() => history.push("/dashboard")}
              />
              <Button
                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                type="button"
                onClick={videoToAddProductPage}
                buttonText={(history.location.state.addVideoData.videoType === 'To collect the donation') ? "Publish" : "Next"}
                fullButton={false}
                loading={loader}
                disabled={isButtonDisabled}
              />
            </div>
          </div>
        </div>
      {/* sticky footer */}
    </div>
  );
}

export default AddVideo;