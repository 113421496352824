export const ORDER_QUERY_LIMIT = 20;
export const TABLE_COLUMNS = [
  {
    key: "id",
    accessor: "id",
    Header: "Order #",
    width: 50,
    disableSortBy: false,
    resizable: true
  },
  {
    key: "name",
    accessor: "name",
    Header: "Product Name",
    disableSortBy: false,
    resizable: true
  },
  {
    key: "variant",
    accessor: "variant",
    Header: "Variant",
    disableSortBy: true,
    resizable: true
  },
  {
    key: "amount",
    accessor: "amount",
    Header: "Price",
    disableSortBy: false,
    resizable: true
  },
  {
    key: "createdAt",
    accessor: "createdAt",
    Header: "Date",
    disableSortBy: false,
    resizable: true
  },
  {
    key: "status",
    accessor: "status",
    Header: "Status",
    minWidth: 115,
    dataKey: "status",
    disableSortBy: false,
    resizable: false
  },
  {
    Header: () => null, // No header
    id: "expander"
  }
];
