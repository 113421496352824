import { useEffect } from "react";

export const TYPES = {
  DEFAULT: "default",
  WARNING: "warning",
  SUCCESS: "success",
  DANGER: "danger",
};

const ICON_CLASSES = {
  [TYPES.DEFAULT]: "ti ti-info-circle",
  [TYPES.WARNING]: "ti ti-alert-triangle",
  [TYPES.SUCCESS]: "ti ti-check",
  [TYPES.DANGER]: "ti ti-x",
};

const TOAST_TIMEOUT = 2000;

const FormToast = ({ type, message, toastTime=2000, children }) => {
console.log('type, message, toastTime=500000, children---->>>',type, message, toastTime=500000, children);
// document.querySelector(".vs-form-toast").style.opacity=1
if(document.querySelector(".vs-form-toast")){
  document.querySelector(".vs-form-toast").style.opacity=1
}
  // useEffect(() => {
    setTimeout(() => {
      const toastElement = document.querySelector(".vs-form-toast");
      if (toastElement) {
        toastElement.style.opacity = 0;
       
      }
    }, TOAST_TIMEOUT);

  //   return () => clearTimeout(timeoutId);
  // }, []);

  const onClose = () => {
    const toastElement = document.querySelector(".vs-form-toast");
    toastElement.style.opacity = 0;
  }

  return (
    <div className={`vs-form-toast vs-form-toast-${type}`}>
      <i onClick={onClose} className={ICON_CLASSES[type]}></i>
      {message?<span>{message}</span>:""}
      {children?<div className="children-toaster">{children}</div>:""}
    </div>
  );
};

export default FormToast;
