import React, { useEffect, useState } from 'react';
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import useAuthState from "../../hooks/useAuthState";
import * as helper from "../../helpers/helper";
import { useUserSubscribedDataContext } from '../../context/UserSubscribedDataContext';
import ChevronDownIcon from "./../../../assets/icons/chevron-down.svg"

const moment = require('moment');


function Page({ formWidth, title, getSubscriptionData, setSubscriptionLoading, children, onNavBarClick, flex, className, pageCustomStyles,setPackageDaysLeft, ...props }) {

  const { user, logout } = useAuthState();
  const [currentPlan, setCurrentPlan] = useState();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [userSubscribedData, setUserSubscribedData] = useState([]);
  const [videoConsumptionWarning, setVideoConsumptionWarning] = useState(false);
  const [videoConsumptionError, setVideoConsumptionError] = useState(false);
  const [consumedVideoWatchedMinutes, setConsumedVideoWatchedMinutes] = useState(0);
  const [allotedVideoWatchedMinutes, setAllotedVideoWatchedMinutes] = useState(0);
  const [showWarningOrderCommission, setShowWarningOrderCommission] = useState(false);
  // const [dropDownMenuList, setDropDownMenuList] = useState([]);

  // let defaultUserList = [{text:"Edit Profile",url:'/settings/profile'}];

  // let brandUserList = [{text:"Account Setup",url:'/settings/account-setting'},{text:"My Brand",url:'/settings/account-setting'},{text:"Help Center",url:'https://videosquirrel.app'},{text:"Contact Support",url:'https://videosquirrel.app'},{text:"Tutorial",url:'https://videosquirrel.app'}];
  // let nonProfileUserList = [{text:"Account Setup",url:'/settings/account-setting'},{text:"My Campaign",url:'/videos'},{text:"Help Center",url:'https://videosquirrel.app'},{text:"Contact Support",url:'https://videosquirrel.app'},{text:"Tutorial",url:'https://videosquirrel.app'}];
  // let influencerUserList = [{text:"Account Setup",url:'/settings/account-setting'},{text:"My Content",url:'/videos'},{text:"Help Center",url:'https://videosquirrel.app'},{text:"Contact Support",url:'https://videosquirrel.app'},{text:"Tutorial",url:'https://videosquirrel.app'}];
  // let individualUserList = [{text:"Account Setup",url:'/settings/account-setting'},{text:"My Offerings",url:'/videos'},{text:"Help Center",url:'https://videosquirrel.app'},{text:"Contact Support",url:'https://videosquirrel.app'},{text:"Tutorial",url:'https://videosquirrel.app'}];


  let defaultUserList = [{text:"Account Setup",url:'/settings/account-setting'}];
  let brandUserList = [{text:"Account Setup",url:'/settings/account-setting'}];
  let nonProfileUserList = [{text:"Account Setup",url:'/settings/account-setting'}];
  let influencerUserList = [{text:"Account Setup",url:'/settings/account-setting'}];
  let individualUserList = [{text:"Account Setup",url:'/settings/account-setting'}];



  const dropDownMenusLinks = helper.showUserTypeBaseMessage({Brand: brandUserList,NonProfit:nonProfileUserList,Influencer:influencerUserList,Individual:individualUserList, default:defaultUserList});

  // const { updateContexSubscribedUserData } = useUserSubscribedDataContext();

  const { updateContextSubscribedUserData } = useUserSubscribedDataContext();

  useEffect(() => {
    updateContextSubscribedUserData(userSubscribedData);
  }, [userSubscribedData]);



  const toggleDropdown = () => {
    setDropdownOpen(prevOpen => !prevOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const target = event.target;
      if (target.closest(".simplebar-wrapper") || target.closest(".nav-item")) return

      setDropdownOpen(false)
    };
    fetchSubscribedPackage();

    window.addEventListener("click", handleClickOutside);
  }, []);

  const fetchSubscribedPackage = () => {
    if(setSubscriptionLoading){
      setSubscriptionLoading(true);
    }
    setVideoConsumptionWarning(false);
    setVideoConsumptionError(false);
    
    fetch(process.env.REACT_APP_API_URL + '/user-new-subscribed-plan-data', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + helper.userLoggedAccessToken(),
        },
        body: JSON.stringify({}), // Replace with your POST data
    })
        .then((response) => response.json())
        .then((data) => {
          setUserSubscribedData(data?.data);
          
          if (getSubscriptionData) {
            getSubscriptionData(data?.data)
          }
          const package_consumption = data?.data?.package_consumption[0];

          const orderCommissionFailedCount = data?.data?.order_commission_failed_count;
          const percent_user_videos_watched = package_consumption?.percent_user_videos_watched;
          const addOnWatchTimeMinutes = helper.allowedAddonWatchTimeMinutes(data?.data);
          if(setPackageDaysLeft){
            setPackageDaysLeft(helper.daysRemaining(data?.data?.current_period_end))
          }
          
          if(orderCommissionFailedCount>0){
            // setShowWarningOrderCommission(true);
          }

          if(addOnWatchTimeMinutes>=1){
            setAllotedVideoWatchedMinutes(package_consumption?.watch_time+addOnWatchTimeMinutes);
          } else {
            setAllotedVideoWatchedMinutes(package_consumption?.watch_time);
          }
          
          // setConsumedVideoWatchedMinutes(package_consumption?.user_videos_watched_time_in_minutes);

          // const duration = moment.duration(package_consumption?.user_videos_watched_time_in_seconds, 'seconds');
          // const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');

          // setConsumedVideoWatchedMinutes(formattedTime);
          setConsumedVideoWatchedMinutes(package_consumption?.user_videos_watched_time_in_minutes);

          if(percent_user_videos_watched>=100){
            setVideoConsumptionError(true);
          } else if(percent_user_videos_watched>=50){
            setVideoConsumptionWarning(true);
          }

        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        }).finally(() => {
          if(setSubscriptionLoading){
            setSubscriptionLoading(false);
          }
      })

  };

  // const { data: VSUserNewSubscribedPlan } = useQuery(
  //   UserNewSubscribedPlanData
  // );

  useEffect(() => {
    if (userSubscribedData) {
      setCurrentPlan(userSubscribedData)
    }
    // if (VSUserNewSubscribedPlan?.userNewSubscribedPlanData?.[0]) {
    //   setCurrentPlan(VSUserNewSubscribedPlan?.userNewSubscribedPlanData?.[0])
    // }

  }, [userSubscribedData])

  const openIntercom = () => {
   
    window.Intercom("boot", {
      app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      user_id: user.sub,
      email: user.email,
      name: user.name
    });
    window.Intercom("show");
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="body-wrapper">
        <header className='app-header'>
          <nav className="navbar navbar-expand-lg navbar-light">
            <ul className="d-lg-none navbar-nav" onClick={onNavBarClick}>
              <li className="nav-item">
                <span className="nav-link sidebartoggler nav-icon-hover ms-n3" id="headerCollapse">
                  <i className="ti ti-menu-2"></i>
                </span>
              </li>
            </ul>
            {
              user && (
                <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                  <div className="d-flex align-items-center justify-content-between">
                    <ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
                      <li className={`nav-item dropdown  ${isDropdownOpen ? 'user-account-nav' : ''}`}>
                        <span
                          className="show"
                          id="sq-profile"
                          data-bs-toggle="dropdown"
                          aria-expanded={isDropdownOpen ? 'true' : 'false'}
                          onClick={toggleDropdown}
                        >
                          <div className="header-profile-button">
                            <div className="header-profile-button-left">
                              <img src={user?.picture} className="rounded-circle user-profile" alt="" />
                            </div>
                            <div className="header-profile-button-center">
                              <span className="text-white">{user?.name}</span>
                            </div>
                            <div className="header-profile-button-right">
                              <img src={ChevronDownIcon} alt="" />
                            </div>
                          </div>
                        </span>

                        <div
                          className={`dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up all-drop-menu-links ${isDropdownOpen ? 'show' : ''}`}
                          aria-labelledby="sq-profile"
                          data-bs-popper="static"
                        >
                          <div data-simplebar="init">
                            <div className="simplebar-wrapper" style={{ margin: 0 }}>
                              <div className="simplebar-height-auto-observer-wrapper">
                                <div className="simplebar-height-auto-observer" />
                              </div>
                              <div className="simplebar-mask">
                                <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
                                  <div
                                    className="simplebar-content-wrapper"
                                    tabIndex={0}
                                    role="region"
                                    aria-label="scrollable content"
                                    style={{ height: "auto", overflow: "hidden scroll" }}
                                  >
                                    <div className="simplebar-content" style={{ padding: 0 }}>
                                      <div className="d-flex align-items-center">
                                        <div className="mb-2">
                                          <p className="mb-1 fs-4 fw-semibold text-black">{user?.name}</p>
                                          <p className="mb-0 fs-2 text-black-light">
                                            {user?.email}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="message-body">
                                        {dropDownMenusLinks.map((dropDownMenusLink, index) => (
                                          <React.Fragment key={`link-${index}`}>
                                            {dropDownMenusLink?.url?.startsWith('/') && (<>
                                              <Link
                                                to={dropDownMenusLink?.url}
                                                className="py-2 d-flex align-items-center bg-hover-default"
                                              >
                                                <div className="w-100 d-inline-block v-middle ps-2">
                                                  <h6 className="mb-1 fs-3 fw-semibold text-black">
                                                    {dropDownMenusLink?.text}
                                                  </h6>
                                                </div>
                                              </Link>
                                            </>)}

                                            {!dropDownMenusLink?.url?.startsWith('/') && (<>
                                              <a
                                                href={dropDownMenusLink?.url}
                                                className="py-2 d-flex align-items-center bg-hover-default"
                                              >
                                                <div className="w-100 d-inline-block v-middle ps-2">
                                                  <h6 className="mb-1 fs-3 fw-semibold text-black">
                                                    {dropDownMenusLink?.text}
                                                  </h6>
                                                </div>
                                              </a>
                                            </>)}
                                          </React.Fragment>
                                        ))}
                                        <a
                                          href="#!"
                                          className="py-2 d-flex align-items-center"
                                          onClick={logout}
                                        >
                                          <div className="w-100 d-inline-block v-middle ps-2">
                                            <h6 className="mb-1 fs-3 bg-hover-primary text-danger">
                                              Log Out
                                            </h6>
                                          </div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="simplebar-placeholder"
                                style={{ width: "auto", height: 400 }}
                              />
                            </div>
                            <div
                              className="simplebar-track simplebar-horizontal"
                              style={{ visibility: "hidden" }}
                            >
                              <div
                                className="simplebar-scrollbar"
                                style={{ width: 0, display: "none" }}
                              />
                            </div>
                            <div
                              className="simplebar-track simplebar-vertical"
                              style={{ visibility: "visible" }}
                            >
                              <div
                                className="simplebar-scrollbar"
                                style={{
                                  height: 536,
                                  display: "block",
                                  transform: "translate3d(0px, 0px, 0px)"
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              )}
          </nav>
        </header>
        <div style={{ ...pageCustomStyles?.containerFluidFormWidth }} className={formWidth ? "container-fluid form-width" : "container-fluid"}>

          <div className={helper.urlSegment(0) == "dashboard" ? "container-fluid" : ""}>
            {videoConsumptionWarning == true && (
              <div className="alert alert-danger" style={{ color: '#EB0000' }} role="alert">
                You have consumed {Math.round(consumedVideoWatchedMinutes)}/{allotedVideoWatchedMinutes} monthly watch minutes. <Link to="/settings/subscription/upgrade-plan">Click here</Link> to upgrade your package or buy Addons
              </div>
            )}

            {/* {showWarningOrderCommission == true && (
              <div className="alert alert-danger" style={{ color: '#EB0000' }} role="alert">
                IN-VIDEO checkout module has been disabled due to the unsuccessful charge of commission. <Link to="/settings/subscription">Click here</Link> to pay the invoice amount.
              </div>
            )} */}

            {videoConsumptionError == true && (
              <div className="alert alert-danger" style={{ color: '#EB0000' }} role="alert">
                Your monthly watch limit has been exceeded. <Link to="/settings/subscription/upgrade-plan">Click here</Link> to upgrade your package now
              </div>
            )}
          </div>

          {children}
        </div>
      </div>
      
      <button onClick={openIntercom} className="btn btn-primary p-3 rounded-circle d-flex align-items-center justify-content-center customizer-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
        <i className="ti ti ti-message-dots fs-7" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Settings"></i>
      </button>
    </>
  );
}

export default Page;
