import React , { useEffect, useState }  from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import validator from 'validator';
import Button from "./../../Form/Button";
import { OnboardingPage } from "../../layouts/OnboardingPage";


function ResetPassword() {
    const history = useHistory();
    const [password, setPassword] = useState("");
    const [confirmpassword, setConfirmpassword] = useState("");
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);

    const [disableField, setDisableField] = useState(false);

    const resetState = () => {
      setErrors('');
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      let path = window.location.pathname;
      var confirmcode = path.split("/");
      var code = confirmcode[2];

      if (validator.isEmpty(password)) {
        setErrors('Password is required.');
        
      } else if (!validator.isLength(password, { min: 5 })) {
        setErrors('Password must be at least 8 characters long.');
        
      } else if (validator.isEmpty(confirmpassword)) {
        setErrors('Confirm Password is required.');
        
      } else if (!validator.equals(password, confirmpassword)) {
        setErrors('Passwords do not match.');
        
      }else{
        submitForm(code,password,confirmpassword);
      }

      // setTimeout(resetState, 4000);
        
    }


    const submitForm = (code,password,confirmpassword) => {
          setLoader(true);

          const fetch_prods =  new Promise((resolve, reject) => {
              fetch(process.env.REACT_APP_API_URL+'/reset-password', {
                  method: 'POST', 
                  mode: 'cors', 
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    "code": code,
                    "password": password,
                  })
                }).then(response => {
                  setLoader(false);
                  if (response.ok) {
                    response.json().then(json => {
                      let history_push_link;
                      if(json?.code=="200"){
                        setErrors('');
                        setSuccess(json?.message);
                        setDisableField(true)
                          // history_push_link = "/login";
                          // history.push("/login")
                      } else {
                        setSuccess('');
                        // setDisableField(true)
                        setErrors(json?.message);

                        // json?.message?"":"";
                        // setErrors('Passwords do not match.');
                      }

                     
                    });
                  }
                }).
                catch((error) => {
                  setLoader(false);
                  console.log('error>>>',error)
                });
          });
          return fetch_prods;    
    };

    const isButtonDisabled = !password || !confirmpassword;



    return (

        <OnboardingPage title="Reset Password">


        <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
          <div className="col-sm-7 col-md-7 col-xl-7">
            <div className="card-body">
              <div className="mb-5">
                <h2 className="fw-bolder fs-7 mb-3">Change Password</h2>
                <p className="mb-0 ">   
                Enter a new password
                {/* <b>rashid@videosquirrel.app</b>*/}
                </p>
              </div>
              {(errors)?<div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div>:""}
              {(success)?<div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div>:""}
              <form>
                 <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">Your New Password *</label>
                  <input type="password" className="form-control" id="new_pass" aria-describedby="emailHelp"  name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">Confirm Your New Password *</label>
                  <input type="password" className="form-control" id="old_pass" aria-describedby="emailHelp" value={confirmpassword} onChange={(e) => setConfirmpassword(e.target.value)}  />
                </div>
               
                {/* <button onClick={handleSubmit} disabled={isButtonDisabled || disableField} className="btn btn-primary w-100 py-8 mb-3">
                    {loader ? (
                        <div>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                        </div>
                    ) : (
                        <div><i class="ti ti-device-floppy me-1 fs-4"></i>Reset Password</div>
                    )}
                    
                </button> */}

                <div className="">

                  <Button
                    type="submit"
                    buttonText="Reset Password"
                    fullButton={true}
                    onClick={handleSubmit}
                    loading={loader}
                    disabled={isButtonDisabled || disableField}
                  />

                </div>

                <div className="">
                  <a
                    className={"btn w-100 mb-1 py-3 waves-effect waves-light btn-outline-primary"}
                    buttonText="Go Back"
                    onClick={(e) => {
                      history.push(history.push("/login"));
                    }}
                  >Back to Login</a>
                </div>

                {/* <Link to="/" className="btn btn-light-primary text-primary w-100 py-8 btn-hover-bg">Back to Login</Link> */}
                {/* <a href="./authentication-login.html" className="btn btn-light-primary text-primary w-100 py-8">Back to Login</a> */}
              </form>
            </div>
          </div>
        </div>
      </OnboardingPage>

    );
}
export default ResetPassword;