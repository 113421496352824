import React, { useEffect, useState, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { Link } from "react-router-dom";
import UploadHelper from "../../../component/helpers/upload";
import { useHistory } from "react-router-dom";
import ImageUploader from "./ImageUploader";
import {
    userLoggedAccessToken,
    showUserTypeBaseMessage,
    isMobile,
    translate,
} from "../../helpers/helper";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/product.png";
import BreadCrump from "../../Breadcrump/BreadCrump";
import ActivityLog from "../../helpers/ActivityLog";
// import translate from "../../helpers/helper";
import Select from "react-select";
import validateField from "../../helpers/AllValidtions";
import ProductVariants from "./../../../component/Product/ProductVariants";
import Input from "../../Form/Input";
import Textarea from "../../Form/Textarea";
import MultiSelect from "../../Form/MultiSelect";
import QuantityInput from "../../Form/QuantityInput";
import RadioButtonRow from "../../Form/RadioButtonRow";
import Button from "../../Form/Button";

function AddProductNav({ callFromVideo, ...props }) {
    const isMobileSize = isMobile();
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(
        isMobileSize ? "mini-sidebar" : "full"
    );
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [description, setDescription] = useState("");
    const [productImage, setProductImage] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [progress, setProgress] = useState(0.0);
    const [productImageUrl, setProductImageUrl] = useState();
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [addProductLoader, setAddProductLoader] = useState(false);
    const [addProductBtnDisable, setAddProductBtnDisable] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const allowedExt = "jpeg, png and webp";

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [productMultiImages, setProductMultiImages] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [category, setCategory] = useState("");
    const [selectedOption, setSelectedOption] = useState([]);
    const [options, setOptions] = useState([]);
    const [titleError, setTitleError] = useState("");
    const [priceError, setPriceError] = useState("");
    const [quantityError, setQuantityError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [productMultiImagesError, setProductMultiImagesError] = useState("");
    const [minLimit, setMinLimit] = useState(1);
    const [hasVariants, setHasVariant] = useState(false);
    const variantsRef = useRef();

    const [shipping, setShipping] = useState("no-shipping");

    const [weight, setWeight] = useState("");
    const [weightError, setWeightError] = useState("");

    const [lenght, setLenght] = useState("");
    const [lenghtError, setLenghtError] = useState("");

    const [width, setWidth] = useState("");
    const [widthtError, setWidthtError] = useState("");

    const [height, setHeight] = useState("");
    const [heightError, setHeightError] = useState("");

    const [size, setSize] = useState("");
    const [sizeError, setSizeError] = useState("");

    const handleLenghtChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setLenght(value);
        validateField(value, rule, setLenghtError);
    };

    const handleWidthChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWidth(value);
        validateField(value, rule, setWidthtError);
    };

    const handleHeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setHeight(value);
        validateField(value, rule, setHeightError);
    };

    const handleShippingChange = (e) => {
        if (shipping == false) {
            setShipping(true);
        } else {
            setShipping(false);
            setWeight("");
            setHeight("");
            setWidth("");
            setLenght("");
        }
    };

    const handleSizeChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setSize(value);
        validateField(value, rule, setSizeError);
    };

    const handleWeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWeight(value);
        validateField(value, rule, setWeightError);
    };

    const handleTitleChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setTitle(value);
        validateField(value, rule, setTitleError);
    };

    const handlePriceChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setPrice(value);
        validateField(value, rule, setPriceError);
    };

    const handleQuantityChange = (e) => {
        var value = e.target.value;
        setQuantity(value);
        validateField(value, "required", setQuantityError);
    };

    const handleCategoryChange = (selectedOption) => {
        var rule = "cat-required";
        var value = selectedOption;
        setSelectedOption(selectedOption);
        validateField(value, rule, setCategoryError);
    };

    const allowedImageTypes = {
        "image/jpeg": "jpg",
        "image/png": "png",
        "image/webp": "webp",
    };

    useEffect(() => {
        fetchCategoryData();

        const timer = setTimeout(() => {
            setShowSuccess(false);
            setSuccessMessage("");
        }, 4500);
        return () => clearTimeout(timer);
    }, []);

    const handleMultipleImages = (multiImagesData) => {
        // var rule = "multi-image";
        setProductMultiImages(multiImagesData);
        setProductImageUrl(multiImagesData?.length ? multiImagesData[0] : null);
        // validateField(multiImagesData, rule, setProductMultiImagesError);
    };

    const fetchCategoryData = () => {
        fetch(process.env.REACT_APP_API_URL + "/all-product-category", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + userLoggedAccessToken(),
            },
            body: JSON.stringify({}), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                // setData(data.data)
                setOptions(data.data);
                setLoader(true);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var variants = [];
        if (hasVariants) {
            if (variantsRef.current && variantsRef.current.validateAndGetVariants) {
                const [error, data] = variantsRef.current.validateAndGetVariants();
                if (error) return;

                variants = data;
            }
        }

        submitForm(
            title,
            price,
            quantity,
            description,
            productImageUrl,
            weight,
            size,
            category,
            variants
        );

        // if (title != '' && price != '' && productImageUrl != '') {
        //     submitForm(title, price, description, productImageUrl, weight, size, category, variants);
        // } else {
        //     setShowErrors('Please check Title,Category,Image and Price fields');
        // }
    };

    function handleProgressEvent({ loaded, total }) {
        setProgress(loaded / total);
    }

    const onNavBarClick = () => {
        setSidebarType((prevSidebarType) =>
            prevSidebarType === "full" ? "mini-sidebar" : "full"
        );
    };

    const submitForm = (
        title,
        price,
        quantity,
        description,
        productImageValue,
        weight,
        size,
        category,
        variants
    ) => {
        const categoryValues = selectedOption
            .map((category) => category.value)
            .join(",");
        setSuccessMessage(null);
        setErrorMessage(null);
        var token = localStorage.getItem("authSession");
        token = JSON.parse(token);
        setAddProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + "/user-insert-product", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userLoggedAccessToken(),
                },
                body: JSON.stringify({
                    title: title,
                    price: price,
                    quantity: quantity,
                    product_image: productImageValue,
                    description: description,
                    weight: weight,
                    category: categoryValues,
                    productMultiImages: productMultiImages,
                    variants: hasVariants ? variants : [],
                    shipping: shipping,
                    lenght: lenght,
                    width: width,
                    height: height,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        response.json().then((json) => {
                            let history_push_link;
                            if (json.code == 200) {
                                const userLog = new ActivityLog();
                                const admin_login_id = localStorage.getItem("admin_login_id");
                                var user_id = admin_login_id ? admin_login_id : token.idToken;
                                var is_admin = admin_login_id ? 1 : 0;
                                var event_id = 8;
                                var account_id = token.idToken;
                                const userLogresult = userLog.createaAtivitylog({
                                    user_id,
                                    is_admin,
                                    event_id,
                                    account_id,
                                });
                                setSuccessMessage(json?.message);
                                history_push_link = "/products";
                                history.push({
                                    pathname: history_push_link,
                                });
                            } else {
                                setErrorMessage(json.message);
                            }
                        });
                    }
                })
                .catch((error) => {
                    setErrorMessage(
                        error?.message ? error?.message : "Something went wrong!"
                    );
                })
                .finally((error) => {
                    setAddProductLoader(false);
                });
        });
        return fetch_prods;
    };

    var isButtonDisabled =
        typeof title !== "string" ||
        !title?.trim() ||
        selectedOption.length < 1 ||
        typeof productImageUrl !== "string" ||
        !productImageUrl?.trim() ||
        titleError ||
        categoryError ||
        productMultiImagesError;

    if (!hasVariants) {
        if (!price || !quantity || priceError || quantityError) {
            isButtonDisabled = true;
        }
    }

    if (shipping == "paid-shipping") {
        if (
            !weight?.trim() ||
            weightError ||
            !lenght?.trim() ||
            lenghtError ||
            !width?.trim() ||
            widthtError ||
            !height?.trim() ||
            heightError
        ) {
            isButtonDisabled = true;
        }
    }

    // const addText = showUserTypeBaseMessage({NonProfit:"Add Donation Campaign",default:"Add Product"});
    // const textPlural = showUserTypeBaseMessage({NonProfit:"Donation Campaigns",default:"Products"});
    // const textSignular = showUserTypeBaseMessage({NonProfit:"Donation Campaign",default:"Product"});

    // const textPlural = showUserTypeBaseMessage({NonProfit:"Donation Campaigns",default:"Products"});
    // const textSignular = showUserTypeBaseMessage({NonProfit:"Donation Campaign",default:"Product"});

    const addText = showUserTypeBaseMessage({
        Brand: `Add Product`,
        default: `Add Product`,
        NonProfit: `Add Campaign`,
        Influencer: `Add Offering`,
        Individual: `Add Offering`,
    });

    const textPlural = showUserTypeBaseMessage({
        Brand: `Products`,
        default: `Products`,
        NonProfit: `Donation Campaigns`,
        Influencer: `Offerings`,
        Individual: `Offerings`,
    });

    const textSignular = showUserTypeBaseMessage({
        Brand: `Product`,
        default: `Product`,
        NonProfit: `Campaign`,
        Influencer: `Offering`,
        Individual: `Offering`,
    });

    const productDescNote = showUserTypeBaseMessage({
        Brand: `Describe your product's features and benefits in detail. Do specify product variations (size, color, style). Help your customers choose wisely!`,
        default: ``,
        NonProfit: `Describe your fundraising campaign cause in detail. Do specify the mission of your nonprofit organization and campaign details (education, health, gender equality, climate change, etc.). Inspire your donors to donate generously!`,
        Influencer: `Describe features and benefits of your content in detail. Do specify about your content type in detail (content type/product/service, delivery time, booking details etc.). Help your customers choose wisely!`,
        Individual: `Describe features and benefits of your offerings in detail. Do specify about your offerings in detail (offering type, delivery time, booking details etc.). Help your customers choose wisely!`,
    });

    const productDescLabel = showUserTypeBaseMessage({
        Brand: `Product Description`,
        default: `Product Description`,
        NonProfit: `Campaign Description`,
        Influencer: `Offerings Description`,
        Individual: `Offerings Description`,
    });

    // tagLine={showUserTypeBaseMessage({'Brand': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience', 'default':''})}

    // const addText = showUserTypeBaseMessage({NonProfit:"Add Donation Campaign",default:"Add Product"});

    const handleRadioChange = (value) => {


        setShipping(value);
    };

    const navigateToProducts = () => {
        return history.push({ pathname: "/products" });
    }

    const categoryMaxAllowedLimit = process.env.REACT_APP_SELECT_CATEGORY_MAX_LIMIT;


    return (
        <div
            className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
            id="main-wrapper"
            data-layout="vertical"
            data-sidebartype={sidebarType}
            data-sidebar-position="fixed"
            data-header-position="fixed"
        >
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page
                title="Add Product"
                onNavBarClick={onNavBarClick}
                formWidth={true}
            >
                <div className="row pb-50">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                {/* <div className="card-header-back" onClick={navigateToProducts}>
                                    <i className="ti ti-arrow-left"></i>
                                </div> */}
                                <h5 className="fw-bolder">Add Product</h5>
                            </div>
                            <div className="card-body">
                                <div className="w-500">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="mb-3">
                                                <Input
                                                    id="title"
                                                    type="text"
                                                    label="Product Title *"
                                                    value={title}
                                                    onChange={handleTitleChange}
                                                    disabled={false}
                                                    placeholder=""
                                                    errorMessage={titleError}
                                                />
                                            </div>
                                            <div className="mb-2">
                                            <label className="form-label">Description</label>
                                                {/* <Textarea
                                                    id="description"
                                                    label="Description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    disabled={false}
                                                    placeholder=""
                                                    errorMessage=""
                                                /> */}

                                            <Editor
                                             tinymceScriptSrc='/tinymce/tinymce.min.js'
                                            apiKey={process.env.REACT_APP_TINYMCE_KEY}
                                            init={{
                                                promotion: false,
                                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                                                toolbar: 'undo redo | bold italic underline strikethrough  | numlist bullist | removeformat',
                                                menubar: 'insert format table',
                                                tinycomments_mode: 'embedded',
                                                tinycomments_author: 'Author name',
                                                mergetags_list: [
                                                { value: 'First.Name', title: 'First Name' },
                                                { value: 'Email', title: 'Email' },
                                                ],
                                                ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                                                branding: false
                                            }}
                                            value={description}
                                            onEditorChange={(newValue, editor) => setDescription(newValue)}
                                            />
                                                                </div>
                                            <div className="mt-3 mb-3">
                                                <MultiSelect
                                                    limit={categoryMaxAllowedLimit}
                                                    id="category"
                                                    label="Category"
                                                    required={true}
                                                    value={selectedOption}
                                                    options={options}
                                                    onChange={handleCategoryChange}
                                                    errorMessage={categoryError}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="mt-2 col-md-6">
                                                    <Input
                                                        id="price"
                                                        type="number"
                                                        label="Price *"
                                                        value={price}
                                                        onChange={handlePriceChange}
                                                        disabled={hasVariants}
                                                        placeholder=""
                                                        errorMessage={priceError}
                                                    />
                                                </div>
                                                <div className="mt-2 col-md-6">
                                                    <QuantityInput
                                                        id="quantity"
                                                        label="Quantity"
                                                        type="number"
                                                        min="1"
                                                        value={quantity}
                                                        onChange={handleQuantityChange}
                                                        disabled={hasVariants}
                                                        placeholder=""
                                                        errorMessage={quantityError}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row mt-4 mb-1">
                                                <div className="col-md-4 pr-0">
                                                    <RadioButtonRow
                                                        label="No Shipping Required"
                                                        name="shipping_type"
                                                        id="inlineRadiow2"
                                                        value="no-shipping"
                                                        disabled={false}
                                                        checked={shipping === "no-shipping"}
                                                        onChange={() => handleRadioChange("no-shipping")}
                                                    />
                                                </div>
                                                <div className="col-md-4 pr-0">
                                                    <RadioButtonRow
                                                        label="Free Shipping"
                                                        name="shipping_type"
                                                        id="inlineRadio3"
                                                        value="free-shipping"
                                                        disabled={false}
                                                        checked={shipping === "free-shipping"}
                                                        onChange={() => handleRadioChange("free-shipping")}
                                                    />
                                                </div>

                                                <div className="col-md-4 pr-0">
                                                    <RadioButtonRow
                                                        label="Paid Shipping"
                                                        name="shipping_type"
                                                        id="inlineRadio3s"
                                                        value="paid-shipping"
                                                        disabled={false}
                                                        checked={shipping === "paid-shipping"}
                                                        onChange={() => handleRadioChange("paid-shipping")}
                                                    />
                                                </div>
                                            </div>

                                            {shipping === 'paid-shipping' && (
                                                <div className="mt-2">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <p className="text-danger">In the video checkout, currently there is no support for products with paid shipping.</p>
                                                            <div className="mb-3">
                                                                <Input
                                                                    id="weight"
                                                                    type="number"
                                                                    label="Weight (In Grams) *"
                                                                    value={weight}
                                                                    onChange={handleWeightChange}
                                                                    disabled={false}
                                                                    placeholder=""
                                                                    errorMessage={weightError}
                                                                    min="1"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-3">
                                                        <div className="col-md-12"><h5 className="text-black fw-semibold">Dimensions (L X W X H)</h5></div>
                                                        <div className="col-md-4 fs-3">
                                                            <Input
                                                                id="length"
                                                                type="number"
                                                                label="Length (In Centimeter) *"
                                                                value={lenght}
                                                                onChange={handleLenghtChange}
                                                                disabled={false}
                                                                placeholder=""
                                                                errorMessage={lenghtError}
                                                                min="1"
                                                            />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <Input
                                                                id="width"
                                                                type="number"
                                                                label="Width (In Centimeter) *"
                                                                value={width}
                                                                onChange={handleWidthChange}
                                                                disabled={false}
                                                                placeholder=""
                                                                errorMessage={widthtError}
                                                                min="1"
                                                            />
                                                        </div>

                                                        <div className="col-md-4">
                                                            <Input
                                                                id="height"
                                                                type="number"
                                                                label="Height (In Centimeter) *"
                                                                value={height}
                                                                onChange={handleHeightChange}
                                                                disabled={false}
                                                                placeholder=""
                                                                errorMessage={heightError}
                                                                min="1"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                        <div className="col-md-12">
                                            {/* <div className="bg-gray-smoke py-4 px-4 br-1"> */}
                                                {/* <p className="text-black fs-3 fw-semibold mb-1">Product Images</p> */}
                                                <ImageUploader
                                                    labelText="Product Images *"
                                                    setErrorMessage={setErrorMessage}
                                                    fieldDesc="Add Image"
                                                    allowedImageTypes={allowedImageTypes}
                                                    returnImagesUrls={handleMultipleImages}
                                                    minLimit={minLimit}
                                                    productMultiImagesError={productMultiImagesError}
                                                    setProductMultiImagesError={setProductMultiImagesError}
                                                />
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-md-12 product-variants-container">
                                            <div className="px-3 br-1 border border-light">
                                                <ProductVariants ref={variantsRef} setHasVariant={setHasVariant} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                        <Button
                                            type="button"
                                            onClick={handleSubmit}
                                            buttonText="Next"
                                            fullButton={false}
                                            loading={addProductLoader}
                                            disabled={(isButtonDisabled || addProductLoader)}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            {/* sticky footer */}
            <div className="sticky-footer ">
                <div className="footer-container">
                    <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                        {/* <Button
                            className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                            type="button"
                            buttonText="Back"
                            onClick={navigateToProducts}
                        /> */}
                        <Button
                            className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                            type="button"
                            onClick={handleSubmit}
                            buttonText="Submit"
                            fullButton={false}
                            loading={addProductLoader}
                            disabled={(isButtonDisabled || addProductLoader)}
                        />
                    </div>
                </div>
            </div>
            {/* sticky footer */}
        </div>
    );

    // return (
    //     <div
    //         className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
    //             }`}
    //         id="main-wrapper"
    //         data-layout="vertical"
    //         data-sidebartype={sidebarType}
    //         data-sidebar-position="fixed"
    //         data-header-position="fixed"
    //     >
    //         <Sidebar onNavBarClick={onNavBarClick} />

    //         <Page
    //             title={translate("add_product")}
    //             onNavBarClick={onNavBarClick}
    //             formWidth={true}
    //         >
    //             <BreadCrump
    //                 title={addText}
    //                 image={dashboardbreadcrumimage}
    //                 breadCrumpDetail={[
    //                     { name: "Dashboard", link: "/" },
    //                     { name: textPlural, link: "/products" },
    //                     { name: addText, link: null },
    //                 ]}
    //             />

    //             <div className="row">
    //                 <section>
    //                     <div class="card">
    //                         <div className="card-body wizard-content">
    //                             {showSuccess || successMessage ? (
    //                                 <div className="alert alert-success" role="alert">
    //                                     <strong>Success: </strong>{" "}
    //                                     {successMessage ? successMessage : ""}{" "}
    //                                 </div>
    //                             ) : (
    //                                 ""
    //                             )}
    //                             {showErrors || errorMessage ? (
    //                                 <div class="alert alert-danger" role="alert">
    //                                     <strong>Error: </strong>{" "}
    //                                     {errorMessage
    //                                         ? errorMessage
    //                                         : "Please check Title, Image and Price fields."}{" "}
    //                                 </div>
    //                             ) : (
    //                                 ""
    //                             )}
    //                             <form className="validation-wizard wizard-circle wizard clearfix">
    //                                 <div className="content clearfix">
    //                                     <div className="row">
    //                                         <div className="col-md-12">
    //                                             <div className="mb-3">
    //                                                 <label htmlFor="wfirstName2">
    //                                                     {textSignular} Title:
    //                                                     <span className="danger">*</span>
    //                                                 </label>
    //                                                 <input
    //                                                     type="text"
    //                                                     className="form-control required"
    //                                                     id="wfirstName2"
    //                                                     placeholder={`Enter ${textSignular} Title`}
    //                                                     name="title"
    //                                                     value={title}
    //                                                     onChange={handleTitleChange}
    //                                                 />
    //                                                 {titleError ? (
    //                                                     <small className="text-danger">{titleError}</small>
    //                                                 ) : (
    //                                                     ""
    //                                                 )}
    //                                                 <small>
    //                                                     {showUserTypeBaseMessage({
    //                                                         Brand: "What is your product name?",
    //                                                         default: "",
    //                                                     })}
    //                                                 </small>
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     <div className="row">
    //                                         <div class="col-12">
    //                                             <div class="mb-4">
    //                                                 <label for="exampleInputPassword1">
    //                                                     {productDescLabel}:
    //                                                 </label>
    //                                                 <textarea
    //                                                     class="form-control"
    //                                                     id=""
    //                                                     cols="20"
    //                                                     rows="3"
    //                                                     placeholder={`Enter ${productDescLabel}`}
    //                                                     name="description"
    //                                                     value={description}
    //                                                     onChange={(e) => setDescription(e.target.value)}
    //                                                 ></textarea>
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     {showUserTypeBaseMessage({
    //                                         Brand: false,
    //                                         default: true,
    //                                     }) === true && (
    //                                             <p>
    //                                                 <b>Note</b> : {productDescNote}
    //                                             </p>
    //                                         )}

    //                                     <div className="row">
    //                                         <div class="col-12">
    //                                             <div class="mb-3">
    //                                                 <label htmlFor="wfirstName2">
    //                                                     Select Category:<span className="danger">*</span>
    //                                                 </label>
    //                                                 <Select
    //                                                     isMulti
    //                                                     menuPortalTarget={document.body}
    //                                                     menuPosition={"fixed"}
    //                                                     options={options}
    //                                                     onChange={handleCategoryChange}
    //                                                     className="basic-multi-select"
    //                                                     classNamePrefix="select screen"
    //                                                 />
    //                                                 {categoryError ? (
    //                                                     <small className="text-danger">
    //                                                         {categoryError}
    //                                                     </small>
    //                                                 ) : (
    //                                                     ""
    //                                                 )}
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     <div className="row">
    //                                         <div className="col-md-12">
    //                                             <label htmlFor="wfirstName2">
    //                                                 {textSignular} Images:
    //                                                 <span className="danger">*</span>
    //                                             </label>
    //                                             <ImageUploader
    //                                                 setErrorMessage={setErrorMessage}
    //                                                 fieldDesc={
    //                                                     Object.values(allowedImageTypes).join(",") +
    //                                                     " extensions are allowed"
    //                                                 }
    //                                                 allowedImageTypes={allowedImageTypes}
    //                                                 returnImagesUrls={handleMultipleImages}
    //                                                 minLimit={minLimit}
    //                                                 productMultiImagesError={productMultiImagesError}
    //                                                 setProductMultiImagesError={
    //                                                     setProductMultiImagesError
    //                                                 }
    //                                             />
    //                                             {productMultiImagesError ? (
    //                                                 <small className="text-danger">
    //                                                     {productMultiImagesError}
    //                                                 </small>
    //                                             ) : (
    //                                                 ""
    //                                             )}
    //                                         </div>
    //                                     </div>

    //                                     <div className="row">
    //                                         <div className="col-md-12">
    //                                             <div className="mb-3">
    //                                                 <label htmlFor="wfirstName2">
    //                                                     {textSignular} Price:
    //                                                     <span className="danger">*</span>
    //                                                 </label>
    //                                                 <input
    //                                                     disabled={hasVariants}
    //                                                     type="number"
    //                                                     min="1"
    //                                                     className="form-control required"
    //                                                     id="wfirstName2"
    //                                                     name="price"
    //                                                     value={price}
    //                                                     onChange={handlePriceChange}
    //                                                 />
    //                                                 {priceError ? (
    //                                                     <small className="text-danger">{priceError}</small>
    //                                                 ) : (
    //                                                     ""
    //                                                 )}
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     <div className="row">
    //                                         <div className="col-md-12">
    //                                             <div className="mb-3">
    //                                                 <label htmlFor="quantity">
    //                                                     {textSignular} Quantity:
    //                                                     <span className="danger">*</span>
    //                                                 </label>
    //                                                 <div className={`form-check mb-2`}>
    //                                                     <input
    //                                                         className="form-check-input"
    //                                                         type="checkbox"
    //                                                         id="unlimited_qty"
    //                                                         disabled={hasVariants}
    //                                                         checked={quantity === -1 ? true : false}
    //                                                         onChange={() =>
    //                                                             setQuantity((e) => (e === -1 ? 1 : -1))
    //                                                         }
    //                                                     />
    //                                                     <label
    //                                                         className="form-check-label"
    //                                                         htmlFor="unlimited_qty"
    //                                                     >
    //                                                         Unlimited
    //                                                     </label>
    //                                                 </div>
    //                                                 {quantity !== -1 && (
    //                                                     <>
    //                                                         <input
    //                                                             disabled={hasVariants}
    //                                                             type="number"
    //                                                             min="1"
    //                                                             className="form-control required"
    //                                                             id="quantity"
    //                                                             name="quantity"
    //                                                             value={quantity}
    //                                                             onChange={handleQuantityChange}
    //                                                         />
    //                                                         {quantityError ? (
    //                                                             <small className="text-danger">
    //                                                                 {quantityError}
    //                                                             </small>
    //                                                         ) : (
    //                                                             ""
    //                                                         )}
    //                                                     </>
    //                                                 )}
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     <div className="row">
    //                                         <div className="col-md-4">
    //                                             <div className="form-check form-check-inline">
    //                                                 <input
    //                                                     className="form-check-input"
    //                                                     type="radio"
    //                                                     name="inlineRadioOptions"
    //                                                     id="inlineRadiow2"
    //                                                     value="no-shipping"
    //                                                     disabled=""
    //                                                     checked={shipping === "no-shipping"}
    //                                                     onChange={() => handleRadioChange("no-shipping")}
    //                                                 />
    //                                                 <label
    //                                                     className="form-check-label"
    //                                                     htmlFor="inlineRadio2"
    //                                                 >
    //                                                     No Shipping Required
    //                                                 </label>
    //                                             </div>
    //                                         </div>

    //                                         <div className="col-md-4">
    //                                             <div className="form-check form-check-inline">
    //                                                 <input
    //                                                     className="form-check-input"
    //                                                     type="radio"
    //                                                     name="inlineRadioOptions"
    //                                                     id="inlineRadio3"
    //                                                     value="free-shipping"
    //                                                     disabled=""
    //                                                     checked={shipping === "free-shipping"}
    //                                                     onChange={() => handleRadioChange("free-shipping")}
    //                                                 />
    //                                                 <label
    //                                                     className="form-check-label"
    //                                                     htmlFor="inlineRadio2"
    //                                                 >
    //                                                     Free Shipping
    //                                                 </label>
    //                                             </div>
    //                                         </div>

    //                                         <div className="col-md-4">
    //                                             <div className="form-check form-check-inline">
    //                                                 <input
    //                                                     className="form-check-input"
    //                                                     type="radio"
    //                                                     name="inlineRadioOptions"
    //                                                     id="inlineRadio3s"
    //                                                     value="paid-shipping"
    //                                                     disabled=""
    //                                                     checked={shipping === "paid-shipping"}
    //                                                     onChange={() => handleRadioChange("paid-shipping")}
    //                                                 />
    //                                                 <label
    //                                                     className="form-check-label"
    //                                                     htmlFor="inlineRadio2"
    //                                                 >
    //                                                     Paid Shipping
    //                                                 </label>
    //                                             </div>
    //                                         </div>
    //                                     </div>

    //                                     {shipping == "paid-shipping" ? (
    //                                         <>
    //                                             <div className="row">
    //                                                 <div className="col-md-12">
    //                                                     <p className="text-danger">
    //                                                         In the video checkout, currently there is no
    //                                                         support for products with paid shipping.
    //                                                     </p>
    //                                                     <div className="mb-3">
    //                                                         <div className="form-group">
    //                                                             <label htmlFor="weight">
    //                                                                 Weight (In Grams):
    //                                                             </label>
    //                                                             <input
    //                                                                 className="form-control"
    //                                                                 type="number"
    //                                                                 min="1"
    //                                                                 id="weight"
    //                                                                 placeholder="Enter Weight"
    //                                                                 value={weight}
    //                                                                 onChange={handleWeightChange}
    //                                                             />
    //                                                             {weightError ? (
    //                                                                 <small className="text-danger">
    //                                                                     {weightError}
    //                                                                 </small>
    //                                                             ) : (
    //                                                                 ""
    //                                                             )}
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>

    //                                             <div className="row">
    //                                                 <h5>Dimensions (L X W X H)</h5>
    //                                                 <div className="col-md-4">
    //                                                     <div className="mb-3">
    //                                                         <div className="form-group">
    //                                                             <label htmlFor="weight">
    //                                                                 Lenght (In Centimeter):
    //                                                             </label>
    //                                                             <input
    //                                                                 className="form-control"
    //                                                                 type="number"
    //                                                                 min="1"
    //                                                                 id="weight"
    //                                                                 placeholder="Enter Lenght"
    //                                                                 value={lenght}
    //                                                                 onChange={handleLenghtChange}
    //                                                             />
    //                                                             {lenghtError ? (
    //                                                                 <small className="text-danger">
    //                                                                     {lenghtError}
    //                                                                 </small>
    //                                                             ) : (
    //                                                                 ""
    //                                                             )}
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>

    //                                                 <div className="col-md-4">
    //                                                     <div className="mb-3">
    //                                                         <div className="form-group">
    //                                                             <label htmlFor="weight">
    //                                                                 Width (In Centimeter):
    //                                                             </label>
    //                                                             <input
    //                                                                 className="form-control"
    //                                                                 type="number"
    //                                                                 min="1"
    //                                                                 id="weight"
    //                                                                 placeholder="Enter Width"
    //                                                                 value={width}
    //                                                                 onChange={handleWidthChange}
    //                                                             />
    //                                                             {widthtError ? (
    //                                                                 <small className="text-danger">
    //                                                                     {widthtError}
    //                                                                 </small>
    //                                                             ) : (
    //                                                                 ""
    //                                                             )}
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>

    //                                                 <div className="col-md-4">
    //                                                     <div className="mb-3">
    //                                                         <div className="form-group">
    //                                                             <label htmlFor="weight">
    //                                                                 Height (In Centimeter):
    //                                                             </label>
    //                                                             <input
    //                                                                 className="form-control"
    //                                                                 type="number"
    //                                                                 min="1"
    //                                                                 id="weight"
    //                                                                 placeholder="Enter Height"
    //                                                                 value={height}
    //                                                                 onChange={handleHeightChange}
    //                                                             />
    //                                                             {heightError ? (
    //                                                                 <small className="text-danger">
    //                                                                     {heightError}
    //                                                                 </small>
    //                                                             ) : (
    //                                                                 ""
    //                                                             )}
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </div>
    //                                         </>
    //                                     ) : (
    //                                         ""
    //                                     )}

    //                                     <ProductVariants
    //                                         ref={variantsRef}
    //                                         setHasVariant={setHasVariant}
    //                                     />

    //                                     <div class="actions clearfix">
    //                                         <ul role="menu" aria-label="Pagination">
    //                                             <li aria-hidden="false" aria-disabled="false">
    //                                                 <button
    //                                                     type="button"
    //                                                     onClick={handleSubmit}
    //                                                     disabled={isButtonDisabled}
    //                                                     class="mr-5 btn btn-primary text-white float-end"
    //                                                 >
    //                                                     {addProductLoader ? (
    //                                                         <div>
    //                                                             <span
    //                                                                 class="spinner-border spinner-border-sm"
    //                                                                 role="status"
    //                                                                 aria-hidden="true"
    //                                                             ></span>{" "}
    //                                                             Loading...
    //                                                         </div>
    //                                                     ) : (
    //                                                         <div>
    //                                                             <i class="ti ti-device-floppy me-1 fs-4"></i>
    //                                                             Submit
    //                                                         </div>
    //                                                     )}
    //                                                 </button>
    //                                             </li>
    //                                         </ul>
    //                                     </div>
    //                                 </div>
    //                             </form>
    //                         </div>
    //                     </div>
    //                 </section>
    //             </div>
    //         </Page>
    //     </div>
    // );
}
export default AddProductNav;
