import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import AddProductVideo from "../Products/AddProductVideo";
import { CSSTransition } from "react-transition-group";
import { isMobile, userLoggedAccessToken } from "../../helpers/helper";
import { VideoSteps } from "../Onboarding/Video/AddVideo";
import SelectProductSelector from "../../Product/SelectProduct";
import Button from "../../Form/Button";
// import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
// import { AddProductHeader } from "../Onboarding/Product/AddProduct";
import { VideoPurpose } from "../../../Enums/VideoPurpose";

const PRODUCT_LIMIT = 5;

function SelectProductNav() {
  const isMobileSize = isMobile();
  const history = useHistory();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? "mini-sidebar" : "full");

  const [data, setData] = useState([]);
  const [successMessage, setSuccessMessage] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [productError, setProductError] =  useState(true);
  const [loading, setLoading] = useState(false);

  // const [selectedProduct, setSelectedProduct] = useState([]);
  const [productData, setProductData] = useState();
  useEffect(() => {
    if (history.location.state?.showSuccess) {
      setSuccessMessage(history.location.state.showSuccess);
    }

    // If video is donation
    if (
      history.location?.state?.addVideoData?.videoType === VideoPurpose.TO_COLLECT_THE_DONATION ||
      history.location?.state?.addVideoData?.videoType === VideoPurpose.TO_GENERATE_LEAD
    ) {
      navigateToCheckout()
    }

    fetchData();
  }, [history.location.state.showSuccess]);


  useEffect(() => {
    // console.log('history.location.state>>>', history.location.state);

    if (history?.location?.state?.selectedProducts) {
      setSelectedOption(history?.location?.state?.selectedProducts);
      

    }
  }, []);

  useEffect(() => {

    if (selectedOption.length > 0) {
      
 
      setProductError(false);
     }else{



      setProductError(true);


     }
 
   
  }, [selectedOption]);


  const navigateToCheckout = () => {
    const pushStateData = {
      selectedProducts: selectedOption,
      addVideoData: history.location.state.addVideoData,
      addStreamData: history.location.state.addStreamData,
      addFacebookData: history.location.state.addFacebookData,
    };

    const draftData = {
      userdata: pushStateData,
      step: 2,
      draftType: "addvideo",
    };

    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + "/user-onboarding", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userLoggedAccessToken(),
        },
        body: JSON.stringify(draftData),
      });
    });

    return history.push({
      pathname: "/videos/add-nav-video/select-nav-product/lead-checkout-nav",
      state: pushStateData,
    });
  }

  function moveToLeadCheckout() {

    if (selectedOption.length > 0) {
      
    //  setProductError(false);
    }

 
    return navigateToCheckout()
  }

  function addProduct() {
    isVisible ? setIsVisible(false) : setIsVisible(true);
  }

  const fetchData = () => {
    setLoading(true);

    var token = localStorage.getItem("authSession");
    token = JSON.parse(token);

    fetch(process.env.REACT_APP_API_URL + "/user-products", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ id: token.idToken }),
    })
      .then((response) => response.json())
      .then((data) => setData(data.data))
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoading(false));
  };

  const handleOptionChange = (event) => {




    // setProductError("");

    if (event.length > 0) {
      // setProductError(false);
    }else{

      // setProductError(true);

    }

    if (event) {
      const selectedProducts = event.map(dataOptions => ({
        value: dataOptions.value,
        label: dataOptions.label,
        shopify_product_id: dataOptions?.shopify_product_id,
        shopify_variant_id: dataOptions?.shopify_variant_id,
        woocommerce_product_id: dataOptions?.woocommerce_product_id,
        has_shipping: dataOptions?.has_shipping,
        ...dataOptions
      }));
      setSelectedOption(selectedProducts);
    }
  };

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  const isButtonDisabled = productError;

  // console.log(selectedProduct,"sajdainnnnnnn hererererer")

  // if(selectedProduct.length>0){

  //   setSelectedOption(selectedProduct)

  //   console.log(selectedProduct,"sajdainnnnnnn hererererer")

  // }

  // const ImportProductRedirect = () => {
  //   history.location.state.OnBoardingShowImport = true;
  //   history.push({
  //     pathname: '/settings/integrations'
  //   });
  // }

  // const ImportWoocommerceProductRedirect = () => {
  //   history.location.state.OnBoardingShowImport = true;
  //   history.push({
  //     pathname: '/settings/integrations'
  //   });
  // }

  const backToMainVideoStep = () => {
    return history.push({
      pathname: "/videos/video-purpose-nav",
      state: history.location.state,
    });
  }

  // if (selectedOption.length < 1) {
  //   return setProductError("Please select products");
  // }



   

  return (
    <div
      className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
      data-sidebartype={sidebarType}
      id="main-wrapper"
      data-theme="blue_theme"
      data-layout="vertical"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title="Add Product" onNavBarClick={onNavBarClick} formWidth={true}>
        <div className="row pb-50">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header vs-card-header">
                <h5 className="fw-bolder">Add Product</h5>
                <VideoSteps step="3" purpose={history.location.state.addVideoData.videoType} />
              </div>
              <div className="card-body">
                <div className={`${isVisible ? "" : "w-500"}`}>
                  {/* {successMessage && (
                    <FormToast
                      type={FORM_TOAST_TYPES.SUCCESS}
                      message={successMessage}
                    />
                  )} */}
                  {/* <AddProductHeader
                    selectedType="product"
                    addProductCallback={() => { }}
                    shopifyCallback={ImportProductRedirect}
                    wooCommerceCallback={ImportWoocommerceProductRedirect}
                    hideAddProduct
                  /> */}

                  {isVisible ? (
                    <CSSTransition
                      in={isVisible}
                      timeout={300}
                      classNames="fade"
                      unmountOnExit
                    >
                      <AddProductVideo setIsVisible={setIsVisible} setSelectedProduct={setSelectedOption} setProductData={setProductData} />
                    </CSSTransition>
                  ) : (
                    <div
                      style={{ width: isMobileSize ? "100%" : 500, margin: "0 auto" }}
                      className="mb-4 mt-4"
                    >
                      <SelectProductSelector
                        selectionLimit={PRODUCT_LIMIT}
                        selectedOptions={selectedOption}
                        onChange={handleOptionChange}
                        options={data}
                        errorMessage={false}
                        addProduct={{
                          label: "Add Product",
                          onClick: addProduct,
                        }}
                      />
                    </div>
                  )}
                  {/* {isVisible ? "" : <div className="vs-form-footer d-flex justify-content-center align-items-center">
                    <Button
                      type="button"
                      onClick={moveToLeadCheckout}
                      buttonText="Next"
                      fullButton={false}
                      loading={loading}
                      disabled={isButtonDisabled}
                    />
                  </div>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
      {/* sticky footer */}

   

      {!isVisible &&
      <div className="sticky-footer ">
        <div className="footer-container">
          <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
            <Button
              className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
              type="button"
              buttonText="Back"
              onClick={backToMainVideoStep}
            />
            <Button
              className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
              type="button"
              onClick={moveToLeadCheckout}
              buttonText="Next"
              fullButton={false}
              loading={loading}
              disabled={isButtonDisabled}
            />
          </div>
        </div>
      </div>
      }
      {/* sticky footer */}
    </div>
  );
}
export default SelectProductNav;
