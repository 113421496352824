export const minimumRequiredLength = (password) => {
  return password.length > 8;
};

export const containsLowercaseLetter = (password) => {
  return (/[a-z]/g).exec(password) !== null;
};

export const containsUppercaseLetter = (password) => {
  return (/[A-Z]/g).exec(password) !== null;
};

export const containsNumber = (password) => {
  return (/[0-9]/g).exec(password) !== null;
};

export const validPassword = (password) => {
  return (
    minimumRequiredLength(password) &&
    containsLowercaseLetter(password) &&
    containsUppercaseLetter(password) &&
    containsNumber(password)
  );
};
