import React, { useState, useEffect } from "react";
import { userLoggedAccessToken } from "../../helpers/helper";
import { useHistory } from "react-router-dom";
import "./ExpandableVideosDetails.css";
import Loading from "../../Pages/Loadings/Loading";
import Button from "./../../Form/Button";
const moment = require("moment");

const ExpandableVideosDetailsLeadsFormDetails = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [listingData, setListingData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (data?.parent_lead_form_id) {
      fetchLeadFormData();
    }
  }, [data]);

  const fetchLeadFormData = () => {
    setLoading(true);
    fetch(process.env.REACT_APP_API_URL + "/leads-by-id", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ parent_lead_form_id: data?.parent_lead_form_id }),
    })
      .then((response) => response.json())
      .then((data) => setListingData(data?.leadFormData?.rows))
      .catch((e) => console.error("Error fetching data:", e))
      .finally(() => setLoading(false));
  };

  const handleClick = (id) => {
    history.push("/leads/" + id);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className='mt-5'>
      <table className='table'>
        <thead>
          <tr>
            <th scope="col">Form Title</th>
            <th scope="col">Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            listingData.map((row, rowIndex) => (
              <tr key={`lead-${rowIndex}`}>
                <td>{row.title}</td>
                <td>{moment(row.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                <td>
                  <Button
                    onClick={() => handleClick(row?.id)}
                    className="btn btn-primary fs-2 text-white py-2 px-2"
                    buttonText="View Details"></Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};


export default ExpandableVideosDetailsLeadsFormDetails;
