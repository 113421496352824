import React, { useState, useEffect } from "react";
import FormHeading from "../../../../atoms/FormHeading/FormHeading";
import {
  isMobile,
} from "../../../helpers/helper";

import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import AddonsBoxesList from "./AddonsBoxesList";


export default function AddonPackagesBox({ onlyListing, ...props }) {
  const isMobileSize = isMobile();
  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );
  // const [addOnData, setAddOnData] = useState();
  // const [subscribedAddOnData, setSubscribedAddOnData] = useState();
  // const [subscribedAddonsLoader, setSubscribedAddonsLoader] = useState(false);
  // const [addonsLoader, setAddonsLoader] = useState(false);
  // const [cancelSubModal, setCancelSubModal] = useState(false);
  // const [addonSuccessMsg, setAddonSuccessMsg] = useState(null);
  // const [addonErrorMsg, setAddonErrorMsg] = useState(null);
  // const [addonCancelLoader, setAddonCancelLoader] = useState(false);
  // const [selectedAddonForCancellation, setSelectedAddonForCancellation] =
  //   useState();


  useEffect(() => {
    // fetchSubscribedAddOns();
    // fetchAddOns();
  }, []);



  // const fetchAddOns = () => {
  //   setAddonsLoader(true);
  //   fetch(process.env.REACT_APP_API_URL + "/subscription-addon-plans", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + userLoggedAccessToken(),
  //     },
  //     body: JSON.stringify({}), // forceToFetch will fetch expired
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setAddOnData(data?.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     })
  //     .finally((error) => {
  //       setAddonsLoader(false);
  //     });
  // };


  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };


  // return <AddonPackagBoxesHTML />;

  return (
    <>
      <div
        className={`page-wrapper ${
          sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
        }`}
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype={sidebarType}
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        <Sidebar onNavBarClick={onNavBarClick} />
        <Page title="Subscription Management" onNavBarClick={onNavBarClick}>
          {/* <BreadCrump
            title="Subscription Management"
            image={subscriptionThumbImg}
            breadCrumpDetail={[
              { name: "Dashboard", link: "/" },
              {
                name: "Subscription Management",
                link: "/settings/subscription",
              },
              { name: "Add-ons", link: "" },
            ]}
            additionalClasses={`subscription-addon-breadcrumb`}
          /> */}

          <div>
            {/* {addonsLoader != true && addOnData?.length >= 1 && ( */}
              <FormHeading weight="600" size={16}>
                Add-ons
              </FormHeading>
            {/* )} */}

            <AddonsBoxesList />
          </div>
        </Page>
      </div>
    </>
  );
}
