import React, { useMemo } from "react";

const Select = ({ id, label, placeholder, options, value, onChange, disabled, errorMessage }) => {
    const memoizedOptions = useMemo(() => (
        options?.map((o, i) => (
            <option key={`option-${i}`} value={o.value}>{o.label}</option>
        ))
    ), [options]);

    return (
        <div className="form-group">
            <label htmlFor={id} className="form-label">{label}</label>
            <select
                placeholder={placeholder}
                name={label}
                onChange={onChange}
                id={id}
                className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                disabled={disabled}
                value={value}
            >
                {memoizedOptions}
            </select>
            {errorMessage && <div><span className="text-danger fs-2">{errorMessage}</span></div>}
        </div>
    );
}

export default Select;
