import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import * as helper from "../../../helpers/helper";
import SocialHelper from "../../../helpers/socialHelper";
import styles from "./StreamPlayer.module.css";
const moment = require("moment");
var localStream;
var peerConnection;
const StreamPlayer = ({
  streamingEndPoint,
  setParentErrorMessage,
  subscriptionData,
  setParentStreamingError,
  videoStreamData,
  setParentCameraPermission,
  endTimeMessage
}) => {
  const history = useHistory();
  const localVideoRef = useRef();
  
  
  const [isPublished, setIsPublished] = useState(false);
  const [streamError, setStreamError] = useState(null);
  const [streamConnectionLoader, setStreamConnectionLoader] = useState(false);
  const [closeStreamLoader, setCloseStreamLoader] = useState(false);
  const [timerInterval, setTimerInterval] = useState(true);
  const [timer, setTimer] = useState(0);
  const [maxStreamDuration, setMaxStreamDuration] = useState(0);
  const [isMutedMic, setIsMutedMic] = useState(false);
  const [isVideoOFF, setIsVideoOFF] = useState(false);
  const [videoSharebleURL, setVideoSharebleURL] = useState();
  const [dropDownOpenIndex, setDropDownOpenIndex] = useState(null);
  const [isConnectionClosed, setIsConnectionClosed] = useState(null);
  const [cameraPermission, setCameraPermission] = useState(null);
  // const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  

  const dropdownRef = useRef(null); // Define the dropdown reference

  // videoUrl = process.env.REACT_APP_WEB_URL + '/watch/' + successVideoId;

  const handleBeforeUnload = (event) => {
    const message = 'Are you sure you want to leave? Your live stream will be end.';



    if(event && isPublished===true){
      event.returnValue = message; // Standard for most browsers
    }

    // if (isPublished) {
      // Stop streaming and turn off camera shutter
      stopStreaming();
      turnOffCameraShutter();

      // alert('awefawef');
    // }


    if(isPublished===true){
      //stop streaming and turn off camera
    }

    return message; // For older versions of Internet Explorer
  };

  useEffect(() => {
    // if(isPublished==true){
      window.addEventListener('beforeunload', handleBeforeUnload);
      window.addEventListener('popstate', handleBeforeUnload);

      

      return () => {
        window.removeEventListener('popstate', handleBeforeUnload);
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    // }
    
  }, []);

  useEffect(()=> {
    setParentCameraPermission(cameraPermission)
  },[cameraPermission]);

  useEffect(() => {
    if (
      helper.showNumberOrInfinity(
        subscriptionData?.live_stream_from_video_squirrel
      ) === "Unlimited"
    ) {
      setMaxStreamDuration(
        helper.showNumberOrInfinity(
          subscriptionData?.live_stream_from_video_squirrel
        )
      );
    } else {
      setMaxStreamDuration(
        helper.allowedAddonSteamTimeMinutes(subscriptionData)
          ?.totalStreamMinutes * 60
      );
    }
  }, [subscriptionData]);

  useEffect(() => {
    if (timer === maxStreamDuration) {
      setStreamError(
        `Your ${
          maxStreamDuration / 60
        }-minutes recording limit has been reached.`
      );
      setIsPublished(false);
    }
  }, [timer, maxStreamDuration]);

  useEffect(() => {
    if (videoStreamData?.video_id) {
      setVideoSharebleURL(
        process.env.REACT_APP_WEB_URL + "/watch/" + videoStreamData?.video_id
      );
    } else {
      setVideoSharebleURL(null);
    }

    // const [videoSharebleURL, setVideoSharebleURL] = useState();

    // videoUrl = ;
  }, [videoStreamData]);


  useEffect(() => {
    // Respond to changes in isMutedMic
    const audioTrack = localStream?.getAudioTracks()[0];
    if (audioTrack) {
      audioTrack.enabled = !isMutedMic;
    }
  }, [isMutedMic]);
  

  const turnOffCameraShutter = () => {
    // Turn off camera shutter logic
    if (localStream) {
      const videoTrack = localStream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.stop();
      }
    }
  };

  const stopStreaming = () => {
    // Stop streaming logic (close connections, clear intervals, etc.)
    if (peerConnection) {
      peerConnection.close();
    }
    // Add any other necessary cleanup steps
  };

  const formatTime = (seconds) => {
    seconds = seconds >= 0 ? seconds : 0;
    const duration = moment.duration(seconds, "seconds");
    return moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
  };

  useEffect(() => {
    if (isPublished) {
      setTimerInterval(
        setInterval(() => {
          setTimer((prevTimer) => prevTimer + 1);
        }, 1000)
      );
    } else {
      clearInterval(timerInterval);
    }
  }, [isPublished]);

  const initWebRTC = async () => {
    setStreamError(null);
    setCameraPermission(false);
    try {
      // alert(helper.isMobile() ? (windowWidth-50) : 1280);
      // const idealWidth = helper.isMobile() ? (windowWidth-50) : 1280;
      // const idealHeight = helper.isMobile() ? windowHeight-50:720;

      const idealWidth = windowWidth<=1280 ? (windowWidth-50) : 1280;
      const idealHeight = windowHeight<=720 ? windowHeight-50:720;

      const minWidth = 640;
      const minHeight = 480;

      const videoConstraints = {
        audio: true,
        video: {
          width: { min: idealWidth<=minWidth?idealWidth:minWidth, ideal: idealWidth, max: 1920 },
          height: { min: minHeight, ideal: idealHeight, max: 1080 },
          // width: { exact: helper.isMobile() ? (windowWidth-50) : 1280 },
          // height: { exact: helper.isMobile() ? 720 : 720 },
          // width: { exact: 2560 },
          // height: { exact: 1440 },
        },
      };

      localStream = await navigator.mediaDevices.getUserMedia(videoConstraints);
      localVideoRef.current.srcObject = localStream;

      const configuration = {
        iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
      };
      peerConnection = new RTCPeerConnection(configuration);


      const audioTrack = localStream.getAudioTracks()[0];
      const videoTrack = localStream.getVideoTracks()[0];
      peerConnection.addTrack(audioTrack, localStream);
      peerConnection.addTrack(videoTrack, localStream);


      // localStream.getTracks().forEach((track) => {
      //   peerConnection.addTrack(track, localStream);
      // });

      setCameraPermission(true);

      if (isPublished) {
        const offer = await peerConnection.createOffer();
        await peerConnection.setLocalDescription(offer);

        const opusCodecId = offer.sdp.match(/a=rtpmap:(\d+) opus\/48000\/2/);

        if (opusCodecId !== null) {
          offer.sdp = offer.sdp.replace(
            "opus/48000/2\r\n",
            "opus/48000/2\r\na=rtcp-fb:" + opusCodecId[1] + " nack\r\n"
          );
        }

        try {
          const response = await fetch(streamingEndPoint, {
            method: "POST",
            headers: {
              "Content-Type": "application/sdp",
            },
            body: offer.sdp,
          });

          const answerSdp = await response.text();
          const answer = new RTCSessionDescription({
            type: "answer",
            sdp: answerSdp,
          });
          await peerConnection.setRemoteDescription(answer);

          peerConnection.onconnectionstatechange = () => {
            console.log("Connection state:", peerConnection.connectionState);
          };

          setCameraPermission(true);

          // setTimerInterval(
          //   setInterval(() => {
          //     setTimer(
          //       (prevTimer) => parseIntprevTimer + (isPublished === true ? 1 : 0)
          //     );
          //   }, 1000)
          // );
        } catch (error) {
          // setStreamError("Error sending signal");
          console.error("Error sending signal:", error);
        } finally {
          setStreamConnectionLoader(false);
        }
      }

      // const videoTrack = localStream.getVideoTracks()[0];
      // const audioTrack = localStream.getAudioTracks()[0];

      // if (isVideoOFF) {
      //   const canvas = document.createElement("canvas");
      //   canvas.width = 1;
      //   canvas.height = 1;
      //   const ctx = canvas.getContext("2d");
      //   ctx.fillRect(0, 0, 1, 1);
      //   const blackStream = canvas.captureStream();
      //   videoTrack.stop();
      //   peerConnection.addTrack(blackStream.getVideoTracks()[0], localStream);
      // }

      if (isMutedMic) {
        audioTrack.enabled = false;
      }
    } catch (error) {
      setStreamError("Error accessing media devices");
      console.error("Error accessing media devices:", error);
    } finally {
      setStreamConnectionLoader(false);
    }
  };

  // isConnectionClosed, setIsConnectionClosed

  useEffect(() => {
    initWebRTC();
    setIsConnectionClosed(false);
    console.log('isPublished',isPublished,'timerInterval',timerInterval);
    if (!isPublished) {
      setIsVideoOFF(false)
      setIsMutedMic(false)
    }
    return () => {
      if (peerConnection) {
        // peerConnection.close();
        stopStreaming();
        // if (isPublished === true) {
          setIsConnectionClosed(true);
          // clearInterval(timerInterval);
          // setTimerInterval(false)

          stopTimer();

          // concludeStream();
        // }
      }

      if (localStream) {
        localStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [isPublished]);

  useEffect(() => {
    console.log(
      "timerInterval && isConnectionClosed",
      timerInterval,
      isConnectionClosed
    );
    if (timerInterval && isConnectionClosed) {
      // clearInterval(timerInterval);
      // setTimerInterval(false);
    }
  }, [isConnectionClosed]);

  const stopTimer = () => {
    setTimerInterval(false);
    console.log('timerInterval',timerInterval)
    // You can also perform other actions here if needed
  };

  const togglePublish = () => {
    setIsPublished((prevIsPublished) => !prevIsPublished);
  };

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleDocumentClick = (event) => {
    if (
      dropDownOpenIndex >= 0 &&
      !dropdownRef.current?.contains(event?.target)
    ) {
      setDropDownOpenIndex(null); // Close the dropdown
    }
  };

  const existStream = () => {
    setIsPublished(false);
    history.push({
      pathname: '/videos',
    });
  }

  useEffect(() => {
    toggleVideo()
  }, [isVideoOFF]);

  const toggleVideoState = async () => {
    setIsVideoOFF(!isVideoOFF);
  }

  const toggleVideo = async () => {
    try {
      // Check if localStream is defined before accessing getVideoTracks
      if (!localStream) {
        console.error('Local stream is not defined');
        return;
      }
      // setIsVideoOFF(!isVideoOFF);
      const videoTrack = localStream.getVideoTracks()[0];
      
  
      if (videoTrack) {
        videoTrack.enabled = !isVideoOFF;
  
        if (isVideoOFF) {
          console.log(isVideoOFF,"true1")
          // If video is turned off, replace the video frames with a black screen
          const canvas = document.createElement('canvas');
          canvas.width = videoTrack.getSettings().width;
          canvas.height = videoTrack.getSettings().height;
          const ctx = canvas.getContext('2d');
          ctx.fillStyle = 'black';
          ctx.fillRect(0, 0, canvas.width, canvas.height);
          const blackStream = canvas.captureStream();
          
          peerConnection.removeTrack(videoTrack, localStream);
          peerConnection.addTrack(blackStream.getVideoTracks()[0], localStream);
        } else {
          console.log(isVideoOFF,"true2")
          // const idealWidth = helper.isMobile() ? (windowWidth-50) : 1280;
          // const idealHeight = helper.isMobile() ? windowHeight-50:720;
          const idealWidth = windowWidth<=1280 ? (windowWidth-50) : 1280;
          const idealHeight = windowHeight<=720 ? windowHeight-50:720;
          const minWidth = 640;
          const minHeight = 480;
    
          // If video is turned on, remove the black screen and add the original video track back
          const videoConstraints = {
            width: { min: idealWidth<=minWidth?idealWidth:minWidth, ideal: idealWidth, max: 1920 },
            height: { min: minHeight, ideal: idealHeight, max: 1080 },

            // width: { exact: 2560 },
            // height: { exact: 1440 },

          };
  
          const newVideoStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: videoConstraints,
          });
  
          const newVideoTrack = newVideoStream.getVideoTracks()[0];
  
          peerConnection.removeTrack(videoTrack, localStream);
          localStream.removeTrack(videoTrack);
          localStream.addTrack(newVideoTrack);
          peerConnection.addTrack(newVideoTrack, localStream);
        }
      }
    } catch (error) {
      console.error('Error toggling video:', error);
    }
  };
  
  
  

  return (
    <div
      className={`d-flex justify-content-center ${styles.streamPlayerMain}`}
      // style={{ flexDirection: "column", position: "relative", zIndex:200 }}
    >
      <div className="col-xs-12">
        <div className="video-controls-top">
          <div style={{ justifyContent: "left" }}></div>
          <div></div>
          <div className="justify-content-end"></div>
        </div>
        <div className="record-player-area">
          {/* {localVideoRef && (
                    )}  */}

          

          {localVideoRef && (
            <div className={`camera-preview-area ${styles.cameraPreviewArea}`}
            
            
            // style={{marginTop:helper.isMobile()?"100px":"auto",position:'relative'}}
            
            >

              {isPublished!==true && (
                <button type="button" onClick={existStream} className={`${styles.closeBtn} close-btn cursor-pointer btn-circle`}>
                  <i className="ti ti-x"></i>
                </button>
              )}

              {/* <video ref={localVideoRef} autoPlay playsInline muted /> */}
              <video
                ref={localVideoRef}
                autoPlay
                playsInline
                // style={{ height: helper.isMobile() ? "100vh" : "auto" }}
                // muted={isMutedMic}
                muted
              />
            </div>
          )}
        </div>
        <div className="video-controls-bottom mb-3">
          <div class="timer-display-parent"></div>
        </div>
      </div>

      <div
        // style={{
        //   position: "absolute",
        //   bottom: "20px",
        //   width: "100%",
        //   // background: cameraPermission?"#20202042":"none",
        // }}
        className={`text-center d-flex justify-content-center align-items-center start-recording-btn-area mt-3 ${styles.cameraButtonControls}`}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div style={{ color: "#222" }}>
          <div className="row">
            {(cameraPermission && isPublished) ? 
              <div className="col-xs-12">
                <div className="button-group">
                   <button
                    onClick={(e) => setIsMutedMic(!isMutedMic)}
                    type="button"
                    className="d-inline-flex align-items-center justify-content-center btn btn-primary btn-circle btn-lg"
                  >
                    <i
                      className={
                        "fs-5 ti ti-" +
                        (isMutedMic ? "microphone-off" : "microphone")
                      }
                    />
                  </button>
                  <button 
              onClick={(e) => toggleVideoState()}
              type="button"
              className="d-inline-flex align-items-center justify-content-center btn btn-primary btn-circle btn-lg"
            >
              <i
                className={
                  "fs-5 ti ti-" + (isVideoOFF ? "video-off" : "video")
                }
              />
            </button>
                </div>
              </div>
            :""}

            {isPublished === true && (
              <>
                {/* <div className="col-xs-12 mb-2">
                  <span
                    class="streaming-live-label fs-4"
                    style={{ color: "#fff" }}
                  >
                    Status:LIVE
                  </span>
                </div> */}

                <div className="col-xs-12">
                  <>
                    <button
                      type="button"
                      disabled={closeStreamLoader}
                      className="btn mb-1 waves-effect rounded-pill waves-light btn-lg btn-rounded btn-primary"
                      onClick={togglePublish}
                    >
                      {closeStreamLoader ? (
                        <div>
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Finishing Streaming...
                        </div>
                      ) : (
                        <div>
                          <i className="ti ti-device-tv-off fs-5"></i> Finish
                          Stream
                        </div>
                      )}
                    </button>
                  </>
                </div>
              </>
            )}

            <div className="col-xs-12">
              {isPublished === false && cameraPermission && (
                <button
                  type="button"
                  disabled={!streamingEndPoint || streamConnectionLoader}
                  className="btn mb-1 waves-effect rounded-pill waves-light btn-lg btn-rounded btn-primary"
                  onClick={togglePublish}
                >
                  {streamConnectionLoader ? (
                    <div>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Start Streaming...
                    </div>
                  ) : (
                    <div>
                      <i className="ti ti-device-tv fs-5"></i> Start Stream
                    </div>
                  )}
                </button>
              )}

              {cameraPermission && (
                <div className="timer-display">
                  <strong style={{ color: "#fff" }}>
                    {formatTime(timer)} /{" "}
                    {maxStreamDuration == "Unlimited" ? (
                      <i title="Unlimited" className="ti ti-infinity"></i>
                    ) : (
                      formatTime(maxStreamDuration)
                    )}
                  </strong>
                </div>
              )}
            </div>

            {endTimeMessage && (
              
              <div className="col-xs-12 mt-3">
                <p className="fw-semibold"  style={{ color: "#fff" }}>{endTimeMessage}</p>
              </div>

            )}

            <div className="col-xs-12">
              <p className="text-danger">{streamError}</p>
            </div>
          </div>
        </div>
        <div style={{ color: "#666" }}>
          <div
            style={{ display: "none" }}
            className="dropdown"
            ref={
              dropDownOpenIndex === videoStreamData?.video_id
                ? dropdownRef
                : null
            }
          >
            <a
              className="text-muted fw-semibold d-flex align-items-center p-1 fs-7"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              onClick={(e) => {
                if (dropDownOpenIndex === videoStreamData?.video_id) {
                  setDropDownOpenIndex(null);
                } else {
                  setDropDownOpenIndex(videoStreamData?.video_id);
                }
              }}
            >
              <i className="ti ti-share"></i>
            </a>
            <ul
              style={{ position: "absolute", right: 10 }}
              className={`dropdown-menu ${
                dropDownOpenIndex === videoStreamData?.video_id ? "show" : ""
              }`}
            >
              <li>
                <h6 className="p-2 text-center">Share on</h6>
              </li>

              <li>
                <a
                  className="dropdown-item"
                  target="_blank"
                  href={SocialHelper.shareOnFacebook(
                    videoSharebleURL,
                    true,
                    true
                  )}
                >
                  <i className="ti ti-brand-facebook"></i> Facebook
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  target="_blank"
                  href={SocialHelper.shareOnTwitter(videoSharebleURL, true)}
                >
                  <i className="ti ti-brand-twitter"></i> Twitter
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  target="_blank"
                  href={SocialHelper.shareOnLinkedIn(videoSharebleURL, true)}
                >
                  <i className="ti ti-brand-linkedin"></i> LinkedIn
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  target="_blank"
                  href={SocialHelper.shareOnWhatsApp(videoSharebleURL, true)}
                >
                  <i className="ti ti-brand-whatsapp"></i> WhatsApp
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* {streamError && ( */}

        {/* )} */}
      </div>
    </div>
  );
};

export default StreamPlayer;
