import React, { useState, useEffect, useRef } from "react";
import {userLoggedAccessToken, extractNumberFromUserId} from "../../../../helpers/helper";
import "./ProfilePrivacySettings.css";

function ProfilePrivacySettings({ isPublicProfile,setIsPublicProfile, profile }) {
  // const [publicProfile,setPublicProfile] = useState(false);
  const [createLinkLoader,setCreateLinkLoader] = useState(false);

  const copyProfileUrlRef = useRef(null);
  const copyUrlDefaultIcon = 'ti ti-clipboard';
  const copyUrlDefaultText = '';
  const [copyUrlText, setCopyUrlText] = useState(copyUrlDefaultText);
  const [copyUrlIcon, setCopyUrlIcon] = useState(copyUrlDefaultIcon);
  const [copyUrlTimeoutId, setCopyUrlTimeoutId] = useState(null);
  const [userPublicProfileUrl, setUserPublicProfileUrl] = useState(null);
  // const [userProfileIdNumber, setUserProfileIdNumber] = useState(null);


  useEffect(() => {
    if(profile?.id){
      let userProfileIdNumberTemp = ''; 
      if(profile?.id?.split('|')[1]){
        // setUserProfileIdNumber(profile?.id?.split('|')[1]);
        userProfileIdNumberTemp = profile?.id?.split('|')[1];
      }

      setUserPublicProfileUrl(process.env.REACT_APP_EXPLORE_URL+'/profile/'+userProfileIdNumberTemp);
    }
  }, [profile]);

  const copyProfileUrl = async () => {
    try {
      const textToCopy = copyProfileUrlRef.current.value;
      await navigator.clipboard.writeText(textToCopy);
      setCopyUrlIcon('ti ti-check');
      setCopyUrlText('Copied!');

    } catch (error) {
      setCopyUrlIcon('ti ti-x');
      setCopyUrlText('Failed!');
      console.error('Error copying text:', error);
    }
    if (copyUrlTimeoutId) {
        clearTimeout(copyUrlTimeoutId);
    }
    const newCopyUrlTimeoutId = setTimeout(function (){setCopyUrlIcon(copyUrlDefaultIcon); setCopyUrlText(copyUrlDefaultText); }, 3000);
    setCopyUrlTimeoutId(newCopyUrlTimeoutId);
  };

  const createProfileLink = () => {
    setCreateLinkLoader(true);
    fetch(process.env.REACT_APP_API_URL + '/create-public-profile-key', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userLoggedAccessToken(),
        },
        // body: JSON.stringify({}), // Replace with your POST data
    })
        .then((response) => response.json())
        .then((responseData) => {

          const extractedProfileID = extractNumberFromUserId(responseData?.data?.userId);

          const profileKeyUrl = process.env.REACT_APP_EXPLORE_URL+'/profile/'+(extractedProfileID)+'/'+(responseData?.data?.profileKey);
          window.open(profileKeyUrl, '_blank')
          //openprofile link in 
          // setUserSubscribedData(data?.data);
        }).catch((error) => {
            console.error('Error fetching data:', error);
        }).finally((error) => {
          setCreateLinkLoader(false);
      });
  };

  return (
    <>
      {/* <div className="mb-4">
      <div className="col-12 profile-privacy-settings">
         <label>Profile Privacy</label>
      </div>

      <div className="mb-4"> 
        <div class="form-check form-switch custom-switch">
          <input
            onClick={(e) => setIsPublicProfile(!isPublicProfile)}
            class="form-check-input"
            type="checkbox"
            id="set-as-private-profile"
            checked={isPublicProfile===true}
          />
          <label
            class="form-check-label"
            for="set-as-private-profile"
            >Public Profilssed</label>
        </div>
        <div className="mt-3">
          {isPublicProfile===true?<span className="privacy-note">People will see your profile including brand information, stats and videos </span>:<span className="privacy-note">Your profile will be hidden for all</span>}
        </div>
        
      </div>  
    </div> */}

      {profile?.isPublicProfile === true && (
        <div class="col-12 mb-3 mt-3">
          <div className="update-profile-button px-3 py-3">
            <label htmlFor="user-profile-link" className="form-label"> Profile Link <code onClick={createProfileLink} className="cursor-pointer">{createLinkLoader ? "Loading..." : "View"}</code>
            </label>
            <div class="form-check form-switch float-end">
              <input
                onClick={(e) => setIsPublicProfile(!isPublicProfile)}
                class="form-check-input"
                type="checkbox"
                id="set-as-private-profile"
                checked={isPublicProfile === true}
              />
              <label
                class="form-check-label"
                for="set-as-private-profile"
              >Public</label>
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Profile Link"
                ref={copyProfileUrlRef}
                id="user-profile-link"
                defaultValue={userPublicProfileUrl}
                name="watch_link"
              />
              <span title="Copy to clip board" onClick={copyProfileUrl} className="fs-2 p-a copy-url-field-icon">
                <i className={'fs-7 ' + copyUrlIcon} />
                {copyUrlText}
              </span>
            </div>

            <div className="mt-3">
              {isPublicProfile === true ? <span className="privacy-note">People will see your profile including brand information, stats and videos </span> : <span className="privacy-note">Your profile will be hidden for all</span>}
            </div>

            {/* <div className="p-r">
                  <input type="text" ref={copyProfileUrlRef} disabled className="form-control required" id="user-profile-link" defaultValue={userPublicProfileUrl} name="watch_link" />
                  <div title="Copy to clip board" className="fs-2 p-a copy-url-field-icon" onClick={copyProfileUrl}>
                      <i className={'fs-7 '+copyUrlIcon}></i>
                      {copyUrlText}
                  </div>
              </div> */}

          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePrivacySettings;
