import React, { useState, useEffect } from "react";
import {
  userLoggedAccessToken,
} from "../../helpers/helper";
// import { truncateText } from "../../helpers/helper";
import "./ExpandableProductDetails.css";

const ExpandableProductDetails = (data) => {

  // const { data } = props;
  data  = data?.data;
  
  const [productsData, setProductsData] = useState([]);
  // const [videoStreamData, setVideoStreamData] = useState(null);
  const [dataLoader, setDataLoader] = useState(false);
  // const [videoTypeText, setVideoTypeText] = useState(false);

  // const titleHeadingText = showUserTypeBaseMessage({
  //   NonProfit: "Donation Campaign",
  //   default: "Product Name",
  // });

  useEffect(() => {
    fetchProductData(data?.id);
  }, [data]);

  const fetchProductData = (videoId) => {
    setDataLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/find-order-detail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ video_id: videoId }),
    })
      .then((response) => response.json())
      .then((data) => {

  


        setProductsData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setDataLoader(false);
      });
  };

  // const columns = [
  //   {
  //     name: "Customer",
  //     selector: (row, i) => row.customer_name,
  //     sortable: false,
  //   },
  //   {
  //     name: "Email",
  //     selector: (row, i) => row.customer_email,
  //     sortable: false,
  //     center: true,
  //     width: "110px",
  //   },{
  //     name: "Address",
  //     selector: (row, i) => row.customer_address,
  //     sortable: false,
  //     center: true,
  //     width: "110px",
  //   },{
  //     name: "Phone",
  //     selector: (row, i) => row.customer_phone,
  //     sortable: false,
  //     center: true,
  //     width: "120px",
  //   }
    
  //   // ,{
  //   //   name: "Shipping Address",
  //   //   selector: (row, i) => row.shipping_address,
  //   //   sortable: false,
  //   //   center: true,
  //   //   width: "180px",
  //   // },{
  //   //   name: "Transaction Id",
  //   //   selector: (row, i) => row.transaction_id,
  //   //   sortable: false,
  //   //   center: true,
  //   //   width: "180px",
  //   // },{
  //   //   name: "Payment Method",
  //   //   selector: (row, i) => row.payment_method,
  //   //   sortable: false,
  //   //   center: true,
  //   //   width: "150px",
  //   // },{
  //   //   name: "Payment Status",
  //   //   selector: (row, i) => row.payment_status,
  //   //   sortable: false,
  //   //   center: true,
  //   //   width: "150px",
  //   // }
    
  // ];


  return (
    <div className="order-details-main">

    <div className="d-flex min-height-100">
    <div className="d-flex w-25 justify-content-center align-items-center">
     <img src={data.image_url} width="82" className="order-img" height="146"></img>
     </div>
     <div className="d-flex flex-column justify-content-center align-items-start">
     <h4 className="text-black card-text fs-6 fw-bolder">{data.product_name}</h4>                  
      </div>
    </div>

    <div className="order-listing-details">

      <div className="order-list-detail">
        <span >Date</span>
        <strong>{productsData[0]?.createdat}</strong>
      </div>
      <div className="order-list-detail">
        <span >Customer</span>
        <strong>{productsData[0]?.customer_name}</strong>
      </div>
      <div className="order-list-detail">
        <span >Phone</span>
        <strong>{productsData[0]?.customer_phone}</strong>
      </div>
      <div className="order-list-detail">
        <span >Email</span>
        <strong>{productsData[0]?.customer_email}</strong>
      </div>
      <div className="order-list-detail">
        <span >Address</span>
        <strong>{productsData[0]?.customer_address}</strong>
      </div>
      {/* <div className="order-list-detail">
        <span >Shipping Address</span>
        <strong>{productsData[0]?.shipping_address}</strong>
      </div> */}
      <div className="order-list-detail">
        <span >Transaction Id</span>
        <strong>{productsData[0]?.transaction_id}</strong>
      </div>
      {/* <div className="order-list-detail">
        <span >Payment Mode</span>
        <strong>{productsData[0]?.payment_method}</strong>
      </div>
      <div className="order-list-detail">
        <span >Payment Status</span>
        {productsData[0]?.payment_status &&  (
          <strong className={` ${productsData[0]?.payment_status=="succeeded"?"text-success":""} `}>{` ${productsData[0]?.payment_status==="succeeded"?"Paid":productsData[0]?.payment_status} `}</strong>
        )}
      </div> */}

    </div>


    </div>
  );
};
export default ExpandableProductDetails;
