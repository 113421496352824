import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import EditVideoAddProduct from "../Products/EditVideoAddProduct";
import { CSSTransition } from "react-transition-group";
import { isMobile } from "../../helpers/helper";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import { VideoSteps } from "../Onboarding/Video/AddVideo";
import SelectProductSelector from "../../Product/SelectProduct";
import Button from "../../Form/Button";
import { VideoPurpose } from "../../../Enums/VideoPurpose";

const PRODUCT_LIMIT = 5;

const EditSelectProductNav = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [originalVideoData, setOriginalVideoData] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [productError, setProductError] = useState("");
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(
        isMobileSize ? "mini-sidebar" : "full"
    );



    useEffect(() => {
        if (
            !history.location.state?.originalVideoData ||
            !history.location.state.addVideoData
        ) {
            history.push({ pathname: "/" });
        }

        if (history.location.state?.showSuccess) {
            setSuccessMessage(history.location.state.showSuccess);
        }

        // If video is donation
        if (
            history.location?.state?.addVideoData?.videoType === VideoPurpose.TO_COLLECT_THE_DONATION ||
            history.location?.state?.addVideoData?.videoType === VideoPurpose.TO_GENERATE_LEAD
        ) {
            navigateToCheckout()
        }

        fetchData();
       
        setOriginalVideoData(history.location.state?.originalVideoData);
    }, []);

    const navigateToCheckout = () => {
        const checkout_link = "/edit-videos/select-nav-product" + (originalVideoData?.is_external_checkout === true ? "/edit-external-checkout-nav" : "/edit-lead-checkout-nav");

        return history.push({
            pathname: checkout_link,
            state: {
                selectedProducts: selectedOption,
                addVideoData: history.location.state.addVideoData,
                originalVideoData: history.location.state.originalVideoData,
            },
        });
    }

    const moveToLeadCheckout = () => {
        if (selectedOption.length < 1) {
            return setErrorMessage("Please select products");
        }

        navigateToCheckout();
    }

    function addProduct() {
        isVisible ? setIsVisible(false) : setIsVisible(true);
    }



   

    const preSelectFromData = (data) => {

    
        const tempSelectedProducts = history.location.state?.originalVideoData?.selectedProducts;

       

            

        const selectedProducts = data.filter(x => tempSelectedProducts.map(x => x.product_id).includes(x.id)).map((dataOptions,index) => ({
            value: dataOptions.id,
            label: dataOptions.name,
            shopify_product_id: dataOptions?.shopify_product_id,
            shopify_variant_id: dataOptions?.shopify_variant_id,
            woocommerce_product_id: dataOptions?.woocommerce_product_id,
            has_shipping: dataOptions?.has_shipping,
            externalLink:tempSelectedProducts[index].external_checkout_link,
            ...dataOptions,
        }))

        setSelectedOption(selectedProducts);
    }

    const fetchData = () => {
        setLoading(true);

        const videoEditId = history?.location?.state?.addVideoData?.editVideoId;
        var token = localStorage.getItem("authSession");
        token = JSON.parse(token);

        fetch(process.env.REACT_APP_API_URL + "/user-products", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token.accessToken,
            },
            body: JSON.stringify({ id: token.idToken, videoId: videoEditId }),
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data.data)
                preSelectFromData(data.data);
            })
            .catch((error) => console.error("Error fetching data: ", error))
            .finally(() => setLoading(false));
    };

    const handleOptionChange = (event) => {
        setProductError("");

        if (event.length === 0) {
            setProductError("Please select products");
        }

        if (event) {
            const selectedProducts = event.map((dataOptions) => ({
                value: dataOptions.value,
                label: dataOptions.label,
                shopify_product_id: dataOptions?.shopify_product_id,
                shopify_variant_id: dataOptions?.shopify_variant_id,
                woocommerce_product_id: dataOptions?.woocommerce_product_id,
                has_shipping: dataOptions?.has_shipping,
                ...dataOptions,
            }));
            setSelectedOption(selectedProducts);
        }
    };

    const onNavBarClick = () => {
        setSidebarType((prevSidebarType) =>
            prevSidebarType === "full" ? "mini-sidebar" : "full"
        );
    };

    const backToMainVideoStep = () => {

        console.log("sajdsain",history.location.state)


        return history.push({
            pathname: `/videos/edit-video-purpose-nav`,
            state: history.location.state,
        });
    }

    const isButtonDisabled = productError;

    return (
        <div
            className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
            data-sidebartype={sidebarType}
            id="main-wrapper"
            data-theme="blue_theme"
            data-layout="vertical"
            data-sidebar-position="fixed"
            data-header-position="fixed"
        >
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Add Product" onNavBarClick={onNavBarClick} formWidth={true}>
                <div className="row pb-50">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                <h5 className="fw-bolder">Add Product</h5>
                                <VideoSteps editMode step="3" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body">
                                <div className={`${isVisible ? "" : "w-500"} mt-2`}>
                                    {successMessage && (
                                        <FormToast
                                            type={FORM_TOAST_TYPES.SUCCESS}
                                            message={successMessage}
                                        />
                                    )}
                                    {errorMessage && (
                                        <FormToast
                                            type={FORM_TOAST_TYPES.DANGER}
                                            message={errorMessage}
                                        />
                                    )}


                                    

                                    {/* <VideoSteps step="2" /> */}
                                    {isVisible ? (
                                        <CSSTransition
                                            in={isVisible}
                                            timeout={300}
                                            classNames="fade"
                                            unmountOnExit
                                        >
                                            <EditVideoAddProduct fetchData={fetchData} setSelectedOption={setSelectedOption} selectedOptionnew={selectedOption}  setIsVisible={setIsVisible} />
                                        </CSSTransition>
                                    ) : (
                                        <div
                                            style={{ width: isMobileSize ? "100%" : 500, margin: "0 auto" }}
                                            className="mb-4"
                                        >
                                            <SelectProductSelector
                                                selectionLimit={PRODUCT_LIMIT}
                                                selectedOptions={selectedOption}
                                                onChange={handleOptionChange}
                                                options={data}
                                                errorMessage={false}
                                                addProduct={{
                                                    label: "Add Product",
                                                    onClick: addProduct,
                                                }}
                                            />
                                        </div>
                                    )}
                                    {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                        <Button
                                            type="button"
                                            onClick={moveToLeadCheckout}
                                            buttonText="Next"
                                            fullButton={false}
                                            loading={loading}
                                            disabled={isButtonDisabled}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            {/* sticky footer */}

            {!isVisible &&
            <div className="sticky-footer ">
                <div className="footer-container">
                    <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                        <Button
                            className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                            type="button"
                            buttonText="Back"
                            onClick={backToMainVideoStep}
                        />
                        <Button
                            className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                            type="button"
                            onClick={moveToLeadCheckout}
                            buttonText="Next"
                            fullButton={false}
                            loading={loading}
                            disabled={isButtonDisabled}
                        />
                    </div>
                </div>
            </div>
            }
            {/* sticky footer */}
        </div>
    );
};
export default EditSelectProductNav;
