import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ExternalCheckoutNav from "./ExternalCheckoutNav";

const LeadCheckoutNav = () => {
    // const history = useHistory();
    // const videoType = history.location.state.addVideoData.videoType;

    // if (
    //     videoType === "To sell your product" ||
    //     videoType === "To collect the donation"
    // ) {
    //     history.push({
    //         pathname: `/videos/add-nav-video/select-nav-product/external-checkout-nav`,
    //         state: history.location.state,
    //     });
    // }

    const history = useHistory();

    useEffect(() => {
        return history.push({
            pathname: `/videos/add-nav-video/select-nav-product/external-checkout-nav`,
            state: history.location.state,
        });
    }, [])

    return <ExternalCheckoutNav />;
};
export default LeadCheckoutNav;
