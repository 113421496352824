import { useEffect, useState } from "react";
import useInputState from "../../../hooks/useInputState";
import { isEmail, isURL } from "validator";
import useMaskedInputState from "../../../hooks/useMaskedInputState";
import { base64ToString, getQueryParam, userLoggedAccessToken,validateURL } from "../../../helpers/helper";

import ActivityLog from "../../../helpers/ActivityLog";
import { useHistory } from "react-router-dom";
const DEFAULT_FONT_COLOR = "#000000";
const DEFAULT_BACKGROUND_COLOR = "#f4f4f4";
const DEFAULT_BUTTON_COLOR = "#ff317e";
const DEFAULT_BUTTON_TEXT_COLOR = "#ffffff";

export const useChangeProfile = ({ profile, fetchProfileData }) => {
  const history = useHistory();

  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [name, setName] = useMaskedInputState("", {
    regex: /[^ ]/,
    maxLength: 255
  });

  const [countryId, setCountryId] = useState(null);
  const [country, setCountry] = useState('');
  const [stateId, setStateId] = useState(null);
  const [state, setState] = useState('');
  const [cityId, setCityId] = useState(null);
  const [zipCode, setZipCode] = useState(null);

  const [business, setBusiness] = useMaskedInputState("", {
    regex: /[^ ]/,
    maxLength: 255
  });

  const [email, setEmail] = useInputState("");
  const [bio, setBio] = useMaskedInputState("", {
    regex: /[^ ]/,
    maxLength: 4000
  });
  const [supportEmail, setSupportEmail] = useMaskedInputState("", {
    regex: /[^ ]/,
    maxLength: 255
  });
  const [termsOfServiceUrl, setTermsOfServiceUrl] = useMaskedInputState("", {
    maxLength: 500
  });
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useMaskedInputState("", {
    maxLength: 500
  });
  const [refundPolicyUrl, setRefundPolicyUrl] = useMaskedInputState("", {
    maxLength: 500
  });
  const [website, setWebsite] = useInputState("");
  const [image, setImage] = useState("");
  const [isPublicProfile, setIsPublicProfile] = useState();
  // const [imageFile, setImageFile] = useState("");
  // const [savedPicture, setSavedPicture] = useState("");

  const [brandName, setBrandName] = useMaskedInputState("", {
    regex: /[^ ]/,
    maxLength: 255
  });
  const [brandDescription, setBrandDescription] = useMaskedInputState("", {
    maxLength: 2000
  });
  const [brandWebsite, setBrandWebsite] = useMaskedInputState("", {
    maxLength: 500
  });
  const [brandCurrency, setBrandCurrency] = useMaskedInputState("USD", {
    maxLength: 10
  });

  const [fontColor, setFontColor] = useMaskedInputState(DEFAULT_FONT_COLOR, {
    maxLength: 50
  });
  const [backgroundColor, setBackgroundColor] = useMaskedInputState(DEFAULT_BACKGROUND_COLOR, {
    maxLength: 50
  });
  const [buttonColor, setButtonColor] = useMaskedInputState(DEFAULT_BUTTON_COLOR, {
    maxLength: 50
  });
  const [buttonTextColor, setButtonTextColor] = useMaskedInputState(DEFAULT_BUTTON_TEXT_COLOR, {
    maxLength: 50
  });

  const [brandImage, setBrandImage] = useState("");
  // const [brandImageFile, setBrandImageFile] = useState("");
  const [savedBrandImage, setSavedBrandImage] = useState("");

  const [statementDescriptor, setStatementDescriptor] = useMaskedInputState(
    "",
    {
      regex: /^[a-zA-Z0-9@^$.\-#+~_\b ]+$/,
      maxLength: 14,
      convertToLowerCase: false
    }
  );
  const [errorField, setErrorField] = useState("");
  // const { loadAuthSession } = useAuthState();

  // const [updateProfileInfo] = useMutation(UpdateAccountInfo, {
  //   refetchQueries: [{ query: GetProfile }]
  // });

  const onImageChange = (file) => {
    setImage(file);

    // const jsFile = new File(file, "profile_image");
    // setImageFile(jsFile);
    // const dataReader = new FileReader();
    // dataReader.onload = (event) => {
    //   setImage(event.target.result);
    // };
    // dataReader.readAsDataURL(jsFile);
  };

  const saveChanges = async () => {
    setIsSaving(false);
    setError(undefined);
    setErrorField(null);

    try {
      let picture = undefined;


      if (!name) {
        setErrorField("name");
        setIsSaving(false);
      } else if (!isEmail(email)) {
        setErrorField("email");
        setIsSaving(false);
      } else if (!bio) {
        setErrorField("bio");
        setIsSaving(false);
      } else if (!website && !validateURL(website)) {
        setErrorField("website");
        setIsSaving(false);
      } else if (supportEmail?.length>=1 && !isEmail(supportEmail)) {
        setErrorField("supportEmail");
        setIsSaving(false);
      } else if (termsOfServiceUrl?.length>=1 && !validateURL(termsOfServiceUrl)) {
        setErrorField("termsOfServiceUrl");
        setIsSaving(false);
      } else if (privacyPolicyUrl?.length>=1 && !validateURL(privacyPolicyUrl)) {
        setErrorField("privacyPolicyUrl");
        setIsSaving(false);
      } else if (refundPolicyUrl?.length>=1 && !validateURL(refundPolicyUrl)) {
        setErrorField("refundPolicyUrl");
        setIsSaving(false);
      } else if (!business) {
        setErrorField("business");
        setIsSaving(false);
      } else if (!countryId) {
        setErrorField("country_id");
        setIsSaving(false);
      } else if (!stateId) {
        setErrorField("state_id");
        setIsSaving(false);
      } else if (!cityId) {
        setErrorField("city_id");
        setIsSaving(false);
      } else if (!zipCode) {
        setErrorField("zip_code");
        setIsSaving(false);
      } else {

        picture = image;

        // picture = url;

        // if (savedPicture !== image) {
        //   const { signedUrl, url } = await UploadHelper.getSignedUrl(
        //     UploadHelper.AVATAR
        //   );
        //   await UploadHelper.uploadToS3(signedUrl, imageFile);
        //   picture = url;
        // }


        setErrorField("");
        setIsSaving(false);
        const updateAccountArgs = {
          picture,
          name,
          email,
          bio,
          refundPolicyUrl,
          privacyPolicyUrl,
          termsOfServiceUrl,
          website,
          supportEmail,
          statementDescriptor,
          isPublicProfile,
          business,
          country_id: countryId,
          state_id: stateId,
          city_id: cityId,
          zip_code: zipCode,
          brand_currency: brandCurrency
          // ...(!isNmiAccount && { statementDescriptor })
        };

        setIsSaving(true);
        fetch(process.env.REACT_APP_API_URL + '/update-user-account', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + userLoggedAccessToken(),
          },
          body: JSON.stringify(updateAccountArgs), // Replace with your POST data
        })
          .then((response) => response.json())
          .then((data) => {
            setIsSaving(false);
            if (data?.status === false) {
              setError(data?.message);
              setSuccess('');

            } else if (data?.status === true) {
              setSuccess(data?.message);
              setError('');

              fetchProfileData();

              let token = localStorage.getItem("authSession")
              token = JSON.parse(token);

              const userLog = new ActivityLog();
              const admin_login_id = localStorage.getItem('admin_login_id');
              var user_id = admin_login_id ? admin_login_id : token.idToken;
              var is_admin = admin_login_id ? 1 : 0;
              var event_id = 13;
              var account_id = token.idToken;
              // const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })
              userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })




            }

            const redirectBackTo = getQueryParam("redirect")
            if (redirectBackTo) {
              history.replace(base64ToString(redirectBackTo))
            }

          })
          .catch((error) => {
            setSuccess('');
            setError('Something went wrong');
            setIsSaving(false);
            console.error('Error fetching data:', error);
          }).finally((error) => {
            setIsSaving(false);
          });




        // const isNmiAccount = profile && profile.isNmiAccount;
        // const updateAccountRes = await updateProfileInfo({
        //   variables: {
        //     account: {
        //       picture,
        //       name,
        //       bio,
        //       refundPolicyUrl,
        //       privacyPolicyUrl,
        //       termsOfServiceUrl,
        //       website,
        //       supportEmail,
        //       ...(!isNmiAccount && { statementDescriptor })
        //     }
        //   }
        // });

        // console.log('updateAccountRes!!',updateAccountRes);

        if (statementDescriptor) {
          // const hasAddedStatementDescriptor = getHasCompletedTutorial(
          //   "Add Statement Descriptor"
          // );

          // if (!hasAddedStatementDescriptor) {
          //   await completeTutorial({
          //     variables: {
          //       name: "Add Statement Descriptor",
          //       version: 1
          //     }
          //   });
          // }
        }

        if (
          refundPolicyUrl &&
          privacyPolicyUrl &&
          termsOfServiceUrl &&
          supportEmail
        ) {
          // const hasAddedCustomerSupportInfo = getHasCompletedTutorial(
          //   "Add Customer Support Info"
          // );

          // if (!hasAddedCustomerSupportInfo) {
          //   await completeTutorial({
          //     variables: {
          //       name: "Add Customer Support Info",
          //       version: 1
          //     }
          //   });

          //   await tagAccount("Customer Support Info Provided");
          // }
        }

        // DefaultToaster.show({
        //   message: "Profile saved!",
        //   icon: "tick",
        //   intent: Intent.SUCCESS
        // });
      }
    } catch (err) {

      setError("An error occurred while updating record.");
    }
  };

  const onBrandImageChange = (fileValue) => {
    setBrandImage(fileValue);

    // const jsFile = new File(file, "brand_image");
    // setBrandImageFile(jsFile);
    // const dataReader = new FileReader();
    // dataReader.onload = (event) => {
    //   setBrandImage(event.target.result);
    // };
    // dataReader.readAsDataURL(jsFile);
  };

  const onThemeSaveChanges = async () => {
    setIsSaving(false);
    setError(undefined);
    setErrorField(undefined);

    try {

      setIsSaving(true);
      fetch(process.env.REACT_APP_API_URL + '/update-theme', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userLoggedAccessToken(),
        },
        body: JSON.stringify({
          "font_color" : fontColor,
          "background_color" : backgroundColor,
          "button_color" : buttonColor,
          "button_text_color" : buttonTextColor,
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setSuccess(response?.message)
          setError('');
        }).catch((err) => {
          setSuccess('');
          setError('Something went wrong');
        }).finally(() => {
          setIsSaving(false);
        })

    } catch(err){
      setError("An error occurred while updating record.");
    }
  }

  const onBrandSaveChanges = async () => {
    setIsSaving(false);
    setError(undefined);
    setErrorField(undefined);

    try {
      let picture = undefined;

      if (!brandName) {
        setErrorField("brandName");
        return;
      }

      if (!brandDescription) {
        setErrorField("brandDescription");
        return;
      }

      if (!brandWebsite || !isURL(brandWebsite)) {
        setErrorField("website");
        return;
      }

      if (!brandCurrency) {
        setErrorField("brandCurrency");
        return;
      }

      // Handle Brand Logo
      if (savedBrandImage !== brandImage) {
          picture = brandImage;
        // const { signedUrl, url } = await UploadHelper.getSignedUrl(
        //   UploadHelper.BRAND_IMAGE
        // );
        // await UploadHelper.uploadToS3(signedUrl, brandImageFile);
        // picture = url;
      }

      setIsSaving(true);
      fetch(process.env.REACT_APP_API_URL + '/update-brand-info', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userLoggedAccessToken(),
        },
        body: JSON.stringify({
          "brand_name": brandName,
          "brand_description": brandDescription,
          "brand_domain_url": brandWebsite,
          "brand_currency": brandCurrency,
          "brand_picture": picture
        }),
      })
        .then((response) => response.json())
        .then((response) => {
          setSuccess(response?.message)
          setError('');
        }).catch((err) => {
          setSuccess('');
          setError('Something went wrong');
        }).finally(() => {
          setIsSaving(false);
        })

    } catch (err) {
      console.log("picturepicture", err)
      setError("An error occurred while updating record.");
    }
  }

  const clearAllWarnings = () => {
    setError('');
    setSuccess('');
    setErrorField('');
  }

  useEffect(() => {
    if (profile && profile.length !== 0) {
      setName(profile.name);
      setBio(profile.bio);
      setEmail(profile.email);
      setImage(profile.picture);
      setWebsite(profile.website);
      setSupportEmail(profile.supportEmail);
      setPrivacyPolicyUrl(profile.policies.privacyPolicyUrl);
      setRefundPolicyUrl(profile.policies.refundPolicyUrl);
      setTermsOfServiceUrl(profile.policies.termsOfServiceUrl);
      // setSavedPicture(profile.picture);
      setStatementDescriptor(profile.statementDescriptor);

      setBrandName(profile.brandName)
      setBrandDescription(profile.brandDescription)
      setBrandWebsite(profile.brandDomainUrl)
      setBrandCurrency(profile.brandCurrency)
      setFontColor(profile?.fontColor ?? DEFAULT_FONT_COLOR)
      setBackgroundColor(profile?.backgroundColor ?? DEFAULT_BACKGROUND_COLOR)
      setButtonColor(profile?.buttonColor ?? DEFAULT_BUTTON_COLOR)
      setButtonTextColor(profile?.buttonTextColor ?? DEFAULT_BUTTON_TEXT_COLOR)
      setBrandImage(profile.brandPicture)
      setSavedBrandImage(profile.brandPicture)
      setIsPublicProfile(profile.isPublicProfile)
      setBusiness(profile.business);
      setCountryId(profile.country_id)
      setCountry(profile.country)
      setStateId(profile.state_id)
      setState(profile.state)
      setCityId(profile.city_id)
      setZipCode(profile.zip_code)
    }
  }, [profile]);

  return {
    error,
    name,
    email,
    bio,
    supportEmail,
    termsOfServiceUrl,
    refundPolicyUrl,
    privacyPolicyUrl,
    website,
    image,
    statementDescriptor,
    brandName,
    brandDescription,
    brandWebsite,
    brandCurrency,
    fontColor,
    backgroundColor,
    buttonColor,
    buttonTextColor,
    brandImage,
    onImageChange,
    setName,
    setEmail,
    setBio,
    isPublicProfile,
    setSupportEmail,
    setTermsOfServiceUrl,
    setPrivacyPolicyUrl,
    setRefundPolicyUrl,
    setIsPublicProfile,
    setWebsite,
    setStatementDescriptor,
    setBrandName,
    setBrandDescription,
    setBrandWebsite,
    setBrandCurrency,
    setFontColor,
    setBackgroundColor,
    setButtonColor,
    setButtonTextColor,
    onBrandImageChange,
    errorField,
    isSaving,
    saveChanges,
    onBrandSaveChanges,
    onThemeSaveChanges,
    success,
    clearAllWarnings,
    business,
    setBusiness,
    countryId,
    setCountryId,
    country,
    setCountry,
    stateId,
    setStateId,
    state,
    setState,
    cityId,
    setCityId,
    zipCode,
    setZipCode
  };
};
