import ThunderImg from "../../../../src/assets/icons/thunder.svg";
import { useHistory } from "react-router-dom";

const SubscriptionWarning = (props) => {
    const history = useHistory();
    const title = props.title;
    return (
        <div className="subscription-warning">
            <div className="subscription-warning-icon">
                <img src={ThunderImg} alt="Upgrade your plan to unlock advanced features" height="50" width="50" />
            </div>
            <div className="subscription-warning-content">
                <h5 className="fw-bolder">{title}</h5>
                <span className="subscription-action" onClick={() => history.push("/settings/subscription/upgrade-plan")}>See plan</span>
            </div>
        </div>
    )
}

export default SubscriptionWarning;