import React, { useState } from "react";
import { Page } from "../../layouts/Page";
import { OrderPageHeader, OrderList } from "../../molecules/AddFormList";


// import {
//   useOrderList,
//   useExportOrders,
//   useFilters,
//   useUpdateOrderStatus
// } from "./hooks";
import { TABLE_COLUMNS } from "./constants";
import { Sidebar } from "../../../component/molecules/Sidebar";
import { useHistory } from "react-router-dom";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";

function Orders() {
  const isMobileSize = isMobile();
  const history = useHistory();
  // const { filters, filterOptions, setFilters, isFiltersLoading } = useFilters();
  const [sort, setSort] = useState({ field: "id", direction: "desc" });
  const [selectedTab, setSelectedTab] = useState("orders");

  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');

  function onSortPress(field, direction) {
    setSort({ field, direction });
  }

  function onAddOrderPress() {
    history.push("/add-lead");
  }

  const onNavBarClick = () => {
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }


  return (
     <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">

      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title="Lead Form" onNavBarClick={onNavBarClick} flex>
        <BreadCrump
          title="Lead Management"
          image={dashboardbreadcrumimage}
          breadCrumpDetail={[
            { name: "Dashboard", link: "/" },
            { name: "Lead Form", link: "" }
          ]}
          tagLine={showUserTypeBaseMessage({'Brand': 'Entice Viewers and Drive Leads, Simplify the Shopping Journey', 'default':''})}
        />
        <div class="card card-body">
          <OrderPageHeader
            // filters={filters}
            // filterOptions={filterOptions}
            // setFilters={setFilters}
            onAddOrderPress={onAddOrderPress}
          />
          <OrderList
            columns={TABLE_COLUMNS}
            onSortPress={onSortPress}
            sort={sort}
          />
        </div>
      </Page>
    </div>
  );
}

export default Orders;
