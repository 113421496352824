import React, { useEffect, useState } from "react";

// import { useHistory  } from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";
import "./StylingProductList.module.scss";
import ActivityLog from "../../helpers/ActivityLog";
// import * as helper from "../../helpers/helper";
import ExpandableProductDetails from "./ExpandableProductDetails";
// import VideoPageHeader from "../../../component/molecules/VideoList/VideoPageHeader";
import ModalContainer from "../../Table/ModalContainer";
import EditOrder from "../../Pages/OrdersReport/EditOrder";
import placeholder from "../../../assets/thumbnail.jpg";
import { truncateText, htmlToPlainText } from "../../helpers/helper";
import Table from "../../Table/Table";
import AddDataPrompt from "../../molecules/DataPrompt/AddingDataPrompt";

function OrdersReportList({
  // products,
  // isLoading,
  // onProductRowClick,
  // // columns,
  // onAddProductClick,
  // isProductListScrollLoading,
  // searchText,
  // onUploadCSVClick,
  // onImportShopify
}) {

  // const isMobileSize = isMobile();
  // const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  // const [isExpand, setIsExpand] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [expandViewType, setExpandViewType] = useState();
  const [startdate, setStartdate] = useState('');
  const [enddate, setEnddate] = useState('');
  const [orderId, setOrderId] = useState('');
  // const isTabletDevice = helper.isTabletDevice();
  const [openModal, setOpenModal] = useState(false);
  // const [openDropdowns, setOpenDropdowns] = useState({});



  useEffect(() => {
    fetchVideosData();

  }, [currentPage, rowsperPage, searchValue]);



  // useEffect(() => {

  //   const handleDocumentClick = (event) => {
  //     const target = event.target;
  //     if (!target.closest('.dropdown')) {
  //       setOpenDropdowns(null);
  //     }
  //   };

  //   // Add event listener when component mounts
  //   document.addEventListener('click', handleDocumentClick);

  //   // Cleanup event listener when component unmounts
  //   return () => {
  //     document.removeEventListener('click', handleDocumentClick);
  //   };


  // }, []);


  const fetchVideosData = (isClear) => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/all-orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({
        "id": token.idToken, "currentPage": currentPage, "rowsPage": rowsperPage, "search": searchValue, "enddate": isClear ? "" : enddate,
        "startdate": isClear ? "" : startdate, "orderId": isClear ? "" : orderId, time_offset: new Date().getTimezoneOffset()
      }),
    })
      .then((response) => response.json())
      .then((data) => {

        setVideotdata(data.data)
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(true)

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchDeleteData = (id) => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/delete-order', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.code?.toString() === "200") {
          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem('admin_login_id');
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 10;
          var account_id = token.idToken;
          userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })
          setSuccess("Product deleted succesfully")
          setSelectedOrderId(null);
          fetchVideosData()
          setLoader(true)
          setTimeout(resetState, 1000);
        } else {
          setErrors("Something went wrong")
          setTimeout(resetState, 1000);
        }


      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setErrors("Something went wrong")
        setTimeout(resetState, 1000);
      });
  };

  const resetState = () => {
    setErrors('');
    setSuccess('');
  };


  const Popup = ({ onClose, onDelete }) => {
    return (

      <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block", " padding-left": "0px" }} aria-modal="true" role="dialog">

        <div class="modal-dialog modal-sm" >
          <div class="modal-content modal-filled bg-light-danger">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7" onClick={onClose}></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">
                  You Want to delete Permanently?
                </p>
                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    fetchVideosData();
    setLoader(false)

  };


  const handleSubmitClear = (e) => {
    setLoader(false)
    setCurrentPage(1);
    setrRowsperPage(10);
    setSearchValue('');
    setStartdate('');
    setEnddate('');
    setOrderId('');
    fetchVideosData(true);

  };

  const handleSubmitExport = (e) => {
    const url = process.env.REACT_APP_API_URL;
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    const user_id = token.idToken;
    const newPath = `${url}/download?id=${user_id}&enddate=${enddate}&startdate=${startdate}&orderId=${orderId}&time_offset=${new Date().getTimezoneOffset()}`

    window.location.href = newPath

  };




  function setstartDateValue(value) {
    setStartdate(value);
  }

  function setendDateValue(value) {
    setEnddate(value);
  }





  const handleEditClick = (id, expandType) => {


    setCurrentRow(id);
    setExpandViewType(expandType);
    setOpenModal(true);

  };

  const handleDeleteClick = (id) => {

    console.log("shjkhkhkhkhkhkhk")


    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId)
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  // const handleClick = (id) => {

  //   history.push("/edit-order/" + id)

  // };


  const columns = [
    {
      name: "Product",
      selector: (row) => row.product_name, // Use product_name for sorting
      sortable: true,
      width: "200px",
      cell: (row) => {
        const imageSrc = row.image_url ? row.image_url : placeholder;

        return (
          // <ul className="hstack mb-0 cursor-pointer">
          //   <li className="d-flex flex-row align-items-center gap-2">
          //     <a href="javascript:void(0)" className="me-1">
          //       <img src={imageSrc} className="rounded-2 border border-2 border-white" width={51} height={51}/>
          //     </a>
          //     <div className="cursor-pointer line-height-1 fw-medium ps-sm-10" title={htmlToPlainText(row.product_name)}>
          //       {truncateText(row.product_name)}
          //     </div>
          //   </li>
          // </ul>

          <div className="d-flex flex-row align-items-center gap-2">
          <img
            src={imageSrc}
            className="rounded-2 border border-2 border-white"
            width={38}
            height={51}
            alt={row.product_name}
          />
          <div className="d-flex flex-column gap-1">
            <span className="fw-medium fs-4 ">{htmlToPlainText(row.product_name)}</span>
          </div>
        </div>
        );
      },
    },
    {
      name: "Video",
      selector: (row) => row.video_name,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: "Payment Mode",
      selector: (row) => row.payment_mode,
      sortable: true,
      center: true,
      width: "180px",
    },
    {
      name: "Order Number",
      selector: (row, i) => row.order_number,
      sortable: true,
      width: "160px"
    },
    {
      name: "Status",
      selector: (row, i) => row.status,
      sortable: true,
      center: true,
      width: "100px"
    },{
      name: "Date",
      selector: (row) => row.createdat,
      sortable: true,
      center: true,
      width: "150px",
    }
  ];


  const data = videodata;
  // const tableData = {
  //   columns,
  //   data
  // };

  const handlePageChange = (page) => {

    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    setCurrentPage(page);
    setrRowsperPage(newPerPage)

  };

  // const handleSearchChange = (event) => {

  //   setSearchValue(event.target.value);
  //   setCurrentPage(1);
  //   setLoader(false)

  // };

  const closeFromChild = () => {
    setCurrentRow(null);
  }

  // const isButtonDisabled = !startdate && !enddate && !orderId;

  const handleOpenModal = (row, expandType) => {

    setCurrentRow(row);
    setExpandViewType(expandType);
    setOpenModal(true);
  }

  const handleCloseModal = (data) => {
    setOpenModal(false);
  }


  const orderDetailsPopupMargin = window.innerHeight === 600 ? "10px auto" : "100px auto";

  // if (loader && videodata && !videodata.length && !startdate && !enddate && !orderId) {

  //   // if (addprompt === true) {
  //     return (
  //       <div className="data-promptWrapper">
  //         <AddDataPrompt   
  //             heading="Create Videos" 
  //             title="Create Videos to collect customers orders" 
  //             link="videos/add-nav-video" 
  //             btntxt="Create Video"/>
  //       </div>
  //     );
  //   // }

  // }


  return (
    <>

      <div className="App order-report-table video-table">

        {showPopup ?
          <Popup
            onClose={handleClosePopup}
            onDelete={handleDelete}
          /> : ""}

        {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
        {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""}

        <div id="complex_header_filter" class="dataTables_filter  data-table-extensions-filter">
          <form className="validation-wizard wizard-circle wizard clearfix order-wizard-content">

            <div className="order-buttons-actions-area">


              <div className="mbl-view-display">

                <div className="btn-groups">


                  <div className="form-group">
                    <input
                      className="form-control ml-0 order-id-field"
                      type="text"
                      id="orderId"
                      name="name"
                      placeholder="Order ID"
                      value={orderId}
                      onChange={(e) => setOrderId(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control order-date-field"
                      type="datetime-local"
                      placeholder="Start Date"
                      id="startDate"
                      value={startdate}
                      onChange={(e) => setstartDateValue(e.target.value)}
                    />
                  </div>

                  <div className="form-group">
                    <input
                      className="form-control order-date-field"
                      type="datetime-local"
                      id="endDate"
                      value={enddate}
                      onChange={(e) => setendDateValue(e.target.value)}
                    />
                  </div>


                </div>

                <div className="order-search-clear-btns">
                  <div>

                    <button type="button" onClick={handleSubmit} className="btn btn-primary text-white">
                      <div><i className="ti ti-search me-1 fs-4"></i>Search</div>
                    </button>

                    <button type="button" onClick={handleSubmitClear} disabled="" className="btn btn-primary text-white">
                      <div><i className="ti ti-circle-x me-1 fs-4"></i>Clear</div>
                    </button>
                  </div>

                  <div>
                    <button type="button" onClick={handleSubmitExport} className="btn btn-outline-primary order-export-btn">
                      <div><i className="ti ti-upload me-1 fs-4"></i>Export</div>
                    </button>

                  </div>




                </div>

              </div>
            </div>
          </form>

          <br />
          <br />

        </div>


        <div className="overflow-auto">
          <Table
            loading={!loader}
            columns={columns}
            data={data}
            totalRows={totalRows}
            currentPage={currentPage}
            currentRow={currentRow}
            handlePerRowsChange={handlePerRowsChange}
            handlePageChange={handlePageChange}
            onRowClicked={(row) => handleOpenModal(row, 1)}
            actions={[
              (row) => ({
                disabled: false,
                icon: (<i className="fs-4 ti ti-edit"></i>),
                label: "Edit",
                title: "Edit",
                onClick: (row) => handleEditClick(row?.id, 2)
              }), (row) => ({
                disabled: false,
                icon: (<i className="fs-4 ti ti-trash"></i>),
                label: "Delete",
                title: "Delete",
                onClick: (row) => handleDeleteClick(row?.id)
              })
            ]}
          />

          <ModalContainer
            width="460"
            title={expandViewType === 2 ? "Edit Order" : ""}
            margin={orderDetailsPopupMargin}
            // margin="10px auto"
            children={expandViewType === 2 ?
              // <ExpandableProductDetails data={currentRow} closeFromChild={closeFromChild} /> :<EditOrder data={currentRow} /> 
              <EditOrder ordata={currentRow} /> : <ExpandableProductDetails data={currentRow} closeFromChild={closeFromChild} />
            }
            isOpen={openModal}
            closable={false}
            handleCloseModal={(e) => handleCloseModal(e)}
          />

        </div>








      </div>
    </>

  );
}

export default OrdersReportList;
