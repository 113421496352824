import React from "react";

const WhiteSquareButton = ({ icon, label, active, onClick, styles, disabled = false }) => {
    return (
        <div className={`w-sq-btn gap-2 ${active === true ? 'w-sq-btn-active' : ''} ${disabled === true ? 'w-sq-btn-disabled' : ''} `} style={styles} onClick={() => {
            if (disabled) return

            onClick();
        }}>
            <span className="w-sq-btn-icon">{icon}</span>
            <span className="w-sq-btn-label">{label}</span>
        </div>
    );
}

export default WhiteSquareButton;
