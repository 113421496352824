import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import useAuthState from "../hooks/useAuthState";
import pathToPageName from "../helpers/pathToPageName";
import { Login } from "../Pages/Login";
import { OnboardingProfileInfo, AddVideo} from "../Pages/Onboarding/Index";
// import ImportProduct from "../Pages/Onboarding/Product/ImportProduct";
import SelectProduct from "../Pages/Onboarding/Product/SelectProduct";
import LeadCheckout from "../Pages/Onboarding/Checkout/LeadCheckout";
import ExternalCheckout from "../Pages/Onboarding/Checkout/ExternalCheckout";
import SuccessCheckout from "../Pages/Onboarding/Checkout/SuccessCheckout";
import { ForgotPassword } from "../Pages/ForgotPassword/Index";
import ProtectedRoute from "./ProtectedRoute";
import { Orders } from "../Pages/Orders/Index";
import { Videos } from "../Pages/Videos/Index";
import { Dashboard } from "../Pages/Dashboard/Index";
import OTPVerification from "../Pages/OTP/OTPVerification";
import { AddProduct } from "../Pages/Onboarding/Product";
import Packages from "../Pages/Packages/Packages";
import IncompleteDashboard from "../Pages/Dashboard/IncompleteDashboard";
// import StatsDashboard from "../Pages/Dashboard/StatsDashboard";
import { Products } from "../Pages/Products/Index";
import AddLead from "../Pages/Lead/AddLead";
import ResetPassword from "../Pages/ForgotPassword/ResetPassword";
import AddProductNav from "../Pages/Products/AddProductNav";
import ImportProductNav from "../Pages/Products/ImportProductNav";
import AddVideoNav from "../Pages/Videos/AddVideoNav";
import EditVideoNav from "../Pages/Videos/EditVideoNav";
import SelectProductNav from "../Pages/Videos/SelectProductNav";
import EditSelectProductNav from "../Pages/Videos/EditSelectProductNav";
import LeadCheckoutNav from "../Pages/Videos/LeadCheckoutNav";
import EditLeadCheckoutNav from "../Pages/Videos/EditLeadCheckoutNav";
import ExternalCheckoutNav from "../Pages/Videos/ExternalCheckoutNav";
import EditExternalCheckoutNav from "../Pages/Videos/EditExternalCheckoutNav";
import ImportShopifyProductNav from "../Pages/Products/ImportShopifyProductNav";
import AddShopifyKeyNav from "../Pages/Products/AddShopifyKeyNav";
import ShopifyProductSyncNav from "../Pages/Products/ShopifyProductSyncNav";
import ShopifySetup from "../Pages/Products/ShopifySetup";
import SuccessCheckoutNav from "../Pages/Videos/SuccessCheckoutNav";
import SuccessUpdateCheckoutNav from "../Pages/Videos/SuccessUpdateCheckoutNav";
import EditProductNav from "../Pages/Products/EditProductNav";
import AddVideoWidget from "../Pages/Onboarding/Video/addVideoWidget";
import IntegrationsOnWeb from "../Pages/IntegrationOnWeb/IntegrationsOnWeb";
import Invoice from "../Pages/Settings/BillingInformationSettings/Invoice";
import BillingInformationSettingsNew from "../Pages/Settings/BillingInformationSettings/BillingInformationSettingsNew";
import OTPDirectVerification from "../Pages/OTP/OTPDirectVerification";
import DirectUnsibscribe from "../Pages/Packages/DirectUnsibscribe";
import SelectAccountType from "../Pages/Settings/SelectAccountType";
import WoocommerceSetup from "../Pages/Products/WoocommerceSetup";
import ImportWoocommerceProductNav from "../Pages/Products/ImportWoocommerceProductNav";
import WoocommerceProductSyncNav from "../Pages/Products/WoocommerceProductSyncNav";
import AddonPackagesBox from "../Pages/Settings/BillingInformationSettings/AddonPackagesBox";
import { LeadForm } from "../Pages/LeadForm/Index";
import AddLeadForm from "../Pages/Lead/AddLeadForm";
import StartSteamLive from "../Pages/Videos/Streaming/StartSteamLive";
import NewAddLead from "../Pages/Lead/NewAddLead";
import NewEditLead from "../Pages/Lead/NewEditLead";
import SocketIO from "../Pages/Videos/Streaming/WebRTCComponent";
import ShortUrl from "../Pages/Videos/ShortUrl";
import { OrdersReport } from "../Pages/OrdersReport/Index";
import EditOrder from "../Pages/OrdersReport/EditOrder";
import InVideoCheckoutMain from "../Pages/Settings/ProfileSettings/InVideoCheckout/InVideoCheckoutMain";
import InviteFriends from "../Pages/Settings/InviteFriends/InviteFriends.js";
import Integration from "../Pages/Products/Integration";
import AccountSetting from "../Pages/Settings/AccountSetting.js";
import EditLeadForm from "../Pages/Lead/EditLeadForm";
import MultiLeadFormReport from "../Pages/LeadFormReport/MultiLeadFormReport";
import AddLeadFormMain from "../Pages/Lead/AddLeadFormMain";
import Donations from "../Pages/Donations/Index";
import AddVideoLeadNav from "../Pages/Onboarding/Video/AddVideoLeadNav";
import EditVideoLeadNav from "../Pages/Onboarding/Video/EditVideoLeadNav";
import VideoPurpose from "../Pages/Onboarding/Video/VideoPurpose.js";
import VideoPurposeNav from "../Pages/Onboarding/Video/VideoPurposeNav.js";
import EditVideoPurposeNav from "../Pages/Onboarding/Video/EditVideoPurposeNav.js";
import LeadAllFormListReport from "../Pages/LeadAllFormListReport/LeadAllFormListReport";
import ImportShopifyProduct from "../Pages/Onboarding/ImportShopifyProduct.js";
import ImportWoocommerceOnboardProduct from "../Pages/Onboarding/ImportWoocommerceProduct.js";
import AddingKeys from "../Pages/Onboarding/AddingKeys";
import WoocommerceAddingKeys from "../Pages/Onboarding/WoocommerceAddingKeys";
import CustomerInfo from "../Pages/CustomerInfo/CustomerInfo.js";
import AddLeadFormOnboarding from "../Pages/Lead/AddLeadFormOnboarding.js";





function RoutesApp() {
  const { isLoggedIn, user } = useAuthState();
  // const { isNmiAccount } = useTutorialsState();

  const bootIntercom = () => {
    return;
    // if (isLoggedIn) {
    //   window.Intercom("boot", {
    //     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    //     user_id: user.sub,
    //     email: user.email,
    //     name: user.name
    //   });
    // } else {
    //   window.Intercom("boot", {
    //     app_id: process.env.REACT_APP_INTERCOM_APP_ID
    //   });
    // }
    // window.Intercom("show");
  };

  return (

      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        {/* <ProtectedRoute
          path="/products/:id/confirmation-email"
          bootIntercom={bootIntercom}
          component={ConfirmationEmail}
        /> */}

      



        <ProtectedRoute
          path="/add-video-lead"
          bootIntercom={bootIntercom}
          component={AddLeadFormOnboarding}
        />

        <ProtectedRoute
          path="/videos/add-nav-video/add-video-lead-nav"
          bootIntercom={bootIntercom}
          component={AddVideoLeadNav}
        />

         <ProtectedRoute
          path="/videos/edit-video-nav/edit-video-lead-nav"
          bootIntercom={bootIntercom}
          component={EditVideoLeadNav}
        />


        <ProtectedRoute
          path="/add-video"
          bootIntercom={bootIntercom}
          component={AddVideo}
        />

        <ProtectedRoute
          path="/video-purpose"
          bootIntercom={bootIntercom}
          component={VideoPurpose}
        />

       <ProtectedRoute
          path="/videos/video-purpose-nav"
          bootIntercom={bootIntercom}
          component={VideoPurposeNav}
        />


       <ProtectedRoute
          path="/videos/edit-video-purpose-nav"
          bootIntercom={bootIntercom}
          component={EditVideoPurposeNav}
        />
        
        <ProtectedRoute
          path="/add-product"
          bootIntercom={bootIntercom}
          component={AddProduct}
        />

        <ProtectedRoute
          path="/products/add-nav-product"
          bootIntercom={bootIntercom}
          component={AddProductNav}
        />

        <ProtectedRoute
          path="/products/edit-nav-product"
          bootIntercom={bootIntercom}
          component={EditProductNav}
        />

        <ProtectedRoute
          path={["/videos/edit-video-nav/:videoId", "/videos/edit-video-nav"]}
          bootIntercom={bootIntercom}
          component={EditVideoNav}
        />

        <ProtectedRoute
          path="/edit-videos/select-nav-product"
          exact
          bootIntercom={bootIntercom}
          component={EditSelectProductNav}
        />

        <ProtectedRoute
          path="/import-product"
          bootIntercom={bootIntercom}
          component={ImportShopifyProduct}
        />

        <ProtectedRoute
          path="/adding-keys"
          bootIntercom={bootIntercom}
          component={AddingKeys}
        />

        <ProtectedRoute
          path="/woocommerce-adding-keys"
          bootIntercom={bootIntercom}
          component={WoocommerceAddingKeys}
        />

        <ProtectedRoute
          path="/import-woocommerce-product"
          bootIntercom={bootIntercom}
          component={ImportWoocommerceOnboardProduct}
        />

        <ProtectedRoute
          path="/import-nav-product"
          bootIntercom={bootIntercom}
          component={ImportProductNav}
        />

        <ProtectedRoute
          path="/settings/import-shopify-product"
          bootIntercom={bootIntercom}
          component={ImportShopifyProductNav}
        />

        <ProtectedRoute
          path="/settings/shopify-setup"
          bootIntercom={bootIntercom}
          component={ShopifySetup}
        />

        <ProtectedRoute
          path="/select-account-type"
          bootIntercom={bootIntercom}
          component={SelectAccountType}
        />

        <ProtectedRoute
          path="/settings/billing/invoice"
          bootIntercom={bootIntercom}
          component={Invoice}
        />

        <ProtectedRoute
          path="/shopify-product-sync"
          bootIntercom={bootIntercom}
          component={ShopifyProductSyncNav}
        />

        <ProtectedRoute
          path="/woocommerce-product-sync"
          bootIntercom={bootIntercom}
          component={WoocommerceProductSyncNav}
        />

        <ProtectedRoute
          path="/add-shopify-keys"
          bootIntercom={bootIntercom}
          component={AddShopifyKeyNav}
        />

        <ProtectedRoute
          path="/select-product"
          bootIntercom={bootIntercom}
          component={SelectProduct}
        />
        
        <ProtectedRoute
          path="/lead-checkout"
          bootIntercom={bootIntercom}
          component={LeadCheckout}
        />

        <ProtectedRoute
          path="/test-peer-streaming"
          bootIntercom={bootIntercom}
          component={SocketIO}
        />

        <ProtectedRoute
          path="/videos/add-nav-video/select-nav-product/lead-checkout-nav"
          exact
          bootIntercom={bootIntercom}
          component={LeadCheckoutNav}
        />

        <ProtectedRoute
          path="/videos/record-stream/:id"
          exact
          bootIntercom={bootIntercom}
          component={StartSteamLive}
        />



        <ProtectedRoute
          path="/videos/add-nav-video/select-nav-product/external-checkout-nav"
          exact
          bootIntercom={bootIntercom}
          component={ExternalCheckoutNav}
        />

        <ProtectedRoute
          path="/videos/add-nav-video/select-nav-product"
          exact
          bootIntercom={bootIntercom}
          component={SelectProductNav}
        />

        <ProtectedRoute
          path="/videos/add-nav-video/success-checkout-nav"
          bootIntercom={bootIntercom}
          component={SuccessCheckoutNav}
        />

        <ProtectedRoute
          path="/edit-videos/select-nav-product/edit-lead-checkout-nav"
          exact
          bootIntercom={bootIntercom}
          component={EditLeadCheckoutNav}
        />

          <ProtectedRoute
          path={["/videos/add-nav-video/:videoId", "/videos/add-nav-video"]}
          exact
          bootIntercom={bootIntercom}
          component={AddVideoNav}
        />

        <ProtectedRoute
          path="/external-checkout"
          bootIntercom={bootIntercom}
          component={ExternalCheckout}
        />

        <ProtectedRoute
          path="/edit-videos/select-nav-product/edit-external-checkout-nav"
          exact
          bootIntercom={bootIntercom}
          component={EditExternalCheckoutNav}
        />
        <ProtectedRoute
          path="/success-checkout"
          bootIntercom={bootIntercom}
          component={SuccessCheckout}
        />

        <ProtectedRoute
          path="/success-update-checkout-nav"
          bootIntercom={bootIntercom}
          component={SuccessUpdateCheckoutNav}
        />

        <ProtectedRoute
          path="/incomplete-profile"
          bootIntercom={bootIntercom}
          component={IncompleteDashboard}
        />
        <ProtectedRoute
          path="/products"
          bootIntercom={bootIntercom}
          component={Products}
        />

       <ProtectedRoute
          path="/orders/customer-information"
          bootIntercom={bootIntercom}
          component={CustomerInfo}
        /> 

        <ProtectedRoute
          path="/orders"
          bootIntercom={bootIntercom}
          component={OrdersReport}
        /> 

        

        <ProtectedRoute
          path="/new-add-lead"
          bootIntercom={bootIntercom}
          component={AddLead}
        />
        <ProtectedRoute
          path="/add-lead"
          bootIntercom={bootIntercom}
          component={NewAddLead}
        />

       {/* <ProtectedRoute
          path="/old-edit-lead"
          bootIntercom={bootIntercom}
          component={EditLead}
        /> */}

          <ProtectedRoute
          path="/edit-order"
          bootIntercom={bootIntercom}
          component={EditOrder}
        />

        <ProtectedRoute
          path="/donations"
          bootIntercom={bootIntercom}
          component={Donations}
        />


        <ProtectedRoute
          path="/edit-lead"
          bootIntercom={bootIntercom}
          component={NewEditLead}
        />

        {/* <ProtectedRoute
          path="/new-videos"
          bootIntercom={bootIntercom}
          component={Products}
        /> */}

        <ProtectedRoute
          path="/leads"
          bootIntercom={bootIntercom}
          component={Orders}
        />

        <ProtectedRoute
          path="/settings/subscription/addon-listings"
          bootIntercom={bootIntercom}
          exact
          component={AddonPackagesBox}
        />

        <ProtectedRoute
          path={[
            "/settings/subscription/upgrade-plan/:packageId",
            "/settings/subscription/upgrade-plan",
            "/settings/subscription/purchase-addon/:addonId",
            "/settings/order-commission/:invoiceId",
          ]}
          // path="/settings/subscription/upgrade-plan"
          exact
          bootIntercom={bootIntercom}
          component={Packages}
        />

        <ProtectedRoute
          path="/videos/integration-on-web/web-integration"
          exact
          bootIntercom={bootIntercom}
          component={AddVideoWidget}
        />

        <ProtectedRoute
          path="/videos/integration-on-web"
          exact
          bootIntercom={bootIntercom}
          component={IntegrationsOnWeb}
        />

        <ProtectedRoute
          path={["/videos/integration-on-web/edit/:widgetId"]}
          exact
          bootIntercom={bootIntercom}
          component={AddVideoWidget}
        />

        <ProtectedRoute
          path="/videos"
          bootIntercom={bootIntercom}
          component={Videos}
        />

        {/* <ProtectedRoute
          path="/settings/profile"
          bootIntercom={bootIntercom}
          component={ProfileSettingsNew}
        /> */}

        {/* <ProtectedRoute
          path="/settings/brand-information"
          bootIntercom={bootIntercom}
          component={BrandInformation}
        /> */}

        {/* <ProtectedRoute
          path="/settings/theme"
          bootIntercom={bootIntercom}
          component={Theme}
        /> */}

        <ProtectedRoute
          path="/settings/invite-friends"
          bootIntercom={bootIntercom}
          component={InviteFriends}
        />


        {/* <ProtectedRoute
          path="/settings/in-video-checkout"
          bootIntercom={bootIntercom}
          component={VideoCheckout}
        /> */}

        <ProtectedRoute

        path={[
          "/settings/in-video-checkout/:payment-gateway-d",
          "/settings/in-video-checkout",
        ]}

          bootIntercom={bootIntercom}
          component={InVideoCheckoutMain}
        />




        <ProtectedRoute
          path="/settings/subscription"
          bootIntercom={bootIntercom}
          component={BillingInformationSettingsNew}
        />


        <ProtectedRoute
          path="/settings/add-lead-form-old"
          bootIntercom={bootIntercom}
          component={AddLeadForm}
        />

        <ProtectedRoute
          path="/add-lead-form"
          bootIntercom={bootIntercom}
          component={AddLeadFormMain}
        />

        <ProtectedRoute
          path="/edit-lead-form"
          bootIntercom={bootIntercom}
          component={EditLeadForm}
        />


        
      <ProtectedRoute
          path="/lead-form-list"
          bootIntercom={bootIntercom}
          component={LeadAllFormListReport}
        />

     

      <ProtectedRoute
          path="/lead-form-report"
          bootIntercom={bootIntercom}
          component={MultiLeadFormReport}
        />

         <ProtectedRoute
          path="/all-lead-form"
          bootIntercom={bootIntercom}
          component={LeadForm}
        />

        <ProtectedRoute
          path="/settings/integrations"
          bootIntercom={bootIntercom}
          component={Integration}
        />

        <ProtectedRoute
          path="/settings/account-setting"
          bootIntercom={bootIntercom}
          component={AccountSetting}
        />


        <ProtectedRoute
          path="/settings/import-woocommerce-product"
          bootIntercom={bootIntercom}
          component={ImportWoocommerceProductNav}
        />

        <ProtectedRoute
          path="/settings/woocommerce-setup"
          bootIntercom={bootIntercom}
          component={WoocommerceSetup}
        />

        <Route
          path="/login"
          render={(routeProps) => {
            // Hotjar.event('button-click')

            if (isLoggedIn) return <Redirect to="/" />;
            return <Login {...routeProps} />;
          }}
        />
        <Route
          path={["/OTP-Verification"]}
          exact
          render={(routeProps) => {
            if (isLoggedIn) return <Redirect to="/" />;
            return <OTPVerification {...routeProps} />;
          }}
        />

        <Route
          path={["/OTP-Verification/:optNumber"]}
          exact
          render={(routeProps) => {
            if (isLoggedIn) return <Redirect to="/" />;
            return <OTPVerification {...routeProps} />;
          }}
        />

        <Route
          path={["/short-url/:id"]}
          exact
          render={(routeProps) => {
            // if (isLoggedIn) return <Redirect to="/" />;
            return <ShortUrl {...routeProps} />;
          }}
        />



        <Route
          path={["/OTP-Verification/:optNumber/:otpUrlKey"]}
          exact
          render={(routeProps) => {
            return <OTPDirectVerification {...routeProps} />;
          }}
        />

        <Route
          path={["/unsubscribe/:unsubkey"]}
          exact
          render={(routeProps) => {
            return <DirectUnsibscribe {...routeProps} />;
          }}
        />

        <Route
          path={["/register/:step", "/register"]}
          render={(routeProps) => {
            bootIntercom();
            window.Intercom("update");

            return <OnboardingProfileInfo {...routeProps} />;
          }}
        />
        <Route
          path="/forgotPassword"
          render={(routeProps) => {
            // bootIntercom();
            window.Intercom("update");
            return <ForgotPassword {...routeProps} />;
          }}
        />

        <Route
          path="/reset-password"
          render={(routeProps) => {
            bootIntercom();
            window.Intercom("update");
            return <ResetPassword {...routeProps} />;
          }}
        />

        <ProtectedRoute
          path="/dashboard"
          bootIntercom={bootIntercom}
          component={Dashboard}
        />


      </Switch>

   
  );
}

export default RoutesApp;
