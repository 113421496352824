import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import Webcam from "react-webcam";
// import {  UserNewSubscribedPlanData } from "../Settings/BillingInformationSettings/Queries";
import "./VideoRecorder.css";
import * as helper from "../../helpers/helper";
import VideoPreviewAddVideo from "./VideoPreviewAddVideo";


const VideoRecorder = forwardRef(({ uploadVideoToServer, videoLoading, isVideoRecorded, setIsVideoRecorded, setParentErrorMessage, setWowzaId }, ref) => {
  // const VideoRecorder = ({ uploadVideoToServer, videoLoading }) => {
  const [permission, setPermission] = useState(false);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [videoChunks, setVideoChunks] = useState([]);
  const [recordedVideo, setRecordedVideo] = useState(null);
  const [timer, setTimer] = useState(0);
  const [cameraDevices, setCameraDevices] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [videoDuration, setVideoDuration] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [disabledClosebtn, setDisabledClosebtn] = useState(false);
  const [isHorizontal, setIsHorizontal] = useState(false);
  const [timerInterval, setTimerInterval] = useState();
  const [recordedVideoFile, setRecordedVideoFile] = useState(null);
  const [maxVideoRecordDuration, setMaxVideoRecordDuration] = useState(0);
  const [videoRecordQualityHeight, setVideoRecordQualityHeight] = useState();
  const [videoRecordQualityWidth, setVideoRecordQualityWidth] = useState();
  const [originalVideoWidth, setOriginalVideoWidth] = useState();
  const [originalVideoHeight, setOriginalVideoHeight] = useState();
  // const [videoUploadLoader, setVideoUploadLoader] = useState();
  // const [videoRecorderPopup, setVideoRecorderPopup] = useState(true);
  const [videoRecorderMirror, setVideoRecorderMirror] = useState(true);
  const [cameraError, setCameraError] = useState();
  const [countBeforeStart, setCountBeforeStart] = useState(false);
  const [countBeforeStartInterval, setCountBeforeStartInterval] = useState(2);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [tempRecHeight, setTempRecHeight] = useState(360);
  // const [tempRecWidth, setTempRecWidth] = useState(640);
  const [userSubscribedData, setUserSubscribedData] = useState([]);
  const [userSubscribedDataLoader, setUserSubscribedDataLoader] = useState();
  const [cameraSupportedHeight, setCameraSupportedHeight] = useState();
  const [cameraSupportedWidth, setCameraSupportedWidth] = useState();
  const [cameraSupportErrorMessage, setCameraSupportErrorMessage] = useState("");
  const [hideRecordingControls, setHideRecordingControls] = useState(false);
  const [hideOrientationControls, setHideOrientationControls] = useState(false);
  const [browserName, setBrowserName] = useState(false);
  const [videoPreview, setVideoPreview] = useState(null);
  const [recordingMaxReachedMessage, setRecordingMaxReachedMessage] = useState("");

  useEffect(() => {

    setBrowserName(helper.browserName());
    if (helper.browserName() === 'Mozilla Firefox' || helper.isMobile()===true ) {
      setHideOrientationControls(true);
      setIsHorizontal(true);
    }
    fetchSubscribedPackage();
  }, []);

  useEffect(() => {
    setParentErrorMessage(cameraError);
  }, [cameraError]);


  const fetchSubscribedPackage = () => {
    setUserSubscribedDataLoader(true);
    fetch(process.env.REACT_APP_API_URL + '/user-new-subscribed-plan-data', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + helper.userLoggedAccessToken(),
      },
      body: JSON.stringify({}), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        setUserSubscribedData(data?.data);
      }).catch((error) => {
        console.error('Error fetching data:', error);
      }).finally((error) => {
        setUserSubscribedDataLoader(false);
      });
  };

  // width: { exact: 480 },
  // height: { exact: 720 },

  useImperativeHandle(ref, () => ({
    closeCameraPopup
  }));


  // const closeCameraPopupFromParent = () => {
  //   closeCameraPopup
  // }; 



  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const setVideoLayout = (layoutParam) => {
    // closeCameraPopup();
    setIsHorizontal(layoutParam);
    // getCameraPermission();
  }

  const mediaRecorder = useRef(null);
  const videoRef = useRef(null); // Reference to the video element
  const mimeType = "video/webm";

  // const { data: VSUserNewSubscribedPlan } = useQuery(UserNewSubscribedPlanData);

  // const recordDefaultMinutes = process.env.REACT_APP_MAX_RECORD_DEFAULT_MINUTES;
  // const recordDefaultQuality = process.env.REACT_APP_MAX_RECORD_DEFAULT_QUALITY;




  const openCameraPopupFromBtn = () => {
    // setIsHorizontal(false); //default should be vertical
    openCameraPopup();
  }


  const openCameraPopup = () => {
    clearInterval(countBeforeStartInterval);
    getCameraPermission();
    // if(){
    // setTimeout(function () {
      openModal();
    // }, browserName != 'Mozilla Firefox' ? 1 : 3000);
    // } 
    setPermission(false);
  }

  const closeCameraPopup = () => {
    closeModal();
    clearRecordingTime();
    stopRecording();
    setRecordedVideo(null);
  }

  const finalcloseCameraPopup = () => {
    closeModal();
    clearRecordingTime();
    setRecordedVideo(null);
  }

  const getCameraPermission = async () => {
    setCameraError(null);

    clearRecordingTime();
    setRecordedVideo(null);

    const cameraResolutions = await getSupportedVideoQualities();

    let resolutionsHeightWidthParam;

    const cameraSupportMessage = 'Your camera does not support the available resolutions; we are recording at the highest possible resolution from your camera.';

    if (isHorizontal) {
      if (cameraResolutions?.maxHeight) {
        resolutionsHeightWidthParam = helper.videoResolutions(Math.min(videoRecordQualityHeight, cameraResolutions?.maxHeight));
      } else {
        resolutionsHeightWidthParam = helper.videoResolutions(videoRecordQualityHeight);
      }
    } else {

      if (cameraResolutions?.maxHeight) {
        resolutionsHeightWidthParam = helper.videoVerticalResolutions(Math.min(videoRecordQualityHeight, cameraResolutions?.maxHeight));
      } else {
        resolutionsHeightWidthParam = helper.videoVerticalResolutions(videoRecordQualityHeight, cameraResolutions);
      }


    }

    if (videoRecordQualityHeight === resolutionsHeightWidthParam.height && videoRecordQualityHeight != cameraResolutions?.maxHeight && videoRecordQualityHeight > resolutionsHeightWidthParam.height) {
      setCameraSupportErrorMessage(cameraSupportMessage);
    }

    setCameraSupportedHeight(resolutionsHeightWidthParam.height);
    setCameraSupportedWidth(resolutionsHeightWidthParam.width);

    try {
      const videoConstraints = {
        audio: false,
        video: {
          deviceId: selectedCamera,
          // width: { ideal: videoRecordQualityWidth },
          // height: { ideal: videoRecordQualityHeight },

          // width: { exact: isHorizontal==true?720:480 },
          // height: { exact: isHorizontal==true?480:720 },

          width: browserName === 'Mozilla Firefox' ? { ideal: resolutionsHeightWidthParam.width } : { exact: resolutionsHeightWidthParam.width },
          height: browserName === 'Mozilla Firefox' ? { ideal: resolutionsHeightWidthParam.height } : { exact: resolutionsHeightWidthParam.height },
          facingMode: 'user',
          // width: { exact: 480 },
          // height: { exact: 720 },

          // width: { exact: tempRecWidth },
          // height: { exact: tempRecHeight },


          // width: { exact: 720 },
          // height: { exact: 1280 },


          // height: 1280,
          // width: 720,
          // height: 1280,
        },
      };
      const audioConstraints = { audio: true };
      const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);
      setPermission(true);
      const combinedStream = new MediaStream([...videoStream.getVideoTracks(), ...audioStream.getAudioTracks()]);
      setStream(combinedStream);

    } catch (err) {

      closeCameraPopup();
      switch (err.name) {
        case "OverconstrainedError":
          setCameraError('CameraError: The requested media constraints are not supported by your device.');
          break;

        case "NotAllowedError":
        case "PermissionDeniedError":
          setCameraError('CameraError: Permission Denied or not allowed');
          break;

        case "NotFoundError":
          setCameraError('CameraError: No camera or microphone');
          break;

        case "NotReadableError":
          setCameraError('CameraError: Not accessible or already in use.');
          break;

        default:
          setCameraError('CameraError: Something went wrong.');

      }
    }
  };

  async function getSupportedVideoQualities(cameraDeviceId) {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      let camera;

      if (!cameraDeviceId) {
        camera = devices.find((device) => device.kind === 'videoinput');
      } else {
        camera = devices.find((device) => device.deviceId === cameraDeviceId);
      }


      if (camera) {
        // Check if the camera supports video input
        if (camera.kind === 'videoinput') {
          // Get the capabilities object
          if (typeof camera.getCapabilities === 'function') {
            const capabilities = camera.getCapabilities();
            if (capabilities) {
              return { maxHeight: capabilities.height.max, maxWidth: capabilities.width.max }
            }

          }

          return { maxHeight: 720, maxWidth: 1280 };

          // Extract the supported video resolutions (qualities)
          const supportedVideoQualities = [];

          // Extract supported width and height values
          // const supportedWidths = capabilities.width.max;
          // const supportedHeights = capabilities.height.max;




          // for (let i = 0; i < supportedWidths.length; i++) {
          //   const width = supportedWidths[i];
          //   const height = supportedHeights[i];
          //   supportedVideoQualities.push({ width, height });
          // }


          return supportedVideoQualities;
        }
      }

      // If the camera is not found or doesn't support video input
      return [];
    } catch (error) {
      console.error('Error while getting supported video qualities:', error);
      return [];
    }
  }


  const updateVideoDuration = () => {
    if (recordedVideo) {
      const videoElement = document.createElement("video");
      videoElement.src = recordedVideo;

      videoElement.onloadedmetadata = () => {
        // Get the duration of the video in seconds
        const durationInSeconds = videoElement.duration;
        setVideoDuration(durationInSeconds);
      };
    }
  };

  const startRecording = async () => {
    if (isModalOpen === true) {
      setRecordingStatus("recording");
      if (isPaused) {
        mediaRecorder.current.resume();
      } else {
        const media = new MediaRecorder(stream, { mimeType });
        mediaRecorder.current = media;
        mediaRecorder.current.start();
        let localVideoChunks = [];
        mediaRecorder.current.ondataavailable = (event) => {
          if (typeof event.data === "undefined") return;
          if (event.data.size === 0) return;
          localVideoChunks.push(event.data);
        };
        setVideoChunks(localVideoChunks);
        startRecordingTime();
      }


    }

  };

  const startCountDown = () => {
    clearInterval(countBeforeStartInterval);
    setHideRecordingControls(true);
    // setCountBeforeStartInterval(null);
    setCountBeforeStart(3);
    setCountBeforeStartInterval(setInterval(() => {
      setCountBeforeStart(prevCount => prevCount - 1);
    }, 1000));
  };


  useEffect(() => {
    const checkisModalOpen = isModalOpen;

    closeCameraPopup();

    //   const [tempRecHeight, setTempRecHeight] = useState(360);
    // const [tempRecWidth, setTempRecWidth] = useState(640);


    // setTempRecHeight(isHorizontal==true?480:720);
    // setTempRecWidth(isHorizontal==true?720:480);



    // openCameraPopup();


    // setVideoRecordQualityWidth(isHorizontal==false?originalVideoHeight:originalVideoWidth)
    // setVideoRecordQualityHeight(isHorizontal==true?originalVideoHeight:originalVideoWidth)
    // getCameraPermission();
    setTimeout(function () {

      if (checkisModalOpen) {
        openCameraPopup();

      }
    }, 100)
  }, [isHorizontal]);

  useEffect(() => {

    // setHideRecordingControls(true);
    if (countBeforeStart === 0) {
      // setHideRecordingControls(false);
      setCameraSupportErrorMessage(''); // clear the error message
      startRecording();
      setCountBeforeStart(false);
      clearInterval(countBeforeStartInterval);
    }
  }, [countBeforeStart, countBeforeStartInterval]);

  const pauseRecording = () => {
    setIsPaused(true);
    mediaRecorder.current.pause();
    clearInterval(timerInterval);
  };

  const resumeRecording = () => {
    setIsPaused(false);
    mediaRecorder.current.resume();
    startRecordingTime(true);
  };

  const clearRecordingTime = () => {
    setHideRecordingControls(false);
    setTimer(0);
    clearInterval(timerInterval);
  };

  const startRecordingTime = (isResume = false) => {
    if (!isResume) {
      clearRecordingTime();
    }
    setTimerInterval(
      setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000)
    );
  };

  const stopRecording = () => {
    try {
      setPermission(false);
      setRecordingStatus("inactive");
      mediaRecorder.current?.stop();
      clearRecordingTime();

      if (mediaRecorder.current) {
        mediaRecorder.current.onstop = () => {
          const videoBlob = new Blob(videoChunks, { type: mimeType });
          const videoUrl = URL.createObjectURL(videoBlob);
          const videoFile = new File([videoBlob], "recorded-video.webm", {
            type: mimeType,
          });

          setVideoPreview(videoUrl)

          setRecordedVideoFile(videoFile);
          setRecordedVideo(videoUrl);
          setVideoChunks([]);
          uploadVideoToServer(videoFile, true);

          finalcloseCameraPopup()

          // passVideoToParent();

        };
      }

      // if(recordedVideo){

      // }

      playVideo();
      closeCamera();
    } catch (e) {
      console.log('stopCameraError', e);
    }
  };


  const playVideo = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const passVideoToParent = () => {


    setDisabledClosebtn(true);
    if (recordedVideoFile) {
      try {
        uploadVideoToServer(recordedVideoFile, true);
        setDisabledClosebtn(false);
        // closeCameraPopup();
      } catch (error) {
        console.error("Error uploading video:", error);
        setDisabledClosebtn(false);
      }
    }
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  };

  const closeCamera = () => {
    try {
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
      setPermission(false);
      clearRecordingTime();
    } catch (error) {
      console.error('Error closing camera:', error);
    }
  };

  useEffect(() => {
    async function fetchCameraDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      setCameraDevices(cameras);
    }
    fetchCameraDevices();
  }, []);

  // useEffect(() => {
  //   setMaxVideoRecordDuration(recordDefaultMinutes * 60);
  //   const selectedQuality = helper.videoResolutions(process.env.REACT_APP_MAX_RECORD_DEFAULT_QUALITY);
  //   setVideoRecordQualityHeight(selectedQuality?.height);
  //   setVideoRecordQualityWidth(selectedQuality?.width);
  //   setOriginalVideoHeight(selectedQuality?.height);
  //   setOriginalVideoWidth(selectedQuality?.width);

  // }, [recordDefaultMinutes]);

  useEffect(() => {
    if (!isNaN(userSubscribedData?.video_quality)) {
      // const selectedQuality = helper.videoResolutions(userSubscribedData?.video_quality);
      const selectedQuality = helper.videoResolutions(720);
      setVideoRecordQualityHeight(selectedQuality?.height);
      setVideoRecordQualityWidth(selectedQuality?.width);

      setOriginalVideoHeight(selectedQuality?.height);
      setOriginalVideoWidth(selectedQuality?.width);
    }

    if (!isNaN(userSubscribedData?.video_record_max_length)) {
      setMaxVideoRecordDuration(helper.allowedVideorecordTimeSeconds(userSubscribedData));
    }

    // if (!isNaN(userSubscribedData?.video_length_limit)) {
      // setMaxVideoRecordDuration(userSubscribedData?.video_record_max_length * 60);
      // setMaxVideoRecordDuration(20);
      // if(!(userSubscribedData?.video_record_max_length>=1)){
      // }
      // setMaxVideoRecordDuration(helper.allowedVideoTimeSeconds(userSubscribedData));
    // }
  }, [userSubscribedData]);

  useEffect(() => {
    updateVideoDuration();
  }, [recordedVideo]);

  useEffect(() => {
    // setRecordingMaxReachedMessage('');
    if (timer === maxVideoRecordDuration && maxVideoRecordDuration>=1) {
      setRecordingMaxReachedMessage('Your '+(maxVideoRecordDuration/60)+'-minutes recording limit has been reached.');
      // setCameraError('Your '+maxVideoRecordDuration+'-minute recording limit has been reached.');
      // alert('Your video has been stopped!');
      stopRecording();
    }
  }, [timer]);

  if (userSubscribedDataLoader) {
    return (<div class="d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>)
  }

  return (
    <div className="video-recorder-area d-flex justify-content-center mb-4">
      <div className="d-flex justify-content-center align-items-center">
        {userSubscribedDataLoader === false && (
          <div className="row">
            {isVideoRecorded ? (
              <>
                {videoPreview && <VideoPreviewAddVideo src={videoPreview} removePreview={() => {
                  closeCamera();
                  clearRecordingTime();
                  setIsVideoRecorded(false)
                  setWowzaId(null)
                }} />}
              </>
            ) : (
              <>
                {
                  !isModalOpen ?
                    (
                      <div className="min-height-300 col-12 d-flex flex-column justify-content-center align-items-center">
                        {videoLoading ? (
                          <div>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          </div>
                        ) :
                          (
                            <>
                              <button disabled={!(maxVideoRecordDuration > 0)} class="btn btn-primary p-3 rounded-circle d-flex align-items-center justify-content-center" type="button" onClick={openCameraPopupFromBtn} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                                <i class="ti ti-camera fs-8" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Settings"></i>
                              </button>
                              <div className="col-12 d-flex justify-content-center video-record-preview">
                                <p className="mt-2 fs-4">Click Camera to Record.</p>
                              </div>
                            </>
                          )
                        }
                      </div>

                    ) : (
                      <div className="disabled-element min-height-300 col-12 d-flex flex-column justify-content-center align-items-center">
                        <button disabled={!(maxVideoRecordDuration > 0)} class="disabled-element btn btn-primary p-3 rounded-circle d-flex align-items-center justify-content-center" type="button" onClick={openCameraPopupFromBtn} data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                          <i class="ti ti-camera fs-8" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Settings"></i>
                        </button>
                        <div className="col-12 d-flex justify-content-center video-record-preview">
                          <p className="mt-2 fs-4">Click camera to Record</p>
                        </div>
                      </div>
                    )
                }
              </>
            )}

            {maxVideoRecordDuration === 0 && (
              <div className="text-danger text-center mt-2" role="alert">
                You have {!isNaN(maxVideoRecordDuration) ? maxVideoRecordDuration : "0"} minutes allowed to record video
              </div>
            )}

            {recordingMaxReachedMessage && (
              <div className="text-warning text-center" role="alert">
                {recordingMaxReachedMessage}
              </div>
            )}
          </div>
        )}
      </div>

      {((permission || recordedVideo) && isModalOpen === true) && (
        <main className={"video-recorder-main " + (isHorizontal === true ? " horizontal-recording" : " vertical-recording")}>
          {/* {countBeforeStart !== false && (countBeforeStart>=1) (
            <div className="countdown">
              <div className="countdown-number">{countBeforeStart}</div>
            </div>
          )} */}

          {countBeforeStart !== false && countBeforeStart >= 1 ? (
            <div className="countdown">
              <div className="countdown-number">{countBeforeStart}</div>
            </div>
          ) : null}

          <div className="video-main-area position-relative">
            {cameraSupportErrorMessage && (
              <div class="camera-support-error alert alert-danger" role="alert">{cameraSupportErrorMessage} </div>
            )}
            <div className="col-xs-12 col-md-10 col-lg-5 col-xl-8">
              <div className="video-controls-top">
                <div></div>
                <div>
                  {permission && ((recordingStatus === "inactive" || recordedVideo) && hideRecordingControls === false && hideOrientationControls === false) && (

                    <div className="btn-group horizontal-vertical-btns none-display" role="group">
                      <button
                        type="button"
                        onClick={(e) => setVideoLayout(true)}
                        className={"btn btn-light-primary text-primary font-medium " + (isHorizontal === true ? "active" : "")}
                      >
                        Horizontal
                      </button>
                      <button
                        type="button"
                        onClick={(e) => setVideoLayout(false)}
                        className={"btn btn-light-primary text-primary font-medium " + (isHorizontal === false ? "active" : "")}
                      >
                        Vertical
                      </button>
                    </div>

                  )}

                </div>
                <div className="justify-content-end">

                  {recordedVideo && (
                    <button className="btn btn-primary text-white cursor-pointer btn-circle btn-sm mr-5" disabled={videoLoading} title="Upload" onClick={(evnt) => passVideoToParent()} type="button">
                      {videoLoading ? (
                        <div>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div>
                      ) : (
                        <div> <i className="ti ti-device-floppy"></i></div>
                      )}
                    </button>
                  )}
                  <button type="button" disabled={disabledClosebtn || videoLoading} onClick={closeCameraPopup} className="close-btn cursor-pointer btn-circle btn-sm">
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <div className="modal-container">
                <div className="record-player-area">
                  {!isHorizontal && false && (
                    <div
                      className="vertical-layer"
                      style={{ gridRow: 1, gridColumn: 1 }}
                    >
                      <div className="vertical-layer-side vertical-layer-left" />
                      <div className="vertical-layer-side vertical-layer-right" />
                    </div>

                  )}


                  {permission && (
                    <div className="camera-preview-area" style={{height:cameraSupportedHeight,width:cameraSupportedWidth}}>
                      <Webcam
                      audio={false}
                      video={true}
                      mirrored={videoRecorderMirror}
                      style={{ "border-radius": "15px", height: '100%', objectFit: 'cover' }}
                      videoConstraints={{
                        deviceId: selectedCamera,
                        // width: { exact: isHorizontal === true ? 1280 : 400 },
                        // height: { exact: isHorizontal === true ? 720 : 720 },

                        // width: { exact: isHorizontal==true?720:480 },
                        // height: { exact: isHorizontal==true?480:720 },

                        width: browserName === 'Mozilla Firefox' ? { ideal: cameraSupportedWidth } : { exact: cameraSupportedWidth },
                        height: browserName === 'Mozilla Firefox' ? { ideal: cameraSupportedHeight } : { exact: cameraSupportedHeight },

                        // width: { exact: cameraSupportedWidth },
                        // height: { exact: cameraSupportedHeight },


                      }}

                      />
                    </div>

                  )}
                  {recordedVideo && (
                    // <div className={`video-player ${isHorizontal ? 'horizontal' : 'vertical'}`}>
                    // <video autoPlay playsinline  loop className={`video-player ${isHorizontal ? 'horizontal' : 'vertical'}`} style={{"pointer-events": "none",height:cameraSupportedHeight,width:cameraSupportedWidth,transform:"scaleX(-1);"}}  ref={videoRef} src={recordedVideo}></video>
                    <video autoPlay playsinline loop className={`video-player ${isHorizontal ? 'horizontal' : 'vertical'}`} style={{ "pointer-events": "none" }} ref={videoRef} src={recordedVideo}></video>
                    // </div>
                  )}
                </div>
              </div>
              <div className="video-controls-bottom mb-3">
                <div>
                  {/* {permission && (recordingStatus === "inactive" || recordedVideo) && (
                    <button type="button" className="mirror-btn cursor-pointer btn-circle btn-sm" onClick={() => (setVideoRecorderMirror(!videoRecorderMirror))}>
                      <i className="ti ti-flip-vertical fs-9"></i>
                    </button>
                  )} */}
                </div>
                <div>
                  {permission && ((recordingStatus === "inactive" || recordedVideo)) ? (
                    <div className="text-center d-flex justify-content-center align-items-center start-recording-btn-area">
                      <div className="start-record-btn">
                        <div title="Start Recording" onClick={startCountDown}></div>
                      </div>
                      <strong className="recording-text">Start Recording</strong>
                    </div>
                  ) : null}

                  {recordingStatus === "recording" ? (
                    <div className="text-center d-flex justify-content-center align-items-center">
                      <div className="start-record-btn">
                        <div title="Stop Recording" onClick={stopRecording}>
                          <div className="stop-record-bullet">
                          </div>
                        </div>
                      </div>

                      {recordingStatus === "recording" && !isPaused ? (
                        <button className="btn btn-primary text-white pause-resum-record-btn" title="Pause Recording" onClick={pauseRecording} type="button">
                          <i className="ti ti-player-pause-filled"></i>
                        </button>
                      ) : null}

                      {isPaused && !recordedVideo ? (
                        <button className="btn btn-primary text-white pause-resum-record-btn" title="Resume Recording" onClick={resumeRecording} type="button">
                          <i className="ti ti-player-play"></i>
                        </button>
                      ) : null}
                    </div>
                  ) : null}
                </div>
                <div class="timer-display-parent">

                  {permission && !recordedVideo &&
                    <div className="timer-display">
                      <strong> {formatTime(timer)}  / {(helper.showNumberOrInfinity(userSubscribedData?.video_record_max_length)=='Unlimited'?<i title="Unlimited" className="ti ti-infinity"></i>:formatTime(maxVideoRecordDuration)) }</strong>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </div>
  );
});

// export default forwardRef(VideoRecorder,ref);

export default VideoRecorder;