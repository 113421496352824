import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import useAuthState from "../../../hooks/useAuthState";
import styles from "./RegisterForm.module.scss";
import facebookIcon from "../../../../assets/dist/images/svgs/facebook-icon.svg";
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useHistory } from "react-router-dom";
import { getQueryString } from '../../../helpers/helper';
import { createAvatar } from '@dicebear/core';
import { bigEarsNeutral } from '@dicebear/collection';
import { RenderGoogleButton } from '../../Login/LoginForm';
import Input from "./../../../Form/Input";
import Select from "./../../../Form/Select";
import CheckBoxRow from "./../../../Form/CheckBoxRow";
import PhoneInputField from "./../../../Form/PhoneInputField";
import { Intents } from "../../../../Enums/Intents";
import Button from "./../../../Form/Button";
import Warning from "./../../../Form/Warning";
import FormToast, { TYPES } from "../../../Form/FormToast";



function RegisterForm({
  fullName,
  phoneNumber,
  email,
  password,
  invalidPassword,
  accountType,
  error,
  setFullName,
  setPhoneNumber,
  setEmail,
  setPassword,
  setAccountType,
  createAccount,
  mutationLoading,
  showPassword,
  setShowPassword,
  invalidEmail,
  isFormValid,
  disabled,
  isChecked,
  handleCheckboxChange,
  setCountryId,
  countryId,
  setCountryCode,
  setCountryDiallingCode
}) {
  const { login } = useAuthState();
  const history = useHistory();
  // const [countryCode, setCountryCode] = useState();
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState();
  const [socialErrot, setSocialErrot] = useState(false);
  const [socialMediaBtnWidth, setSocialMediaBtnWidth] = useState(250)
  // const [countryCodeInitialNumber, setCountryCodeInitialNumber] = useState("+1")

  // const [countryId, setCountryId] = useState()

  useEffect(() => {
    const el = document.getElementById("google-btn-row");
    const computedStyle = window.getComputedStyle(el);
    const totalPadding = parseInt(computedStyle.paddingRight) + parseInt(computedStyle.paddingLeft)
    const totalWidth = document.getElementById("google-btn-row").offsetWidth - totalPadding;
    setSocialMediaBtnWidth(totalWidth > 330 ? 330 : totalWidth)
  }, [history])

  useEffect(() => {
    const utm_source = getQueryString('utm_source');
    const utm_medium = getQueryString('utm_medium');
    const utm_campaign = getQueryString('utm_campaign');
    const utm_signup_type = getQueryString('utm_signup_type');
    const invitation_code = getQueryString('invitation_code');
    // if(utm_source=='marketingweb'){
    localStorage.setItem('utm_source', utm_source);
    // }
    localStorage.setItem('utm_medium', utm_medium);
    localStorage.setItem('utm_campaign', utm_campaign);

    if (utm_signup_type) {
      localStorage.setItem('utm_signup_type', utm_signup_type);
    }

    if (invitation_code) {
      localStorage.setItem('invitation_code', invitation_code);
    }


  }, []);

  const responseFacebook = async (FBresponse) => {
    try {
      setSocialErrot(false)
      if (FBresponse?.email) {

        const avatar = createAvatar(bigEarsNeutral, {
          "seed": FBresponse?.name,
          "scale": 120,
          "backgroundType": [
            "gradientLinear",
            "solid"
          ]
        });

        const avatarSVG = avatar.toString();
        // const decodeGoogleData = jwtDecode(response.credential);
        const verifyOTP = await fetch(process.env.REACT_APP_API_URL + '/create-new-user-otp', {
          method: 'POST',
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "name": FBresponse?.name,
            "email": FBresponse?.email,
            "source": "facebook",
            "avatar": avatarSVG,
            "invitation_code": localStorage.getItem('invitation_code'),

          })

        }).then(async response => {
          if (response.ok) {
            response.json().then(async json => {
              if (json.code == 200) {
                localStorage.removeItem('invitation_code');
                await login(FBresponse.email, "12345", "facebook");
                history.replace("/");
              } else {
                setSocialErrot(true);
              }
            });
          }
        }).catch((error) => {
          console.log('error>>>', error)
        }).finally((error) => {
          //
        });

      } else {
        //failed to login
      }




    } catch (err) {

      throw err;
    }
  }

  const responseGoogle = async (response) => {
    setSocialErrot(false);

    if (!response) return;

    const name = `${response?.family_name} ${response?.given_name}`
    const email = response?.email

    try {
      const avatar = createAvatar(bigEarsNeutral, {
        "seed": name,
        "scale": 120,
        "backgroundType": [
          "gradientLinear",
          "solid"
        ]
      });
      const avatarSVG = avatar.toString();

      const verifyOTP = await fetch(process.env.REACT_APP_API_URL + '/create-new-user-otp', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "name": name,
          "email": email,
          "source": "gmail",
          "avatar": avatarSVG,
          "invitation_code": localStorage.getItem('invitation_code'),
        })

      }).then(async response => {
        if (response.ok) {
          response.json().then(async json => {

            if (json.code == 200) {
              localStorage.removeItem('invitation_code');
              await login(email, "12345", "gmail");
              history.replace("/");
            } else {
              setSocialErrot(true);
            }
          });
        }
      }).catch((error) => {
        console.log('error>>>', error)
      }).finally((error) => {
        //
      });

    } catch (err) {

      throw err;
    }
  }

  return (

    <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
      <div className="col-sm-7 col-md-7 col-xl-7">

        <div className="reg-req-points">
          <div className="reg-req-point">
            <span><i className="ti ti-circle-check color-primary fs-7 mr-5"></i></span>
            <span className="fs-5 text-black">Free Account</span>
          </div>
          <div className="reg-req-point">
            <span><i className="ti ti-circle-check color-primary fs-7 mr-5"></i></span>
            <span className="fs-5 text-black">No Card Required</span>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-12 mb-2 mb-1 d-flex justify-content-center" id="google-btn-row">
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <RenderGoogleButton title="Sign up with Google" responseGoogle={responseGoogle} socialMediaBtnWidth={socialMediaBtnWidth} />
            </GoogleOAuthProvider>
          </div>
          {/* <div className="col-xs-12 col-12 d-flex justify-content-center none-display">
            <FacebookLogin
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              callback={responseFacebook}
              fields="name,email,picture"
              render={renderProps => (
                <a href="#!" style={{ width: socialMediaBtnWidth }} className="btn btn-white text-dark border fw-normal d-flex align-items-center justify-content-center rounded-2 py-8 facebook-sign" onClick={renderProps.onClick}>
                  <img src={facebookIcon} alt="" className="img-fluid me-2" width="18" height="18" />
                  <b>Sign up with Facebook</b>
                </a>
              )}
            />
          </div> */}
        </div>

        <div className="position-relative text-center my-4">
          <p className="mb-0 fs-4 px-3 d-inline-block bg-white text-dark z-index-5 position-relative">OR</p>
          <span className="border-top w-100 position-absolute top-50 start-50 translate-middle"></span>
        </div>

        <form className={styles.register} onSubmit={createAccount}>
          {socialErrot && (
            <div className="mb-4">
              <Warning
                intent={Intents.WARNING}
                title="Sorry"
                message="User Alreay Exist With Different Source"
              >
                <p>User Alreay Exist With Different Source</p>
              </Warning>
            </div>
          )}

          {error && (

            <FormToast
              // intent={Intent.WARNING}
              type={TYPES.WARNING}
              // style={{ marginBottom: "1em" }}
              // icon={null}
            >
              {(error.code === "user_exists" ||
                error.code === "username_exists") && (
                  <p>
                    {error.message}{" "}
                    <Link className="ml-5" to="/login">
                      <b>Here</b>
                    </Link>
                  </p>
                )}
                
              {error.code !== "user_exists" && error.code !== "username_exists" && (
                <p>{error.message}</p>
              )}
            </FormToast>
          )}

          <div className="row mb-3">
            <div className="col-md-6 ">
              <Input
                id="first-name"
                type="text"
                label="Full Name *"
                value={fullName}
                onChange={setFullName}
                disabled={false}
                placeholder=""
              />
            </div>
            <div className="col-md-6 reg-email-field">
              <Input
                id="email"
                type="email"
                label="Email *"
                value={email}
                onChange={setEmail}
                disabled={false}
                placeholder=""
                errorMessage={(!isFormValid && invalidEmail) ? "Invalid email address." : ""}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <Input
                id="password"
                type="password"
                label="Password *"
                value={password}
                onChange={setPassword}
                disabled={false}
                placeholder=""
                errorMessage={(!isFormValid && invalidPassword) ? "Password must contain at least 9 characters, contain 1 lowercase, 1 uppercase letter and a number." : ""}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6 ">

            {/* reactSelectCountryFlag, setReactSelectCountryFlag */}


              {/* <CountriesPhoneNumber /> */}
              
              {/* <CountriesPhoneNumber
              onChange={(e) => {
                console.log('e',e)
                setCountryId(e?.country?.id)
                setCountryCodeInitialNumber(e?.country?.dialling_code)
                setCountryCode(e?.country?.code)
                setPhoneNumber(e?.phone)
              }}
              />   */}
              <PhoneInputField
                id="phone-number"
                label="Phone Number *"
                errorMessage={isPhoneNumberValid === false ? "Invalid phone number" : ""}
                valid={isPhoneNumberValid}
                setValid={setIsPhoneNumberValid}
                onChange={(e) => {
                  // setCountryId(e?.country?.id)
                  setCountryDiallingCode(e?.dialCode)
                  setCountryCode(e?.country)
                  setPhoneNumber(e?.phone)
                }}
              />
            </div>
            <div className="col-md-6 reg-signup-as-field">
              <Select
                id="account-type"
                label="Sign Up As *"
                value={accountType}
                onChange={setAccountType}
                disabled={false}
                options={[
                  { label: "Brand", value: "Brand" },
                  { label: "Influencer", value: "Influencer" },
                  { label: "Individual", value: "Individual" },
                  { label: "Non Profit", value: "Non Profit" },
                ]}
              />
            </div>
          </div>
          <div className="mb-5">
            <CheckBoxRow
              id="demo"
              label="Book a demo for me"
              value={isChecked}
              onChange={handleCheckboxChange}
              disabled={false}
            />
          </div>
          <div className="">
            <Button
              type="submit"
              buttonText="Sign Up"
              fullButton={true}
              loading={mutationLoading}
              disabled={disabled || isPhoneNumberValid != true}
            />
          </div>

        </form>

        <div className="d-flex align-items-center justify-content-center">
          <p className="fs-3 mb-0 fw-semibold text-black">
            Already have an Account? <Link to="/login" className="text-primary"> Sign In </Link>
          </p>
        </div>
      </div>
    </div>

  );
}

export default RegisterForm;
