import validator from 'validator';

function validateField(value, rule, cb) {

  // console.log(value,"yaseeee")

  // return

  switch (rule) {
    case 'required':
      if (!value) {
        return cb('This field is required')
        // return 'This field is required';
      }
      break;

    case 'email':
      if (validator.isEmpty(value)) {
        return cb('Email is required.')
      } else if (!validator.isEmail(value)) {
        return cb('Invalid email address.');
      }
      break;

    case 'phone':
      // Add your password validation logic here
      if (validator.isEmpty(value) || value.length < 11 || value.length > 15) {

        return cb('Phone is required and should be between 11 and 15 numbers.')
      }
      break;

    case 'url':
        if (validator.isEmpty(value)) {
          return cb('URL is required.')
        } else if (!validator.isURL(value)) {
          return cb('Please enter correct URL.');
        }
        break;

    case 'multi-image':
      if (validator.isEmpty(value)) {
        return cb('URL is required.')
      } else if (!validator.isURL(value)) {
        return cb('Please enter correct URL.');
      }
      break;

      case 'cat-required':
        if (value.length <1) {
          return cb('This field is required')
          // return 'This field is required';
        }
        break;




    // Add more cases for additional validation rules
    default:
      break;
  }
  return cb(""); // If all rules pass, return an empty string to indicate no error
}


export const validateHttpsUrl = (url) => {
  const regex = /^https:\/\/.+/;
  return regex.test(url);
}; 

export default validateField;
