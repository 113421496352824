import React, { useState, useEffect} from "react";
import OTPVerificationForm from "./OTPVerificationForm";
import useAuthState from "../../hooks/useAuthState";
import { useHistory } from "react-router-dom";
import { OnboardingPage } from "../../layouts/OnboardingPage";

function OTPVerification() {
  
  const [otp, setotp] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);

  const { login } = useAuthState();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const disabled = otp.length !== 6;


  


  const verifyOTP = async (e) => {
    setLoader(true)
    if(e){
      e.preventDefault();
    }
    try {
      const verifyOTP = await fetch(process.env.REACT_APP_API_URL + '/user-verify-otp', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "otp": otp,
          "email": localStorage.getItem('otp-email')

        })
      }).then(response => {
        if (response.ok) {
          response.json().then(async json => {

            if(json?.status==true && json?.code==200){
              await login(localStorage.getItem('otp-email'), localStorage.getItem('otp-password'));
              setLoader(false);

              if(localStorage.getItem('utm_source')=='marketingweb'){

                if(localStorage.getItem('utm_campaign')>=1){
                  history.replace("/settings/subscription/upgrade-plan/"+localStorage.getItem('utm_campaign'));
                } else {
                  history.replace("/settings/subscription/upgrade-plan");
                }
              } else {
                history.replace("/");
              }
            } else {
            setError({
              title: "OTP Verification!",
              message:json?.message
            });
              
            }
          });
        } else {
          response.json().then(async json => {
            setError({
              title: "OTP Verification!",
              message:json?.message?json.message:"Something went wrong."
            });

          });

        }
      })
      
      setLoader(false);
      
    } catch (err) {
      setLoader(false);
      setError({
        title: "OTP Verification!",
        message:
          "Entered OTP is invalid."
      });
      // throw err;
    }
  };

  return (
    <OnboardingPage title="OTP Verification">    
      
      <OTPVerificationForm
        otp={otp}
        setOtp={setotp}
        error={error}
        disabled={disabled}
        success={success}
        loader={loader}
        onSubmit={verifyOTP}
      />
    
    </OnboardingPage>
  );
}

export default OTPVerification;
