import React, { useEffect, useRef, useState } from "react";
import UploadHelper from "../../../component/helpers/upload";
import { useHistory } from "react-router-dom";
import ImageUploader from "./ImageUploader";
import {
    userLoggedAccessToken,
    loggedUserAccountType,
    showUserTypeBaseMessage,
    isMobile,
} from "../../helpers/helper";
import validateField from "../../helpers/AllValidtions";
import ProductVariants from "./../../../component/Product/ProductVariants";
import Input from "../../Form/Input";
import Textarea from "../../Form/Textarea";
import MultiSelect from "../../Form/MultiSelect";
import QuantityInput from "../../Form/QuantityInput";
import RadioButtonRow from "../../Form/RadioButtonRow";
import Button from "../../Form/Button";
import FieldWarning from "../../Form/FieldWarning";

function AddProductVideo({ setIsVisible, setSelectedProduct, setProductData }) {
    const isMobileSize = isMobile();
    const history = useHistory();
    const [title, setTitle] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState(1);
    const [description, setDescription] = useState("");
    const [productImage, setProductImage] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [progress, setProgress] = useState(0.0);
    const [productImageUrl, setProductImageUrl] = useState();
    const [fileUploadLoading, setFileUploadLoading] = useState(false);
    const [addProductLoader, setAddProductLoader] = useState(false);

    const allowedExt = "jpeg, png and webp";
    const [loader, setLoader] = useState(false);
    const [accountTypeMessage, setAccountTypeMessage] = useState("");
    const [productMultiImages, setProductMultiImages] = useState([]);

    const [selectedOption, setSelectedOption] = useState([]);
    const [options, setOptions] = useState([]);

    const [titleError, setTitleError] = useState("");
    const [priceError, setPriceError] = useState("");
    const [quantityError, setQuantityError] = useState("");
    const [categoryError, setCategoryError] = useState("");
    const [productMultiImagesError, setProductMultiImagesError] = useState("");
    const [minLimit, setMinLimit] = useState(1);

    const [hasVariants, setHasVariant] = useState(false);
    const variantsRef = useRef();

    const [shipping, setShipping] = useState("no-shipping");

    const [size, setSize] = useState("");

    const [weight, setWeight] = useState("");
    const [weightError, setWeightError] = useState("");

    const [lenght, setLenght] = useState("");
    const [lenghtError, setLenghtError] = useState("");

    const [width, setWidth] = useState("");
    const [widthtError, setWidthtError] = useState("");

    const [height, setHeight] = useState("");
    const [heightError, setHeightError] = useState("");

    const handleLenghtChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setLenght(value);
        validateField(value, rule, setLenghtError);
    };

    const handleWidthChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWidth(value);
        validateField(value, rule, setWidthtError);
    };

    const handleHeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setHeight(value);
        validateField(value, rule, setHeightError);
    };

    const handleWeightChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setWeight(value);
        validateField(value, rule, setWeightError);
    };

    const handleTitleChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setTitle(value);
        validateField(value, rule, setTitleError);
    };

    const handlePriceChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setPrice(value);
        validateField(value, rule, setPriceError);
    };

    const handleQuantityChange = (e) => {
        var value = e.target.value;
        setQuantity(value);
        validateField(value, "required", setQuantityError);
    };

    const handleCategoryChange = (selectedOption) => {
        var rule = "cat-required";
        var value = selectedOption;
        setSelectedOption(selectedOption);
        validateField(value, rule, setCategoryError);
    };

    const allowedImageTypes = {
        "image/jpeg": "jpg",
        "image/png": "png",
        "image/webp": "webp",
    };

    // const addText = showUserTypeBaseMessage({
    //     NonProfit: "Add Another Donation Campaign",
    //     default: "Add Another Product",
    // });
    // const addTextOnly = showUserTypeBaseMessage({
    //     NonProfit: "Add Donation Campaign",
    //     default: "Add Product",
    // });
    // const selectText = showUserTypeBaseMessage({
    //     NonProfit: "Select Donation Campaign",
    //     default: "Select Product",
    // });
    // const textPlural = showUserTypeBaseMessage({
    //     NonProfit: "Donation Campaigns",
    //     default: "Products",
    // });
    const textSignular = showUserTypeBaseMessage({
        NonProfit: "Donation Campaign",
        default: "Product",
    });

    useEffect(() => {
        // setShowSuccess(true);
        // setSuccessMessage('Video added');

        switch (loggedUserAccountType()?.toLowerCase()) {
            case "brands": {
                setAccountTypeMessage("To start selling");
                break;
            }
            case "influencer": {
                setAccountTypeMessage("to earning commission");
                break;
            }
        }

        fetchCategoryData();

        // const timer = setTimeout(() => {
        //     setShowSuccess(false);
        //     setSuccessMessage('');
        //   }, 4500);
        // return () => clearTimeout(timer);
    }, []);

    const handleMultipleImages = (multiImagesData) => {
        setProductMultiImages(multiImagesData);
        setProductImageUrl(multiImagesData?.length ? multiImagesData[0] : null);
    };

    const fetchCategoryData = () => {
        let token = localStorage.getItem("authSession");
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + "/all-product-category", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + userLoggedAccessToken(),
            },
            body: JSON.stringify({}), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                // setData(data.data)
                setOptions(data.data);
                setLoader(true);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        var variants = [];
        if (hasVariants) {
            if (variantsRef.current && variantsRef.current.validateAndGetVariants) {
                const [error, data] = variantsRef.current.validateAndGetVariants();
                if (error) return;

                variants = data;
            }
        }

        submitForm(
            title,
            price,
            quantity,
            description,
            productImageUrl,
            weight,
            size,
            variants
        );

        // if (title != '' && price != '' && productImageUrl != '') {
        //     submitForm(title, price, description, productImageUrl, weight, size, variants);
        // } else {
        //     setShowErrors('Please check Title,Category,Image and Price fields');
        // }
    };

    function handleProgressEvent({ loaded, total }) {
        setProgress(loaded / total);
    }

    const validateImage = (photoFile) => {
        if (photoFile) {
            const fileType = photoFile.type;
            const allowedImageTypes = {
                "image/jpeg": true,
                "image/png": true,
                "image/webp": true,
            };
            if (!allowedImageTypes.hasOwnProperty(fileType)) {
                setErrorMessage(allowedExt + " extensions are allowed");
                return false;
            } else {
                setErrorMessage(null);
                return true;
            }
        } else {
            return false;
        }
    };

    async function upload(event) {
        setProductImageUrl(null);
        const photoFile = event.target.files[0];
        setProductImage(photoFile);
        if (validateImage(photoFile)) {
            setFileUploadLoading(true);
            const uploadingPath = await UploadHelper.getSignedUrl(
                UploadHelper.PRODUCT_IMAGE
            );
            if (uploadingPath) {
                setProductImageUrl(uploadingPath.url);
                setFileUploadLoading(false);
                await UploadHelper.uploadToS3(
                    uploadingPath.signedUrl,
                    photoFile,
                    handleProgressEvent
                );
            }
        }
    }

    const submitForm = (
        title,
        price,
        quantity,
        description,
        productImageValue,
        weight,
        size,
        variants
    ) => {
        //
        setAddProductLoader(true);
        var token = localStorage.getItem("authSession");
        token = JSON.parse(token);
        const categoryValues = selectedOption
            .map((category) => category.value)
            .join(",");
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + "/user-insert-product", {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + userLoggedAccessToken(),
                },
                body: JSON.stringify({
                    id: token.idToken,
                    title: title,
                    price: price,
                    quantity: quantity,
                    product_image: productImageValue,
                    description: description,
                    weight: weight,
                    category: categoryValues,
                    productMultiImages: productMultiImages,
                    variants: hasVariants ? variants : [],
                    shipping: shipping,
                    lenght: lenght,
                    width: width,
                    height: height,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        response.json().then((json) => {
                            // let history_push_link;
                            if (json?.code?.toString() === "200") {



                                const productdata = json.productData;
                                const productId = json.data;
                                // if(history.location.state.source=="addvideo"){
                                // history_push_link = '/videos/add-nav-video/select-nav-product/lead-checkout-nav';
                                // }
                                const selectedProducts = [];
                                selectedProducts.push({
                                    value: productId,
                                    label: title,
                                    name: title,
                                    image: productImageValue,
                                    has_shipping: shipping
                                });

                                const pushStateData = {
                                    selectedProducts: selectedProducts,
                                    addVideoData: history.location.state.addVideoData,
                                    addStreamData: history.location.state.addStreamData,
                                    addFacebookData: history.location.state.addFacebookData,
                                    showSuccess:
                                        "Your " +
                                        textSignular +
                                        " has been added successfully next step is to publish your video " +
                                        accountTypeMessage,
                                };

                                let token = localStorage.getItem("authSession");
                                token = JSON.parse(token);

                                const draftData = {
                                    userdata: pushStateData,
                                    id: token.idToken,
                                    step: 2,
                                    draftType: "addvideo",
                                };

                                new Promise((resolve, reject) => {
                                    fetch(process.env.REACT_APP_API_URL + "/user-onboarding", {
                                        method: "POST",
                                        mode: "cors",
                                        headers: {
                                            "Content-Type": "application/json",
                                            Authorization: "Bearer " + token.accessToken,
                                        },
                                        body: JSON.stringify(draftData),
                                    });
                                });


                                setSelectedProduct([productdata])



                                setProductData(selectedProducts)

                                setIsVisible(false)

                                history.push({
                                    pathname:
                                        "/videos/add-nav-video/select-nav-product",
                                    state: pushStateData,
                                });

                                // history.push("/select-product")


                            } else {
                                setErrorMessage(json.message);

                                // history_push_link = "/add-product";
                            }
                        });
                    }
                })
                .catch((error) => {
                    setErrorMessage("Something went wrong!");
                })
                .finally((error) => {
                    setAddProductLoader(false);
                });
        });
        return fetch_prods;
    };

    var isButtonDisabled =
        !title?.trim() ||
        selectedOption.length < 1 ||
        !productImageUrl?.trim() ||
        titleError ||
        categoryError ||
        productMultiImagesError;

    if (!hasVariants) {
        if (!price?.trim() || priceError || quantityError) {
            isButtonDisabled = true;
        }
    }

    if (shipping == "paid-shipping") {
        if (
            !weight?.trim() ||
            weightError ||
            !lenght?.trim() ||
            lenghtError ||
            !width?.trim() ||
            widthtError ||
            !height?.trim() ||
            heightError
        ) {
            isButtonDisabled = true;
        }
    }

    const handleRadioChange = (value) => {



        setShipping(value);
    };

    const categoryMaxAllowedLimit = process.env.REACT_APP_SELECT_CATEGORY_MAX_LIMIT;


    return (

        <div>
            <div className="row">
                <div className="col-md-12">
                    <div className="w-500">
                        {/* <div className="card-header d-flex flex-row justify-content-between pl-4 pr-4">
                            <Button
                                className="btn btn-outline-primary"
                                type="button"
                                onClick={() => setIsVisible(false)}
                                buttonText="Cancel"
                            />
                            <h5 className="fw-bolder">Add New Product</h5>
                            <Button
                                className="btn btn-primary"
                                type="button"
                                onClick={handleSubmit}
                                buttonText="Done"
                                disabled={isButtonDisabled || addProductLoader}
                            />
                        </div> */}
                        <div className="row mt-2">
                            <div className="col-md-12">
                                <div className="mb-3">
                                    <Input
                                        id="title"
                                        type="text"
                                        label={`Product Title *`}
                                        value={title}
                                        onChange={handleTitleChange}
                                        disabled={false}
                                        placeholder=""
                                        errorMessage={titleError}
                                    />
                                </div>
                                <div className="mb-2">
                                    <Textarea
                                        id="description"
                                        label={`Product Description`}
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        disabled={false}
                                        placeholder=""
                                        errorMessage=""
                                    />
                                </div>
                                <FieldWarning message="Enter a detailed description of the product here, including its key features and benefits. Don't forget to specify available variations such as size, color, and style. Providing comprehensive information will help customers make informed choices." />
                                <div className="mt-3 mb-3">
                                    <MultiSelect
                                        limit={categoryMaxAllowedLimit}
                                        id="category"
                                        required={true}
                                        label="Category"
                                        options={options}
                                        value={selectedOption}
                                        onChange={handleCategoryChange}
                                        errorMessage={categoryError}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <Input
                                                id="price"
                                                type="number"
                                                label="Price *"
                                                value={price}
                                                onChange={handlePriceChange}
                                                disabled={hasVariants}
                                                placeholder=""
                                                errorMessage={priceError}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <QuantityInput
                                            id="quantity"
                                            label="Quantity"
                                            type="number"
                                            min="1"
                                            value={quantity}
                                            onChange={handleQuantityChange}
                                            disabled={hasVariants}
                                            placeholder=""
                                            errorMessage={quantityError}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4 mb-2">
                                    <div className="col-md-4 pr-0">
                                        <RadioButtonRow
                                            label="No Shipping Required"
                                            name="shipping_type"
                                            id="inlineRadiow2"
                                            value="no-shipping"
                                            disabled={false}
                                            checked={shipping === "no-shipping"}
                                            onChange={() => handleRadioChange("no-shipping")}
                                        />
                                    </div>
                                    <div className="col-md-4 pr-0">
                                        <RadioButtonRow
                                            label="Free Shipping"
                                            name="shipping_type"
                                            id="inlineRadio3"
                                            value="free-shipping"
                                            disabled={false}
                                            checked={shipping === "free-shipping"}
                                            onChange={() => handleRadioChange("free-shipping")}
                                        />
                                    </div>

                                    <div className="col-md-4 pr-0">
                                        <RadioButtonRow
                                            label="Paid Shipping"
                                            name="shipping_type"
                                            id="inlineRadio3s"
                                            value="paid-shipping"
                                            disabled={false}
                                            checked={shipping === "paid-shipping"}
                                            onChange={() => handleRadioChange("paid-shipping")}
                                        />
                                    </div>
                                </div>

                                {shipping === 'paid-shipping' && (
                                    <div className="mt-2">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="text-danger">In the video checkout, currently there is no support for products with paid shipping.</p>
                                                <div className="mb-3">
                                                    <Input
                                                        id="weight"
                                                        type="number"
                                                        label="Weight (In Grams) *"
                                                        value={weight}
                                                        onChange={handleWeightChange}
                                                        disabled={false}
                                                        placeholder=""
                                                        errorMessage={weightError}
                                                        min="1"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-md-12"><h5 className="text-black fw-semibold">Dimensions (L X W X H)</h5></div>
                                            <div className="col-md-4 fs-3">
                                                <Input
                                                    id="length"
                                                    type="number"
                                                    label="Length (In Centimeter) *"
                                                    value={lenght}
                                                    onChange={handleLenghtChange}
                                                    disabled={false}
                                                    placeholder=""
                                                    errorMessage={lenghtError}
                                                    min="1"
                                                />
                                            </div>
                                            <div className="col-md-4">
                                                <Input
                                                    id="width"
                                                    type="number"
                                                    label="Width (In Centimeter) *"
                                                    value={width}
                                                    onChange={handleWidthChange}
                                                    disabled={false}
                                                    placeholder=""
                                                    errorMessage={widthtError}
                                                    min="1"
                                                />
                                            </div>

                                            <div className="col-md-4">
                                                <Input
                                                    id="height"
                                                    type="number"
                                                    label="Height (In Centimeter) *"
                                                    value={height}
                                                    onChange={handleHeightChange}
                                                    disabled={false}
                                                    placeholder=""
                                                    errorMessage={heightError}
                                                    min="1"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12 mt-3">
                                {/* <div className="bg-gray-smoke py-4 px-4 br-1"> */}
                                {/* <p className="text-black fs-3 fw-semibold mb-1">Product Images</p> */}
                                <ImageUploader
                                    labelText="Product Images *"
                                    setErrorMessage={setErrorMessage}
                                    fieldDesc="Add Image"
                                    allowedImageTypes={allowedImageTypes}
                                    returnImagesUrls={handleMultipleImages}
                                    minLimit={minLimit}
                                    productMultiImagesError={productMultiImagesError}
                                    setProductMultiImagesError={setProductMultiImagesError}
                                />
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="w-500">
                        <div className="row mt-3 mb-4">
                            <div className="col-md-12 product-variants-container">
                                <div className="px-3 br-1 border border-light">
                                    <ProductVariants ref={variantsRef} setHasVariant={setHasVariant} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <div className="sticky-footer ">
                <div className="footer-container">
                    <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                        <Button
                            className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                            type="button"
                            buttonText="Back"
                            onClick={() => setIsVisible(false)}
                        />
                        <Button
                            className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                            type="button"
                            onClick={handleSubmit}
                            buttonText="Add Product"
                            fullButton={false}
                            disabled={isButtonDisabled || addProductLoader}
                        />
                    </div>
                </div>
            </div>

        </div>
    )
}
export default AddProductVideo;
