import React, { useState, useEffect } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { useHistory } from "react-router-dom";
import * as helper from "../../../helpers/helper";
import CheckOnBoardingSteps from "../Component/CheckOnBoardingSteps";
import validateField from "../../../helpers/AllValidtions";
import StreamVideoFormFields from '../../../Pages/Videos/StreamVideoFormFields'
import Input from "./../../../Form/Input"
import Textarea from "./../../../Form/Textarea"
import Select from "./../../../Form/Select"
import Tabs from "./../../../Form/Tabs"
import ScreenLoading from '../../Loadings/ScreenLoading';
import StreamVideoFacebookFormFields from "../../Videos/StreamVideoFacebookFormFields";
import Button from "../../../Form/Button";
import RecordVideoFormFields from "../../Videos/RecordVideoFormFields";
import UploadVideoFields from "../../Videos/UploadVideoFields";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../Form/FormToast";
import AddLeadForm from "../../Lead/AddLeadForm";
import { VideoSteps } from "./AddVideo";


export const CheckSubmitDisable = (
  streamType,
  videoName,
  videoType,
  streamSourceType,
  streamUrl,
  streamStartTime,
  streamEndTime,
  allStreamUploadError,
  facebookCode,
  allUploadError,
  wowzaId
) => {

  var disable = true;
  if (streamType === "live-stream-from-external-source") {
    disable = (!videoName || !videoName.trim() || !videoType || !streamSourceType || !streamUrl || !streamStartTime || !streamEndTime || allStreamUploadError);
  } else if (streamType === "facebook-integration") {
    disable = !videoName || !videoName.trim() || !videoType || !facebookCode || allUploadError;
  } else {
    disable = (!videoName || !videoName.trim() || !videoType || !wowzaId || !wowzaId.trim() || allUploadError);
  }

  return disable
}

export const PURPOSE_OF_VIDEOS = [
  { label: "Select purpose of video", value: "" },
  { label: "To sell your product", value: "To sell your product" },
  { label: "To generate lead", value: "To generate lead" },
  { label: "To collect the donation", value: "To collect the donation" },
];

function AddVideoLeadNav(...props) {
  const isMobileSize = helper.isMobile();
  const history = useHistory();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
  const [videoName, setVideoName] = useState('');
  const [videoDesc, setVideoDesc] = useState('');
  const [videoType, setVideoType] = useState('');
  const [videoNameError, setVideoNameError] = useState('');
  const [videoTypeError, setVideoTypeError] = useState();
  const [alluploadError, setAlluploadError] = useState(false);
  const [allstreamuploadError, setAllstreamuploadError] = useState(false);
  const [wowzaId, setWowzaId] = useState();
  const [errorMessage, setErrorMessage] = useState(false);
  const [videoThumbnailURL, setVideoThumbnailURL] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [userSubscription, setUserSubscription] = useState();
  const [streamType, setStreamType] = useState("upload-video");
  const [streamSourceType, setStreamSourceType] = useState("Live stream from URL");
  const [streamplatform, setStreamplatform] = useState();
  const [streamname, setStreamname] = useState();
  const [streamurl, setStreamurl] = useState();
  const [streamkey, setStreamkey] = useState();
  const [streamstarttime, setStreamstarttime] = useState();
  const [streamendtime, setStreamendtime] = useState();
  const [maxVideoTimeAllowedInSeconds, setMaxVideoTimeAllowedInSeconds] = useState();
  const [facebookCode, setFacebookCode] = useState();
  const [facebookUrl, setFacebookUrl] = useState();


  const [leadForm, setLeadForm] = useState();
  const [leadLoader, setLeadLoader] = useState(false);
  const [leaddata, setLeaddata] = useState();
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);
  const [leadFormId, setLeadFormId] = useState('');

  const [isVisible, setIsVisible] = useState(false);



  useEffect(() => {
    setMaxVideoTimeAllowedInSeconds(helper.allowedVideoTimeSeconds(userSubscription));
  }, [userSubscription]);

  useEffect(() => {
    fetchLeadData()
   
  }, []);

 


 
 

 



  useEffect(() => {

    if (history?.location?.state?.addVideoData?.leadForm) {
      setLeadForm(history?.location?.state?.addVideoData?.leadForm);
    }

    //leadForm

    // if(history?.location?.state?.selectedProducts){
    //   setSelectedOption(history?.location?.state?.selectedProducts);
    // }
  }, []);


  useEffect(() => {

    if (leadFormId > 0) {

      setLeadForm(leadFormId);

      proceedToProductPage(leadFormId)

    }



  }, [leadFormId]);


  const fetchLeadData = () => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/all-lead-form-list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "userId": token.idToken }),
    })
      .then((response) => response.json())
      .then((data) => {


        var userleadform = data.data;
        const userleadformvalue = userleadform.map(item => ({ label: item.title, value: item.id.toString() }));
        // Add the default option
        userleadformvalue.unshift({ label: "Select form", value: "" });


        setLeaddata(userleadformvalue)
        setLeadLoader(true)

      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  };

  const handleNameChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setVideoName(value);
    validateField(value, rule, setVideoNameError);

  };

  const handleTypeChange = (e) => {
    var value = e.target.value;
    var rule = "required";

    if (value == '') {
      setVideoType(value);
      validateField(value, rule, setVideoTypeError);
    } else {
      setVideoType(value);
      validateField(value, rule, setVideoTypeError);
    }
  };

  const handleLeadChange = (e) => {
    var value = e.target.value;
    var rule = "required";

    if (value == '') {
      setLeadForm(value);
      // proceedToProductPage(value)
    } else {
      setLeadForm(value);
      // proceedToProductPage(value)


    }

  };

  const producText = helper.showUserTypeBaseMessage({ NonProfit: "Donation Campaign", default: "Product" });

  const onStepIsPassed = (e) => {

    const user_data = JSON.parse(e?.user_data)
    const addVideoData = user_data?.addVideoData

    // history.push({
    //   pathname: '/add-product',
    //   state: {
    //     addVideoData: addVideoData
    //   },
    // });
  }

  const { loading: stepsLoading } = CheckOnBoardingSteps({ currentStep: 1, onStepIsPassed });

  const validateForm = () => {
    if (streamType === "live-stream-from-external-source") {
      const selectedStreamTime = helper.getDateDifferenceInMinutes(streamstarttime, streamendtime);

      if (selectedStreamTime > userSubscription?.addon_live_stream) {
        setErrorMessage("You do not have enough minutes left to schedule this stream. Please adjust your scheduling or consider upgrading your plan.")
        return false;
      }
    }

    return true;
  }





  function videoToAddProductPage() {
    if (!validateForm()) {
      return;
    }

    setErrorMessage('');

    const addVideoData = {
      videoName: videoName,
      videoDesc: videoDesc,
      videoType: videoType,
      streamType: streamType,
      wowzaId: wowzaId,
      leadForm: leadForm,
      videoThumbnailURL: videoThumbnailURL
    }

    const addStreamData = {
      streamsourcetype: streamSourceType,
      streamplatform: streamplatform,
      streamname: streamname,
      streamurl: streamurl,
      streamkey: streamkey,
      streamstarttime: streamstarttime,
      streamendtime: streamendtime,
      utc_offset: new Date().getTimezoneOffset()
    }

    const addFacebookData = {
      facebookCode: facebookCode,
      facebookUrl: facebookUrl,
    }

    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    const draftData = {
      userdata: { addVideoData: addVideoData, addStreamData: addStreamData, addFacebookData: addFacebookData },
      id: token.idToken,
      step: 1,
      draftType: 'onboarding'
    };

    new Promise((resolve, reject) => {
      fetch(process.env.REACT_APP_API_URL + '/user-onboarding', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + token.accessToken
        },
        body: JSON.stringify(draftData)
      });
    });

    history.push({
      pathname: '/add-product',
      state: {
        addVideoData: addVideoData,
        addStreamData: addStreamData,
        addFacebookData: addFacebookData,
        showSuccess: 'Your first video has been added successfully. The next step is to add a ' + producText + ' to your video'
      },
    });
  }

  const onNavBarClick = () => {
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }

  const getSubscriptionData = (e) => {
    setUserSubscription(e)
  }

  if (stepsLoading) {
    return <ScreenLoading />
  }

  var isButtonDisabled = CheckSubmitDisable(
    streamType,
    videoName,
    videoType,
    streamSourceType,
    streamurl,
    streamstarttime,
    streamendtime,
    allstreamuploadError,
    facebookCode,
    alluploadError,
    wowzaId
  );



  function proceedToProductPage(leadFormId) {


    const addVideoData = history.location.state.addVideoData
    const updatedAddVideoData = {
      ...addVideoData, // Copying all existing properties from addVideoData
      leadForm: leadFormId // Updating the leadForm property
    };


    history.push({
      pathname: "/videos/add-nav-video/select-nav-product",
      state: {
        addVideoData: updatedAddVideoData,
        addStreamData: history.location.state.addStreamData,
        addFacebookData: history.location.state.addFacebookData,
        showSuccess:
          "Your video has been added successfully. The next step is to add a " +
          producText +
          " to your video",
      },
    });


  }

  function handleId(leadFormId) {

    // console.log(leadFormId, "sajdain")

    // setLeadForm(leadFormId);


  }




  function addForm() {
    setIsVisible(true)
  }

  function hideForm() {
    setIsVisible(false)
  }


  const backToSelectType = () => {
    return history.push({
      // pathname: "/videos/add-nav-video",
      pathname: "/videos/video-purpose-nav",
      state: history.location.state,
    });
  }


  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">

      <Sidebar onNavBarClick={onNavBarClick} />
      <Page
        title="Add Video"
        onNavBarClick={onNavBarClick}
        formWidth={true}
        getSubscriptionData={getSubscriptionData}>
        <div className="row pb-50">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header vs-card-header">
                <h5 className="fw-bolder">Lead Form</h5>
                <VideoSteps step="3" purpose={history.location.state.addVideoData.videoType} />
              </div>
              <div className="card-body">
                <div className="">
                  <div className="">
                    {isVisible ? (
                      <div className=" mt-4">
                        <div className="d-flex flex-row justify-content-between pl-4 pr-4">
                          <h5 className="fw-bolder">Add Lead Form</h5>
                          <Button
                            onClick={hideForm}
                            buttonText="Cancel"
                            className="btn btn-outline-primary"
                          />
                        </div>

                        <div className="card-body">
                          <div className="row mt-2">
                            <AddLeadForm errors={errors} success={success} setErrors={setErrors} setSuccess={setSuccess} leadFormId={leadFormId} setLeadFormId={setLeadFormId} proceedToProductPage={proceedToProductPage} />
                          </div>
                        </div>
                      </div>
                    ) : (
                      
                      
                      <div className="w-500 mt-4">
                      <div className="card-body">
                      <p className="form-label mb-0">Select Lead Form</p>
                      <div className="d-flex justify-content-between" style={{ marginBottom: -16 }}>
                        <div className="mb-2">                        
                        <p>You can select your previous lead forms <br />or create a new lead form.</p>
                      </div>
                        <div className="action-buttons">
                          <Button
                            onClick={addForm}
                            buttonText="Create Lead Form"
                            className="btn btn-primary text-white"
                          />
                          </div>
                        </div>

                        
                          <div className="row mt-2">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <Select
                                  id="purpose"
                                  label="Please select form *"
                                  value={leadForm}
                                  onChange={handleLeadChange}
                                  disabled={false}
                                  options={leaddata}
                                  errorMessage={videoTypeError}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* {!isVisible ?
                    <div className="vs-form-footer d-flex justify-content-center align-items-center">
                      <Button
                        type="button"
                        onClick={() => { proceedToProductPage(leadForm) }}
                        buttonText="Next"
                        disabled={!leadForm?.length}
                      />
                    </div>

                    : ""} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
      {/* sticky footer */}
      {!isVisible &&
      <div className="sticky-footer ">
        <div className="footer-container">
          <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
            <Button
              className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
              type="button"
              buttonText="Back"
              onClick={backToSelectType}
            />
            <Button
              className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
              type="button"
              onClick={() => proceedToProductPage(leadForm)}
              buttonText="Next"
              disabled={!leadForm?.length}
            />
          </div>
        </div>
      </div>}
      {/* sticky footer */}
    </div>
  );
}

export default AddVideoLeadNav;