import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import moment from "moment";
import {
    isMobile,
} from "../../helpers/helper";
import ActivityLog from "../../helpers/ActivityLog";
// import { showUserTypeBaseMessage } from "../../helpers/helper";
import AuthCustom from "../../helpers/AuthCustom";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import { VideoSteps } from "../Onboarding/Video/AddVideo";
import Input from "../../Form/Input";
import Button from "../../Form/Button";
import RadioButtonRow from "../../Form/RadioButtonRow";
import { VideoPurpose } from "../../../Enums/VideoPurpose";
import FieldWarning from "../../Form/FieldWarning";
import ToggleSwitchRow from "../../Form/ToggleSwitchRow";
import SuccessCheckout from "../Onboarding/Checkout/SuccessCheckout";
import DateTimePicker from "../../Form/DateTimePicker";

const ExternalCheckoutNav = () => {
    const authCustom = new AuthCustom();
    const history = useHistory();
    const [publishDate, setPublishDate] = useState("");
    const [externalLink, setExternalLink] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [schedulePublish, setSchedulePublish] = useState(false);
    const [urlErrors, setUrlErrors] = useState([]);
    const [externalCheckoutLoader, setExternalCheckoutLoader] = useState(false);
    const [minDateTime, setMinDateTime] = useState("");
    const [checkoutTypes, setCheckoutTypes] = useState([]);
    const [user, setUser] = useState();
    const [publishDateError, setPublishDateError] = useState("");
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(
        isMobileSize ? "mini-sidebar" : "full"
    );
    // const addTextOnly = showUserTypeBaseMessage({
    //     NonProfit: "Add Donation Campaign",
    //     default: "Add Product",
    // });
    // const selectText = showUserTypeBaseMessage({
    //     NonProfit: "Select Donation Campaign",
    //     default: "Select Product",
    // });
    // const textPlural = showUserTypeBaseMessage({
    //     NonProfit: "Donation Campaigns",
    //     default: "Products",
    // });
    // const textSignular = showUserTypeBaseMessage({
    //     NonProfit: "Donation Campaign",
    //     default: "Product",
    // });
    const [urlproblemError, setProblemErrorErrors] = useState(true);
    const [allcheckout, setAllcheckout] = useState(false);
    const pagetitle = history.location.state.addVideoData.videoType;
    const title = "External Checkout";
    const fieldtitle = "External Checkout";
    const [videotype, setVideotype] = useState(title);
    // const [fieldtext, setFieldtext] = useState(fieldtitle);
    var streamType = history.location.state.addVideoData.streamType;
    const isVideoTypeLead = history.location.state.addVideoData.videoType === VideoPurpose.TO_GENERATE_LEAD;
    const isDonationVideo = history.location.state.addVideoData?.videoType === VideoPurpose.TO_COLLECT_THE_DONATION;

    const [payment, setPayment] = useState(false);
    const [inapp, setInapp] = useState(false);
    const [showSuccessModal, setSuccessModal] = useState(false);

    const [donationCheckoutType, setDonationCheckoutType] = useState("external_checkout"); // external_checkout, in_video
    const [donationAmounts, setDonationAmounts] = useState([
        { value: 5, error: "" },
        { value: 10, error: "" },
        { value: 15, error: "" },
        { value: 20, error: "" },
    ]);

    const onChangeDonationAmount = (value, index) => {
        const updatedItems = [...donationAmounts];
        const updatedItem = { ...updatedItems[index] };
        updatedItem.value = value;
        updatedItems[index] = updatedItem;
        setDonationAmounts(updatedItems);
    }

    const setUrlErrorsNew = (index, value) => {
        setUrlErrors((prevErrors) => {
            const newErrors = [...prevErrors];
            newErrors[index] = value;
            return newErrors;
        });
    };

    useEffect(() => {
        async function fetchUser() {
            const authSession = JSON.parse(
                window.localStorage.getItem("authSession")
            );
            const userResponse = await authCustom.getUserInfo(
                authSession.accessToken,
                authSession.idToken
            );
            setUser(userResponse);
            return () => { };
        }
        fetchUser();
    }, []);

    useEffect(() => {
        if (!history.location.state?.selectedProducts) {
            history.push({ pathname: "/" });
        }
        setMinDateTime(new Date().toISOString().slice(0, 16));
    }, []);

    async function validateAllUrls() {

        if (isVideoTypeLead) return

        const selectedProducts = history.location.state.selectedProducts;
        if (selectedProducts && selectedProducts.length >= 1) {
            // Wrap the map in Promise.all to wait for all promises to complete
            await Promise.all(
                selectedProducts.map(async (value, index) => {
                    await setExternalLinkMultiLink(
                        typeof value.externalLink !== "undefined"
                            ? value.externalLink
                            : null,
                        index
                    );
                })
            );
        }
    }

    const handlePubishRightNow = (e) => {
        setSchedulePublish(e.target.checked);
        if (e.target.checked === true) {
            setPublishDate(null);
            setPublishDateError("");
        } else {
            setPublishDate(moment());
        }
    }

    useEffect(() => {
        fetchUserPaymentGateway();
    }, []);

    const fetchUserPaymentGateway = () => {
        let token = localStorage.getItem("authSession");
        token = JSON.parse(token);

        fetch(process.env.REACT_APP_API_URL + "/check-payment-gateway", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token.accessToken,
            },
            body: JSON.stringify({ user_id: token.idToken }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.code?.toString() === "200" && pagetitle !== "To generate lead") {
                    setPayment(true);
                }
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    };

    function handlePubishDate(value) {
        let dateTimeOnly =  moment(value, "YYYY-MM-DD HH:mm:ss");
        setPublishDateError("");
        setPublishDate(value);
        if (dateTimeOnly?.isBefore(moment().add(0, "minutes"))) {
            setPublishDateError(
                "Publish date must be greater than " +
                moment().add(0, "minutes").format("D/M/Y h:mm a")
            );
        }
    }

    function setExternalLinkMultiLink(link_value, index_number) {
        if (checkoutTypes[index_number] === "custom") {
            if (link_value == null || !isURL(link_value)) {
                // urlErrors[index_number] = 'Please enter valid url';
                setUrlErrors((prevErrors) => {
                    prevErrors[index_number] = "Please enter a valid URL";
                    return [...prevErrors];
                });
                setProblemErrorErrors(true);
            } else {
                setUrlErrorsNew(index_number, null);
                setProblemErrorErrors(false);
                // urlErrors[index_number] = null;
            }

            // setUrlErrorsNew(urlErrors);

            setExternalLink(link_value);
            const selectedProducts = history.location.state.selectedProducts;
            if (
                typeof history.location.state.selectedProducts[index_number] !==
                "undefined"
            ) {
                selectedProducts[index_number].externalLink = link_value;
                selectedProducts[index_number].use_shopify_checkout = false;
                selectedProducts[index_number].use_woo_commerce_checkout = false;
                selectedProducts[index_number].use_in_video_checkout = false;
                selectedProducts[index_number].use_collect_info = false;
            }
        } else {
            const selectedProducts = history.location.state.selectedProducts;
            if (
                typeof history.location.state.selectedProducts[index_number] !==
                "undefined"
            ) {
                selectedProducts[index_number].externalLink = null;

                selectedProducts[index_number].externalLink = link_value;
                selectedProducts[index_number].use_in_video_checkout = false;

                if (checkoutTypes[index_number] === "shopify") {
                    selectedProducts[index_number].use_shopify_checkout = true;
                    selectedProducts[index_number].use_woo_commerce_checkout = false;
                    selectedProducts[index_number].use_collect_info = false;
                }

                if (checkoutTypes[index_number] === "woo_commerce") {
                    selectedProducts[index_number].use_woo_commerce_checkout = true;
                    selectedProducts[index_number].use_shopify_checkout = false;
                    selectedProducts[index_number].use_collect_info = false;
                }

                if (checkoutTypes[index_number] === "use_collect_info") {
                    selectedProducts[index_number].use_shopify_checkout = false;
                    selectedProducts[index_number].use_woo_commerce_checkout = false;
                    selectedProducts[index_number].use_collect_info = true;
                }

                if (checkoutTypes[index_number] === "in_video") {
                    selectedProducts[index_number].use_in_video_checkout = true;
                    selectedProducts[index_number].use_woo_commerce_checkout = false;
                    selectedProducts[index_number].use_collect_info = false;
                    selectedProducts[index_number].use_shopify_checkout = false;
                }
            }
        }
    }

    // const setInVideoCheckout = (index) => {
    //     console.log(checkoutTypes[index], "asdasdasdasd")

    //     const updatedCheckboxes = [...checkoutTypes];
    //     updatedCheckboxes[index] = updatedCheckboxes[index] === "in_video" ? "custom" : "in_video";
    //     setCheckoutTypes(updatedCheckboxes);
    //     setAllcheckout(true)
    //     updatedCheckboxes[index] == "custom" ? setProblemErrorErrors(true) : setProblemErrorErrors(false)

    //     console.log('updatedCheckboxes[index]>>',updatedCheckboxes[index]);

    //     if (updatedCheckboxes[index] === "in_video") {
    //         urlErrors[index] = null;
    //         setUrlErrorsNew(urlErrors);
    //     }
    //     // if (urlErrors.includes("Please enter valid url")) {
    //     //     setProblemErrorErrors(true)
    //     // }

    //     // validateAllUrls();

    // }

    const setInVideoCheckout = (index) => {
        // console.log(checkoutTypes[index], "asdasdasdasd")

        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] =
            updatedCheckboxes[index] === "in_video" ? "custom" : "in_video";
        setCheckoutTypes(updatedCheckboxes);
        setAllcheckout(true);
        updatedCheckboxes[index] === "custom"
            ? setProblemErrorErrors(true)
            : setProblemErrorErrors(false);

        if (updatedCheckboxes[index] === "in_video") {
            // urlErrors[index] = null;
            setUrlErrorsNew(index, null);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true);
        }
    };

    const setICollectInfoCheckout = (index) => {
        // console.log(checkoutTypes[index], "asdasdasdasd")

        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] =
            updatedCheckboxes[index] === "use_collect_info" ? "custom" : "use_collect_info";
        setCheckoutTypes(updatedCheckboxes);
        setAllcheckout(true);
        updatedCheckboxes[index] === "custom"
            ? setProblemErrorErrors(true)
            : setProblemErrorErrors(false);

        if (updatedCheckboxes[index] === "use_collect_info") {
            // urlErrors[index] = null;
            setUrlErrorsNew(index, null);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true);
        }
    };

    const setShopifyCheckoutType = (index) => {
        // console.log(checkoutTypes[index], "asdasdasdasd")

        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] =
            updatedCheckboxes[index] === "shopify" ? "custom" : "shopify";
        setCheckoutTypes(updatedCheckboxes);
        setAllcheckout(true);
        updatedCheckboxes[index] === "custom"
            ? setProblemErrorErrors(true)
            : setProblemErrorErrors(false);

        if (updatedCheckboxes[index] === "shopify") {
            console.log(updatedCheckboxes[index], "updatedCheckboxes");
            // urlErrors[index] = null;
            setUrlErrorsNew(index, null);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true);
        }
    };

    const setWooCommerceCheckoutType = (index) => {
        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] =
            updatedCheckboxes[index] === "woo_commerce" ? "custom" : "woo_commerce";
        setCheckoutTypes(updatedCheckboxes);
        setAllcheckout(true);
        updatedCheckboxes[index] === "custom"
            ? setProblemErrorErrors(true)
            : setProblemErrorErrors(false);
        console.log(updatedCheckboxes[index], "updatedCheckboxes");
        if (updatedCheckboxes[index] === "woo_commerce") {
            console.log(updatedCheckboxes[index], "updatedCheckboxes");
            // urlErrors[index] = null;
            setUrlErrorsNew(index, null);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true);
        }
    };

    const setCustomCheckoutLink = (index) => {
        const updatedCheckboxes = [...checkoutTypes];
        updatedCheckboxes[index] = "custom";

        setCheckoutTypes(updatedCheckboxes);
        setAllcheckout(true);
        updatedCheckboxes[index] === "custom"
            ? setProblemErrorErrors(true)
            : setProblemErrorErrors(false);
        if (updatedCheckboxes[index] === "woo_commerce") {
            setUrlErrorsNew(index, null);
        }
        if (urlErrors.includes("Please enter valid url")) {
            setProblemErrorErrors(true);
        }
    }

    // const ValidateNmoveToSuccessCheckout = () => {
    //     validateAllUrls();

    //     setTimeout(function (){
    //         const filteredUrlErrors = urlErrors?.filter((e) => e);
    //         console.log('ValidateNmoveToSuccessCheckout>>filteredUrlErrors',filteredUrlErrors?.length);

    //         moveToSuccessCheckout();
    //     },1000);
    // }

    const SaveVideo = async (params) => {
        setErrorMessage(false);
        setExternalCheckoutLoader(true);

        const accessToken = JSON.parse(window.localStorage.getItem("authSession"))
            .accessToken;

        var token = localStorage.getItem("authSession");
        token = JSON.parse(token);

        await fetch(process.env.REACT_APP_API_URL + "/add-video-with-product", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: JSON.stringify(params),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then((json) => {
                        if (json.code=="200") {

                        console.log(json.code,"billlllllll")



                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem("admin_login_id");
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 2;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({
                                user_id,
                                is_admin,
                                event_id,
                                account_id,
                            });

                            return setSuccessModal({
                                successVideoId: json.videoData.id,
                                successVideoShortUrl: json.videoData.video_short_url,
                                successWidgetAppId: json.widgetData.app_id,
                            })

                            // return history.push({
                            //     pathname: "/videos/add-nav-video/success-checkout-nav",
                            //     state: {
                            //         successVideoId: json.videoData.id,
                            //         successVideoShortUrl: json.videoData.video_short_url,
                            //         successWidgetAppId: json.widgetData.app_id,
                            //     },
                            // });
                        }else{
                            setErrorMessage(json.message);
                        }
                    });
                } else {
                    response.json().then((json) => {
                        if (json) {
                            const alreadyVideoStream = json?.data?.alreadyVideoStream;

                            if (alreadyVideoStream?.id) {
                                const dateFormat = "YYYY-MM-DD HH:mm:ss";
                                setErrorMessage(
                                    `You have already schedule a stream video between ${moment(
                                        alreadyVideoStream?.start_time
                                    ).format(dateFormat)} and ${moment(
                                        alreadyVideoStream?.end_time
                                    ).format(dateFormat)}`
                                );
                            } else {
                                setErrorMessage(json?.message);
                            }
                        } else {
                            setErrorMessage("Something went wrong");
                        }
                    });
                }
            })
            .catch(() => setErrorMessage("Something went wrong!"))
            .finally(() => setExternalCheckoutLoader(false));
    }

    async function moveToSuccessCheckout() {
        await validateAllUrls();
        // console.log('urlproblemError',urlproblemError);

        // mber].use_in_video_checkout = true;
        //             selectedProducts[index_number].use_woo_commerce_checkout = false;
        //             selectedProducts[index_number].use_shopify_checkout = false;

        const selectedProducts = history.location.state.selectedProducts;
        const index = selectedProducts?.findIndex(
            (product) => product?.use_in_video_checkout !== true
        );
        // const index = selectedProducts?.findIndex(product => product?.use_in_video_checkout !== true && product?.use_woo_commerce_checkout !== true && product?.use_shopify_checkout !== true && isURL(product?.externalLink)===false);

        const filteredUrlErrors = urlErrors?.filter((e) => e);
        if (filteredUrlErrors?.length > 0) {
            return;
        }

        // alert('moveToSuccessCheckout');
        // return;

        // if (getActualObjectLength(removeEmptyProperties(urlErrors)) >= 1) {
        //     setErrorMessage("Please validate fields!");
        // } else if ((schedulePublish == true && !moment(publishDate).isValid())) {
        //     setErrorMessage("Please validate publish date!");
        // } else if (schedulePublish == true && (!moment(publishDate).isAfter(moment().add(0, 'minutes')))) {
        //     setErrorMessage("Publish date must be greater than " + moment().add(0, 'minutes').format('D/M/Y h:mm a'));
        // } else {

        const params = {
            addVideoData: history.location.state,
            selectedProducts: history.location.selectedProducts,
            publishDate: publishDate,
            externalLink: isVideoTypeLead ? null : externalLink,
            isExternalCheckout: isVideoTypeLead ? false : true,
            inapp: inapp,
            utc_offset: new Date().getTimezoneOffset(),
        };

        await SaveVideo(params);
    }

    const backToMainProductSelection = () => {
        let redirectBackTo = history.location.state?.addVideoData?.videoType === 'To generate lead' ? "/videos/add-nav-video/add-video-lead-nav" : "/videos/add-nav-video/select-nav-product";

        return history.push({
            pathname: redirectBackTo,
            state: history.location.state,
        });
    }

    const backToPurposeScreen = () => {
        return history.push({
            pathname: "/videos/video-purpose-nav",
            state: history.location.state
        });
    }


    var isButtonDisabled = schedulePublish === true && (publishDateError || !moment(publishDate).isValid());


    // console.log('--isButtonDisabled--',isButtonDisabled);
    // console.log('--schedulePublish--',schedulePublish);
    // console.log('--publishDateError--',publishDateError);
    // console.log('--!moment(publishDate).isValid()--',!moment(publishDate).isValid());
    // console.log('--(publishDateError || !moment(publishDate).isValid()--',(publishDateError || !moment(publishDate).isValid()));


    var importedProductsCount = 0;
    if (user && history.location.state?.selectedProducts) {
        history.location.state?.selectedProducts.map((e) => {

            // If product is imported from shopify
            const isShopifyProduct =
                !!e?.shopify_product_id &&
                !!user?.shopify?.store &&
                !!user?.shopify?.storefront_access_token;
            if (isShopifyProduct) return importedProductsCount++;

            // If product is imported from Woo Commerce
            const isWooCommerceProduct =
                !!e?.woocommerce_product_id &&
                !!user?.woo_commerce?.store &&
                !!user?.woo_commerce?.consumer_key;
            if (isWooCommerceProduct) return importedProductsCount++;

            return null;
        });
    }

    const onNavBarClick = () => {
        setSidebarType((prevSidebarType) => prevSidebarType === "full" ? "mini-sidebar" : "full");
    };

    const onPressFinishDonation = async () => {

        setErrorMessage('');
        setDonationAmounts((d) => d.map(item => ({ ...item, error: "" })));

        if (donationCheckoutType === "external_checkout") {
            if (externalLink === "" || !isURL(externalLink)) {
                return setErrorMessage("Please write valid URL");
            }
        }

        if (schedulePublish) {
            if (!publishDate) {
                return setPublishDateError("Please select publish date")
            }

            let dateTimeOnly =  moment(publishDate, "YYYY-MM-DD HH:mm:ss");
            if (dateTimeOnly?.isBefore(moment().add(0, "minutes"))) {
                return setPublishDateError(
                    "Publish date must be greater than " +
                    moment().add(0, "minutes").format("D/M/Y h:mm a")
                );
            }
        }

        if (donationCheckoutType === "in_video") {
            const updatedItems = donationAmounts.map(item => ({
                ...item,
                error: item.value % 5 !== 0 ? "amount should be divisible by 5" : ""
            }));
            setDonationAmounts(updatedItems);

            const donationAmountErrors = updatedItems.reduce((accumulator, e) => {
                if (e.error !== "") {
                    accumulator++;
                }

                return accumulator;
            }, 0);

            if (donationAmountErrors > 0) {
                return;
            }
        }

        const params = {
            addVideoData: history.location.state,
            selectedProducts: null,
            publishDate: publishDate,
            externalLink: donationCheckoutType === "external_checkout" ? externalLink : null,
            isExternalCheckout: donationCheckoutType === "external_checkout" ? true : false,
            inapp: donationCheckoutType === "in_video",
            utc_offset: new Date().getTimezoneOffset(),
            donation_amounts: donationAmounts.map(a => a.value)
        };

        await SaveVideo(params);
    }

    const PublishVideoContent = () => {

        return (
            <div className="card">
                <div className="card-body">
                    {streamType !== "live-stream-from-external-source" && (<div className="d-flex justify-content-between mt-2 mb-2">
                        <h5>Schedule Video</h5>
                        <ToggleSwitchRow
                            id="schedule_video"
                            name="schedule_video"
                            label=""
                            checked={schedulePublish}
                            onChange={handlePubishRightNow}
                            disabled={false}
                            height="20px"
                            width="32px"
                        />
                    </div>)}
                    <div className="mb-2">
                        <FieldWarning message="Your video will be publish rightaway select date & time to schedule it." />
                    </div>
                    {/* <DateTimePicker 
                                value={publishDate}
                                onChange={(newDate) => handlePubishDate(newDate)}
                                isValidDate={(current) => current.isAfter(moment(minDateTime).subtract(1, 'day'), 'day')} // Disable dates before today
                            /> */}




                    {schedulePublish === true && (
                        <div className="mt-4 mb-2">

                            {/* <DateTimePicker /> */}

                            <Input
                                id="schedule_date"
                                type="datetime-local"
                                label="Publish Date *"
                                min={minDateTime}
                                value={publishDate}
                                onChange={(e) => handlePubishDate(e.target.value)}
                                disabled={false}
                                errorMessage={publishDateError}
                            />


                            <div className="datetimepicker-container">
                                {/* <Datetime
                                    value={publishDate}
                                    className="custom-calendar"
                                    inputProps={{ placeholder: 'Select Date and Time' }}
                                    // onChange={(newDate) => handlePubishDate(newDate)}
                                    onChange={(newDate) => handlePubishDate(newDate)}
                                    isValidDate={(current) => current.isAfter(moment(minDateTime).subtract(1, 'day'), 'day')} // Disable dates before today
                                /> */}

                                {/* <DateTimePicker
                                    value={publishDate}
                                    inputProps={{ placeholder: 'Select Date and Time' }}
                                    onChange={(newDate) => handlePubishDate(newDate)}
                                    isValidDate={(current) => current.isAfter(moment(minDateTime).subtract(1, 'day'), 'day')} // Disable dates before today
                                    errorMessage={publishDateError}
                                /> */}

                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    if (isDonationVideo) {
        return (
            <div
                className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
                data-sidebartype={sidebarType}
                id="main-wrapper"
                data-theme="blue_theme"
                data-layout="vertical"
                data-sidebar-position="fixed"
                data-header-position="fixed"
            >
                {errorMessage && (
                    <FormToast
                        type={FORM_TOAST_TYPES.DANGER}
                        message={errorMessage ? errorMessage : "Please publish date field."}
                    />
                )}

                {showSuccessModal && (<SuccessCheckout
                    {...showSuccessModal}
                    heading="You publish your video!"
                    subHeading="Your video is now ready"
                />)}

                <Sidebar onNavBarClick={onNavBarClick} />
                <Page onNavBarClick={onNavBarClick} title={videotype} formWidth={true}>
                    <div className="row pb-50">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header vs-card-header">
                                    <h5 className="fw-bolder">Publish your video</h5>
                                    <VideoSteps step="4" purpose={history.location.state.addVideoData.videoType} />
                                </div>
                                <div className="card-body">
                                    <div className="w-500">
                                        <div className="mb-4">
                                            <div className="mb-4">
                                                {payment ? (
                                                    <div className="vs-checkout-card mt-2 flex-column">
                                                        <h5 className="mt-2 text-black mb-3">Donation Checkout Type</h5>
                                                        <div className="mb-2">
                                                            <RadioButtonRow
                                                                label="In Video Checkout"
                                                                name="checkout_type"
                                                                id="in_video"
                                                                value="in_video"
                                                                disabled={false}
                                                                checked={donationCheckoutType === "in_video"}
                                                                onChange={() => setDonationCheckoutType("in_video")}
                                                            />
                                                        </div>
                                                        {
                                                            donationCheckoutType === "in_video" && (
                                                                <div className="mb-4">
                                                                    <h5 className="mt-2 text-black">Edit donation collection amount</h5>
                                                                    {
                                                                        donationAmounts.map((d, i) => (
                                                                            <div key={`donation_amount_${i}`} className="mt-2">
                                                                                <Input
                                                                                    type="number"
                                                                                    id={`donation_amount_${i}`}
                                                                                    value={d.value}
                                                                                    onChange={(e) => onChangeDonationAmount(e.target.value, i)}
                                                                                    disabled={donationCheckoutType !== "in_video"}
                                                                                    placeholder=""
                                                                                    errorMessage={d.error}
                                                                                />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            )
                                                        }
                                                        <div className="mb-2">
                                                            <RadioButtonRow
                                                                label="Custom Checkout link"
                                                                name={`checkout_type`}
                                                                id={`custom_type`}
                                                                value="custom"
                                                                disabled={false}
                                                                checked={donationCheckoutType === "external_checkout"}
                                                                onChange={() => setDonationCheckoutType("external_checkout")}
                                                            />
                                                        </div>
                                                        <div className="mb-7">
                                                            <Input
                                                                type="text"
                                                                id="custom_link"
                                                                label=""
                                                                value={externalLink}
                                                                onChange={(e) => setExternalLink(e.target.value)}
                                                                disabled={donationCheckoutType !== "external_checkout"}
                                                                placeholder="https://www.link.com/"
                                                                errorMessage={errorMessage}
                                                            />
                                                        </div>

                                                        
                                                    </div>
                                                ) : (
                                                    <>
                                                        <h5 className="mt-2 text-black">Custom Donation Collection Link *</h5>
                                                        <div className="mb-2 mt-2">
                                                            <Input
                                                                type="text"
                                                                id="custom_link"
                                                                label=""
                                                                value={externalLink}
                                                                onChange={(e) => setExternalLink(e.target.value)}
                                                                disabled={donationCheckoutType !== "external_checkout"}
                                                                placeholder="https://www.link.com/"
                                                                errorMessage={errorMessage}
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                            </div>

                                            <PublishVideoContent />

                                        </div>
                                    </div>
                                    {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                        <Button
                                            type="button"
                                            onClick={onPressFinishDonation}
                                            buttonText="Finish"
                                            fullButton={false}
                                            loading={externalCheckoutLoader}
                                            disabled={!user}
                                        />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
                {/* sticky footer */}
                <div className="sticky-footer ">
                    <div className="footer-container">
                        <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                            <Button
                                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                                type="button"
                                buttonText="Back"
                                onClick={backToPurposeScreen}
                            />
                            <Button
                                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                                type="button"
                                onClick={onPressFinishDonation}
                                buttonText="Finish"
                                fullButton={false}
                                loading={externalCheckoutLoader}
                                disabled={!user}
                            />
                        </div>
                    </div>
                </div>
                {/* sticky footer */}
            </div>
        )
    }


    return (
        <div
            className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
            data-sidebartype={sidebarType}
            id="main-wrapper"
            data-theme="blue_theme"
            data-layout="vertical"
            data-sidebar-position="fixed"
            data-header-position="fixed"
        >
            {errorMessage && (
                <FormToast
                    type={FORM_TOAST_TYPES.DANGER}
                    message={errorMessage ? errorMessage : "Please publish date field."}
                />
            )}

            {showSuccessModal && (<SuccessCheckout
                {...showSuccessModal}
                heading="You publish your video!"
                subHeading="Your video is now ready"
            />)}

            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title={videotype} formWidth={true}>
                <div className="row pb-50">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                <h5 className="fw-bolder">Publish your video</h5>
                                <VideoSteps step="4" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body">
                                <div className="w-500">
                                    {!isVideoTypeLead && <h5 className="mt-2 mb-2 text-black">Select checkout option for your products</h5>}

                                    <div className="mb-4 mt-2">
                                        {
                                            !isVideoTypeLead && (
                                                <>
                                                    {user &&
                                                        history.location.state?.selectedProducts.map(
                                                            (value, index) => {
                                                                const isShopifyProduct =
                                                                    !!value?.shopify_product_id &&
                                                                    !!user?.shopify?.store &&
                                                                    !!user?.shopify?.storefront_access_token;
                                                                const isWooCommerceProduct =
                                                                    !!value?.woocommerce_product_id &&
                                                                    !!user?.woo_commerce?.store &&
                                                                    !!user?.woo_commerce?.consumer_key;
                                                                const isShippingProduct = value.has_shipping;

                                                                // Set default checkout type shopify
                                                                if (!checkoutTypes[index]) {
                                                                    checkoutTypes[index] = isShopifyProduct
                                                                        ? "shopify"
                                                                        : isWooCommerceProduct
                                                                            ? "woo_commerce"
                                                                            : "custom";
                                                                    setCheckoutTypes(checkoutTypes);
                                                                    checkoutTypes[index] === "custom"
                                                                        ? setProblemErrorErrors(true)
                                                                        : setProblemErrorErrors(false);
                                                                }

                                                                if (inapp) {
                                                                    return null;
                                                                }

                                                                return (
                                                                    <div className="vs-checkout-card mb-4">
                                                                        <div className="vs-checkout-card-img-conatiner">
                                                                            <div className="vs-checkout-card-img mb-3">
                                                                                <img src={value.image} alt={value.name} />
                                                                            </div>
                                                                            <div className="vs-checkout-card-cotent">
                                                                                <h5 className="text-black">{value.name}</h5>
                                                                            </div>
                                                                        </div>
                                                                        <div className="vs-checkout-card-content">
                                                                            <h3>{value.name}</h3>
                                                                            {isShippingProduct !== "paid-shipping" && payment && (
                                                                                <div className="mb-2">
                                                                                    <RadioButtonRow
                                                                                        id={`in_video_type_${index}`}
                                                                                        name={`checkout_type_${index}`}
                                                                                        value="in_video"
                                                                                        label="In Video Checkout"
                                                                                        checked={checkoutTypes[index] === "in_video"}
                                                                                        onChange={(e) => setInVideoCheckout(index)}
                                                                                        disabled={false}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {isShopifyProduct && (
                                                                                <div className="mb-2">
                                                                                    <RadioButtonRow
                                                                                        label="Shopify Checkout"
                                                                                        name={`checkout_type_${index}`}
                                                                                        id={`shopify_type_${index}`}
                                                                                        value="shopify"
                                                                                        disabled={false}
                                                                                        checked={checkoutTypes[index] === "shopify"}
                                                                                        onChange={(e) => setShopifyCheckoutType(index)}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            {isWooCommerceProduct && (
                                                                                <div className="mb-2">
                                                                                    <RadioButtonRow
                                                                                        label="Woocommerce Checkout"
                                                                                        name={`checkout_type_${index}`}
                                                                                        id={`shopify_type_${index}`}
                                                                                        value="woo_commerce"
                                                                                        disabled={false}
                                                                                        checked={checkoutTypes[index] === "woo_commerce"}
                                                                                        onChange={(e) => setWooCommerceCheckoutType(index)}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                            <div className="mb-2">
                                                                                <RadioButtonRow
                                                                                    label="Collect Customer Info Only"
                                                                                    name={`checkout_type_${index}`}
                                                                                    id={`use_collect_info_${index}`}
                                                                                    value="use_collect_info"
                                                                                    disabled={false}
                                                                                    checked={checkoutTypes[index] === "use_collect_info"}
                                                                                    onChange={(e) => setICollectInfoCheckout(index)}
                                                                                />
                                                                            </div>
                                                                            <div className="mb-0">
                                                                                <RadioButtonRow
                                                                                    label="Custom Checkout link"
                                                                                    name={`checkout_type_${index}`}
                                                                                    id={`custom_type_${index}`}
                                                                                    value="custom"
                                                                                    disabled={false}
                                                                                    checked={checkoutTypes[index] === "custom"}
                                                                                    onChange={(e) => setCustomCheckoutLink(index)}
                                                                                />
                                                                            </div>
                                                                            <div className="pl-6">
                                                                                <Input
                                                                                    id={`custom_link_${index}`}
                                                                                    type="text"
                                                                                    label=""
                                                                                    value={value?.externalLink}
                                                                                    onChange={(e) => setExternalLinkMultiLink(e.target.value, index)}
                                                                                    disabled={checkoutTypes[index] !== "custom"}
                                                                                    placeholder={"https://www.link" + index + ".com/"}
                                                                                    errorMessage={typeof urlErrors[index] !== "undefined" &&
                                                                                        urlErrors[index] !== null &&
                                                                                        urlErrors[index]?.length >= 1 && "Please enter correct URL"}
                                                                                    key={index}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )

                                                                return (
                                                                    <div className="card">
                                                                        <div className="card-body">
                                                                            <div>
                                                                                <div className="d-flex align-items-center gap-9">
                                                                                    <div className="checkout-product-img">
                                                                                        <img alt={value?.name} src={value?.image} />
                                                                                    </div>
                                                                                    <h5 className="text-black fs-5">{value?.name}</h5>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-flex flex-column  mt-4">
                                                                                {isShippingProduct !== "paid-shipping" && payment && (
                                                                                    <div className="form-check mt-1">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name={`checkout_type_${index}`}
                                                                                            id={`in_video_type_${index}`}
                                                                                            value="in_video"
                                                                                            checked={checkoutTypes[index] === "in_video"}
                                                                                            onChange={(e) => setInVideoCheckout(index)}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label user-select-none"
                                                                                            for={`in_video_type_${index}`}
                                                                                            role="button"
                                                                                        >
                                                                                            IN-Video
                                                                                        </label>
                                                                                    </div>
                                                                                )}
                                                                                {isShopifyProduct && (
                                                                                    <div className="form-check mt-1">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name={`checkout_type_${index}`}
                                                                                            id={`shopify_type_${index}`}
                                                                                            value="shopify"
                                                                                            checked={checkoutTypes[index] === "shopify"}
                                                                                            onChange={(e) => setShopifyCheckoutType(index)}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label user-select-none"
                                                                                            for={`shopify_type_${index}`}
                                                                                            role="button"
                                                                                        >
                                                                                            Shopify Checkout
                                                                                        </label>
                                                                                    </div>
                                                                                )}
                                                                                {isWooCommerceProduct && (
                                                                                    <div className="form-check mt-1">
                                                                                        <input
                                                                                            className="form-check-input"
                                                                                            type="radio"
                                                                                            name={`checkout_type_${index}`}
                                                                                            id={`shopify_type_${index}`}
                                                                                            value="shopify"
                                                                                            checked={checkoutTypes[index] === "woo_commerce"}
                                                                                            onChange={(e) => setWooCommerceCheckoutType(index)}
                                                                                        />
                                                                                        <label
                                                                                            className="form-check-label user-select-none"
                                                                                            for={`shopify_type_${index}`}
                                                                                            role="button"
                                                                                        >
                                                                                            WooCommerce Checkout
                                                                                        </label>
                                                                                    </div>
                                                                                )}
                                                                                <div className="form-check mt-1">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name={`checkout_type_${index}`}
                                                                                        id={`use_collect_info_${index}`}
                                                                                        value="use_collect_info"
                                                                                        checked={checkoutTypes[index] === "use_collect_info"}
                                                                                        onChange={(e) => setICollectInfoCheckout(index)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label user-select-none"
                                                                                        for={`use_collect_info_${index}`}
                                                                                        role="button"
                                                                                    >
                                                                                        Collect Info
                                                                                    </label>
                                                                                </div>
                                                                                <div className="form-check mt-1">
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type="radio"
                                                                                        name={`checkout_type_${index}`}
                                                                                        id={`custom_type_${index}`}
                                                                                        value="custom"
                                                                                        checked={checkoutTypes[index] === "custom"}
                                                                                        onChange={(e) => setCustomCheckoutLink(index)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label user-select-none"
                                                                                        for={`custom_type_${index}`}
                                                                                        role="button"
                                                                                    >
                                                                                        Custom Checkout Link
                                                                                    </label>
                                                                                </div>

                                                                            </div>
                                                                            <div className="mb-2">
                                                                                <Input
                                                                                    id={`custom_link_${index}`}
                                                                                    type="text"
                                                                                    label=""
                                                                                    value={value?.externalLink}
                                                                                    onChange={(e) => setExternalLinkMultiLink(e.target.value, index)}
                                                                                    disabled={checkoutTypes[index] !== "custom"}
                                                                                    placeholder={"https://www.link" + index + ".com/"}
                                                                                    errorMessage={typeof urlErrors[index] !== "undefined" &&
                                                                                        urlErrors[index] !== null &&
                                                                                        urlErrors[index]?.length >= 1 && "Please enter correct URL"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </>
                                            )
                                        }

                                        <PublishVideoContent />
                                    </div>
                                </div>
                                {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                    <Button
                                        type="button"
                                        onClick={moveToSuccessCheckout}
                                        buttonText="Finish"
                                        fullButton={false}
                                        loading={externalCheckoutLoader}
                                        disabled={isButtonDisabled || externalCheckoutLoader || !user}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            {/* sticky footer */}
            <div className="sticky-footer ">
                <div className="footer-container">
                    <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                        <Button
                            className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                            type="button"
                            buttonText="Back"
                            onClick={backToMainProductSelection}
                        />
                        <Button
                            className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                            type="button"
                            onClick={moveToSuccessCheckout}
                            buttonText="Finish"
                            fullButton={false}
                            loading={externalCheckoutLoader}
                            disabled={isButtonDisabled || externalCheckoutLoader || !user}
                        />
                    </div>
                </div>
            </div>
            {/* sticky footer */}
        </div>
    );
}
export default ExternalCheckoutNav;