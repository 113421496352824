import React, { useState, useEffect } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import * as helper from "../../helpers/helper";
import StreamVideoFormFields from "./StreamVideoFormFields";
import validateField from "../../helpers/AllValidtions";
import moment from "moment";
import {
  CheckSubmitDisable,
  // PURPOSE_OF_VIDEOS,
  VideoSteps,
} from "../Onboarding/Video/AddVideo";
import StreamVideoFacebookFormFields from "./StreamVideoFacebookFormFields";
import RecordVideoFormFields from "./RecordVideoFormFields";
import UploadVideoFields from "./UploadVideoFields";
import Tabs from "../../Form/Tabs";
// import Select from "../../Form/Select";
import Textarea from "../../Form/Textarea";
import Input from "../../Form/Input";
import Button from "../../Form/Button";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import {
  loggedUserAccountType,
  showUserTypeBaseMessage,
  userLoggedAccessToken,
  daysRemaining,
  loggedUserName,
} from "../../../component/helpers/helper";



function AddVideoNav(...props) {

  const history = useHistory();
  const [videoName, setVideoName] = useState();
  const [videoDesc, setVideoDesc] = useState();
  const [videoType, setVideoType] = useState();
  const [streamType, setStreamType] = useState("upload-video");
  const [maxVideoTimeAllowedInSeconds, setMaxVideoTimeAllowedInSeconds] = useState();
  const [uploading, setUploading] = useState(false);
  const [wowzaId, setWowzaId] = useState();
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [videoThumbnailURL, setVideoThumbnailURL] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [streamSourceType, setStreamSourceType] = useState("Live stream from URL");
  const [streamplatform, setStreamplatform] = useState();
  const [streamname, setStreamname] = useState();
  const [streamurl, setStreamurl] = useState();
  const [streamkey, setStreamkey] = useState();
  const [streamstarttime, setStreamstarttime] = useState();
  const [streamendtime, setStreamendtime] = useState();
  const [userSubscription, setUserSubscription] = useState();
  const isMobileSize = helper.isMobile();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? "mini-sidebar" : "full");
  const [videoNameError, setVideoNameError] = useState("");
  const [videoTypeError, setVideoTypeError] = useState();
  const [alluploadError, setAlluploadError] = useState(false);
  const [allstreamuploadError, setAllstreamuploadError] = useState(false);
  const [facebookCode, setFacebookCode] = useState();
  const [facebookUrl, setFacebookUrl] = useState();
  const [leadForm, setLeadForm] = useState();
  const [leadLoader, setLeadLoader] = useState(false);
  const [leaddata, setLeaddata] = useState();
  const [draftVideoId, setDraftVideoId] = useState();

  const [content, setContent] = useState('');

  const [userplanType, setUserplanType] = useState();

  const handleEditorChange = (content) => {

    setContent(content);
    // setVideoDesc(content);
    console.log('Content was updated:', content);
  };

  const producText = helper.showUserTypeBaseMessage({
    NonProfit: "Donation Campaign",
    default: "Product",
  });

  // const handledescChange = (content) => {
  //  setVideoDesc(content);
  // };


  useEffect(() => {
    userProfileType();
  }, []);

  const userProfileType = async () => {
    try {
      var token = localStorage.getItem("authSession");
      token = JSON.parse(token);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/user-subscribe-type",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.accessToken,
          },
          body: JSON.stringify({ user_id: token.idToken }),
        }
      );

      const data = await response.json();

      // console.log(data.data[0].plan_type,"bilalllll")
      setUserplanType(data.data[0].plan_type)

      // return data.code === "200";
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {

    const backStateData = history?.location?.state;
    const addVideoData = backStateData?.addVideoData;
    const addFacebookData = backStateData?.addFacebookData;
    const addStreamData = backStateData?.addStreamData;

    if (backStateData) {
      setVideoName(addVideoData?.videoName);
      setVideoDesc(addVideoData?.videoDesc);
      setVideoThumbnailURL(addVideoData?.videoThumbnailURL);
      setVideoType(addVideoData?.videoType);
      setWowzaId(addVideoData?.wowzaId);
      setDraftVideoId(addVideoData?.videoId);

      setStreamType(addVideoData?.streamType);

      setFacebookCode(addFacebookData?.facebookCode);
      setFacebookUrl(addFacebookData?.facebookUrl);

      setStreamSourceType(addStreamData?.streamsourcetype);
      setStreamkey(addStreamData?.streamkey);
      setStreamname(addStreamData?.streamname);
      setStreamplatform(addStreamData?.streamplatform);

      setStreamstarttime(addStreamData?.streamstarttime);
      setStreamendtime(addStreamData?.streamendtime);

      setStreamurl(addStreamData?.streamurl);


    }

    // setMaxVideoTimeAllowedInSeconds(
    //   helper.allowedVideoTimeSeconds(userSubscription)
    // );
  }, [history?.location?.state]);

  useEffect(() => {



    setMaxVideoTimeAllowedInSeconds(
      helper.allowedVideorecordTimeSeconds(userSubscription)
    );
  }, [userSubscription]);

  useEffect(() => {
    fetchLeadData()
  }, []);

  const fetchLeadData = () => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/all-lead-form-list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "userId": token.idToken }),
    })
      .then((response) => response.json())
      .then((data) => {


        var userleadform = data.data;
        const userleadformvalue = userleadform.map(item => ({ label: item.title, value: item.id.toString() }));
        // Add the default option
        userleadformvalue.unshift({ label: "Select form", value: "" });


        setLeaddata(userleadformvalue)
        setLeadLoader(true)

      })
      .catch((error) => {
        // console.error('Error fetching data:', error);
      });
  };

  const handleNameChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setVideoName(value);

    validateField(value, rule, setVideoNameError);
  };

  const handleTypeChange = (e) => {
    var value = e.target.value;
    var rule = "required";

    if (value == "") {
      setVideoType(value);
      validateField(value, rule, setVideoTypeError);
    } else {
      setVideoType(value);
      validateField(value, rule, setVideoTypeError);
    }
  };

  const handleLeadChange = (e) => {
    var value = e.target.value;
    var rule = "required";

    if (value == '') {
      setLeadForm(value);
    } else {
      setLeadForm(value);


    }

  };


  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  useEffect(() => {
    const fetchDraftVideoData = new Promise((resolve, reject) => {
      const draftVideoId = helper.urlSegment(2);
      fetch(process.env.REACT_APP_API_URL + "/user-onboarding-data", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + helper.userLoggedAccessToken(),
        },
        body: JSON.stringify({
          draftType: "addvideo",
          draftVideoId: draftVideoId,
        }),
      }).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            if (json.data?.length > 0) {
              const user_data = json.data[0].user_data;
              const step = json.data[0].step;
              let history_push_link;

              let push_data = JSON.parse(user_data);


              if (push_data?.addVideoData?.videoType === 'To generate lead') {

                // leadForm

                if (push_data?.addVideoData?.leadForm) {
                  history_push_link = "/videos/add-nav-video/select-nav-product/external-checkout-nav";
                } else if (step == 2) {
                  history_push_link = "/videos/add-nav-video/add-video-lead-nav";
                } else if (step == 3) {
                  history_push_link =
                    "/videos/add-nav-video/select-nav-product/lead-checkout-nav";
                }


              } else if (push_data?.addVideoData?.videoType === 'To sell your product') {
                if (push_data?.selectedProducts?.length) {
                  history_push_link = "/videos/add-nav-video/select-nav-product/lead-checkout-nav";

                } else {
                  // alert('products are selected redirect to select product screen')
                  history_push_link = "/videos/add-nav-video/select-nav-product";

                }

              } else if (!push_data?.addVideoData?.videoType) {
                history_push_link = "/videos/video-purpose-nav";
              } else {
                if (step == 1) {
                  history_push_link = "/videos/add-nav-video/select-nav-product";
                } else if (step == 2) {
                  history_push_link =
                    "/videos/add-nav-video/select-nav-product/lead-checkout-nav";
                }
              }

              if (history_push_link) {
                history.push({
                  pathname: history_push_link,
                  state: push_data,
                });
              }
            }
          });
        }
      });
    });
  }, []);

  const validateForm = () => {
    if (streamType === "live-stream-from-external-source") {
      const selectedStreamTime = helper.getDateDifferenceInMinutes(
        streamstarttime,
        streamendtime
      );

      if (selectedStreamTime > userSubscription?.addon_live_stream) {
        setErrorMessage(
          "You do not have enough minutes left to schedule this stream. Please adjust your scheduling or consider upgrading your plan."
        );
        return false;
      }
    }

    return true;
  };

  function videoToAddProductPage() {
    if (!validateForm()) {
      return;
    }

    const addVideoData = {
      videoName: videoName,
      videoDesc: videoDesc,
      videoType: videoType,
      streamType: streamType,
      wowzaId: wowzaId,
      leadForm: leadForm,
      videoThumbnailURL: videoThumbnailURL,
    };

    const addStreamData = {
      streamsourcetype: streamSourceType,
      streamplatform: streamplatform,
      streamname: streamname,
      streamurl: streamurl,
      streamkey: streamkey,
      streamstarttime: streamstarttime,
      streamendtime: streamendtime,
      utc_offset: new Date().getTimezoneOffset(),
    };

    const addFacebookData = {
      facebookCode: facebookCode,
      facebookUrl: facebookUrl,
    };

    if (videoName != null) {
      let token = localStorage.getItem("authSession");
      token = JSON.parse(token);
      const draftData = {
        userdata: {
          addVideoData: addVideoData,
          addStreamData: addStreamData,
          addFacebookData: addFacebookData,
        },
        id: token.idToken,
        step: 1,
        draftType: "addvideo",
        videoId: draftVideoId,
      };

      const draftVideoData = new Promise((resolve, reject) => {
      
        setUploading(true);

        fetch(process.env.REACT_APP_API_URL + "/user-onboarding", {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token.accessToken,
          },
          body: JSON.stringify(draftData),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data?.status === false) {


             

              const alreadyVideoStream = data?.data?.alreadyVideoStream;

             

              if (alreadyVideoStream?.id) {
              
                const dateFormat = "YYYY-MM-DD HH:mm:ss";
                setErrorMessage(
                  `You have already schedule a stream video between ${moment(
                    alreadyVideoStream?.start_time
                  ).format(dateFormat)} and ${moment(
                    alreadyVideoStream?.end_time
                  ).format(dateFormat)}`
                );
              } else {
                setErrorMessage(`Something went wrong`);
              }
            } else {

             

              addVideoData.videoId = data?.data?.videoId;
              history.push({
                pathname: "/videos/video-purpose-nav",
                state: {
                  addVideoData: addVideoData,
                  addStreamData: addStreamData,
                  addFacebookData: addFacebookData,
                  showSuccess:
                    "Your video has been added successfully. The next step is to add a " +
                    producText +
                    " to your video",
                },
              });



              // if (videoType == 'To generate lead') {

              //   addVideoData.videoId = data?.data?.videoId;
              //   history.push({
              //     pathname: "/videos/add-nav-video/add-video-lead-nav",
              //     state: {
              //       addVideoData: addVideoData,
              //       addStreamData: addStreamData,
              //       addFacebookData: addFacebookData,
              // showSuccess:
              //   "Your video has been added successfully. The next step is to add a " +
              //   producText +
              //   " to your video",
              //     },
              //   });




              // } else {


              //   addVideoData.videoId = data?.data?.videoId;
              //   history.push({
              //     pathname: "/videos/add-nav-video/select-nav-product",
              //     state: {
              //       addVideoData: addVideoData,
              //       addStreamData: addStreamData,
              //       addFacebookData: addFacebookData,
              //       showSuccess:
              //         "Your video has been added successfully. The next step is to add a " +
              //         producText +
              //         " to your video",
              //     },
              //   });


              // }







            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setUploading(false);
          });
      });

      setShowErrors(false);
    } else {
      setShowErrors(true);
    }
  }

  const getSubscriptionData = (e) => {

    setUserSubscription(e);
  };

  var isButtonDisabled = CheckSubmitDisable(
    streamType,
    videoName,
    streamSourceType,
    streamurl,
    streamstarttime,
    streamendtime,
    allstreamuploadError,
    facebookCode,
    alluploadError,
    wowzaId
  );


  const tabs =  [
    {
      key: "upload-video",
      label: "Upload Video",
      component: (
        <UploadVideoFields
          videoThumbnailURL={videoThumbnailURL}
          setVideoThumbnailURL={setVideoThumbnailURL}
          fileUploadLoading={fileUploadLoading}
          setFileUploadLoading={setFileUploadLoading}
          maxVideoTimeAllowedInSeconds={
            maxVideoTimeAllowedInSeconds
          }
          setWowzaId={setWowzaId}
          wowzaId={wowzaId}
        />
      ),
    },
    {
      key: "record-video",
      label: "Record Video",
      component: (
        <RecordVideoFormFields
          videoThumbnailURL={videoThumbnailURL}
          setVideoThumbnailURL={setVideoThumbnailURL}
          fileUploadLoading={fileUploadLoading}
          setFileUploadLoading={setFileUploadLoading}
          setParentErrorMessage={setErrorMessage}
          maxVideoTimeAllowedInSeconds={
            maxVideoTimeAllowedInSeconds
          }
          setWowzaId={setWowzaId}
        />
      ),
    },
    {
      key: "live-stream-from-external-source",
      label: "Live Stream",
      component: (
        <StreamVideoFormFields
          streamplatform={streamplatform}
          setStreamplatform={setStreamplatform}
          streamname={streamname}
          setStreamname={setStreamname}
          streamurl={streamurl}
          setStreamurl={setStreamurl}
          streamkey={streamkey}
          setStreamkey={setStreamkey}
          setStreamstarttime={setStreamstarttime}
          setStreamendtime={setStreamendtime}
          setErrorMessage={setErrorMessage}
          streamstarttime={streamstarttime}
          streamendtime={streamendtime}
          streamSourceType={streamSourceType}
          setStreamSourceType={setStreamSourceType}
          setAllstreamuploadError={
            setAllstreamuploadError
          }
          userSubscription={userSubscription}
        />
      ),
    },
    {
      key: "facebook-integration",
      label: "Facebook Video",
      component: (
        <StreamVideoFacebookFormFields
          setFacebookUrl={setFacebookUrl}
          facebookCode={facebookCode}
          setFacebookCode={setFacebookCode}
          videoThumbnailURL={videoThumbnailURL}
          setVideoThumbnailURL={setVideoThumbnailURL}
          fileUploadLoading={fileUploadLoading}
          setFileUploadLoading={setFileUploadLoading}
        />
      ),
    },
  ];

  const filteredTabs = userplanType === "free" 
  ? tabs.filter(tab => tab.key !== "live-stream-from-external-source")
  : tabs;

  return (
    <div
      className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
      data-sidebartype={sidebarType}
      id="main-wrapper"
      data-theme="blue_theme"
      data-layout="vertical"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >

            {errorMessage && (
                    <FormToast
                        type={FORM_TOAST_TYPES.DANGER}
                        message={errorMessage ? errorMessage : "Please publish date field."}
                    />
                )}



      <Sidebar onNavBarClick={onNavBarClick} />
      <Page
        title="Add Video"
        onNavBarClick={onNavBarClick}
        formWidth={true}
        getSubscriptionData={getSubscriptionData}
      >
        <div className="row pb-50">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header vs-card-header">
                <h5 className="fw-bolder">Upload Video</h5>
                <VideoSteps step="1" />
              </div>
              <div className="card-body">
                <div className="w-500">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <Input
                          id="title"
                          type="text"
                          label="Video Title *"
                          value={videoName}
                          onChange={handleNameChange}
                          disabled={false}
                          placeholder=""
                          errorMessage={videoNameError}
                        />
                      </div>
                      <div className="mb-3">

                        {/* <Textarea
                          id="description"
                          label="Description"
                          value={videoDesc}
                          onChange={(e) => setVideoDesc(e.target.value)}
                          disabled={false}
                          placeholder=""
                          errorMessage=""
                        /> */}

                        <label className="form-label">Video Description</label>
                        <Editor
                          tinymceScriptSrc='/tinymce/tinymce.min.js'
                          apiKey={process.env.REACT_APP_TINYMCE_KEY}
                          init={{
                            promotion: false,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            toolbar: 'undo redo | bold italic underline strikethrough  | numlist bullist | removeformat',
                            menubar: 'insert format table',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                              { value: 'First.Name', title: 'First Name' },
                              { value: 'Email', title: 'Email' },
                            ],
                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                            branding: false
                          }}
                          value={videoDesc}
                          onEditorChange={(newValue, editor) => setVideoDesc(newValue)}
                        />

                      </div>
                    </div>

        

                    <div className="col-md-12">
                      <div className="video-upload-type-container">
                        <Tabs
                          selectedTab={streamType}
                          tabs={filteredTabs}
                          onChange={(selectedTab) => {
                            setStreamType(selectedTab.key);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                  <Button
                    type="button"
                    onClick={videoToAddProductPage}
                    buttonText="Next"
                    fullButton={false}
                    loading={false}
                    disabled={isButtonDisabled || uploading}
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </Page>
      {/* sticky footer */}
      <div className="sticky-footer ">
        <div className="footer-container">
          <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
            <Button
              className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
              type="button"
              buttonText="Back"
              onClick={() => history.push("/videos")}
            />
            <Button
              className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
              type="button"
              onClick={videoToAddProductPage}
              buttonText="Next"
              fullButton={false}
              loading={false}
              disabled={isButtonDisabled || uploading}
            />
          </div>
        </div>
      </div>
      {/* sticky footer */}
    </div>
  );
}
export default AddVideoNav;
