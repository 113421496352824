import moment from "moment";
import _ from "lodash";

export function mapDataToVideos({
  data,
  isLoading,
  onUnarchiveVideo,
  onAddToReport
}) {
  if (isLoading || !data || !data.videos) {
    return [];
  }

  return data.videos.map((video) => ({
    ...video,
    createdAt: moment(video.createdAt).format("MMM D, YYYY hh:mm A"),
    productName: video.product.name,
    scheduleStartTime: moment(video.scheduleStartTime).format(
      "MMM D, YYYY hh:mm A"
    ),
    onUnarchiveVideo,
    onAddToReport
  }));
}

export function buildGetVideosVariables({
  filters,
  cursor,
  scheduled,
  searchText,
  isArchived
}) {
  const variables = {
    id: undefined,
    name: {
      contains: searchText
    },
    createdAt: {},
    product: undefined,
    visible: !isArchived
  };

  const filterKeys = Object.keys(filters);

  filterKeys.forEach((key) => {
    if (key === "createdAt") {
      variables[key] = {
        ...variables[key],
        greaterThan: new Date(filters[key][0]).toUTCString(),
        lessThan: new Date(filters[key][1]).toUTCString()
      };
    } else if (key === "product") {
      variables[key] = filters[key].key;
    } else {
      variables[key] = {
        ...variables[key],
        equals: filters[key].label
      };
    }
  });

  variables.scheduleStatus = scheduled ? "Enabled" : "Disabled";

  return {
    variables: {
      video: {
        ...variables,
        ...(cursor && { cursor: cursor.id })
      }
    },
    fetchPolicy: "network-only"
  };
}

export const getColumnWidth = (selectedTab) => ({
  columnWidths,
  index,
  width
}) => {
  const watchVideoButtonWidth = 162;
  const isBroadcastTab = selectedTab === "broadcasts";
  const isWatchVideoButtonColumn = isBroadcastTab ? index === 4 : index === 1;
  const totalColumns = isBroadcastTab ? 4 : 5;

  if (isWatchVideoButtonColumn) {
    return watchVideoButtonWidth;
  }

  const adjustedWidth = width - watchVideoButtonWidth;
  const firstColumnWidth = 0.3 * adjustedWidth;
  const remainingWidth = adjustedWidth - firstColumnWidth;

  return (
    columnWidths[index] ||
    (index === 0 ? 0.3 * adjustedWidth : remainingWidth / totalColumns)
  );
};

export const onColumnResizeHof = ({
  columnWidths,
  setColumnWidths,
  columns
}) => ({ column, width }) => {
  if (!columnWidths.length) {
    setColumnWidths(new Array(6).fill(null));
  }

  const index = columns.findIndex((c) => c.key === column.key);
  const newWidths = columnWidths;
  newWidths[index] = width;

  setColumnWidths(newWidths);
};

export function buildFilterOptions({ videos }) {
  const mappedVideos = !videos
    ? []
    : videos.videos.map((video) => ({
        ...video,
        productName: video.title
      }));

  return [
    {
      key: "product",
      label: "Product Name",
      type: "menu",
      options: _.uniqBy(mappedVideos, "productName").map((v) => ({
        key: v.id,
        label: v.title
      })),
      filter: true
    },
    {
      key: "createdAt",
      label: "Order Date",
      type: "dateRange"
    }
  ];
}
