import React, { useState } from "react";
import { Page } from "../../layouts/Page";
import { OrderPageHeader, OrderList } from "../../molecules/OrderList";
import PageHeading from "./../../Form/PageHeading";


// import {
//   useOrderList,
//   useExportOrders,
//   useFilters,
//   useUpdateOrderStatus
// } from "./hooks";
import { TABLE_COLUMNS } from "./constants";
import { Sidebar } from "../../../component/molecules/Sidebar";
import { useHistory } from "react-router-dom";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";

function Orders() {
  const isMobileSize = isMobile();
  const history = useHistory();
  // const { filters, filterOptions, setFilters, isFiltersLoading } = useFilters();
  const [sort, setSort] = useState({ field: "id", direction: "desc" });
  const [selectedTab, setSelectedTab] = useState("orders");

  // const { loading, loadingMore, orders, refetch } = useOrderList({
  //   sort,
  //   filters
  // });

  // const { onExportPress, isExportLoading } = useExportOrders({ sort, filters });
  // const { onStatusChange } = useUpdateOrderStatus({ refetch });

  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');

  // const nonDonationOrders = orders.filter((o) => !o.isDonation);
  // const donationOrders = orders.filter((o) => o.isDonation);

  function onSortPress(field, direction) {
    setSort({ field, direction });
  }

  function onAddOrderPress() {
    history.push("/add-lead");
  }

  const onNavBarClick = () => {
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }


  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title="Leads" onNavBarClick={onNavBarClick} flex>
          {/* <OrderPageHeader
            onAddOrderPress={onAddOrderPress}
          /> */}
        <PageHeading text="Leads" />
        <OrderList/>
      </Page>
    </div>
  );
}

export default Orders;
