import React from "react";

import { Toolbar } from "../Toolbar";
import Button from "../../Form/Button";
// 


function OrderPageHeader({
  // filters,
  // filterOptions,
  // setFilters = () => {},
  // onExportPress = () => {},
  // isExportLoading,
  onAddOrderPress
}) {
  return (
    <Toolbar >
      {/* <FilterToolbar
        value={filters}
        onChange={setFilters}
        filterOptions={filterOptions}
      /> */}
 <Button
            icon="plus"
            className="narrow bold"
            text="Add Lead"
            onClick={onAddOrderPress}
          />
      {/* <ToolbarGroup>
        <Button
          icon="share"
          className="narrow bold"
          text="CSV Export"
          onClick={onExportPress}
          loading={isExportLoading}
        />
      </ToolbarGroup> */}
    </Toolbar>
  );
}

export default OrderPageHeader;
