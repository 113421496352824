import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import {
  isMobile,
  userLoggedAccessToken,
} from "../../../helpers/helper";

import Button from "../../../../component/Form/Button";
import bigTickIcon from "../../../../../src/assets/icons/big-tick.svg";

const numberToAmount = (e) => {
  const formattedNumber = e.toLocaleString('en-US');
  return formattedNumber;
}

export default function AddonsBoxesList({ onlyListing, ...props }) {
  const [addOnData, setAddOnData] = useState();
  const [addonsLoader, setAddonsLoader] = useState(false);
    useState();

  useEffect(() => {
    // fetchSubscribedAddOns();
    fetchAddOns();
  }, []);

  const AddonPackagBoxesHTML = () => {
    return (
      <>


        <div className="row addon-listing all-addon-listing">

        {addonsLoader === true && (
          <div className="d-flex justify-content-center addons-spinner">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}
        
          {addOnData?.length >= 1
            ? addOnData.map((singleAddOnData, index) => (
                <>
                  <div className="col-xs-12 d-flex">
                    <div className="card w-100 py-3 px-2">
                    {singleAddOnData?.purchased_addon_id >= 1 && (
                          <div className="selected-plan-tag position-absolute top-40 end-0">
                            <div className="bg-primary text-white p-2">
                              SELECTED
                            </div>
                          </div>
                        )}
                      <div className="card-body">
                        <h5 className="card-title fw-bolder">
                          {singleAddOnData.addon_name}
                        </h5>

                        <div className="d-flex justify-content-space-between align-items-center">
                          <p
                            className="addon-date-expiry"
                            title={singleAddOnData?.end_datetime}
                          >
                            ${singleAddOnData.total_amount}/Month
                          </p>

                          <div className="addon-billing-cycle">
                            {singleAddOnData?.purchased_addon_id >= 1 ===
                              false && (
                              <div >
                                {/* {subscriptionPlansData.plan_type!='free' && ( */}
                                <Link
                                  to={`/settings/subscription/purchase-addon/${singleAddOnData?.id}`}
                                  className="addons-btn add-sub-btn"
                                >
                                  Subscribe
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className={`d-flex justify-content-space-between align-items-center ${singleAddOnData?.purchased_addon_id >= 1 === false ? "" : "mt-3"}`}>
                          {singleAddOnData?.addonItems?.length >= 1
                            ? singleAddOnData?.addonItems.map(
                              (addonItem, addonItemIndex) => (
                                <>
                                  <div className="py-2 d-flex align-items-center package-parameter">
                                    <h6 className="mb-0 fw-semibold text-muted">
                                      <img
                                        className="right-check-icon"
                                        src={bigTickIcon}
                                        alt=""
                                      />
                                      {addonItem?.name} = {addonItem?.total_unit ? numberToAmount(addonItem?.total_unit) : addonItem?.total_unit} minutes
                                    </h6>
                                  </div>
                                </>
                              )
                            )
                            : ""}

                          <div className="text-center">
                            {singleAddOnData?.status === "Active" && (
                              <Button
                                buttonText="Cancel"
                                className="cancel-sub-btn add-sub-btn"
                              />
                            )}

                            {singleAddOnData?.status === "Cancelled" && (
                              <div>
                                <p className="text-danger">
                                  {singleAddOnData?.status}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            : ""}
        </div>

        <div className="row justify-content-center hide">
          {addOnData?.length >= 1
            ? addOnData.map((singleAddOnData, index) => (
                <>
                  <div className="col-lg-4 d-flex">
                    <div className="card w-100">
                      <div className="card-body position-relative">
                        {singleAddOnData?.purchased_addon_id >= 1 && (
                          <div className="selected-plan-tag position-absolute top-40 end-0">
                            <div className="bg-primary text-white p-2">
                              SELECTED
                            </div>
                          </div>
                        )}

                        <h5 className="card-title fw-semibold">
                          {singleAddOnData.addon_name}
                        </h5>
                        <small>(${singleAddOnData.total_amount}/Month)</small>

                        <div
                          className="mt-9 py-2 d-flex align-items-center package-parameter"
                          style={{ borderTop: "#FF317E 1px solid" }}
                        ></div>

                        {singleAddOnData?.addonItems?.length >= 1
                          ? singleAddOnData?.addonItems.map(
                              (addonItem, addonItemIndex) => (
                                <>
                                  <div className="py-2 d-flex align-items-center package-parameter">
                                    <div className="ms-3">
                                      <h6 className="mb-0 fw-semibold">
                                        {" "}
                                        <i className="ti ti-check packages-features-tick" />
                                        {addonItem?.name}(
                                        {addonItem?.total_unit})
                                      </h6>
                                    </div>
                                  </div>
                                </>
                              )
                            )
                          : ""}
                      </div>

                      {singleAddOnData?.purchased_addon_id >= 1 === false && (
                        <div className="text-center mb-5">
                          {/* {subscriptionPlansData.plan_type!='free' && ( */}
                          <Link
                            to={`/settings/subscription/purchase-addon/${singleAddOnData?.id}`}
                            className="btn btn-primary mt-4"
                          >
                            Subscribe
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ))
            : ""}
        </div>
      </>
    );
  };

  const fetchAddOns = () => {
    setAddonsLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/subscription-addon-plans", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({}), // forceToFetch will fetch expired
    })
      .then((response) => response.json())
      .then((data) => {
        setAddOnData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setAddonsLoader(false);
      });
  };


  return <AddonPackagBoxesHTML />;
}
