import React, { useState, useRef } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { useHistory } from "react-router-dom";
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon } from "react-share";
import { isMobile, showWidgetSharingCode } from '../../../helpers/helper';
import CongratulationsImg from "./../../../../assets/icons/congratulations.svg"
import ModalContainer from "../../../Table/ModalContainer";
import CopyToClipboard from "../../../Form/CopyToClipboard";
import Button from "../../../Form/Button";

const TITLE = "Congratulations";
const HEADING = "You publish your first video!";
const SUB_HEADING = "Your video is ready and account setup has been completed";

const SuccessCheckout = ({
    title = TITLE,
    heading = HEADING,
    subHeading = SUB_HEADING,

    successVideoId,
    successVideoShortUrl,
    successWidgetAppId
}) => {
    const history = useHistory();
    const isMobileSize = isMobile();
    const copyScriptRef = useRef(null);

    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');

    let videoUrl;
    let videoIframe;
    let videoShortUrl;

    if (history.location.state) {
        if (!successVideoId) {
            history.push({ pathname: '/', state: { successMsg: true } })
        }
        videoUrl = process.env.REACT_APP_WEB_URL + '/watch/' + successVideoId;
        videoShortUrl = process.env.REACT_APP_PORTAL_URL + '/short-url/' + successVideoShortUrl;

        videoIframe = showWidgetSharingCode({ app_id: successWidgetAppId });
    } else {
        history.push({ pathname: '/' });
    }

    const redirectToDashboard = () => {
        localStorage.setItem("is_onboarding_complete", true);
        history.push({ pathname: '/', state: { successMsg: true } })
    }

    const redirectToVideoScreen = () => {
        localStorage.setItem("is_onboarding_complete", true);
        history.push({ pathname: '/videos/add-nav-video', state: { successMsg: true } })
    }

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    return (
        <ModalContainer width={650} isOpen={true} closable={false} handleCloseModal={redirectToDashboard}>
            <div className="d-flex flex-column align-items-center">
                <img src={CongratulationsImg} alt="Congrats" width="300" />
                <h3 className="mt-2 text-pink fw-bolder">{title}</h3>
                <h3 className="text-black fw-bolder">{heading}</h3>
                <p className="text-black">{subHeading}</p>
                <div className="d-flex gap-2">
                    <FacebookShareButton url={videoUrl}><FacebookIcon size={32} round={true} /></FacebookShareButton>
                    <TwitterShareButton url={videoUrl}><TwitterIcon size={32} round={true} /></TwitterShareButton>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-md-6 col-sm-12 mb-2">
                    <label className="form-label">Watch Link</label>
                    <div className="p-r">
                        <input type="text" className="form-control form-control-copy-link" disabled value={videoUrl} />
                        <CopyToClipboard url={videoUrl} />
                    </div>
                </div>
                <div className="col-md-6 col-sm-12">
                    <label className="form-label">Watch Short Link</label>
                    <div className="p-r">
                        <input type="text" className="form-control form-control-copy-link" disabled value={videoShortUrl} />
                        <CopyToClipboard url={videoShortUrl} />
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div class="col-12">
                    <label className="form-label">Embed On Your Website</label>
                    <div className="p-r">

                        <textarea disabled ref={copyScriptRef} class="form-control w-100 pop-up" rows="9" value={videoIframe} />


                        <CopyToClipboard url={videoIframe} />

                        
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-4 mb-2 gap-2">
                <Button
                    type="button"
                    buttonText="Add Another"
                    fullButton={false}
                    className="btn btn-primary"
                    loading={false}
                    disabled={false}
                    onClick={redirectToVideoScreen}
                />
                <Button
                    type="button"
                    buttonText="Go to Dashboard"
                    fullButton={false}
                    className="btn btn-outline-primary"
                    loading={false}
                    disabled={false}
                    onClick={redirectToDashboard}
                />
            </div>
        </ModalContainer>
    );
}
export default SuccessCheckout;