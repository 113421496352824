import React, { useEffect, useState } from 'react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from 'validator';
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from '../../helpers/helper';
import Input from "./../../Form/Input";
import Select from "./../../Form/Select";
import PhoneInputField from "./../../Form/PhoneInputField";
import Warning from "./../../Form/Warning";
import { Intents } from "../../../Enums/Intents";
import Button from "./../../Form/Button";
import DropDown from "./../../Form/DropDown";


function EditOrder(props) {

    



    const history = useHistory();
    const [data, setData] = useState([]);
    const [videodata, setVideotdata] = useState([]);
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState('loading..');
    const [email, setEmail] = useState('loading..');
    const [phone, setPhone] = useState('loading..');
    const [status, setStatus] = useState('loading..');
    const [shipping, setShipping] = useState('loading..');
    const [address, setAddress] = useState('loading..');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [statusError, setStatusError] = useState('');
    const [shippingError, setShippingError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loaderSubmit, setloaderSubmit] = useState(false);
    const [Pageloader, setPageLoader] = useState(false);
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');


    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }


    const handleNameChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setName(value);
        validateField(value, rule, setNameError);

    };

    const handleEmailChange = (e) => {
        var value = e.target.value;
        var rule = "email";
        setEmail(value);
        validateField(value, rule, setEmailError);

    };

    const handlePhoneChange = (e) => {
        var value = e.target.value;
        var rule = "phone";

        setPhone(value);
        validateField(value, rule, setPhoneError);

    };


    const handleStatusChange = (e) => {
        var value = e.target.value;
        var rule = "required";

        if (value == '') {
            setStatus(value);
            validateField(value, rule, setStatusError);
        } else {
            setStatus(value);
            validateField(value, rule, setStatusError);

        }


    };

    const handleAddressChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setAddress(value);
        validateField(value, rule, setAddressError);

    };
    const handleshippingChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setShipping(value);
        validateField(value, rule, setShippingError);

    };





    useEffect(() => {

        fetchLeadData();

    }, []);




    const fetchLeadData = () => {
        // let path = window.location.pathname;
        // var confirmcode = path.split("/");
        // var id = confirmcode[2];

        var id = props.data;
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/find-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": id }),
        })
            .then((response) => response.json())
            .then((data) => {
                // setVideotdata(data.data)
                // setLoader(true)
                setPageLoader(true)
                const orderData = data.data

                setName(orderData.customer_name)
                setEmail(orderData.customer_email)
                setPhone(orderData.customer_phone)
                setStatus(orderData.order_status)
                setAddress(orderData.customer_address)

            



            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };




    const fetchVideosData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/user-videos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                setVideotdata(data.data)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const resetState = () => {
        setErrors('');
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm();
        setTimeout(resetState, 2000);
    };




    const submitForm = () => {
        setloaderSubmit(true)
        let path = window.location.pathname;
        var confirmcode = path.split("/");
        var id = props.data;


        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/update-order', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token.accessToken,
                },
                body: JSON.stringify({
                    "id": id,
                    "name": name,
                    "email": email,
                    "phone": phone,
                    "status": status,
                    "address": address,
                    "shipping": shipping,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();

                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 6;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })


                            setSuccess("Information Updated succesfully")
                            setTimeout(() => {
                                resetState()
                                history.push("/orders/customer-information")
                            }, 2000);

                            // history.push("/orders")
                            setloaderSubmit(false)
                        } else {
                            history.push("/edit-order")
                            setloaderSubmit(false)
                        }
                    });
                }
            }).
                catch((error) => {
                    setloaderSubmit(false)
                    console.log('error>>>', error)
                });
        });
        return fetch_prods;
    };



    const isButtonDisabled = !name || !email || !phone || !shipping || !address || nameError || emailError || phoneError || shippingError || addressError;


    return (


        <div className="container col-xs-12 lg-6 edit-order">
            <section>
                {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
                {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""}
                <form className="validation-wizard wizard-circle mt-5 wizard clearfix">
                    <div className="mb-3 row">
                        <div class="col-12">
                            <div class="mb-2">

                                {/* <label htmlFor="wfirstName2">Customer Name:<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue="Artisanal kale"
                                                id="example-text-input"
                                                name="name" value={name} onChange={handleNameChange}
                                            />
                                             {(nameError?<small className="text-danger">{nameError}</small>:"")} */}

                                <Input
                                    id="store-url"
                                    type="text"
                                    label="Customer Name *"
                                    value={name}
                                    onChange={(e) => handleNameChange(e)}
                                    disabled={false}
                                    placeholder={false}
                                    errorMessage={nameError}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div class="col-12">
                            <div class="mb-2">
                                {/* <label htmlFor="wfirstName2">Customer Email:<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                defaultValue="bootstrap@example.com"
                                                id="example-email-input"
                                                name="email" value={email} onChange={handleEmailChange} 
                                            />
                                             {(emailError?<small className="text-danger">{emailError}</small>:"")} */}

                                <Input
                                    id="store-url"
                                    type="text"
                                    label="Customer Email *"
                                    value={email}
                                    onChange={(e) => handleEmailChange(e)}
                                    disabled={false}
                                    placeholder="example@example.com"
                                    errorMessage={emailError}
                                />


                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div class="col-12">
                            <div class="mb-2">
                                {/* <label htmlFor="wfirstName2">Customer Phone:<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                defaultValue="1-(555)-555-5555"
                                                id="example-tel-input"
                                                min="1" 
                                                name="phone" value={phone} onChange={handlePhoneChange}
                                            />
                                              {(phoneError?<small className="text-danger">{phoneError}</small>:"")} */}

                                <Input
                                    id="store-url"
                                    type="Number"
                                    label="Customer Phone *"
                                    value={phone}
                                    onChange={(e) => handlePhoneChange(e)}
                                    disabled={false}
                                    placeholder={false}
                                    errorMessage={phoneError}
                                />


                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div class="col-12">
                            <div class="mb-2">
                                {/* <label htmlFor="wfirstName2">Customer Address:<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue="Artisanal kale"
                                                id="example-text-input"
                                                name="name" value={address} onChange={handleAddressChange}
                                            />
                                             {(addressError?<small className="text-danger">{addressError}</small>:"")} */}

                                <Input
                                    id="store-url"
                                    type="text"
                                    label="Customer Address *"
                                    value={address}
                                    onChange={(e) => handleAddressChange(e)}
                                    disabled={false}
                                    placeholder={false}
                                    errorMessage={addressError}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <div class="col-12">
                            <div class="mb-2">
                                {/* <label htmlFor="wfirstName2">Customer Shipping Address:<span className="danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue="Artisanal kale"
                                                id="example-text-input"
                                                name="name" value={shipping} onChange={handleshippingChange}
                                            />
                                             {(shippingError?<small className="text-danger">{shippingError}</small>:"")} */}
                                {/* <Input
                                    id="store-url"
                                    type="text"
                                    label="Customer Shipping Address *"
                                    value={shipping}
                                    onChange={(e) => handleshippingChange(e)}
                                    disabled={false}
                                    placeholder={false}
                                    errorMessage={shippingError}
                                /> */}
                            </div>
                        </div>
                    </div>

                    <div className="mb-3 row">
                        <div class="col-12">
                            <div class="mb-2">

                                {/* <label htmlFor="wfirstName2">Select Status:<span className="danger">*</span></label>
                                            <select className="form-select col-12" id="example-month-input2" name="status" value={status} onChange={handleStatusChange}  >
                                            <option value="">Select an option</option>
                                            <option value="Pending">Pending</option>
                                            <option value="Completed">Completed</option>
                                           
                                            </select>
                                            {(statusError?<small className="text-danger">{statusError}</small>:"")} */}

                                <DropDown
                                    id="input-type"
                                    label="Select Status *"
                                    options={[
                                        { value: 'Pending', label: 'Pending' },
                                        { value: 'Completed', label: 'Completed' },
                                    ]}
                                    value={status}
                                    onChange={(e) => handleStatusChange(e)}
                                    errorMessage={statusError}
                                />
                            </div>

                        </div>
                    </div>
                    <div class="actions clearfix">
                        <ul role="menu" aria-label="Pagination">
                            <li aria-hidden="false" aria-disabled="false">

                                {/* <button type="submit" onClick={handleSubmit} disabled={isButtonDisabled} class="mr-5 btn btn-primary text-white">
                                                    {loaderSubmit ? (
                                                        <div>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                        </div>
                                                    ) : (
                                                        <div><i class="ti ti-device-floppy me-1 fs-4"></i>Submit</div>
                                                    )}
                                                
                                            </button> */}

                                <div className="form-actions order-button">

                                    <Button
                                        type="type"
                                        className="btn btn-primary fs-5 text-white"
                                        buttonText="Update"
                                        // fullButton={false}
                                        loading={loaderSubmit}
                                        onClick={handleSubmit}
                                        disabled={isButtonDisabled}
                                    />

                                </div>




                            </li>
                        </ul>
                    </div>
                </form>
            </section>
        </div>

    );
}
export default EditOrder;