import React from "react";
import { Toolbar, ToolbarGroup } from "../Toolbar";

// import { Icon } from "../../../atoms/Icon";

import { useHistory } from "react-router-dom";
import {showUserTypeBaseMessage} from "../../helpers/helper";

function WidgetPageHeader({
  // onSearchTextChange,
  // onUploadPress,
  // filters,
  // setFilters,
  // filterOptions,
  // uploadBtnText,
  // searchText,
  // disableAddVideo
}) {
  // const history = useHistory();

  // const buttonText = showUserTypeBaseMessage(
  //   {
  //     'Brand': 'Embed Video to Web', 
  //     'default':'Add Integration to Web',
  //     'NonProfit':'Embed Video to Web',
  //     'Influencer':'Embed Video to Web',
  //     'Individual':'Embed Video to Web',
  //   }
  // );

  return (
    <Toolbar title="Integration to Web">
      {/* <ToolbarGroup>
        <InputGroup
          type="search"
          placeholder="Search..."
          value={searchText}
          leftIcon={<Icon name="search" color="#8a9ba8" />}
          onChange={onSearchTextChange}
        />
      </ToolbarGroup> */}

      {/* <FilterToolbar
        value={filters}
        onChange={setFilters}
        filterOptions={filterOptions}
      /> */}

      <ToolbarGroup>
        {/* <ButtonGroup> */}
          {/* <Button
            icon="plus"
            className="narrow bold"
            text={buttonText}
            // text={showUserTypeBaseMessage({Brand: 'Embed Videos to Web', default:'Add Integration to Web'})} 
            onClick={() => history.push("/videos/integration-on-web/web-integration")}
            // disabled={disableAddVideo}
          >
          </Button> */}
          
        {/* </ButtonGroup> */}
      </ToolbarGroup>
    </Toolbar>
  );
}

export default WidgetPageHeader;
