import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "../../../../molecules/Sidebar";
import { Page } from "../../../../layouts/Page";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  isMobile,
  userLoggedAccessToken,
  urlSegment,
  stringToBase64,
} from "../../../../helpers/helper";
import InVideoCheckoutPaymentGatewaysListing from "./InVideoCheckoutPaymentGatewaysListing";
import AuthorizeNetCredentials from "./AuthorizeNetCredentials";
// import StripeCredentialConfigs from "./StripeCredentialConfigs";
import StripeCredentials from "./StripeCredentials";
import starIcon from "../../../../../assets/dist/images/svgs/icon-star.svg";
import checkRoundImg from "../../../../../assets/icons/check-round.svg";
import lockImg from "../../../../../assets/icons/lock.svg";
import userIcon from "../../../../../assets/icons/user-round.svg";
import styles from "./InVideoCheckoutMain.module.css";
import FieldWarning from "../../../../Form/FieldWarning";
// import FieldWarning from "../../Form/FieldWarning";

import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../../Form/FormToast";



const Statuses = {
  COMPLETE: "complete",
  ACTIVE: "acitve",
  DISABLE: "disable",
};

const InVideoCheckoutMain = () => {
  const history = useHistory();
  const location = useLocation();
  const isMobileSize = isMobile();
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState();

  // const [isModuleRestricted, setIsModuleRestricted] = useState(false);
  const [isModuleRestrictedByCountry, setIsModuleRestrictedByCountry] =
    useState(false);

  const [
    allowedInVideoCheckoutInCountries,
    setAllowedInVideoCheckoutInCountries,
  ] = useState(null);

  const [openSections, setOpenSections] = useState([]);
  const [paymentGateWayLoader, setPaymentGateWayLoader] = useState(true); //initial should be TRUE to avoid showing button
  const [isSubscriptionLoading, setSubscriptionLoading] = useState();
  const [paymentGateWays, setPaymentGateWays] = useState([]);
  // const [isInVideoCheckoutDisabled, setIsInVideoCheckoutDisabled] =
  //   useState(false);
  const [paymentGateWaySlug, setPaymentGateWaySlug] = useState(urlSegment(2));
  const [userSubscription, setUserSubscription] = useState();
  const [isNecessaryProfileInfoFilled, setIsNecessaryProfileInfoFilled] =
    useState(false);
  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );

  const OnBoardingItem = ({
    icon,
    title,
    buttonText,
    status,
    buttonCallback,
    additionalClasses
  }) => {
    let className = "";

    if (status === Statuses.COMPLETE) {
      className = "onboarding-active";
    }

    if (status === Statuses.DISABLE) {
      className = "onboarding-disable";
    }

    // d-flex list-group-item min-height-60
    // d-flex flex-1 justify-content-between align-items-center
    // d-flex justify-content-center align-items-center

    return (
      <li className={`${className}  ${additionalClasses?additionalClasses:""} list-group-item ${styles.completeItemListBox}`}>
        <div className={`${styles.completeItemListText}`}>
          <div className="">
            <img
              className="mr-10"
              src={icon}
              alt={title}
              height="20"
              width="20"
            />
            <span className="text-black fs-4 fw-semibold">{title}</span>
          </div>
          {status === Statuses.COMPLETE && (
            <img src={checkRoundImg} alt="Completed" height="20" width="20" />
          )}
          {status === Statuses.ACTIVE && (
            <button
              className="btn btn-primary py-1 px-3"
              onClick={buttonCallback}
            >
              <span className="fs-2">{buttonText}</span>
            </button>
          )}
          {status === Statuses.DISABLE && (
            <img src={lockImg} alt="Locked" height="20" width="20" />
          )}
        </div>
      </li>
    );
  };

  // let isSubscriptionLoading;

  useEffect(() => {
    checkUserProfile();
  }, []);

  useEffect(() => {
    setPaymentGateWaySlug(urlSegment(2));
    setSuccess("");
    setError("");
  }, [location.pathname]);

  useEffect(() => {
    fetchUserPaymentGateways();
  }, []);
  // }, [paymentGateWaySlug]);

  useEffect(() => {
    // if(userSubscription?.plan_type === "paid"){
    //   //
    // }
    //isInVideoCheckoutDisabled
    // setIsInVideoCheckoutDisabled(userSubscription?.plan_type !== "paid");
    // fetchUserPaymentGateways();
  }, [userSubscription]);

  const checkUserProfile = async () => {
    try {
      var token = localStorage.getItem("authSession");
      var parsedToken = JSON.parse(token);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/check-user-profile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + userLoggedAccessToken(),
          },
          body: JSON.stringify({ user_id: parsedToken.idToken }),
        }
      );

      const data = await response.json();
      setIsNecessaryProfileInfoFilled(data.code === "200");
      return data.code === "200";
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchUserPaymentGateways = (...props) => {
    setAllowedInVideoCheckoutInCountries(null);
    setError();
    if(props?.callLoader){
      setPaymentGateWayLoader(true);
    }
    fetch(process.env.REACT_APP_API_URL + "/user-payment-gateways", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status === true) {
          setPaymentGateWays(response?.data);
          setIsModuleRestrictedByCountry(response?.restrictedbyCountry);
          setAllowedInVideoCheckoutInCountries(
            response?.allowedInVideoCheckoutInCountries
          );
          // setDefaultOrFirst(response?.data);
        } else {
          if (response?.restrictedFromSubscription !== true) {
            setError(
              response?.message ? response?.message : "Something wernt wrong"
            );
          }

          setIsModuleRestrictedByCountry(response?.restrictedbyCountry);

          // setIsModuleRestricted(true);
        }
      })
      .catch((error) => {
        setError(error);
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setPaymentGateWayLoader(false);
        // console.error("Error fetching data:", error);
      });
  };

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  const getSubscriptionData = (e) => {
    setUserSubscription(e);
  };

  let additionalBreadcrumb=null;

  if (urlSegment(2)?.length >= 1) {
    additionalBreadcrumb = { name: urlSegment(2), link: null };
  }

  // const toggleSection = (sectionId) => {
  //   const isOpen = openSections.includes(sectionId);
  //   const updatedSections = isOpen
  //     ? openSections.filter((id) => id !== sectionId)
  //     : [...openSections, sectionId];

  //   setOpenSections(updatedSections);
  // };


  const isInVideoCheckoutDisabled =
    userSubscription?.plan_type !== "paid" ||
    isModuleRestrictedByCountry === true ||
    isNecessaryProfileInfoFilled !== true;

  return (
    <>
      <div
        className={`page-wrapper ${
          sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
        }`}
        id="main-wrapper"
        data-layout="vertical"
        data-sidebartype={sidebarType}
        data-sidebar-position="fixed"
        data-header-position="fixed"
      >
        {<Sidebar onNavBarClick={onNavBarClick} />}
        <Page
          title="In Video Checkout"
          onNavBarClick={onNavBarClick}
          isSubscriptionLoading={isSubscriptionLoading}
          getSubscriptionData={getSubscriptionData}
          setSubscriptionLoading={setSubscriptionLoading}
        >
          {/* <BreadCrump
            title="In Video Checkout"
            image={dashboardbreadcrumimage}
            // tagLine={`Steps to Enable IN-VIDEO Checkout`}
            breadCrumpDetail={[
              { name: "Dashboard", link: "/" },
              { name: "Settings", link: null },
              {
                name: "In Video Checkout",
                link:
                  paymentGateWaySlug?.length >= 1
                    ? "/settings/in-video-checkout"
                    : "",
              },

              ...(additionalBreadcrumb ? [additionalBreadcrumb] : []),
            ]}
          /> */}


            <div className="mb-3">
              <h3 className="fw-bolder">In Video Checkout</h3>
              {/* <p>Steps to Enable In-Video Checkout</p> */}
              <p>Select Payment Gateway</p>
            </div>
          


          <div >
            {/* {success && (
              <div className="alert alert-success" role="alert">
                <strong>Success: </strong> {success}
              </div>
            )} */}
            {success  && (
                <FormToast
                  type={FORM_TOAST_TYPES.SUCCESS}
                  message={success?.toString()}
                />
              )}


              

              {error && (
                <FormToast
                  type={FORM_TOAST_TYPES.ERROR}
                  message={error?.toString()}
                />
              )}

            {paymentGateWayLoader === true && (
              <div className="d-flex justify-content-center">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              </div>
            )}

            <div className="in-video-setting-steps">
              <div>
                <ul className="list-group">
                {paymentGateWayLoader !== true &&
                    isInVideoCheckoutDisabled === true && (
                      <>
                        <h5 className="mb-3">
                          Steps to Enable IN-VIDEO Checkout
                        </h5>

                        <OnBoardingItem
                          icon={starIcon}
                          title="Subscribe to Premium Package"
                          buttonText={
                            userSubscription?.plan_type === "paid"
                              ? null
                              : "Subscribe"
                          }
                          status={
                            userSubscription?.plan_type === "paid"
                              ? Statuses.COMPLETE
                              : Statuses.ACTIVE
                          }
                          buttonCallback={() =>
                            history.push(`/settings/subscription?redirect=${stringToBase64(window.location.pathname)}`)
                          }
                          // additionalClasses="subscription-checks"

                          additionalClasses={userSubscription?.plan_type?"subscription-checks":"profile-details"}

                        />

                        {/* {openSections.includes(2) && ( */}
                        <OnBoardingItem
                          icon={userIcon}
                          title="Add Profile Details"
                          // buttonText="Subscribe"
                          buttonText={
                            isNecessaryProfileInfoFilled === true
                              ? null
                              : "Add Details"
                          }
                          status={
                            isNecessaryProfileInfoFilled === true
                              ? Statuses.COMPLETE
                              : Statuses.ACTIVE
                          }
                          buttonCallback={() =>
                            history.push(`/settings/account-setting?redirect=${stringToBase64(window.location.pathname)}`)
                          }
                          additionalClasses={isNecessaryProfileInfoFilled?"":"profile-details"}
                        />

                        
                        

                        

                        {/* <small className="m-3">
                          Note: This feature is exclusively available for {allowedInVideoCheckoutInCountries} accounts.
                        </small> */}
                      </>
                    )}
                </ul>
              </div>
            </div>
          </div>

          <div className="">
            <div className="">
              {/* currentSubscription */}

              {/* {userSubscription?.plan_type !== "paid" &&
                paymentGateWayLoader !== true &&
                isModuleRestrictedByCountry === false &&
                isSubscriptionLoading !== true && (
                  <>
                    <div className="d-flex justify-content-center flex-column align-items-center">
                      <div className="d-flex justify-content-center flex-column">
                        <p>
                          Please subscribe the paid plan to avail this amazing
                          service{" "}
                        </p>
                        <Link
                          to="/settings/subscription/upgrade-plan/"
                          className="btn waves-effect waves-light btn-primary"
                        >
                          Subscribe Paid Plan
                        </Link>
                      </div>
                    </div>
                  </>
                )} */}

              {/* {paymentGateWaySlug?.length > 0 == false && ( */}


              {paymentGateWayLoader !== true && !paymentGateWaySlug?.length && (
                <div className="mb-3 mt-3">
                  {/* {allowedInVideoCheckoutInCountries?
                    <FieldWarning message={`Note: This feature is exclusively available for ${allowedInVideoCheckoutInCountries} accounts.`} />
                  :""} */}

                  {allowedInVideoCheckoutInCountries && isModuleRestrictedByCountry?
                    <FieldWarning message={`Note: This feature is exclusively available for ${allowedInVideoCheckoutInCountries} accounts.`} />
                  :<FieldWarning message={`To enable a payment gateway, simply select your preferred option from the list provided.`} />}
                  
                </div>
              )}



              {paymentGateWayLoader !== true && (
                <>
                  {/* </>
                )} */}

                  <InVideoCheckoutPaymentGatewaysListing
                    userSubscription={userSubscription}
                    setError={setError}
                    isInVideoCheckoutDisabled={isInVideoCheckoutDisabled}
                    paymentGateWayLoader={paymentGateWayLoader}
                    paymentGateWays={paymentGateWays}
                  />
                  {/* )} */}

                  {paymentGateWaySlug?.length >= 1 && (
                    <div className="card">
                      <div className="card-body wizard-content">
                        {true && (
                          <>
                            {paymentGateWaySlug?.toLowerCase() === "stripe" &&
                              isInVideoCheckoutDisabled !== true && (
                                <>
                                  <StripeCredentials
                                    isInVideoCheckoutDisabled={
                                      isInVideoCheckoutDisabled
                                    }
                                    reFetchUserPaymentGateways={fetchUserPaymentGateways}
                                    userSubscription={userSubscription}
                                    setSuccess={setSuccess}
                                    setError={setError}
                                    paymentGateWayLoader={paymentGateWayLoader}
                                    paymentGateWays={paymentGateWays}
                                    defaultCredentials={paymentGateWays.find(
                                      (e) => e.name === "STRIPE"
                                    )}
                                    isAnotherGateWayActive={
                                      !!paymentGateWays
                                        .flatMap((pg) =>
                                          pg.paymentGatewaysCredentials.map(
                                            (c) => {
                                              return {
                                                id: c.id,
                                                is_active: c.is_active,
                                                payment_gateway_id:
                                                  c.payment_gateway_id,
                                                payment_mode: c.payment_mode,
                                                name: pg.name,
                                              };
                                            }
                                          )
                                        )
                                        .find(
                                          (f) =>
                                            f.name !== "STRIPE" && f.is_active
                                        )
                                    }
                                  />
                                </>
                              )}

                            {paymentGateWaySlug?.toLowerCase() ===
                              "authorize-net" &&
                              isInVideoCheckoutDisabled !== true && (
                                <AuthorizeNetCredentials
                                  defaultCredentials={paymentGateWays.find(
                                    (e) => e.name === "AUTHORIZE .NET"
                                  )}
                                  reFetchUserPaymentGateways={fetchUserPaymentGateways}
                                  setSuccess={setSuccess}
                                  setError={setError}
                                  isAnotherGateWayActive={
                                    !!paymentGateWays
                                      .flatMap((pg) =>
                                        pg.paymentGatewaysCredentials.map(
                                          (c) => {
                                            return {
                                              id: c.id,
                                              is_active: c.is_active,
                                              payment_gateway_id:
                                                c.payment_gateway_id,
                                              payment_mode: c.payment_mode,
                                              name: pg.name,
                                            };
                                          }
                                        )
                                      )
                                      .find(
                                        (f) =>
                                          f.name !== "AUTHORIZE .NET" &&
                                          f.is_active
                                      )
                                  }
                                />
                              )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Page>
      </div>
    </>
  );
};

export default InVideoCheckoutMain;
