import React, { useState, useEffect } from "react";
import PasswordInput from "./PasswordInput";
import { useChangeProfile } from "./hook";
import { userLoggedAccessToken } from "../../../helpers/helper";
import ProfilePrivatePublicSettings from "./ProfilePrivacySettings/ProfilePrivacySettings";
import validateField from "../../../helpers/AllValidtions";
import CountriesDropDown from "../../Onboarding/ProfileInfo/CountriesDropDown";
import StatesDropDown from "../../Onboarding/ProfileInfo/StatesDropDown";
import CitiesDropDown from "../../Onboarding/ProfileInfo/CitiesDropDown";
import Input from "../../../Form/Input";
import Button from "../../../Form/Button";
import Textarea from "../../../Form/Textarea";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../Form/FormToast";
import ProfileBrandUploadArea from "./ProfileBrandUploadArea";
import UploadHelper from "../../../helpers/upload";
import CurrencyList from 'currency-list';
const CURRENCIES = CurrencyList.getAll('en_US');

function ProfileSettingsNew() {
  const [profile, setProfile] = useState();
  const [nameError, setNameError] = useState("");
  const [bioError, setBioError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [urlErrors, setUrlErrors] = useState(false);
  const [businessError, setBusinessError] = useState("");
  const [zipcodeError, setZipcodeError] = useState("");
  const [privacyPolicyUrlError, setPrivacyPolicyUrlError] = useState("");
  const [termsOfServiceUrlError, setTermsOfServiceUrlError] = useState("");
  const [supportEmailError, setSupportEmailError] = useState("");
  const [fileUploadLoading, setFileUploadLoading] = useState(false);


  const fetchProfileData = () => {
    fetch(process.env.REACT_APP_API_URL + "/get-profile", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.status) {
          setProfile(response.data);
        }
      });
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const {
    // error,
    name,
    email,
    bio,
    supportEmail,
    termsOfServiceUrl,
    refundPolicyUrl,
    privacyPolicyUrl,
    website,
    image,
    // brandName,
    // brandDescription,
    // brandWebsite,
    brandCurrency,
    // brandImage,
    isPublicProfile,
    onImageChange,
    setName,
    setEmail,
    setBio,
    setSupportEmail,
    setTermsOfServiceUrl,
    setPrivacyPolicyUrl,
    setRefundPolicyUrl,
    setWebsite,
    // setBrandName,
    // setBrandDescription,
    // setBrandWebsite,
    setBrandCurrency,
    // onBrandImageChange,
    errorField,
    isSaving,
    saveChanges,
    // onBrandSaveChanges,
    success,
    // clearAllWarnings,
    setIsPublicProfile,
    business,
    setBusiness,
    countryId,
    setCountryId,
    country,
    setCountry,
    stateId,
    setStateId,
    state,
    setState,
    cityId,
    setCityId,
    zipCode,
    setZipCode,
  } = useChangeProfile({ profile, fetchProfileData });

  const handleNameChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setName(value);
    validateField(value, rule, setNameError);
  };

  const handleBusinessChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setBusiness(value);
    validateField(value, rule, setBusinessError);
  };

  const handlePrivatePolicyChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setPrivacyPolicyUrl(value);
    validateField(value, rule, setPrivacyPolicyUrlError);
  };

  const handleConditionsChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setTermsOfServiceUrl(value);
    validateField(value, rule, setTermsOfServiceUrlError);
  };

  const handleBioChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setBio(value);
    validateField(value, rule, setBioError);
  };

  const handleEmailChange = (e) => {
    var value = e.target.value;
    var rule = "email";
    setEmail(value);
    validateField(value, rule, setEmailError);
  };


  const handleSupportEmailChange = (e) => {
    var value = e.target.value;
    setSupportEmail(value);
    if (value?.trim()?.length) {
      var rule = "email";
      validateField(value, rule, setSupportEmailError);
    } else {
      setSupportEmailError('');
    }
  };

  const handleZipCodeChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setZipCode(value);
    validateField(value, rule, setZipcodeError);

  };

  const handleUrlChange = (e) => {
    var value = e.target.value;
    var rule = "url";
    setWebsite(value);
    validateField(value, rule, setUrlErrors);
  };

  const isButtonDisabled =
    !name ||
    !email ||
    !bio ||
    !website ||
    !business ||
    !termsOfServiceUrl ||
    !privacyPolicyUrl ||
    bioError ||
    emailError ||
    urlErrors ||
    nameError ||
    businessError ||
    privacyPolicyUrlError ||
    termsOfServiceUrlError ||
    zipcodeError ||
    supportEmailError || !image;


  return (
    <div className="container col-12">
      <section className="central-form">
        {success && (<FormToast type={FORM_TOAST_TYPES.SUCCESS} message={success} />)}
        <div className="mt-4">
          <form className="form">
            <div className="row">
              <div className="col-xs-12 col-md-6 col-xs-12">
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4">
                      <Input
                        id="accountName"
                        type="text"
                        label="Name *"
                        value={name}
                        onChange={(e) => handleNameChange(e)}
                        disabled={false}
                        placeholder="Enter Name"
                        errorMessage={nameError}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-4">
                      <Textarea
                        id="accountBio"
                        label="Bio *"
                        value={bio}
                        onChange={(e) => handleBioChange(e)}
                        disabled={false}
                        placeholder="Enter Your Bio"
                        errorMessage={bioError}
                        rows="2"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                  <ProfileBrandUploadArea
                    fileUploadLoading={fileUploadLoading}
                    setFileUploadLoading={setFileUploadLoading}
                    label="Profile Image *"
                    fileSrc={image}
                    onChangeEvent={onImageChange}
                    uploadPathName={UploadHelper.AVATAR}
                    inlineError={!image ? "Profile Image is required" : null}
                  />
                  </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-4">
                      <Input
                        id="accountWebsite"
                        type="text"
                        label="Email *"
                        value={email}
                        onChange={(e) => handleEmailChange(e)}
                        disabled={false}
                        placeholder="Enter Website URL"
                        errorMessage={emailError}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-4">
                      <Input
                        id="accountWebsite"
                        type="text"
                        label="Website URL *"
                        value={website}
                        onChange={(e) => handleUrlChange(e)}
                        disabled={false}
                        placeholder="Enter Website URL"
                        errorMessage={urlErrors}
                      />
                    </div>
                  </div>

                  {/* <div className="col-12">
                    <div className="mb-4">
                      <Input
                        id="supportEmail"
                        type="text"
                        label="Support Email"
                        value={supportEmail}
                        onChange={(e) => handleSupportEmailChange(e)}
                        disabled={false}
                        errorMessage={supportEmailError}
                        placeholder="Enter Support Email"
                      />
                    </div>
                  </div> */}

                  <div className="col-12">
                    <div className="mb-4">
                      <Input
                        id="termsAndConditions"
                        type="text"
                        label="Terms & Conditions *"
                        value={termsOfServiceUrl}
                        onChange={(e) => handleConditionsChange(e)}
                        disabled={false}
                        placeholder="Enter Terms & Conditions URL"
                        errorMessage={termsOfServiceUrlError}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="mb-4">
                      <Input
                        id="privacyPolicyUrl"
                        type="text"
                        label="Privacy Policy *"
                        value={privacyPolicyUrl}
                        onChange={(e) => handlePrivatePolicyChange(e)}
                        disabled={false}
                        placeholder="Enter Privacy Policy URL"
                        errorMessage={privacyPolicyUrlError}
                      />
                    </div>
                  </div>

                  <div className="col-12" style={{display:'none'}}>
                    <div className="mb-4">
                      <Input
                        id="refundPolicyUrl"
                        type="url"
                        label="Refund Policy"
                        value={refundPolicyUrl}
                        onChange={(e) => setRefundPolicyUrl(e)}
                        disabled={false}
                        placeholder="Enter Refund Policy URL"
                      />
                    </div>
                  </div>



                </div>
              </div>

              <div className="col-xs-12 col-md-6 col-xs-12">
                <div className="row">

                  

                  <div className="col-12">
                    <div className="mb-4">
                      <Input
                        id="businessLocation"
                        type="text"
                        label="Business Address *"
                        value={business}
                        onChange={(e) => handleBusinessChange(e)}
                        disabled={false}
                        placeholder="Enter business address"
                        errorMessage={businessError}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-4">
                      <label for="brandWebsite" className="form-label">Currency *</label>
                      <select value={brandCurrency} className={errorField === "brandWebsite" ? "is-invalid form-control" : "form-control"} aria-label="Default select example" onChange={(e) => setBrandCurrency(e.target.value)}>
                        <option value="">Select Currency</option>
                        {Object.entries(CURRENCIES).map(([key, c]) => {
                          return (
                            <option key={`currency-${key}`} value={c?.code}>
                              {`${c?.name} (${c?.symbol_native})`}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className="mb-4">
                      <label className="form-label">Country *</label>
                      <CountriesDropDown
                        value={countryId}
                        onChange={(e) => {
                          setCountryId(e?.id);
                          setCountry(e?.name);
                          setStateId(null);
                          setCityId(null);
                        }}
                      />
                      {errorField === "country_id" && (
                        <small className="text-danger">This field is required</small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="mb-4">
                      <label className="form-label">State *</label>
                      <StatesDropDown
                        country_id={countryId}
                        value={stateId}
                        onChange={(e) => {
                          setStateId(e?.id);
                          setState(e?.name);
                          setCityId(null);
                        }}
                      />
                      {errorField === "state_id" && (
                        <small className="text-danger"> This field is required </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="mb-4">
                      <label className="form-label">City *</label>
                      <CitiesDropDown
                        country={country}
                        country_id={countryId}
                        state={state}
                        state_id={stateId}
                        value={cityId}
                        onChange={(e) => setCityId(e?.id)}
                      />
                      {errorField === "city_id" && (
                        <small className="text-danger"> This field is required </small>
                      )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="mb-4">
                      <Input
                        id="zipCode"
                        type="text"
                        label="ZIP Code *"
                        value={zipCode}
                        onChange={(e) => handleZipCodeChange(e)}
                        disabled={false}
                        placeholder="Enter Zip"
                        errorMessage={zipcodeError}
                      />
                      {errorField === "zip_code" && (
                        <small className="text-danger"> This field is required </small>
                      )}
                    </div>
                  </div>

                  <PasswordInput profile={profile} />

                  <ProfilePrivatePublicSettings
                    profile={profile}
                    isPublicProfile={isPublicProfile}
                    setIsPublicProfile={setIsPublicProfile}
                  />

                </div>
              </div>
            </div>

            <div className="form-actions submit mt-3">
              <Button
                type="button"
                buttonText="Save"
                fullButton={false}
                loading={isSaving}
                onClick={saveChanges}
                disabled={isButtonDisabled}
              />
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default ProfileSettingsNew;
