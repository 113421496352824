import React, { useEffect,useRef, useState, forwardRef,useImperativeHandle} from "react";
import UploadHelper from "../../../component/helpers/upload";
import {getPathFromUrl,extensionToFormat} from "../../helpers/helper";
import validator from "validator";
import Button from "../../Form/Button";
import { Container } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import ProductImageCardBox from "../../Form/ProductImageCardBox";


const imageMaxLimit = process.env.REACT_APP_MAX_PRODUCT_IMAGE;

const ImageUploader = forwardRef(({ returnImagesUrls, allowedImageTypes, setErrorMessage,minLimit ,productMultiImagesError ,setProductMultiImagesError, labelText}, ref) => {
  const [images, setImages] = useState([]);
  const [returnValueData, setReturnValueData] = useState([]);
  const [imagesLimitLeft, setImagesLimitLeft] = useState([imageMaxLimit]);
  const [allowTypeFilesCommaSep, setAllowTypeFilesCommaSep] = useState();
  const [allowTypeFilesAccept, setAllowTypeFilesAccept] = useState();

  const [imagesLimitMessage, setImagesLimitMessage] = useState(`You can upload maximum ${imageMaxLimit} images.`);


  const inputFileBrowseRef = useRef(null);

  useEffect(() => {
    setAllowTypeFilesCommaSep(Object.values(allowedImageTypes).join(','));
    setAllowTypeFilesAccept(extensionToFormat(Object.values(allowedImageTypes).join(',')));
  }, [allowedImageTypes]);



  useImperativeHandle(ref, () => ({
    setImageFromExternal
  }));

  const setImageFromExternal = (productImages) => {
    if (productImages?.length) {
      const imagesObj = [];
      for (let index = 0; index < productImages.length; index++) {
        const imageUrl = productImages[index].url;
        appendImageURL(imageUrl, index); // Pass the index to the appendImageURL function
        imagesObj.push(imageUrl);
      }
  
      setImages(imagesObj);
    }
  }

  useEffect(() => {

    setImagesLimitLeft(imageMaxLimit-images?.length);

    
    if(images?.length >= minLimit){
      setProductMultiImagesError('')
    }
    
    // if(images?.length==0){
    //   setImagesLimitMessage(`You can select maximum ${imageMaxLimit} images.`)
    // } else if(images?.length==imageMaxLimit){
    //   setImagesLimitMessage(`${images?.length}/${imageMaxLimit} images are selected`);
    // } else if(images?.length>=1){
    //   setImagesLimitMessage(`${images?.length} selected , ${imageMaxLimit-images?.length} images are left`);
    // }




  }, [images]);

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);
    
    const validImages = selectedImages.filter(validateImage); 
    const newImages = validImages.filter((image) => !images.some((img) => img.name === image.name)).slice(0, imagesLimitLeft);
    setImages((prevImages) => [...prevImages, ...newImages]);
    // setNewlyAddedImages(newImages);
    // newImages.forEach(async (image, index) => await uploadOnServer(image, index));
    const newLength = (images?.length)?images?.length:0;
    newImages.forEach(async (image, index) => await uploadOnServer(image, index+newLength));

    inputFileBrowseRef.current.value = '';
  }
  // };

  const uploadOnServer = async (imageSrc,index) => {
    const uploadingPath = await UploadHelper.getSignedUrl(
      UploadHelper.PRODUCT_IMAGE
    );

    await UploadHelper.uploadToS3(uploadingPath.signedUrl, imageSrc, handleProgressEvent,(response) => {
      // Handle success here, for example:

      // const imageIndex = images.length===0?0:images.length+1;
      appendImageURL(getPathFromUrl(uploadingPath.signedUrl),index);
    });
  }

  const appendImageURL = (urlValue, index) => {
    // const updatedData = [...returnValueData];
    const updatedData = returnValueData;
    updatedData[index] = urlValue;
    setReturnValueData(updatedData);
    returnImagesUrls(updatedData);
  }

  // const  handleProgressEvent = ({ loaded, total }) => {
  const  handleProgressEvent = () => {
    
    // setProgress(loaded / total);
  }

  const displaySrcImage = (imgSrc,index) => {
    try{

      if(returnValueData[index]?.length>=1){
        return returnValueData[index];
      }
    } catch(firstError){
      console.log('firstError',firstError);
    }

    try{
      if(validator.isURL(imgSrc)){
        return imgSrc;
      } 
    } catch(firstError){
      

      try{
        return URL.createObjectURL(imgSrc);
      } catch(secondError){
        
        return null;
      }
    }

  }

  const removeImage = (index) => {

  
    console.log('index-->',index)

    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
    
    const updatedImagesValue = [...returnValueData];
    updatedImagesValue.splice(index, 1);
    setReturnValueData(updatedImages.length===0?[]:updatedImagesValue);
    returnImagesUrls(updatedImagesValue);
    if(updatedImages.length < minLimit){
      setProductMultiImagesError('This field is required')
    }



    // const returnValueDataTemp = [...returnValueData];
    // returnValueDataTemp.splice(index, 1);
    // setImages(returnValueDataTemp);
    
  };

  // const removeImage = (index) => {
  //   const updatedImages = [...images];
  //   updatedImages.splice(index, 1);
  //   setImages(updatedImages);

  //   // const updatedReturnValueData = [...returnValueData];
  //   // updatedReturnValueData.splice(index, 1);
  //   // setReturnValueData(updatedReturnValueData);
  // };

  const validateImage = (photoFile) => {
    if (photoFile) {
        const fileType = photoFile.type;
        if (!allowedImageTypes.hasOwnProperty(fileType)) {
          // const selectedFormats = Object.values(allowedImageTypes).join(',');
          // console.log(Object.values(allowedImageTypes).join(','));
          setErrorMessage('Please select only '+allowTypeFilesCommaSep);
        } else {
            return true;    
        }
    } else {
        return false;
    }
}
const browseImageTrigger = () => {
  inputFileBrowseRef.current.click();
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.4,
    slidesToSlide: 4 // optional, default to 1.
  },  
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2.3,
    slidesToSlide: 3 // optional, default to 1.
  },
  mobilexSmall: {
    breakpoint: { max: 349, min: 280 },
    items: 1.3,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobileSmall: {
    breakpoint: { max: 499, min: 350 },
    items: 1.4,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobileLarge: {
    breakpoint: { max: 767, min: 500 },
    items: 2.3,
    slidesToSlide: 1 // optional, default to 1.
  }
};


  return (
    <div className="multi-files-uploader">
      
      <input ref={inputFileBrowseRef} accept={allowTypeFilesAccept} type="file" className="form-control d-none" multiple onChange={handleImageChange} />
      
      
      <div className="imageuploader-note-button">
        <div className="imageuploader-note-label">
          {labelText?<label className="form-label">{labelText}</label>:""}
          <p className="text-black fs-3">Image {images.length}/{imageMaxLimit}</p>
          
          {productMultiImagesError ? <p className="text-danger mb-0">You can upload maximum {imageMaxLimit} images.</p> : ""}
        </div>
        <div>
          <Button
              listingButtonUnfilled={true}
              type="button"
              buttonText="Upload"
              disabled={images?.length === Number(imageMaxLimit)}
              onClick={browseImageTrigger}
            />

        </div>
      </div>

      {/* <div className="d-flex gap-3 flex-wrap justify-content-center"> */}
      <div className="product-images-slider">


      <Container>
              <Carousel
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={false}
                partialVisible={false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
              >
                {images.map((image, index) => {
                  return (
                    <div className="slider" key={index}>
                        <ProductImageCardBox
                            boxIndexKey={index}
                            boxImgSrc={displaySrcImage(image, index)}
                            // boxTitle={selectedOption?.name}
                            crossDeleteBtnFunction={removeImage}
                        />
                    </div>
                  );
                })}
              </Carousel>
        </Container>

        {/* {
          images.map((image, index) => (
            <div className="w-sq-btn-view-thumbnail position-relative" key={`image-${index}`}>
              <div className="close" onClick={() => removeImage(index)}>
                <i class="ti ti-x"></i>
              </div>
              <div className="w-sq-btn gap-2" onClick={() => { }} style={{ height: 140, width: 140 }}>
                <img className="view" src={displaySrcImage(image, index)} alt="Upload Thumbnail" />
              </div>
            </div>
          ))
        } */}
      </div>
    </div>
  );
});


export default ImageUploader;