import React, { useEffect, useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import ShopifyProductSyncNav from "../../Pages/Products/ShopifyProductSyncNav";
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/product.png";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import RoundtickIcon from "./../../../assets/icons/roundtick.svg"

import Input from "./../../Form/Input";
import Select from "./../../Form/Select";
import CheckBoxRow from "./../../Form/CheckBoxRow";
import ToggleSwitchRow from "./../../Form/ToggleSwitchRow";
import PhoneInputField from "./../../Form/PhoneInputField";
import Warning from "./../../Form/Warning";
import { Intents } from "../../../Enums/Intents";
import Button from "./../../Form/Button";
import { VideoSteps } from "./Video/AddVideo";

const Statuses = {
    VERIFY: "verify",
    VERIFIED: "verified",
    SYNCING: "product-syncing",
    DONE: "done"
}

function AddingKeys(props) {
    const isMobileSize = isMobile();
    const [keysStored, setkeysStored] = useState(true);
    const [storeid, setStoreid] = useState();
    const [accesstoken, setAccesstoken] = useState();
    const [storeFrontAccesstoken, setStoreFrontAccesstoken] = useState("");
    const [showStoreFrontField, setShowStoreFrontField] = useState("");
    const [storeidError, setStoreidError] = useState('');
    const [accesstokenError, setAccesstokenError] = useState('');
    const [storeFrontAccesstokenError, setStoreFrontAccesstokenError] = useState('');
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [importProductLoader, setImportProductLoader] = useState(false);
    const [status, setStatus] = useState("verify");

    const handleStoreChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setStoreid(value);
        validateField(value, rule, setStoreidError);

    };

    const handleAccesstokenChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setAccesstoken(value);
        validateField(value, rule, setAccesstokenError);

    };

    const handleStoreFrontAccesstokenChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setStoreFrontAccesstoken(value);
        validateField(value, rule, setStoreFrontAccesstokenError);

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm(storeid, accesstoken);
    };

    const resetState = () => {
        setShowErrors(false);
        setErrorMessage('');
    };

    const submitForm = (storeid, accessToken) => {

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-shopify-keys', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "shopify_store": storeid,
                    "access_token": accessToken,
                    "storefront_access_token": showStoreFrontField ? storeFrontAccesstoken : ""
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 14;
                            var account_id = token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })
                            setStatus("verified")
                        }
                        else {

                            setShowErrors(true);
                            setErrorMessage(json.message);
                            setTimeout(resetState, 2000);


                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {

                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };

    const SeeProducts = (e) => {

        e.preventDefault();
        history.location.state.OnBoardingShowImport = true;
        history.push({
            pathname: '/import-product',
            state: history.location.state,
        });


    };



    const SyncProduct = (e) => {
        e.preventDefault();
        submitProductForm(storeid, accesstoken);
    };

    const GoToSelectProduct = () => {
        history.location.state.OnBoardingShowImport = true;
        history.push({
            pathname: "/select-product",
            state: history.location.state,
        });
    }


    const submitProductForm = (storeid, accessToken) => {
        // setLoader("loading")

        setStatus("product-syncing")

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-variant-shopify-product-sync', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "shopify_store": storeid,
                    "access_token": accessToken
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 16;
                            var account_id = token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            // setLoader("true")

                            setStatus("done")

                            GoToSelectProduct()

                        }
                        else {
                            // history_push_link = "/add-product";
                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {
                setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };


    const IsURL = storeid ? !isURL(storeid) : false
    const isButtonDisabled = IsURL || !accesstoken || storeidError || accesstokenError;


    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    const tagLine = showUserTypeBaseMessage({
        'Brand': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
        'default': '',
        'NonProfit': 'Empower Your Donors by Providing an Ultimate Shoppable Video Experience',
        'Influencer': 'Empower Your Viewers by Providing an Ultimate Shoppable Video Experience',
        'Individual': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    });
    useEffect(() => {
        fetchVideosData();

    }, []);

    const backToAddProducts = () => {

        return history.push({
            pathname: "/add-product",
            state: history.location.state,
        });
    };
    const fetchVideosData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/check-user-keys', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                setkeysStored(false)
                // setVideotdata(data.data)
                // setLoader(true)
                if (data.code == 200) {
                    setStoreid(data.data.store)
                    setAccesstoken(data.data.access_token)
                    setStoreFrontAccesstoken(data.data.storefront_access_token)
                    setShowStoreFrontField(data.data.storefront_access_token)



                } else {

                }

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} data-sidebartype={sidebarType} id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Add Product" onNavBarClick={onNavBarClick} formWidth={true}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                <h5 className="fw-bolder">Create Your First Shoppable Video</h5>
                                <VideoSteps onBoarding step="3" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body">
                                <div className="w-500">
                                    {(keysStored) ? <><div class="card-body"><div class="text-center"><div class="spinner-border" role="status"></div></div></div></> :
                                        <div className="import-shopify">
                                            <section className="import-shopify-integration">
                                                {(showErrors || errorMessage) ? <div className="alert alert-danger" role="alert"><strong>Error </strong> {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."} </div> : ""}
                                                {status === Statuses.VERIFY && (
                                                    <div className="mt-4">
                                                    <h5 className="fw-bolder">Add shopify Keys</h5>
                                                    <p>Authenticate your app by adding your keys</p>
                                                        <form className="form">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    <div className="mb-3">
                                                                    <Button
                                                                        type="submit"
                                                                        className="btn btn-primary fs-5 text-white key-btn"
                                                                        buttonText="Add Keys"
                                                                        fullButton={false}
                                                                        loading={false}
                                                                        onClick={SeeProducts}
                                                                        disabled={false}
                                                                    />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                        </form>
                                                    </div>)}                                                                      
                                            </section>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            {/* sticky footer */}
            {status === Statuses.VERIFY && (
                <div className="sticky-footer ">
                    <div className="footer-container">
                        <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                            <Button
                                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                                type="button"
                                buttonText="Back"
                                onClick={backToAddProducts}
                            />
                            <Button
                                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                                type="button"
                                onClick={handleSubmit}
                                buttonText="Next"
                                fullButton={false}
                                loading={importProductLoader}
                                disabled={isButtonDisabled}
                            />
                        </div>
                    </div>
                </div>
            )}
            {/* sticky footer */}
        </div>


    );
}
export default AddingKeys;
