const VideoPreviewAddVideo = ({ src, removePreview }) => {
    return (
        <div className="mt-2">
            <div className="d-flex justify-content-center align-items-center">
                <video className="video-preview" width="252" height="448" controls src={src} />
            </div>
            <div className="d-flex justify-content-center align-items-center cursor-pointer mt-2" onClick={removePreview}>
                <i class="ti ti-trash text-danger fs-9"></i>
            </div>
        </div>
    )
}

export default VideoPreviewAddVideo;