import React, { useEffect } from "react";
import ExternalCheckout from "./ExternalCheckout";
import { useHistory } from "react-router-dom";

function SelectProduct() {

    const history = useHistory();

    useEffect(() => {
        return history.push({
            pathname: '/external-checkout',
            state: history.location.state,
        });
    }, [])

    return <ExternalCheckout />
}

export default SelectProduct;
