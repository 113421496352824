import React, { useState, useEffect } from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";
import { showWidgetSharingCode } from "../../helpers/helper";
import Input from "../../Form/Input";
import Textarea from "../../Form/Textarea";
import Loading from "../../Pages/Loadings/Loading";

const SocialShare = (data, props) => {
  data = data ? data.data : data;

  const [socialSharingCode, setSocialSharingCode] = useState("");
  const [loading, setLoading] = useState(false);

  const successVideoId = data.id;
  let successVideoShortUrl = data.wsl_code;
  let videoUrl = process.env.REACT_APP_WEB_URL + "/watch/" + successVideoId;
  let videoShortUrl =
    process.env.REACT_APP_PORTAL_URL + "/short-url/" + successVideoShortUrl;

  useEffect(() => {
    setSocialSharingCode(data?.app_id != null ? showWidgetSharingCode({ app_id: data?.app_id }) : "");

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [data?.app_id]);

  if (loading) {
    return (
      <div className="py-10">
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <h3 className="text-black">Share Video</h3>

      <div>
        <center className="d-flex gap-1 justify-content-center">
          <FacebookShareButton url={videoUrl}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={videoUrl}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </center>
      </div>

      <div className="row mt-4">
        <div className="col-md-12">
          <Input
            id="widget-id"
            type="text"
            label="Widget ID:"
            value={data.app_id}
            onChange={() => { }}
            disabled={true}
            placeholder=""
            copyToClipboard={true}
          />
        </div>
        <div className="col-md-12 mt-4">
          <Input
            id="watch-link"
            type="text"
            label="Watch Link:"
            value={videoUrl}
            onChange={() => { }}
            disabled={true}
            placeholder=""
            copyToClipboard={true}
          />
        </div>
        <div className="col-md-12 mt-4">
          <Input
            id="watch-short-link"
            type="text"
            label="Watch Short Link:"
            value={videoShortUrl}
            onChange={() => { }}
            disabled={true}
            placeholder=""
            copyToClipboard={true}
          />
        </div>
        <div className="col-md-12 mt-4">
          <Textarea
            id="embed-on-your-website"
            type="text"
            label="Embed On Your Website:"
            value={socialSharingCode}
            onChange={() => { }}
            disabled={true}
            placeholder=""
            copyToClipboard={true}
            rows="10"
          />
        </div>
      </div>
    </div>
  );
};
export default SocialShare;
