import React, { useState, useEffect, useRef } from "react";
import Input from "./Input";
import Button from "./Button";
import RadioButtonRow from "./RadioButtonRow";

const FilterDropDown = ({
  placeholder = "Filter By",
  filterOptions,
  callBack,
  ...props
}) => {
  const [selectedType, setSelectedType] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setSelectedType({ ...selectedType, [name]: value });
  };

  const handleApplyClick = () => {
    if (callBack) {
      callBack(selectedType);
    }
    setIsOpen(false);
  };

  const handleResetClick = () => {
    setSelectedType({});
    if (callBack) {
      callBack({});
    }
    setIsOpen(false);
  };

  return (
    <>
      <div className="filter-field-main" ref={dropdownRef}>
        <div>
          <Input
            type="text"
            onClick={(e) => {
              setIsOpen(!isOpen);
            }}
            placeholder={placeholder}
            rightIconClass={`ti ${
              isOpen ? "ti-chevron-up" : "ti-chevron-down"
            }`}
            leftIconClass="ti ti-filter"
            readOnly={true} // Add readOnly attribute
          />
        </div>

        <div
          className={`filter-dropdown-main dropdown-menu z-1000 ${
            isOpen ? "show" : ""
          }`}
        >
          <div className="filter-listing-dropdown-options">
            <ul>
              {Object.entries(filterOptions).map(([type, value]) => (
                <li key={type}>
                  <label>{value.label}</label>
                  <ul>
                    {value.children.map((child) => (
                      <li key={child.value}>
                        <RadioButtonRow
                          label={child.label}
                          type="radio"
                          id={child.value}
                          name={type}
                          value={child.value}
                          onChange={handleRadioChange}
                          checked={selectedType[type] === child.value}
                        />
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <div className="filter-apply-reset-button">
            <Button
              type="button"
              buttonText="Reset"
              className="btn btn-outline-primary"
              onClick={handleResetClick}
            >
              Reset
            </Button>

            <Button
              type="button"
              buttonText="Apply"
              className="btn btn-primary"
              onClick={handleApplyClick}
            ></Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterDropDown;
