import React, { useState } from "react";
import {
  VideoList,
} from "../../molecules/VideoList";
// import { VideoDetailSidebar } from "../../molecules/VideoDetailSidebar";
import { Page } from "../../layouts/Page";
// import VideoUploadSidebar from "../../molecules/VideoUploadSidebar/VideoUploadSidebar";
// import ScheduleBroadcastSidebar from "../../molecules/VideoUploadSidebar/ScheduleBroadcastSidebar";
// import dashboardbreadcrumimage from "../../../dist/images/dashboard/video.png";
// import { getColumnWidth, onColumnResizeHof } from "./helpers";
import useInputState from "../../hooks/useInputState";
// import { useFilters, useVideoList, useProductList } from "./hooks";
// import { BROADCAST_TABLE_COLUMNS, VIDEO_TABLE_COLUMNS, ARCHIVED_TABLE_COLUMNS } from "./constants";
import { Sidebar } from "../../../component/molecules/Sidebar";
import { useHistory } from "react-router-dom";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import PageHeading from "./../../Form/PageHeading";
function Videos() {
  const isMobileSize = isMobile();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
  const history = useHistory();
  // const [selectedVideo, setSelectedVideo] = useState();
  // const [isVideoUploadOpen, setIsVideoUploadOpen] = useState(false);
  // const [isScheduledBroadcastOpen, setIsScheduledBroadcastOpen] = useState(
  //   false
  // );
  // const [isVideoDetailsOpen, setIsVideoDetailsOpen] = useState(false);
  // const [columnWidths, setColumnWidths] = useState([]);
  // const [selectedTab, setSelectedTab] = useState("videos");
  // const [searchText, setSearchText] = useInputState("");




  // const onColumnResize = onColumnResizeHof({
  //   columnWidths,
  //   setColumnWidths,
  //   columns:
  //     selectedTab === "broadcasts"
  //       ? BROADCAST_TABLE_COLUMNS
  //       : (selectedTab === "videos")
  //         ? VIDEO_TABLE_COLUMNS
  //         : ARCHIVED_TABLE_COLUMNS
  // });


  // const {
  //   filters,
  //   setFilters,
  //   filterOptions,
  //   loading: isFiltersLoading
  // } = useFilters(selectedTab);

  // const [editVideo] = useMutation(EditVideo);

  // const { loading: isVideosLoading, videos, refetchVideos } = useVideoList({
  //   filters,
  //   scheduled: selectedTab === "broadcasts",
  //   searchText,
  //   isArchived: selectedTab === "archives",
  //   onUnarchiveVideo,
  //   onAddToReport
  // });
  async function onUnarchiveVideo(rowData) {
    // await editVideo({
    // variables: {
    //   video: {
    //     id: rowData.id,
    //     visible: true,
    //     includeInReport: true
    //   }
    // }
    // });
    // await refetchVideos();
  }
  async function onAddToReport(rowData) {
    // await editVideo({
    //   variables: {
    //     video: {
    //       id: rowData.id,
    //       includeInReport: !rowData.includeInReport
    //     }
    //   }
    // });
    // await refetchVideos();
  }

  // const { loading: isProductsLoading, products } = useProductList();

  // const isLoading = isVideosLoading || isFiltersLoading;

  // function openVideo(video) {
  //   setSelectedVideo(video);
  //   setIsVideoDetailsOpen(true);
  // }

  function onUploadPress() {
    history.push("/videos/add-nav-video")
    // if (selectedTab === "videos") {
    //   setIsVideoUploadOpen(true);
    // } else {
    //   setIsScheduledBroadcastOpen(true);
    // }
  }

  // const tagLine = showUserTypeBaseMessage(
  //   {
  //     'Brand': 'Gear up to Boost Engagement with Seamless Video Management',
  //     'default': '',
  //     'NonProfit': 'Gear up to Boost Fundraising with Seamless Video Management',
  //     'Influencer': 'Gear up to Boost Engagement with Seamless Video Management',
  //     'Individual': 'Gear up to Boost Engagement with Seamless Video Management',
  //   }
  // );
  //Gear up to Boost Fundraising with Seamless Video Management

  const onNavBarClick = () => {
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }

  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title="Your Videos" flex onNavBarClick={onNavBarClick}>
        <PageHeading text="Manage Videos" />
        <TableContainer>
          <VideoList onAddVideo={() => onUploadPress()} />
        </TableContainer>
      </Page>
    </div>
  );
}

export const TableContainer = ({ children }) => {
  return (
    <div className="table_container">
      {children}
    </div>
  )
}

export default Videos;
