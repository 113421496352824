import { useState, useEffect } from "react";
// import WhiteSquareButton from "../../Form/WhiteSquareButton"
// import ImageImg from "./../../../assets/icons/image.svg"
import UploadThumbnailAddVideo from "./UploadThumbnailAddVideo";
import Textarea from "../../Form/Textarea";
import {getExternalRedirectedURL, getReelIdFromUrl,newgetReelIdFromUrl} from "../../helpers/helper";
import FieldWarning from "./../../Form/FieldWarning"
const cheerio = require('cheerio');

function extractVideoId(url) {

    try{
        var match = url.match(/videos%2F(\d+)/);
        return match ? match[1] : null;
    } catch (catchError){
        console.log(catchError);
    }
}

function extractNormalVideoId(url) {
    try{
        var match = url?.match(/(?:\?v=|\/videos\/)(\d+)/);
        return match ? match[1] : null;
    } catch (catchError){
        console.log(catchError);
    }

}

const StreamVideoFacebookFormFields = ({
    setFacebookUrl,
    facebookCode,
    setFacebookCode,
    videoThumbnailURL,
    setVideoThumbnailURL,
    fileUploadLoading,
    setFileUploadLoading,
}) => {
    const [facebookCodeError, setFacebookCodeError] = useState('');
    const [facebookPreview, setFacebookPreview] = useState(null);
    const width = 252;
    const height = 448;

    // handleFacebookChange
    useEffect(() => {

        if(facebookCode){
            handleFacebookChange(facebookCode);
        }
    }, []);

    const handleFacebookChange = async (eventOrDirectValue) => {
        let videoId = '';
        let value;
        if(eventOrDirectValue?.target){
            value = eventOrDirectValue.target.value;
        } else {
            value = eventOrDirectValue;
        }
        setFacebookCode(value)

        let url;
        let videoId2;

        if (!value) {
            setFacebookCodeError('This field is required')
            setFacebookPreview('')
        } else {
            var iframeRegex = /<iframe.*?<\/iframe>/;
            var containsIframe = iframeRegex.test(value);

            const fbWatchRegex = /^(?:https?:\/\/)?(fb\.watch)\b/i;
            let isFBWatchURLTemp; 

            try{
                isFBWatchURLTemp = value?.match(fbWatchRegex); 

            } catch (error){

            }

    
            if (containsIframe) {

               


                const $ = cheerio.load(value);
                url = $('iframe').attr('src');
                var videoId1 = extractVideoId(url);
                videoId = videoId1
                var check = url?.split(".");
                var fbREELId = getReelIdFromUrl(url);

            

                
                if (fbREELId === null && check[1] === "facebook") {

        


                    setFacebookCodeError('')
                    url = `https://www.facebook.com/plugins/video.php?height=${height}&href=https%3A%2F%2Fwww.facebook.com%2F61550106899491%2Fvideos%2F${videoId}%2F&show_text=false&width=${width}&t=0`;
                    setFacebookPreview(url)
                    setFacebookUrl(url)

                } else if(isFBWatchURLTemp) {

                    const newRedirectedUrl = await getExternalRedirectedURL(value);
                    videoId2 = extractNormalVideoId(newRedirectedUrl);
                    videoId = videoId2
                    url=`https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F61550106899491%2Fvideos%2F${videoId}%2F&show_text=false&width=476&t=0`;
                    setFacebookPreview(url)
                    setFacebookUrl(url)
                    setFacebookCodeError('')
          
                  } 
                  else if(fbREELId) {

                    url=`https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Freel%2F${fbREELId}%2F&show_text=true&width=267&t=0`;
                 
       
                    setFacebookPreview(url)
                    setFacebookUrl(url)
                    setFacebookCodeError('')
                  
          
                  } else {
                    setFacebookCodeError('Invalid domain')
                    setFacebookPreview('')
                }

            } else {

         
       

            var newurl=value;
            const fbREELIdnew = newgetReelIdFromUrl(newurl);

   

                if(fbREELIdnew){

                    url=`https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2Freel%2F${fbREELIdnew}%2F&show_text=true&width=267&t=0`;
                
                    setFacebookPreview(url)
                    setFacebookUrl(url)
                    setFacebookCodeError('')

                
                }else{

                    url = value;
                    let check;
                    try{
                        check = url?.split(".");
    
                    } catch(errroCheck){
                        console.log('errroCheck',errroCheck);
                    }
                    videoId2 = extractNormalVideoId(url);
                    videoId = videoId2
    
                    if (check && check[1] == "facebook") {
                        setFacebookCodeError('')
                        url = `https://www.facebook.com/plugins/video.php?height=${height}&href=https%3A%2F%2Fwww.facebook.com%2F61550106899491%2Fvideos%2F${videoId}%2F&show_text=false&width=${width}&t=0`;
                        setFacebookPreview(url)
                        setFacebookUrl(url)
                    } else if(isFBWatchURLTemp) {
                        const newRedirectedUrl = await getExternalRedirectedURL(value);
                        videoId2 = extractNormalVideoId(newRedirectedUrl);
                        videoId = videoId2
                        url=`https://www.facebook.com/plugins/video.php?height=476&href=https%3A%2F%2Fwww.facebook.com%2F61550106899491%2Fvideos%2F${videoId}%2F&show_text=false&width=476&t=0`;
                        setFacebookPreview(url)
                        setFacebookUrl(url)
                        setFacebookCodeError('')
              
                    } else {
                        setFacebookCodeError('Invalid domain')
                        setFacebookPreview('')
                    }


                }

        


              
            }
        }
    };

    return (
        <div className="p-3 py-4">
            <h5 className="fw-bolder mb-1">Upload Video Thumbnail</h5>
            <p className="fs-4">File size should not be more than 2MB. JPG, JPEG, PNG & WEBP supported</p>
            <div className="d-flex flex-row justify-content-center gap-8 mt-2 mb-2">
                <UploadThumbnailAddVideo
                    videoThumbnailURL={videoThumbnailURL}
                    setVideoThumbnailURL={setVideoThumbnailURL}
                    fileUploadLoading={fileUploadLoading}
                    setFileUploadLoading={setFileUploadLoading}
                />
            </div>
            <div className="col-md-12 mb-2">
                <Textarea
                    id="facebook-integration-codes"
                    label="Facebook Integration Code *"
                    value={facebookCode}
                    onChange={handleFacebookChange}
                    disabled={false}
                    placeholder=""
                    errorMessage={facebookCodeError}
                    rows="4"
                />

                {/* {console.log(facebookPreview,"sallll")} */}


                {facebookPreview && <div className="mt-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><iframe title="Facebook" src={facebookPreview} width={width} height={height}></iframe></div>}
            </div>
            <FieldWarning message="This section will demonstrate how to get facebook integration code. It can be either external link or descriptive text here" />
        </div>
    )
}

export default StreamVideoFacebookFormFields;