import jwtDecode from "jwt-decode";


class AuthCustom {
  createAccount({ fullName,email,phoneNumber,password,accountType }) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL+'/create-new-user-otpp', {
            method: 'POST', 
            mode: 'cors', 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "name": fullName,
              "email": email,
              "phone": phoneNumber,
              "password": password,
              "type": accountType,
              "source": "Generic",
            })
          },
          (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          })
       });
  }

  login(email, password,source) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL+'/user-login', {
            method: 'POST', 
            mode: 'cors', 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "email": email,
              "password": password,
              "source":source
            })
          }).then((response) => response.json())
          .then((result) => {
            // if (err) {
            //   reject(err);
            // } else {
              resolve(result);
            // }
          })
    });
  }

  adminUserlogininfo(verificationcode) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL+'/admin-user-login', {
            method: 'POST', 
            mode: 'cors', 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "verificationcode": verificationcode,
            })
          }).then((response) => response.json())
          .then((result) => {

            
            // if (err) {
            //   reject(err);
            // } else {
              resolve(result);
            // }
          })
    });
  }

  

  SendForgetPasswordEmail(email) {
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL+'/forget-password', {
            method: 'POST', 
            mode: 'cors', 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "email": email,
            })
          }).then((response) => response.json())
          .then((result) => {
            // if (err) {
            //   reject(err);
            // } else {
              resolve(result);
            // }
          })
    });
  }

//   sendPasswordReset(email) {
//     return new Promise((resolve, reject) => {
//       webAuth.changePassword(
//         {
//           connection: "Username-Password-Authentication",
//           email
//         },
//         (err) => {
//           if (err) {
//             reject(err);
//           } else {
//             resolve();
//           }
//         }
//       );
//     });
//   }

  getUserInfo(token, idToken) {
    if (!token || !idToken) return null;
    
    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL+'/user-info', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer '+token,
            },
            body: JSON.stringify({ "id": idToken }), // Replace with your POST data
          }).then((response) => response.json())
          .then((result) => {
            // if (err) {
            //   reject(err);
            // } else {
              resolve(result);
            // }
        });
        
    });
  }

  getSavedSession() {
    const savedAuthSession = window.localStorage.getItem("authSession");

    if (!savedAuthSession) {
      throw new Error("not_logged_in");
    }

    const { idToken, accessToken } = JSON.parse(savedAuthSession);
    const { exp } = jwtDecode(idToken);

    if (Date.now() > exp * 1000) {
      throw new Error("token_expired");
    }
    
    return { idToken, accessToken };
  }
}

export default AuthCustom;
