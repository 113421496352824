import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";

const ContainerStyles = {
    height: 48,
    // width: 482,
    padding: 10,
    border: "1px solid #DFE5EF",
    borderRadius: 4,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    fontSize: 10,
    textTransform: "uppercase",
    fontWeight: "bold"
}

const ColorTextStyle = {
    padding: 10,
    fontSize: 14,
    fontWeight: 500,
    fontStyle: "normal",
    color: "#000" 
}

const InnerContainerStyles = {
    height: 40,
    width: 100,
    borderRadius: 8,
    border: "1px solid rgb(223, 229, 239)"
}

const PickerStyles = {
    default: {
        picker: {
            position: 'absolute',
            zIndex: 9999
        },
    }
};

const ColorField = ({ label, defaultValue, onChange, show, onShowChange }) => {
    const [color, setColor] = useState(defaultValue);

    useEffect(() => {
        setColor(defaultValue)
    }, [defaultValue])

    return (
        <div className="form-group row user-select-none">
        
            <label className="col-form-label">{label}</label>
            <div className="position-relative">
                <div style={ContainerStyles} className="vs-color-picker" onClick={() => onShowChange(!show)}>
                    <div style={{ ...InnerContainerStyles, backgroundColor: color }}></div>
                    <span style={ColorTextStyle}>{color}</span>
                </div>
                {show && (
                    <SketchPicker
                        onChange={(c) => setColor(c.hex)}
                        color={color}
                        onChangeComplete={e => onChange(e)}
                        styles={PickerStyles}
                        className="sketch-picker"
                    />
                )}
            </div>
        </div>
    );
}

export default ColorField;