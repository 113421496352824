import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index";
import { useHistory } from "react-router-dom";
import ActivityLog from "../../helpers/ActivityLog";




const OrderList = ({
  // isLoading,
  // loadingMore,
  // orders,
  // // columns,
  // onSortPress,
  // onStatusChange,
  // emptyMessage = "No Orders Found",
  // sort
}) => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState('');
  const [success, setSuccess] = useState(false);


  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    fetchVideosData();

  }, [currentPage, rowsperPage, searchValue]);

  const fetchVideosData = () => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/all-leads-form', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({  "id": token.idToken ,"currentPage": currentPage ,"rowsPage": rowsperPage,"search": searchValue}), 
    })
      .then((response) => response.json())
      .then((data) => {
        setVideotdata(data.data)
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(true)

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };


  const fetchDeleteData = (id) => {
    let token = localStorage.getItem("authSession")
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + '/delete-lead', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token.accessToken,
      },
      body: JSON.stringify({ "id": id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        // setVideotdata(data.data)
        if (data.code?.toString() === "200") {

          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem('admin_login_id');
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 7;
          var account_id = token.idToken;
          userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })


          setSuccess("Lead deleted succesfully")
          setSelectedOrderId(null);
          fetchVideosData()
          setLoader(true)
          setTimeout(resetState, 1000);
        } else {
          setErrors("Something went wrong")
          setTimeout(resetState, 1000);
        }

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setErrors("Something went wrong")
        setTimeout(resetState, 1000);

      });


  };

  const handleClick = (id) => {
    console.log(`You clicked me! ${id}`);
    history.push("/edit-lead/" + id)
  };

  const handleDeleteClick = (id) => {
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId)
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const resetState = () => {
    setErrors('');
    setSuccess('');
  };

  const Popup = ({ onClose, onDelete }) => {
    return (

      <div class="modal fade show" id="al-danger-alert" tabindex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block", " padding-left": "0px" }} aria-modal="true" role="dialog">

        <div class="modal-dialog modal-sm" >
          <div class="modal-content modal-filled bg-light-danger">
            <div class="modal-body p-4">
              <div class="text-center text-danger">
                <i class="ti ti-x fs-7" onClick={onClose}></i>
                <h4 class="mt-2">Are You sure?</h4>
                <p class="mt-3">
                  You Want to delete Permanently?
                </p>
                <button type="button" class="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onClose}> Cancel</button>
                <button type="button" class="btn btn-light my-2 btn-dlt" data-bs-dismiss="modal" onClick={onDelete}>Delete</button>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  };





  const columns = [
    // {
    //   name: "#",
    //   selector: (row, i) => row.ordersid,
    //   sortable: true,
    //   width: '60px',
    //   center: true,
    // },
    {
      name: "Field Title",
      selector: (row, i) => row.field_title,
      sortable: true,
      width: '150px',

    },
    {
      name: "Field Type",
      selector: (row, i) => row.field_type,
      sortable: true,
      width: '150px',
    },
    {
      name: "Is Required",
      selector: (row, i) => row.requied,
      sortable: true,
      width: '180px',
    },
    {
      name: "Date",
      selector: (row, i) => row.created_at,
      sortable: true,
      width: '150px',
      center: true,
    },
    {
      name: "Action",
      selector: (row, i) => row.id,
      sortable: true,
      width: '100px',
      center: true,
      cell: (d) => {
        return (

          <div className="action-btn tbl-action-buttons">
            <a href="javascript:void(0)" key={d.id} onClick={handleClick.bind(this, d.id)} className="text-dark delete ms-2">
              <i class="ti ti-pencil fs-7" ></i>
            </a>

            <a href="javascript:void(0)" key={d.id} onClick={handleDeleteClick.bind(this, d.id)} className="text-dark delete ms-2">
              <i className="ti ti-trash fs-7" />
            </a>
          </div>

        )
      }
    }


  ]




  const data = videodata;

  const tableData = {
    columns,
    data
  };

  const handlePageChange = (page) => {
  
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {

    setCurrentPage(page);
    setrRowsperPage(newPerPage)

  };

  const handleSearchChange = (event) => {

    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false)

  };

  return (
    <>

      <div className="App">

        {showPopup ?
          <Popup
            onClose={handleClosePopup}
            onDelete={handleDelete}
          /> : ""}
        {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
        {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""}

        <div id="complex_header_filter" class="dataTables_filter search_section data-table-extensions-filter">
  <label for="filterDataTable" class="icon "></label>
   
      <input
      type="text"
      value={searchValue}
      onChange={handleSearchChange}
      placeholder="Search"
      />
      
      </div>

        {loader ?

          <DataTableExtensions {...tableData} export={false} print={false} >
            <DataTable
              columns={columns}
              data={data}
              defaultSortField="id"
              defaultSortAsc={true}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              highlightOnHover
              dense
            />
          </DataTableExtensions>
          : <div class="d-flex justify-content-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        }


        {showPopup ? <div class="modal-backdrop fade show"></div> : ""}
      </div>

    </>
  );
};

export default OrderList;
