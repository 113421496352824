import Auth0 from "./Auth0";

class UploadHelper {
  /** Upload Types */
  static PRODUCT_IMAGE = "product_image";
  static VIDEO = "video";
  static THUMBNAIL = "thumbnail";
  static AVATAR = "avatar";
  static BRAND_IMAGE = "brand_image";

  static getUploadEndpoint(resource) {
    switch (resource) {
      case "product_image":
        return "upload";
      case "video":
        return "upload/video";
      case "thumbnail":
        return "upload/thumbnail";
      case "avatar":
        return "upload/avatar";
      case "brand_image":
        return "upload/brand_image";
      default:
        throw new Error("Unknown upload resource: " + resource);
    }
  }

  static async getSignedUrl(resource, options = {}) {
    return new Promise((resolve, reject) => {
      const accessToken = JSON.parse(window.localStorage.getItem("authSession"))?.accessToken;

      // const auth0 = new Auth0();
      // const token = auth0.getSavedSession().idToken;
      const xhr = new XMLHttpRequest();
      const endpoint = this.getUploadEndpoint(resource);

    
      xhr.open("POST", `${process.env.REACT_APP_API_URL}/${endpoint}`);
      xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
      xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);

      xhr.onreadystatechange = (e) => {
        if (xhr.readyState !== 4) {
          return;
        }
    
        if (xhr.status === 200) {
          return JSON.parse(xhr.responseText);
        } else {
          console.warn('request_error');
        }
      };

      
      xhr.send(
        JSON.stringify({
          contentType: options.contentType || "image/jpeg",
          key: options.key
        })
      );

      xhr.addEventListener("load", () => {
        resolve(JSON.parse(xhr.responseText));
      });

      xhr.addEventListener("error", (err) => {
        reject(err);
      });


    });
  }

  // static uploadToS3(url, file, onProgress) {
  //   return new Promise((resolve, reject) => {
  //     const xhr = new XMLHttpRequest();

  //     xhr.open("PUT", url);
  //     // xhr.setRequestHeader("X-Amz-ACL", "public-read");
  //     xhr.setRequestHeader("Content-Type", file.type);
  //     xhr.setRequestHeader("Cache-Control", "max-age=2592000");

  //     xhr.upload.addEventListener("progress", onProgress);

  //     xhr.addEventListener("load", () => {
  //       // resolve(); 
  //       resolve(xhr.response);
  //     });

  //     xhr.addEventListener("error", (err) => {
  //       reject(err);
  //     });

  //     xhr.send(file);
  //   });
  // }

  static uploadToS3(url, file, onProgress, onSuccess) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
  
      xhr.open("PUT", url);
      // xhr.setRequestHeader("X-Amz-ACL", "public-read");
      xhr.setRequestHeader("Content-Type", file.type);
      xhr.setRequestHeader("Cache-Control", "max-age=2592000");
  
      xhr.upload.addEventListener("progress", onProgress);
  
      xhr.addEventListener("load", () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          if (onSuccess) {
            onSuccess(xhr.response);
          }
          resolve(xhr.response);
        } else {
          reject(xhr.status);
        }
      });
  
      xhr.addEventListener("error", (err) => {
        reject(err);
      });
  
      xhr.send(file);
    });
  }

  static uploadToFDCServer(file, filename, onSuccess) {
    return new Promise((resolve, reject) => {
      const formdata = new FormData();
      formdata.append("filename", filename);
      formdata.append('file', file);

      const requestOptions = {
        method: "POST",
        body: formdata,
        mode: "cors"
      };

      fetch("https://cdnvod.videosquirrel.app/upload-file.php", requestOptions)
        .then((response) => response.json())
        .then((e) => {
          onSuccess(e)
          resolve(e)
        })
        .catch((e) => reject(e));
    });
  }

  static uploadExternalImageToS3(url) {
    return new Promise((resolve, reject) => {
      const auth0 = new Auth0();
      const token = auth0.getSavedSession().idToken;
      const xhr = new XMLHttpRequest();

      xhr.open(
        "GET",
        `${process.env.REACT_APP_API_URL}/upload/external/${encodeURIComponent(
          url
        )}`
      );
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.send("");

      xhr.addEventListener("load", () => {
        const { url } = JSON.parse(xhr.response);
        resolve(url);
      });
      xhr.addEventListener("error", () => {
        reject("Oops! Something went wrong with your network");
      });
    });
  }

  static getImageDimensions(imageUrl) {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () =>
        resolve({ width: image.width, height: image.height });
      image.onerror = (err) => reject(err);
    });
  }
}

export default UploadHelper;
