import stripe from "stripe-client";

const initializedStripe = stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const InvalidCardNumberError = "InvalidCardNumberError";
export const InvalidCardCvvError = "InvalidCardCvvError";
export const InvalidCardExpirationError = "InvalidCardExpirationError";
export const InvalidCardholderNameError = "InvalidCardholderNameError";
export const InvalidCardError = "InvalidCardError";

class CardError extends Error {
  constructor(message, name) {
    super(message);
    this.name = name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export default {
  createTokenFromCard: async ({ cardholderName, cardNumber, expiration, cvv }) => {
    const response = await initializedStripe.createToken({
      card: {
        name: cardholderName,
        number: cardNumber,
        cvc: cvv,
        exp_month: expiration.split("/")[0],
        exp_year: expiration.split("/")[1]
      }
    });

    const { status } = response;
    if (status === 200) {
      return response;
    }

    const body = await response.json();
    switch (body.error.param) {
      case "number":
        throw new CardError("Invalid card number.", InvalidCardNumberError);
      case "exp_year":
        throw new CardError(
          "Invalid card expiration, or card is expired.",
          InvalidCardExpirationError
        );
      case "cvc":
        throw new CardError("Invalid CVV.", InvalidCardCvvError);
      case "name":
        throw new CardError("Invalid or unrecognized cardholder name.", InvalidCardholderNameError);
      default:
        throw new CardError("Invalid card information.", InvalidCardError);
    }
  }
};
