import React, { useState } from "react";
import ForgotPasswordExplanation from "./ForgotPasswordExplanation";
import ForgotPasswordForm from "./ForgotPasswordForm";
// import Auth0 from "../../helpers/Auth0";
import { isEmail } from "validator";
import AuthCustom from "../../helpers/AuthCustom";
import { OnboardingPage } from "../../layouts/OnboardingPage";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);

  
  const disabled = email === "" || !isEmail(email);

  const sendPasswordReset = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      
      const auth0 = new AuthCustom();
      const response = await auth0.SendForgetPasswordEmail(email);

      if(response?.code && response?.status===false){
        setError({
          title: "Error",
          message:response?.message
        });
      }
      setSuccess(response?.status===false?false:true);

     

      setLoader(false);
    } catch (err) {
      setLoader(false); 
      setError({
        title: "Something went wrong.",
        message:
          "We apologize, we couldn't reset your password at this time. Please try again in a few minutes."
      });

      throw err;
    }
  };

  return (
    <OnboardingPage title="Forgot your password?">

      <ForgotPasswordForm
        email={email}
        error={error}
        success={success}
        disabled={disabled}
        onSubmit={sendPasswordReset}
        loader={loader}
        onEmailChange={(e) => setEmail(e.target.value)}
      />
     </OnboardingPage>
  );
}

export default ForgotPassword;
