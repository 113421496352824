import React, { useState, useRef, useEffect } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";

const DateTimePicker = ({
  value,
  onChange,
  isValidDate,
  inputProps,
  errorMessage,
  placeholder="Select Date and Time",
  label,
  id
}) => {
  const [dateTime, setDateTime] = useState(value);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const datePickerRef = useRef(null);

  const handleDateChange = (momentDate) => {
    setDateTime(momentDate);
  };

  const handleFocus = () => {
    setIsPickerOpen(true);
  };

  const handleClickOutside = (event) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target)
    ) {
      setIsPickerOpen(false);
      if (dateTime) {
        const dateTimeOnly = dateTime.format("YYYY-MM-DD HH:mm:ss");
        // console.log('dateTime-->>', dateTime);
        // console.log('dateTimeOnly-->>', dateTimeOnly);
        onChange(dateTimeOnly);
      } else {
        onChange(dateTime);
        // console.log('dateTime is null');
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dateTime]);  // Add dateTime as a dependency to ensure it has the latest value

  useEffect(() => {
    // console.log('value--->',value)
    if (value) {
      setDateTime(moment(value));
    }
  }, [value]);

  return (
    <div ref={datePickerRef}>

    {label?<label htmlFor={id} className="form-label">{label}</label>:""}


      <Datetime
        value={dateTime}
        onChange={handleDateChange}
        inputProps={{
          placeholder: placeholder,
          onFocus: handleFocus,
        }}
        closeOnSelect={false}
        isValidDate={isValidDate}
        open={isPickerOpen}
      />
        {errorMessage && <div><span className="text-danger fs-2 lh-1">{errorMessage}</span></div>}

    </div>
  );
};

export default DateTimePicker;
