import React, { useState, useEffect, useRef } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { useHistory, Link } from "react-router-dom";
import validator from "validator";
import VideoDropDown from "./VideoDropDown";
import { Container, Row, Col, Card } from "react-bootstrap";
import {
  showUserTypeBaseMessage,
  randomNumberInRange,
  urlSegment,
  showWidgetSharingCode,
  loggedUserAccountType,
  userLoggedAccessToken,
  isMobile,
} from "../../../helpers/helper";
import Select from "react-select";
import Modal from "react-modal";
import BreadCrump from "../../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../../dist/images/dashboard/integeration.png";
import ActivityLog from "../../../helpers/ActivityLog";
import { isURL } from "validator";
import validateField, { validateHttpsUrl } from "../../../helpers/AllValidtions";
import CopyToClipboard from "./../../../../component/Form/CopyToClipboard";
import SelectProductSelector from "../../../Product/SelectProduct";
import Button from "../../../../../src/component/Form/Button";
import VideoProductCardBox from "../../../../../src/component/Form/VideoProductCardBox";
import CarouselSlider from "../../../../../src/component/Form/CarouselSlider";
import ArrowLeft from "../../../../assets/icons/arrow-left.svg";
import copyIcon from "../../../../assets/icons/copy-icon.svg";

import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../Form/FormToast";

import Carousel from "react-multi-carousel";

{/* <FormToast
                    type={FORM_TOAST_TYPES.DANGER
// showUserTypeBaseMessage({Brand: 'Emb ed Videos to Web', default:'Add Integration to Web'}) */}

function AddVideoWidget(...props) {
  const forAddEdit = showUserTypeBaseMessage({
    Brand: "Embed Videos to Web",
    default: "Add Integration to Web",
    NonProfit: "Embed Videos to Web",
    Influencer: "Embed Videos to Web",
    Individual: "Embed Videos to Web",
  });
  const isMobileSize = isMobile();
  const history = useHistory();
  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );
  const [title, setTitle] = useState("");
  const [domainURL, setDomainURL] = useState("");
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [options, setOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [saveWidgetLoader, setSaveWidgetLoader] = useState(false);
  const [widgetCode, setWidgetCode] = useState("");
  const [widgetCodeScript, setWidgetCodeScript] = useState("");
  const copyButtonDefaultText = "Copy Code";
  const [editWidgetData, setWidgetData] = useState([]);
  const [copyButtonText, setCopyButtonText] = useState(copyButtonDefaultText);
  const [videodata, setVideotdata] = useState([]);
  const [activeInactive, setActiveInactive] = useState(true);
  const [videosOptions, setVideosOptions] = useState(false);
  const [titlePlaceHolder, setTitlePlaceHolder] = useState("");
  const [websiteNamePlaceHolder, setWebsiteName] = useState("");
  const [generateButtonText, setGenerateButtonText] = useState("");
  const [pageHeading, setPageHeading] = useState(forAddEdit);
  const [saveUpdateUrl, setSaveUpdateUrl] = useState("/add-widget");
  const [widgetId, setWidgetId] = useState("");
  const maxVideoLimit = 100;
  const copyScriptRef = useRef(null);
  const copyCodeIcon = "ti ti-clipboard";
  const copyCodeDefaultText = "Copy Code";
  const [copyCodeText, setCopyCodeText] = useState(copyCodeDefaultText);
  const [copyButtonIcon, setcopyButtonIcon] = useState(copyCodeIcon);
  const [copyCodeTimeoutId, setCopyCodeTimeoutId] = useState(null);
  const [selectedVideoOptions, setSelectedVideoOptions] = useState([]);
  const [titleError, setTitleError] = useState("");
  const [domainURLError, setDomainURLError] = useState("");
  const [addVideoError, setaddVideoError] = useState("");
  const [forceToEditAfterCreatedId, setForceToEditAfterCreatedId] =
    useState(null);
  const [selectVideoModal, setSelectVideoModal] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsTemp, setSelectedOptionsTemp] = useState([]);

  const [finaldomainURL, setFinalDomainURL] = useState("");

  // const forAddEdit = showUserTypeBaseMessage({Brand: 'Embed Videos to Web', default:'Add Integration to Web'})
  const customTitle = showUserTypeBaseMessage({
    Brand: "Embed Videos to Web",
    default: "Add Integration to Web",
  });

  let selectVideosDesc = showUserTypeBaseMessage({
    Brand: `You can embed up to ${maxVideoLimit} videos on your website`,
    default: `You can select up to ${maxVideoLimit} videos to integrate to your website`,
    NonProfit: `You can embed up to ${maxVideoLimit} videos on your website`,
    Influencer: `You can embed up to ${maxVideoLimit} videos on your website`,
    Individual: `You can embed up to ${maxVideoLimit} videos on your website`,
  });

  selectVideosDesc = `Video ${selectedOptions?.length}/${maxVideoLimit} Select multiple videos to create a carousel`;

  //

  // const selectVideosDesc = showUserTypeBaseMessage({Brand: `
  // You can embed up to ${maxVideoLimit} videos on your website`, default:`
  // You can select up to ${maxVideoLimit} videos to integrate to your website`})

  useEffect(() => {
    fetchVideosData();
    handleParamtersToSetAddEdit();
  }, []);

  useEffect(() => {
    if (forceToEditAfterCreatedId) {
      handleParamtersToSetAddEdit();
    }
  }, [forceToEditAfterCreatedId]);

  const handleParamtersToSetAddEdit = () => {
    if (urlSegment(2) == "edit" || forceToEditAfterCreatedId >= 1) {
      // setPageHeading('Edit Integration to Web');

      const editId = forceToEditAfterCreatedId ?? urlSegment(3);
      setPageHeading(`Edit ${forAddEdit}`);
      setWidgetId(editId);
      fillPlaceHolders(true);
      setSaveUpdateUrl("/update-widget");
      fetchWidgetData(editId);
    } else {
      setActiveInactive(true);

      if (!forceToEditAfterCreatedId) {
        if (!options.length) {
          addOption(); //initialized value
        }
      }
    }
  };

  // useEffect(() => {
  //   setErrorMessage(null);
  //   if(!isURL(domainURL) && domainURL?.length>=1){
  //     setErrorMessage('Please enter valid Website URL');
  //   }
  // }, [domainURL]);

  const handleTitleChange = (e) => {
    var value = e.target.value; 
    var rule = "required";
    setTitle(value);
    validateField(value, rule, setTitleError);
  };

  function extractDomain(url) {
    // Remove any leading or trailing spaces
    url = url.trim();
    
    // Remove 'http://' or 'https://'
    url = url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '');

    // Extract domain
    var domain = url.split('/')[0];

    return domain;
}


  const handleUrlChange = (e) => {
    var value = e.target.value;
    var rule = "url";
    setDomainURL(value);
    validateField(value, rule, setDomainURLError);

    if (!validateHttpsUrl(value)) {
      setDomainURLError('Please ensure the URL starts with "https://"')
    }
    
    setFinalDomainURL(extractDomain(value));
  };

  // isURL

  const fillValues = (widgetParam) => {
    if (widgetParam?.length > 0) {
      setTitle(widgetParam[0].title);
      setDomainURL(widgetParam[0].website_url);
      setFinalDomainURL(widgetParam[0].website_url);
      fillWidgetCode(widgetParam[0]);

      setActiveInactive(widgetParam[0].is_active);


      setSelectedOptions(widgetParam[0].selectedVideos);

      const updatedSelectedVideoOptions = [];

      // addOptionRow(null);
      // setOptions([]); NOT REQUIRED AFTER NEW DESIGN
      setSelectedVideoOptions(updatedSelectedVideoOptions);

      if (widgetParam[0]?.selectedVideos) {
        widgetParam[0].selectedVideos.map((selectedVideosOpt, index) => {
          addOptionRow(selectedVideosOpt.video_id);
          updatedSelectedVideoOptions[index] = parseInt(
            selectedVideosOpt.video_id
          );
        });

        setSelectedVideoOptions(updatedSelectedVideoOptions);
      } else {
        addOptionRow(null);
      }

      fillPlaceHolders(false);
    } else {
      //
    }
  };

  const fillPlaceHolders = (setPlaceHolder) => {
    setTitlePlaceHolder(setPlaceHolder ? "Loading title ...." : null);
    setWebsiteName(setPlaceHolder ? "Loading title ...." : null);
    setGenerateButtonText(setPlaceHolder ? "Loading title ...." : null);
    setGenerateButtonText("Re Generate Code");
  };

  const addOptionRow = (selectValue) => {
    if (options.length < maxVideoLimit) {
      addOption(selectValue);
    }
  };

  const fillWidgetCode = (widgetCodeParam) => {
    setWidgetCode(widgetCodeParam.app_id);
    setWidgetCodeScript(showWidgetSharingCode(widgetCodeParam));
  };

  // const copyScriptTag = async () => {
  //   try {
  //     const textToCopy = copyScriptRef.current.value;
  //     await navigator.clipboard.writeText(textToCopy);
  //     setCopyButtonText('Copied  ...');
  //   } catch (error) {
  //     setCopyButtonText('Not copied  ...');
  //     console.error('Error copying text:', error);
  //   }
  // };

  const copyScriptTag = async () => {
    try {
      const textToCopy = copyScriptRef.current.value;
      await navigator.clipboard.writeText(textToCopy);
      setcopyButtonIcon("ti ti-check");
      setCopyCodeText("Copied!");
    } catch (error) {
      setcopyButtonIcon("ti ti-x");
      setCopyCodeText("Failed!");
      console.error("Error copying text:", error);
    }

    if (copyCodeTimeoutId) {
      clearTimeout(copyCodeTimeoutId);
    }
    const newCopyCodeTimeoutId = setTimeout(function () {
      setcopyButtonIcon(copyCodeIcon);
      setCopyCodeText(copyCodeDefaultText);
    }, 3000);
    setCopyCodeTimeoutId(newCopyCodeTimeoutId);
  };

  const updateThubmailNValue = (index, data) => {
    options[index].value = data?.value;

    if (options.lenght == 0) {
      setaddVideoError("This is required");
    }

    // Update the selected options state
    const updatedSelectedVideoOptions = [...selectedVideoOptions];
    updatedSelectedVideoOptions[index] = data?.value;
    setSelectedVideoOptions(updatedSelectedVideoOptions);

    if (index >= 0) {
      // options[index].video_thumbnail = data?.video_thumbnail;
      options[index].value = data?.value;
      setOptions([...options]);
    }
  };

  const addOption = (selectValue = null) => {
    const newOption = {
      id: options.length + 1,
      // id: randomNumberInRange(10000,20000),
      value: selectValue ? selectValue : null,
      // video_thumbnail: null
    };

    setOptions((prevOptions) => [...prevOptions, newOption]);

    // if(selectValue){
    //   // updateThubmailNValue(options.length,{id:newOption.value});
    // }
  };

  // const removeOption = (id,index,selectedValue) => {
  //   console.log('id>>',id,'index>>',index,'options>>',options,'selectedValue>>',selectedValue);
  //   // const updatedOptions = options.filter(option => option.id !== id);
  //   // const updatedOptions = options.filter((item, i) => i !== index);
  //   let updatedOptions;;
  //   if(selectedValue){
  //     updatedOptions = options.filter((item, i) => ( i !== index && item.id !== id && item.value !== selectedValue ) );
  //   } else {
  //     updatedOptions = options.filter((item, i) => ( i !== index && item.id !== id ) );
  //   }

  //   const updatedSelectedVideoOptions = selectedVideoOptions.filter(value => value !== selectedValue);
  //   setSelectedVideoOptions(updatedSelectedVideoOptions);

  //   console.log('updatedOptions',updatedOptions);
  //   setOptions(updatedOptions);
  // };


  // selectedOptions, setSelectedOptions


  const removeOptionNewDesign = (index) => {
    // const updatedOptions = options.filter(
    //   (item, i) => !(i === index )
    // );

    // selectedOptions, setSelectedOptions

    const updateSelectedOptions = [...selectedOptions];
    updateSelectedOptions.splice(index, 1);
    setSelectedOptions(updateSelectedOptions);
    // setOptions(updatedOptions);
  };


  const removeOption = (id, index, selectedValue) => {
    const updatedOptions = options.filter(
      (item, i) => !(i === index && item.id === id)
    );

    const updatedSelectedVideoOptions = [...selectedVideoOptions];
    updatedSelectedVideoOptions.splice(index, 1);
    setSelectedVideoOptions(updatedSelectedVideoOptions);
    setOptions(updatedOptions);
  };

  const fetchWidgetData = (paramWidgetId) => {
    // var token = localStorage.getItem("authSession")
    // var token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/user-widget", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ widgetId: paramWidgetId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setWidgetData(data.data);
        setLoader(true);
        fillValues(data.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        // console.error('Error fetching data:', error);
      });
  };

  const fetchVideosData = () => {
    // all-videos-list-for-widgets

    // fetch(process.env.REACT_APP_API_URL + '/all-videos-list', {
    fetch(process.env.REACT_APP_API_URL + "/all-videos-list-for-widgets", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setVideotdata(data.data);
        setLoader(true);

        const productMultiOpt = [];

        data.data.map((dataOptions) =>
          productMultiOpt.push({
            value: dataOptions.id,
            label: dataOptions.videoname,
          })
        );

        setVideosOptions(productMultiOpt);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        // console.error('Error fetching data:', error);
      });
  };

  const validateSelectedVideos = () => {
    if (options?.length >= 1) {
      for (const forSingleOpt of options) {
        if (!forSingleOpt.value) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const createWidget = async () => {
    if (options.length == 0) {
      setaddVideoError("This is required");
    }

    setErrorMessage(null);
    setCopyButtonText(copyButtonDefaultText);
    const params = {
      title: title,
      domainURL: finaldomainURL,
      // selectedVideos: options,
      selectedVideos: selectedOptions,
      isActive: activeInactive,
    };

    if (widgetId > 0 && !isNaN(widgetId)) {
      params.widgetId = widgetId; //for edit
    }

    const accessToken = JSON.parse(
      window.localStorage.getItem("authSession")
    ).accessToken;

    let token = localStorage.getItem("authSession");
     token = JSON.parse(token);

    setSaveWidgetLoader(true);

    setWidgetCode("");
    setWidgetCodeScript("");
    await fetch(process.env.REACT_APP_API_URL + saveUpdateUrl, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        setSaveWidgetLoader(false);
        if (response.ok) {
          response.json().then((json) => {
            if (json) {
              const userLog = new ActivityLog();
              const admin_login_id = localStorage.getItem("admin_login_id");
              var user_id = admin_login_id ? admin_login_id : token.idToken;
              var is_admin = admin_login_id ? 1 : 0;
              var event_id = saveUpdateUrl == "/add-widget" ? 11 : 12;
              var account_id = token.idToken;
              const userLogresult = userLog.createaAtivitylog({
                user_id,
                is_admin,
                event_id,
                account_id,
              });

              if (json?.widgetData?.id && !forceToEditAfterCreatedId) {
                setForceToEditAfterCreatedId(json?.widgetData?.id?.toString());
              }

              if (json?.widgetData) {
                fillWidgetCode(json?.widgetData);
              }
              // history.push({
              //     pathname: '/success-update-checkout-nav',
              // });
            }
          });
        } else {
          response.json().then((json) => {
            if (json) {
              setErrorMessage(json?.message);
            } else {
              setErrorMessage("Something went wrong");
            }
          });
        }
      })
      .catch((error) => {
        setErrorMessage("Something went wrong");
      })
      .finally((error) => {
        setSaveWidgetLoader(false);
      });
  };

  const modalStyles = {
    content: {
      top: "10px",
      inset: "auto",
      width: isMobileSize ? "90%" : "700px",
      minWidth: isMobileSize ? "90%" : "700px",
      margin: "100px auto",
      position: "relative",
      border: "none",
      backgroundColor: null,
      height: "100vh",
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "#00000066",
    },
  };

  const isButtonDisabled =
    !title.trim() ||
    !isURL(domainURL) ||
    // validateSelectedVideos() == false ||

    !selectedOptions?.length  ||
    titleError ||
    domainURLError;
  // const isButtonDisabled = !title.trim() || !domainURL.trim() || validateSelectedVideos()==false;

  // const handleOptionChange = (e) => {
  //   console.log(e,'awefawe')
  // }

  const handleOptionChange = (e) => {
    if (e?.length <= maxVideoLimit) {
      setSelectedOptions(e);
    }
  };

  useEffect(() => {}, [selectedOptions]);

  // setSelectVideoModal

  const cancelPopup = () => {
    setSelectedOptions(selectedOptionsTemp);
    setSelectVideoModal(false);
  };

  const selectPopup = () => {
    setSelectedOptionsTemp(selectedOptions);
    // setSelectedOptions(selectedOptionsTemp);
    setSelectVideoModal(false);
  };

  const openSelectVideosPopup = () => {
    setSelectedOptionsTemp(selectedOptions);
    setSelectVideoModal(true);
  };

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2.3,
      slidesToSlide: 4 // optional, default to 1.
    },  
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2.3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobilexSmall: {
      breakpoint: { max: 349, min: 280 },
      items: 1.3,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobileSmall: {
      breakpoint: { max: 499, min: 350 },
      items: 1.6,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobileLarge: {
      breakpoint: { max: 767, min: 500 },
      items: 2.3,
      slidesToSlide: 1 // optional, default to 1.
    }
  };



  const Slider = () => {
    return (
      <div className="widget-new-slider">
        <Container>
              <Carousel
                responsive={responsive}
                autoPlay={false}
                swipeable={true}
                draggable={false}
                partialVisible={false}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
              >
                {selectedOptions.map((selectedOption, index) => {
                  return (
                    <div className="slider" key={index}>
                        <VideoProductCardBox
                            boxImgSrc={selectedOption?.image}
                            boxTitle={selectedOption?.name}
                            crossDeleteBtnFunction={removeOptionNewDesign}
                        />
                    </div>
                  );
                })}
              </Carousel>
        </Container>
      </div>
    );
  };
  
  const nagivateToWidgetListing = () => {
    return history.push({ pathname: "/videos/integration-on-web" });
}



  return (
    // <div className="page-wrapper" id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
    <div
      className={`page-wrapper add-video-widget ${
        sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
      }`}
      id="main-wrapper"
      data-layout="vertical"
      data-sidebartype={sidebarType}
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title={pageHeading} onNavBarClick={onNavBarClick} formWidth={true}>
        <div className="row">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header">
                        <div className="card-header-back" onClick={nagivateToWidgetListing}>
                            <i className="ti ti-arrow-left"></i>
                        </div>
                        <h5 className="fw-bolder">{forAddEdit}</h5>
                    </div>


        {/* <BreadCrump
                    title={pageHeading}
                    image={dashboardbreadcrumimage}
                    breadCrumpDetail={[
                        { name: "Dashboard", link: "/" },
                        { name: "Integration to web", link: "/videos/integration-on-web/web-integration" }
                    ]}
            /> */}
        {/* <AddProduct callFromVideo="true" /> */}
        {/* <div className="row"> */}
          {/* <div className="card-body wizard-content no-p-sm-0 no-p-xs-0"> */}
          <div className="card-body wizard-content">
            {showSuccess || successMessage ? (
              <div className="alert alert-success" role="alert">
                <strong>Success </strong> {successMessage ? successMessage : ""}{" "}
              </div>
            ) : (
              ""
            )}

            {showErrors || errorMessage && (
                <FormToast
                    type={FORM_TOAST_TYPES.DANGER}
                    message={errorMessage
                      ? errorMessage
                      : ":  Please fill the required fields."}
                />
            )}


            {/* {showErrors || errorMessage ? (
              <div className="alert alert-danger" role="alert">
                <strong>Error </strong>{" "}
                {errorMessage
                  ? errorMessage
                  : ":  Please fill the required fields."}{" "}
              </div>
            ) : (
              ""
            )} */}
            {/* <p className="card-subtitle mb-3"> You can us the validation like what we did </p> */}
            <form
              className="validation-wizard wizard-circle wizard clearfix widget-form-area"
              role="application"
              id="steps-uid-7"
              noValidate="novalidate"
            >
              <div className="content clearfix">
                {/* Step 1 */}
                <section
                  id="steps-uid-7-p-0"
                  role="tabpanel"
                  aria-labelledby="steps-uid-7-h-0"
                  className="body current"
                  aria-hidden="false"
                >
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <label htmlFor="title">
                          Title *
                        </label>
                        <input
                          type="text"
                          className="form-control required"
                          id="title"
                          maxLength="50"
                          value={title}
                          placeholder={
                            titlePlaceHolder ? titlePlaceHolder : "Enter Title"
                          }
                          onChange={handleTitleChange}
                        />
                        {titleError ? (
                          <small className="text-danger">{titleError}</small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="mb-4">
                        <label htmlFor="videodesc">
                          Website URL *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name=""
                          id="videodesc"
                          cols="20"
                          rows="3"
                          maxLength="100"
                          value={domainURL}
                          onChange={handleUrlChange}
                          placeholder={
                            websiteNamePlaceHolder
                              ? websiteNamePlaceHolder
                              : "http(s)://domain.com"
                          }
                        />
                        {domainURLError ? (
                          <small className="text-danger">
                            {domainURLError}
                          </small>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-12 mb-4 d-flex justify-content-spacebetween">
                      <div>
                        <label>
                          Select Videos *
                        </label>
                        <br />
                        <span>{selectVideosDesc}</span>

                        {/* {options.map((option,index) => (
                            <div style={{display:'flex',justifyContent:'space-between'}} className="mb-3" key={index}>
                              <div style={index>=1?{width:'calc(98% - 46px)'}:{width:'100%'}}>
                                {videosOptions?.length >=1 && (
                                  <Select
                                  defaultValue={(videosOptions?.length >= 1) ? videosOptions.find(item => item.value === parseInt(option.value)) : ""}
                                  onChange={e => updateThubmailNValue(index, e)}
                                  options={videosOptions.filter(videoOption => !selectedVideoOptions.includes(videoOption.value))}
                                  isMulti={false}
                                  menuPortalTarget={document.body} menuPosition={'fixed'}
                                  placeholder="Select Video"
                                />
                                )}

                                {!videosOptions?.length && (
                                  <Select
                                    isMulti={false}
                                    menuPortalTarget={document.body} menuPosition={'fixed'}
                                    placeholder="Select Video"
                                  />
                                )}
                                
                              </div>

                              {index>0 && (
                              <div style={{width:46, display:'flex', justifyContent:'center', alignItems:'center'}} >
                                <button type="button" onClick={() => removeOption(option.id,index,option.value)} className="btn btn-primary text-white ">
                                  <i className="ti ti-trash cursor-pointer widget-video-delete-btn"></i>
                                </button> 
                              </div>

                              ) }
                            </div>
                          ))} */}

                        <div>
                          {addVideoError ? (
                            <small className="text-danger">
                              {addVideoError}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <Button
                          buttonText="Select Videos"
                          className="btn white-bg-btn ml-5"
                          onClick={(e) => {
                            openSelectVideosPopup();
                          }}

                          // onClick={() =>
                          //   setAddonListingModal(true)
                          // }
                        />
                        {/* <button type="button" className="btn white-bg-btn">
                            awfawe
                          </button> */}
                      </div>
                    </div>
                  </div>

                  {/* {videosOptions?.length>=1 && (
                      <div className="d-flex justify-content-space-between mb-3">
                        <button type="button" disabled={options.length>=maxVideoLimit} onClick={() => addOptionRow()} className="btn btn-primary text-white" role="menuitem">
                        <i className="ti ti-plus"></i> Add More
                        </button>
                      </div>
                    )} */}

                  {/* {(widgetId>=1)? */}

                  <Slider />

                  {/* <div className="widget-videos-carousel mb-3">
                    <CarouselSlider>
                      {selectedOptions?.map((selectedOption, indexKey) => (
                        <>
                          <VideoProductCardBox
                            boxImgSrc={selectedOption?.image}
                            boxTitle={selectedOption?.name}
                            crossDeleteBtnFunction={removeOptionNewDesign}
                          />
                        </>
                      ))}
                    </CarouselSlider>
                  </div> */}

                  <div className="row mb-3">
                    <div className="col-12">
                      <div className={`form-check form-switch custom-switch `}>
                        <input
                          onChange={(e) => {
                            setActiveInactive(!activeInactive);
                          }}
                          className="form-check-input"
                          type="checkbox"
                          checked={activeInactive === true}
                        />
                        <label className="radio-active-label">Active</label>
                      </div>
                    </div>
                  </div>

                  {/* :""} */}

                  {/* {videosOptions?.length>=1 && (
                    <div className="d-flex justify-content-space-between mb-4">
                      <button onClick={createWidget} type="button" className="btn btn-primary text-white" disabled={(isButtonDisabled || saveWidgetLoader===true)} role="menuitem">
                          <div className="d-flex align-items-center">
                            {saveWidgetLoader ? (
                                    <div>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...
                                    </div>
                                ) : <div> <i className="ti ti-device-floppy"></i> {!widgetId?"Generate and Save":"Re-Generate and save code"}</div>}
                            </div>
                        </button>
                    </div>
                    )} */}

                  {/* <div className="row">
                      <div className="col-12">
                        <div className="mb-4">
                          <label htmlFor="videodesc">Status</label>
                            <div className="form-check">
                                  <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={activeInactive}
                                      id="flexCheckDefault"
                                      onChange={(e) => setActiveInactive(e.target.checked)}
                                  />
                                  <label className="form-check-label" htmlFor="flexCheckDefault">
                                      Active
                                  </label>
                              </div>

                        </div>
                      </div>
                    </div> */}

                  <div className="row">
                    {widgetCode && (
                      <div className="col-12 mb-3">
                        <div className="mb-2">
                          <label>
                            <b>Widget ID:</b>
                          </label>
                          <div className="p-r">
                            <input
                              type="text"
                              className="form-control form-control-copy-link"
                              disabled
                              value={widgetCode}
                            />
                            <CopyToClipboard
                              url={widgetCode}
                              showText={false}
                              title="Copy Widget ID"
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-12 mb-3">
                      <div className="p-r">
                        <textarea
                          ref={copyScriptRef}
                          rows={11}
                          disabled={true}
                          defaultValue={widgetCodeScript}
                          className="form-control p-7"
                        ></textarea>

                        {/* <textarea disabled ref={copyScriptRef} className="form-control p-7" name="" id="" cols="20" rows="3" placeholder="150, Ring Road" value={videoIframe} /> */}
                        <div
                          title="Copy to clip board"
                          disabled={!widgetCode}
                          className={
                            "fs-2 p-a copy-url-field-icon" +
                            (!widgetCode ? " pointer-events-none" : "")
                          }
                          onClick={copyScriptTag}
                        >

                          {copyButtonIcon === copyCodeIcon && (
                              <img style={{marginTop:"5px"}} src={copyIcon} />
                          )}
                          
                          {copyButtonIcon !== copyCodeIcon && (
                              <i className={'fs-7 ' + copyButtonIcon}></i>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="vs-form-footer save-widget-btn-area">
                        <Button
                            className="btn btn-primary text-white"
                            type="button"
                            onClick={createWidget}
                            buttonText="Generate Code"
                            fullButton={false}
                            loading={saveWidgetLoader}
                            disabled={isButtonDisabled || saveWidgetLoader}
                        />
                      </div>
                    
                      {/* <div className="d-flex justify-content-end">
                        <Link className="btn btn-primary text-white mr-10" to="/videos/integration-on-web"><i className="ti ti-arrow-narrow-left"></i> Go to Listing</Link>
                      </div> */}
                  </div>
                </section>
              </div>
            </form>
          </div>

        <Modal isOpen={selectVideoModal} style={modalStyles} closable={false}>
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header d-flex align-items-center">
                <h4 class="modal-title" id="myLargeModalLabel"></h4>
                {/* <button
                        type="button"
                        onClick={handleCloseModal}
                        class="btn-close mb-4"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button> */}
              </div>
              <div class="modal-body">
                <div className="d-flex justify-content-center">
                  <div className="card select-videos-widget-area">
                    <div className="px-4 py-3 d-flex justify-content-between align-items-center">
                      <h5 className="card-title fw-semibold mb-0">
                        Select Videos
                      </h5>
                      <button
                        type="button"
                        onClick={(e) => {
                          setSelectVideoModal(false);
                        }}
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>

                    {/* <CreditCardForm /> */}

                    <div className="card-body p-4">
                      <SelectProductSelector
                        contentClass={`d-flex flex-wrap gap-3 ${isMobile?`justify-content-center`:`justify-content-start`}`}
                        selectionLimit={maxVideoLimit}
                        selectedOptions={selectedOptions}
                        onChange={handleOptionChange}
                        options={videodata}
                        selectLabel={``}
                        showFooter={true}
                        cancelPopupCallBack={cancelPopup}
                        selectPopupCallBack={selectPopup}
                        listingStyles={{ overflow: "auto", height: "40vh" }}
                        // errorMessage={errorMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer"></div>
            </div>
          </div>
        </Modal>

        </div>
        </div>
        </div>


        {/* </div> */}
      </Page>
    </div>
  );
}
export default AddVideoWidget;
