import React from "react";
import CopyToClipboard from "./CopyToClipboard";

const Textarea = ({ id, label, placeholder, value, onChange, disabled, errorMessage, copyToClipboard, ...props }) => {
    return (
        <div className="form-group">
            <label htmlFor={id} className="form-label">{label}</label>
            <div className="position-relative">
                <textarea
                    className={`form-control ${errorMessage ? 'is-invalid' : ''}`}
                    placeholder={placeholder}
                    disabled={disabled}
                    id={id}
                    autoCapitalize="none"
                    value={value}
                    onChange={onChange}
                    rows="6"
                    {...props}
                ></textarea>
                {copyToClipboard && <CopyToClipboard url={value} title="Copy" />}
            </div>
            {errorMessage && <div><span className="text-danger fs-2 lh-1">{errorMessage}</span></div>}
        </div>
    );
}

export default Textarea;