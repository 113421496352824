import React, { useEffect, useState } from "react";

const Tabs = ({ tabs, onChange, selectedTab }) => {
  const [activeTabKey, setActiveTabKey] = useState(tabs[0].key);

  useEffect(() => {
    if (selectedTab) {
      setActiveTabKey(selectedTab)
    }

  }, [selectedTab])

  return (
    <div className="vs-tabs">
      <ul className="nav nav-pills" role="tablist">
        {tabs.map((t) => (
          <li
            className={`nav-item ${activeTabKey === t.key ? "active" : ""} ${t.disabled ? "disabled" : ""}`}
            role="presentation"
            key={`nav-${t.key}`}
            onClick={() => {

              if (t.disabled) return

              onChange?.(t);
              setActiveTabKey(t.key);
            }}
          >
            <span className="nav-link">
              <span className="fw-semibold">{t.label}</span>
            </span>
          </li>
        ))}
      </ul>
      <div className="tab-content">
        {tabs.map((t) => (
          <div
            className={`tab-pane ${activeTabKey === t.key ? "active show" : ""
              }`}
            id={`#navpill-${t.key}`}
            role="tabpanel"
            key={`tab-${t.key}`}
          >
            {t.component}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
