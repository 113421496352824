import SpinnerImage from "../../../assets/spinner.png";

const ScreenLoading = () => {
    return (
        <div className="preloader">
            <img src={SpinnerImage} alt="loader" className="lds-ripple img-fluid" />
        </div>
    );
}

export default ScreenLoading;