const trans = {
    en: {
        Brand: {
            add_product: "Add Product"
        },
        Influencer: {
            add_product: "Add Offering"
        },
        Individual: {
            add_product: "Add Offering"
        },
        NonProfit: {
            add_product: "Add Campaign"
        }
    }
};

export default trans;
