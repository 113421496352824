import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { isMobile } from "../../../helpers/helper";
import { VideoSteps } from "../Video/AddVideo";
import SelectProductSelector from "../../../Product/SelectProduct";
import Loading from "../../Loadings/Loading";
import Button from "../../../Form/Button";

const PRODUCT_LIMIT = 1;

const SelectProduct = () => {
    const history = useHistory();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [errorMessage, setErrorMessage] = useState(false);

    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(
        isMobileSize ? "mini-sidebar" : "full"
    );

    useEffect(() => {
        fetchData();
    }, []);

    const moveToLeadCheckout = () => {
        if (selectedOptions.length === 0)
            return setErrorMessage("Please select products");

        history.push({
            pathname: "/lead-checkout",
            state: {
                selectedProducts: selectedOptions,
                addVideoData: history.location.state.addVideoData,
                addStreamData: history.location.state.addStreamData,
                addFacebookData: history.location.state.addFacebookData,
            },
        });
    };

    const fetchData = () => {
        var token = localStorage.getItem("authSession");
        token = JSON.parse(token);
        setLoading(true);

        fetch(process.env.REACT_APP_API_URL + "/user-products", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token.accessToken,
            },
            body: JSON.stringify({ id: token.idToken }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.data) {
                    const results = [];
                    data.data.map((dataOptions) =>
                        results.push({
                            ...dataOptions,
                            value: dataOptions.id,
                            label: dataOptions.name,
                            shopify_product_id: dataOptions?.shopify_product_id,
                            shopify_variant_id: dataOptions?.shopify_variant_id,
                            woocommerce_product_id: dataOptions?.woocommerce_product_id,
                            has_shipping: dataOptions?.has_shipping,
                        })
                    );

                    setData(results);
                }
            })
            .finally(() => setLoading(false));
    };

    const handleOptionChange = (e) => {
        if (e.length <= PRODUCT_LIMIT) {
            setSelectedOptions(e);
        }
    };

    const isButtonDisabled = selectedOptions.length === 0;

    const onNavBarClick = () => {
        setSidebarType((prevSidebarType) =>
            prevSidebarType === "full" ? "mini-sidebar" : "full"
        );
    };

    const backToAddProductStep = () => {
        return history.push({
            pathname: "/add-product",
            state: history.location.state,
        });
    };

    return (
        <div
            className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
            data-sidebartype={sidebarType}
            id="main-wrapper"
            data-theme="blue_theme"
            data-layout="vertical"
            data-sidebar-position="fixed"
            data-header-position="fixed"
        >
            <Sidebar onNavBarClick={onNavBarClick} />

            <Page title="Add Product" onNavBarClick={onNavBarClick} formWidth={true}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                <h5 className="fw-bolder">Create Your First Shoppable Video</h5>
                                <VideoSteps onBoarding step="3" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body">
                                {/* <VideoSteps step="2" /> */}
                                {loading === true && <Loading />}
                                <div className="w-500 mb-4">
                                    {loading === false && (
                                        <SelectProductSelector
                                            selectionLimit={PRODUCT_LIMIT}
                                            selectedOptions={selectedOptions}
                                            onChange={handleOptionChange}
                                            options={data}
                                            errorMessage={errorMessage}
                                        />
                                    )}
                                </div>
                                {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                    <Button
                                        type="button"
                                        onClick={moveToLeadCheckout}
                                        buttonText="Next"
                                        fullButton={false}
                                        loading={false}
                                        disabled={isButtonDisabled}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            {/* sticky footer */}
        <div className="sticky-footer ">
          <div className="footer-container">
            <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
              <Button
                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                type="button"
                buttonText="Back"
                onClick={backToAddProductStep}
              />
              <Button
                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                type="button"
                onClick={moveToLeadCheckout}
                buttonText="Next"
                fullButton={false}
                loading={false}
                disabled={isButtonDisabled}
              />
            </div>
          </div>
        </div>
      {/* sticky footer */}
        </div>
    );
};
export default SelectProduct;
