import React, { useState, forwardRef, useImperativeHandle } from "react";
import styles from "./ProductVariants.module.css";
import { useEffect } from "react";
import Input from "../Form/Input";
import Button from "../Form/Button";
import EditImg from "./../../assets/edit.svg"
import Loading from "../Pages/Loadings/Loading";

const DEFAULT_OPTION = {
    name: "",
    options: [""],
    open: true
}

const mergeOptions = (categories, currentIndex = 0, currentOptions = []) => {
    if (currentIndex === categories.length) {
        return [currentOptions];
    }

    const currentCategory = categories[currentIndex];

    const mergedOptions = [];
    for (const option of currentCategory.options) {
        const updatedOptions = [...currentOptions, option];
        mergedOptions.push(...mergeOptions(categories, currentIndex + 1, updatedOptions));
    }

    return mergedOptions;
};

const ProductVariants = forwardRef((props, ref) => {
    const [hasVariant, setHasVariant] = useState(props.hasVariants);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [rowsLoading, setRowsLoading] = useState(false);
    const [showErrors, setShowErrors] = useState(false);
    const [options, setOptions] = useState([]);

    const toggleOption = (index) => {
        const updatedItems = options.map((e, i) => ({
            ...e,
            open: i === index
        }));
        setOptions(updatedItems);
    }

    const updateOption = (index, keyOrObject, value) => {
        const updatedItems = options.map((e, i) => {
            if (i === index) {
                if (typeof keyOrObject === 'object') {
                    return {
                        ...e,
                        ...keyOrObject
                    };
                } else {
                    return {
                        ...e,
                        [keyOrObject]: value
                    };
                }
            }
            return e;
        });
        setOptions(updatedItems);
    }

    const addOption = () => {
        if (options.length >= 5) return

        setOptions((prevOptions) => {
            return [
                ...prevOptions.map(e => ({
                    ...e,
                    open: false
                })),
                DEFAULT_OPTION
            ];
        });
    };

    const resetVariations = (updatedOptions = false) => {

        const opts = updatedOptions ? updatedOptions : options

        setRowsLoading(true);
        setTimeout(() => {
            setColumns(opts.map(o => o.name))
            const result = mergeOptions(opts);
            const updatedRows = result.map(r => ({
                sku: "",
                stock: "",
                price: "",
                values: r
            }))
            setRows(updatedRows);
            setRowsLoading(false);
        }, 1500);
    }

    const removeOption = (index) => {
        if (options.length === 1) return

        const updatedOptions = options.filter((_, i) => i !== index)
        setOptions(updatedOptions);

        resetVariations(updatedOptions);
    };

    const doneOption = (index) => {

        const option = options[index];
        const updateObj = {};

        if (!option.name) {
            updateObj.nameError = "Please write option name";
        } else {
            updateObj.nameError = "";
        }

        const valueErrors = option.options.map(e => !e ? "Please write option value" : "")
        const valueErrorsCount = valueErrors.filter(value => value.trim() !== '').length;
        updateOption(index, {
            ...updateObj,
            optionsErrors: valueErrors
        })

        if (valueErrorsCount !== 0 || updateObj.nameError) return

        setOptions((prevOptions) => {
            return [
                ...prevOptions.map(e => ({
                    ...e,
                    open: false
                }))
            ];
        });

        resetVariations();
    }

    const addOptionValue = (index) => {

        if (options[index].options.length >= 5) return

        const updatedItems = options.map((e, i) => ({
            ...e,
            options: i === index ? [...e.options, ""] : e["options"]
        }));
        setOptions(updatedItems);
    }

    const removeOptionValue = (index, removeIndex) => {
        if (options[index].options.length === 1) return

        const updatedItems = options.map((e, i) => ({
            ...e,
            options: i === index ? e.options.filter((_, optIndex) => optIndex !== removeIndex) : e["options"]
        }));
        setOptions(updatedItems);
    }

    const updateOptionValue = (index, optionValueIndex, value) => {
        const updatedItems = options.map((e, i) => ({
            ...e,
            options: i === index ? e.options.map((opt, optIndex) => optIndex === optionValueIndex ? value : opt) : e["options"]
        }));
        setOptions(updatedItems);
    }

    const getVariants = () => {
        const variants = columns.map((c, cIndex) => ({
            variation_title: c,
            product_sku: rows.map((r) => ({
                sku: r.sku,
                price: r.price,
                quantity: r.stock,
                product_variation_values: [{ values: r.values[cIndex] }]
            }))
        }))

        return variants;
    }

    const validateAndGetVariants = () => {
        const rowsErrors = rows.filter(r => !r.sku || !r.stock || !r.price || r.values.some(rr => !rr)).length
        const duplicateSKUErrors = rows.filter(r => rows.filter(rr => rr.sku === r.sku).length > 1).length
        const totalErrors = rowsErrors + duplicateSKUErrors

        if (totalErrors > 0) {
            setShowErrors(true);
            return [true, []];
        } else {
            setShowErrors(false)
            return [false, getVariants()];
        }
    }

    useImperativeHandle(ref, () => ({
        validateAndGetVariants,
    }));

    const onChangeRowValue = (key, value, index) => {
        setRows(prevRows => prevRows.map((r, i) => i === index ? { ...r, [key]: value } : r));
    }

    const onPressRemoveRow = (index) => {
        setRows(prevRows => [...prevRows.slice(0, index), ...prevRows.slice(index + 1)])
    }

    useEffect(() => {
        props.setHasVariant(hasVariant)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasVariant])

    useEffect(() => {
        setHasVariant(props.hasVariants)
    }, [props.hasVariants])

    useEffect(() => {
        var prevOptions = [];

        if (props.variants && props.variants.columns.length > 0) {
            setColumns(props.variants.columns)

            prevOptions = props.variants.columns.map((c, cIndex) => ({
                ...DEFAULT_OPTION,
                name: c,
            }))
        }

        if (props.variants && props.variants.rows.length > 0) {
            setRows(props.variants.rows)

            prevOptions = prevOptions.map((p, pIndex) => ({
                ...p,
                open: false,
                options: [...new Set(props.variants.rows.map(r => r.values[pIndex]))]
            }))

            setOptions(prevOptions)
        }

    }, [props.variants])

    return (
        <div className={styles.variant_container}>
            <div className={`form-check form-switch custom-switch ${styles.checkbox_container}`}>
                <input className="form-check-input" style={{ width: 50 }} type="checkbox" id="product_has_variants" checked={hasVariant} onChange={() => setHasVariant(e => !e)} />
                <label className="form-check-label fs-4 text-black fw-semibold pl-2" htmlFor="product_has_variants">Product has Variants</label><br />
            </div>
            <p className="form-text text-muted mb-2 fs-3">Toggling on allows product variants to be added.</p>

            {hasVariant && (
                <>
                    {
                        options.map((option, oIndex) => {
                            return option.open ? (
                                <div className={`${styles.variant_opt_container} mb-3`} key={`option-${oIndex}`}>
                                    <Input
                                        id="title"
                                        type="text"
                                        label="Option Name"
                                        value={option.name}
                                        onChange={(e) => updateOption(oIndex, {
                                            name: e.target.value,
                                            nameError: ""
                                        })}
                                        errorMessage={option.nameError}
                                    />

                                    <div className="form-group position-relative mt-3">
                                        <label className="form-label">Option Value</label>
                                        {
                                            option.options.map((e, eIndex) => {
                                                const hasError = (option.optionsErrors && option.optionsErrors[eIndex]) ? option.optionsErrors[eIndex] : ""
                                                return (
                                                    <div className="d-flex align-items-center mb-2" key={`option-value-${eIndex}`}>
                                                        <input
                                                            className={`form-control ${hasError ? "is-invalid" : ""}`}
                                                            type="text"
                                                            autoCapitalize="none"
                                                            value={e}
                                                            onChange={(x) => updateOptionValue(oIndex, eIndex, x.target.value)}
                                                        />
                                                        {option.options.length !== 1 && (
                                                            <div className="px-2" role="button" onClick={() => removeOptionValue(oIndex, eIndex)}>
                                                                <i className="ti ti-trash fs-6"></i>
                                                            </div>
                                                        )}
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>

                                    {option.options.length !== 5 && (<div>
                                        <span className="text-pink" role="button" onClick={() => addOptionValue(oIndex)}>
                                            <span className="fs-6 mr-5">+</span>
                                            <span>Add Value</span>
                                        </span>
                                    </div>)}

                                    <div className="d-flex justify-content-start align-items-center gap-2 mt-4">
                                        <Button
                                            className="btn btn-primary fs-3"
                                            type="button"
                                            buttonText="Done"
                                            onClick={() => doneOption(oIndex)}
                                        />
                                        {options.length !== 1 && (
                                            <Button
                                                className="btn btn-outline-primary fs-3"
                                                type="button"
                                                buttonText="Remove"
                                                onClick={() => removeOption(oIndex)}
                                            />
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div role="button" className={`${styles.variant_opt_container_closed} mb-3 d-flex justify-content-between`} key={`option-${oIndex}`} onClick={() => toggleOption(oIndex)}>
                                    <span className="text-black fs-4">{option.name}</span>
                                    <img src={EditImg} alt="Size" width="16" height="16" />
                                </div>
                            )
                        })
                    }

                    {options.length !== 5 && (
                        <div className="d-flex justify-content-start align-items-center gap-2 mt-4 mb-4">
                            <Button
                                className="btn btn-primary fs-3"
                                type="button"
                                buttonText="Add Option"
                                onClick={addOption}
                            />
                        </div>
                    )}

                    <div className={styles.variant_table}>
                        {(rowsLoading) && <Loading />}

                        {rows.length > 0 && (
                            <>
                                <div className={styles.row}>
                                    <div className={styles.column}>
                                        <div className={`${styles.cell} ${styles.header_cell}`}>
                                            <span className="user-select-none fs-3 fw-semibold text-black">Variant</span>
                                        </div>
                                    </div>
                                    <div className={styles.column}>
                                        <div className={`${styles.cell} ${styles.header_cell}`}>
                                            <span className="user-select-none fs-3 fw-semibold text-black">SKU</span>
                                        </div>
                                    </div>
                                    <div className={styles.column}>
                                        <div className={`${styles.cell} ${styles.header_cell}`}>
                                            <span className="user-select-none fs-3 fw-semibold text-black">Price</span>
                                        </div>
                                    </div>
                                    <div className={styles.column}>
                                        <div className={`${styles.cell} ${styles.header_cell}`}>
                                            <span className="user-select-none fs-3 fw-semibold text-black">Qty</span>
                                        </div>
                                    </div>
                                    <div className={`${styles.column} ${styles.column_action}`}></div>
                                </div>

                                {
                                    rows.map((r, i) => {
                                        const isSKUDuplicate = rows.filter(rr => rr.sku === r.sku && rr.sku).length > 1;
                                        const isStockUnlimited = r.stock === -1

                                        return (
                                            <div key={`row-${i}`} className={styles.row}>
                                                <div className={`${styles.column} ${styles.column_variant}`}>
                                                    <div className={`${styles.cell}`}>
                                                        <span className="text-black">{r.values.join("/ ")}</span>
                                                    </div>
                                                </div>
                                                <div className={styles.column}>
                                                    <div className={`${styles.cell}`}>
                                                        <input
                                                            type="text"
                                                            className={`form-control ${((showErrors && !r.sku) || isSKUDuplicate) && "is-invalid"} ${styles.form_control}`}
                                                            value={r.sku}
                                                            onChange={e => onChangeRowValue("sku", e.target.value, i)}
                                                            placeholder="SKU"
                                                        />
                                                        {isSKUDuplicate && <small class={`text-danger ${styles.text_danger}`}>Duplicate SKU</small>}
                                                    </div>
                                                </div>
                                                <div className={styles.column}>
                                                    <div className={`${styles.cell}`}>
                                                        <input
                                                            type="number"
                                                            className={`form-control ${(showErrors && !r.price) && "is-invalid"} ${styles.form_control}`}
                                                            value={r.price}
                                                            onChange={e => onChangeRowValue("price", e.target.value, i)}
                                                            placeholder="29.99"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={styles.column}>
                                                    <div className={`${styles.cell} ${styles.cell_stock}`}>
                                                        <input
                                                            type={isStockUnlimited ? "text" : "number"}
                                                            className={`form-control ${(showErrors && !r.stock) && "is-invalid"} ${styles.form_control}`}
                                                            value={isStockUnlimited ? "Unlimited" : r.stock}
                                                            disabled={isStockUnlimited}
                                                            onChange={e => onChangeRowValue("stock", e.target.value, i)}
                                                            placeholder="100"
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`${styles.column} ${styles.column_action}`}>
                                                    {rows.length > 1 ? (
                                                        <div className={`${styles.cell} ${styles.cell_action}`} onClick={() => onPressRemoveRow(i)}>
                                                            <i className="ti ti-trash fs-6"></i>
                                                        </div>
                                                    ) : (
                                                        <div className={`${styles.cell} ${styles.cell_action}`}></div>
                                                    )}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    )
})

export default ProductVariants;