import React, { useEffect, useState } from 'react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from 'validator';
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from '../../helpers/helper';
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import Input from "./../../Form/Input";
import ToggleSwitchRow from "./../../Form/ToggleSwitchRow";
import DropDown from "./../../Form/DropDown";
import Button from "./../../Form/Button";
import TrashPinkIcon from "./../../../assets/icons/trash-pink.svg"
import PageHeading from "./../../Form/PageHeading";

import AddLeadForm from "./AddLeadForm";

const AddLeadFormMain = () => {
    const history = useHistory();
    const [name, setName] = useState('');
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [urlErrors, setUrlErrors] = useState([]);
    const [urlproblemError, setProblemErrorErrors] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [optionErrors, setOptionErrors] = useState([]);
    const [leadName, setLeadName] = useState();
    const [leadNameError, setLeadNameError] = useState();
    const [loaderSubmit, setloaderSubmit] = useState(false);

    const [fields, setFields] = useState([]);
    const [leadFormId, setLeadFormId] = useState('');



    useEffect(() => {
        fetchLeadData();

    }, []);

    const resetState = () => {
        setErrors('');
        setSuccess('');
    };

    const fetchLeadData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/find-lead-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }),
        })
            .then((response) => response.json())
            .then((data) => {

                // setFields(data.data)

            })
            .catch((error) => {
                // console.error('Error fetching data:', error);
            });
    };

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }



    const addFieldOption = (index) => {

        const updatedFields = [...fields];
        var newValue = { "name": "", "error": "" };
        updatedFields[index]["child"].push(newValue);




        setFields(updatedFields);

        setProblemErrorErrors(true)

    };

    const addField = () => {
        setFields([...fields, { name: '', type: 'Text', isChecked: false, fieldvalue: null, child: [] }]);

        setErrors('')
        setProblemErrorErrors(true)

    };

    const removeField = (index) => {

        const updatedFields = [...fields];

        if (updatedFields.length == 1) {

            setProblemErrorErrors(true)
            setErrors("Atlest one Field is required")

        } else {
            setErrors('')
            setProblemErrorErrors(false)
            updatedFields.splice(index, 1);

        }

        setFields(updatedFields);
    };




    const handleFieldChange = (index, event) => {



        const updatedFields = [...fields];

      

        if (event.target.name === 'isChecked') {
            updatedFields[index].isChecked = event.target.checked;
        } else if (event.target.id == "input-type") {
            updatedFields[index]["type"] = event.target.value;
            if (event.target.value == "Dropdown") {
                addFieldOption(index);
            }
        }
        else if (event.target.id == "input-name") {

            if (event.target.value === null || event.target.value === undefined || event.target.value === "") {
                urlErrors[index] = 'Please enter value';
                setProblemErrorErrors(true)

            } else {
                urlErrors[index] = null;
                setProblemErrorErrors(false)
            }
            updatedFields[index]["name"] = event.target.value;


        }

        setFields(updatedFields);

    };

    const handleFieldOptionChange = (index1, index, event) => {

        const updatedFields = [...fields];

        if (event.target.value === null || event.target.value === undefined || event.target.value === "") {

            // optionErrors[index] = 'Please enter value';

            updatedFields[index]["child"][index1]["error"] = 'Please enter value';

            setProblemErrorErrors(true)

        } else {

            updatedFields[index]["child"][index1]["error"] = null;

            setProblemErrorErrors(false)

        }

        updatedFields[index]["child"][index1]["name"] = event.target.value;
        setFields(updatedFields);



    };

    const removeOptionField = (index1, index) => {

        const updatedFields = [...fields];
        updatedFields[index]["child"].splice(index1, 1);
        setFields(updatedFields);

    };






    const isButtonDisabled = urlproblemError || !leadName || !(fields.length !== 0);

    const handleSubmit = (e) => {
        setShowPopup(true);
        // e.preventDefault()
        // submitForm();
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };


    const handleDelete = () => {
        submitForm();
        setShowPopup(false);
    };

    const submitForm = () => {

        setloaderSubmit(true)

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-lead-form', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "title": leadName,
                    "fields": fields,
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json?.code?.toString() === "200") {

                            var token = localStorage.getItem("authSession")
                            token = JSON.parse(token);

                            const userLog = new ActivityLog();
                            var user_id = token.idToken;
                            var is_admin = 0;
                            var event_id = 27;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            setSuccess("lead form created successfully")

                            setTimeout(() => {
                                resetState()
                                history.push("/settings/lead-form-report")
                            }, 2000);

                            setloaderSubmit(false)

                        } else {

                            setErrors("Something went wrong")

                            setloaderSubmit(false)

                        }
                    });
                }
            }).
                catch((error) => {

                    console.log('error>>>', error)
                });
        });
    };



    const handleNameChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setLeadName(value);
        validateField(value, rule, setLeadNameError);

    };

    function proceedToProductPage(leadFormId) {


    

   
    };




    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} data-sidebartype={sidebarType} id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title="Add Lead Form" formWidth={true}>
                <PageHeading text="Lead Form" />
                {errors && (
                    <FormToast
                        type={FORM_TOAST_TYPES.DANGER}
                        message={errors}
                    />
                )}


                {success && (
                    <FormToast
                        type={FORM_TOAST_TYPES.SUCCESS}
                        message={success}
                    />
                )}


            <div className='mt-4'></div>
                <div className="row add-form pb-50">
                    <section>
                        <div className="card">
                            <div className="card-body wizard-content">
                                <AddLeadForm errors={errors} success={success} setErrors={setErrors} setSuccess={setSuccess} leadFormId={leadFormId} setLeadFormId={setLeadFormId} proceedToProductPage={proceedToProductPage} />
                            </div>
                        </div>
                    </section>
                </div>
            </Page>
        </div>
    );
}
export default AddLeadFormMain;