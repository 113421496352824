import React, { useEffect, useState } from "react";
// import { useMutation, useQuery } from "@apollo/react-hooks";
import useAuthState from "../../../hooks/useAuthState";
// import useTutorialsState from "../../../hooks/useTutorialsState";
import useMaskedInputState from "../../../hooks/useMaskedInputState";
import useInputState from "../../../hooks/useInputState";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router";

import Auth0 from "../../../helpers/Auth0";
import { isEmail } from "validator";
import { validPassword } from "../../../helpers/password";

// import { UpdateAccountInfo } from "./mutations";
// import { GetProfileInfo } from "./Queries";
// import useTagAccount from "../../../hooks/useTagAccount";

import { createAvatar } from '@dicebear/core';
import { pixelArtNeutral,bigEarsNeutral  } from '@dicebear/collection';

import UploadHelper from "../../../../component/helpers/upload";
import * as helper from '../../../helpers/helper';


const useProfileInfo = () => {
  const [fullName, setFullName] = useMaskedInputState("",{maxLength:255});
  const [phoneNumber, setPhoneNumber] = useMaskedInputState("",{maxLength:255});
  const [countryId, setCountryId] = useState(false);
  const [countryCode, setCountryCode] = useState(false);
  const [countryDiallingCode, setCountryDiallingCode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useMaskedInputState("",{maxLength:255});
  const [password, setPassword] = useMaskedInputState("",{maxLength:255});
  const [accountType, setAccountType] = useMaskedInputState("Brand",{maxLength:255});

  const [mutationLoading, setLoading] = useState(false);
  // const { getHasCompletedTutorial, completeTutorial } = useTutorialsState();
  const [error, setError] = useState();
  const [isFormValid, setIsFormValid] = useState(true);
  const [PhoneValid, setIsPhoneValid] = useState(false);
  const { login } = useAuthState();
  const history = useHistory();
  const location = useLocation();

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); // Toggle the checkbox state
  };

  // const { tagAccount } = useTagAccount();
  // const [updateAccountInfo] = useMutation(UpdateAccountInfo, {
  //   refetchQueries: [{ query: GetProfileInfo }]
  // });

  function telephoneCheck(str) {
    const isphone = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g.test(str);
    return isphone && str.length>=4;
  }

  const pocNameValidation = !!fullName;
  const validForm =
    pocNameValidation &&
    phoneNumber &&
    // !parsePhoneNumber(phoneNumber).isValid()
    telephoneCheck(phoneNumber)
    // !isEmail(phoneNumber) &&
    !isEmail(phoneNumber) &&
    email &&
    isEmail(email) &&
    password &&
    validPassword(password);

  const createAccount = async (e) => {
    e.preventDefault();
   
    setIsFormValid(validForm);
    const resReg = await registerUser();
    
    if(!resReg){
      setLoading(false) ;
      return;
    }

    if(resReg.code == 200 || resReg.code == 201){
        history.push("/OTP-Verification");
        // history.push("/login");
    } else if(resReg.code == "400"){
      setLoading(false) ;

      if(resReg?.is_already_exists==true){
      setError({
        title: "We couldn't create your account at this time.",
        // message:'Please verify your otp'
        message:resReg?.message
      });
      setTimeout(function (){
        // history.push("/OTP-Verification");

      },3000)
      } else {
        setError({
          title: "We couldn't create your account at this time.",
          message:
          resReg.message
        });

      }
        
      
    }else{

      setError({
        title: "We couldn't create your account at this time.",
        message:
        resReg.message
      });


      // history.push("/OTP-Verification");
    }
    
    // const hasCompletedOnboardingStep = getHasCompletedTutorial(
    //   "Brand Dashboard Personal Info"
    // );

    // if (!hasCompletedOnboardingStep) {
    //   await completeTutorial({
    //     variables: { name: "Brand Dashboard Personal Info", version: 1 }
    //   });
    // }
  };





  

  const registerUser = async () => {
    if (validForm) {
      setLoading(true);

      const avatar = createAvatar(bigEarsNeutral, {
        "seed": fullName,
        // "seed": helper.randomNumberInRange(100000,999999),
        "scale": 120,
        "backgroundType": [
          "gradientLinear",
          "solid"
        ]
      });
      
      const avatarSVG = avatar.toString();

      try {
        
        const resultOTP = await fetch(process.env.REACT_APP_API_URL+'/create-new-user-otp', {
          method: 'POST', 
          mode: 'cors', 
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            "name": fullName,
            "email": email,
            "phone": phoneNumber,
            "password": password,
            "type": accountType,
            "source": "Generic",
            "avatar": avatarSVG,
            "is_demo": isChecked,
            "country_id": countryId,
            "country_code": countryCode,
            "country_dialling_code": countryDiallingCode,
            "invitation_code":localStorage.getItem('invitation_code'),
            // "avatarPath": url
          })
        }).then((response) => response.json());
        if(resultOTP){

          
        if (resultOTP.code == 200 || resultOTP.code == 201) {
          setLoading(false);
          localStorage.setItem('otp-email', email);
          localStorage.setItem('otp-password', password);
          localStorage.removeItem('invitation_code');

          history.replace("/login");


          return resultOTP;

        } else {
          setError({
            title: "Unable to register",
            message:resultOTP.message
          });
        }
          

        if (resultOTP.code == 400) {
          setLoading(false);
        
          
          // return resultOTP;
          
        }  else {
          
        }

      }else{
        return 0;
      }
        localStorage.setItem('otp-email', email);
        localStorage.setItem('otp-password', password);

        

        //
        // await login(email, password, true);
        
        


        // await tagAccount("Brand");

        setLoading(false);

        // history.replace("/login");


       return resultOTP;
      } catch (err) {
        setLoading(false) ;
        
          setError({
            title: "We couldn't create your account at this time.",
            message:
              "We apologize for the inconvenience. Please try again later."
          });
          // throw err;
        
      }
    }
  };



  return {
    fullName,
    phoneNumber,
    email,
    password,
    accountType,
    error,
    isFormValid: isFormValid,
    // disabled: fullName === "" || phoneNumber === "" || email === "" || password === "" || accountType === "",
    disabled: fullName === "" || email === "" || password === "" || accountType === "",
    mutationLoading,
    setFullName,
    setPhoneNumber,
    setEmail,
    setPassword,
    setAccountType,
    showPassword,
    setShowPassword,
    createAccount,
    invalidPassword: !!password && !validPassword(password),
    // invalidPhoneNumber: !!phoneNumber && isEmail(phoneNumber),
    // invalidPhoneNumber: parsePhoneNumber(phoneNumber).isValid(),
    invalidPhoneNumber: !telephoneCheck(phoneNumber),
    invalidEmail: !!email && !isEmail(email),
    isChecked,
    handleCheckboxChange,
    setCountryId,
    setCountryCode,
    setCountryDiallingCode
  };
};
 
const getError = () => {
  return {
    message: (
      <span>
        Something went wrong! Try again or contact{" "}
        {
          <a
            href="https://help.videosquirrel.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            support
          </a>
        }{" "}
        if the problem persists.
      </span>
    )
  };
};

export default useProfileInfo;
