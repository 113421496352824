import React, { useEffect, useState } from 'react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import validator from 'validator';
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import validateField from  "../../helpers/AllValidtions";
import { isMobile,showUserTypeBaseMessage } from '../../helpers/helper';


function AddLead() {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [videodata, setVideotdata] = useState([]);
    const [loader, setLoader] = useState(false);
    const [product, setProduct] = useState('');
    const [video, setvideo] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [datetime, setDatetime] = useState('');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loaderSubmit, setloaderSubmit] = useState(false);
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');

    const [productError, setProductError] = useState('');
    const [videoError, setvideoError] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');


    const handleNameChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setName(value);
        validateField(value, rule, setNameError);

    };

     const handleEmailChange = (e) => {
        var value = e.target.value;
        var rule = "email";
        setEmail(value);
        validateField(value, rule, setEmailError);

        };

    const handlePhoneChange = (e) => {
        var value = e.target.value;
        var rule = "phone";
      
        setPhone(value);
        validateField(value, rule, setPhoneError);

        };


    const handleProductChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        // setProduct(value);
        // validateField(value, rule, setProductError);

        if(value==''){
            setProduct(value);
            validateField(value, rule, setProductError);
        }else{
            setProduct(value);
            validateField(value, rule, setProductError);

        } 

        };

        const handleVdeoChange = (e) => {
            var value = e.target.value;
            var rule = "required";
            e.preventDefault();
            if(value==''){
    
                setvideo(e.target.value)
                validateField(value, rule, setvideoError);
    
            }else{
    
                setvideo(e.target.value)
                fetchProductData(e.target.value);
                validateField(value, rule, setvideoError);
    
            }      
        };


    const handleSubmit = (e) => {
        e.preventDefault()
        submitForm(product, video, name, email, phone, datetime);

      

    };


    useEffect(() => {
        fetchVideosData();

    }, []);

    const fetchProductData = (id) => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/video-product-id', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "videoid": id }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                setData(data.data)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const fetchVideosData = () => {
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/user-videos', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken }), // Replace with your POST data
        })
            .then((response) => response.json())
            .then((data) => {
                setVideotdata(data.data)
                setLoader(true)

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const resetState = () => {
        setErrors('');
        setSuccess('');
    };





  

    const submitForm = (product, video, name, email, phone, datetime) => {


        const userLog = new ActivityLog();

        setloaderSubmit(true)
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-lead', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "product": product,
                    "video": video,
                    "name": name,
                    "email": email,
                    "phone": phone,
                    "datetime": datetime,
                    "source": "dashboard"
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json?.code?.toString() === "200") {

                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin =admin_login_id ? 1 : 0;
                            var event_id =5;
                            var account_id =token.idToken;
                            const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            setSuccess("Lead Added succesfully")

                            setTimeout(()=>{
                                resetState()
                                history.push("/orders")
                            }, 2000);


                            
                            setloaderSubmit(false)
                        } else {
                            history.push("/add-lead")
                            setloaderSubmit(false)
                        }
                    });
                }
            }).
                catch((error) => {
                    setloaderSubmit(false)
                    console.log('error>>>', error)
                });
        });
        return fetch_prods;
    };


    const onNavBarClick = () =>{
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
      } 

    const addLeadTagLine = showUserTypeBaseMessage({
        'Brand': `Entice Viewers and Drive Leads, Simplify the Shopping Journey`, 
        'default':``,
        'NonProfit':`Inspire Donors, Streamline Your Fundraising Journey`,
        'Influencer':`Entice Viewers and Drive Leads, Simplify the Shopping Journey`,
        'Individual':`Entice Viewers and Drive Leads, Simplify the Shopping Journey`,
    });


    const isButtonDisabled = !name.trim() || !email.trim() || !product.trim() || !video.trim() || !phone.trim() || productError || videoError || nameError || emailError || phoneError;
    

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} data-sidebartype={sidebarType} id="main-wrapper" data-theme="blue_theme" data-layout="vertical"  data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title="Add Video" formWidth={true}>
                <div className="container-fluid">
                    

                    <BreadCrump
                    title="Lead Management"
                    image={dashboardbreadcrumimage}
                    breadCrumpDetail={[
                        { name: "Dashboard", link: "/" },
                        { name: "Add Lead", link: "" }
                    ]}
                    tagLine={addLeadTagLine}

                    />

                    <section>
                        <div className="card">
                            <div className="card-body wizard-content">

                                {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
                                {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""}



                                <form className="validation-wizard wizard-circle mt-5 wizard clearfix">

                                <div className="content clearfix">


                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Select Video:<span className="danger">*</span></label>
                                                <select className="form-select col-12" id="example-month-input2" name="video" value={video} onChange={handleVdeoChange} >
                                                    <option value="">Select an option</option>
                                                    {setLoader ? videodata.map((option) => (
                                                        <option key={option.id} value={option.id}>
                                                            {option.name}
                                                        </option>
                                                    )) : ""}
                                                </select>
                                                {(videoError?<small className="text-danger">{videoError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">

                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Select Product:<span className="danger">*</span>
                                                </label>
                                                <select className="form-select col-12" id="example-month-input2" name="product" value={product} onChange={handleProductChange} >
                                                    <option value="">Select an option</option>
                                                    {setLoader ? data.map((option) => (
                                                        <option key={option.product_id} value={option.product_id}>
                                                            {option.product_name}
                                                        </option>
                                                    )) : ""}
                                                </select>
                                                {(productError?<small className="text-danger">{productError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Customer Name:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Customer Name'
                                                    className="form-control"
                                                    type="text"
                                                    defaultValue="Artisanal kale"
                                                    id="example-text-input"
                                                    name="name" value={name} onChange={handleNameChange}
                                                />
                                                   {(nameError?<small className="text-danger">{nameError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Customer Email:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Customer Email'
                                                    className="form-control"
                                                    type="email"
                                                    defaultValue="bootstrap@example.com"
                                                    id="example-email-input"
                                                    name="email" value={email} onChange={handleEmailChange}
                                                />
                                                {(emailError?<small className="text-danger">{emailError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3 row">
                                        <div class="col-12">
                                            <div class="mb-3">

                                                <label htmlFor="wfirstName2">Customer Phone:<span className="danger">*</span></label>
                                                <input
                                                    placeholder='Customer Phone'
                                                    className="form-control"
                                                    type="number"
                                                    defaultValue="1-(555)-555-5555"
                                                    id="example-tel-input"
                                                    min="1"
                                                    name="phone" value={phone} onChange={handlePhoneChange}
                                                />
                                                 {(phoneError?<small className="text-danger">{phoneError}</small>:"")}
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div class="actions clearfix">
                                        <ul role="menu" aria-label="Pagination">
                                            <li aria-hidden="false" aria-disabled="false">
                                            <button type="submit" onClick={handleSubmit} disabled={isButtonDisabled} class="mr-5 btn btn-primary text-white float-end">
                                               
                                                    {loaderSubmit ? (
                                                        <div>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                        </div>
                                                    ) : (
                                                        <div><i class="ti ti-device-floppy me-1 fs-4"></i>Submit</div>
                                                    )}
                                                
                                            </button>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </section>
                </div>

            </Page>
        </div>
    );
}
export default AddLead;