import React, { useState } from "react";
import { Page } from "../../layouts/Page";
import { LeadFormList } from "../../molecules/LeadFormList";
import useInputState from "../../hooks/useInputState";
import { useHistory } from "react-router-dom";
import { Sidebar } from "../../../component/molecules/Sidebar";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/product.png";
import BreadCrump from "../../Breadcrump/BreadCrump";
import * as helper from "../../helpers/helper";
import PageHeading from "./../../Form/PageHeading";

function MultiLeadFormReport() {
  const isMobileSize = helper.isMobile();
  const history = useHistory();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');


  const onNavBarClick = () =>{
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  } 

  const titleHeadingText = 'Lead Management';


  

  return (

   <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">


    <Sidebar  onNavBarClick = {onNavBarClick} />
    <Page title={"Your "+titleHeadingText} onNavBarClick = {onNavBarClick}>
       <PageHeading text="Lead Form List"/>
      <LeadFormList/>
    </Page>
    </div>
  );
}

export default MultiLeadFormReport;