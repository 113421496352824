import React, { useEffect, useState } from "react";
import styles from "./PhoneInput.module.css";
// import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input";

// import isPossiblePhoneNumber from "react-phone-number-input";
import "react-phone-number-input/style.css";
const googlePhoneLib = require("google-libphonenumber");

const PhoneInputField = ({
  id,
  label,
  onChange,
  disabled,
  valid,
  setValid,
  errorMessage,
}) => {
  const [countries, setCountries] = useState([]);
  const [phone, setPhone] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [reactSelectCountryFlag, setReactSelectCountryFlag] = useState("");
  const [value, setValue] = useState();

  const onMobileNumberChange = (value) => {
    setValue(value);
    const parseNumber = value?parsePhoneNumber(value):null;

    setValid(false);

    try {
        if(parseNumber?.nationalNumber && parseNumber?.country){
            const phoneUtil = googlePhoneLib.PhoneNumberUtil.getInstance();
            const parsedNumber = phoneUtil.parse(parseNumber?.nationalNumber, parseNumber?.country);
            const isPhoneValid = phoneUtil.isValidNumberForRegion(
                parsedNumber,
                parseNumber?.country
            );
            setValid(isPhoneValid);

            onChange({
                country: parseNumber?.country,
                phone: parseNumber?.nationalNumber,
                dialCode:parseNumber?.countryCallingCode
            })
        } 
    } catch(e){
        console.log('e-->',e);
    }

  };

  const fetchCountries = () => {
    fetch(process.env.REACT_APP_API_URL + "/countries-list", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        const results = response.data.rows;
        setCountries(results);
        setSelectedCountry(results[0]);
      })
      .catch(() => setCountries([]));
  };

  useEffect(() => {
    // fetchCountries();
  }, []);

  const setPhoneFormat = (phoneNumberString) => {
    phoneNumberString = phoneNumberString?.replace(/[^0-9+\-]/g, "");
    const countryCode = selectedCountry?.code;
    const diallingCode = selectedCountry?.dialling_code;

    if (typeof phoneNumberString !== "undefined") {
    //   try {
        const phoneUtil = googlePhoneLib.PhoneNumberUtil.getInstance();
        const PNF = googlePhoneLib.PhoneNumberFormat;
        const parsedNumber = phoneUtil.parse(phoneNumberString, countryCode);
        const isPhoneValid = phoneUtil.isValidNumberForRegion(
          parsedNumber,
          countryCode
        );
        const formattedNumber = phoneUtil.format(
          parsedNumber,
          PNF.INTERNATIONAL
        );

        onChange({
          country: selectedCountry,
          phone: formattedNumber,
        });

        setValid(isPhoneValid);
        setPhone(formattedNumber.replace(diallingCode, ""));
    //   } catch (error) {
    //     setPhone(phoneNumberString);
    //   }
    }
  };

  return (
    <>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <div
        className={`form-control px-2 ${styles.form_control} ${
          valid === false ? "is-invalid" : ""
        }`}
      >
        <div className="enter-phone-number">
          <PhoneInput
            // country="US"
            defaultCountry="US"
            className="country-code-number-input"
            placeholder="Enter phone number"
            value={value}
            onChange={onMobileNumberChange}
          />
        </div>
      </div>
      {errorMessage && (
        <div>
          <span className="text-danger fs-2 lh-1">{errorMessage}</span>
        </div>
      )}
    </>
  );
};

export default PhoneInputField;
