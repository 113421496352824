import Input from "../Form/Input";
import { useEffect, useState } from "react";
import Button from "../../component/Form/Button";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../src/component/Form/FormToast";

const SelectProduct = ({
    selectionLimit,
    selectedOptions,
    onChange,
    options,
    selectLabel = "Select Product *",
    errorMessage,
    showFooter = false,
    cancelPopupCallBack,
    selectPopupCallBack,
    listingStyles = {},
    // contentClass = "d-flex flex-wrap gap-2 justify-content-start",
    contentClass = "select-product-main-area",
    addProduct = false
}) => {
    const toCancelPopupCallBack = cancelPopupCallBack || (() => { }); // Default callback function
    const toSelectPopupCallBack = selectPopupCallBack || (() => { }); // Default callback function

    const [search, setSearch] = useState("");
    const [products, setProducts] = useState(options);
    const [selectedProducts, setSelectedProducts] = useState(selectedOptions);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        setSelectedProducts(selectedOptions)
    }, [selectedOptions])

    const onPressProduct = (e) => {


        const itemIndex = selectedProducts?.findIndex((item) => item?.id === e?.id);

        if (itemIndex !== -1) {
            const updatedOptions = selectedProducts?.filter(
                (item) => item?.id !== e?.id
            );
            onChange(updatedOptions);
            setSelectedProducts(updatedOptions);

        } else {
            const updatedOptions = [...selectedProducts, e];
            if (updatedOptions.length > selectionLimit){
    
                setMessage(true)
                return;

            } else{

                setMessage(false)

            }
            onChange(updatedOptions);
            setSelectedProducts(updatedOptions);

        }


    };

    useEffect(() => {
        setProducts(
            search
                ? options.filter((o) => o.name.toLowerCase().includes(search))
                : options
        );
    }, [search, options]);

    const okPopupCall = () => {
        if (typeof toSelectPopupCallBack === "function") {
            toSelectPopupCallBack();
        }
    };

    const cancelPopupCall = () => {
        if (typeof toCancelPopupCallBack === "function") {
            toCancelPopupCallBack();
        }
    };

    return (
        <div className="select-product-selector">

            {message?
             <FormToast
             type={FORM_TOAST_TYPES.DANGER}
             message={"You can only select 5 products"}
         />:""}

           


            {addProduct && (
                <div className="d-flex justify-content-between" style={{ marginBottom: -16 }}>
                    <div className="mb-2">
                        <p className="form-label mb-0">{selectLabel}</p>
                        <p>You must choose one of your Products to pair with your video.</p>
                    </div>
                    <div className="action-buttons">
                        <Button
                            onClick={addProduct?.onClick}
                            buttonText={addProduct?.label}
                            className="btn btn-primary text-white"
                        />
                    </div>
                </div>
            )}
            <div className="mb-3">
                <Input
                    id="select_product"
                    type="text"
                    label={addProduct ? '' : selectLabel}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    disabled={false}
                    placeholder="Search"
                    errorMessage={errorMessage}
                />
            </div>
            <div className={contentClass} style={listingStyles}>
                {products?.map((e) => {
                    const isChecked = selectedProducts?.find((x) => x?.id === e?.id);
                    return (
                        <div
                            className={`select-product-preview ${isChecked ? `select-product-preview-checked` : ``} `}
                            key={`product-${e?.id}`}
                            onClick={() => onPressProduct(e)}
                        >
                            <div className="select-product-preview-checkbox-row">
                                <div className={`checkbox ${isChecked ? "checked" : ""}`}>
                                    {isChecked && <i className="ti ti-check"></i>}
                                </div>
                            </div>
                            <div className="select-product-preview-image">
                                <img src={e.image} alt={e.name} />
                            </div>
                            <div className="select-product-preview-title">
                                <span className="text-black fs-3 fw-medium">{e?.name}</span>
                            </div>
                        </div>
                    );
                })}
            </div>

            {showFooter === true && (
                <div className="mt-3">
                    <Button
                        onClick={cancelPopupCall}
                        buttonText="Cancel"
                        className="btn white-bg-btn mr-5"
                    ></Button>
                    <Button
                        onClick={okPopupCall}
                        buttonText="Select"
                        className="btn btn-primary text-white"
                    />
                </div>
            )}
        </div>
    );
};

export default SelectProduct;
