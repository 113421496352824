import React, { useState } from "react";
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/product.png";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";

function AddShopifyKeyNav() {
    const isMobileSize = isMobile();
    const history = useHistory();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    // const [showErrors, setShowErrors] = useState(false);
    // const [errorMessage, setErrorMessage] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        history.push("/settings/import-shopify-product");
    };

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    const tagLine = showUserTypeBaseMessage({
        'Brand': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience', 
        'default':'',
        'NonProfit':'Empower Your Donors by Providing an Ultimate Shoppable Video Experience',
        'Influencer':'Empower Your Viewers by Providing an Ultimate Shoppable Video Experience',
        'Individual':'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    });

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Import Product" onNavBarClick={onNavBarClick}>
                <BreadCrump
                    title="Shopify Integration"
                    image={dashboardbreadcrumimage}
                    breadCrumpDetail={[
                        { name: "Dashboard", link: "/" },
                        { name: "Add Shopify Keys", link: "" },
                        
                    ]}
                    tagLine={tagLine}

                />
                <div className="row">
                    <section>
                        <div class="card">
                            <div class="card-body pt-5 pb-5">
                                {/* {(showErrors || errorMessage) && (
                                    <div class="alert alert-danger" role="alert">
                                        <strong>Error </strong>
                                        {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."}
                                    </div>
                                )} */}
                                <div className="d-flex flex-column align-items-center">
                                    <h2 className="text-center mb-4">Add Shopify Keys</h2>
                                    <p className="mb-4 text-center">Authenticate your app by adding keys</p>
                                    <button type="submit" onClick={handleSubmit} class="btn btn-primary w-50 text-white">Add Keys</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </Page>
        </div>
    );
}
export default AddShopifyKeyNav;