import React, { useEffect, useState } from "react";
import { OnboardingPage } from "../../layouts/OnboardingPage";
import LoginForm from "./LoginForm";
import useAuthState from "../../hooks/useAuthState";
import { withRouter } from "react-router-dom";
import qs from "qs";
import { getQueryString } from '../../helpers/helper';


function Login({ history, location }) {
  const [email, setEmail] = useState("");
  const [isEmailPrefilled, setIsEmailPrefilled] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { login } = useAuthState();

  

  useEffect(() => {
    if (location.search) {
      const { email } = qs.parse(location.search, { ignoreQueryPrefix: true });
      if (email) {
        setEmail(email);
        setIsEmailPrefilled(true);
      }
    }
  }, [location]);

  const disabled = email === "" || password === "";

  const handleLogin = async (e) => {
    e.preventDefault();
    // history.replace("/OTP-Verification");
    try {
      setLoading(true);
      await login(email, password);
      const redirectToPathName = location.state
        ? location.state.from.pathname
        : "/";
      history.replace(redirectToPathName);
      
    } catch (err) {
      setLoading(false);
      if (err.message === "old_user") {
        setError({
          title: "Account Restoration",
          message: "Your account restoration is not complete. Check your email for instructions on how to restore your account."
        });
      }else{
        setError({
          title: "Invalid username/email or passwordd.",
          message: "Please check your login credentials and try again."
        });
      }
      // if (err.message === "invalid_credentials") {
        // setError({
        //   title: "Invalid username/email or password.",
        //   message: "Please check your login credentials and try again."
        // });
      // } else {
      //   throw err;
      // }
    }
  };

  return (
    <OnboardingPage title="Video Squirrel - Sign In">
      <LoginForm
        isEmailPrefilled={isEmailPrefilled}
        email={email}
        password={password}
        error={error}
        disabled={disabled}
        loading={loading}
        onEmailChange={(e) => setEmail(e.target.value)}
        onPasswordChange={(e) => setPassword(e.target.value)}
        checkboxs={isChecked}
        onCheckboxChange={(e) => setIsChecked(!isChecked)}
        onSubmit={(e) => handleLogin(e)}
      />
    </OnboardingPage>
  );
}

export default Login;
