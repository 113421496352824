import { useCallback, useEffect, useState } from "react";
import Select from "react-select";

const CitiesDropDown = ({
    country,
    country_id,
    state,
    state_id,
    value,
    onChange
}) => {
    const [data, setData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    const fetchData = useCallback(() => {
        if (!country || !country_id || !state || !state_id) return

        fetch(process.env.REACT_APP_API_URL + "/cities-list", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                "country": country,
                "country_id": country_id,
                "state": state,
                "state_id": state_id
            })
        })
            .then((response) => response.json())
            .then((response) => setData(response?.data ?? []))
            .finally(() => {
                setIsDisabled(false)
            });
    }, [country, country_id, state, state_id])

    useEffect(() => {
        if (!country_id) return

        fetchData();
    }, [country_id, fetchData]);

    const handleChange = (e) => {
        onChange?.(e)
    }

    return (
        <Select
            value={data.find(d => d.id === value) || null}
            options={data}
            onChange={handleChange}
            getOptionLabel={(e) => e["name"]}
            getOptionValue={(e) => e["id"]}
            placeholder="City"
            menuPortalTarget={document.body}
            menuPosition={"fixed"}
            // styles={{
            //     control: (styles) => ({ ...styles, borderColor: "#DFE5EF" }),
            //     menuPortal: base => ({ ...base, zIndex: 9999 })
            // }}
            isDisabled={isDisabled}
        />
    )
}


export default CitiesDropDown;