import React from "react";
import { OnboardingPage } from "../../../layouts/OnboardingPage";
import RegisterForm from "./RegisterForm";
import useProfileInfo from "./useProfileInfo";
import useAuthState from "../../../hooks/useAuthState";

import { useHistory } from "react-router-dom";

import styles from "./RegisterForm.module.scss";

function ProfileInfo() {
  const profileInfoProps = useProfileInfo();
  const history = useHistory(); 
  const { isLoggedIn, logout } = useAuthState();

  return (
    <>
      <OnboardingPage title="Video Squirrel - Sign Up">
        
        <RegisterForm
          {...profileInfoProps}
        />
       
      </OnboardingPage>
    </>
  );
}

export default ProfileInfo;
