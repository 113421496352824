import { useState, useEffect } from "react";
import {userLoggedAccessToken} from "../../../helpers/helper";

const CheckOnBoardingSteps = ({ currentStep, onStepIsPassed }) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        fetch(process.env.REACT_APP_API_URL + '/user-onboarding-data', {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + userLoggedAccessToken()
            },
            body: JSON.stringify({
                draftType: 'onboarding',
            })
        })
            .then(e => e.json())
            .then(e => {
                setLoading(false);
                if (e?.code === 200 && e?.data?.length > 0) {
                    let data = e?.data[0];
                    let step = data?.step;
                    // Handle Step
                    if (currentStep > step) return
                   
                    
                    onStepIsPassed(data)
                }
            }).catch(err => {
                setLoading(false);
                console.log("err", err)
            })
    }, [])

    return { loading }
}

export default CheckOnBoardingSteps;