import React, { useState, useEffect } from "react";
// import classNames from "classnames";
// import styles from "./SidebarItem.module.scss";
// import { Icon } from "../../../atoms/Icon";
import { Link } from "react-router-dom";
import * as helper from "../../helpers/helper";


function SidebarItem({ isOnBoardingComplete, active, icon, label, onClick, childMenu,isSubMenu, parentMenuLink, isAccountTypeNotSelected }) {
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const segOne = helper.urlSegment(0);
  const pathname = helper.urlSegment(1);
  
  function handleClickEvent(){
    onClick();
    // openCloseSubMenu();
  }

  function openCloseSubMenu(){
    setOpenSubMenu(openSubMenu ? false:true);
  }

  useEffect(() => {
    // fetchVideoData();
  }, []);



  return(  

    <li className={(isOnBoardingComplete  && ( isAccountTypeNotSelected===false) ) ? "sidebar-item": "sidebar-item disabled-event" }>

      <span className={(isSubMenu && !active) ? "sidebar-link has-arrow" : (isSubMenu && active) ? "sidebar-link has-arrow active" : active ? "sidebar-link active" : "sidebar-link"} onClick={isSubMenu ? openCloseSubMenu : handleClickEvent} aria-expanded="false">
        <img src={icon} alt={label} />
        <span className="hide-menu" >{label}</span>
      </span>

      {typeof childMenu!=='undefined' && childMenu.length>=1?
        // <ul className={(openSubMenu || childMenu.find((item) => '/'+item.link === pathname)) ? "collapse first-level in":"collapse first-level"}>
        <ul className={(openSubMenu || active===true) ? "collapse first-level in":"collapse first-level"}>
        {childMenu.map(({name,link,icon, isDisabled, toolTip}, i) => {

             const isActive = segOne === parentMenuLink && link === (pathname || null || '');
            //  const isShopifySetup = (!videoData || !isOnBoardingComplete) && link === "shopify-setup";
             const isShopifySetup = helper.isOnboardCompleted()===false && (link == "shopify-setup" || link === "woocommerce-setup");
             
            return (
              <li className={"sidebar-item sidebar-sub-item"+(isDisabled===true?" disabled-event":"")} title={toolTip} key={`sidebar-item-${i}`}>
                 {isShopifySetup?<><Link to={('/'+parentMenuLink+'/'+link)} className={"sidebar-link disabled-event"}> 
                  <span className="hide-menu">{name}</span>
                </Link></>:<><Link to={('/'+parentMenuLink+'/'+link)} className={isActive ? "sidebar-link active" : "sidebar-link"}> 
                  <span className="hide-menu">{name}</span>
                </Link></>}  
              </li>
            );
          })}
        </ul>:""}
        
  </li>
    )
  
}

export default SidebarItem;
