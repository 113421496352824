import React  from "react";
import Loading from "../Pages/Loadings/Loading";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { useEffect } from 'react';

const customStyles = {
    rows: {
        style: {
            minHeight: "68px !important",
        },
    },
    cells: {
        style: {
            justifyContent: "start !important",
        }
    },
    headCells: {
        style: {
            justifyContent: "start !important",
        }
    }
};

const Table = ({
    loading,
    columns,
    data,
    totalRows,
    currentPage,
    currentRow,
    handlePerRowsChange,
    handlePageChange,
    onRowClicked,
    actions,
    ...props
}) => {
    // const dropdownRefs = useRef({});

    const isDropdownOpen = (id) => {
        const dropdownEl = document.getElementById(`dropdown-${id}`)
        return dropdownEl.getAttribute("aria-expanded")
    }

    const openDropdown = (id) => {
        const dropdownEl = document.getElementById(`dropdown-${id}`)
        if (dropdownEl) {
            dropdownEl.setAttribute("aria-expanded", true)
        }

        const dropdownMenuEl = document.getElementById(`dropdown-menu-${id}`)
        if (dropdownMenuEl){
            dropdownMenuEl.classList.add("show");
        }
    }

    const closeDropdown = (id) => {
        const dropdownEl = document.getElementById(`dropdown-${id}`)
        if (dropdownEl) {
            dropdownEl.setAttribute("aria-expanded", false)
        }

        const dropdownMenuEl = document.getElementById(`dropdown-menu-${id}`)
        if (dropdownMenuEl) {
            dropdownMenuEl.classList.remove("show");
        }
    }

    const closeAllDropdowns = () => {
        const elements = Array.from(document.querySelectorAll('[aria-expanded]'));
        const elementsIds = elements.filter(e => e.getAttribute('aria-expanded') === 'true').map(e => e.getAttribute('data-id'));

        elementsIds.forEach(id => closeDropdown(id));
    }

    const handleToggleDropdown = (id) => {
        if (isDropdownOpen(id) === 'false') {
            closeAllDropdowns();
            return openDropdown(id)
        } else {
            return closeDropdown(id)
        }
    };


    const handleClickOutside = (event) => {
        if (!event.target.classList.contains('ti-dots-vertical')) {
            setTimeout(() => {
                closeAllDropdowns();
            }, 500);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (actions) {
        columns.push(
            {
                name: "",
                cell: (row) => {
                    return (
                        <div className="dropdown dropstart">
                            <a href="#!" className="black-color" id={`dropdown-${row.id}`} data-id={row.id} data-bs-toggle="dropdown" aria-expanded="false" onClick={() => handleToggleDropdown(row.id)}>
                                <i className="ti ti-dots-vertical fs-8"></i>
                            </a>
                            <ul className={`dropdown-menu z-1000 `} id={`dropdown-menu-${row.id}`} aria-labelledby={`dropdownMenuButton-${row.id}`}>
                                {
                                    actions.map(action => {

                                        const { disabled, icon, label, title, onClick } = action(row)

                                        return (
                                            <li>
                                                <span
                                                    role="button"
                                                    className={`dropdown-item d-flex align-items-center gap-3 ${disabled ? "gray-disabled-tag" : ""}`}
                                                    title={title}
                                                    onClick={() => {
                                                        handleToggleDropdown(row.id)
                                                        if (disabled) return;

                                                        onClick(row)
                                                    }}
                                                >
                                                    {icon}
                                                    <span>{label}</span>
                                                </span>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )
                },
                width: "50px"
            }
        )
    }

    if (loading === true) {
        return <div className="py-5"><Loading /></div>;
    }

    return (
        <DataTableExtensions
            columns={columns}
            data={data}
            export={false}
            print={false}
        >
            <DataTable
                customStyles={customStyles}
                columns={columns}
                data={data}
                className="hide-expandable-icons-row css-for-dropdown cursor-pointer"
                noHeader
                defaultSortField="id"
                defaultSortAsc={true}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationDefaultPage={currentPage}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
                highlightOnHover
                dense
                expandableRows
                expandableRowsComponent={() => ""}
                // expandableIcon={{
                //     collapsed: <Add />,
                //     expanded: <Subtract />,
                // }}
                expandableRowExpanded={(row) => row === currentRow}
                onRowClicked={onRowClicked}
                {...props}
            />
        </DataTableExtensions>
    );
};

export default Table;
