import React, { useState, useEffect } from "react";
import { userLoggedAccessToken } from "../../helpers/helper";
import "./ExpandableProductDetails.css";
import Loading from "../../Pages/Loadings/Loading";
import { constant } from "lodash";

const ExpandableProductDetails = ({ row }) => {





  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const fetchProductData = (videoId) => {
    setLoading(true);

    fetch(process.env.REACT_APP_API_URL + "/find-product-detail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ video_id: videoId }),
    })
      .then((response) => response.json())
      .then((data) => setData(data?.data))
      .finally(() => setLoading(false));
  };

  useEffect(() => {

    if(row.has_variants==true){
      fetchProductData(row?.id);
    }

  }, [row]);




  if (loading === true) {
    return (
      <div className="py-5">
        <Loading />
      </div>
    );
  }

  return (
    <div  className="product-modal">
      <h4 className="text-black">Product Variant(s)</h4>

      <div className="mt-4">
        <div className="d-flex flex-row align-items-center gap-3">
          <img
            src={row.image}
            className="rounded-2 border border-2 border-primary-subtle"
            width={82}
            height={146}
            alt={row.name}
          />
          <div>
            <span className="text-black fs-7">{row.name}</span>
          </div>
        </div>
      </div>

      {/* {data?.length > 0 && (
        <div className="mt-4">
          <div className="d-flex">
            <div className="flex-fill">
              <span className="text-black fw-bolder">SKU</span>
            </div>
            <div className="flex-fill">
              <span className="text-black fw-bolder">Quantity</span>
            </div>
            <div className="flex-fill">
              <span className="text-black fw-bolder">Price</span>
            </div>
          </div>
          {data.map((variant, i) => (
            <div key={`variant-${i}`} className="d-flex">
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{variant.sku}</span>
              </div>
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{variant.quantity}</span>
              </div>
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{variant.price}</span>
              </div>
            </div>
          ))}
        </div>
      )} */}



      
      {(data?.length > 0 )?(
        <div>
        <div className="mt-4">
          <div className="d-flex">
            <div className="flex-fill">
              <span className="text-black fw-bolder">SKU</span>
            </div>
            <div className="flex-fill">
              <span className="text-black fw-bolder">Quantity</span>
            </div>
            <div className="flex-fill">
              <span className="text-black fw-bolder">Price</span>
            </div>
          </div>
          {data.map((variant, i) => (
            <div key={`variant-${i}`} className="d-flex">
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{variant.sku}</span>
              </div>
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{variant.quantity}</span>
              </div>
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{variant.price}</span>
              </div>
            </div>
          ))}
        </div>
        </div>
        
      ):(<div>

      <div className="mt-4">
          <div className="d-flex">
            <div className="flex-fill">
              <span className="text-black fw-bolder">SKU</span>
            </div>
            <div className="flex-fill">
              <span className="text-black fw-bolder">Quantity</span>
            </div>
            <div className="flex-fill">
              <span className="text-black fw-bolder">Price</span>
            </div>
          </div>
          {/* {data.map((variant, i) => ( */}
            <div className="d-flex">
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{row?.sku}</span>
              </div>
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{(row.quantity==-1)?"Unlimited":row.quantity}</span>
              </div>
              <div className="flex-fill py-2">
                <span className="text-black fw-bold">{row.price}</span>
              </div>
            </div>
          {/* ))} */}
        </div>

      </div>)}



    </div>
  );
};

export default ExpandableProductDetails;
