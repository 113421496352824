import React, { useState } from 'react';
import { formatCardNumber } from "../../../helpers/helper";

const StripeTestCardNumbers = () => {
  const [copiedIndex, setCopiedIndex] = useState(null);

  const handleCopy = (value, index) => {
    const plainValue = value?.replace(/\s+/g, ''); // Remove spaces before copying
    navigator.clipboard.writeText(plainValue);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 3500); // Reset after 2 seconds
  };

  const data = [
    {
      brand: 'Visa',
      number: '4242424242424242',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
    {
      brand: 'Visa (debit)',
      number: '4000056655665556',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
    {
      brand: 'Mastercard',
      number: '5555555555554444',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
    {
      brand: 'Mastercard (2-series)',
      number: '2223003122003222',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
    {
      brand: 'Mastercard (debit)',
      number: '5200828282828210',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
    {
      brand: 'Mastercard (prepaid)',
      number: '5105105105105100',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
    {
      brand: 'American Express',
      number: '378282246310005',
      cvc: 'Any 4 digits',
      date: 'Any future date',
    },
    {
      brand: 'American Express',
      number: '371449635398431',
      cvc: 'Any 4 digits',
      date: 'Any future date',
    },
    {
      brand: 'Discover',
      number: '6011111111111117',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
    {
      brand: 'Discover',
      number: '6011000990139424',
      cvc: 'Any 3 digits',
      date: 'Any future date',
    },
  ];

  return (
    <div className='mt-5'>
      <h5>Testing Cards</h5>
      <table className='table'>
        <thead>
          <tr>
            <th scope="col">Brand</th>
            <th scope="col">Number</th>
            <th scope="col">CVC</th>
            <th scope="col">Expiry Date</th>
          </tr>
        </thead>
        <tbody>
          {
            data.map((r, rIndex) => (
              <tr key={`row-${rIndex}`}>
                <th>{r.brand}</th>
                <td>
                <div className='cursor-pointer' onClick={() => handleCopy(r.number, rIndex)}>
                  {formatCardNumber(r.number)}
                  <i className={"ti fs-5 cursor-pointer " + (copiedIndex === rIndex ? "ti-check" : "ti-clipboard")} ></i>
                </div>
                </td>
                <td>{r.cvc}</td>
                <td>{r.date}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
};

export default StripeTestCardNumbers;
