import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import * as helper from "../../../helpers/helper";
import CheckOnBoardingSteps from "../Component/CheckOnBoardingSteps";
import ScreenLoading from '../../Loadings/ScreenLoading';
import WhiteSquareButton from "../../../Form/WhiteSquareButton";
import PackageImg from "./../../../../assets/icons/package.svg"
import HelpingHandImg from "./../../../../assets/icons/Vector-donation.svg"
import SearchCheckImg from "./../../../../assets/icons/vector-lead.svg"
import SubscriptionWarning from "../../Warnings/SubscriptionWarning";
import useAuthState from "../../../hooks/useAuthState";
import {userLoggedAccessToken,showUserTypeBaseMessage,urlSegment,isTypeNullOrNotFilled, isOnboardCompleted} from "../../../helpers/helper"; 


export const PURPOSE_OF_VIDEOS = [
  { label: "Select purpose of video", value: "" },
  { label: "To sell your product", value: "To sell your product" },
  { label: "To generate lead", value: "To generate lead" },
  { label: "To collect the donation", value: "To collect the donation" },
];

function VideoPurpose({ videoPurpose, setVideoPurpose }) {
  const history = useHistory();
  const isMobileSize = helper.isMobile();
  const { user } = useAuthState();
  const AddVideoPurpose = (purpose) => {

    const addVideoData = {
      videoType: purpose,
    }

    history.push({
      pathname: '/add-video',
      state: {
        addVideoData: addVideoData
      },
    });
  }
  const onStepIsPassed = (e) => {
    history.push({
      pathname: '/add-video',
    });
  }

  const { loading: stepsLoading } = CheckOnBoardingSteps({ currentStep: 1, onStepIsPassed });

  const [allowuser, setAllowuser] = useState(true);



  useEffect(() => {
  
    var token = localStorage.getItem("authSession");
    token = JSON.parse(token);

    var usr=token.restrictedbyCountry
    setAllowuser(usr)

  },[]);


  // const UserAllowwedData = async () => {
    
  //   fetch(process.env.REACT_APP_API_URL + "/user-allowed-countries", {
  //     method: "POST",
  //     mode: "cors",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + userLoggedAccessToken(),
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((response) => {
  //       if (response?.status === true) {


  //         setAllowuser(response?.restrictedbyCountry)
       
  //       } else {
         
  //       }
  //     })
  //     .catch((error) => {

  //       console.error("Error fetching data:", error);
  //     })
  //     .finally((error) => {
       
  //     });
  // };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <h1 className="fw-bolder text-capitalize">Welcome {user?.name} 👋</h1>
          <p className="fs-4 text-black">Follow these steps to finalize the setup and start engaging and converting</p>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="mb-4">

            {(allowuser==false)?"":<SubscriptionWarning title="Upgrade your plan to unlock advanced features" />}

            {/* <SubscriptionWarning title="Upgrade your plan to unlock advanced features" /> */}

  
           
          </div>
        </div>
      </div>

      <div className="row pb-50">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header vs-card-header">
              <h5 className="fw-bolder">What would you like to do?</h5>
            </div>
            <div className="card-body form-card-body">
              <div className={`d-flex align-items-center gap-4 mt-8 onboarding-purpose-boxes`}>
                <WhiteSquareButton
                  icon={<img src={PackageImg} alt="Sell Product" height="28" width="28" />}
                  label="Sell Product"
                  active={videoPurpose === 'To sell your product'}
                  onClick={() => setVideoPurpose('To sell your product')}
                  styles={{
                    height: isMobileSize ? 100 : 109,
                    width: isMobileSize ? 100 : 155,
                  }}
                />
                <WhiteSquareButton
                  icon={<img src={HelpingHandImg} alt="Get Donations" height="28" width="36" />}
                  label="Get Donations"
                  active={videoPurpose === 'To collect the donation'}
                  onClick={() => setVideoPurpose('To collect the donation')}
                  styles={{
                    height: isMobileSize ? 100 : 109,
                    width: isMobileSize ? 100 : 155,
                  }}
                />
                <WhiteSquareButton
                  icon={<img src={SearchCheckImg} alt="Capture Leads" height="28" width="28" />}
                  label="Capture Leads"
                  active={videoPurpose === 'To generate lead'}
                  onClick={() => setVideoPurpose('To generate lead')}
                  styles={{
                    height: isMobileSize ? 100 : 109,
                    width: isMobileSize ? 100 : 155,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoPurpose;