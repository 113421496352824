import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { userLoggedAccessToken } from "../../../helpers/helper";
import { formatNumber, removeTrailingZeros } from "../../../helpers/helper";
const moment = require("moment");

const ExpandOrderCommissionView = (props) => {
  const { data } = props;
  const [dataLoader, setDataLoader] = useState(false);
  //   const [orderCommissionTableData, setOrderCommissionTableData] = useState();
  const [orderCommissionData, setOrderCommissionData] = useState();

  const dbDateFormat = "YYYY-MM-DD HH:mm:ssZ";

  useEffect(() => {
    fetchCommissionDetails(data?.commission_deduction_id);
  }, []);

  const fetchCommissionDetails = (commissionId) => {
    setDataLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/order-commission-details", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ commission_id: commissionId }),
    })
      .then((response) => response.json())
      .then((data) => {
        setOrderCommissionData(data?.data);
        // setOrderCommissionTableData(data?.data?.commissionDetails);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setDataLoader(false);
      });
  };

  const columns = [
    {
      name: "Order ID",
      width: "170px",
      sortable: false,
      cell: (d) => {
        return d?.order_number;
      },
    },

    {
      name: "Order Date",
      sortable: false,
      width: "160px",
      cell: (d) => {
        return moment(d?.purchase_date, dbDateFormat).format("LL");
      },
    },

    {
      name: "Product Name",
      sortable: false,
      width: "160px",
      cell: (d) => {
        return d?.name;
      },
    },

    {
      name: "Product SKU",
      sortable: false,
      width: "150px",
      cell: (d) => {
        return d?.sku;
      },
    },

    // {
    //   name: "Order Details",
    //   width: "230px",
    //   sortable: false,
    //   cell: (d) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="user-meta-info">
    //           <h6 className="mb-1 fw-semibold chat-title">{d?.order_number}</h6>
    //           <small>
    //             {moment(d?.purchase_date, dbDateFormat).format("LL")}
    //           </small>
    //         </div>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   name: "Product Details",
    //   width: "230px",
    //   sortable: false,
    //   cell: (d) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="user-meta-info">
    //           <h6 className="mb-1 fw-semibold chat-title">{d?.name}</h6>
    //           <small>{d?.sku}</small>
    //         </div>
    //       </div>
    //     );
    //   },
    // },

    // {
    //   name: "Variant",
    //   sortable: false,
    //   cell: (d) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <div className="user-meta-info">
    //           <h6 className="mb-1 fw-semibold chat-title">{d?.variant_type}</h6>
    //           <small>{d?.variant_value}</small>
    //         </div>
    //       </div>
    //     );
    //   },
    // },

    // {
    //   name: "SKU",
    //   width: "150px",
    //   sortable: false,
    //   cell: (d) => {
    //     // return 1;
    //   },
    // },

    {
      name: "Variant",
      width: "130px",
      sortable: false,
      cell: (d) => {
        return d?.variant;
      },
    },
    {
      name: "Quantity",
      width: "100px",
      sortable: false,
      cell: (d) => {
        return 1;
      },
    },

    {
      name: "Order Amount",
      sortable: false,
      width: "150px",
      cell: (d) => {
        return formatNumber(d?.price);
      },
    },

    {
      name: "Commission Amount",
      sortable: false,
      width: "180px",
      cell: (d) => {
        return formatNumber(removeTrailingZeros(d?.order_commission_amount));
      },
    },
  ];

  //   const data = orderCommissionData;

  //   const tableData = {
  //     columns,
  //     orderCommissionData.commissionDetails,
  //   };

  return (
    <div className="video-details-main">
      <button
        className="close-toggle-btn"
        onClick={(e) => {
          props.closeFromChild();
        }}
        type="button"
      >
        <i className="ti ti-x fs-7"></i>
      </button>

      <div className="d-flex justify-content-center">
        {!dataLoader && (
          <div className="col-12 mt-2 p-2">
            <div className="invoice-inner-part">
              <div className="invoiceing-box">
                <div className="invoice-header d-flex align-items-center border-bottom pt-3">
                  <h4 className="font-medium text-uppercase">
                    Commission Invoice
                  </h4>
                  <div className="ms-auto">
                    {orderCommissionData?.transaction_id && (
                      <h4 className="invoice-number">
                        #{orderCommissionData?.transaction_id}
                      </h4>
                    )}
                  </div>
                </div>
                <div
                  className="pt-2"
                  id="custom-invoice"
                  style={{ display: "block" }}
                >
                  <div
                    className="invoice-123 mb-3"
                    id="printableArea"
                    style={{ display: "block" }}
                  >
                    <div className="row ">
                      <div className="col-md-12">
                        {orderCommissionData?.deduction_date && (
                          <h6 className="fw-bold">
                            Charge Date:{" "}
                            {moment(
                              orderCommissionData?.deduction_date,
                              dbDateFormat
                            ).format("LL")}{" "}
                          </h6>
                        )}
                      </div>
                      <DataTable
                        columns={columns}
                        data={orderCommissionData?.commissionDetails}
                        selectableRows
                        customStyles={{
                          rows: {
                            style: {
                              backgroundColor: "#fafafa", // Set the background color for table rows
                            },
                          },
                          headRow: {
                            style: {
                              backgroundColor: "#fafafa", // Set the background color for table header row
                            },
                          },
                        }}
                      />
                      <div className="col-md-12">
                        <div className="pull-right mt-4 text-end">
                          <hr />

                          {orderCommissionData?.commission_amount && (
                            <h5>
                              <b>Total Commission :</b>{" "}
                              {formatNumber(
                                orderCommissionData?.commission_amount
                              )}
                            </h5>
                          )}
                        </div>
                        {/* <div className="col-12 text-center">
                          <div className="d-flex justify-content-center">
                            <button
                              type="button"
                              className="btn btn-lg rounded-pill waves-effect waves-light btn-primary"
                            >
                              PAY NOW{" "}
                              {formatNumber(
                                orderCommissionData?.commission_amount
                              )}
                            </button>
                          </div>
                          <small>Click proceed</small>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {dataLoader && (
          <div className="spinner-border m-5" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpandOrderCommissionView;
