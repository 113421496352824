import React from "react";
import { Link } from "react-router-dom";
import {
  isMobile,
  urlSegment,
  htmlToPlainText,
} from "../../../../helpers/helper";
import stripeLogo from "../../../../../dist/images/paymentgateways/stripe-round-logo.svg";
import paypalLogo from "../../../../../dist/images/paymentgateways/paypal-round-logo.svg";
import authorizeNetLogo from "../../../../../dist/images/paymentgateways/authorize-net-logo.svg";
import checkRoundImg from "../../../../../assets/icons/check-round.svg";
import styles from "./InVideoCheckoutMain.module.css";
const paymentGateWaysLogo = {
  stripe: stripeLogo,
  paypal: paypalLogo,
  "authorize-net": authorizeNetLogo,
};

const InVideoCheckoutPaymentGatewaysListing = ({
  userSubscription,
  paymentGateWays,
  paymentGateWayLoader,
  isInVideoCheckoutDisabled,
}) => {
  

  const paymentSlug = urlSegment(2);

  return (
    <>
      {(!paymentGateWays?.length && userSubscription?.plan_type !== "paid") ===
        false &&
        paymentGateWayLoader === false && (
          <>
            {/* <h4 className="mt-2 mb-4">Payment Gateways</h4> */}

            <div className={`${styles.gateyWayListingArea}`}>
              {paymentGateWays?.map((paymentGateWay, index) => (
                <>
                  <div
                    className={` ${styles.gateyWayListBox} ${
                      paymentGateWay?.is_payment_gateway_active !== true ||
                      isInVideoCheckoutDisabled === true
                        ? "disabled-tag"
                        : ""
                    }`}
                  >
                    <Link
                      to={`/settings/in-video-checkout/${paymentGateWay?.slug}`}
                      title={`Click to configure ${htmlToPlainText(
                        paymentGateWay?.name
                      )}`}
                      className={`card p-4 ${styles.gateWayLinkBox} ${paymentGateWay?.slug===paymentSlug?styles.selectedPaymentMethod:""}`}
                    >
                      {paymentGateWay?.active_payment_method > 0 && (
                        <div
                          className={`payment-method-current-check fs-6 ${styles.paymentMethodCurrentCheck}`}
                        >
                          <img src={checkRoundImg} />
                          {/* <i className="ti ti-circle-check-filled"></i> */}
                        </div>
                      )}

                      {paymentGateWaysLogo[paymentGateWay?.slug] && (
                        <img
                          className={`${styles.gatewayLogo}`}
                          src={paymentGateWaysLogo[paymentGateWay?.slug]}
                        />
                      )}
                    </Link>
                  </div>
                </>
              ))}
            </div>
          </>
        )}
    </>
  );
};

export default InVideoCheckoutPaymentGatewaysListing;
