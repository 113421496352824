import React, { useEffect, useState } from "react";
import Select from 'react-select'
// import countriesList from './CountriesListJson';



function VideoDropDown(
    {optionList,selectOption,indexValue,setValue}
){

    const [selectedValue, setSelectedValue] = useState(null);


  useEffect(() => {
    setSelectedValue(setValue);
  });


    const handleChangeMethod = (data) => {
        // setCountryCode(data?.code);
        // setPhoneNumber('');
        // selectOption()
        selectOption(indexValue,data);
    }
      
    return (
        <div>
            <Select
            options={optionList}
            onChange={handleChangeMethod}
            // value={selectedValue}
            defaultValue={selectedValue}

            menuPortalTarget={document.body} menuPosition={'fixed'}
            
            // getOptionLabel={(countriesList) => countriesList['name']+' ' + countriesList['dialling_code']}
            getOptionLabel={(optionList) => optionList['videoname']}
            getOptionValue={(optionList) => optionList['id']}
            placeholder="Select Video"
            />
        </div>
                
    );
};


export default VideoDropDown;