class SocialHelper {

  static  shareOnFacebook = (urlToShare,returnValue=false) => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${(urlToShare)}` ;
    if(returnValue){
      return url;
    } else {
      window.open(url, '_blank');
    }
  }

  static shareOnLinkedIn = (urlToShare,returnValue=false) => {
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${(urlToShare)}`;
    if(returnValue){
      return url;
    } else {
      window.open(url, '_blank');
    }


  };

  static shareOnWhatsApp = (urlToShare,returnValue=false) => {
    const text = encodeURIComponent('Check out this content: '+urlToShare);
    const url = `https://api.whatsapp.com/send?text=${text}`;

    if(returnValue){
      return url;
    } else {
      window.open(url, '_blank');
    }


  };

  static shareOnTwitter = (urlToShare,returnValue=false) => {
    const url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(urlToShare)}`;
    if(returnValue){
      return url;
    } else {
      window.open(url, '_blank');
    }
  };
}

export default SocialHelper ;
