class ActivityLog {

  createaAtivitylog({user_id,is_admin,event_id,account_id}) {

    return new Promise((resolve, reject) => {
        fetch(process.env.REACT_APP_API_URL+'/add-activity-logs', {
            method: 'POST', 
            mode: 'cors', 
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              "user_id": user_id,
              "is_admin": is_admin,
              "event_id": event_id,
              "account_id": account_id
            })
          },
          (err) => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          })
       });
  }

}

export default ActivityLog;
