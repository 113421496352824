import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userLoggedAccessToken } from "../../../../helpers/helper";
import StripeTestCardNumbers from "../StripeTestCardNumbers";
import ConfirmationPopup from "../../../../Form/ConfirmationPopup";
import styles from "./StripeCredentials.module.css";
import Input from "../../../../../component/Form/Input";
import Button from "../../../../../component/Form/Button";
import ActivityLog from "../../../../helpers/ActivityLog";

// const warningMessage = "";
// "All products with payment method selection will not work";

const GATEWAY_NAME = "Stripe";
const PAYMENT_MODES = ["TEST", "LIVE"];
const DEFAULT_FIELDS = [
  {
    label: "Publishable Key",
    value: "",
    id: "api_public_key",
    placeholder: "Publishable Key",
  },
  {
    label: "Secret Key",
    value: "",
    id: "api_secret_key",
    placeholder: "Secret Key",
  },
];

const StripeCredentials = ({
  defaultCredentials,
  setSuccess,
  setError,
  isInVideoCheckoutDisabled,
  isAnotherGateWayActive,
  reFetchUserPaymentGateways
}) => {
  const [fields, setFields] = useState(
    PAYMENT_MODES.map((p) => ({ [p]: DEFAULT_FIELDS }))
  );
  const [activeMode, setActiveMode] = useState(PAYMENT_MODES[0]);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fieldsError, setFieldsError] = useState(false);
  const [expendTestCards, setExpendTestCards] = useState(false);
  const [userprofile, setUserprofileError] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    if (defaultCredentials) {
      setIsActive(
        defaultCredentials?.paymentGatewaysCredentials[0]?.is_active === true ||
          defaultCredentials?.paymentGatewaysCredentials[1]?.is_active === true
      );

      // console.log('defaultCredentials.paymentGatewaysCredentials',defaultCredentials.paymentGatewaysCredentials);

      defaultCredentials.paymentGatewaysCredentials.map((df) => {
        updateFields(df.payment_mode, 0, "value", df.api_key);
        updateFields(df.payment_mode, 1, "value", df.secret_key);
        // setIsActive(df.is_active);
        // setIsActive(defaultCredentials?.active_payment_method);

        if (df.is_selected) {
          setActiveMode(df.payment_mode);
        }

        return null;
      });
    }
  }, [defaultCredentials]);

  // useEffect(() => {
  //   console.log("activeMode>><<", activeMode);
  // }, [activeMode]);

  const updateFields = (type, index, key, value) => {
    setFields((prevFields) =>
      prevFields.map((f) => {
        const mode = Object.keys(f)[0];
        return {
          [mode]:
            type === mode
              ? f[mode].map((ff, ffIndex) =>
                  ffIndex === index ? { ...ff, [key]: value } : ff
                )
              : f[mode],
        };
      })
    );
  };

  const onPressSaveChange = async () => {
    setLoading(true);
    const isProfileCompleted = await CheckUserProfile();

    if (!isProfileCompleted) {
      setLoading(false);
      return setUserprofileError(true);
    }

    setError("");
    setSuccess("");
    const errors = fields
      .find((f) => {
        const mode = Object.keys(f)[0];
        return mode === activeMode;
      })
      [activeMode].filter((f) => !f.value).length;

    setFieldsError(!!errors);
    if (errors > 0) return;

    const dataToSubmit = fields
      .map((f) => {
        const mode = Object.keys(f)[0];

        if (activeMode !== mode) return null;

        return {
          payment_mode: mode,
          is_selected: mode === activeMode,
          api_key: f[mode].find((ff) => ff.id === "api_public_key").value,
          secret_key: f[mode].find((ff) => ff.id === "api_secret_key").value,
        };
      })
      .filter((e) => e);

    fetch(process.env.REACT_APP_API_URL + "/save-stripe-credentials", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({
        active: isActive,
        params: dataToSubmit,
      }),
    })
      .then((response) => response.json())
      .then((e) => {

        reFetchUserPaymentGateways({callLoader:false});

        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        const userLog = new ActivityLog();
        var user_id = token.idToken;
        var is_admin = 0;
        var event_id = 25;
        var account_id = token.idToken;
        userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })


        e?.status === true
          ? setSuccess(e?.message)
          : setError(e?.message ?? "Something went wrong")

      })
      .catch((err) => setError(err?.message))
      .finally(() => setLoading(false));
  };

  const CheckUserProfile = async () => {
    try {
      var token = localStorage.getItem("authSession");
      var parsedToken = JSON.parse(token);

      const response = await fetch(
        process.env.REACT_APP_API_URL + "/check-user-profile",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + parsedToken.accessToken,
          },
          body: JSON.stringify({ user_id: parsedToken.idToken }),
        }
      );

      const data = await response.json();
      return data.code === "200";
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateActive = (e) => {
    if (e && isAnotherGateWayActive) {
      return setShowConfirmationModal(true);
    }

    setIsActive(e);
  };

  const setLiveTestModeForSwitch = (isItCheckedOrNot) => {
    if (isItCheckedOrNot) {
      setActiveMode("LIVE");
    } else {
      setActiveMode("TEST");
    }
    // const mode = Object.keys(f)[0];
    // const isModeActive = activeMode === mode;
    // setActiveMode(mode)

    // const mode = Object.keys(f)[0];
    //       const isModeActive = activeMode === mode;
  };


  return (
    <>
      <ConfirmationPopup
        show={showConfirmationModal}
        title="Are you sure?"
        subTitle="Enabling this payment gateway will deactivate all others; proceed with caution"
        cancelTitle="Cancel"
        onCancel={() => setShowConfirmationModal(false)}
        proceedTitle="Confirm"
        onProceed={() => {
          setShowConfirmationModal(false);
          setIsActive(true);
        }}
      />
      <h4>{GATEWAY_NAME}</h4>

      <div className={`col-xs-12 ${styles.testLiveModeSwitch}`}>
        <label
          onClick={(e) => {
            setLiveTestModeForSwitch(false);
          }}
          className={`form-check-label cursor-pointer ${
            activeMode === "TEST" ? "font-weight-700" : ""
          }`}
        >
          Test Mode
        </label>
        <div
          className={`form-check form-switch custom-switch ${styles.testLiveModeSwitchInput}`}
        >
          <input
            onChange={(e) => {
              setLiveTestModeForSwitch(e?.target?.checked);
            }}
            className="form-check-input w-50p"
            type="checkbox"
            checked={activeMode === "LIVE"}
          />
        </div>
        <label
          onClick={(e) => {
            setLiveTestModeForSwitch(true);
          }}
          className={`form-check-label cursor-pointer ${
            activeMode === "LIVE" ? "font-weight-700" : ""
          }`}
        >
          Live Mode
        </label>{" "}
      </div>
      <div className="col-md-12 col-lg-6 col-xs-12 auto-margin">
        <div className="row">
          {fields.map((f, index) => {
            const mode = Object.keys(f)[0];
            const isModeActive = activeMode === mode;

            return (
              <div className={`col-12 ${isModeActive ? "" : "hide"}`}>
                <div className="credential-fields">
                  {f[mode].map((field, fIndex) => (
                    <div className="mb-3 row" key={`stripe-field-${fIndex}`}>
                      <div className="col-12">
                        <div className="mb-2">
                          <Input
                            type="text"
                            label={`${field.label} *`}
                            value={field.value}
                            onChange={(e) =>
                              updateFields(
                                mode,
                                fIndex,
                                "value",
                                e.target.value
                              )
                            }
                            id={field.id}
                            disabled={false}
                            placeholder={field.placeholder}
                            className={`form-control ${
                              !isModeActive && "gray-disabled-tag"
                            } ${fieldsError && !field.value && "is-invalid"}`}
                          />

                          {/* <input
                            type="text"
                            value={field.value}
                            onChange={(e) =>
                              updateFields(
                                mode,
                                fIndex,
                                "value",
                                e.target.value
                              )
                            }
                            id={field.id}
                            placeholder={field.placeholder}
                            className={`form-control ${
                              !isModeActive && "gray-disabled-tag"
                            } ${fieldsError && !field.value && "is-invalid"}`}
                          /> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="col-12">
              <div className="form-check form-switch custom-switch">
                <input
                  onChange={() => updateActive(!isActive)}
                  // onChange={() => setIsActive((e) => !e)}
                  className="form-check-input w-50p"
                  type="checkbox"
                  id={`set-active-pay`}
                  checked={isActive}
                />
                <label className={`form-check-label ${isActive?"font-weight-700":""}`} for="set-active-pay">
                  Active
                </label>
              </div>
            </div>
          </div>
          {/* {!isActive && <span className="text-warning">{warningMessage}</span>} */}
          {userprofile ? (
            <p className="text-danger">
              Kindly add the required fields to activate the IN-VIDEO checkout.
              <Link to="/settings/account-setting">Click here</Link>
            </p>
          ) : (
            ""
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <div className="actions clearfix">
              <ul
                className={`d-flex justify-content-space-between ${styles.seeCardSubmitBtn} align-items-center mt-3`}
                role="menu"
                aria-label="Pagination"
              >
                <li
                  className="text-primary cursor-pointer fs-4"
                  onClick={(e) => setExpendTestCards(!expendTestCards)}
                >
                  See test cards
                  <i
                    className={
                      expendTestCards ? "ti ti-arrow-up" : "ti ti-arrow-down"
                    }
                  ></i>
                </li>
                <li className="auto-margin">
                  {/* <Link
                    className="btn btn-dark text-white mr-10"
                    to="/settings/in-video-checkout"
                  >
                    <i className="ti ti-arrow-narrow-left"></i> Back
                  </Link> */}

                  <Button  buttonText="Save" onClick={onPressSaveChange} loading={loading} />

                  {/* <button
                    type="button"
                    onClick={onPressSaveChange}
                    className="btn btn-primary text-white"
                    role="menuitem"
                  >
                    <div className="d-flex align-items-center">
                      {loading ? (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Saving...
                        </div>
                      ) : (
                        <div>
                          <i className="ti ti-device-floppy"></i> Save Changes
                        </div>
                      )}
                    </div>
                  </button> */}
                </li>
              </ul>
            </div>
          </div>

          {expendTestCards === true && <StripeTestCardNumbers />}
        </div>
      </div>
    </>
  );
};

export default StripeCredentials;
