import React, { useEffect, useState } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import { isMobile, urlSegment, allowedVideoTimeSeconds } from "../../helpers/helper";
import validateField from "../../helpers/AllValidtions";
import { PURPOSE_OF_VIDEOS, VideoSteps } from "../Onboarding/Video/AddVideo";
import Input from "../../Form/Input";
import Textarea from "../../Form/Textarea";
import Select from "../../Form/Select";
import Tabs from "../../Form/Tabs";
import UploadVideoFields from "./UploadVideoFields";
import Button from "../../Form/Button";
import StreamVideoFacebookFormFields from "./StreamVideoFacebookFormFields";
import Loading from "../Loadings/Loading";

const EditVideoNav = () => {
  const history = useHistory();
  const [videoName, setVideoName] = useState();
  const [videoDesc, setVideoDesc] = useState();
  const [videoType, setVideoType] = useState();
  const [editVideoId, setEditVideoId] = useState();
  const [progress, setProgress] = useState(0.0);
  const [wowzaId, setWowzaId] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [originalVideoData, setOriginalVideoData] = useState([]);
  const [assignedWidget, setAssignedWidget] = useState([]);
  const [videoThumbnailURL, setVideoThumbnailURL] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [publish, setPublish] = useState();
  const [videoNameError, setVideoNameError] = useState("");
  const [videoTypeError, setVideoTypeError] = useState();
  const isMobileSize = isMobile();
  const [draftVideoId, setDraftVideoId] = useState();
  const [sidebarType, setSidebarType] = useState(
    isMobileSize ? "mini-sidebar" : "full"
  );

  const [videoPreview, setVideoPreview] = useState(null);
  // const allowedVideoExt = "mp4";
  // const allowedExt = "jpeg, png and webp";
  // const allowedImageTypes = {
  //   "image/jpeg": "jpg",
  //   "image/png": "png",
  //   "image/webp": "webp",
  // };
  const [facebookCode, setFacebookCode] = useState("");
  const [facebookCodeError, setFacebookCodeError] = useState("");
  const [facebookUrl, setFacebookUrl] = useState();
  const [streamType, setStreamType] = useState("");
  const [facebookPreview, setFacebookPreview] = useState(null);
  const [width, setWidth] = useState(400);
  const [height, setHeight] = useState(400);
  const [loadingApi, setLoadingApi] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [leadForm, setLeadForm] = useState();
  const [userSubscription, setUserSubscription] = useState();
  const [maxVideoTimeAllowedInSeconds, setMaxVideoTimeAllowedInSeconds] = useState();
  const [content, setContent] = useState('');

  const handleEditorChange = (content) => {

    setContent(content);

    console.log('Content was updated:', content);
  };



  useEffect(() => {
    fetchVideoData();
  }, []);

  useEffect(() => {

    const backStateData = history?.location?.state;
    console.log('backStateData', backStateData)
    const addVideoData = backStateData?.addVideoData;
    const addFacebookData = backStateData?.addFacebookData;
    const addStreamData = backStateData?.addStreamData;
    const originalVideoData = backStateData?.originalVideoData;

    if (backStateData) {

      setOriginalVideoData(originalVideoData);
      setVideoName(addVideoData?.videoName);
      setVideoDesc(addVideoData?.videoDesc);

      setVideoThumbnailURL(addVideoData?.videoThumbnailURL);
      setVideoType(addVideoData?.videoType);
      setWowzaId(addVideoData?.wowzaId);
      setDraftVideoId(addVideoData?.videoId);

      setStreamType(addVideoData?.streamType);

      setSelectedProducts(originalVideoData?.selectedProducts);

      setFacebookCode(addFacebookData?.facebookCode);
      setFacebookUrl(addFacebookData?.facebookUrl);

      setLeadForm(originalVideoData?.parent_lead_form_id);


      // setStreamSourceType(addStreamData?.streamsourcetype);
      // setStreamkey(addStreamData?.streamkey);
      // setStreamname(addStreamData?.streamname);
      // setStreamplatform(addStreamData?.streamplatform);

      // setStreamstarttime(addStreamData?.streamstarttime);
      // setStreamendtime(addStreamData?.streamendtime);

      // setStreamurl(addStreamData?.streamurl);

    }

    // setMaxVideoTimeAllowedInSeconds(
    //   helper.allowedVideoTimeSeconds(userSubscription)
    // );
  }, [history?.location?.state]);


  useEffect(() => {
    setMaxVideoTimeAllowedInSeconds(
      allowedVideoTimeSeconds(userSubscription)
    );
  }, [userSubscription]);

  const fillVideoData = (videoData) => {
    if (videoData?.data && videoData?.data.length >= 1) {
      setOriginalVideoData(videoData.data[0]);
      setVideoName(videoData.data[0].name);
      setVideoDesc(videoData.data[0].description);
     


      setWowzaId(videoData.data[0].name);
      setEditVideoId(videoData.data[0].id);
      setVideoThumbnailURL(videoData.data[0].video_thumbnail);
      setSelectedProducts(videoData.data[0].selectedProducts);
      setAssignedWidget(videoData.data[0]?.assignedWidgets);
      setVideoType(videoData.data[0].video_type);
      setPublish(videoData.data[0].schedule_start_time);

      setLeadForm(videoData.data[0].parent_lead_form_id);


      setVideoPreview("https://www.w3schools.com/tags/movie.mp4");

      if (videoData.data[0].video_thumbnail == "false") {
        setImagePreview("");
      } else {
        setImagePreview(videoData.data[0].video_thumbnail);
      }

      setWidth(400);
      setHeight(400);

      setStreamType(videoData.data[0].stream_type);
      setFacebookCode(videoData.data[0]?.facebookvideos[0]?.code);
      setFacebookUrl(videoData.data[0]?.facebookvideos[0]?.url);
      setFacebookPreview(videoData.data[0]?.facebookvideos[0]?.url);
    }
  };

  const handleNameChange = (e) => {
    var value = e.target.value;
    var rule = "required";
    setVideoName(value);
    validateField(value, rule, setVideoNameError);
  };

  const handleTypeChange = (e) => {
    var value = e.target.value;
    var rule = "required";

    if (value == "") {
      setVideoType(value);
      validateField(value, rule, setVideoTypeError);
    } else {
      setVideoType(value);
      validateField(value, rule, setVideoTypeError);
    }
  };

  const getSubscriptionData = (e) => {
    setUserSubscription(e)
  }

  const fetchVideoData = () => {
    setLoading(true);

    const videoId = urlSegment(2);
    var token = localStorage.getItem("authSession");
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/user-videos-data", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({ videoId: videoId }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        setLoadingApi(true);

        if (!history?.location?.state) {
          fillVideoData(data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => setLoading(false));
  };

  function videoToAddProductPage() {
    const addVideoData = {
      videoName: videoName,
      videoDesc: videoDesc,
      videoType: videoType,
      wowzaId: wowzaId,
      editVideoId: editVideoId,
      videoThumbnailURL: videoThumbnailURL,
      publish: publish,
      facebookCode: facebookCode,
      leadForm: leadForm,
      facebookUrl: facebookUrl,
      streamType: streamType
    };

    history.push({
      pathname: "/videos/edit-video-purpose-nav",
      state: {
        addVideoData: addVideoData,
        originalVideoData: originalVideoData,
      },
    });

    // if(videoType == 'To generate lead'){

    //   console.log("All")

    //   if (videoName != null && wowzaId != null) {
    //     setShowErrors(false);

    //     history.push({
    //       pathname: "/videos/edit-video-nav/edit-video-lead-nav",
    //       state: {
    //         addVideoData: addVideoData,
    //         originalVideoData: originalVideoData,
    //       },
    //     });
    //   } else {
    //     setShowErrors(true);
    //   }



    // }else{


    //   if (videoName != null && wowzaId != null) {
    //     setShowErrors(false);

    //     history.push({
    //       pathname: "/edit-videos/select-nav-product",
    //       state: {
    //         addVideoData: addVideoData,
    //         originalVideoData: originalVideoData,
    //       },
    //     });
    //   } else {
    //     setShowErrors(true);
    //   }


    // }


  }

  // function handleProgressEvent({ loaded, total }) {
  //   setProgress(loaded / total);
  // }

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  var isButtonDisable = false;

  if (streamType === "embed") {
    isButtonDisable =
      !videoName ||
      !wowzaId ||
      !videoName.trim() ||
      !wowzaId.trim() ||
      !videoType ||
      !facebookCode ||
      facebookCodeError;
  } else {
    isButtonDisable =
      !videoName ||
      !wowzaId ||
      !videoName.trim() ||
      !wowzaId.trim() ||
      !videoType;
  }

  return (
    <div
      className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""
        }`}
      data-sidebartype={sidebarType}
      id="main-wrapper"
      data-theme="blue_theme"
      data-layout="vertical"
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title="Edit Video" onNavBarClick={onNavBarClick} getSubscriptionData={getSubscriptionData} formWidth={true}>
        <div className="row pb-50">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header vs-card-header">
                <h5 className="fw-bolder">Edit your shoppable video</h5>
                <VideoSteps editMode={true} step="1" purpose={videoType} />
              </div>
              <div className="card-body">
                {loading ? (
                  <div className="py-5">
                    <Loading />
                  </div>
                ) : (
                  <>
                    <div className="w-500">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <Input
                              id="title"
                              type="text"
                              label="Video Title *"
                              value={videoName}
                              onChange={handleNameChange}
                              disabled={false}
                              placeholder=""
                              errorMessage={videoNameError}
                            />
                          </div>
                          <div className="mb-3">
                            {/* <Textarea
                              id="description"
                              label="Description"
                              value={videoDesc}
                              onChange={(e) => setVideoDesc(e.target.value)}
                              disabled={false}
                              placeholder=""
                              errorMessage=""
                            /> */}

                        

                      <label className="form-label">Video Description</label>
                        <Editor
                          tinymceScriptSrc='/tinymce/tinymce.min.js'
                          apiKey={process.env.REACT_APP_TINYMCE_KEY}
                          init={{
                            promotion: false,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            // toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                            toolbar: 'undo redo | bold italic underline strikethrough  | numlist bullist | removeformat',
                            menubar: 'insert format table',
                            tinycomments_mode: 'embedded',
                            tinycomments_author: 'Author name',
                            mergetags_list: [
                              { value: 'First.Name', title: 'First Name' },
                              { value: 'Email', title: 'Email' },
                            ],
                            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject("See docs to implement AI Assistant")),
                            branding: false
                          }}
                          value={videoDesc}
                          onEditorChange={(newValue, editor) => setVideoDesc(newValue)}
                        />


                          </div>

                          {/* <div className="mb-3">
                          <Select
                            id="purpose"
                            label="Purpose of video"
                            value={videoType}
                            onChange={handleTypeChange}
                            disabled={false}
                            options={PURPOSE_OF_VIDEOS}
                            errorMessage={videoTypeError}
                          />
                        </div> */}
                        </div>
                        <div className="col-md-12">
                          <div className="video-upload-type-container">
                            <Tabs
                              tabs={[
                                {
                                  key: "upload-video",
                                  label: "Upload Video",
                                  disabled: streamType === "embed",
                                  component: (
                                    <UploadVideoFields
                                      videoThumbnailURL={videoThumbnailURL}
                                      setVideoThumbnailURL={setVideoThumbnailURL}
                                      fileUploadLoading={fileUploadLoading}
                                      setFileUploadLoading={setFileUploadLoading}
                                      maxVideoTimeAllowedInSeconds={maxVideoTimeAllowedInSeconds}
                                      setWowzaId={setWowzaId}
                                      cannotAlterVideo={true}
                                    />
                                  ),
                                },
                                {
                                  key: "record-video",
                                  label: "Record Video",
                                  disabled: true,
                                  component: <></>,
                                },
                                {
                                  key: "live-stream-from-external-source",
                                  label: "Live Stream",
                                  disabled: true,
                                  component: <></>,
                                },
                                {
                                  key: "facebook-integration",
                                  label: "Facebook Video",
                                  disabled: streamType !== "embed",
                                  component: (
                                    <StreamVideoFacebookFormFields
                                      setFacebookUrl={setFacebookUrl}
                                      facebookCode={facebookCode}
                                      setFacebookCode={setFacebookCode}
                                      videoThumbnailURL={videoThumbnailURL}
                                      setVideoThumbnailURL={setVideoThumbnailURL}
                                      fileUploadLoading={fileUploadLoading}
                                      setFileUploadLoading={setFileUploadLoading}
                                    />
                                  ),
                                },
                              ]}
                              onChange={() => { }}
                              selectedTab={
                                streamType === "embed"
                                  ? "facebook-integration"
                                  : "upload-video"
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="vs-form-footer d-flex justify-content-center align-items-center">
                      <Button
                        type="button"
                        onClick={videoToAddProductPage}
                        buttonText="Next"
                        fullButton={false}
                        loading={false}
                        disabled={isButtonDisable}
                      />
                    </div> */}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </Page>
      {/* sticky footer */}
      <div className="sticky-footer ">
          <div className="footer-container">
            <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
              <Button
                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                type="button"
                buttonText="Back"
                onClick={() => history.push("/videos")}
              />
              <Button
                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                type="button"
                onClick={videoToAddProductPage}
                buttonText="Next"
                fullButton={false}
                loading={false}
                disabled={isButtonDisable}
              />
            </div>
          </div>
        </div>
      {/* sticky footer */}
    </div>
  );
}
export default EditVideoNav;
