import React, { useEffect, useState } from "react";
import {
  // SidebarVideoSquirrelLogo,
  SidebarGroup,
  SidebarItem,
} from "./";
import { useResponsive } from "react-hooks-responsive";
import { useHistory } from "react-router-dom";
// import styles from "./Sidebar.module.scss";
// import useTutorialsState from "../../hooks/useTutorialsState";
// import useAuthState from "../../hooks/useAuthState";
import {userLoggedAccessToken,showUserTypeBaseMessage,urlSegment,isTypeNullOrNotFilled, isOnboardCompleted} from "../../helpers/helper"; 
import {useUserSubscribedDataContext} from "../../context/UserSubscribedDataContext";
import DashboardIcon from "./../../../assets/icons/dashboard.svg"
import VideoIcon from "./../../../assets/icons/video.svg"
import ProductIcon from "./../../../assets/icons/products.svg"
import LeadIcon from "./../../../assets/icons/lead.svg"
import OrderIcon from "./../../../assets/icons/order.svg"
import SettingIcon from "./../../../assets/icons/setting.svg"
import DollarSignIcon from "./../../../assets/icons/dollar-sign.svg"


function Sidebar({ onNavBarClick }) {
  // const videoData = localStorage.getItem("isOnBoardingComplete");
  // console.log(videoData,"videoDatavideoDatavideoData")
 
  // const [disableVideoCheckoutLink, setDisableVideoCheckoutLinksetVideoData] = useState(true);
  const [videoCheckoutToolTip, setVideoCheckoutToolTip] = useState('Available for paid users!');
  
  const { contextSubscribedUserData } = useUserSubscribedDataContext();



  const [allowuser, setAllowuser] = useState(true);
  

  useEffect(() => {
    // UserAllowwedData();

    var token = localStorage.getItem("authSession");
    token = JSON.parse(token);

    var usr=token.restrictedbyCountry
    setAllowuser(usr)

  },[]);

  // const UserAllowwedData = async () => {

    //  var usr=localStorage.getItem("restrictedbyCountry");

    // setAllowuser(localStorage.getItem("restrictedbyCountry"))
    
    // fetch(process.env.REACT_APP_API_URL + "/user-allowed-countries", {
    //   method: "POST",
    //   mode: "cors",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + userLoggedAccessToken(),
    //   },
    // })
    //   .then((response) => response.json())
    //   .then((response) => {
    //     if (response?.status === true) {


    //       setAllowuser(response?.restrictedbyCountry)
       
    //     } else {
         
    //     }
    //   })
    //   .catch((error) => {

    //     console.error("Error fetching data:", error);
    //   })
    //   .finally((error) => {
       
    //   });
  // };



  useEffect(() => {
    // const orderCommissionFailedCount = data?.data?.order_commission_failed_count;
    if(contextSubscribedUserData?.plan_type==="paid" && contextSubscribedUserData?.order_commission_failed_count<=0){
      // setDisableVideoCheckoutLinksetVideoData(false);
      setVideoCheckoutToolTip('Manage your video checkout and payment getways');
    }
    // setDisableVideoCheckoutLinksetVideoData
    // console.log('contextSubscribedUserData',contextSubscribedUserData)
  },[contextSubscribedUserData]);


  // const { logout } = useAuthState();
  const history = useHistory();
  const { size } = useResponsive({ xs: 0, sm: 480, md: 1200 });
  const collapsed = size !== "md";
  if (
    window.location.pathname === "/login" ||
    window.location.pathname === "/forgotPassword" ||
    window.location.pathname === "/influencer/login" ||
    window.location.pathname === "/influencer/register" ||
    window.location.pathname.startsWith("/register") ||
    window.location.pathname.startsWith("/share") ||
    window.location.pathname.startsWith("/brand")
  )
    return null;

  const windowLocation = window.location.pathname.split("/").filter((l) => !!l)[0];

  const integrationToWebText = showUserTypeBaseMessage(
    {
      'Brand': 'Embed Videos to Web', 
      'default':'Embed Videos to Web',
      'NonProfit':'Embed Videos to Web',
      'Influencer':'Embed Videos to Web',
      'Individual':'Embed Videos to Web',
    }
  );

  const profileSettingsSubMenu = [
    { link: 'account-setting', icon: 'ti ti-circle', name: 'Account' },
    { link: 'subscription', icon: 'ti ti-circle', name: 'Subscription' },
    { link: 'integrations', icon: 'ti ti-circle', name: 'Integrations' },
    { link: 'in-video-checkout', icon: 'ti ti-circle', name: 'In Video Checkout', toolTip:videoCheckoutToolTip },
    // { link: 'lead-form-report', icon: 'ti ti-circle', name: 'Leads Forms' },
    // { link: 'add-lead-form', icon: 'ti ti-circle', name: 'Setup Lead Forma' },
    // { link: 'invite-friends', icon: 'ti ti-circle', name: 'Invite Friends' },
    // { link: 'woocommerce-setup', icon: 'ti ti-circle', name: 'Woo-Comm Integration'},
    // { link: 'profile', icon: 'ti ti-circle', name: 'Profile Settings' },
    // { link: 'brand-information', icon: 'ti ti-circle', name: 'Brand Information' },
    // { link: 'theme', icon: 'ti ti-circle', name: 'Theme' },
  ];


  const filteredProfileSettingsSubMenu = profileSettingsSubMenu.filter(item => {
    if (!allowuser && (item.link === 'subscription'|| item.link === 'in-video-checkout')) {
      return false;
    }
    return true;
  });

  

  const videoSubMenu = [
    { link: '', icon: 'ti ti-circle', name: 'Manage Videos' },
    { link: 'integration-on-web', icon: 'ti ti-circle', name: integrationToWebText },
  ];

  const OrdersSubMenu= [
    { link: '', icon: 'ti ti-circle', name: 'Payment based Orders' },
    { link: 'customer-information', icon: 'ti ti-circle', name: 'Info based Orders' },
  ];


  const ordermenu = OrdersSubMenu.filter(item => {
    if (!allowuser && item.name === 'Payment based Orders') {
      return false;
    }
    return true;
  });


  if(isTypeNullOrNotFilled()){
    const redictToAccountTypeURL = 'select-account-type';
    const firstSegment = urlSegment(0); 
    if(firstSegment!==redictToAccountTypeURL && firstSegment!==null){
      history.push("/"+redictToAccountTypeURL);
    }
  }


  const textPlural = showUserTypeBaseMessage({ NonProfit: "Donation Campaigns", default: "Products" });
    // const textSignular = showUserTypeBaseMessage({NonProfit:"Donation Campaign",default:"Product"});

  return (


    <SidebarGroup onNavBarClick={onNavBarClick}>
          <div style={{marginTop: 56}}></div>
          <SidebarItem
            key="dashboard"
            active={windowLocation === "dashboard" || windowLocation === "stats" || windowLocation === "stats"}
            icon={DashboardIcon}
            label="Dashboard"
            collapsed={collapsed}
            onClick={() => {
              history.push("/dashboard");
            }}
            parentMenuLink="dashboard"
            // isOnBoardingComplete = {isOnboardCompleted()}
            isOnBoardingComplete = {true}
            isAccountTypeNotSelected = {isTypeNullOrNotFilled()}
          />

        <>
          <SidebarItem
            key="videos"
            active={windowLocation === "videos"}
            icon={VideoIcon}
            label="Videos"
            childMenu={videoSubMenu}
            isSubMenu={true}
            collapsed={collapsed}
            onClick={() => history.push("/videos")}
            parentMenuLink="videos"
            // isOnBoardingComplete = {videoData == undefined ? false : videoData}
            isOnBoardingComplete = {isOnboardCompleted()}
            isAccountTypeNotSelected = {isTypeNullOrNotFilled()}

          />

          {/* <SidebarItem
            active={windowLocation === "integration-on-web"}
            icon="ti ti-plug-connected"
            label="Integraiton to Web"
            collapsed={collapsed}
            onClick={() => history.push("/integration-on-web")}
            isOnBoardingComplete = {videoData == undefined ? true : videoData}
          /> */}

          <SidebarItem
            key="products"
            active={windowLocation === "products"}
            icon={ProductIcon}
            label={textPlural}
            collapsed={collapsed}
            onClick={() => {
              history.push("/products");
            }}
            parentMenuLink="products"
            // isOnBoardingComplete = {videoData == undefined ? false : videoData}
            isOnBoardingComplete = {isOnboardCompleted()}
            isAccountTypeNotSelected = {isTypeNullOrNotFilled()}

          />

        {/* <SidebarItem
            active={windowLocation === "success-checkout"}
            icon="ti ti-basket"
            label="success-checkout"
            collapsed={collapsed}
            onClick={() => {
              history.push("/success-checkout");
            }}
          /> */}

          <SidebarItem
            key="lead-form-report"
            active={windowLocation === "leads" || windowLocation === "lead-form-report" || windowLocation === "add-lead"  || windowLocation === "edit-lead"  || windowLocation === "add-lead-form"}
            icon={LeadIcon}
            label="Leads"
            collapsed={collapsed}
            onClick={() => {
              history.push("/lead-form-report");
            }}
            parentMenuLink="lead-form-report"
            // isOnBoardingComplete = {videoData == undefined ? false : videoData}
            isOnBoardingComplete = {isOnboardCompleted()}
            isAccountTypeNotSelected = {isTypeNullOrNotFilled()}

          />

        <SidebarItem
          key="donations"
          active={windowLocation === "donations"}
          icon={DollarSignIcon}
          label="Donations"
          collapsed={collapsed}
          onClick={() => history.push("/donations")}
          parentMenuLink="donations"
          isOnBoardingComplete={isOnboardCompleted()}
          isAccountTypeNotSelected={isTypeNullOrNotFilled()}
        />


          {/* <SidebarItem
            key="orders"
            active={windowLocation === "orders" || windowLocation === "edit-order"}
            icon={OrderIcon}
            label="Orders"
            collapsed={collapsed}
            onClick={() => {
              history.push("/orders");
            }}
            parentMenuLink="orders"
            // isOnBoardingComplete = {videoData == undefined ? false : videoData}
            isOnBoardingComplete = {isOnboardCompleted()}
            isAccountTypeNotSelected = {isTypeNullOrNotFilled()}

          />

<SidebarItem
            key="customer-info"
            active={windowLocation === "customer-info" || windowLocation === "edit-order"}
            icon={OrderIcon}
            label="Customer info"
            collapsed={collapsed}
            onClick={() => {
              history.push("/customer-info");
            }}
            parentMenuLink="customer-info"
            // isOnBoardingComplete = {videoData == undefined ? false : videoData}
            isOnBoardingComplete = {isOnboardCompleted()}
            isAccountTypeNotSelected = {isTypeNullOrNotFilled()}

          /> */}


<SidebarItem
            key="orders"
            active={windowLocation === "orders"}
            icon={OrderIcon}
            label="Orders"
            childMenu={ordermenu}
            isSubMenu={true}
            collapsed={collapsed}
            onClick={() => history.push("/orders")}
            parentMenuLink="orders"
            // isOnBoardingComplete = {videoData == undefined ? false : videoData}
            isOnBoardingComplete = {isOnboardCompleted()}
            isAccountTypeNotSelected = {isTypeNullOrNotFilled()}

          />



        </>
      <SidebarItem
        key="settings"
        active={windowLocation === "settings"}
        icon={SettingIcon}
        label="Settings"
        collapsed={collapsed}
        childMenu={filteredProfileSettingsSubMenu}
        onClick={() => {
          history.push("/settings/profile");
        }}
        isSubMenu={true}
        parentMenuLink="settings"
        // disableShopify= {videoData == undefined ? false : videoData}
        // disableShopify= {true}
        // disableShopify= {false}
        isOnBoardingComplete = {isOnboardCompleted()}
        isAccountTypeNotSelected = {isTypeNullOrNotFilled()}

      />

    </SidebarGroup>


    // <SidebarProfileFooter collapsed={collapsed} />

  );
}

export default Sidebar;
