import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";
// import "./StylingProductList.module.scss";
import ActivityLog from "../../helpers/ActivityLog";
import * as helper from "../../helpers/helper";
import ExpandableProductDetails from "./ExpandableProductDetails";
import Button from "../../Form/Button";
import Table from "../../Table/Table";
import ModalContainer from "../../Table/ModalContainer";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";
import FilterDropDown from "../../Form/FilterDropDown";
import AddDataPrompt from "../../molecules/DataPrompt/AddingDataPrompt";

const ProductList = () => {
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [videodata, setVideotdata] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [errors, setErrors] = useState("");
  const [success, setSuccess] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsperPage, setrRowsperPage] = useState(10);
  const [searchValue, setSearchValue] = useState("");
  const [currentRow, setCurrentRow] = useState(null);
  const [showActivatePopup, setShowActivatePopup] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [importToggleBtn, setImportToggleBtn] = useState(false);

  const [filterOptionsValues, setFilterOptionsValues] = useState({});

  const filterOptions = {
    "Source": {
      "label": "Source",
      "children": [
        {
          "label": "WOO COMMERCE",
          "value": "WOO COMMERCE"
        },
        {
          "label": "SHOPIFY",
          "value": "SHOPIFY"
        },
        {
          "label": "PORTAL",
          "value": "PORTAL"
        }
      ]
    },
    "Variant": {
      "label": "Variant",
      "children": [
        {
          "label": "Has Variant",
          "value": "Has Variant"
        },
        {
          "label": "Has No Variant",
          "value": "Has No Variant"
        }
      ]
    }
  };

  useEffect(() => {
    fetchVideosData();
  }, [currentPage, rowsperPage, searchValue,filterOptionsValues]);

  const fetchVideosData = () => {
    var token = localStorage.getItem("authSession");
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/all-products", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({
        id: token.idToken,
        currentPage: currentPage,
        rowsPage: rowsperPage,
        search: searchValue,
        filterOptionsValues:filterOptionsValues
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setVideotdata(data.data);
        setTotalRows(data.total);
        setCurrentPage(data.currentPage);
        setLoader(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const fetchDeleteData = (id) => {
    var token = localStorage.getItem("authSession");
    token = JSON.parse(token);
    fetch(process.env.REACT_APP_API_URL + "/delete-product", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({ id: id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === "200") {
          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem("admin_login_id");
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 10;
          var account_id = token.idToken;
          userLog.createaAtivitylog({
            user_id,
            is_admin,
            event_id,
            account_id,
          });
          setSuccess("Product deleted successfully");
          setSelectedOrderId(null);
          fetchVideosData();
          setLoader(true);
          setTimeout(resetState, 2000);
        } else {
          setErrors("Something went wrong");
          setTimeout(resetState, 2000);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setErrors("Something went wrong");
        setTimeout(resetState, 2000);
      });
  };

  const resetState = () => {
    setErrors("");
    setSuccess("");
  };

  const titleHeadingText = helper.showUserTypeBaseMessage({
    NonProfit: "Donation Campaign",
    default: "Product",
  });

  const ActivatePopup = ({ onClose, onActivate }) => {
    return (
      <div
        className="modal fade show"
        id="al-danger-alert"
        tabindex="-1"
        aria-labelledby="vertical-center-modal"
        style={{ display: "block", " padding-left": "0px" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content modal-filled">
            <div className="modal-body p-4">
              <div className="text-center text-danger">
                <i className="ti ti-x fs-7" onClick={onClose}></i>
                <h4 className="mt-2"> Are You sure? </h4>
                <p className="mt-3"> You want to activate the video? </p>
                <button
                  type="button"
                  className="btn btn-light my-2 btn-cnl"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-light my-2 btn-dlt"
                  data-bs-dismiss="modal"
                  onClick={onActivate}
                >
                  {" "}
                  Activate{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const Popup = ({ onClose, onDelete }) => {
    return (
      <div
        className="modal fade show"
        id="al-danger-alert"
        tabindex="-1"
        aria-labelledby="vertical-center-modal"
        style={{ display: "block", " padding-left": "0px" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog modal-sm">
          <div className="modal-content modal-filled bg-light-danger">
            <div className="modal-body p-4">
              <div className="text-center text-danger">
                <i className="ti ti-x fs-7" onClick={onClose}></i>
                <h4 className="mt-2">Are You sure?</h4>
                <p className="mt-3">You Want to delete Permanently?</p>
                <button
                  type="button"
                  className="btn btn-light my-2 btn-cnl"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  {" "}
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-light my-2 btn-dlt"
                  data-bs-dismiss="modal"
                  onClick={onDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleDeleteClick = (id) => {
    setSelectedOrderId(id);
    setShowPopup(true);
  };

  const handleEditClick = (row) => {
    history.push("/products/edit-nav-product/" + row?.id)
  };

  const handleDelete = () => {
    fetchDeleteData(selectedOrderId);
    setShowPopup(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleActivateClick = (id) => {
    setSelectedOrderId(id);
    setShowActivatePopup(true);
  };

  const handleActivate = () => {
    fetchActivateProduct(selectedOrderId);
    setShowActivatePopup(false);
  };

  const handleCloseActivatePopup = () => {
    setShowActivatePopup(false);
  };

  const fetchActivateProduct = (id) => {
    var token = localStorage.getItem("authSession");
    token = JSON.parse(token);

    fetch(process.env.REACT_APP_API_URL + "/publish-product", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token.accessToken,
      },
      body: JSON.stringify({ id: id }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === "200") {
          const userLog = new ActivityLog();
          const admin_login_id = localStorage.getItem("admin_login_id");
          var user_id = admin_login_id ? admin_login_id : token.idToken;
          var is_admin = admin_login_id ? 1 : 0;
          var event_id = 10;
          var account_id = token.idToken;
          userLog.createaAtivitylog({
            user_id,
            is_admin,
            event_id,
            account_id,
          });
          setSuccess("Product deleted successfully");
          setSelectedOrderId(null);
          fetchVideosData();
          setLoader(true);
          setTimeout(resetState, 2000);
        } else {
          setErrors("Something went wrong");
          setTimeout(resetState, 2000);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setErrors("Something went wrong");
        setTimeout(resetState, 2000);
      });
  };

  // const columns = [
  //   {
  //     name: titleHeadingText,
  //     selector: (row, i) => (
  //       <div className="d-flex flex-row align-items-center gap-2">
  //         <img
  //           src={row.image}
  //           className="rounded-2 border border-2 border-white"
  //           width={51}
  //           height={51}
  //           alt={row.name}
  //         />
  //         <div className="d-flex flex-column gap-1">
  //           <span className="fw-semibold text-black fs-4">{row.name}</span>
  //           {row.has_variants === true && <span>{row.variant_count} variants</span>}
  //         </div>
  //       </div>
  //     ),
  //     sortable: true,
  //     width: "560px",
  //   },
  //   {
  //     name: "Source",
  //     selector: (row, i) => (
  //       <div className="d-flex flex-row align-items-center gap-2">
  //         <span className="fw-semibold text-black fs-3">
  //           {row.product_added_from}
  //         </span>
  //       </div>
  //     ),
  //     sortable: true,
  //     center: true,
  //     width: "160px",
  //   },
  //   {
  //     name: "Cart Enabled",
  //     selector: (row, i) => (
  //       <div className="d-flex flex-row align-items-center gap-2">
  //         <span className="fw-semibold text-black fs-3">
  //           {row.is_add_cart_enabled}
  //         </span>
  //       </div>
  //     ),
  //     sortable: true,
  //     center: true,
  //     width: "160px",
  //   },
  //   {
  //     name: "Date",
  //     selector: (row, i) => (
  //       <div className="d-flex flex-row align-items-center gap-2">
  //         <span className="fw-semibold text-black fs-3">{row.created_at}</span>
  //       </div>
  //     ),
  //     sortable: true,
  //     center: true,
  //     width: "200px",
  //   },
  // ];

  const columns = [
    {
      name: titleHeadingText,
      selector: (row) => row.name,
      sortable: true,
      width: "560px",
      cell: (row) => (
        <div className="d-flex flex-row align-items-center gap-2">
          <img
            src={row.image}
            className="rounded-2 border border-2 border-white"
            width={38}
            height={51}
            alt={row.name}
          />
          <div className="d-flex flex-column gap-1">
            <span className="fw-semibold text-black fs-4">{row.name}</span>
            {row.has_variants && <span>{row.variant_count} variants</span>}
          </div>
        </div>
      ),
    },
    {
      name: "Source",
      selector: (row) => row.product_added_from,
      sortable: true,
      center: true,
      width: "160px",
      cell: (row) => (
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="fw-semibold text-black fs-3">
            {row.product_added_from}
          </span>
        </div>
      ),
    },
    {
      name: "Cart Enabled",
      selector: (row) => row.is_add_cart_enabled,
      sortable: true,
      center: true,
      width: "160px",
      cell: (row) => (
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="fw-semibold text-black fs-3">
            {row.is_add_cart_enabled}
          </span>
        </div>
      ),
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      center: true,
      width: "200px",
      cell: (row) => (
        <div className="d-flex flex-row align-items-center gap-2">
          <span className="fw-semibold text-black fs-3">{row.created_at}</span>
        </div>
      ),
    },
  ];

  const data = videodata;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setCurrentPage(page);
    setrRowsperPage(newPerPage);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
    setLoader(false);
  };

  const handleOpenModal = (row) => {
    setCurrentRow(row);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };



  if (loader && videodata && !videodata.length && !searchValue && !filterOptions ) {

    // if (addprompt === true) {
      return (
        <div className="data-promptWrapper">
          <AddDataPrompt   
              heading="Create Product" 
              title="Add products to start receiving orders seamlessly." 
              link="products/add-nav-product" 
              btntxt="Create Products"/>
        </div>
      );
    // }

  }

  return (
    <div className="App video-table vs-table">
      {showPopup && (<Popup onClose={handleClosePopup} onDelete={handleDelete} />)}
      {showActivatePopup && (<ActivatePopup onClose={handleCloseActivatePopup} onActivate={handleActivate} />)}
      {errors && (<FormToast type={FORM_TOAST_TYPES.DANGER} message={errors} />)}
      {success && (<FormToast type={FORM_TOAST_TYPES.SUCCESS} message={success} />)}

      {/* <div className="row">
        <h3 className="fw-bolder">Products</h3>
      </div> */}

      {/* videos-listing-action-btns action-row */}

      {/* <div className="d-flex justify-content-between align-items-center mb-3 mt-3 action-row"> */}
      <div className="videos-listing-action-btns action-row">

        <form className="">
        <div className="position-relative action-search">
          <input
            type="text"
            className="form-control search-chat py-2 ps-5 d-lg-inline"
            value={searchValue}
            placeholder="Search "
            onChange={handleSearchChange}
          />
          <i className="ti ti-search position-absolute top-50 start-0 translate-middle-y fs-6 text-dark ms-3"></i>
          </div>
          <FilterDropDown callBack={setFilterOptionsValues} filterOptions={filterOptions} />

        </form>
        <div className="action-buttons">
          <Button
            className="btn btn-outline-primary"
            type="button"
            buttonText="<div class='fs-4'><span>Import </span> <i class='ti ti-chevron-down'></i></div>"
            onClick={() => setImportToggleBtn(e => !e)}
          />
          <ul className={`dropdown-menu z-1000 ${importToggleBtn === true ? 'show' : ''}`}>
            <li>
              <a
                href="#!"
                className="dropdown-item d-flex align-items-center gap-3"
                title=""
                onClick={() => {
                  setImportToggleBtn(e => !e)

                  return history.push({ pathname: "/settings/integrations" })
                }}
              >
                <span>Shopify</span>
              </a>
            </li>
            <li>
              <a
                href="#!"
                className="dropdown-item d-flex align-items-center gap-3"
                title=""
                onClick={() => {
                  setImportToggleBtn(e => !e)

                  return history.push({ pathname: "/settings/integrations" })
                }}
              >
                <span>Woocommerce</span>
              </a>
            </li>
          </ul>
          <Button
            className="btn btn-primary ml-10"
            type="button"
            buttonText="Add Product"
            onClick={() =>
              history.push({
                pathname: "/products/add-nav-product",
                state: { source: "" },
              })
            }
          />
        </div>
      </div>

      <div>
        <Table
          loading={!loader}
          columns={columns}
          data={data}
          totalRows={totalRows}
          currentPage={currentPage}
          currentRow={currentRow}
          handlePerRowsChange={handlePerRowsChange}
          handlePageChange={handlePageChange}
          onRowClicked={handleOpenModal}
          actions={[
            (row) => ({
              disabled: ["SHOPIFY", "WOO COMMERCE"].indexOf(row.product_added_from) !== -1,
              icon: <i className="fs-4 ti ti-edit"></i>,
              label: "Edit",
              title: ["SHOPIFY", "WOO COMMERCE"].indexOf(row.product_added_from) !== -1 ? "Synced product cannot be edited" : "",
              onClick: handleEditClick,
            }),
            (row) => ({
              disabled: false,
              icon:
                row.is_deleted === true ? (
                  <i className="fs-4 ti ti-checks"></i>
                ) : (
                  <i className="fs-4 ti ti-trash"></i>
                ),
              label: row.is_deleted === true ? "Publish" : "Delete",
              title: row.is_deleted === true ? "Publish" : "Delete",
              onClick: (row) => row.is_deleted === true ? handleActivateClick(row?.id) : handleDeleteClick(row?.id),
            }),
          ]}
        />
        <ModalContainer
          children={<ExpandableProductDetails row={currentRow} />}
          isOpen={openModal}
          closable={false}
          handleCloseModal={() => handleCloseModal()}
          width="500"
        />
      </div>
    </div>
  );
}

export default ProductList;
