import React, { useState } from "react";
import { Page } from "../../layouts/Page";
import DonationList from "./DonationList";
import { Sidebar } from "../../../component/molecules/Sidebar";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import PageHeading from "./../../Form/PageHeading";

function Donations() {
    const isMobileSize = isMobile();
    const [sort, setSort] = useState({ field: "id", direction: "desc" });
    const [sidebarType, setSidebarType] = useState(isMobileSize ? "mini-sidebar" : "full");

    function onSortPress(field, direction) {
        setSort({ field, direction });
    }

    const onNavBarClick = () => {
        setSidebarType((prevSidebarType) => prevSidebarType === "full" ? "mini-sidebar" : "full");
    };

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page title="Your Donations" flex onNavBarClick={onNavBarClick}>
            <PageHeading text="Donations"/>
                <DonationList onSortPress={onSortPress} sort={sort} />
            </Page>
        </div>
    )
}

export default Donations;