import { useEffect, useRef, useState } from "react";
import UploadThumbnailAddVideo from "./UploadThumbnailAddVideo";
import UploadHelper from "../../helpers/upload";
import VideoPreviewAddVideo from "./VideoPreviewAddVideo";
import Loading from "../Loadings/Loading";

const ALLOWED_EXTENSIONS = ".mp4";

const UploadVideoFields = ({
  videoThumbnailURL,
  setVideoThumbnailURL,
  fileUploadLoading,
  setFileUploadLoading,
  maxVideoTimeAllowedInSeconds,
  setWowzaId,
  cannotAlterVideo,
  wowzaId
}) => {
  const uploadFileRef = useRef(null);
  // const [progress, setProgress] = useState(0.0);
  const [videoPreview, setVideoPreview] = useState(null);
  const [videoError, setVideoError] = useState("");
  const [videoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    if (cannotAlterVideo) {
      setVideoError("Once Published, You cannot alter the video.")
    }
  }, [cannotAlterVideo])


  useEffect(() => {
    // if (cannotAlterVideo) {
    //   setVideoError("Once Published, You cannot alter the video.")
    // }
    if (!videoPreview && wowzaId?.length) {
      setVideoPreview(`${process.env.REACT_APP_VIDEO_URL}${wowzaId}`);
    }


  }, [wowzaId])

  // wowzaId

  // function handleProgressEvent({ loaded, total }) {
  //   setProgress(loaded / total);
  // }

  const validateVideoDuration = async (file) => {
    setVideoError("");

    if (file) {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.src = URL.createObjectURL(file);

        video.onloadedmetadata = () => {
          const durationInSeconds = Math.floor(video.duration);
          if (durationInSeconds > maxVideoTimeAllowedInSeconds) {
            setVideoError(
              "The maximum video duration should not exceed " +
              maxVideoTimeAllowedInSeconds / 60 +
              " minutes"
            );
            setVideoPreview(null);
            resolve(false);
          } else {
            resolve(true);
          }
        };
      });
    }

    return false;
  };

  const clearStates = () => {
    setVideoPreview(null);
    setWowzaId(null);
    setVideoError("");
  };

  async function uploadVideoToServer(videoFile, isRecordedVideo = false) {
    const isVideoTimeValid = await validateVideoDuration(videoFile);

    if (videoFile.type !== "video/mp4") {
      setVideoError("Please upload video in MP4 format");
      setVideoPreview(null);
    }

    if (isVideoTimeValid) {
      clearStates();
      setVideoLoading(true);

      const blobUrl = URL.createObjectURL(
        new Blob([videoFile], { type: videoFile.type })
      );

      const uploadedVideoRes = await UploadHelper.getSignedUrl(
        UploadHelper.VIDEO
      );

      if (uploadedVideoRes) {

        await Promise.all([
          UploadHelper.uploadToS3(
            uploadedVideoRes.signedUrl,
            videoFile,
            // handleProgressEvent
          ),
          UploadHelper.uploadToFDCServer(
            videoFile,
            uploadedVideoRes.key,
            (e) => { }
          )
        ]);

        setVideoLoading(false);
        setWowzaId(uploadedVideoRes.key);
        setVideoPreview(blobUrl);
      }
    }

    uploadFileRef.current.value = '';
  }

  return (
    <div className="p-3 py-4">
      <h5 className="fw-bolder mb-1">Upload Video Thumbnail</h5>
      <p className="fs-4">File size should not be more than 2MB. JPG, JPEG, PNG & WEBP supported</p>
      <div className="d-flex flex-row justify-content-center gap-8 mt-2 mb-2">
        <UploadThumbnailAddVideo
          videoThumbnailURL={videoThumbnailURL}
          setVideoThumbnailURL={setVideoThumbnailURL}
          fileUploadLoading={fileUploadLoading}
          setFileUploadLoading={setFileUploadLoading}
        />
      </div>
      <h5 className="fw-bolder mb-1">Upload Video *</h5>
      <p className="fs-4">Max video length should be {maxVideoTimeAllowedInSeconds/60} mins, only MP4 supported</p>
      <div class="video-recorder-area d-flex justify-content-center mb-4">
        <div class="d-flex justify-content-center align-items-center">
          <div class="row">
            <div class="min-height-300 col-12 d-flex flex-column justify-content-center align-items-center">
              {videoLoading ? (
                <Loading />
              ) : (
                <>
                  {videoPreview ? (
                    <VideoPreviewAddVideo
                      src={videoPreview}
                      removePreview={clearStates}
                    />
                  ) : (
                    <>
                      <button
                        class={`btn btn-primary p-3 rounded-circle d-flex align-items-center justify-content-center ${cannotAlterVideo ? 'gray-disabled-tag' : ''}`}
                        type="button"
                        onClick={() => uploadFileRef.current.click()}
                      >
                        <i class="ti ti-upload fs-8"></i>
                      </button>
                      {videoError ? (
                        <div class="col-12 d-flex flex-column justify-content-center mt-2">
                          <span className="fs-4 text-danger">{videoError}</span>
                        </div>
                      ) : (
                        <div class="col-12 d-flex flex-column justify-content-center mt-2">
                          <span className="fs-4">No preview available.</span>
                          <span className="fs-4">Click to upload video</span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}

              <input
                type="file"
                className="d-none"
                accept={ALLOWED_EXTENSIONS}
                ref={uploadFileRef}
                onChange={(firedEvent) =>
                  uploadVideoToServer(firedEvent.target.files[0])
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadVideoFields;
