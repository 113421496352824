import React, { useState } from "react";
import { Page } from "../../layouts/Page";
import { ProductList } from "../../molecules/ProductList";
import { Sidebar } from "../../../component/molecules/Sidebar";
import * as helper from "../../helpers/helper";
import PageHeading from "./../../Form/PageHeading";
import { TableContainer } from "../Videos/Videos";

const Products = () => {
  const isMobileSize = helper.isMobile();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? "mini-sidebar" : "full");

  const onNavBarClick = () => {
    setSidebarType((prevSidebarType) =>
      prevSidebarType === "full" ? "mini-sidebar" : "full"
    );
  };

  const titleHeadingText = helper.showUserTypeBaseMessage({
    NonProfit: "Donation Campaigns",
    default: "Products",
  });

  return (
    <div
      className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
      id="main-wrapper"
      data-layout="vertical"
      data-sidebartype={sidebarType}
      data-sidebar-position="fixed"
      data-header-position="fixed"
    >
      <Sidebar onNavBarClick={onNavBarClick} />
      <Page title={"Your " + titleHeadingText} onNavBarClick={onNavBarClick}>
        <PageHeading text="Products" />
        <TableContainer>
          <ProductList />
        </TableContainer>
      </Page>
    </div>
  );
}

export default Products;
