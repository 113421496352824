import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import SpinnerImage from "../../assets/spinner.png";
import AuthCustom from "../helpers/AuthCustom";
import AuthContext, { defaultAuthState } from "../context/UserContext";
import * as helper from '../helpers/helper';
import ActivityLog from "../helpers/ActivityLog";


function Auth({ children }) {
  const authCustom = new AuthCustom();
  const history = useHistory();
  const [authState, setAuthState] = useState(defaultAuthState);
  

  const login = async (email, password, source, alias) => {
    try {
      const result = await authCustom.login(email, password, source);
      if(result?.is_not_otp_verified===true){
        localStorage.setItem('otp-email', email);
        history.push('/OTP-Verification?send_otp=true');
      }
      if (result.error === "invalid_grant") {

        throw new Error("invalid_credentials");
      }
      if (result.error === "old_user") {

        throw new Error("old_user");
      }
      const user = await authCustom.getUserInfo(result.accessToken, result.idToken);
      window.localStorage.setItem("authSession", JSON.stringify(result));
      localStorage.removeItem('is_onboarding_complete');


      const userLog = new ActivityLog();
      var user_id  = result.idToken;
      var is_admin =0;
      var event_id =1;
      var account_id =result.idToken;
      const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })



      setAuthState({
        isLoggedIn: true,
        loading: false,
        user,
        token: result.accessToken,
        logout,
        login
      });



      if (!alias) {
        
        
      } else {
        
        
      }
      

    } catch (err) {
      if (err.code === "invalid_grant") {
        throw new Error("invalid_credentials");
      } else {
        throw err;
      }
    }
  };


  const adminUserlogin = async (verificationcode, alias) => {
    try {
      const result = await authCustom.adminUserlogininfo(verificationcode);
      if (result.error === "invalid_grant") {
        throw new Error("invalid_credentials");
        
      }

      console.log(result.adminData,"adminnnnnn data")

      window.localStorage.setItem("admin_login_id",JSON.stringify(result.adminData.id));
      const user = await authCustom.getUserInfo(result.accessToken, result.idToken);
      window.localStorage.setItem("authSession", JSON.stringify(result));
      localStorage.removeItem('is_onboarding_complete');


      const userLog = new ActivityLog();
      const admin_login_id = localStorage.getItem('admin_login_id');
      var user_id  = admin_login_id;
      var is_admin =1;
      var event_id =1;
      var account_id =result.idToken;
      const userLogresult = userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

  
      setAuthState({
        isLoggedIn: true,
        loading: false,
        user,
        token: result.accessToken,
        logout,
        login
      });

      if (!alias) {
        
        
      } else {
        
        
      }
      

    } catch (err) {
      if (err.code === "invalid_grant") {
        throw new Error("invalid_credentials");
      } else {
        throw err;
      }
    }
  };


  const loginByOTP = async (OTPCode,OTPUrlCode,alias) => {
    try {
      const verifyOTP = await fetch(process.env.REACT_APP_API_URL + '/user-verify-otp', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "otp": OTPCode,
          "urlcode": OTPUrlCode,
          // "email": localStorage.getItem('otp-email')

        })
      }).then(response => {
        if (response.ok) {
          response.json().then(async json => {
            // console.log('json?.code==200 && json?.accessToken!=null',json?.code==200 && json?.accessToken!=null);
            if( json?.accessToken!=null){
              const authSessionData = json;
              const user = authSessionData.user; 
              delete authSessionData.user;
              window.localStorage.setItem("authSession", JSON.stringify(authSessionData));
              localStorage.removeItem('is_onboarding_complete');
              setAuthState({
                isLoggedIn: true,
                loading: false,
                user,
                token: json.accessToken,
                logout,
                login
              });


              if (!alias) {
                
                
              } else {
                
                
              }
              

              // await login(json.data.email, "12345","OTPLogin");         
              history.replace("/");
              // loginByOTP(json.accessToken,json.data);
            } else {
              //

              history.replace("/OTP-Verification/"+OTPCode);

            }
            // if(json.code=="200"){
            //     setSuccess("Lead Updated succesfully")
            //     history.push("/orders")
            //     setloaderSubmit(false)
            // }else{
            //     history.push("/edit-lead")
            //     setloaderSubmit(false)
            // }
          });
        } else {
          history.replace("/OTP-Verification/"+OTPCode);
        }
      }).
      catch((error) => {
        history.replace("/OTP-Verification/"+OTPCode);
        console.log('error>>>',error)
      });
    } catch (err) {
      history.replace("/OTP-Verification/"+OTPCode);
      console.log('error>>>',err)
    }
  };





  const logout = () => {

    console.log("logout")
    // window.localStorage.removeItem("authSession");
    localStorage.clear();
    window.localStorage.removeItem("authSession");


    setAuthState({
      isLoggedIn: false,
      loading: false,
      user: null,
      logout,
      login
    });

    
    
  };

  useEffect(() => {

    const loadAuthSession = async () => {

      try {
        const OTPParam = helper.urlSegment(0);
        const OTPCode = helper.urlSegment(1);
        const OTPUrlCode = helper.urlSegment(2);
        const queryParams = new URLSearchParams(window.location.search);
        const verifycode = queryParams.get('code');
        const verifysource = queryParams.get('source');
        if (verifycode) {
          if (verifysource == "admin") {
            console.log(verifycode,"source")
            adminUserlogin(verifycode);
          }
          
        } else if(OTPCode!=null && OTPUrlCode!=null && OTPParam=='OTP-Verification'){
          //OTP login
          loginByOTP(OTPCode,OTPUrlCode);
        }
    
        // const { idToken, accessToken } = await authCustom.getSavedSession();
        const savedAuthSession = window.localStorage.getItem("authSession");
        const { idToken, accessToken } = JSON.parse(savedAuthSession);
        const user = await authCustom.getUserInfo(accessToken,idToken);
        //login
        setAuthState({
          isLoggedIn: true,
          loading: false,
          user,
          token: accessToken,
          idToken,
          logout,
          login,
          loadAuthSession
        });

      } catch (err) {
        
        if (err.message !== "not_logged_in") {
          console.error("login_error", err);
        }

        window.localStorage.removeItem("authSession");

        setAuthState({
          isLoggedIn: false,
          loading: false,
          user: null,
          logout,
          login,
          loadAuthSession
        });
      }
    };

    loadAuthSession();
  }, []);



  return (
    <AuthContext.Provider value={authState}>
      {authState.loading && (
        // <div className="loadingContainer">
        //   <Belt centered width={300}>
        //     <Spinner />
        //   </Belt>
        // </div>
        <div class="preloader"><img src={SpinnerImage} alt="loader" class="lds-ripple img-fluid" /></div>
      )}

      {!authState.loading && children}
    </AuthContext.Provider>
  );
}

export default Auth;
