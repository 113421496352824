import React, { useState, useEffect } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { useHistory } from "react-router-dom";
import * as helper from "../../../helpers/helper";
import CheckOnBoardingSteps from "../Component/CheckOnBoardingSteps";
import ScreenLoading from '../../Loadings/ScreenLoading';
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../Form/FormToast";
import WhiteSquareButton from "../../../Form/WhiteSquareButton";
import ShirtImg from "./../../../../assets/icons/shirt.svg"
import HelpingHandImg from "./../../../../assets/icons/Vector-donation.svg"
import SearchCheckImg from "./../../../../assets/icons/vector-lead.svg"
import { VideoSteps } from "./AddVideo";
import PackageImg from "./../../../../assets/icons/package.svg"
import Button from "../../../Form/Button";

export const PURPOSE_OF_VIDEOS = [
  { label: "Select purpose of video", value: "" },
  { label: "To sell your product", value: "To sell your product" },
  { label: "To generate lead", value: "To generate lead" },
  { label: "To collect the donation", value: "To collect the donation" },
];


const producText = helper.showUserTypeBaseMessage({
  NonProfit: "Donation Campaign",
  default: "Product",
});

function EditVideoPurposeNav(...props) {
  const isMobileSize = helper.isMobile();
  const history = useHistory();
  const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
  const [errorMessage, setErrorMessage] = useState(false);
  const [userSubscription, setUserSubscription] = useState();
  const [streamType, setStreamType] = useState("upload-video");
  const [streamSourceType, setStreamSourceType] = useState("Live stream from URL");
  const [, setStreamplatform] = useState();
  const [streamname, setStreamname] = useState();
  const [streamurl, setStreamurl] = useState();
  const [streamkey, setStreamkey] = useState();
  const [streamstarttime, setStreamstarttime] = useState();
  const [streamendtime, setStreamendtime] = useState();
  const [maxVideoTimeAllowedInSeconds, setMaxVideoTimeAllowedInSeconds] = useState();
  const [facebookCode, setFacebookCode] = useState();
  const [facebookUrl, setFacebookUrl] = useState();
  const [leadForm, setLeadForm] = useState();
  const [leadLoader, setLeadLoader] = useState(false);
  const [leaddata, setLeaddata] = useState();
  const [videopurpose, setVideoPurpose] = useState(history.location.state.addVideoData.videoType);

  useEffect(() => {
    setMaxVideoTimeAllowedInSeconds(helper.allowedVideoTimeSeconds(userSubscription));
  }, [userSubscription]);


  const AddVideoPurpose = (purpose) => {



    if (purpose == 'To generate lead') {

      const addVideoData = history.location.state.addVideoData
      const updatedAddVideoData = {
        ...addVideoData, // Copying all existing properties from addVideoData
        videoType: purpose // Updating the leadForm property
      };

      history.push({
        pathname: "/videos/edit-video-nav/edit-video-lead-nav",
        state: {
          addVideoData: updatedAddVideoData,
          originalVideoData: history.location.state.originalVideoData,
        },
      });



    } else {

      const addVideoData = history.location.state.addVideoData
      const updatedAddVideoData = {
        ...addVideoData, // Copying all existing properties from addVideoData
        videoType: purpose // Updating the leadForm property
      };

      history.push({
        pathname: "/edit-videos/select-nav-product",
        state: {
          addVideoData: updatedAddVideoData,
          originalVideoData: history.location.state.originalVideoData,
        },
      });


    }



  }


  const onNavBarClick = () => {
    setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
  }

  const getSubscriptionData = (e) => {
    setUserSubscription(e)
  }

  const backToMainVideoStep = () => {
    return history.push({
        pathname: `/videos/edit-video-nav/${history?.location?.state?.addVideoData?.editVideoId}`,
        state: history.location.state,
    });
}


  return (
    <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">

      <Sidebar onNavBarClick={onNavBarClick} />
      <Page
        title="Add Video"
        onNavBarClick={onNavBarClick}
        formWidth={true}
        getSubscriptionData={getSubscriptionData}>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-header vs-card-header">
                <h5 className="fw-bolder">What would you like to do?</h5>
                <VideoSteps editMode={true} step="2" purpose={videopurpose} />
              </div>
              <div className="card-body form-card-body">
                <div className={`d-flex align-items-center gap-4 mt-8 onboarding-purpose-boxes`}>
                  <WhiteSquareButton
                    icon={<img src={PackageImg} alt="Sell Product" height="28" width="28" />}
                    label="Sell Product"
                    onClick={() => setVideoPurpose('To sell your product')}
                    styles={{
                      height: isMobileSize ? 100 : 109,
                      width: isMobileSize ? 100 : 155,
                    }}
                    active={(videopurpose === "To sell your product") ? true : false}
                  />
                  <WhiteSquareButton
                    icon={<img src={HelpingHandImg} alt="Get Donations" height="28" width="36" />}
                    label="Get Donations"
                    onClick={() => setVideoPurpose('To collect the donation')}
                    styles={{
                      height: isMobileSize ? 100 : 109,
                      width: isMobileSize ? 100 : 155,
                    }}
                    active={(videopurpose === "To collect the donation") ? true : false}
                  />
                  <WhiteSquareButton
                    icon={<img src={SearchCheckImg} alt="Capture Leads" height="28" width="28" />}
                    label="Capture Leads"
                    onClick={() => setVideoPurpose('To generate lead')}
                    styles={{
                      height: isMobileSize ? 100 : 109,
                      width: isMobileSize ? 100 : 155,
                    }}
                    active={(videopurpose === "To generate lead") ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
      {/* sticky footer */}
      <div className="sticky-footer ">
          <div className="footer-container">
            <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
              <Button
                className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                type="button"
                buttonText="Back"
                // onClick={() => history.push("/dashboard")}
                onClick={backToMainVideoStep}
              />
              <Button
                className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                type="button"
                onClick={() => AddVideoPurpose(videopurpose)}
                buttonText="Next"
                fullButton={false}
                loading={false}
                disabled={!videopurpose}
              />
            </div>
          </div>
        </div>
      {/* sticky footer */}
    </div>
  );
}

export default EditVideoPurposeNav;