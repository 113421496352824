function ShopifySyncing() {

  return (
    
   <div class="card">
      <div class="card-body">
        <p className="card-subtitle-verified-cred mb-3">Product Syncing</p>
        <div class="card-body">

                    <div class="text-center">
                      <div class="spinner-border" role="status">
                   
                      </div>
                    </div>
                  </div>
        <p className="card-subtitle-verified mb-3">Copying...</p>
      </div>
    </div>
  

  );
}

export default ShopifySyncing;
