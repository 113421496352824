import React , { useEffect, useState }  from "react";
import { useHistory } from "react-router-dom";
import * as helper from '../../helpers/helper';

import Button from "./../../Form/Button";
import { OnboardingPage } from "../../layouts/OnboardingPage";


function DirectUnsibscribe() {
    const history = useHistory();
    const [error, setError] = useState();
    const [success, setSuccess] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
      setIsChecked(!isChecked); // Toggle the checkbox state
    };

    const directUnSubscribeCall = async (e) => {
      setError('');
      setSuccess('');
      setLoader(true)
      if(e){
        e.preventDefault();
      }
      // const OTPCode = helper.urlSegment(1);
      const directUnSubkey = helper.urlSegment(1);
      const verifyOTP = await fetch(process.env.REACT_APP_API_URL + '/direct-cancel-user-subscriptions', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "directUnSubkey": directUnSubkey,

        })
      }).then(response => {
        if (response.ok) {
          response.json().then(async json => {
            setSuccess(json?.message);
          });
        } else {

          response.json().then(async json => {
            setError(json?.message);
          });

        }
      }).catch((error) => {
        // setError(error);
        console.log('error>>>',error)
      }).finally((error) => {
        setLoader(false)
      });
    };

    // const disableSubmitBtn = loader===true || isChecked!==true;
    const disableSubmitBtn = loader===true;


    return (

      <OnboardingPage title="Unsubscribe Package">


        <div className="authentication-login min-vh-100 bg-body row justify-content-center align-items-center p-4">
          <div className="col-sm-7 col-md-7 col-xl-7">
            <div className="card-body">
              <div className="mb-5">
                <h2 className="fw-bolder fs-7 mb-3">To unsubscribe, click the button below.</h2>
                <h2 className="fw-bolder fs-7 mb-3">Thanks!</h2>

              </div>
              {(error)?<div class="alert alert-danger" role="alert"><strong>Error</strong> {(error)} </div>:""}
              {(success)?<div class="alert alert-success" role="alert"><strong>Success</strong> {(success)} </div>:""}
              <form>

             {/* <div className="d-flex align-items-center justify-content-between mb-4">
             <div className="form-check">
              <input
                className="form-check-input primary"
                type="checkbox"
                id="flexCheckChecked"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label text-dark" htmlFor="flexCheckChecked">
                I want to unsubscribe package
              </label>
            </div> 
          </div>*/}
               
                {/* <button disabled={disableSubmitBtn} type="button" onClick={directUnSubscribeCall} className="w-100 btn btn-primary py-8 mb-3">
                    {loader ? (
                        <div>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                        </div>
                    ) : (
                        <div><i class="me-1 fs-4"></i>Unsubscribe</div>
                    )}
                    
                </button> */}

                <Button
                    type="submit"
                    buttonText="Unsubscribe"
                    fullButton={true}
                    onClick={directUnSubscribeCall}
                    loading={loader}
                    disabled={disableSubmitBtn}
                  />

                {/* <Link className="w-100 btn btn-primary py-8 mb-3" disabled={disableSubmitBtn} to="/login">Cancel</Link> */}
                
                {/* <a href="./authentication-login.html" className="btn btn-light-primary text-primary w-100 py-8">Back to Login</a> */}
              </form>
            </div>
          </div>
        </div>


      </OnboardingPage>

    );
}
export default DirectUnsibscribe;