import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import ActivityLog from "../../helpers/ActivityLog";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from "../../helpers/helper";
import RoundtickIcon from "./../../../assets/icons/roundtick.svg"

import Input from "./../../Form/Input";
import ToggleSwitchRow from "./../../Form/ToggleSwitchRow";
import Button from "./../../Form/Button";

import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";

const Statuses = {
    VERIFY: "verify",
    VERIFIED: "verified",
    SYNCING: "product-syncing",
    DONE: "done"
}

function ImportShopifyProductNav(props) {
    const isMobileSize = isMobile();
    const userdata = props.propValue;
    const store = (userdata == undefined) ? "" : userdata.store
    const shopaccess = (userdata == undefined) ? "" : userdata.access_token
    const [storeid, setStoreid] = useState(store);
    const [accesstoken, setAccesstoken] = useState(shopaccess);
    const [storeFrontAccesstoken, setStoreFrontAccesstoken] = useState(userdata ? userdata?.storefront_access_token : "");
    const [showStoreFrontField, setShowStoreFrontField] = useState(!!userdata?.storefront_access_token);
    const [storeidError, setStoreidError] = useState('');
    const [accesstokenError, setAccesstokenError] = useState('');
    const [storeFrontAccesstokenError, setStoreFrontAccesstokenError] = useState('');
    const history = useHistory();
    // const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [showErrors, setShowErrors] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    // const [importProductLoader, setImportProductLoader] = useState(false);
    const [status, setStatus] = useState("verify");

    const [loader, setLoader] = useState(false);

    const handleStoreChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setStoreid(value);
        validateField(value, rule, setStoreidError);

    };

    const handleAccesstokenChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setAccesstoken(value);
        validateField(value, rule, setAccesstokenError);

    };

    const handleStoreFrontAccesstokenChange = (e) => {
        var value = e.target.value;
        var rule = "required";
        setStoreFrontAccesstoken(value);
        validateField(value, rule, setStoreFrontAccesstokenError);

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        submitForm(storeid, accesstoken);
    };

    const resetState = () => {
        setShowErrors(false);
        setErrorMessage('');
    };

    const submitForm = (storeid, accessToken) => {
        setLoader(true)
        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        // setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-shopify-keys', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "shopify_store": storeid,
                    "access_token": accessToken,
                    "storefront_access_token": showStoreFrontField ? storeFrontAccesstoken : ""
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        // let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 14;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })
                            
                            setLoader(false)
                            setStatus("verified")
                        }
                        else {

                            
                            setLoader(false)

                            setShowErrors(true);
                            setErrorMessage(json.message);


                            setTimeout(resetState, 2000);


                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {

                // setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };

    const SeeProducts = (e) => {

        e.preventDefault();
        history.push("/products");


    };



    const SyncProduct = (e) => {
        e.preventDefault();
        submitProductForm(storeid, accesstoken);
    };

    const submitProductForm = (storeid, accessToken) => {
        // setLoader("loading")

        setStatus("product-syncing")

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        // setImportProductLoader(true);
        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/add-variant-shopify-product-sync', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "id": token.idToken,
                    "shopify_store": storeid,
                    "access_token": accessToken
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        // let history_push_link;
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();
                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 16;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            // setLoader("true")

                            setStatus("done")

                            props.onCompleteCallback?.()

                        }
                        else {
                            // history_push_link = "/add-product";
                        }
                    });
                }
            }).catch((error) => {
            }).finally((error) => {
                // setImportProductLoader(false);
            });


        });
        return fetch_prods;
    };


    const IsURL = storeid ? !isURL(storeid):false

    if(showStoreFrontField==true){

        
        var isButtonDisabled = IsURL || !accesstoken || storeidError || accesstokenError || !storeFrontAccesstoken;
    }else{

      
        var isButtonDisabled = IsURL || !accesstoken || storeidError || accesstokenError;

    }
    // const isButtonDisabled = IsURL || !accesstoken || storeidError || accesstokenError;


    // const tagLine = showUserTypeBaseMessage({
    //     'Brand': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    //     'default': '',
    //     'NonProfit': 'Empower Your Donors by Providing an Ultimate Shoppable Video Experience',
    //     'Influencer': 'Empower Your Viewers by Providing an Ultimate Shoppable Video Experience',
    //     'Individual': 'Empower Your Customers by Providing an Ultimate Shoppable Video Experience',
    // });



    return (

        <div className="container col-lg-6 col-md-12 col-xs-12 import-shopify">
            <section className="import-shopify-integration">
                {/* <div className="card">
                    <div className="card-body"> */}
                        {/* {(showErrors || errorMessage) ? <div className="alert alert-danger" role="alert"><strong>Error </strong> {(errorMessage) ? errorMessage : "Please check Title, Image and Price fields."} </div> : ""} */}



                        {errorMessage && (
                                <FormToast
                                    type={FORM_TOAST_TYPES.DANGER}
                                    message={errorMessage}
                                />
                                )}




                        {status === Statuses.VERIFY && (

                      <div className="mt-4">
                            <form className="form">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <Input
                                                id="store-url"
                                                type="text"
                                                label="Store URL *"
                                                value={storeid}
                                                onChange={(e) => handleStoreChange(e)}
                                                disabled={false}
                                                placeholder="STORE_ID.myshopify.com"
                                                errorMessage={storeidError}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <Input
                                                id="store-url"
                                                type="text"
                                                label="Access Key *"
                                                value={accesstoken}
                                                onChange={(e) => handleAccesstokenChange(e)}
                                                disabled={false}
                                                placeholder="shpat_00000000000000000000000000000000"
                                                errorMessage={accesstokenError}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="form-check form-check-inline mt-2 mb-3">
                                    {/* <CheckBoxRow
                                        id="demo"
                                        label="Add Product to Shopify Cart on 'Shop Now' Press"
                                        value={showStoreFrontField}
                                        onChange={e => setShowStoreFrontField(c => !c)}
                                        disabled={false}
                                    /> */}


                                    <ToggleSwitchRow
                                        id="demo"
                                        label="Add Product to Shopify Cart on 'Shop Now' Press"
                                        value={showStoreFrontField}
                                        onChange={e => setShowStoreFrontField(c => !c)}
                                        disabled={false}
                                        height="20px"
                                        width="40px"
                                    />


                                </div>

                                

                           


                                {showStoreFrontField && (<div className="row">
                                    <div className="col-md-12">
                                        <div className="mb-3">
                                            <Input
                                                id="store-url"
                                                type="text"
                                                label="Store Front Access Token *"
                                                value={storeFrontAccesstoken}
                                                onChange={(e) => handleStoreFrontAccesstokenChange(e)}
                                                disabled={false}
                                                placeholder=""
                                                errorMessage={storeFrontAccesstokenError}
                                            />
                                        </div>
                                    </div>
                                </div>)}

                                <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                
                                    <Button
                                        type="submit"
                                        buttonText="Next"
                                        fullButton={false}
                                        loading={loader}
                                        onClick={handleSubmit}
                                        disabled={isButtonDisabled}
                                    />
                                </div>
                            </form>
                            </div>)}
                            
                        {/* adding all the components  */}

                        {status === Statuses.VERIFIED && (
                            <div className="row">
                                <section>
                                    <div class="card-body pt-5 pb-5">
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <img src={RoundtickIcon} width="75" height="75"></img>
                                            <br />
                                            <h2 className="text-center mb-4 text-verfied">Credentials Verified</h2>

                                            {/* <button type="submit" onClick={SyncProduct} class="btn mb-1 btn-primary btn-lg px-4 w-10 text-white">Sync Products</button> */}

                                            <Button
                                                type="submit"
                                                buttonText="Sync Products"
                                                fullButton={false}
                                                loading={false}
                                                onClick={SyncProduct}
                                                disabled={false}
                                            />

                                        </div>
                                    </div>
                                </section>
                            </div>
                        )}
                        
                        {status === Statuses.SYNCING && (

                            <div className="row">
                                <section>
                                    <div class="card-body pt-5 pb-5">
                                        <div class="card-body">
                                            <p className="card-subtitle-verified-cred mb-3 text-black fw-bolder">Product Syncing</p>
                                            <div class="card-body">
                                                <div class="text-center">
                                                    <div class="spinner-border" role="status">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        )}
                        {status === Statuses.DONE && (

                            <div className="row">
                                <section>
                                    <div class="card-body pt-5 pb-5">
                                        <div className="d-flex flex-column align-items-center justify-content-center">
                                            <img src={RoundtickIcon} width="75" height="75"></img>
                                            <br />
                                            <h2 className="text-center mb-4 text-verfied">Product Synced Successfully</h2>

                                            {/* <button type="submit" onClick={SyncProduct} class="btn mb-1 btn-primary btn-lg px-4 w-10 text-white">See Products</button> */}


                                            <Button
                                                type="submit"
                                                buttonText="See Products"
                                                fullButton={false}
                                                loading={false}
                                                onClick={SeeProducts}
                                                disabled={false}
                                            />


                                        </div>
                                    </div>
                                </section>
                            </div>


                        )}





                    {/* </div>
                </div> */}
            </section>


        </div>



    );
}
export default ImportShopifyProductNav;