import React, { useState,useEffect} from "react";
import moment from "moment";
import {convertToMMYYYY} from "../../../helpers/helper";

// defaultValue={cardExpiry} setCardExpiry={setCardExpiry}

export function CreditCardExpireyField({defaultValue,setCardExpiry,setIsValidExpiry,setCardExpiryErrorMsg}) {
  const [expiryValue, setExpiryValue] = useState(defaultValue ?moment(defaultValue).format('MM/YY'):"");
  // const [expiryLastValue, setExpiryLastValue] = useState('');
  // const [isYearValid, setIsYearValid] = useState();

  useEffect(() => {
    setIsValidExpiry(validateExpireDate(expiryValue));
    setCardExpiry(expiryValue);
  }, [expiryValue]); // Empty dependency array ensures this effect runs once

  const validateExpireDate = (value) => {

    const parsedDate = moment(value, 'MM/YY', true);
    return parsedDate.isValid() && parsedDate.isAfter(moment());

    
  }



  // console.log('defaultValue>>',defaultValue,moment(defaultValue).format('MM/YY'));



  const handleTyped = (event) => {

    setCardExpiryErrorMsg("")

    const eventTargetValue = event.target.value?.replace(/[^0-9/]/g, '');
    let setCardExpiryValue = eventTargetValue;

    if(!eventTargetValue){
      setCardExpiryErrorMsg("This is Required")
    }

    if(expiryValue?.length > eventTargetValue.length ){
      // setExpiryLastValue(expiryValue);
      setExpiryValue(eventTargetValue);
      // setCardExpiry(eventTargetValue);
    } else {
      setCardExpiryValue = expiryFormat(eventTargetValue);
      setExpiryValue(expiryFormat(eventTargetValue));
      // setExpiryLastValue(expiryFormat(eventTargetValue));
    }
    setCardExpiry(convertToMMYYYY(setCardExpiryValue));
  }

  const expiryFormat = (value) => {
    if(value>1 && value?.length===1){
      if(value<=9){
        value = '0'+value+'/';
      } else {
        value = value+'/';
      }
    } else if(value>12 && value?.length===2){
      console.log(value>12 && value?.length===2);
      value = "0"+value;
      value = value.toString().substring(0, 2) + '/' + value.toString().substring(2);
    }
    

    let expdate = value;
    const expDateFormatter =
      expdate?.replace(/\//g, '')?.substring(0, 2) +
      (expdate?.length >= 2 ? '/' : '') +
      expdate?.replace(/\//g, '')?.substring(2, 4);

    return expDateFormatter
  }
  
  

  return (
    <>
      <div>
        {/* <input type="text" id="cc-expiry" value={expiryFormat(expiryValue)} placeholder="MM/YY" class="form-control" /> */}
        <input type="text" id="cc-expiry" onChange={handleTyped} value={expiryValue} placeholder="MM/YY" class="form-control" />
      </div>
    </>
  );
}


export default CreditCardExpireyField;