import React from "react";
import { Link } from "react-router-dom";
import styles from "./BreadCrump.module.css";

function BreadCrump({ title, breadCrumpDetail, image, tagLine, additionalClasses='' }) {
  return (
    <div className={`card bg-light-info shadow-none ${styles.breadcrumb} ${additionalClasses}`}>
      <div className={`p-r d-flex ${styles.breadcrumb_body}`}>
        <div className={`breadcrumb_left ${styles.breadcrumb_left}`}>
          <h4 className="fw-semibold mb-1">{title}</h4>
          <ol className="breadcrumb">
            {breadCrumpDetail.map((item, index) => (
              <li className="breadcrumb-item" key={index}>
                {(item.link?.length >= 1) ? <Link className="text-muted" to={item.link}>
                  {item.name}
                </Link> : item.name}
              </li>
            ))}
          </ol>
          {tagLine && (
            <p className="breadcrumb_tagline mb-0">{tagLine}</p>
          )}
        </div>
        {image && (
        <div className={`breadcrumb_right  ${styles.breadcrumb_right}`}>
          <img src={image} alt="Video Squirrel" className="breadcrumb-img img-fluid mb-n4" />
        </div>

        )}
      </div>
    </div>
  );
}

export default BreadCrump;
