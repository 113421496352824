import React, { useEffect, useState } from 'react';
import { Sidebar } from "../../molecules/Sidebar";
import { Page } from "../../layouts/Page";
import { useHistory } from "react-router-dom";
import ActivityLog from "../../helpers/ActivityLog";
import BreadCrump from "../../Breadcrump/BreadCrump";
import dashboardbreadcrumimage from "../../../dist/images/dashboard/add-video.png";
import validateField from "../../helpers/AllValidtions";
import { isMobile, showUserTypeBaseMessage } from '../../helpers/helper';
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../Form/FormToast";

import Input from "./../../Form/Input";
import Select from "./../../Form/Select";
import CheckBoxRow from "./../../Form/CheckBoxRow";
import PhoneInputField from "./../../Form/PhoneInputField";
import Warning from "./../../Form/Warning";
import { Intents } from "../../../Enums/Intents";
import Button from "./../../Form/Button";
import DropDown from "./../../Form/DropDown";
import Textarea from "./../../Form/Textarea";
import EyeIcon from "./../../../assets/icons/eye.svg"
import ArrowLeft from "./../../../assets/icons/arrow-left.svg"
import ToggleSwitchRow from "./../../Form/ToggleSwitchRow";


const FIELD_TYPES = {
    TEXT: "Text",
    NUMBER: "Number",
    EMAIL: "Email",
    PHONE: "Phone",
    TEXTAREA: "Textarea",
    DROPDOWN: "Dropdown"
}

function NewEditLead() {
    const history = useHistory();
    const [status, setStatus] = useState('loading..');
    const [desc, setDesc] = useState();
    const [statusError, setStatusError] = useState('');
    const [errors, setErrors] = useState('');
    const [success, setSuccess] = useState(false);
    const [loaderSubmit, setloaderSubmit] = useState(false);
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [fields, setFields] = useState([]);
    const [fielderror, setFieldError] = useState(false);

    const [screenLoading, setScreenLoading] = useState(true);
    const [fieldloader, setFieldLoader] = useState(false);
    const [formId, setFormId] = useState();

    // const [formId, setFormId] = useState(5);

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    const handleStatusChange = (e) => {
        var value = e.target.value;
        var rule = "required";

        if (value == '') {
            setStatus(value);
            validateField(value, rule, setStatusError);
        } else {
            setStatus(value);
            validateField(value, rule, setStatusError);
        }
    };


    useEffect(() => {
        fetchLeadFields();
    }, [formId]);

    useEffect(() => {
        fetchLeadData();
        // console.log('history.location.state',)
    }, []);

    useEffect(() => {
        fetchVideoLeadData();
    }, []);




    const fetchVideoLeadData = () => {
        let path = window.location.pathname;
        var confirmcode = path.split("/");
        var id = confirmcode[2];
        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/find-lead', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": id }),
        })
            .then((response) => response.json())
            .then((data) => {

                setFormId(data?.data?.parent_form_id)
                fetchLeadFields()
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            }).finally(() => {
                // setScreenLoading(false);
            });
    };



    const fetchLeadData = (prevFields) => {
        let path = window.location.pathname;
        var confirmcode = path.split("/");
        var id = confirmcode[2];
        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);
        fetch(process.env.REACT_APP_API_URL + '/find-lead', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": id }),
        })
            .then((response) => response.json())
            .then((data) => {

                const updatedFields = prevFields.map(e => {
                    const attemptedField = data?.data?.lead_data?.find(x => x.lead_form_id === e.id)
                    return ({
                        ...e,
                        value: attemptedField.field_value,
                        error: false,
                        error_message: ""
                    });
                });

                setFields(updatedFields)
                setStatus(data?.data?.status)
                setDesc(data?.data?.comment)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            }).finally(() => {
                // setScreenLoading(false);
            });
    };

    const fetchLeadFields = () => {
        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        setScreenLoading(false)

        if(formId){

        fetch(process.env.REACT_APP_API_URL + '/find-all-lead-form', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,
            },
            body: JSON.stringify({ "id": token.idToken,"form_id":formId }),
        })
            .then((response) => response.json())
            .then((e) => {
                const updatedFields = e.data.map(e => ({
                    ...e,
                    value: "",
                    error: false,
                    error_message: ""
                }));
                setFields(updatedFields)

                fetchLeadData(updatedFields);
            });

        }
    };



    const resetState = () => {
        setErrors('');
    };


    const handleSubmit = (e) => {
        submitForm();
    };

    const submitForm = () => {
        setloaderSubmit(true)

        let path = window.location.pathname;
        var confirmcode = path.split("/");
        var id = confirmcode[2];

        var token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        const fetch_prods = new Promise((resolve, reject) => {
            fetch(process.env.REACT_APP_API_URL + '/update-lead', {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: 'Bearer ' + token.accessToken,
                },
                body: JSON.stringify({
                    "id": id,
                    "status": status,
                    "comment": desc,
                    "data": fields
                })
            }).then(response => {
                if (response.ok) {
                    response.json().then(json => {
                        if (json?.code?.toString() === "200") {

                            const userLog = new ActivityLog();

                            const admin_login_id = localStorage.getItem('admin_login_id');
                            var user_id = admin_login_id ? admin_login_id : token.idToken;
                            var is_admin = admin_login_id ? 1 : 0;
                            var event_id = 6;
                            var account_id = token.idToken;
                            userLog.createaAtivitylog({ user_id, is_admin, event_id, account_id })

                            const listingLeadId = history.location.state?.listingLeadId;

                            setSuccess("Lead Updated succesfully")
                            setTimeout(() => {
                                resetState()
                                if(listingLeadId){
                                    history.push(`/leads/${listingLeadId}`)

                                } else {
                                    window.location.reload();
                                }
                            }, 2000);

                        } else {
                            history.push("/edit-lead")
                        }
                    });
                }
            }).catch((error) => {
                console.log("Error: ", error)
            }).finally(() => {
                setloaderSubmit(false);
                setScreenLoading(false);
            });
        });
        return fetch_prods;
    };

    const onFieldChange = (id, value, type, is_required) => {

        if (type === FIELD_TYPES.EMAIL) {
            const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;

            if (!emailRegex.test(value)) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;

            } else {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }


        }

        if (type === FIELD_TYPES.NUMBER) {
            const numericRegex = /^[0-9]+$/;
            if (!numericRegex.test(value)) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;

            } else {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }
        }

        if (type === FIELD_TYPES.PHONE) {
            if (!value || value.length > 12 || value.length < 8) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;

            } else {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }
        }

        if ([FIELD_TYPES.TEXT, FIELD_TYPES.TEXTAREA].indexOf(type) !== -1) {

            if (is_required === true) {

                if (!value) {
                    const objectToUpdate = fields.find(itemv => itemv.id === id);
                    objectToUpdate.error = true;
                    objectToUpdate.error_message = `Please write valid ${type}`;
                } else {
                    const objectToUpdate = fields.find(itemv => itemv.id === id);
                    objectToUpdate.error = false;
                    objectToUpdate.error_message = "";
                }

            } else {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";
            }

        }

        if (type === FIELD_TYPES.DROPDOWN) {

            if (!value) {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = true;
                objectToUpdate.error_message = `Please write valid ${type}`;

            } else {
                const objectToUpdate = fields.find(itemv => itemv.id === id);
                objectToUpdate.error = false;
                objectToUpdate.error_message = "";

            }
        }


        const updatedFields = fields.map((item) =>
            item.id === id ? { ...item, value } : item
        )

        setFields(updatedFields);

        const totalErrors = updatedFields.filter(item => item.error).length;
        const totalRequiredFields = updatedFields.filter(item => item.is_required).length;
        const totalFilledFields = updatedFields.filter(item => item.value).length;
        const canSubmit = totalErrors === 0 && totalFilledFields >= totalRequiredFields;

        setFieldError(!canSubmit);
    }

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebartype={sidebarType} data-sidebar-position="fixed" data-header-position="fixed">
            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title="Dashboard" formWidth={true}>
                {/* <div className="container-fluid"> */}
                    {/* <BreadCrump
                        title="Lead Management"
                        image={dashboardbreadcrumimage}
                        breadCrumpDetail={[
                            { name: "Dashboard", link: "/" },
                            { name: "Edit Lead", link: "" }
                        ]}
                        tagLine={showUserTypeBaseMessage({ 'Brand': 'Entice Viewers and Drive Leads, Simplify the Shopping Journey', 'default': '' })}
                    /> */}
                    <div className="row adding-lead">

                    <section>

                    <div className="card header">
                            <div className="card-body">
                                <a  className="icon-image"><img src={ArrowLeft} width="20" height="20"></img></a>
                                <h4 className="text-black fs-6 fw-semibold">Edit Lead</h4>
                            </div>
                        </div>


                        <div className="card form">
                        <div class="card-body pt-4 pb-5">
                            <div className="card-body add-lead">

                                {/* {(errors) ? <div class="alert alert-danger" role="alert"><strong>Error: </strong> {(errors)} </div> : ""}
                                {(success) ? <div class="alert alert-success" role="alert"><strong>Success: </strong> {(success)} </div> : ""} */}

{errors && (
                                <FormToast
                                    type={FORM_TOAST_TYPES.DANGER}
                                    message={errors}
                                />
                                )}


                                {success && (
                                <FormToast
                                    type={FORM_TOAST_TYPES.SUCCESS}
                                    message={success}
                                />
                                )}

                                {
                                    screenLoading ? (
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) : (
                                        <form className="validation-wizard wizard-circle mt-5 lead clearfix">
                                            {fields.map(f => {
                                                if (f.type === FIELD_TYPES.TEXTAREA) {
                                                    return (
                                                        <div className="mb-3 row" key={`field-${f.id}`}>
                                                            <div className="col-12">
                                                                <div className="mb-3">
                                                                    <Textarea
                                                                        id={`field-${f.id}`}
                                                                        type="text"
                                                                        label={<>{f.name}:{f.is_required && <span className="danger">*</span>}</>}
                                                                        onChange={(e) => onFieldChange(f.id, e.target.value, f.type, f.is_required)}
                                                                        disabled={false}
                                                                        // value={f.value}
                                                                        placeholder={f.name}
                                                                        errorMessage={f.error_message}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );

                                                } else if (f.type === FIELD_TYPES.DROPDOWN && f.fieldvalue?.split(',')) {

                                                    const options = f.fieldvalue.split(',').map(item => ({
                                                        value: item.trim(),
                                                        label: item.trim()
                                                    }));


                                                    return (
                                                        <div className="mb-3 row" key={`field-${f.id}`}>
                                                            <div className="col-12">
                                                                <div className="mb-3">

                                                                     <DropDown
                                                                        id={`field-${f.id}`}
                                                                        label={<>{f.name}:{f.is_required && <span className="danger">*</span>}</>}
                                                                        options={options}
                                                                        value={f.value}
                                                                        onChange={(e) => onFieldChange(f.id, e.target.value, f.type, f.is_required)}
                                                                        errorMessage={f.error_message}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )

                                                }

                                                return (
                                                    <div className="mb-3 row" key={`field-${f.id}`}>
                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                               
                                                            <Input
                                                                    id={`field-${f.id}`}
                                                                    type={f.type === FIELD_TYPES.PHONE ? "number" : f.type}
                                                                    label={<>{f.name}:{f.is_required && <span className="danger">*</span>}</>}
                                                                    onChange={(e) => onFieldChange(f.id, e.target.value, f.type, f.is_required)}
                                                                    disabled={false}
                                                                    value={f.value}
                                                                    placeholder={f.name}
                                                                    errorMessage={f.error_message}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            })}

                                            <div className="mb-3 row">
                                                <div class="col-12">
                                                    <div class="mb-4">
                                                        {/* <label htmlFor="videodesc">Comments:</label>
                                                        <textarea class="form-control" name="" id="videodesc" value={desc} onChange={(e) => setDesc(e.target.value)} placeholder="Comments"></textarea> */}

                                                        <Textarea
                                                            id="comments"
                                                            type="text"
                                                            label="Comments"
                                                            onChange={(e) => setDesc(e.target.value)}
                                                            value={desc}
                                                            disabled={false}
                                                            placeholder="Comments"
                                                            errorMessage={false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mb-3 row">
                                                <div class="col-12">
                                                    <div class="mb-3">
                                                    <DropDown
                                                            id="Status"
                                                            label="Select Status:*"
                                                            options={[
                                                                { value: 'Pending', label: 'Pending' },
                                                                { value: 'Completed', label: 'Completed' },
                                                                { value: 'Abandoned', label: 'Abandoned' },
                                                            ]}
                                                            value={status}
                                                            onChange={(e) => handleStatusChange(e)}
                                                            errorMessage={statusError}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="actions clearfix" style={{ paddingRight: 0 }}>
                                                <ul role="menu" aria-label="Pagination">
                                                    <li aria-hidden="false" aria-disabled="false">
                                                        {/* <button type="button" onClick={handleSubmit} disabled={fielderror} class="btn btn-primary text-white">

                                                            {loaderSubmit ? (
                                                                <div>
                                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Loading...
                                                                </div>
                                                            ) : (
                                                                <div><i class="ti ti-device-floppy me-1 fs-4"></i>Submit</div>
                                                            )}

                                                        </button> */}

                                                            <div className="form-actions lead-button">

                                                            <Button
                                                                type="submit"
                                                                buttonText="Submit"
                                                                fullButton={false}
                                                                loading={loaderSubmit}
                                                                onClick={handleSubmit}
                                                                disabled={fielderror}
                                                            />

                                                            </div>



                                                    </li>
                                                </ul>
                                            </div>
                                        </form>
                                    )
                                }
                            </div>
                        </div>

                        </div>
                    </section>
                </div>
            </Page>
        </div>
    );
}
export default NewEditLead;