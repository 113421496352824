import React, { useState, useEffect} from "react";
import Auth0 from "../../helpers/Auth0";
import { isEmail } from "validator";
import useAuthState from "../../hooks/useAuthState";
import { useHistory } from "react-router-dom";
import * as helper from '../../helpers/helper';
import AuthContext, { defaultAuthState } from "../../context/UserContext";

// import AuthCustom from "../../helpers/AuthCustom"
function OTPDirectVerification() {
  
  const [otp, setotp] = useState("");
  const [error, setError] = useState();
  const [success, setSuccess] = useState(false);
  const [isFuncCalled, setIsFuncCalled] = useState(false);

  // const { login, OTPLogin} = useAuthState();
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const disabled = otp.length !== 6;
  const [authState, setAuthState] = useState(defaultAuthState);


  const { login, loginByOTP } = useAuthState();

  useEffect(() => {

    const OTPCode = helper.urlSegment(1);
    const OTPUrlCode = helper.urlSegment(2);

    if(isFuncCalled===false){
      // OTPDirectVerificationCall();
      setIsFuncCalled(true);
    }
  });

  const OTPDirectVerificationCall = async (e) => {
    setLoader(true)
    if(e){
      e.preventDefault();
    }
    const OTPCode = helper.urlSegment(1);
    const OTPUrlCode = helper.urlSegment(2);
    try {
      const verifyOTP = await fetch(process.env.REACT_APP_API_URL + '/user-verify-otp', {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "otp": OTPCode,
          "urlcode": OTPUrlCode,
          // "email": localStorage.getItem('otp-email')

        })
      }).then(response => {
        if (response.ok) {
          response.json().then(async json => {
            if(json?.code==200 && json?.accessToken!=null && json?.data!=null && json?.data?.email!=null){

              const authSessionData = json;
              // delete authSessionData.user;
              window.localStorage.setItem("authSession", JSON.stringify(authSessionData));
              localStorage.removeItem('is_onboarding_complete');
              setAuthState({
                isLoggedIn: true,
                loading: false,
                token: json.accessToken,
                // logout,
                // login
              });

              history.replace("/");


              // await login(json.data.email, "12345","OTPLogin");         
              // history.replace("/");
              // loginByOTP(json.accessToken,json.data);
            } else {
              //
            }
            // if(json.code=="200"){
            //     setSuccess("Lead Updated succesfully")
            //     history.push("/orders")
            //     setloaderSubmit(false)
            // }else{
            //     history.push("/edit-lead")
            //     setloaderSubmit(false)
            // }
          });
        }
      }).
      catch((error) => {
        console.log('error>>>',error)
      });

      // await OTPLogin();
      // await login(localStorage.getItem('otp-email'), localStorage.getItem('otp-password'));
      setLoader(false);

      if(localStorage.getItem('utm_source')=='marketingweb'){

        if(localStorage.getItem('utm_campaign')>=1){
          // history.replace("/settings/subscription/upgrade-plan/"+localStorage.getItem('utm_campaign'));
        } else {
          // history.replace("/settings/subscription/upgrade-plan");
        }

      } else {
        // history.replace("/");
      }
      
    } catch (err) {
      setLoader(false)
      setError({
        title: "OTP Verification!",
        message:
          "Entered OTP is invalid."
      });
      // throw err;
    }
  };


}

export default OTPDirectVerification;
