import { useCallback, useEffect, useState } from "react";
import Select from "react-select";

const CountriesDropDown = ({
    value,
    onChange
}) => {
    const [data, setData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true);

    const fetchData = useCallback(() => {
        fetch(process.env.REACT_APP_API_URL + "/countries-list", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((response) => setData(response?.data?.rows))
            .finally(() => setIsDisabled(false));
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleChange = (e) => {
        onChange?.(e)
    }

    return (
        <Select
            value={data.length > 0 ? data.find(d => d.id === value) : null}
            options={data}
            onChange={handleChange}
            getOptionLabel={(e) => e["name"]}
            getOptionValue={(e) => e["id"]}
            placeholder="Country"
            menuPortalTarget={document.body}
            styles={{
                control: (styles) => ({ ...styles, borderColor: "#DFE5EF" }),
                menuPortal: base => ({ ...base, zIndex: 9999 })
            }}
            isDisabled={isDisabled}
        />
    )
}


export default CountriesDropDown;