import * as React from "react";
// import {isMobile} from "../../../helpers/helper";
// import Button from "../../../Form/Button";
// import validateField from  "../../../helpers/AllValidtions";
import UploadThumbnailAddVideo from "../../Videos/UploadThumbnailAddVideo";

function ProfileBrandUploadArea({ label="Picture",fileSrc, onChangeEvent, fileUploadLoading, setFileUploadLoading,uploadPathName, inlineError, parentClass}) {
  // const isMobileSize = isMobile();

  return (
    <>
      <div className={parentClass}>
        <h5 className="fw-bolder mb-1">{label}</h5>
        <p className="fs-4">File size should not be more than 2MB. JPG, JPEG, PNG & WEBP supported</p>
        <div className="d-flex flex-row justify-content-center gap-8 mt-2 mb-2">
          <UploadThumbnailAddVideo
            videoThumbnailURL={fileSrc}
            setVideoThumbnailURL={onChangeEvent}
            fileUploadLoading={fileUploadLoading}
            setFileUploadLoading={setFileUploadLoading}
            uploadPathName={uploadPathName}
          />



        </div>
        {inlineError?<small className="text-danger">
              {inlineError}
            </small>:""}

      </div>
    </>
  );
}

export default ProfileBrandUploadArea;
