import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormHeading from "../../../../atoms/FormHeading/FormHeading";
import Modal from "react-modal";
import {
  isMobile,
  userLoggedAccessToken,
  roundNumber,
} from "../../../helpers/helper";
import Button from "../../../../component/Form/Button";
import bigTickIcon from "../../../../../src/assets/icons/big-tick.svg";

const moment = require("moment");

export default function AddonsListing(props) {
  const isMobileSize = isMobile();
  const [addOnData, setAddOnData] = useState();
  const [subscribedAddOnData, setSubscribedAddOnData] = useState();
  const [subscribedAddonsLoader, setSubscribedAddonsLoader] = useState(false);
  const [addonsLoader, setAddonsLoader] = useState(false);
  const [cancelSubModal, setCancelSubModal] = useState(false);
  const [addonSuccessMsg, setAddonSuccessMsg] = useState(null);
  const [addonErrorMsg, setAddonErrorMsg] = useState(null);
  const [addonCancelLoader, setAddonCancelLoader] = useState(false);
  const [selectedAddonForCancellation, setSelectedAddonForCancellation] =
    useState();

  const cancelSubModalStyles = {
    content: {
      top: "100px",
      inset: "auto",
      // width:'450px',
      width: isMobileSize ? "90%" : "632px",
      margin: "100px auto",
      position: "relative",
      border: "none",
      backgroundColor: null,
    },
    overlay: {
      zIndex: 1000,
      backgroundColor: "#00000066",
    },
  };

  useEffect(() => {
    fetchSubscribedAddOns();
    // fetchAddOns();
  }, []);


  const fetchSubscribedAddOns = () => {
    setSubscribedAddonsLoader(true);
    fetch(process.env.REACT_APP_API_URL + "/user-subscribed-addons", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({}), // forceToFetch will fetch expired
    })
      .then((response) => response.json())
      .then((data) => {
        setSubscribedAddOnData(data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally((error) => {
        setSubscribedAddonsLoader(false);
      });
  };

  const handleOpenModalCancelSub = (subscribedAddOnDataParam) => {
    setSelectedAddonForCancellation(subscribedAddOnDataParam);
    setCancelSubModal(true);
  };

  const handleCloseModalCancelSub = () => {
    setCancelSubModal(false);
  };

  const confirmCancelAddon = (addonId) => {
    setAddonCancelLoader(true);
    setAddonSuccessMsg(null);
    setAddonErrorMsg(null);

    fetch(process.env.REACT_APP_API_URL + "/cancel-user-addon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userLoggedAccessToken(),
      },
      body: JSON.stringify({ addonId: addonId }), // Replace with your POST data
    })
      .then((response) => response.json())
      .then((responseData) => {
        if (responseData.status === false) {
          setAddonErrorMsg(
            responseData?.message
              ? responseData.message
              : "Something went wrong"
          );
        }

        if (responseData?.status === true) {
          setAddonSuccessMsg(
            responseData?.message ? responseData?.message : ""
          );

          setTimeout(function () {
            window.location.reload();
          }, 4000);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setAddonErrorMsg(
          error?.message ? error?.message : "Something went wrong"
        );
      })
      .finally((error) => {
        setAddonCancelLoader(false);
      });
  };

  return (
    <>
      <div>
        <Modal
          isOpen={cancelSubModal}
          style={cancelSubModalStyles}
          closable={false}
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <div className="d-flex justify-content-center">
                  <div className="card card pt-3 pb-3 px-7">
                    <div className="card-body p-4">
                      {addonErrorMsg ? (
                        <div class="alert alert-danger" role="alert">
                          <strong></strong> {addonErrorMsg}{" "}
                        </div>
                      ) : (
                        ""
                      )}

                      {addonSuccessMsg ? (
                        <>
                          <div className="text-center">
                            <img src={bigTickIcon} />
                            <h3 className="m-3 subscription-cancelled-success-msg">
                              Addon Cancelled
                            </h3>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {(addonSuccessMsg?.length <= 0 ||
                        typeof addonSuccessMsg?.length === "undefined") && (
                        <p className="fw-bolder fs-5">
                          Do you really want to cancel your current addon?
                        </p>

                        // <p>Do you really want to cancel your current addon?</p>
                      )}

                      <p>
                        After the cancellation, you'll use your addon till{" "}
                        <strong>
                          {moment(
                            selectedAddonForCancellation?.end_datetime
                          ).format("LL")}
                        </strong>{" "}
                      </p>

                      {(addonSuccessMsg?.length <= 0 ||
                        typeof addonSuccessMsg?.length === "undefined") && (
                        <div>
                          <div className="d-flex justify-content-center">
                            {/* selectedAddonForCancellation?.id-- {console.log('selectedAddonForCancellation',selectedAddonForCancellation)} */}
                            
                            
                            <Button
                                  buttonText="Yes"
                                  loading={addonCancelLoader}
                                  className="btn btn-primary mr-10"
                                  fullButton={false}
                                  onClick={(e) =>
                                    confirmCancelAddon(
                                      selectedAddonForCancellation?.id
                                    )
                                  }
                                />
                                
                                <Button
                                    buttonText="No"
                                    // loading={subscriptionCancelLoader}
                                    disabled={addonCancelLoader}
                                    className="btn btn-outline-primary"
                                    fullButton={false}
                                    onClick={(e) =>
                                      handleCloseModalCancelSub()
                                    }
                                  />
                                
                                {/* <button
                              type="submit"
                              onClick={(e) =>
                                confirmCancelAddon(
                                  selectedAddonForCancellation?.id
                                )
                              }
                              class="btn btn-primary white-bg-btn px-4"
                            >
                              <div class="d-flex align-items-center px-4">
                                {addonCancelLoader ? (
                                  <div>
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    Loading...
                                  </div>
                                ) : (
                                  <div>Yes</div>
                                )}
                              </div>
                            </button>
                            <button
                              type="button"
                              disabled={addonCancelLoader}
                              onClick={handleCloseModalCancelSub}
                              class="btn btn-primary text-white ml-5 px-4"
                              data-bs-dismiss="modal"
                            >
                              No
                            </button> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer"></div>
            </div>
          </div>
        </Modal>

        {addonsLoader != true && addOnData?.length >= 1 && (
          <FormHeading weight="600" size={16}>
            Add-Ons
          </FormHeading>
        )}

        {addonsLoader === true && (
          <div className="d-flex justify-content-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}

        <div className="row">
          {addOnData?.length >= 1
            ? addOnData.map((singleAddOnData, index) => (
                <>
                  {singleAddOnData?.addonItems?.length >= 1
                    ? singleAddOnData?.addonItems.map(
                        (addonItem, addonItemIndex) => (
                          <>
                            <div className="col-md-6 col-xl-4">
                              <div className="card">
                                <div className="card-body p-4 d-flex align-items-center gap-3">
                                  <i className="ti ti-apps fs-6"></i>
                                  <div>
                                    <h5 className="mt-3">
                                      {addonItem?.name}({addonItem?.total_unit})
                                    </h5>
                                    <span className="fs-4 fw-semibold d-flex align-items-center mt-2">
                                      $ {addonItem?.amount}
                                    </span>
                                  </div>
                                  <Link
                                    to={`/settings/subscription/purchase-addon/${singleAddOnData?.id}`}
                                    title="Click to buy Addon"
                                    className="btn btn-outline-primary py-1 px-2 ms-auto"
                                  >
                                    <i className="ti ti-plus fs-3"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      )
                    : ""}
                </>
              ))
            : ""}
        </div>
      </div>

      <div>
        {subscribedAddonsLoader != true && subscribedAddOnData?.length >= 1 && (
          <FormHeading weight="600" size={16}>
            Subscribed Add-Ons
          </FormHeading>
        )}

        {subscribedAddonsLoader === true && addonsLoader != true && (
          <div className="d-flex justify-content-center">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
        )}

        <div className="row addon-listing">
          {subscribedAddOnData?.length >= 1
            ? subscribedAddOnData.map((singleAddOnData, index) => (
                <>
                  <div className="col-lg-4 col-md-4 col-xs-12 d-flex">
                    <div className="card w-100 py-3 px-2">
                      <div className="card-body">
                        <h5 className="card-title fw-semibold">
                          {singleAddOnData.addon_name}
                        </h5>

                        <div className="d-flex justify-content-space-between align-items-center">
                          <p
                            className="addon-date-expiry"
                            title={singleAddOnData?.end_datetime}
                          >
                            {singleAddOnData?.status == "Cancelled"
                              ? "Expire at "
                              : "Renew at "}{" "}
                            {moment(singleAddOnData?.end_datetime)
                              .add(1, "day")
                              .format("LL")}
                          </p>

                          <h3 className="addon-billing-cycle">
                            ${roundNumber(singleAddOnData.total)}/Month
                          </h3>
                        </div>

                        <div className="d-flex justify-content-space-between align-items-center mt-3">
                          {singleAddOnData?.addonItems?.length >= 1
                            ? singleAddOnData?.addonItems.map(
                                (addonItem, addonItemIndex) => (
                                  <>
                                    <div className="py-2 d-flex align-items-center package-parameter">
                                      <h6 className="mb-0 fw-semibold text-muted">
                                        <img
                                          className="right-check-icon"
                                          src={bigTickIcon}
                                        />
                                        {addonItem?.name} (
                                        {addonItem?.addon_item_value})
                                      </h6>
                                    </div>
                                  </>
                                )
                              )
                            : ""}

                          <div className="text-center">
                            {singleAddOnData?.status == "Active" && (
                              <Button
                                buttonText="Cancel"
                                // className="cancel-sub-btn add-sub-btn"
                                className="btn btn-outline-primary"
                                onClick={(e) =>
                                  handleOpenModalCancelSub(singleAddOnData)
                                }
                              />

                              // <button
                              //   type="button"
                              //   onClick={(e) =>
                              //     handleOpenModalCancelSub(singleAddOnData)
                              //   }
                              //   title="Click to Cancel Addon"
                              //   className="btn btn-primary py-1 px-2 ms-auto"
                              // >
                              //   Cancel
                              // </button>
                            )}

                            {singleAddOnData?.status === "Cancelled" && (
                              <div>
                                <p className="text-danger">
                                  {singleAddOnData?.status}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))
            : ""}
        </div>
      </div>
    </>
  );
}
