import React from "react";
import styles from "./ConfirmationPopup.module.css"

const ConfirmationPopup = ({
    show,
    title,
    subTitle,
    cancelTitle,
    onCancel,
    proceedTitle,
    onProceed,
}) => {

    if (!show) return null;

    return (
        <div className={`modal fade show ${styles.confirmation_modal}`} tabIndex="-1" aria-labelledby="vertical-center-modal" style={{ display: "block" }} aria-modal="true" role="dialog">
            <div className={`modal-dialog ${styles.confirmation_modal_dialog}`}>
                <div className="modal-content modal-filled">
                    <div className="modal-body p-4">
                        <div className="text-center text-danger">
                            <h4 className="mt-2">{title}</h4>
                            <p className="mt-3">{subTitle}</p>
                            <button type="button" className="btn btn-light my-2 btn-cnl" data-bs-dismiss="modal" onClick={onCancel}> {cancelTitle} </button>
                            <button type="button" className="btn btn-primary text-white" data-bs-dismiss="modal" onClick={onProceed}> {proceedTitle} </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;
