import React, { useEffect, useState } from "react";
import { Sidebar } from "../../../molecules/Sidebar";
import { Page } from "../../../layouts/Page";
import { useHistory } from "react-router-dom";
import { isURL } from "validator";
import moment from "moment";
import { isMobile } from '../../../helpers/helper';
import AuthCustom from "../../../helpers/AuthCustom";
// import validateField from "../../../helpers/AllValidtions";
import { VideoSteps } from "../Video/AddVideo";
import FormToast, { TYPES as FORM_TOAST_TYPES } from "../../../Form/FormToast";
import ToggleSwitchRow from "./../../../Form/ToggleSwitchRow"
import RadioButtonRow from "../../../Form/RadioButtonRow";
import Input from "../../../Form/Input";
import Button from "../../../Form/Button";
import CheckBoxRow from "../../../Form/CheckBoxRow";
import { VideoPurpose } from "../../../../Enums/VideoPurpose";
import FieldWarning from "../../../Form/FieldWarning";
import SuccessCheckout from "./SuccessCheckout";
import DateTimePicker from "../../../Form/DateTimePicker";

// getActualObjectLength(removeEmptyProperties

function ExternalCheckout() {
    const authCustom = new AuthCustom();
    const history = useHistory();
    const [publishDate, setPublishDate] = useState("");
    const [externalLink, setExternalLink] = useState("");
    const [errorMessage, setErrorMessage] = useState(false);
    const [externalCheckoutLoader, setExternalCheckoutLoader] = useState(false);
    const [inputValues, setInputValues] = useState([]);
    const [urlErrors, setUrlErrors] = useState([]);
    const [minDateTime, setMinDateTime] = useState("");
    const [schedulePublish, setSchedulePublish] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [user, setUser] = useState();
    const [checkoutTypes, setCheckoutTypes] = useState([]);
    const isMobileSize = isMobile();
    const [sidebarType, setSidebarType] = useState(isMobileSize ? 'mini-sidebar' : 'full');
    const [publishDateError, setPublishDateError] = useState("");
    const pagetitle = history.location.state.addVideoData.videoType;
    const videotypeTitle = "External Checkout";
    const fieldtext = "Checkout Link";
    const [urlproblemError, setProblemErrorErrors] = useState(true);
    const [allcheckout, setAllcheckout] = useState(false);
    const [payment, setPayment] = useState(false);
    const [inapp, setInapp] = useState(false);
    const [showSuccessModal, setSuccessModal] = useState(false);
    var streamType = history.location.state.addVideoData?.streamType;
    const isVideoTypeLead = history.location.state.addVideoData.videoType === VideoPurpose.TO_GENERATE_LEAD;
    const isDonationVideo = history.location.state.addVideoData?.videoType === VideoPurpose.TO_COLLECT_THE_DONATION;

    const [donationCheckoutType, setDonationCheckoutType] = useState("external_checkout"); // external_checkout, in_video
    const [donationAmounts, setDonationAmounts] = useState([
        { value: 5, error: "" },
        { value: 10, error: "" },
        { value: 15, error: "" },
        { value: 20, error: "" },
    ]);

    const onChangeDonationAmount = (value, index) => {
        const updatedItems = [...donationAmounts];
        const updatedItem = { ...updatedItems[index] };
        updatedItem.value = value;
        updatedItems[index] = updatedItem;
        setDonationAmounts(updatedItems);
    }

    useEffect(() => {
        async function fetchUser() {
            const authSession = JSON.parse(window.localStorage.getItem("authSession"))
            const userResponse = await authCustom.getUserInfo(authSession.accessToken, authSession.idToken);
            setUser(userResponse)
            return () => { }
        }

        fetchUser();
    }, [])

    useEffect(() => {

        fetchUserPaymentGateway();
    }, [])


    const fetchUserPaymentGateway = () => {

        let token = localStorage.getItem("authSession")
        token = JSON.parse(token);

        fetch(process.env.REACT_APP_API_URL + '/check-payment-gateway', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token.accessToken,

            },
            body: JSON.stringify({ "user_id": token.idToken }),
        })
            .then((response) => response.json())
            .then((data) => {



                if (data.code == '200' && (pagetitle !== "To generate lead")) {

                    setPayment(true)

                }

            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };


    var schetype = history.location.state.addVideoData.streamType;

    function handlePubishDate(value) {
        var value = value;
        var rule = "publish-date";
        setPublishDate(value)

        setPublishDateError("")

        if ((!moment(value).isAfter(moment().add(0, 'minutes')))) {

            setPublishDateError("Publish date must be greater than " + moment().add(0, 'minutes').format('D/M/Y h:mm a'))
        }


    }

    function handlePubishRightNow(e) {
        setSchedulePublish(e);
        if (e === true) {
            setPublishDate(null);
            setPublishDateError("")
        } else {
            setPublishDate(moment());
        }
    }

    function validateAllUrls() {
        if (isVideoTypeLead) return

        const selectedProducts = history.location.state.selectedProducts;
        if ((selectedProducts) && selectedProducts.length >= 1) {
            selectedProducts.map((value, index) => setExternalLinkMultiLink(typeof value.externalLink !== 'undefined' ? value.externalLink : null, index));
        }
    }

    function setExternalLinkMultiLink(link_value, index_number) {
        if (checkoutTypes[index_number] === "custom") {
            if (link_value == null || !isURL(link_value)) {
                urlErrors[index_number] = 'Please enter valid url';
                setUrlErrors(urlErrors);
                setProblemErrorErrors(true)

            } else {
                setProblemErrorErrors(false)
                urlErrors[index_number] = null;
                setUrlErrors(urlErrors);

            }

            setUrlErrors(urlErrors);

            setExternalLink(link_value);
            const selectedProducts = history.location.state.selectedProducts;
            if (typeof history.location.state.selectedProducts[index_number] !== 'undefined') {
                selectedProducts[index_number].externalLink = link_value;
                selectedProducts[index_number].use_shopify_checkout = false;
                selectedProducts[index_number].use_in_video_checkout = false;
                selectedProducts[index_number].use_collect_info = false;
            }
        } else {
            urlErrors[index_number] = null;
            setUrlErrors(urlErrors);

            const selectedProducts = history.location.state.selectedProducts;
            if (typeof history.location.state.selectedProducts[index_number] !== 'undefined') {
                selectedProducts[index_number].externalLink = null;


                if (checkoutTypes[index_number] === "shopify") {
                    selectedProducts[index_number].use_shopify_checkout = true;
                    selectedProducts[index_number].use_woo_commerce_checkout = false;
                    selectedProducts[index_number].use_collect_info = false;
                }

                if (checkoutTypes[index_number] === "woo_commerce") {
                    selectedProducts[index_number].use_woo_commerce_checkout = true;
                    selectedProducts[index_number].use_shopify_checkout = false;
                    selectedProducts[index_number].use_collect_info = false;
                }

                if (checkoutTypes[index_number] === "in_video") {
                    selectedProducts[index_number].use_in_video_checkout = true;
                    selectedProducts[index_number].use_woo_commerce_checkout = false;
                    selectedProducts[index_number].use_shopify_checkout = false;
                    selectedProducts[index_number].use_collect_info = false;
                }

                if (checkoutTypes[index_number] === "use_collect_info") {
                    selectedProducts[index_number].use_collect_info = true;
                    selectedProducts[index_number].use_in_video_checkout = false;
                    selectedProducts[index_number].use_woo_commerce_checkout = false;
                    selectedProducts[index_number].use_shopify_checkout = false;
                }

            }










        }
    }

    const setExternalLinkType = (value, index) => {
        const updatedCheckoutTypes = [...checkoutTypes];
        updatedCheckoutTypes[index] = value;
        setCheckoutTypes(updatedCheckoutTypes);
        setAllcheckout(false)

    };

    const handleInputChange = (index, value) => {
        const newInputValues = [...inputValues];
        newInputValues[index] = value;
        setInputValues(newInputValues);
    };
    useEffect(() => {
        setMinDateTime(new Date().toISOString().slice(0, 16));
        if (history.location.state?.showSuccess) {
            setSuccessMessage(history.location.state.showSuccess);
        }

        if (!history.location.state?.selectedProducts) {
            history.push({ pathname: '/' });
        }

    }, []);

    const SaveVideo = async (params) => {
        // console.log(params,"params");return;
        setErrorMessage(false);


        // const fetch_prods =  new Promise((resolve, reject) => {

        // let token = localStorage.getItem("authSession")
        // token = JSON.parse(token);
        // params.authId = token.idToken;

        const accessToken = JSON.parse(window.localStorage.getItem("authSession")).accessToken;

        setExternalCheckoutLoader(true);

        await fetch(process.env.REACT_APP_API_URL + '/add-video-with-product', {
            method: 'POST',
            mode: 'cors',
            headers: {
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + accessToken,
            },
            body: JSON.stringify(params)
        }).then(response => {
            if (response.ok) {
                response.json().then(json => {
                    if (json) {

                        setSuccessModal({
                            successVideoId: json.videoData.id,
                            successVideoShortUrl: json.videoData.video_short_url,
                            successWidgetAppId: json.widgetData.app_id,
                        })
                        // history.push({
                        //     pathname: '/success-checkout',
                        //     state: {
                        //         successVideoId: json.videoData.id,
                        //         successVideoShortUrl: json.videoData.video_short_url,
                        //         successWidgetAppId: json.widgetData.app_id,

                        //     },
                        // });
                        localStorage.setItem('is_onboarding_complete', true);
                    }
                });
            } else {
                response.json().then(json => {
                    if (json) {
                        setErrorMessage(json?.message);

                    } else {
                        setErrorMessage('Something went wrong');

                    }
                });
            }
        }).catch((error) => {
            setErrorMessage('Something went wrong');
        }).finally((error) => {
            setExternalCheckoutLoader(false);
        });
        // });

        // }
    }

    async function moveToSuccessCheckout() {

        validateAllUrls();

        // console.log("urlErrors", urlErrors)
        // if ((schedulePublish == true && !moment(publishDate).isValid()) || getActualObjectLength(removeEmptyProperties(urlErrors)) >= 1) {
        //     setErrorMessage("Please validate checkout and publish date!");
        // } else if (schedulePublish == true && (!moment(publishDate).isAfter(moment().add(0, 'minutes')))) {
        //     setErrorMessage("Publish date must be greater than " + moment().add(0, 'minutes').format('D/M/Y h:mm a'));
        // } else {

        const params = {
            addVideoData: history.location.state,
            selectedProducts: history.location.selectedProducts,
            publishDate: publishDate,
            externalLink: isVideoTypeLead ? null : externalLink,
            isExternalCheckout: isVideoTypeLead ? false : true,
            inapp: inapp,
            isRequestFromOnboarding: true,
            utc_offset: new Date().getTimezoneOffset()
        }

        await SaveVideo(params)
    }

    const onPressFinishDonation = async () => {

        setErrorMessage('');
        setDonationAmounts((d) => d.map(item => ({ ...item, error: "" })));

        if (donationCheckoutType === "external_checkout") {
            if (externalLink === "" || !isURL(externalLink)) {
                return setErrorMessage("Please write valid URL");
            }
        }

        if (donationCheckoutType === "in_video") {
            const updatedItems = donationAmounts.map(item => ({
                ...item,
                error: item.value % 5 !== 0 ? "amount should be divisible by 5" : ""
            }));
            setDonationAmounts(updatedItems);

            const donationAmountErrors = updatedItems.reduce((accumulator, e) => {
                if (e.error !== "") {
                    accumulator++;
                }

                return accumulator;
            }, 0);

            if (donationAmountErrors > 0) {
                return;
            }
        }

        const params = {
            addVideoData: history.location.state,
            selectedProducts: null,
            publishDate: publishDate,
            externalLink: donationCheckoutType === "external_checkout" ? externalLink : null,
            isExternalCheckout: donationCheckoutType === "external_checkout" ? true : false,
            isRequestFromOnboarding: true,
            inapp: donationCheckoutType === "in_video",
            utc_offset: new Date().getTimezoneOffset(),
            donation_amounts: donationAmounts.map(a => a.value)
        };

        await SaveVideo(params);
    }

    const PublishVideoContent = () => {
        return (
            <>
                {streamType !== "live-stream-from-external-source" && (
                    <div className="row mt-3">
                        <p className="mb-2">
                            <b>Note:</b> Your video will be publish rightaway click below to schedule it.
                        </p>
                        <CheckBoxRow
                            id="schedule_publish"
                            label="Schedule Publish"
                            checked={schedulePublish}
                            onChange={handlePubishRightNow}
                            disabled={false}
                        />
                    </div>
                )}

                {schedulePublish === true && (
                    <div className="mt-3">
                        {/* <DateTimePicker
                            id="schedule_date"
                            value={publishDate}
                            inputProps={{ placeholder: 'Select Date and Time' }}
                            onChange={(newDate) => handlePubishDate(newDate)}
                            isValidDate={!publishDateError} // Disable dates before today
                            errorMessage={publishDateError}
                        /> */}

                        <Input
                            id="schedule_date"
                            type="datetime-local"
                            label="Publish Date:"
                            min={minDateTime}
                            onChange={handlePubishDate}
                            disabled={false}
                            errorMessage={publishDateError}
                        />
                    </div>
                )}
            </>
        )
    }

    if (inapp == true) {

        var isButtonDisabled = (schedulePublish == true && (publishDateError || !moment(publishDate).isValid()))

    } else {

        if (checkoutTypes[0] == 'woo_commerce' || checkoutTypes[0] == 'shopify' || checkoutTypes[0] == 'use_collect_info') {

            var isButtonDisabled = (schedulePublish == true && (publishDateError || !moment(publishDate).isValid())) || allcheckout;

        } else {

            var isButtonDisabled = (schedulePublish == true && (publishDateError || !moment(publishDate).isValid())) || urlproblemError;

        }


    }

    if (isVideoTypeLead) {
        var isButtonDisabled = !moment(publishDate).isValid() && schedulePublish == true;
    }

    const onNavBarClick = () => {
        setSidebarType(prevSidebarType => prevSidebarType === 'full' ? 'mini-sidebar' : 'full');
    }

    const backToProductStep = () => {
        return history.push({
            pathname: "/add-product",
            state: {
                ...history.location.state,
                backFromExternal: true
            }
        });
    }


    //add-product

    if (isDonationVideo) {
        return (
            <div
                className={`page-wrapper ${sidebarType === "mini-sidebar" ? "mini-sidebar show-sidebar" : ""}`}
                data-sidebartype={sidebarType}
                id="main-wrapper"
                data-theme="blue_theme"
                data-layout="vertical"
                data-sidebar-position="fixed"
                data-header-position="fixed"
            >
                {errorMessage && (
                    <FormToast
                        type={FORM_TOAST_TYPES.DANGER}
                        message={errorMessage ? errorMessage : "Please publish date field."}
                    />
                )}

                {showSuccessModal && (<SuccessCheckout
                    {...showSuccessModal}
                    heading="You publish your video!"
                    subHeading="Your video is now ready"
                />)}

                <Sidebar onNavBarClick={onNavBarClick} />
                <Page onNavBarClick={onNavBarClick} title="External Checkout" formWidth={true}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-header-back" onClick={() => { backToProductStep() }}>
                                        <i className="ti ti-arrow-left"></i>
                                    </div>
                                    <h5 className="fw-bolder">Create Your First Shoppable Video</h5>
                                </div>
                                <div className="card-body">
                                    {/* <VideoSteps step="3" /> */}
                                    <div style={{ width: isMobileSize ? "100%" : 580, margin: "0 auto" }} className="mb-4">

                                        <div className="mb-4">
                                            <div className="card mb-2">
                                                <div className="card-body">
                                                    <div className="mt-2 mb-2">
                                                        <RadioButtonRow
                                                            label="External Checkout"
                                                            name="checkout_type"
                                                            id="external_checkout"
                                                            value="external_checkout"
                                                            disabled={false}
                                                            checked={donationCheckoutType === "external_checkout" ? true : false}
                                                            onChange={() => setDonationCheckoutType("external_checkout")}
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        <Input
                                                            type="text"
                                                            id="custom_link"
                                                            label="Custon Link:"
                                                            value={externalLink}
                                                            onChange={(e) => setExternalLink(e.target.value)}
                                                            disabled={donationCheckoutType !== "external_checkout"}
                                                            placeholder="https://www.link.com/"
                                                            errorMessage={errorMessage}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {payment && (<div className="card mb-4">
                                                <div className="card-body">
                                                    <div className="mt-2 mb-2">
                                                        <RadioButtonRow
                                                            label="IN-Video"
                                                            name="checkout_type"
                                                            id="in_video"
                                                            value="in_video"
                                                            disabled={false}
                                                            checked={donationCheckoutType === "in_video" ? true : false}
                                                            onChange={() => setDonationCheckoutType("in_video")}
                                                        />
                                                    </div>
                                                    <div className="mb-2">
                                                        <h6 className="text-black">Donation Amount</h6>
                                                        <div className="checkout-donation-options">
                                                            {
                                                                donationAmounts.map((d, i) => (
                                                                    <div
                                                                        // className={`col-md-3 ${i === 0 ? "pr-1" : "pr-1 pl-1"} ${i === donationAmounts.length - 1 ? "pr-5" : ""} `}
                                                                        className={``}
                                                                        key={`donation_amount_${i}`}>
                                                                        <Input
                                                                            type="number"
                                                                            id={`donation_amount_${i}`}
                                                                            value={d.value}
                                                                            onChange={(e) => onChangeDonationAmount(e.target.value, i)}
                                                                            disabled={donationCheckoutType !== "in_video"}
                                                                            placeholder=""
                                                                            errorMessage={d.error}
                                                                        />
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>

                                        <PublishVideoContent />

                                    </div>
                                    <div className="vs-form-footer d-flex justify-content-center align-items-center">
                                        <Button
                                            type="button"
                                            onClick={onPressFinishDonation}
                                            buttonText="Finish"
                                            fullButton={false}
                                            loading={externalCheckoutLoader}
                                            disabled={!user}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Page>
            </div>
        )
    }

    return (
        <div className={`page-wrapper ${sidebarType === 'mini-sidebar' ? 'mini-sidebar show-sidebar' : ''}`} data-sidebartype={sidebarType} id="main-wrapper" data-theme="blue_theme" data-layout="vertical" data-sidebar-position="fixed" data-header-position="fixed">

            {errorMessage && (
                <FormToast
                    type={FORM_TOAST_TYPES.DANGER}
                    message={(errorMessage) ? errorMessage : "Please publish date field."}
                />
            )}

            {successMessage && (
                <FormToast
                    type={FORM_TOAST_TYPES.SUCCESS}
                    message={successMessage}
                />
            )}

            {showSuccessModal && (<SuccessCheckout
                {...showSuccessModal}
                heading="You publish your video!"
                subHeading="Your video is now ready"
            />)}

            <Sidebar onNavBarClick={onNavBarClick} />
            <Page onNavBarClick={onNavBarClick} title={videotypeTitle} formWidth={true}>

                <div className="row pb-50">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header vs-card-header">
                                <h5 className="fw-bolder">Create Your First Shoppable Video</h5>
                                <VideoSteps onBoarding step="4" purpose={history.location.state.addVideoData.videoType} />
                            </div>
                            <div className="card-body">
                                <div className="w-500">

                                    <h5 className="mt-2 mb-4 text-black">Select checkout option for your products</h5>

                                    <div className="mb-4 mt-2">
                                        {history.location.state?.selectedProducts.map((value, index) => {
                                            console.log("value", value)
                                            const isShopifyProduct = !!value?.shopify_product_id && !!user?.shopify?.store && !!user?.shopify?.storefront_access_token
                                            const isWooCommerceProduct = !!value?.woocommerce_product_id

                                            if (!checkoutTypes[index]) {
                                                checkoutTypes[index] = isShopifyProduct ? 'shopify' : (isWooCommerceProduct) ? 'woo_commerce' : 'custom'
                                                setCheckoutTypes(checkoutTypes)
                                            }

                                            return (
                                                <div className="vs-checkout-card mb-4">
                                                    <div className="vs-checkout-card-img-conatiner">
                                                        <div className="vs-checkout-card-img mb-3">
                                                            <img src={value.image} alt={value.label} />
                                                        </div>
                                                        <div className="vs-checkout-card-cotent">
                                                            <h5 className="text-black">{value.label}</h5>
                                                        </div>
                                                    </div>
                                                    <div className="vs-checkout-card-content">
                                                        <h3>{value.label}</h3>
                                                        {(payment && value.has_shipping !== "paid-shipping") && (
                                                            <div className="mb-2">
                                                                <RadioButtonRow
                                                                    id="in-video-checkout"
                                                                    name="in-video-checkout"
                                                                    label="In Video Checkout"
                                                                    checked={inapp}
                                                                    onChange={() => setInapp(!inapp)}
                                                                    disabled={false}
                                                                />
                                                            </div>
                                                        )}
                                                        {isShopifyProduct && (
                                                            <div className="mb-2">
                                                                <RadioButtonRow
                                                                    label="Shopify Checkout"
                                                                    name="shopify_type"
                                                                    id="shopify_type"
                                                                    value="shopify"
                                                                    disabled={false}
                                                                    checked={checkoutTypes[index] === "shopify" ? true : false}
                                                                    onChange={(e) => setExternalLinkType(e.target.value, index)}
                                                                />
                                                            </div>
                                                        )}
                                                        {isWooCommerceProduct && (
                                                            <div className="mb-2">
                                                                <RadioButtonRow
                                                                    label="Woocommerce Checkout"
                                                                    name="woo_type"
                                                                    id="woo_type"
                                                                    value="woo_commerce"
                                                                    disabled={false}
                                                                    checked={checkoutTypes[index] === "woo_commerce" ? 'checked' : ''}
                                                                    onChange={(e) => setExternalLinkType(e.target.value, index)}
                                                                />
                                                            </div>
                                                        )}
                                                        <div className="mb-2">
                                                            <RadioButtonRow
                                                                label="Collect Customer Info Only"
                                                                name="use_collect_info_type"
                                                                id="use_collect_info_type"
                                                                value="use_collect_info"
                                                                disabled={false}
                                                                checked={checkoutTypes[index] === "use_collect_info" ? true : false}
                                                                onChange={(e) => setExternalLinkType(e.target.value, index)}
                                                            />
                                                        </div>
                                                        <div className="mb-0">
                                                            <RadioButtonRow
                                                                label="Custom Checkout link"
                                                                name="custom_type"
                                                                id="custom_type"
                                                                value="custom"
                                                                disabled={false}
                                                                checked={checkoutTypes[index] === "custom" ? true : false}
                                                                onChange={(e) => setExternalLinkType(e.target.value, index)}
                                                            />
                                                        </div>
                                                        <div className="pl-6">
                                                            <Input
                                                                id="custom_link"
                                                                type="text"
                                                                label=""
                                                                value={externalLink}
                                                                onChange={(e) => setExternalLinkMultiLink(e.target.value, index)}
                                                                disabled={checkoutTypes[index] !== "custom"}
                                                                placeholder={"https://www.link" + index + ".com/"}
                                                                errorMessage={(typeof urlErrors[index] !== 'undefined' && urlErrors[index]?.length >= 1) ? "Please enter correct URL" : ""}
                                                                key={index}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                        <div className="card">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between mt-2 mb-2">
                                                    <h5>Schedule Video</h5>
                                                    <ToggleSwitchRow
                                                        id="schedule_video"
                                                        name="schedule_video"
                                                        label=""
                                                        checked={schedulePublish}
                                                        onChange={() => handlePubishRightNow(!schedulePublish)}
                                                        disabled={false}
                                                        height="20px"
                                                        width="32px"
                                                    />
                                                </div>
                                                <div className="mb-2">
                                                    <FieldWarning message="Your video will be publish rightaway select date & time to schedule it." />
                                                </div>
                                                {schedulePublish === true && (
                                                    <div className="mt-4 mb-2">
                                                        {/* <DateTimePicker
                                                            value={publishDate}
                                                            inputProps={{ placeholder: 'Select Date and Time' }}
                                                            onChange={(newDate) => handlePubishDate(newDate)}
                                                            isValidDate={!publishDateError} // Disable dates before today
                                                            errorMessage={publishDateError}
                                                        /> */}
                                                        <Input
                                                            id="datec"
                                                            type="datetime-local"
                                                            label="Publish Date"
                                                            value={publishDate}
                                                            // onChange={handlePubishDate}
                                                            onChange={(e) => handlePubishDate(e.target.value)}
                                                            disabled={false}
                                                            placeholder=""
                                                            errorMessage={publishDateError}
                                                            min={minDateTime}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {user && (<div className="vs-form-footer d-flex justify-content-center align-items-center">
                                    <Button
                                        type="button"
                                        onClick={moveToSuccessCheckout}
                                        buttonText="Publish Video"
                                        fullButton={false}
                                        loading={externalCheckoutLoader}
                                        disabled={isButtonDisabled || externalCheckoutLoader}
                                    />
                                </div>)} */}
                            </div>
                        </div>
                    </div>
                </div>
            </Page>
            {/* sticky footer */}
            <div className="sticky-footer ">
                <div className="footer-container">
                    <div className="d-flex justify-content-start align-items-center pl-7 gap-3">
                        <Button
                            className="btn btn-outline-primary fs-5 w-233 py-14 px-16 "
                            type="button"
                            buttonText={"Back"}
                            onClick={() => { backToProductStep() }}
                        />
                        <Button
                            className="btn btn-primary fs-5 text-white w-233 py-14 px-16"
                            type="button"
                            onClick={moveToSuccessCheckout}
                            buttonText="Publish Video"
                            loading={externalCheckoutLoader}
                            disabled={isButtonDisabled || externalCheckoutLoader}
                        />
                    </div>
                </div>
            </div>
            {/* sticky footer */}
        </div>
    );
}
export default ExternalCheckout;