import InfoImg from "./../../assets/icons/info.svg"

const FieldWarning = ({ message }) => {
    return (
        <div className="vs-form-field-warning">
            <div>
                <img src={InfoImg} alt="Warning" height="26" />
            </div>
            <div>
                <span>{message}</span>
            </div>
        </div>
    )
}

export default FieldWarning;